import { FlamingoContext } from 'contexts/flamingo';
import { USER_ROLES } from 'helpers/permissions-util';
import { useContext } from 'react';

/**
 * Hook to check if the logged-in user is an Uber admin or not.
 */
const useCheckGozioAdmin = () => {
  const { user } = useContext(FlamingoContext);
  return user.roles?.[0] === USER_ROLES.UBER_ADMIN;
};

export const useCheckRole = () => {
  const { user } = useContext(FlamingoContext);
  return user.roles?.[0];
};

export default useCheckGozioAdmin;
