import { ArrowForward, Warning } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loading from 'components/loading/loading';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = ({ theme: { palette, spacing } }) => ({
  card: {
    alignItems: 'center',
    border: `solid 1px ${palette.blue[400]}`,
    borderRadius: '8px',
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(palette.grey[350], 0.4)}`,
    cursor: 'pointer',
    display: 'flex',
    height: '78px',
    padding: '16px',

    '& svg': {
      height: '36px',
      marginRight: '10px',
      verticalAlign: 'baseline',
      width: '36px',
    },
    '&:hover': {
      border: `solid 2px ${palette.blue[400]}`,
      boxShadow: `0 2px 4px 0 ${colorWithAlpha(palette.grey[350], 0.6)}`,
      '& $iconContainer svg': {
        marginLeft: '-1px',
      },
      '& $iconWrapper svg': {
        marginLeft: '-1px',
      },
      '& $title': {
        color: palette.grey[600],
        marginLeft: '-1px',
      },
      '& $rightArrow': {
        color: `${palette.grey[600]} !important`,
      },
      '& $description': {
        color: palette.grey[600],
        fontWeight: 'bold',
        marginLeft: '-1px',
      },
    },
  },
  description: {
    display: '-webkit-box',
    fontSize: '12px',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  title: {
    display: 'inline-block',
  },
  rightArrow: {
    color: `${palette.grey[500]} !important`,
    height: '16px !important',
    marginLeft: '3px',
    verticalAlign: 'text-bottom !important',
    width: '16px !important',
  },
  iconContainer: {
    alignItems: 'center',
    borderRadius: spacing(1),
    color: palette.orange[400],
    display: 'flex',
    flex: `0 0 ${spacing(8)}`,
    height: spacing(8),
    justifyContent: 'center',
    marginRight: spacing(1.5),
  },
  iconWrapper: {
    color: palette.orange[400],
    height: '36px',
    marginRight: '10px',
    verticalAlign: 'baseline',
    width: '36px',
  },
  viewOnlyCard: {
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    '& svg': {
      height: '36px',
      verticalAlign: 'baseline',
      width: '36px',
    },
  },
  viewOnlyUnactionable: {
    marginTop: '16px',
    marginBottom: '0 !important',
  },
});

const CardContainer = ({ actionProps, sx, large, title, children }) => (
  <Grid
    key={`gozioProgressCard-${title}`}
    sx={sx}
    item
    xs={large ? 12 : 4}
    {...actionProps}
  >
    {children}
  </Grid>
);

CardContainer.propTypes = {
  actionProps: PropTypes.object,
  sx: PropTypes.object,
  large: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

const ProgressCard = ({
                        actionable,
                        children,
                        description,
                        large,
                        icon,
                        onClick,
                        onHoverDescription,
                        onHoverTitle,
                        title,
                        tooltip,
                        showTooltip,
                        status,
                        sx,
                      }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const onCardClicked = () => onClick({ title, description });
  const [activeTitle, setActiveTitle] = useState(title);
  const [activeDescription, setActiveDescription] = useState(description);

  const textContainerStyle = {
    color:
      status === 'active' ? ColorPalette.grey[600] : ColorPalette.grey[500],
    '& p': {
      color:
        status === 'active' ? ColorPalette.grey[600] : ColorPalette.grey[400],
    },
  };

  useEffect(() => {
    setActiveTitle(title);
  }, [title]);

  useEffect(() => {
    setActiveDescription(description);
  }, [description]);

  const actionProps = actionable
    ? {
      onClick: () => onCardClicked(),
      onMouseEnter: () => {
        if (actionable) {
          if (onHoverTitle) setActiveTitle(onHoverTitle);
          if (onHoverDescription) setActiveDescription(onHoverDescription);
        }
      },
      onMouseLeave: () => {
        if (actionable) {
          setActiveTitle(title);
          setActiveDescription(description);
        }
      },
    }
    : {};

  if (!theme) {
    return <Loading />;
  }

  let content;
  if (actionable) {
    content = (
      <CardContainer
        actionProps={actionProps}
        large={large}
        title={title}
        sx={{ ...styles.card, ...sx }}
      >
        <Box sx={styles.iconWrapper}>{icon || <Warning />}</Box>
        <Grid container direction="column">
          <Grid item>
            <Typography sx={styles.title} variant="subtitle1">
              {activeTitle}
            </Typography>
            <ArrowForward sx={styles.rightArrow} />
          </Grid>
          <Grid item>
            <Typography sx={styles.description} variant="body2">
              {activeDescription}
            </Typography>
          </Grid>
        </Grid>
      </CardContainer>
    );
  } else {
    content = (
      <CardContainer
        actionProps={actionProps}
        large={large}
        title={title}
        sx={[styles.viewOnlyCard, styles.viewOnlyUnactionable, sx].reduce(
          (target, current) => Object.assign(target, current),
          {},
        )}
      >
        <Box
          sx={{
            ...styles.iconContainer,
            border: `1px solid ${
              status === 'inactive' ? '#dfdfe0' : '#c2c3c4'
            }`,
          }}
        >
          {icon || <Warning />}
        </Box>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle1" sx={textContainerStyle}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={textContainerStyle}>
              {description}
            </Typography>
          </Grid>
          <Grid item sx={textContainerStyle}>
            {children}
          </Grid>
        </Grid>
      </CardContainer>
    );
  }

  if (showTooltip && tooltip !== '') {
    return (
      <Tooltip title={tooltip} placement="top-start">
        <Box sx={styles.root}>{content}</Box>
      </Tooltip>
    );
  }
  return content;
};

ProgressCard.propTypes = {
  actionable: PropTypes.bool,
  children: PropTypes.any,
  large: PropTypes.bool,
  tooltip: PropTypes.string,
  showTooltip: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onHoverDescription: PropTypes.string,
  onHoverTitle: PropTypes.string,
  status: PropTypes.oneOf(['active', 'inactive']),
  sx: PropTypes.object,
};

ProgressCard.defaultProps = {
  actionable: true,
  large: false,
  tooltip: '',
  showTooltip: false,
  onClick: () => {},
  status: 'active',
  sx: {},
};

export default ProgressCard;
