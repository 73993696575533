import React from 'react';
import { TextField } from 'mui-rff';

const preventLetterE = (e) => {
	if (e.key.toLowerCase() === 'e') {
		e.preventDefault();
		e.stopPropagation();
	}
};

const NumberField = ({ ...rest }) => (
	<TextField onKeyDown={preventLetterE} type="number" {...rest} />
);

export default NumberField;
