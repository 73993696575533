import Loading from 'components/loading/loading';
import Notifier from 'components/notifier/notifier';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { isGlobalTemplate } from 'helpers/network-util';
import { ALL_WORKSPACES } from 'helpers/workspace-util';
import { useLogout } from 'hooks/dataHooks';
import useNetworkList from 'hooks/dataHooks/useNetworkList';
import { useWorkspace } from 'hooks/useWorkspace';
import PropTypes from 'prop-types';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const PageHelpers = ({
                       showWorkspaceSwitcher,
                       supportedWorkspace,
                       children,
                     }) => {
  const { networkId } = useParams();
  const { workspace, initializeWorkspace } = useWorkspace();
  const { activeNetwork, flash, globalTemplate, networks }
    = useContext(FlamingoContext);
  const { networkList, loading: NetworkLoading } = useNetworkList();
  const [loading, setLoading] = useState(true);
  const logout = useLogout();

  useEffect(() => {
    if (
      !NetworkLoading
      && networkList.length > 0
      && (!activeNetwork?.id || activeNetwork?.id !== networkId)
    ) {
      if (isGlobalTemplate(networkId)) {
        activeNetwork.setActiveNetwork(globalTemplate);
      } else {
        const selected = networkList.find((n) => n.id === networkId);
        if (selected) {
          if (selected.isActive) {
            activeNetwork.setActiveNetwork(selected);
          } else {
            logout();
          }
        }
      }
    }
  }, [
    activeNetwork,
    globalTemplate,
    networkId,
    NetworkLoading,
    networkList,
    networks.length,
    logout,
  ]);

  useEffect(() => {
    setLoading(activeNetwork?.configuration?.features?.languages?.length === 0);
  }, [activeNetwork]);

  if (workspace) {
    if (
      supportedWorkspace !== ALL_WORKSPACES
      && supportedWorkspace !== workspace
    ) {
      return <Navigate to={`/network/${networkId}/welcome`} />;
    }
  } else {
    initializeWorkspace();
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Notifier flash={flash} clearFlash={flash.resetNotification} />
      {children}
      {showWorkspaceSwitcher && <WorkspaceSwitcher />}
    </Suspense>
  );
};

PageHelpers.propTypes = {
  children: PropTypes.any,
  showWorkspaceSwitcher: PropTypes.bool,
  supportedWorkspace: PropTypes.oneOf(['all', 'live', 'draft']),
};

PageHelpers.defaultProps = {
  showWorkspaceSwitcher: true,
  supportedWorkspace: ALL_WORKSPACES,
};

export default PageHelpers;
