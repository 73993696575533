import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    marginTop: '24px',
    marginBottom: '18px',
    letterSpacing: '0.38px',
  },
  formSubSectionSubtitle: {
    display: 'inline-block',
    marginRight: '12px',
    color: theme.palette.grey[600],
  },
  clearAll: {
    display: 'inline-block',
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    marginLeft: '12px',
    paddingLeft: '12px',
  },
  noClear: {
    marginRight: 0,
  },
  tooltip: {
    marginLeft: '6px',
    verticalAlign: 'middle',
  },
});

const ClearComponent = ({ text, onClick, styles }) => (
  <Box sx={styles.clearAll}>
    <Link
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {text}
    </Link>
  </Box>
);

ClearComponent.propTypes = {
  styles: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ClearComponent.defaultProps = {
  onClick: () => {},
  text: 'Clear Fields',
};

const FormSubSectionTitle = ({
                               sx,
                               title,
                               tooltip,
                               variant,
                               clearText,
                               onClear,
                               id,
                             }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={{ ...styles.root, ...sx }} id={id}>
      <Typography
        sx={{
          ...styles.formSubSectionSubtitle,
          ...onClear && styles.noClear,
        }}
        variant={variant}
      >
        {title}
      </Typography>
      {tooltip && <InfoTooltip sx={styles.tooltip} title={tooltip} />}
      {onClear && (
        <ClearComponent styles={styles} onClick={onClear} text={clearText} />
      )}
    </Box>
  );
};

FormSubSectionTitle.propTypes = {
  clearText: PropTypes.string,
  id: PropTypes.string,
  onClear: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  variant: PropTypes.string,
};

FormSubSectionTitle.defaultProps = {
  clearText: 'Clear Fields',
  id: null,
  onClear: null,
  sx: {},
  variant: 'body1',
};

export default FormSubSectionTitle;
