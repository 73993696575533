import { Box, Button, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Condition from 'components/forms/condition';
import Switch from 'components/switch/formSwitch';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { normalizeDate, STANDARD_DATE_REGEX } from 'helpers/date-util';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'validGoLiveDate', function(errorMessage) {
  return this.test('valid-date-format', errorMessage, function(str) {
    const { path, createError } = this;

    const date = dayjs(str, DATE_FORMAT, true);
    if (!(date.isValid() && str.match(STANDARD_DATE_REGEX))) {
      return createError({
        path,
        message: date ? errorMessage : 'Invalid date',
      });
    }

    return true;
  });
});

export const buildAnalyticsConfigSchema = ({
                                             mobileEnabled,
                                             kioskEnabled,
                                           }) => ({
  ...mobileEnabled && {
    analytics_mobile_project_id: Yup.string()
      .nullable()
      .required('Mobile Segment Project ID is required'),
    analytics_mobile_enabled: Yup.bool().nullable(),
    go_live_date_mobile: Yup.string()
      .nullable()
      .when('analytics_mobile_enabled', {
        is: true,
        then: (schema) => schema.nullable().validGoLiveDate('Format: MM/DD/YYYY', true),
      }),
  },

  ...kioskEnabled && {
    analytics_kiosk_project_id: Yup.string()
      .nullable()
      .required('Kiosk Segment Project ID is required'),
    analytics_kiosk_enabled: Yup.bool().nullable(),
    go_live_date_kiosk: Yup.string()
      .nullable()
      .when('analytics_kiosk_enabled', {
        is: true,
        then: (schema) => schema.nullable().validGoLiveDate('Format: MM/DD/YYYY', true),
      }),
  },
});

export const ANALYTICS_CONFIG_ERROR_MAP = [
  [
    'analytics_mobile_project_id',
    {
      label: 'Mobile Segment Project ID',
      section: 'Analytics',
    },
  ],
  [
    'go_live_date_mobile',
    {
      label: 'Mobile Go Live Date',
      section: 'Analytics',
    },
  ],
  [
    'analytics_kiosk_project_id',
    {
      label: 'Kiosk Segment Project ID',
      section: 'Analytics',
    },
  ],
  [
    'go_live_date_kiosk',
    {
      label: 'Kiosk Go Live Date',
      section: 'Analytics',
    },
  ],
];

const buildStyles = ({ theme }) => ({
  root: {
    padding: '24px',
  },
  formControlLabel: {
    '& fieldset': {
      marginRight: '-20px',
      paddingLeft: '12px',
    },
  },
  footer: {
    backgroundColor: theme.palette.white,
    bottom: 0,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: `0 -2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.3)}`,
    height: '70px',
    padding: '13px 24px',
    position: 'absolute',
    right: 0,
    textAlign: 'right',
    width: '100%',
    zIndex: 100,
  },
});

const AnalyticsConfig = ({ data, disabled, onSaved }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <>
      <Grid container sx={styles.root}>
        {data?.mobileEnabled && (
          <>
            <Grid item xs={12} sx={{ marginBottom: '26px' }}>
              <Typography variant="subtitle1">
                Mobile Analytics Settings
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="analytics_mobile_project_id"
                label={getFormLabel('Segment Project ID', true)}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ paddingLeft: '24px', paddingRight: '24px' }}
            >
              <Typography variant="body1">Collect Mobile Analytics</Typography>
              <Field name="analytics_mobile_enabled">
                {({ input }) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={styles.formControlLabel}
                      control={<Switch input={input} />}
                      label={input.value ? 'On' : 'Off'}
                      labelPlacement="end"
                    />
                  </FormGroup>
                )}
              </Field>
            </Grid>
            <Condition when="analytics_mobile_enabled" is={true}>
              <Grid item xs={3}>
                <Field
                  name="go_live_date_mobile"
                  parse={normalizeDate}
                  format={normalizeDate}
                >
                  {({ input: { value, ...rest } }) => (
                    <TextField
                      label={getFormLabel('Go Live Date', true)}
                      variant="outlined"
                      fullWidth
                      placeholder="MM/DD/YYYY"
                      {...rest}
                      value={value || ''}
                    />
                  )}
                </Field>
              </Grid>
            </Condition>
          </>
        )}
        {data?.kioskEnabled && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: data?.mobileEnabled ? '36px' : 0,
                marginBottom: '26px',
              }}
            >
              <Typography variant="subtitle1">
                Kiosk Analytics Settings
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="analytics_kiosk_project_id"
                label={getFormLabel('Segment Project ID', true)}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ paddingLeft: '24px', paddingRight: '24px' }}
            >
              <Typography variant="body1">Collect Kiosk Analytics</Typography>
              <Field name="analytics_kiosk_enabled">
                {({ input }) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={styles.formControlLabel}
                      control={<Switch input={input} />}
                      label={input.value ? 'On' : 'Off'}
                      labelPlacement="end"
                    />
                  </FormGroup>
                )}
              </Field>
            </Grid>
            <Condition when="analytics_kiosk_enabled" is={true}>
              <Grid item xs={3}>
                <Field
                  name="go_live_date_kiosk"
                  parse={normalizeDate}
                  format={normalizeDate}
                >
                  {({ input: { value, ...rest } }) => (
                    <TextField
                      label={getFormLabel('Go Live Date', true)}
                      variant="outlined"
                      fullWidth
                      placeholder="MM/DD/YYYY"
                      {...rest}
                      value={value || ''}
                    />
                  )}
                </Field>
              </Grid>
            </Condition>
          </>
        )}
      </Grid>
      <Box sx={styles.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

AnalyticsConfig.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.object,
  onSaved: PropTypes.func,
};

AnalyticsConfig.defaultProps = {
  data: null,
  disabled: false,
  onSaved: null,
};

export default AnalyticsConfig;
