import { ALL_WORKSPACES } from 'helpers/workspace-util';
import PropTypes from 'prop-types';
import React from 'react';
import { AuthGuard } from 'router/authGuard';

export const RouteGuard = ({ component: InputComponent, auth }) => {
  if (!auth) {
    return <InputComponent />;
  }

  return <AuthGuard component={InputComponent} />;
};

RouteGuard.propTypes = {
  component: PropTypes.any.isRequired,
  private: PropTypes.bool,
  workspace: PropTypes.string,
};

RouteGuard.defaultProps = {
  private: true,
  workspace: ALL_WORKSPACES,
};
