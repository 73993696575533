import { gql } from '@apollo/client';

export const FIND_NETWORK_CONTENT_PROGRESS = gql`
  query ($workspaces: AutoGraphMixed) {
    findNetworkContentProgress(
      where: {
        workspace: {
          name: $workspaces
        }
      }
    ) {
      edges {
        node {
          id
          type
          status
          workspace { name }
          updatedBy { profile { firstName lastName } }
          updatedAt
        }
      }
    }
    incompleteLiveLocations: findNetworkPlace(
      where: {
        validation: {
          status: "incomplete"
        }
        workspaces: {
          name: "live"
        }
      }
    ) {
      count
    }
    totalLiveLocations: findNetworkPlace(
      where: {
        workspaces: {
          name: "live"
        }      
      }
    ) {
      count
    }
    incompleteDraftLocations: findNetworkPlace(
      where: {
        validation: {
          status: "incomplete"
        }
        workspaces: {
          name: "draft"
        }
      }
    ) {
      count
    }
    totalDraftLocations: findNetworkPlace(
      where: {
        workspaces: {
          name: "draft"
        }      
      }
    ) {
      count
    }
  }
`;
