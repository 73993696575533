import { Box, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TabPanel from 'components/tabPanel/tabPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { tabA11yProps } from 'helpers/page-util';
import Table from 'pages/users/containers/Table';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 193px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
}));

const UsersTable = ({
                      data,
                      onUserCreated,
                      onUserUpdated,
                      loading,
                      searchParam,
                    }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const invited = data.filter(
    (user) => user?.state === null
      || user?.state?.toString().toLowerCase() === 'invited',
  );
  const active = data.filter(
    (user) => user?.state?.toString().toLowerCase() === 'active',
  );
  const inactive = data.filter(
    (user) => user?.state?.toString().toLowerCase() === 'inactive',
  );

  return (
    <Box className={classes.content}>
      <Grid className={classes.takeoverTabs}>
        <Tabs
          value={currentTab}
          onChange={(e, idx) => setCurrentTab(idx)}
          aria-label="simple tabs"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Active Users" {...tabA11yProps('users', 0)} />
          <Tab label="Invited" {...tabA11yProps('users', 1)} />
          <Tab label="Archived" {...tabA11yProps('users', 2)} />
        </Tabs>
      </Grid>
      <TabPanel
        value={currentTab}
        index={0}
        className={classes.tabPanel}
        sx={{
          ...currentTab === 0 && {
            height: 'calc(100% - 69px)',
          },
        }}
      >
        <Box className={classes.tabBody}>
          <Table
            data={active}
            status={'active'}
            loading={loading}
            onUserCreated={onUserCreated}
            onUserUpdated={onUserUpdated}
            search={searchParam}
          />
        </Box>
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
        className={classes.tabPanel}
        sx={{
          ...currentTab === 1 && {
            height: 'calc(100% - 69px)',
          },
        }}
      >
        <Box className={classes.tabBody}>
          <Table
            data={invited}
            status={'invited'}
            onUserCreated={onUserCreated}
            onUserUpdated={onUserUpdated}
            search={searchParam}
          />
        </Box>
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={2}
        className={classes.tabPanel}
        sx={{
          ...currentTab === 2 && {
            height: 'calc(100% - 69px)',
          },
        }}
      >
        <Box className={classes.tabBody}>
          <Table
            data={inactive}
            status={'inactive'}
            loading={loading}
            onUserCreated={onUserCreated}
            onUserUpdated={onUserUpdated}
            search={searchParam}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};

UsersTable.propTypes = {
  data: PropTypes.array,
  onUserCreated: PropTypes.func,
  onUserUpdated: PropTypes.func,
  searchParam: PropTypes.string,
};

UsersTable.defaultProps = {
  data: [],
  onUserCreated: () => {},
  searchParam: '',
};

export default UsersTable;
