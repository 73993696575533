import {
  buildLabelByLang,
  buildLanguageSchema,
  getLabelByLang,
  LANGUAGE_CODES,
} from 'helpers/lang-util';
import { DRAFT_WORKSPACE } from 'helpers/workspace-util';
import * as Yup from 'yup';

export const EXPLORE_PANEL_QUICKLINKS = 'quicklinks';
export const EXPLORE_PANEL_GENERAL = 'general';
export const DEFAULT_EXPLORE_PANEL_BACKGROUND_COLOR = '#C2C3C4'; // SCM-2203

export const GENERAL_TAB_I18N_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'Explore Panel Name',
    required: true,
    charLimit: 30,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'Explore Panel Name (Spanish)',
    required: false,
    charLimit: 30,
  },
];

export const buildExplorePanelSchema = (languages) => Yup.object().shape(
    buildLanguageSchema(
      {
        nameEn: Yup.string()
          .max(30, 'Name must be 30 characters or less')
          .required('Name is required'),
        nameEs: Yup.string().max(30, 'Name must be 30 characters or less'),
        color: Yup.string().matches(/^#([A-F0-9]){6}$/i, {
          message: 'Background Color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
        template: Yup.string().oneOf(['four_actions', 'eight_actions']),
      },
      languages,
      { nameEn: LANGUAGE_CODES.ENGLISH, nameEs: LANGUAGE_CODES.SPANISH },
    ),
  );

export const buildExplorePanelQuicklinkSchema = (languages = []) => Yup.object().shape(
    buildLanguageSchema(
      {
        nameEn: Yup.string()
          .max(18, 'Name must be 18 characters or less')
          .required('Name is required'),
        nameEs: Yup.string().max(18, 'Name must be 18 characters or less'),

        // Quicklink Validation
        linkType: Yup.string().nullable(),
        // list
        listDest: Yup.string()
          .nullable()
          .when('linkType', {
            is: 'list',
            then: (schema) => schema.required('List is required.'),
          }),
        // Custom
        custom: Yup.string()
          .nullable()
          .when('linkType', {
            is: 'custom',
            then: (schema) => schema.required('This open field is required'),
          }),
      },
      languages,
      { nameEn: LANGUAGE_CODES.ENGLISH, nameEs: LANGUAGE_CODES.SPANISH },
    ),
  );

export const sortSites = (a, b) => {
  const aName = a?.name[0]?.label.toLowerCase();
  const bName = b?.name[0]?.label.toLowerCase();
  return aName > bName ? 1 : -1;
};

export const getAvailableSites = (panels = [], sites = []) => {
  const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
      ? 1
      : b.label.toLowerCase() > a.label.toLowerCase()
        ? -1
        : 0;
  const panelSiteIds = panels.map((p) => p.site?.id).filter((p) => p);
  return sites
    .filter((s) => !panelSiteIds?.includes(s.id) && s.isMapped)
    .map((s) => ({ id: s.id, label: getLabelByLang(s.name) }))
    .sort(nameSort);
};

export const buildExplorePanelQuicklink = (values = {}) => {
  let quicklink = {};

  switch (values.linkType) {
    case 'category':
      quicklink = {
        ...quicklink,
        type: 'category',
        categories: values.categories?.map((c) => c.id),
        custom: null,
        list: null,
      };
      break;
    case 'list':
      quicklink = {
        ...quicklink,
        type: 'list',
        list: values.listDest,
        categories: null,
        custom: null,
        filterLinkDestBySite: false,
      };
      break;
    case 'custom':
      if (!values.custom) {
        return null;
      }
      quicklink = {
        ...quicklink,
        type: 'custom',
        custom: values.custom,
        categories: null,
        list: null,
        filterLinkDestBySite: false,
      };
      break;
    default:
      quicklink = null;
      break;
  }

  return quicklink;
};

export const buildQuicklinkFromValues = (data, values, forGraphQl = true) => {
  const rest = forGraphQl
    ? buildExplorePanelQuicklink(values)
    : {
      ...values,
    };
  return {
    id: data?.id,
    name: buildLabelByLang(values, 'nameEn', 'nameEs'),
    order: data?.order,
    type: values.type,
    filterLinkDestBySite: values.filterLinkDestBySite,
    ...rest,
  };
};

export const getExplorePanelTemplateCount = (templateOption) => {
  switch (templateOption) {
    case 'four_actions':
      return 4;
    case 'eight_actions':
      return 8;
    default:
      return 4;
  }
};

export const getProgress = (quicklink = {}) => {
  if (quicklink.name?.length === 0) {
    return 'Incomplete';
  }

  if (!quicklink.icon?.url) {
    return 'Incomplete';
  }

  if (!quicklink.type) {
    return 'Incomplete';
  }

  switch (quicklink.type) {
    case 'category':
      if (!quicklink.categories?.length) {
        return 'Incomplete';
      }
      break;
    case 'list':
      if (!quicklink.list?.id) {
        return 'Incomplete';
      }
      break;
    case 'custom':
      if (!quicklink.custom) {
        return 'Incomplete';
      }
      break;
    default:
      break;
  }

  return 'Complete';
};

export const mapToPanelAction = (
  items = [],
  originalItems = [],
  systemPanelActions = false,
) => items.map((item, index) => {
    const originalItem = originalItems.find((t) => t.id === item.id);

    let mappedItem = {};

    if (originalItem) {
      const {
        name,
        icon,
        type,
        list,
        categories,
        filterLinkDestBySite,
        custom,
      } = originalItem;
      mappedItem = {
        name: name || [],
        order: index,
        type: item.type || type || 'category',
        icon: item.icon?.image?.id || icon?.image?.id || null,
        list: item.list?.id || list?.id || null,
        categories: (item.categories || categories || []).map((c) => c.id),
        filterLinkDestBySite:
          item.filterLinkDestBySite || filterLinkDestBySite || false,
        custom: item.custom || custom || null,
      };
    } else {
      const {
        name,
        icon,
        type,
        list,
        categories,
        filterLinkDestBySite,
        custom,
      } = item;
      mappedItem = {
        name,
        order: index,
        type,
        icon: icon?.image?.id,
        list: list?.id,
        categories: categories,
        filterLinkDestBySite,
        custom,
      };
    }

    if (systemPanelActions) {
      delete mappedItem.custom;
      delete mappedItem.filterLinkDestBySite;

      // Why is this only the case with System Explore Panel?
      if (mappedItem.categoryId) mappedItem.categoryId = mappedItem.categoryId.map(
          (category) => category.id,
        );
    }

    return mappedItem;
  });

export const buildTableColumns = ({
                                    explorePanels,
                                    navigate,
                                    networkId,
                                    workspace,
                                  }) => [
  {
    Header: 'Site Explore Panel Name',
    accessor: 'name',
    width: 232,
    onClick: (data) => {
      const panel = explorePanels.filter((panel) => panel.id === data.id)[0];
      navigate(`/network/${networkId}/explore/${panel?.id}`);
    },
    isClickable: (data) => {
      const panel = explorePanels.filter((panel) => panel.id === data.id)[0];
      return panel?.workspace === workspace;
    },
    sticky: 'left',
  },
  {
    Header: 'Progress',
    accessor: 'status',
    width: 96,
  },
  {
    Header: 'Site',
    accessor: 'site',
    width: 232,
  },
  ...workspace === DRAFT_WORKSPACE
    ? [
      {
        Header: 'Workspace',
        accessor: 'workspace',
        width: 116,
      },
    ]
    : [],
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'SiteID',
    accessor: 'siteId',
    alwaysHidden: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    alwaysHidden: true,
  },
  {
    Header: 'Color',
    accessor: 'color',
    alwaysHidden: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    minWidth: 90,
    width: 90,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
    sticky: 'right',
  },
];

export const EXPLORE_PANEL_MODAL_CUSTOM_TAGS = {
  createButton: {
    'data-test': 'explorePanelsListPaneAddButton',
  },
  createInitialNetwork: {
    'data-test': 'explorePanelsListPaneCreateInitialNetwork',
  },
  createInitialSite: {
    'data-test': 'explorePanelsListPaneCreateInitialSite',
  },
  confirm: 'explorePanelsDeletePanelConfirm',
  cancel: 'explorePanelsDeletePanelCancel',
};
