import { colorWithAlpha } from 'helpers/color-util';
import _ from 'lodash';
import TypeaheadStyles from 'pages/typeaheads/containers/typeahead-styles';

const styles = (theme) => _.merge({}, TypeaheadStyles(theme), {
    toggleGroup: {
      boxShadow: 'none',
      background: 'inherit',
    },
    button: {
      height: '42px',
      minWidth: '85px',
      border: `1px solid ${theme.palette.grey[400]} !important`,
      borderRadius: '2px !important',
      marginRight: '20px',
      fontWeight: 600,
      color: theme.palette.grey[500],
      fontSize: '12px',
      '&:hover': {
        backgroundColor: colorWithAlpha(theme.palette.blue[100], 0.3),
      },
    },
    smallButton: {
      height: '32px',
      minWidth: '80px',
      border: `1px solid ${theme.palette.grey[400]} !important`,
      fontWeight: 500,
      borderRadius: '2px',
      marginRight: '10px',
      color: theme.palette.grey[500],
      fontSize: '10px',
      '&:hover': {
        backgroundColor: colorWithAlpha(theme.palette.blue[100], 0.3),
      },
    },
    label: {
      flexDirection: 'column',
      textTransform: 'capitalize',
    },
    selected: {
      backgroundColor: `${colorWithAlpha(
        theme.palette.blue[100],
        0.5,
      )} !important`,
      border: `1px solid ${theme.palette.blue[400]} !important`,
      '& > *': {
        color: theme.palette.blue[400],
      },
    },
    inputBaseRoot: {
      width: '363px',
    },
    textField: {
      marginTop: '4px',
      width: '420px',
    },
    title: {
      marginBottom: '9px',
      color: theme.palette.grey[600],
    },
    contentText: {
      marginBottom: '32px',
    },
    formControl: {
      width: '256px',
      marginTop: '16px',
    },
    chipGroup: {
      marginBottom: '12px',
    },
    chipRoot: {
      marginRight: '12px',
      fontSize: '14px',
      backgroundColor: 'initial',
      borderRadius: '4px 4px 4px 4px',
      border: `1px solid ${theme.palette.grey[350]}`,
      '&:focus': {
        backgroundColor: 'initial',
      },
      marginBottom: '8px',
    },
    chipIcon: {
      width: '18px',
      fill: colorWithAlpha(theme.palette.black, 0.38),
      float: 'right',
    },
    chipLabel: {
      paddingRight: '20px',
    },
    errorMessage: {
      fontSize: '0.75em',
      color: 'red',
      marginLeft: '8px',
    },
    buttonContainer: {
      marginTop: theme.spacing(6),
      '& div button:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  });

export default styles;
