const ColorPalette = {
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 3,
  tonalOffset: 0.2,
  primary: {
    light: '#619EFF',
    main: '#0D6AFF',
    dark: '#0845A6',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#E64F8B',
    main: '#C74478',
    dark: '#96345B',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#F24949',
    main: '#CC3E3E',
    dark: '#A13030',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#EB8E3B',
    main: '#CC7C33',
    dark: '#A66429',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#8853F2',
    main: '#6C42BF',
    dark: '#482C80',
    contrastText: '#FFFFFF',
  },
  success: {
    light: '#1F9956',
    main: '#1A8249',
    dark: '#146639',
    contrastText: '#FFFFFF',
  },
  gradient: {
    blue: {
      start: '#0A51C2',
      end: '#083F98',
    },
    green: {
      start: '#1D9051',
      end: '#1A8249',
    },
    orange: {
      start: '#EB8E3B',
      end: '#CC7C33',
    },
    pink: {
      start: '#E64F8B',
      end: '#C74478',
    },
    purple: {
      start: '#8853F2',
      end: '#6C42BF',
    },
    red: {
      start: '#F24949',
      end: '#CC3E3E',
    },
    table: {
      start: '#DEF9FF',
      end: '#E7F0FF',
    },
    white: {
      start: '#FFFFFF',
      end: '#F3F3F3',
    },
    yellow: {
      start: '#F2B249',
      end: '#D9972B',
    },
  },
  chart: {
    blue: '#0D6AFF',
    green: '#99b418',
    yellow: '#f2b249',
    red: '#f24949',
    purple: '#8853f2',
  },
  white: '#FFFFFF',
  black: '#000000',
  blue: {
    50: '#E7F0FF',
    100: '#CFE1FF',
    200: '#9EC3FF',
    300: '#619EFF',
    400: '#0D6AFF',
    500: '#0845A6',
    600: '#052A66',
  },
  grey: {
    50: '#F9F9F9',
    100: '#F3F3F3',
    200: '#EEEFEF',
    300: '#DFDFE0',
    350: '#C2C3C4',
    400: '#8F9193',
    500: '#707375',
    600: '#262B2E',
  },
  emerald: {
    200: '#99B418',
  },
  green: {
    50: '#DEF0E6',
    100: '#A5D6BB',
    200: '#78C29A',
    300: '#4BAD78',
    400: '#1F9956',
    500: '#1A8249',
    600: '#146639',
  },
  yellow: {
    50: '#FCEFDA',
    100: '#FAE0B6',
    200: '#F6CD88',
    300: '#F5C26D',
    400: '#F2B249',
    500: '#D9972B',
    600: '#B88025',
  },
  orange: {
    50: '#FBE8D7',
    100: '#F7D2B0',
    200: '#F3BB89',
    300: '#EFA562',
    400: '#EB8E3B',
    500: '#CC7C33',
    600: '#A66429',
  },
  red: {
    50: '#FDE3E3',
    100: '#FBC8C8',
    200: '#F79292',
    300: '#F56D6D',
    400: '#F24949',
    500: '#CC3E3E',
    600: '#A13030',
  },
  pink: {
    50: '#FBE3ED',
    100: '#F7C2D7',
    200: '#F2A7C5',
    300: '#EF8CB3',
    400: '#E64F8B',
    500: '#C74478',
    600: '#96345B',
  },
  purple: {
    50: '#EBE3FD',
    100: '#D8C7FB',
    200: '#BEA1F8',
    300: '#A67FF6',
    400: '#8853F2',
    500: '#6C42BF',
    600: '#482C80',
  },
  teal: {
    500: '#1DADC0',
  },
  transparentImageBackground:
    'linear-gradient(45deg, #C2C3C4 25%, transparent 25%, transparent 75%, #C2C3C4 75%, #C2C3C4 100%), linear-gradient(45deg, #C2C3C4 25%, white 25%, white 75%, #C2C3C4 75%, #C2C3C4 100%)',
  text: {
    primary: '#707375',
    secondary: '#707375',
    disabled: '#8F9193',
    hint: '#146639',
  },
};

export default ColorPalette;
