import { PhoneOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import Condition from 'components/forms/condition';
import GozioSelect from 'components/selector/selector';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import normalizePhone from 'helpers/normalizePhone';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

const LinkTypePhone = ({ linkOptions, isUberAdmin, icons }) => {
  const getIcon = useCallback(() => {
    const phoneIcon = icons.find((icon) => icon.type === 'phone');
    return phoneIcon?.image?.url ? (
      <img
        style={{ width: '50px', height: '50px' }}
        src={phoneIcon?.image?.url}
        alt="Phone Icon"
      />
    ) : (
      <PhoneOutlined color="primary" sx={{ fontSize: '50px' }} />
    );
  }, [icons]);

  return (
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <Field
            component={GozioSelect}
            name="linkType"
            label={getFormLabel('Quicklink Type', true)}
            options={linkOptions}
            isUberAdmin={isUberAdmin}
          />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body1"
            sx={{
              lineHeight: '1.1875rem',
              letterSpacing: '0',
              color: ColorPalette.grey[500],
              fontWeight: 400,
              display: 'inline',
              marginLeft: '18px',
              marginRight: '24px',
            }}
          >
            Icon That Appears on Mobile
          </Typography>
          {getIcon()}
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3} sx={{ marginTop: '28px' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '2px' }}>
            Phone Number Details
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: '8px' }}>
          <Field name="name">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel(' Name', true)}
                charLimit={25}
                data-test="ActionLinkName"
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Condition when="langES" is={true}>
          <Grid item xs={6}>
            <Field name="nameES">
              {({ input, meta }) => (
                <TextField
                  label={getFormLabel('Name', false, true)}
                  charLimit={25}
                  data-test="ActionLinkNameES"
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  {...input}
                  fullWidth
                />
              )}
            </Field>
          </Grid>
        </Condition>
        <Grid item xs={6} sx={{ marginBottom: '8px' }}>
          <Field name="shortName">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Short Name', true)}
                charLimit={10}
                data-test="ActionLinkShortName"
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Condition when="langES" is={true}>
          <Grid item xs={6}>
            <Field name="shortNameES">
              {({ input, meta }) => (
                <TextField
                  label={getFormLabel('Short Name', false, true)}
                  charLimit={10}
                  data-test="ActionLinkShortNameES"
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  {...input}
                  fullWidth
                />
              )}
            </Field>
          </Grid>
        </Condition>
        <Grid item xs={6}>
          <Field name="phone" parse={normalizePhone}>
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Phone Number', true)}
                helperText={meta.touched ? meta.error : ''}
                data-test="ActionLinkPhone"
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3} sx={{ marginTop: '28px' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '12px' }}>
            Phone Number Preferences
          </Typography>
          <Field name="showInDash" type="checkbox">
            {({ input, meta }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    sx={{ padding: '4px 4px 4px 8px' }}
                    {...input}
                  />
                }
                label="Show in Dashboard View"
              />
            )}
          </Field>

          <Typography
            variant="body1"
            sx={{
              marginLeft: '25px',
              fontWeight: 400,
              lineHeight: '1.4375 rem',
              color: ColorPalette.grey[500],
            }}
          >
            The link will be shown as an action when it is used in a database
            card.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

LinkTypePhone.propTypes = {
  linkOptions: PropTypes.array.isRequired,
  isUberAdmin: PropTypes.bool,
  icons: PropTypes.array,
};

LinkTypePhone.defaultProps = {
  isUberAdmin: false,
  icons: [],
};

export default LinkTypePhone;
