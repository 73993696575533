import { Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

const buildStyles = ({ theme }) => ({
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  itemWrap: {
    marginBottom: '20px',
  },
  item: {
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '0.875rem',
    paddingLeft: '8px',
    borderLeft: '3px solid transparent',
    borderRadius: '1px',
    boxSizing: 'border-box',
    color: theme.palette.grey[500],
    opacity: 0.66,
    lineHeight: '24px',
    '&:hover': {
      color: theme.palette.grey[600],
      fontWeight: 700,
      opacity: 1,
    },
    '&$active,&:active': {},
  },
  active: {
    borderLeftColor: theme.palette.blue[400],
    color: theme.palette.black,
    fontSize: '14px',
    fontWeight: 700,
    opacity: 1,
  },
});

const getItemsClient = (headings) => {
  const itemsWithNode = [];

  headings.forEach((item) => {
    itemsWithNode.push({
      ...item,
      node: document.getElementById(item.hash),
    });
  });
  return itemsWithNode;
};

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = useMemo(
    () => callback ? throttle(callback, delay) : null,
    [callback, delay],
  );

  useEffect(() => {
    if (!throttledCallback) {
      return undefined;
    }

    document
      .getElementById('detail-scroll')
      .addEventListener('scroll', throttledCallback);
    return () => {
      document
        .getElementById('detail-scroll')
        ?.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
};

const DetailTableOfContents = ({
                                 items,
                                 isUberAdmin,
                                 data,
                                 activeState,
                                 setActiveState,
                               }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const itemsWithNodeRef = useRef([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef(null);

  const findActiveIndex = useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) {
      return;
    }

    const scrollElement = document.getElementById('detail-scroll');
    const topScrollActivator = scrollElement?.clientHeight / 5;
    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      const item = itemsWithNodeRef.current[i];
      if (item.node) {
        // if close to top, then set active...mostly done for smaller first sections
        if (
          (i === 0
            && scrollElement.scrollTop
            <= itemsWithNodeRef.current[0].node.offsetTop + 75)
          || (i > 0
            && scrollElement.scrollTop
            > itemsWithNodeRef.current[0].node.offsetTop + 75
            && item.node.offsetTop < scrollElement.scrollTop + topScrollActivator)
        ) {
          active = item;
          break;
        }
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash) => (event) => {
    // Ignore click for new tab/new window behavior
    if (
      event.defaultPrevented
      || event.button !== 0 // ignore everything but left-click
      || event.metaKey
      || event.ctrlKey
      || event.altKey
      || event.shiftKey
    ) {
      return;
    }

    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);
    }

    document.getElementById(hash).scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    [],
  );

  const itemLink = (item) => (
    <Link
      display="block"
      underline="none"
      data-test={`LocationTOCOption-${item.hash}`}
      onClick={handleClick(item.hash)}
      sx={{
        ...styles.item,
        ...(activeState || items[0].hash) === item.hash && styles.active,
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: item.text }} />
    </Link>
  );

  return (
    <nav>
      {items?.length > 0 ? (
        <Typography component="ul" sx={styles.ul}>
          {items
            .map((item) => {
              if (
                !item.conditional
                || item.conditional({ hasPermissions: isUberAdmin, data })
              ) {
                return (
                  <li key={item.hash} style={styles.itemWrap}>
                    {itemLink(item)}
                  </li>
                );
              }
              return null;
            })
            .filter((notFalse) => notFalse)}
        </Typography>
      ) : null}
    </nav>
  );
};

DetailTableOfContents.propTypes = {
  activeState: PropTypes.string,
  items: PropTypes.array.isRequired,
  data: PropTypes.object,
  isUberAdmin: PropTypes.bool,
  setActiveState: PropTypes.func.isRequired,
};

DetailTableOfContents.defaultProps = {
  activeState: null,
};

export default React.memo(DetailTableOfContents);
