import { useQuery } from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';

import {
  GET_NETWORK_THEME_COLORS,
} from 'graphql/queries';

export const useConfigColors = (networkId) => {
  const { handleError } = useHandleError('Navigation');
  const isGlobal = isGlobalTemplate(networkId);

  const { data, error } = useQuery(GET_NETWORK_THEME_COLORS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: isGlobal,
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  if (isGlobal) {
    return {
      data: [],
      getColors: () => {},
      loading: false,
    };
  }

  return {
    data: data?.getNetwork?.theme?.colors || [],
    error,
  };
};
