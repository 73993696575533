import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from 'components/dashboard/cardComponent/card';
import { dndHover, isCardIncompleteForPackaging } from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  card: {
    marginBottom: theme.spacing(1),
    transform: 'translate3d(0, 0, 0)',
    maxHeight: '288px',
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const TwoCardLayout = ({
                         id,
                         index,
                         card1,
                         card2,
                         preview,
                         hovered,
                         moveCard,
                         onCardClick,
                         onDrag,
                         onHover,
                         canDragAndDrop,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
        card2,
      },
      cardType: TwoCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? '2-card layout' : ''}>
      <Grid
        container
        direction="row"
        spacing={1}
        ref={ref}
        sx={{
          ...styles.card,
          ...hovered && styles.fade,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs={6}>
          <Card
            size="medium"
            title={card1.title}
            subtitle={card1.subtitle}
            overline={card1.overline}
            backgroundImageUrl={card1.backgroundImageUrl}
            backgroundColor={card1.backgroundColor}
            textColor={card1.textColor}
            clickHandler={() => onCardClick({
                cardGroupId: id,
                key: 'card1',
                size: 'small',
                ...card1,
              })
            }
            iconUrl={card1.iconUrl}
            incompleteForPackaging={isCardIncompleteForPackaging(card1)}
            disabled={card1.disabled}
            selected={card1.selected}
            standalone={false}
          />
        </Grid>
        <Grid item xs={6}>
          <Card
            size="medium"
            title={card2.title}
            subtitle={card2.subtitle}
            overline={card2.overline}
            backgroundImageUrl={card2.backgroundImageUrl}
            backgroundColor={card2.backgroundColor}
            textColor={card2.textColor}
            clickHandler={() => onCardClick({
                cardGroupId: id,
                key: 'card2',
                size: 'small',
                ...card2,
              })
            }
            iconUrl={card2.iconUrl}
            incompleteForPackaging={isCardIncompleteForPackaging(card2)}
            disabled={card2.disabled}
            selected={card2.selected}
            standalone={false}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

TwoCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card2: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

TwoCardLayout.defaultProps = {
  preview: false,
  card1: {
    incompleteForPackaging: false,
    title: 'Card title 1',
    clickHandler: () => {},
  },
  card2: {
    incompleteForPackaging: false,
    title: 'Card title 2',
    clickHandler: () => {},
  },
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default TwoCardLayout;
