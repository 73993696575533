import { ApolloClient, ApolloProvider, from, InMemoryCache, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError as onLinkError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { createClient } from 'graphql-ws';
import { getConfig } from 'helpers/config-util';
import { WORKSPACE_STORAGE_KEY } from 'helpers/workspace-util';
import useLogout from 'hooks/useLogout';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const devTools = (getConfig().apolloDevTools || process.env.NODE_ENV === 'development') ?? false;

const hasSubscriptionOperation = ({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition'
    && definition.operation === 'subscription'
  );
};

const AuthorizedApolloProvider = ({ onError, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const logout = useLogout();

  const getTokenOrLogin = async () => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      logout();
    }
    return token;
  };

  /**
   * add the authorization to the headers
   * https://www.apollographql.com/docs/react/networking/network-layer/#middleware
   * https://www.apollographql.com/docs/link/links/context/
   */

    // console.info('[Apollo] NetworkID:', activeNetworkID, workspace);

  const authMiddleware = setContext(async (_, { headers, ...context }) => {
      const token = await getTokenOrLogin();
      if (typeof Storage !== 'undefined') {
        localStorage.setItem('token', token);
      }
      return {
        ...context,
        headers: {
          ...headers,
          ...token ? { Authorization: `Bearer ${token}` } : {},
          lang: headers?.lang || '*',
          network: headers?.network || window.location.pathname.split('/')[2],
          workspace: window.localStorage.getItem(WORKSPACE_STORAGE_KEY),
        },
      };
    });

  /**
   * Adding fix to improve logRocket recording
   * https://docs.logrocket.com/docs/troubleshooting-sessions#apollo-client
   */

  const connectionParams = async () => {
    const token = await getTokenOrLogin();
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('token', token);
    }
    return { authToken: token ? `Bearer ${token}` : '' };
  };

  const wsLink = from([
    onLinkError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          LogRocket.captureException(error, {
            tags: { type: 'graphQLError', Location: error.locations },
          });
          console.error(`[GraphQL error]: Message: ${error.message}`, error);

          if (error.statusCode !== 400 && !error.extensions?.debug?.message) {
            onError(error);
          }
        });
      }
      if (networkError) {
        LogRocket.captureException(networkError, {
          tags: { type: 'networkError' },
        });
        console.error('[GraphQL Network error]:', networkError);
        if (networkError.toString().includes('Login required')) {
          logout();
        }
        if (networkError.statusCode === 401) {
          logout();
        }
        onError(networkError);
      }
    }),
    // new WebSocketLink({
    //   uri: process.env.REACT_APP_GRAPH_WS_URL,
    //   options: { reconnect: true, connectionParams },
    // }),
    new GraphQLWsLink(
      /**
       * To see all available options:
       * https://the-guild.dev/graphql/ws/docs/interfaces/client.ClientOptions
       */
      createClient({
        url: process.env.REACT_APP_GRAPH_WS_URL,
        connectionParams,
      })
    ),
  ]);

  const httpLink = from([
    onLinkError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          LogRocket.captureException(error, {
            tags: { type: 'graphQLError', Location: error.locations },
          });
          console.error(`[GraphQL error]: Message: ${error.message}`, error);

          if (error.statusCode !== 400 && !error.extensions?.debug?.message) {
            onError(error);
          }
        });
      }
      if (networkError) {
        LogRocket.captureException(networkError, {
          tags: { type: 'networkError' },
        });
        console.error('[GraphQL Network error]:', networkError);
        if (networkError.toString().includes('Login required')) {
          logout();
        }
        if (networkError.statusCode === 401) {
          logout();
        }
        onError(networkError);
      }
    }),
    authMiddleware,
    new RetryLink(),
    new BatchHttpLink({
      uri: process.env.REACT_APP_GRAPH_API_URL,
    }),
  ]);

  const client = useMemo(() => {
    if (httpLink && wsLink) {
      return new ApolloClient({
        link: split(hasSubscriptionOperation, wsLink, httpLink),
        cache: new InMemoryCache({
          typePolicies: {
            System: {
              merge: true,
            },
            SystemExplorePanelAction: {
              fields: {
                icon: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                categories: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkCuratedListSection: {
              fields: {
                locations: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkDashboardCard: {
              fields: {
                quickLinksData: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkExplorePanelAction: {
              fields: {
                categories: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkNavigation: {
              fields: {
                items: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkPlace: {
              fields: {
                buildings: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                descendants: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                geoLocation: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                indoorLocation: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                media: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                navigation: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            NetworkPlaceConnection: {
              fields: {
                descendants: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
          },
        }),
        connectToDevTools: devTools,
        name: 'Flamingo',
        version: '1.0',
      });
    }
  }, [httpLink, wsLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
};

AuthorizedApolloProvider.defaultProps = {
  onError: () => {},
};

export default AuthorizedApolloProvider;
