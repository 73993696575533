import { httpRequest } from 'helpers/http-util';
import useLogout from 'hooks/useLogout';
import useToast from 'hooks/useToast';
import { useCallback, useState } from 'react';

export const useAnalytics = ({
                               endpoint = 'newusers',
                               networkId,
                               points,
                               detailed = false,
                               historical = false,
                             }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toastNotificationErrorHook } = useToast();
  const logout = useLogout();

  const fetchData = useCallback(
    async (params) => {
      setData(null);
      setLoading(true);
      const { start, end, points } = params;
      const fetchAnalytics = async () => {
        const response = await httpRequest({
          url: `${
            process.env.REACT_APP_ANALYTICS_URL
          }/${endpoint}?networkId=${networkId}&start=${start}&end=${end}${
            points ? `&points=${points}` : ``
          }${historical ? '&historical=true' : ''}${
            detailed ? '&detailed=true' : ''
          }`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          logout,
        });
        if (response.status === 200) {
          setData(await response.json());
        } else {
          toastNotificationErrorHook('Unable to fetch analytics data.');
        }
      };

      if (!loading) {
        try {
          await fetchAnalytics();
        } catch (err) {
          console.error(err);
          toastNotificationErrorHook('Unable to fetch analytics data.');
        }
        setLoading(false);
      }
    },
    [
      endpoint,
      detailed,
      historical,
      loading,
      logout,
      networkId,
      toastNotificationErrorHook,
    ],
  );

  return {
    data: loading ? null : data,
    fetch: fetchData,
  };
};
