import { Typography } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import { getLabelByLang } from 'helpers/lang-util';
import { useDeleteListSection } from 'hooks/dataHooks/useLists';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = () => ({
	root: {
		'& .MuiPaper-root.MuiDialog-paper': {
			width: '660px',
			'& .MuiDialogContent-root': {
				width: '100%',
			},
		},
	},
});

const DeleteListSectionModal = ({ handleClose, handleDeleted, listId, section }) => {
	const styles = buildStyles();
	const { toastNotificationSuccessHook } = useToast();

	const [deleteListSection] = useDeleteListSection(listId);

	const handleDeleteListSection = async () => {
		await deleteListSection({ variables: { id: section.id } });
		toastNotificationSuccessHook(`Section “${getLabelByLang(section.name)}” has been deleted.`);
		handleDeleted();
	};

	return (
		<GenericModal
			sx={styles.root}
			title="Delete List Section"
			body={[
				<Typography key="del-list-sec" variant="subtitle1">
					Deleting a List’s Section will delete the section name and all location references it
					contains. Are you sure you want to delete the List’s Section, “
					{getLabelByLang(section.name)}”?
				</Typography>,
			]}
			cancelText="CANCEL"
			confirmText="DELETE SECTION"
			handleConfirm={handleDeleteListSection}
			handleClose={handleClose}
		/>
	);
};

DeleteListSectionModal.propTypes = {
  listId: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleDeleted: PropTypes.func.isRequired,
	section: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		),
	}).isRequired,
};

export default DeleteListSectionModal;
