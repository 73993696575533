import { Button, Grid, Typography } from '@mui/material';
import noResult from 'assets/locations_sites_noresults.png';
import PropTypes from 'prop-types';
import React from 'react';

function EmptyPanel({
                      large,
                      showImage,
                      title,
                      subtitle,
                      buttonLabel,
                      buttonClicked,
                      ButtonIcon,
                      buttonDisabled,
                      buttonShown,
                      sx,
                      ...rest
                    }) {
  return (
    <Grid
      sx={{ height: '100%', ...sx }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      {showImage && <img src={noResult} alt="Empty Icon" width={180} />}
      {title && (
        <Typography
          variant="h1"
          className="hero"
          sx={{
            textAlign: 'center',
            marginTop: '24px',
            maxWidth: large ? '100%' : '400px',
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: '8px',
            textAlign: 'center',
            maxWidth: large ? '55%' : '400px',
          }}
        >
          {subtitle}
        </Typography>
      )}
      {!!buttonLabel && !!buttonClicked && buttonShown && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: '15px 14px',
            marginTop: '24px',
          }}
          disabled={buttonDisabled}
          onClick={buttonClicked}
          startIcon={ButtonIcon ? <ButtonIcon /> : null}
        >
          {buttonLabel}
        </Button>
      )}
    </Grid>
  );
}

EmptyPanel.propTypes = {
  large: PropTypes.bool,
  showImage: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLabel: PropTypes.string,
  buttonClicked: PropTypes.func,
  ButtonIcon: PropTypes.object,
  buttonShown: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  sx: PropTypes.object,
};

EmptyPanel.defaultProps = {
  large: false,
  showImage: true,
  ButtonIcon: null,
  buttonShown: false,
  buttonDisabled: false,
  sx: {},
};

export default EmptyPanel;
