import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioSelect from 'components/selector/selector';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DEFAULT_ANALYTICS_FILTER, persistAnalyticsFilter } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { isFutureDate, normalizeDate, STANDARD_DATE_FORMAT, STANDARD_DATE_REGEX } from 'helpers/date-util';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

dayjs.extend(duration);

window.dayjs = dayjs;

export const ANALYTICS_FILTER_MAP = Object.freeze({
  last_7_days: 'Last 7 Days',
  month_to_date: 'Month to Date',
  last_30_days: 'Last 30 Days',
  last_6_months: 'Last 6 Months',
  last_year: 'Last Year',
  project_lifetime: 'Project Lifetime',
  custom: 'Custom Date Range',
});

Yup.addMethod(
  Yup.string,
  'validCustomDateRange',
  function(errorMessage, isStartDate) {
    return this.test('valid-date-format', errorMessage, function(str) {
      const { path, createError, parent } = this;
      const { startDate, endDate } = parent;

      const date = dayjs(str, STANDARD_DATE_FORMAT, true);
      if (!(date.isValid() && str.match(STANDARD_DATE_REGEX))) {
        return createError({
          path,
          message: date ? errorMessage : 'Date is required',
        });
      }

      if (isFutureDate(date.toDate())) {
        return createError({
          path,
          message: 'Date is in the future',
        });
      }

      if (
        startDate
        && endDate
        && !validateCustomDateRange({ startDate, endDate })
      ) {
        if (path === 'startDate') {
          return createError({
            path,
            message: 'Invalid date range',
          });
        }
      }

      return true;
    });
  },
);

const validateCustomDateRange = ({ startDate, endDate }) => {
  if (startDate && !startDate.match(STANDARD_DATE_REGEX)) {
    return true;
  }

  const start = dayjs(startDate, STANDARD_DATE_FORMAT, true);
  if (!start.isValid()) {
    return true;
  }

  if (isFutureDate(start.toDate())) {
    return true;
  }

  if (endDate && !endDate.match(STANDARD_DATE_REGEX)) {
    return true;
  }

  const end = dayjs(endDate, STANDARD_DATE_FORMAT, true);
  if (!end.isValid()) {
    return true;
  }

  if (isFutureDate(end.toDate())) {
    return true;
  }

  if (Math.round((end - start) / (1000 * 60 * 60 * 24)) < 2) {
    // Minimum date range is 3 days (inclusive)
    return false;
  }

  return true;
};

const customDateRangeSchema = Yup.object().shape({
  startDate: Yup.string()
    .nullable()
    .validCustomDateRange('Format: MM/DD/YYYY', true),
  endDate: Yup.string()
    .nullable()
    .validCustomDateRange('Format: MM/DD/YYYY', false),
});

const buildStyles = ({ theme }) => ({
  root: {
    width: '212px',
  },
  modal: {
    '& .MuiDialog-paper': {
      width: '410px',

      '& form': {
        overflow: 'hidden',
      },
    },
  },
  modalContent: {
    marginBottom: '11px',
  },
  modalActions: {
    justifyContent: 'end !important',
  },
  dateRangeContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '12px',
  },
  dateFieldContainer: {
    width: '149px',
    height: '77px',

    '& .MuiFormHelperText-contained': {
      marginLeft: '8px',
      marginRight: '8px',
    },
  },
  rightArrowIcon: {
    margin: '-20px 12px 0 12px',
    width: '24px',
    height: '24px',

    '& svg': {
      fill: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
});

const isoDateFormat = 'YYYY-MM-DD';
const today = dayjs().format(isoDateFormat);

const AnalyticsFilters = ({ onChange, value, start, end }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const { activeNetwork } = useContext(FlamingoContext);

  const filterOptionsDetails = useMemo(() => ({
    last_7_days: {
      start: dayjs(today).subtract(1, 'week').format(isoDateFormat),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 7,
    },
    month_to_date: {
      start: dayjs().startOf('month').format(isoDateFormat),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 4,
    },
    last_30_days: {
      start: dayjs(today).subtract(30, 'days').format(isoDateFormat),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 4,
    },
    last_6_months: {
      start: dayjs(today).subtract(6, 'months').format(isoDateFormat),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 6,
    },
    last_year: {
      start: dayjs(today).subtract(1, 'year').format(isoDateFormat),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 12,
    },
    project_lifetime: {
      start: dayjs(activeNetwork.goLiveMobile).format('YYYY-MM-DD'),
      end: dayjs(today).subtract(1, 'day').format(isoDateFormat),
      points: 14,
    },
    custom: {
      start: null,
      end: null,
      points: 14,
    },
  }), [activeNetwork]);

  const validate = makeValidate(customDateRangeSchema);

  const [currentDaysAgo, setCurrentDaysAgo] = useState(value);

  useEffect(() => {
    onChange({
      value: currentDaysAgo,
      ...filterOptionsDetails[currentDaysAgo],
      ...start && { start },
      ...end && { end },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.root}>
      <GozioSelect
        fullWidth
        fullHeight
        input={{
          value: currentDaysAgo,
          onChange: (value) => {
            if (value === 'custom') {
              setShowCustomDateRange(true);
            } else {
              const { start, end, points } = filterOptionsDetails[value];
              setCurrentDaysAgo(value);
              persistAnalyticsFilter(value, start, end);
              onChange({ start, end, points, value });
            }
          },
          onClick: (value) => {
            if (currentDaysAgo === 'custom' && value === 0) {
              setShowCustomDateRange(true);
            }
          },
        }}
        label="Filter View By:"
        options={Object.keys(ANALYTICS_FILTER_MAP).map((key) => ({
          id: key,
          label: ANALYTICS_FILTER_MAP[key],
        }))}
      />
      {showCustomDateRange && (
        <GenericFormModal
          title="Custom Date Range"
          open={true}
          sx={styles.modal}
          contentSx={styles.modalContent}
          actionSx={styles.modalActions}
          formParams={{
            onSubmit: ({ startDate, endDate }) => {
              setShowCustomDateRange(false);
              setCurrentDaysAgo('custom');
              const start = dayjs(startDate).format(isoDateFormat);
              const end = dayjs(endDate).format(isoDateFormat);
              persistAnalyticsFilter('custom', start, end);
              onChange({
                value: 'custom',
                start,
                end,
                points: filterOptionsDetails.custom.points,
              });
            },
            validate,
            initialValues: {
              startDate: null,
              endDate: null,
            },
          }}
          confirmText="SHOW RESULTS"
          disableCancel={true}
          handleClose={() => setShowCustomDateRange(false)}
          body={() => (
            <Grid container>
              <Grid item xs={12} sx={styles.dateRangeContainer}>
                <Box sx={styles.dateFieldContainer}>
                  <Field
                    name="startDate"
                    parse={normalizeDate}
                    format={normalizeDate}
                  >
                    {({ input: { value, ...rest } }) => (
                      <TextField
                        label="From"
                        variant="outlined"
                        fullWidth
                        placeholder="MM/DD/YYYY"
                        {...rest}
                        value={value || ''}
                      />
                    )}
                  </Field>
                </Box>
                <Box sx={styles.rightArrowIcon}>
                  <ArrowRightAlt />
                </Box>
                <Box sx={styles.dateFieldContainer}>
                  <Field
                    name="endDate"
                    parse={normalizeDate}
                    format={normalizeDate}
                  >
                    {({ input: { value, ...rest } }) => (
                      <TextField
                        label="To"
                        variant="outlined"
                        fullWidth
                        placeholder="MM/DD/YYYY"
                        {...rest}
                        value={value || ''}
                      />
                    )}
                  </Field>
                </Box>
              </Grid>
            </Grid>
          )}
        />
      )}
    </Box>
  );
};

AnalyticsFilters.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
};

AnalyticsFilters.defaultProps = {
  onChange: () => {},
  value: DEFAULT_ANALYTICS_FILTER,
  start: null,
  end: null,
};

export default React.memo(AnalyticsFilters);
