import { AddPhotoAlternate } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = () => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-6px',
    textAlign: 'center',
    width: '66px',
  },
  image: {
    borderRadius: '50%',
  },
  placeholderIcon: {
    borderRadius: '50%',
    margin: '0 auto',

    '& svg': {
      position: 'relative',
      top: '6px',
    },
  },
  text: {
    fontSize: '12px',
  },
});

const PanelPreviewLink = ({ backgroundColor, name, icon, isMiniIcon }) => {
  const styles = buildStyles();
  const dimensionStyle = {
    height: `${isMiniIcon ? 40 : 60}px`,
    width: `${isMiniIcon ? 40 : 60}px`,
  };

  return (
    <Box
      sx={styles.root}
    >
      {icon?.url ? (
        <Box sx={{ ...styles.image, ...dimensionStyle, background: backgroundColor }} component="img" src={icon?.url}
             alt="icon" />
      ) : (
        <Box
          sx={{
            ...styles.placeholderIcon,
            ...dimensionStyle,
            background: backgroundColor || ColorPalette.grey[350],

            '& svg': {
              ...dimensionStyle,
            },
          }}
        >
          <AddPhotoAlternate />
        </Box>
      )}
      <Typography sx={styles.text} variant="body2">
        {getLabelByLang(name)}
      </Typography>
    </Box>
  );
};

PanelPreviewLink.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  icon: PropTypes.shape({
    url: PropTypes.string,
  }),
  isMiniIcon: PropTypes.bool,
};

PanelPreviewLink.defaultProps = {
  name: [],
  icon: {},
  isMiniIcon: false,
};

export default PanelPreviewLink;
