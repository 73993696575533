import { Add, Delete } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import { section } from 'components/authorization';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GenericModal from 'components/genericModal/genericModal';
import Notifier from 'components/notifier/notifier';
import GozioTable from 'components/tables/gozioTable';
import { capitalize } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { checkVisibility } from 'helpers/permissions-util';
import { getTableCountTitle } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import _ from 'lodash';
import {
  deleteNetworkTypeahead,
  deleteSystemTypeahead,
  readNetworkTypeahead,
  readSystemTypeahead,
  resetTypeaheadFlash,
} from 'modules/typeahead';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import TypeaheadStyles from 'pages/typeaheads/containers/typeahead-styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class Typeaheads extends Component {
  constructor(props) {
    super(props);
    this.handleAddTypeAhead = this.handleAddTypeAhead.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.prepareTableData = this.prepareTableData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDeleteTypeahead = this.handleDeleteTypeahead.bind(this);
    this.state = {};
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
  }

  componentDidMount() {
    const { networkId } = this.props;

    // openContent();
    readNetworkTypeahead({
      networkId,
    });

    readSystemTypeahead({
      networkId,
    });
  }

  componentDidUpdate(prevProps) {
    const { readNetworkTypeahead, readSystemTypeahead, networkId } = this.props;

    if (networkId !== prevProps.networkId) {
      readNetworkTypeahead({
        networkId,
      });

      readSystemTypeahead({
        networkId,
      });
    }
  }

  handleEdit = (id) => (e) => {
    e.preventDefault();
    const { navigate, networkId } = this.props;
    const url = `/network/${networkId}/typeahead/${id}/edit`;
    navigate(url);
  };

  openDeleteModal = (id, name) => (e) => {
    this.setState({
      deleteModalOpen: true,
      typeaheadName: name,
      typeaheadId: id,
    });
  };

  closeDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      typeaheadId: null,
      typeaheadName: null,
    });
  }

  handleAddTypeAhead() {
    const { navigate, networkId } = this.props;
    navigate(`/network/${networkId}/typeahead-wizard`);
  }

  handleDeleteTypeahead() {
    const {
      deleteNetworkTypeahead,
      deleteSystemTypeahead,
      networkId,
      typeahead,
    } = this.props;
    const { typeaheadId } = this.state;
    let action = null;
    if (isGlobalTemplate(networkId)) {
      action = deleteSystemTypeahead;
    } else {
      action = deleteNetworkTypeahead;
    }
    action({ id: typeaheadId, networkId }).then(() => {
      if (!_.get(typeahead, 'flash.error', null)) {
        this.closeDeleteModal();
      }
    });
  }

  prepareTableData(typeaheads, isNetwork) {
    const { networkId } = this.props;
    const isGlobal = isGlobalTemplate(networkId);
    return typeaheads
      .filter(
        (typeahead) => !isNetwork
          || (typeahead.network && typeahead.network.id === networkId),
      )
      .map((typeahead) => ({
        id: typeahead.id,
        typeaheadLabel: typeahead.name.en,
        linkDestination: capitalize(typeahead.actionType),
        matchBy: typeahead.matchBy === 'tag' ? 'Tags' : 'Numbers',
        actions: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!isNetwork && !isGlobal && (
              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                Managed By Gozio
              </Typography>
            )}
            <Box className="hoverUnhide">
              {isNetwork ? (
                <Delete
                  onClick={this.openDeleteModal(
                    typeahead.id,
                    typeahead.name.en,
                  )}
                  sx={{ marginLeft: '8px' }}
                  {...{ 'data-test': 'delete-button' }}
                />
              ) : isGlobal ? (
                <Delete
                  onClick={this.openDeleteModal(
                    typeahead.id,
                    typeahead.name.en,
                  )}
                  sx={{ marginLeft: '8px' }}
                  {...{ 'data-test': 'delete-button' }}
                />
              ) : null}
            </Box>
          </Box>
        ),
        customTags: {
          row: {
            'data-test': `row-${typeahead.id}`,
          },
          cells: {
            typeaheadLabel: {
              'data-test': `cell-typeaheadLabel-${typeahead.id}`,
            },
          },
        },
      }));
  }

  getData(systemTypeaheads, networkTypeaheads, isNetwork) {
    return this.prepareTableData(systemTypeaheads, false).concat(
      this.prepareTableData(networkTypeaheads, true),
    );
  }

  getColumns(navigate, networkId) {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        alwaysHidden: true,
      },
      {
        Header: 'Typeahead label',
        accessor: 'typeaheadLabel',
        onClick: (data) => {
          navigate(`/network/${networkId}/typeahead/${data.id}/edit`);
        },
      },
      {
        Header: 'Quicklink',
        accessor: 'linkDestination',
      },
      {
        Header: 'Match by',
        accessor: 'matchBy',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        minWidth: 140,
        width: 140,
        disableSortBy: true,
        justifyRight: true,
      },
    ];
  }

  renderTable(systemTypeaheads, networkTypeaheads, loading) {
    const { navigate, networkId } = this.props;
    const data = this.getData(systemTypeaheads, networkTypeaheads);
    const count = data?.length || 0;

    return (
      <Box sx={{ height: '100%', paddingTop: '8px' }}>
        <GozioTable
          name="ColorsTable"
          columns={this.getColumns(navigate, networkId)}
          data={data}
          loading={loading}
          sortBy={[{ id: 'typeaheadLabel', desc: false }]}
          countTitle={getTableCountTitle(count, 'Typeahead$')}
        />
      </Box>
    );
  }

  render() {
    const {
      typeahead: {
        flash,
        page: {
          docs: { system: systemTypeaheads, network: networkTypeaheads },
        },
        networkLoading,
        systemLoading,
      },
      resetTypeaheadFlash,
      permissions,
      classes,
    } = this.props;
    const { deleteModalOpen, typeaheadName } = this.state;

    const networkAddVisibility = checkVisibility({
      permissions,
      type: 'System',
      section: section.TYPEAHEAD,
      allowed: [permissions.CREATE],
    });

    const systemAddVisibility = checkVisibility({
      permissions,
      type: 'System',
      section: section.TYPEAHEAD,
      allowed: [permissions.CREATE],
    });
    const loading = networkLoading || systemLoading;
    return (
      <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
        <FlamingoPage
          pageName="Typeahead"
          headerButtons={
            <AddCircleButton
              onClick={this.handleAddTypeAhead}
              {...{ 'data-test': 'add-button' }}
            />
          }
        >
          <Notifier
            flash={flash}
            clearFlash={() => {
              resetTypeaheadFlash();
            }}
          />
          {loading || systemTypeaheads.length + networkTypeaheads.length > 0 ? (
            <Box className={classes.content}>
              {this.renderTable(systemTypeaheads, networkTypeaheads, loading)}
            </Box>
          ) : (
            <Box className={classes.emptyContainer}>
              `{' '}
              <EmptyPanel
                title="There Are No Typeaheads Set Up"
                subtitle="Start by creating a Typeahead."
                buttonLabel="Add a Typeahead"
                ButtonIcon={Add}
                buttonShown={networkAddVisibility || systemAddVisibility}
                buttonClicked={() => {
                  this.handleAddTypeAhead();
                }}
              />
              `{' '}
            </Box>
          )}
          {deleteModalOpen && (
            <GenericModal
              title="Delete this typeahead?"
              body={`Are you sure you want to delete "${typeaheadName}"?`}
              open={deleteModalOpen}
              handleClose={() => {
                this.setState({ deleteModalOpen: false });
              }}
              handleConfirm={this.handleDeleteTypeahead}
              customTags={{
                confirm: 'TypeaheadDeleteModalConfirmButton',
                cancel: 'TypeaheadDeleteModalCancelButton',
              }}
            />
          )}
        </FlamingoPage>
      </LoggedinLayout>
    );
  }
}

const mapStateToProps = ({ flamingo, typeahead }) => ({
    typeahead,
    networkId: window.location.pathname.split('/')[2],
    permissions: flamingo?.auth0?.user?.permissions || [],
    navigate: (url) => {
      window.location.href = url;
    },
  });

const mapDispatchToProps = {
  deleteNetworkTypeahead: (params) => deleteNetworkTypeahead(params),
  deleteSystemTypeahead: (params) => deleteSystemTypeahead(params),
  // openContent: (params) => openContent(params),
  readNetworkTypeahead: (params) => readNetworkTypeahead(params),
  readSystemTypeahead: (params) => readSystemTypeahead(params),
  resetTypeaheadFlash: (params) => resetTypeaheadFlash(params),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(TypeaheadStyles),
)(Typeaheads);
