import { Box, Checkbox } from '@mui/material';
import React from 'react';

export const processHeaderProps = ({ style, ...restProps }, headerColumn) => {
  const updatedStyle = Object.assign(
    { maxWidth: headerColumn.maxWidth },
    style,
  );
  return { headerSx: updatedStyle, ...restProps };
};

export const processCellProps = (
  { style, ...restProps },
  headerColumn,
  data,
) => {
  const updatedStyle = Object.assign(
    { maxWidth: headerColumn.maxWidth },
    style,
  );

  if (
    headerColumn.onClick
    && (typeof headerColumn.isClickable === 'function'
      ? headerColumn.isClickable(data)
      : true)
  ) {
    restProps.onClick = () => headerColumn.onClick(data);
    updatedStyle.cursor = 'pointer';
  }
  return { style: updatedStyle, ...restProps };
};

export const HeaderCheckbox = ({ styles, data, handleRowSelectUpdates }) => {
  const selectAll = !data.find((d) => !d.isSelected);
  return (
    <Checkbox
      color="primary"
      sx={styles.checkbox}
      onChange={(e) => {
        handleRowSelectUpdates(
          data.map((d) => ({
            id: d.id,
            name: d.name,
            checked: e.target.checked,
          })),
        );
      }}
      checked={selectAll}
    />
  );
};

export const RowCheckbox = ({ styles, original, handleRowSelectUpdates }) => (
  <Checkbox
    color="primary"
    sx={styles.checkbox}
    onChange={(e) => handleRowSelectUpdates([
        { id: original.id, name: original.name, checked: e.target.checked },
      ])
    }
    checked={original.isSelected}
  />
);

export const getSelectColumn = (styles) => ({
  id: 'selection',
  width: 28,
  minWidth: 28,
  maxWidth: 28,
  disableGlobalFilter: true,

  Header: ({ data, handleRowSelectUpdates }) => (
    <Box>
      <HeaderCheckbox
        styles={styles}
        data={data}
        handleRowSelectUpdates={handleRowSelectUpdates}
      />
    </Box>
  ),

  Cell: ({ row, handleRowSelectUpdates }) => (
    <Box>
      <RowCheckbox
        styles={styles}
        handleRowSelectUpdates={handleRowSelectUpdates}
        {...row}
      />
    </Box>
  ),
});
