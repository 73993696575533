import { Box, Grid, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import SpeedDialBackdrop from 'components/speedDial/speedDialBackdrop';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useFindNetworkPlacesByCategories } from 'hooks/dataHooks/useCategories';
import { useList, useUpdateListSection } from 'hooks/dataHooks/useLists';
import { useWorkspace } from 'hooks/useWorkspace';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddListLocationModal from 'pages/lists/containers/AddListLocationModal';
import AddOrEditListSectionModal from 'pages/lists/containers/AddOrEditListSectionModal';
import GeneralTabPanel from 'pages/lists/containers/GeneralTabPanel';
import LocationsTabPanel from 'pages/lists/containers/LocationsTabPanel';
import MoveLocationModal from 'pages/lists/containers/MoveLocationModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  buttonContainer: {
    width: theme.spacing(6),
  },
  content: {
    height: 'calc(100vh - 224px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
    marginTop: '4px',
  },
  generalTabContent: {
    height: 'calc(100% - 70px) !important',
    overflowY: 'auto',
  },
  innerTabPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3),
    '& > .subtitle': {
      marginBottom: theme.spacing(2.5),
    },
  },
  innerTabPanelContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    overflowY: 'hidden',
    position: 'relative',
  },
  tabDisabled: {
    color: theme.palette.grey[300],
    cursor: 'not-allowed',
  },
  tabBody: {
    height: '100%',
    width: '100%',
    padding: '24px',
  },
  sections: {
    marginTop: '24px',
  },
  footer: {
    background: theme.palette.white,
    bottom: 0,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: '0 -2px 4px 0 rgba(38, 43, 46, 0.4)',
    height: '74px',
    padding: '13px 24px',
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 100,
  },
  saveBtn: {
    bottom: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(3),
    width: '119px',
  },
});

const ListDetailPageContent = ({ networkId, listId }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { activeNetwork, handleNavigate } = useContext(FlamingoContext);
  const activeNetworkID = activeNetwork?.id;

  const { list } = useList(listId);
  const [updateListSection] = useUpdateListSection(listId);
  const [
    findNetworkPlacesByCategories,
    { data: locationData, loading: locationLoading },
  ] = useFindNetworkPlacesByCategories();

  const { workspace } = useWorkspace();

  const [canChangeTab, setCanChangeTab] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [showAddOrEditSectionModal, setShowAddOrEditSectionModal]
    = useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [showMoveLocationModal, setShowMoveLocationModal] = useState(false);
  const [selectedListSectionData, setSelectedListSectionData] = useState(null);
  const [selectedListSectionId, setSelectedListSectionId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchParam, setSearchParam] = useState(null);
  const [unavailableLocationIds, setUnavailableLocationIds] = useState([]);

  const backToLists = useCallback(() => {
    handleNavigate(`/network/${networkId}/lists`);
  }, [handleNavigate, networkId]);

  useEffect(() => {
    if (activeNetworkID && networkId && activeNetworkID !== networkId) {
      backToLists();
    }
  }, [activeNetworkID, backToLists, networkId]);

  useEffect(() => {
    const updateSection = async (variables) => {
      await updateListSection({
        variables,
      });
    };

    if (!locationLoading && locationData) {
      const categories = locationData?.findCategory?.edges
        .filter((category) => category.node.places.edges.length)
        .map(({ node }) => ({
          places: node.places.edges.map(({ node }) => ({
            id: node.id,
            label: getLabelByLang(node.name),
            designation: node.designation,
            workspaces: node.workspaces,
          })),
        }));
      let newLocationIds = [];
      categories.forEach(
        ({ places }) => (newLocationIds = newLocationIds.concat(
            places
              .filter(
                ({ designation, workspaces }) => workspaces[0].name === workspace
                  && ['site', 'building', 'poi'].includes(designation),
              )
              .map(({ id }) => id),
          )),
      );
      const sectionData = list?.sections.filter(
        ({ id }) => id === selectedListSectionId,
      );
      const currentSectionLocationIds = sectionData[0]?.locations.map(
        ({ id }) => id,
      );
      const combinedLocationIds = currentSectionLocationIds.concat(
        newLocationIds.filter((id) => !unavailableLocationIds.includes(id)),
      );
      updateSection({
        id: selectedListSectionId,
        input: {
          locations: combinedLocationIds,
        },
      });
    }
  }, [
    list,
    locationData,
    locationLoading,
    selectedListSectionId,
    unavailableLocationIds,
    updateListSection,
    workspace,
  ]);

  const hasLocations = useMemo(() => {
    if (!list?.sections?.length) {
      return false;
    }

    if (list.sections) {
      for (const section of list.sections) {
        if (section.locations?.length > 0) {
          return true;
        }
      }
    }

    return false;
  }, [list]);

  const headerButtons = useMemo(() => {
    const addLocationOption = {
      name: 'Location',
      onClick: () => setShowAddLocationModal(true),
    };
    const addSectionOption = {
      name: 'Section',
      onClick: () => setShowAddOrEditSectionModal(true),
    };

    // Locations tab
    if (currentTab === 0) {
      if (!list?.sections?.length) {
        return null;
      }
      if (!hasLocations) {
        return null;
      }
      if (workspace === DRAFT_WORKSPACE) {
        return (
          <AddCircleButton
            onClick={() => {
              setShowAddLocationModal(true);
            }}
            tooltipTitle="Add a Location"
          />
        );
      }
      return (
        <Box sx={styles.buttonContainer}>
          <SpeedDialBackdrop
            direction="down"
            actions={[addLocationOption, addSectionOption]}
          />
        </Box>
      );
    } else if (currentTab === 1) {
      // General Tab
      // N.B. The general tab is only available in the Live Workspace
      const options = [addSectionOption];
      if (list?.sections.length > 0) {
        options.push(addLocationOption);
      }
      return (
        <Box sx={styles.buttonContainer}>
          <SpeedDialBackdrop direction="down" actions={options} />
        </Box>
      );
    }
  }, [styles.buttonContainer, currentTab, hasLocations, list, workspace]);

  if (workspace === DRAFT_WORKSPACE && currentTab !== 0) setCurrentTab(0);

  const handleChangeTab = (e, idx) => {
    if (canChangeTab) {
      if (workspace === LIVE_WORKSPACE || idx === 0) {
        setCurrentTab(idx);
      }
    }
  };

  if (isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  return (
    <FlamingoPage
      pageName={getLabelByLang(list?.name)}
      headerButtons={headerButtons}
      handleBack={() => backToLists()}
      handleBackTitle="Back to Lists"
      onSearchChange={(searchString) => {
        if (searchString.length) setSearchParam(searchString);
        else setSearchParam(null);
      }}
    >
      <Box sx={styles.content}>
        <Grid sx={styles.takeoverTabs}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="List tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Locations"
              sx={{ ...!canChangeTab && styles.tabDisabled }}
              {...tabA11yProps('locations', 0)}
            />
            <Tab
              label="General"
              sx={{
                ...workspace === DRAFT_WORKSPACE && styles.tabDisabled,
              }}
              {...tabA11yProps('general', 1)}
            />
          </Tabs>
        </Grid>
        <LocationsTabPanel
          sx={styles}
          tabValue={currentTab}
          data={list}
          searchParam={searchParam}
          onAddLocationClicked={() => {
            setShowAddLocationModal(true);
          }}
          onMoveLocationClicked={(location) => {
            setShowMoveLocationModal(true);
            setSelectedLocation(location);
          }}
          onAddSectionClicked={() => setShowAddOrEditSectionModal(true)}
        />
        {workspace === LIVE_WORKSPACE && (
          <GeneralTabPanel
            sx={styles}
            list={list}
            tabValue={currentTab}
            onFormChanged={({ dirty, errors }) => setCanChangeTab(!dirty && Object.keys(errors).length === 0)
            }
            onListSectionClicked={(sectionData) => {
              setSelectedListSectionData(sectionData);
              setShowAddOrEditSectionModal(true);
            }}
            onListSectionDeleted={async () => {
              setSelectedListSectionData(null);
            }}
          />
        )}
      </Box>
      {showAddOrEditSectionModal && (
        <AddOrEditListSectionModal
          handleClose={() => {
            setSelectedListSectionData(null);
            setShowAddOrEditSectionModal(false);
          }}
          handleSaved={async () => {
            setSelectedListSectionData(null);
            setShowAddOrEditSectionModal(false);
          }}
          listId={listId}
          sectionData={selectedListSectionData}
        />
      )}
      {showAddLocationModal && (
        <AddListLocationModal
          handleClose={() => {
            setShowAddLocationModal(false);
          }}
          handleSaved={async (fields) => {
            const {
              categoryIds,
              locationId,
              sectionId,
              unavailableLocationIds: unavailableIds,
            } = fields;
            setUnavailableLocationIds(unavailableIds);
            setSelectedListSectionId(sectionId);
            setShowAddLocationModal(false);
            if (locationId) {
              const sectionData = list?.sections.filter(
                ({ id }) => id === sectionId,
              );
              const currentSectionLocationIds = sectionData[0]?.locations.map(
                ({ id }) => id,
              );
              await updateListSection({
                variables: {
                  id: sectionId,
                  input: {
                    locations: currentSectionLocationIds.concat(locationId),
                  },
                },
              });
            }
            if (categoryIds) {
              await findNetworkPlacesByCategories({
                variables: {
                  ids: categoryIds,
                },
              });
            }
          }}
          list={list}
        />
      )}
      {showMoveLocationModal && (
        <MoveLocationModal
          handleClose={() => {
            setShowMoveLocationModal(false);
            setSelectedLocation(null);
          }}
          handleSaved={async () => {
            setShowMoveLocationModal(false);
            setSelectedLocation(null);
          }}
          location={selectedLocation}
          list={list}
        />
      )}
      {<WorkspaceSwitcher />}
    </FlamingoPage>
  );
};

export const ListDetailPage = () => {
  const { networkId, listId } = useParams();
  return (
    <LoggedinLayout
      key={networkId}
      enableSuspense={true}
      showWorkspaceSwitcher={false}
    >
      <ListDetailPageContent networkId={networkId} listId={listId} />
    </LoggedinLayout>
  );
};

export default ListDetailPage;
