import { GetApp, Settings } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import Loading from 'components/loading/loading';
import GozioTable from 'components/tables/gozioTable';
import { colorWithAlpha } from 'helpers/color-util';
import { convertDateToString } from 'helpers/date-util';
import { capitalize, grammaticallyJoin } from 'helpers/lang-util';
import { getFullNameFromProfile } from 'helpers/string-util';
import { parseSortBy, PUBLISHING_HISTORY_TABLE_NAME, retrieveTablePageSize } from 'helpers/table-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useDataPackage, usePublishingEvents } from 'hooks/dataHooks/useDataPackage';
import { useNetworkPublishingControl } from 'hooks/dataHooks/useNetworkConfiguration';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { useWorkspace } from 'hooks/useWorkspace';
import { downloadPackage } from 'modules/publishing';
import ColorPalette from 'pages/gozio_colors';
import PublishFailureModal from 'pages/home/containers/PublishFailureModal';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import RevertModal from 'pages/publishing/containers/revertModal';
import { convertNetworkChannels, convertPackageEvent } from 'pages/publishing/publishingHelper';
import PublishingSettingsModal from 'pages/publishing/publishingSettingsModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  pageTitle: {
    '& > div:first-child': {
      width: '338px',
    },
  },
  headerContainer: {
    width: '100%',
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  settingsButton: {
    '& svg': {
      color: theme.palette.grey[500],
    },
  },
  revertButton: {
    marginTop: '4px',
  },
  content: { height: 'calc(100vh - 194px)', position: 'relative' },
  table: {
    borderRadius: '20px',
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(ColorPalette.grey[600], 0.4)}`,
    paddingTop: '24px',
  },
  actionIcon: {
    color: colorWithAlpha(theme.palette.black, 0.54),
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  actions: {
    position: 'relative',
  },
});

const sortOverride = {
  channels: 'networkChannels.label',
  date: 'updatedAt',
  description: 'message',
  packageNumber: 'externalId',
  status: 'state',
};

const sortOverrideFunction = ({ id, desc }) => {
  if (id === 'user') {
    const order = desc === true ? 'desc' : 'asc';
    return { createdBy: { profile: { firstName: order, lastName: order } } };
  }

  return null;
};

const PublishingHistoryPageContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const dispatch = useDispatch();
  const [showPublishFailureModal, setShowPublishFailureModal] = useState(false);
  const [selectedDataPackage, setSelectedDataPackage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { workspace } = useWorkspace();

  const { refetch: findDataPackage, data: findDataPackageData }
    = useDataPackage(networkId, 1);

  useEffect(() => {
    const dataPackage = findDataPackageData?.findDataPackage?.edges[0]?.node;
    if (dataPackage?.errorDetails?.length || dataPackage?.message?.toLowerCase() === 'timed out') {
      if (dataPackage.message?.toLowerCase() === 'timed out') {
        dataPackage.errorDetails = [{
          publishingError: {
            bundle: null,
            group: 'system',
            dataFacet: null,
          },
          error: {
            message: null,
          },
        }];
      }

      setSelectedDataPackage(dataPackage);
      const channelLabels = dataPackage.networkChannels.map(
        (channel) => channel.label,
      );
      const numErrors = dataPackage.errorDetails.length;
      const message = `When attempting to Publish to ${grammaticallyJoin(
        channelLabels,
      )} in the ${capitalize(workspace)} Workspace, there ${
        numErrors > 1 ? 'were' : 'was'
      } ${numErrors} ${numErrors > 1 ? 'errors' : 'error'} found.`;
      setErrorMessage(message);
    }
  }, [findDataPackageData, workspace]);

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      alwaysHidden: true,
    },
    {
      Header: 'Event ID',
      accessor: 'eventId',
      alwaysHidden: true,
    },
    {
      Header: 'Publish #',
      accessor: 'packageNumber',
      minWidth: 78,
      width: 78,
      onClick: (data) => {
        if (data?.packageNumber !== selectedDataPackage?.externalId) {
          setSelectedDataPackage(null);
          findDataPackage({
            id: data.id,
            workspaces: [DRAFT_WORKSPACE, LIVE_WORKSPACE],
          });
        }
        setShowPublishFailureModal(true);
      },
      isClickable: (data) => data.status.toLowerCase() === 'failed',
    },
    {
      Header: 'Condition',
      accessor: 'status',
      minWidth: 80,
      width: 80,
    },
    {
      Header: <span>Published&nbsp;Channels</span>,
      accessor: 'channels',
      disableSortBy: true,
      minWidth: 130,
      width: 130,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      minWidth: 150,
      width: 150,
    },
    {
      Header: 'User',
      accessor: 'user',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ cell }) => convertDateToString(cell.value),
      minWidth: 95,
      width: 95,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      minWidth: 55,
      width: 55,
      disableSortBy: true,
      justifyRight: true,
    },
  ];

  const isUberAdmin = useCheckGozioAdmin();

  const { data: publishingControl, refetch: refetchPublishingControl }
    = useNetworkPublishingControl(networkId);

  const {
    findPublishingEvents,
    loading: publishingEventsLoading,
    count,
    data: publishingEvents,
    refetch: refetchPublishingEvents,
  } = usePublishingEvents();

  const [showPublishingSettingsModal, setShowPublishingSettingsModal]
    = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [remoteDataParams, setRemoteDataParams] = useState({
    pageSize: retrieveTablePageSize(PUBLISHING_HISTORY_TABLE_NAME),
    pageIndex: 0,
    sortBy: { id: 'date', desc: true },
  });

  useEffect(() => {
    findPublishingEvents({
      variables: {
        limit: remoteDataParams.pageSize,
        skip: remoteDataParams.pageIndex * remoteDataParams.pageSize,
        sortBy: remoteDataParams.sortBy?.id
          ? parseSortBy(
            remoteDataParams.sortBy,
            sortOverride,
            sortOverrideFunction,
          )
          : {},
        workspaces: [workspace],
      },
    });
  }, [findPublishingEvents, remoteDataParams, workspace]);

  const disableRevert = useMemo(() => {
    if (!publishingEvents || publishingEventsLoading) {
      return true;
    }
    return !publishingEvents.find((event) => event.revertible);
  }, [publishingEvents, publishingEventsLoading]);

  const handleDownloadClick = ({ network, zipUrl }) => {
    dispatch(
      downloadPackage({
        networkId,
        networkName: network?.name,
        zipUrl,
      }),
    );
  };

  const canDownloadPackage = (state, zipUrl) => state !== 'errored' && !!zipUrl;

  const buildEventRow = ({
                           id,
                           date,
                           externalId,
                           message,
                           network,
                           networkChannels,
                           state,
                           userProfile,
                           zipUrl,
                         }) => ({
    id,
    status: convertPackageEvent(state),
    channels: convertNetworkChannels(networkChannels),
    packageNumber: externalId,
    description: message,
    user: getFullNameFromProfile(userProfile),
    date,
    actions: (
      <Box sx={styles.actions}>
        <Box className="hoverUnhide">
          {canDownloadPackage(state, zipUrl) && (
            <Tooltip title="Download JSON File(s)" placement="left">
              <GetApp
                sx={styles.actionIcon}
                onClick={() => handleDownloadClick({ network, zipUrl })}
                data-test="publishingPackageDownloadBtn"
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    ),
  });

  const getRemoteData = useCallback(
    (sortBy, pageIndex = 0, pageSize = 10, filters = [], gotoPage = null) => {
      if (
        remoteDataParams.sortBy?.id !== sortBy?.id
        || remoteDataParams.sortBy?.desc !== sortBy?.desc
        || remoteDataParams.pageIndex !== pageIndex
        || remoteDataParams.pageSize !== pageSize
      ) {
        gotoPage(pageIndex);
        setRemoteDataParams({
          sortBy,
          pageIndex,
          pageSize,
          gotoPage,
        });
      }
    },
    [remoteDataParams],
  );

  const memoizedData = useMemo(
    () => {
      const events = [];
      publishingEvents.forEach(
        ({
           id,
           createdAt,
           createdBy = {},
           errorDetails,
           externalId,
           message,
           network,
           networkChannels,
           state,
           zipUrl,
           revertedAt,
           revertedBy = {},
           revertMessage,
         }) => {
          events.push(
            buildEventRow({
              id,
              date: createdAt,
              userProfile: createdBy?.profile,
              errorDetails,
              externalId,
              message,
              network,
              networkChannels,
              state: state === 'reverted' ? 'packaged' : state,
              zipUrl,
            }),
          );

          if (state === 'reverted') {
            events.push(
              buildEventRow({
                id,
                date: revertedAt,
                userProfile: revertedBy?.profile,
                errorDetails,
                externalId: '',
                message: revertMessage,
                networkChannels,
                state,
              }),
            );
          }
        },
      );

      return events;
    },
    [publishingEvents], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRevertCompleted = async () => {
    await refetchPublishingEvents();
    setShowRevertModal(false);
  };

  return isUberAdmin === false && workspace === DRAFT_WORKSPACE ? (
    <Navigate to="/" />
  ) : (
    <FlamingoPage
      pageName={'Publishing History'}
      sx={styles.root}
      pageTitleSx={styles.pageTitle}
      headerSx={styles.headerContainer}
      headerButtons={
        <Grid
          container
          justifyContent={isUberAdmin ? 'space-between' : 'flex-end'}
        >
          {isUberAdmin && (
            <IconButton
              sx={styles.settingsButton}
              onClick={() => setShowPublishingSettingsModal(true)}
              data-test="PublishingHistoryPage-button-settings"
              size="large"
            >
              <Settings />
            </IconButton>
          )}
          <Button
            sx={styles.revertButton}
            variant="outlined"
            color="primary"
            data-test="publishingRevertBtn"
            disabled={disableRevert}
            onClick={() => setShowRevertModal(true)}
          >
            REVERT TO PREVIOUS PUBLISH
          </Button>
        </Grid>
      }
    >
      <Box sx={styles.content}>
        {publishingEventsLoading && <Loading />}
        {!publishingEventsLoading && (
          <GozioTable
            name={PUBLISHING_HISTORY_TABLE_NAME}
            columns={COLUMNS}
            data={memoizedData}
            currentPage={remoteDataParams.pageIndex}
            rowsPerPage={remoteDataParams.pageSize}
            remoteDataFn={getRemoteData}
            sortBy={[{ ...remoteDataParams.sortBy }]}
            dataCount={count}
            sx={styles.table}
            emptyContent={
              <Box sx={{ height: `${window.innerHeight - 200}px` }}>
                <EmptyPanel large title="Nothing has been Published" />
              </Box>
            }
            loading={publishingEventsLoading}
          />
        )}
      </Box>
      {showPublishingSettingsModal && (
        <PublishingSettingsModal
          handleClose={() => setShowPublishingSettingsModal(false)}
          handleSave={async () => {
            await refetchPublishingControl();
            setShowPublishingSettingsModal(false);
          }}
          publishingControl={publishingControl}
        />
      )}
      {showRevertModal && (
        <RevertModal
          handleCompleted={handleRevertCompleted}
          handleCancel={() => setShowRevertModal(false)}
        />
      )}
      {showPublishFailureModal && (
        <PublishFailureModal
          dataPackage={selectedDataPackage}
          errorDescription={errorMessage}
          open={showPublishFailureModal}
          handleClose={() => setShowPublishFailureModal(false)}
        />
      )}
    </FlamingoPage>
  );
};

const PublishingHistoryPage = () => (
  <LoggedinLayout enableSuspense={true}>
    <PublishingHistoryPageContent />
  </LoggedinLayout>
);

export default PublishingHistoryPage;
