import { Grid } from '@mui/material';
import Autocomplete from 'components/autocomplete/autoComplete';
import GenericFormModal from 'components/genericModal/genericFormModal';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang } from 'helpers/lang-util';
import { useUpdateWaitTimeFeedPlace } from 'hooks/dataHooks/useWaitTimeFeed';
import useToast from 'hooks/useToast';
import { makeValidate, TextField } from 'mui-rff';
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const generalSchema = Yup.object().shape({
  id: Yup.string().required('Location is required'),
  key: Yup.string().required('Key is required'),
});

const validate = makeValidate(generalSchema);

const EditFeedModal = ({ feed, locations, networkId, handleClose }) => {
  const [isEdit] = useState(!!feed.id);
  const [updateNetworkPlace] = useUpdateWaitTimeFeedPlace(networkId);
  const { toastNotificationSuccessHook } = useToast();

  const handleSave = async (values) => {
    const result = await updateNetworkPlace({
      variables: {
        id: values.id,
        input: {
          waitTimeToken: values.key,
        },
      },
    });
    if (result.data) {
      toastNotificationSuccessHook(
        `Wait time feed ${isEdit ? 'updated' : 'added'}`,
      );
      handleClose();
    }
  };

  const placeOptions = useMemo(
    () => locations
        .filter((loc) => !loc.waitTimeToken || loc.id === feed.id)
        .map((loc) => ({
          id: loc.id,
          label: getLabelByLang(loc.name),
          type: loc.designation,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locations],
  );

  return (
    <GenericFormModal
      title={isEdit ? 'Edit Wait Time Feed' : 'Wait Time Feed'}
      open={true}
      formParams={{
        onSubmit: handleSave,
        validate,
        initialValues: {
          key: feed?.waitTimeToken,
          id: feed?.id,
        },
      }}
      body={
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Field name="id" subscription={{ value: true }}>
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={placeOptions}
                  label={'Location'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
          </Grid>
          <Grid item>
            <TextField
              label={getFormLabel('Key', true)}
              name="key"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      }
      confirmText={isEdit ? 'Save' : 'Add Wait Time Feed'}
      handleClose={handleClose}
    />
  );
};

export default EditFeedModal;
