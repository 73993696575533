import { useEffect, useMemo, useState } from 'react';
import {
  NetworkStatus,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';
import useHandleError from 'hooks/useHandleError';

const defaultOptions = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true,
};

/**
 * @typedef {Object} [QueryResultObject]
 */

/**
 * Create a custom useQuery hook that provides out-of-the-box state management, data normalization, and execution control.
 *
 * @param {string} gql - The _required_ GraphQL query to be executed. Defaults to `null`.
 * @param {Object} options - An object containing optional parameters. See below for default options
 * @param {function} [options.dataFn=null] - An optional callback with a single parameter that represents the current state of the useQuery result object. It _must_ be memoized or otherwise static, since it is passed into the dependecy array of the internal useEffect hook that helps manage the state. Defaults to `null`.
 * @param {any} [options.initialState={}] An optional parameter that sets the initial state of the data. Defaults to `{}`.
 * @param {function(QueryResultObject):QueryResultObject} [options.returnFn=(userQueryResult) => userQueryResult] An optional callback that forwards the useQuery result object as a parameter and returns the result of the custom query hook. This is useful for renaming properties of the useQuery result object as needed before it is returned. By default, it returns the useQuery result object itself.
 * @param {boolean} [options.skip=false] An optional parameter that sets whether or not to skip useQuery execution. NOTE: This takes precedence over `skipFn`, i.e. if `skip` is set to `true` then `skipFn` will be ignored. Defaults to `false`
 * @param {function(QueryResultObject):boolean} [options.skipFn=() => false] An optional callback that provides more discrete control over whether or not useQuery execution is skipped. If `dataFn` is provided and normalizes the returned data, then that is what is forwarded to `skipFn`. Defaults to an anonymous function that returns `false`
 * @param {boolean} [options.suspense=false] An optional parameter that, if set to `true`, will execute a `useSuspenseQuery` hook rather than a `useQuery` hook. Defaults to `false`
 * @param {object} [options.useQueryOptions={}] An optional options object forwarded to the `useQuery` hook. Use this to set headers or provide variables as needed
 * @throws {Error} Will throw an error if a gql parameter is not provided
 * @throws {Error} Will throw an error if a returnFn callback is provided, but fails to return a value
 * @returns {any} The result of `returnFn`, which defaults to the `useQuery` query result object
 *
 * @example
 * const useNetworkPlace = ({ id }) => useCustomQuery(GET_PLACE, {
 *  // Normalize the data in the useQuery result object
 *  dataFn: useMemo(() => (data) => data?.getNetworkPlace, []),
 *  // Set the initial state of the data; this defaults to `{}` any case and is provided here for clarity
 *  initialState: {},
 *  // Skip execution (i.e., do not call `useQuery`) if no `id` option is passed to `useNetworkPlace`
 *  skip: !id,
 *  // Skip execution (i.e., do not call `useQuery`) if the data already exists (implicit via the presence of an `id` property). This can be further customized with conditional logic to control if and when data needs to be fetched again. Must return a boolean value
 *  // NOTE: If `dataFn` is provided (as above) and normalizes the return data (as above), then that is what is forwarded to `skipFn`
 *  skipFn: useMemo(() => (data) => data?.id !== undefined, []),
 *  // Forward options parameter to Apollo client's useQuery hook
 *  useQueryOptions: {
 *    variables: {
 *      id,
 *    },
 *  },
 * });
 */
export const useCustomQuery = (gql, {
  // Optional parameters
  dataFn = null,
  initialState = {},
  returnFn = (useQueryResult) => useQueryResult,
  skip = false,
  skipFn = () => false,
  suspense = false,
  useQueryOptions = {},
}) => {
  if (!gql) throw new Error('useCustomQuery: `gql` parameter required');
  if (dataFn && typeof dataFn !== 'function') throw new Error('useCustomQuery: If `dataFn` is provided, it must be a function');
  if (skipFn && typeof skipFn !== 'function') throw new Error('useCustomQuery: If `skipFn` is provided, it must be a function');
  if (returnFn && typeof returnFn !== 'function') throw new Error('useCustomQuery: If `returnFn` is provided, it must be a function');
  if (skip !== true && skip !== false) throw new Error('useCustomQuery: If `skip` parameter is provided, it must be a boolean');
  if (suspense !== true && suspense !== false) throw new Error('useCustomQuery: If `suspense` parameter is provided, it must be a boolean');

  /**
   * Takes a query result data from a GraphQL query and, if a `dataFn` exists, normalizes it updates the state of the data with the result of `dataFn`. If no `dataFn` exists, it simply updates the state of the data with the query result data as-is
   * @param {any} [queryResultData] Query result data
   * @returns {any} The query result data, normalized or as-is
   */
  const normalizeData = useMemo(() => (queryResultData) => {
    if (!dataFn && queryResultData) {
      setData(queryResultData);
      return queryResultData;
    } else if (typeof dataFn === 'function' && queryResultData) {
      const normalizedData = dataFn(queryResultData);
      if (normalizedData) setData(normalizedData);
      return normalizedData;
    }
  }, [dataFn]);

  /**
   * Wraps the default `refetch` function from the query result object, allowing the state to be updated with the refetch results
   * @param {any} [variables={}] The query variables to include in the refetch query, if any. Defaults to `{}`
   * @returns {any} The normalized data from the refetched query result object
   * @example
   * // Inside a component using a custom query... refetch with updated variables
   * const { data, refetch, loading } = useMyCustomQuery({ variables: { limit: 10 } });
   * const refetchedData = refetch({ limit: 100 })
   * if (refetchedData) doSomethingWithTheData(refetchedData) // Not necessary, but available
   */
  const refetchAndUpdate = async (variables={}) => {
    setLoading(true);
    const { data } = await originalRefetch(variables);
    setLoading(false);
    return normalizeData(data); // This is needed to update the state; it does not happen as part of the above `useEffect` hook
  };

  const { handleError } = useHandleError('GraphQL Error');
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const useQueryOrSuspenseQuery = suspense ? useSuspenseQuery : useQuery;
  const {
    data: queryResultData,
    loading: queryResultLoading,
    networkStatus: queryResultNetworkStatus,
    refetch: originalRefetch,
    ...rest
  } = useQueryOrSuspenseQuery(gql, {
    ...defaultOptions,
    ...useQueryOptions,
    ...useQueryOptions.onCompleted && { onCompleted: (results) => {
      if (useQueryOptions.onCompleted) useQueryOptions.onCompleted(results, { data, setData });
    } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: (!skip && skipFn(data)) || skip,
  });

  // Update the state with the initial query result data when the query result data changes
  // NOTE: Per the standard behavior of the Apollo Client hooks, executing the `refetch` method of the query result object will _not_ trigger a change here.
  useEffect(() => {
    normalizeData(queryResultData);
    setLoading(queryResultLoading);
  }, [
    normalizeData,
    queryResultData,
    queryResultLoading,
  ]);

  // Pass the modified query result object through `returnFn` to modify it further as needed (e.g., renaming properties) before returning
  const result = returnFn({
    data,
    loading,
    networkStatus: queryResultNetworkStatus,
    refetch: refetchAndUpdate,
    refetching: queryResultNetworkStatus === NetworkStatus.refetch,
    ...rest,
  });

  if (!result) throw new Error('useCustomQuery: `returnFn` must return a value');
  return result;
};
