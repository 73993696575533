import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Autocomplete from 'components/autocomplete/autoComplete';
import ColorOptions from 'components/colorOptions/colorOptions';
import GozioDrawer from 'components/drawer/drawer';
import Condition from 'components/forms/condition';

import FormWatcher from 'components/forms/formWatcher';
import ImageUploader from 'components/imageUploader/imageUploader';
import GozioListItem from 'components/listItem/gozioListItem';
import RichTextField from 'components/richTextField/richTextField';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang } from 'helpers/lang-util';
import { TextField as FinalTextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  tileDrawerRoot: {
    height: 'calc(100% - 138px)',
    overflow: 'scroll',
  },
  tileDrawerInner: {
    padding: '12px 24px 36px',
  },
}));

const poiTypeOptions = [
  { id: 'freeform', label: 'Free Form Entry' },
  { id: 'poigroup', label: 'POI Group' },
];

const formFieldMap = new Map([
  ['name', { label: 'Name', section: 'Details' }],
  ['tileColor', { label: 'Background Color', section: 'Details' }],
  ['type', { label: 'Tile Type', section: 'Details' }],
]);

const MAX_POI_COUNT = 9;

const getSecondDataLine = (poi) => {
  if (!poi?.parentBuilding?.name && !poi?.parentFloor?.name) return null;
  const labels = [];
  if (poi?.parentBuilding?.name) labels.push(`${getLabelByLang(poi.parentBuilding.name)}`);
  if (poi?.parentFloor?.name) labels.push(`${getLabelByLang(poi.parentFloor.name)}`);
  return labels.join(', ');
};

const getThirdDataLine = (poi) => {
  if (
    !poi?.geoLocation?.address?.street
    && !poi?.geoLocation?.address?.state
    && !poi?.geoLocation?.address?.city
    && !poi?.geoLocation?.address?.zip
  ) return null;

  const labels = [];
  if (poi?.geoLocation?.address?.street) labels.push(poi.geoLocation.address.street);
  if (poi?.geoLocation?.address?.city) labels.push(poi.geoLocation.address.city);
  if (poi?.geoLocation?.address?.state) labels.push(poi.geoLocation.address.state);
  let retVal = labels.join(', ');
  if (poi?.geoLocation?.address?.zip) retVal = `${retVal} ${poi.geoLocation.address.zip}`;
  return retVal;
};

const TileDrawer = ({
  form,
  currentTile,
  closeTileDrawer,
  pois,
  submitting,
  invalid,
  dirty,
}) => {
  const classes = useStyles();

  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedPOIs, setSelectedPOIs] = useState([]);

  useEffect(() => {
    setSelectedPOIs(
      (currentTile?.poiItems || [])
        .map((p) => {
          const poi = pois.find((poi) => poi.id === p.poi?.id);
          if (poi) {
            return { order: p.order, ...poi };
          }
          return null;
        })
        .filter((item) => item)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTile]);

  const addNewPOI = (poiID) => {
    const { selectedPOIs } = form.getState().values;
    const newPOIs = [...selectedPOIs.sort((a, b) => a.order - b.order)];
    if (!newPOIs.find((p) => p.id === poiID)) {
      const newPOI = pois.find((p) => p.id === poiID);
      if (newPOI) newPOIs.push(newPOI);
    }
    const indexedPOIs = newPOIs.map((p, idx) => ({ ...p, order: idx + 1 }));
    form.change('selectedPOIs', indexedPOIs);
    setSelectedPOIs(indexedPOIs);
  };

  const deletePOI = (form, deletedId) => {
    const { selectedPOIs } = form.getState().values;
    const newSelectedPOIs = selectedPOIs
      .filter((poi) => poi.id !== deletedId)
      .sort((a, b) => a.order - b.order)
      .map((item, idx) => ({ ...item, order: idx + 1 }));
    form.change('selectedPOIs', newSelectedPOIs);
    setSelectedPOIs(newSelectedPOIs);
  };

  const updateOptions = (form, newOptions) => {
    const { selectedPOIs } = form.getState().values;
    const newSelectedPOIs = selectedPOIs.map((poi) => {
      const newOpt = newOptions.find((n) => n.id === poi.id);
      return {
        ...poi,
        order: newOpt?.order || poi.order,
      };
    });
    form.change('selectedPOIs', newSelectedPOIs);
    setSelectedPOIs(newSelectedPOIs);
  };

  const handleClose = () => {
    const { dirty, errors } = form.getState();
    setIsClosing(true);
    if (!dirty && !Object.keys(errors).length) {
      setIsClosing(false);
      closeTileDrawer();
    }
  };

  return (
    <GozioDrawer
      open={!!currentTile}
      onClose={handleClose}
      onConfirm={() => {
        form.submit();
      }}
      disableCancel={true}
      greyOutConfirm={!dirty || submitting || invalid}
      title="Edit Kiosk Directory Tile"
      top={0}
      width={568}
      variant="temporary"
    >
      <FormWatcher
        formRenderProps={{ form }}
        errorFormFieldMap={formFieldMap}
        isClosing={isClosing}
        isSubmitting={isSubmitting}
        onClose={() => {
          setIsClosing(false);
          setIsSubmitting(false);
        }}
        onContinue={() => {
          setIsClosing(false);
          setIsSubmitting(false);
          closeTileDrawer();
        }}
        onSave={async () => {
          setIsClosing(false);
          setIsSubmitting(false);
          closeTileDrawer();
        }}
      />
      <Box className={classes.tileDrawerRoot}>
        {currentTile && (
          <Box className={classes.tileDrawerInner}>
            <Typography variant="subtitle1" sx={{ marginBottom: '26px' }}>
              Directory Tile Details
            </Typography>
            <FinalTextField
              label={getFormLabel('Name', true)}
              name="name"
              variant="outlined"
            />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '40px', marginBottom: '26px' }}
            >
              Tile Display
            </Typography>
            <Box sx={{ width: '60%' }}>
              <Field name="tileColor">
                {({ input, meta }) => (
                  <ColorOptions
                    initialColor={input.value}
                    getColor={input.onChange}
                    label={'Background Color'}
                    helperText={meta.error}
                    defaultColor={ColorPalette.grey[300]}
                    required={true}
                    customStyle={{ marginBottom: '30px' }}
                    formControl={true}
                  />
                )}
              </Field>
            </Box>
            <ImageUploader
              title={getFormLabel('Tile Icon', true)}
              image={form.getState().values.tileIcon}
              display="inline"
              isIcon={true}
              onImageUpdated={(file) => {
                form.change('tileIcon', {
                  url: file.url,
                  file: file.file,
                  name: file.name,
                });
              }}
              onImageDeleted={() => {
                form.change('tileIcon', null);
              }}
              allowDelete={false}
              showImage={true}
              showImageName={false}
              minHeight={48}
              maxHeight={48}
              minWidth={48}
              maxWidth={48}
              previewWidth={64}
              previewHeight={64}
              maxFileSize={Infinity}
              fileInfo="Supported files includes jpeg and png. We recommend the image dimensions be 48x by 48px with a transparent background."
            />
            <Field name="tileIcon">{() => null}</Field>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '74px', marginBottom: '26px' }}
            >
              Tile Type
            </Typography>
            <Field
              name="tileType"
              component={({ input }) => (
                <GozioSelect
                  input={input}
                  label={getFormLabel('Tile Type', true)}
                  options={poiTypeOptions}
                />
              )}
            />
            <Condition when="tileType" is="freeform">
              <Typography
                variant="subtitle1"
                sx={{ marginTop: '40px', marginBottom: '26px' }}
              >
                Free Form Entry Details
              </Typography>
              <FinalTextField
                label={getFormLabel('Title', true)}
                name="title"
                variant="outlined"
                sx={{ marginBottom: '34px' }}
              />
              <RichTextField
                name="description"
                label={'Description'}
                required={true}
              />
            </Condition>
            <Condition when="tileType" is="poigroup">
              <Typography
                variant="subtitle1"
                sx={{ marginTop: '40px', marginBottom: '26px' }}
              >
                POI Group Details
              </Typography>
              <Field name="newPOI">
                {({ input }) => {
                  const onChange = (value) => {
                    addNewPOI(value);
                    input.onChange(null);
                  };
                  return (
                    <Autocomplete
                      input={{ value: input.value, onChange }}
                      label="Select or Search for a POI"
                      options={pois
                        .filter(
                          (p) => !updatedPOIs.find((sp) => p.id === sp.id)
                        )
                        .map((d) => ({
                          label: getLabelByLang(d?.name),
                          label2: getSecondDataLine(d),
                          label3: getThirdDataLine(d),
                          id: d.id,
                        }))}
                      disableClearable={true}
                      required={true}
                      disabled={updatedPOIs.length >= MAX_POI_COUNT}
                    />
                  );
                }}
              </Field>
              <Box sx={{ marginTop: '40px' }}>
                <Field name="selectedPOIs">
                  {({ input }) => (
                    <GozioListItem
                      title={getFormLabel(
                        `${
                          input.value?.length || 0
                        }/${MAX_POI_COUNT} Directory Tile POIs`,
                        true
                      )}
                      enableReorder={true}
                      emptyMessage="There are No POIs in This Directory Tile"
                      options={(input.value || []).map((poi) => ({
                        order: poi?.order,
                        id: poi?.id,
                        label: getLabelByLang(poi?.name),
                        secondaryText: [
                          getSecondDataLine(poi),
                          getThirdDataLine(poi),
                        ],
                        actions: [
                          {
                            Icon: DeleteIcon,
                            hover: 'Delete',
                            onClick: (id) => deletePOI(form, id),
                          },
                        ],
                      }))}
                      updateOptions={(newList) => updateOptions(form, newList)}
                    />
                  )}
                </Field>
              </Box>
            </Condition>
          </Box>
        )}
      </Box>
    </GozioDrawer>
  );
};

export default TileDrawer;
