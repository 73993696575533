import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import renderLargeToggle from 'components/renderLargeToggle';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

class StepperSorted extends React.Component {
  render() {
    const { classes, valid, matchBy, actionType } = this.props;
    const customTags = {
      alpha: {
        'data-test': 'typeaheadStep4AlphaButton',
      },
      relevance: {
        'data-test': 'typeaheadStep4RelevanceButton',
      },
      distance: {
        'data-test': 'typeaheadStep4DistanceButton',
      },
      default: {
        'data-test': 'typeaheadStep4DefaultButton',
      },
      next: {
        'data-test': 'typeaheadStep4NextButton',
      },
      back: {
        'data-test': 'typeaheadStep2BackButton',
      },
      cancel: {
        'data-test': 'typeaheadStep2CancelButton',
      },
    };
    const options
      = actionType === 'category'
        ? [
            { label: 'Alphanumeric', value: 'alphanumeric' },
            { label: 'Distance', value: 'distance' },
            { label: 'Relevance', value: 'relevance' },
          ]
        : [
            { label: 'Distance', value: 'distance' },
            { label: 'Default', value: 'default' },
          ];

    return (
      <Box>
        <Typography className={classes.title}>
          How are the results sorted?
        </Typography>
        <Typography className={classes.contentText}>
          If selected, a typeahead rule will display a list of results without
          any particular order. Choose from the options below to order the
          results
        </Typography>

        <Field
          name="sortBy"
          component={renderLargeToggle}
          allowUnselect={false}
          customTags={customTags}
          options={options}
        />

        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={this.props.handleCancel}
            {...customTags.cancel}
          >
            CANCEL
          </Button>
          <Box sx={{ float: 'right', display: 'inline-block' }}>
            <Button
              variant="outlined"
              onClick={this.props.handleBack}
              {...customTags.back}
            >
              BACK
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!valid}
              color="primary"
              {...customTags.next}
            >
              {matchBy === 'tag' ? 'NEXT' : 'SAVE'}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

const selector = formValueSelector('typeaheadForm');

export default compose(
  connect(
    (state) => {
      const { form } = state;
      const matchBy = selector(state, 'matchBy');
      const actionType = selector(state, 'actionType');
      return {
        typeaheadForm: form.typeaheadForm,
        matchBy,
        actionType,
      };
    },
    (dispatch) => ({
      dispatch,
    }),
  ),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: false,
    enableReinitialize: false,
  }),
  withStyles(StepStyles),
)(StepperSorted);
