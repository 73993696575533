import { Grid } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import LabeledCheckbox from 'components/labeledCheckbox/labeledCheckbox';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '673px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& .MuiDialogContent-root': {
        width: '100%',
      },
    },
  },
});

const compareArrays = (initial, current) => {
  for (let i = 0, l = current.length; i < l; i++) {
    if (current[i].hidden !== initial[i].hidden) {
      return false;
    }
  }
  return true;
};

const ColumnConfigModal = ({
                             columns,
                             showModal,
                             setShowModal,
                             handleSave,
                           }) => {
  const styles = buildStyles();
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns]);

  const updateCol = (id) => (e, val) => {
    const updatedCols = selectedColumns.map((c) => ({ ...c }));
    const currentCol = updatedCols.find((c) => c.id === id);
    currentCol.hidden = !val;
    setSelectedColumns(updatedCols);
  };
  return (
    showModal && (
      <GenericModal
        title="Configure Table Columns"
        sx={styles.root}
        body={
          <Grid container>
            {selectedColumns
              .filter((c) => !c.alwaysHidden)
              .map((option, index) => (
                <Grid
                  item
                  xs={4}
                  key={`config-col-${index}`}
                  sx={{ paddingTop: '8px', paddingBottom: 0 }}
                >
                  <LabeledCheckbox
                    checked={option.alwaysShown || !option.hidden}
                    label={option.label}
                    onChange={updateCol(option.id)}
                    disabled={option.alwaysShown}
                  />
                </Grid>
              ))}
          </Grid>
        }
        open={true}
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={() => {
          handleSave(selectedColumns);
        }}
        greyOutConfirm={compareArrays(columns, selectedColumns)}
        confirmText="Update Table Columns"
      />
    )
  );
};

ColumnConfigModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

export default React.memo(ColumnConfigModal);
