import {
  useLazyQuery,
  useMutation,
  useSubscription,
  useSuspenseQuery,
} from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';

import {
  FIND_DATA_PACKAGE,
  GET_DATA_PACKAGE,
  SUBSCRIBE_TO_DATA_PACKAGE,
  UPDATE_DATA_PACKAGE,
} from 'graphql/queries';

export const useDataPackage = (networkId, limit = 1) => {
  const { handleError } = useHandleError('Publishing');

  return useSuspenseQuery(GET_DATA_PACKAGE, {
    variables: { limit },
    fetchPolicy: 'no-cache',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
  });
};

export const usePublishingEvents = (sortBy = [{ id: 'date', desc: true }]) => {
  const { handleError } = useHandleError('Publishing');

  const [findPublishingEvents, { data, error, loading, refetch }]
    = useLazyQuery(FIND_DATA_PACKAGE, {
      variables: {
        sortBy,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    });

  return {
    count: data?.findDataPackage?.count ?? 0,
    data: data?.findDataPackage?.edges?.map((child) => child.node) || [],
    error,
    findPublishingEvents,
    loading,
    refetch,
  };
};

export const usePublishingEventsSubscription = (networkId) => {
  const { handleError } = useHandleError('Publishing');
  return useSubscription(SUBSCRIBE_TO_DATA_PACKAGE, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
  });
};

export const useUpdateDataPackage = () => {
  const { handleError } = useHandleError('Publishing');

  return useMutation(UPDATE_DATA_PACKAGE, {
    onerror: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
