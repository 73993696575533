import { Box, Grid, Typography } from '@mui/material';
import Condition from 'components/forms/condition';
import ImageUploader from 'components/imageUploader/imageUploader';
import GozioSelect from 'components/selector/selector';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import { TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const LinkTypeCustom = ({ linkOptions, isUberAdmin }) => (
  <>
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6} sx={{ marginTop: '8px' }}>
        <Field
          component={GozioSelect}
          name="linkType"
          label={getFormLabel('Quicklink Type', true)}
          options={linkOptions}
          isUberAdmin={isUberAdmin}
        />
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
        <Field name="icon">
          {({ input: { onChange, value } }) => (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <ImageUploader
                fileInfo="Supported files include jpeg and png. Image dimensions must be 88px by 88px."
                image={
                  value?.image?.id
                    ? {
                      id: value?.image?.id,
                      name: value?.image?.name,
                      url: value?.url,
                    }
                    : value?.file
                      ? { name: value?.file?.name, url: value?.url }
                      : null
                }
                onImageUpdated={({ file, url }) => {
                  onChange({ file, url });
                }}
                isIcon={true}
                maxFileSize={Infinity}
                minHeight={88}
                minWidth={88}
                maxHeight={88}
                maxWidth={88}
                activeDragPadding={8}
                allowDelete={false}
                display="inline"
                previewWidth={64}
                previewHeight={64}
                showImage={true}
                showImageName={false}
              />
            </Box>
          )}
        </Field>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={3} sx={{ marginTop: '12px' }}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ marginBottom: '2px' }}>
          Custom Gozio Action Type Details
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ marginBottom: '8px' }}>
        <Field name="name">
          {({ input, meta }) => (
            <TextField
              label={getFormLabel(' Name', true)}
              charLimit={25}
              helperText={meta.touched ? meta.error : ''}
              error={meta.touched && !!meta.error}
              {...input}
              fullWidth
            />
          )}
        </Field>
      </Grid>
      <Condition when="langES" is={true}>
        <Grid item xs={6}>
          <Field name="nameES">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Name', false, true)}
                charLimit={25}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Condition>
      <Grid item xs={6} sx={{ marginBottom: '8px' }}>
        <Field name="shortName">
          {({ input, meta }) => (
            <TextField
              label={getFormLabel('Short Name', true)}
              charLimit={10}
              helperText={meta.touched ? meta.error : ''}
              error={meta.touched && !!meta.error}
              {...input}
              fullWidth
            />
          )}
        </Field>
      </Grid>
      <Condition when="langES" is={true}>
        <Grid item xs={6}>
          <Field name="shortNameES">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Short Name', false, true)}
                charLimit={10}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Condition>
      <Grid item xs={12}>
        <FinalTextField
          label={getFormLabel('Open Field', true)}
          name="custom"
          variant="outlined"
        />
      </Grid>
    </Grid>
  </>
);

LinkTypeCustom.propTypes = {
  linkOptions: PropTypes.array.isRequired,
  isUberAdmin: PropTypes.bool,
};

export default LinkTypeCustom;
