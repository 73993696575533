import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import InfoPanel from 'components/infoPanel/infoPanel';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkPlace } from 'hooks/dataHooks/useSites';
import { makeValidate, Radios, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const addPoiSchema = Yup.object().shape({
  poiName: Yup.string().required('POI name is required'),
  building: Yup.string().when('isSite', {
    is: 'building',
    then: (schema) => schema.required('Building is required'),
  }),
});

const validate = makeValidate(addPoiSchema);

const poiSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const buildBuildingOptions = (buildings) => buildings
    .map((b) => ({
      id: b.node.id,
      label: `${getLabelByLang(b.node.name)} (${
        b.node.isMapped ? 'Mapped' : 'Unmapped'
      })`,
    }))
    .sort(poiSort);

const AddPoiModal = ({
                       handleClose,
                       handleConfirm,
                       buildings,
                       site,
                       networkName,
                       networkLogo,
                     }) => {
  const classes = useStyles();
  const isMapped = site?.isMapped === true;
  const noBuildings = buildings.length === 0;
  const [createPoi] = useCreateNetworkPlace('poi');
  const options = buildBuildingOptions(buildings);

  const onSubmit = async (values) => {
    const response = await createPoi({
      variables: {
        input: {
          name: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.poiName }],
          designation: 'poi',
          inheritAddress: true,
          contact: {
            hours: {
              type: 'none',
            },
          },
        },
        meta: {
          parent: values.isSite === 'site' ? site.id : values.building,
        },
      },
    });
    handleConfirm(response?.data?.createNetworkPlace);
  };

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          siteName: `${getLabelByLang(site?.name)} (${
            site?.isMapped ? 'Mapped' : 'Unmapped'
          })`,
          poiName: '',
          isSite: site?.isMapped === true ? 'building' : 'site',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={parseByLang(site?.name) || networkName}
      content={({ form }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Unmapped POI
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Unmapped POI
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '33px' }}>
            <FinalTextField
              label={getFormLabel('Parent Site Selection', true)}
              name="siteName"
              variant="outlined"
              disabled={true}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '33px', textAlign: 'left' }}>
            <Radios
              label={getFormLabel('This POI’s Parent location is the', true)}
              name="isSite"
              color="primary"
              size="small"
              radioGroupProps={{ row: true }}
              required={false}
              data={[
                { label: 'Site', value: 'site', disabled: isMapped },
                {
                  label: 'Building',
                  value: 'building',
                  disabled: !isMapped && noBuildings,
                },
              ]}
            />
          </Grid>
          {isMapped && (
            <InfoPanel
              title={'You cannot create an Unmapped POI for a Mapped Site.'}
            />
          )}
          {!isMapped && noBuildings && (
            <InfoPanel
              title={'You have no Unmapped Buildings for this Site.'}
            />
          )}
          {!noBuildings && (
            <Condition when="isSite" is={'building'}>
              <Grid item xs={12} sx={{ marginTop: '24px', marginBottom: '9px' }}>
                <Field name="building">
                  {({ input, meta }) => (
                    <Autocomplete
                      input={input}
                      meta={meta}
                      options={options}
                      label={'Parent Building Selection'}
                      required={true}
                      fullWidth
                      disableClearable={true}
                    />
                  )}
                </Field>
              </Grid>
            </Condition>
          )}
          <Grid item xs={12} sx={{ marginTop: '24px' }}>
            <FinalTextField
              label={getFormLabel('POI Name', true)}
              name="poiName"
              variant="outlined"
              autoComplete="off"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddPoiModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  buildings: PropTypes.array,
  site: PropTypes.object,
};

AddPoiModal.defaultProps = {};

export default React.memo(AddPoiModal);
