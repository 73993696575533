import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const FIND_SYSTEM_APP_DESTINATIONS = gql`
  query findInApp {
    system {
      appDestinations {
        id
        type
        label
      }
    }
  }
`;

export const FIND_SYSTEM_ENVIRONMENT = gql`
  query {
    system {
      environments {
        env
        dataUrl
        versionUrl
        searchUrl
        networkUrl
        customerContentBaseUrl
      }
    }
  }
`;

export const GET_SYSTEM_NAVIGATION = gql`
  ${fragments.SYSTEM_NAVIGATION_ATTRIBUTES}
  query {
    system {
      navigation {
        ...systemNavigationAttributes
      }
    }
  }
`;

export const REMOVE_SYSTEM_NAVIGATION_ACTION = gql`
  ${fragments.SYSTEM_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $actionId: ID!) {
    removeSystemNavigationAction(id: $id, actionId: $actionId) {
      ...systemNavigationAttributes
    }
  }
`;

export const UPDATE_SYSTEM_NAVIGATION = gql`
  ${fragments.SYSTEM_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $input: SystemNavigationInputIdentity!) {
    replaceSystemNavigation(id: $id, input: $input) {
      ...systemNavigationAttributes
    }
  }
`;

export const UPDATE_SYSTEM_NAVIGATION_ITEM = gql`
  ${fragments.SYSTEM_NAVIGATION_ATTRIBUTES}
  mutation($id: ID!, $input: ActionLinkInputUpdate!) {
    setSystemNavigationItem(id: $id, item: $input) {
      ...systemNavigationAttributes
    }
  }
`;

export const UPDATE_SYSTEM_NAVIGATION_TAB = gql`
  ${fragments.SYSTEM_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $input: ActionLinkInputUpdate!) {
    setSystemNavigationTab(id: $id, tab: $input) {
      ...systemNavigationAttributes
    }
  }
`;

export const SET_SYSTEM_ENVIRONMENT = gql`
  mutation setSystemEnvironment($envInput: EnvironmentInput!) {
    setSystemEnvironment(input: $envInput) {
      environments {
        env
        dataUrl
        searchUrl
        versionUrl
        networkUrl
        customerContentBaseUrl
      }
    }
  }
`;
