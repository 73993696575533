import { KeyboardArrowLeft, KeyboardArrowRight, Warning } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import ProgressCard from 'components/progressCard/progressCard';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.white,
    minWidth: '816px',
  },
  pageButton: {
    height: '32px',
    width: '32px',
    margin: `0 -${theme.spacing(1)}`,
    '&.Mui-disabled': {
      color: theme.palette.grey[350],
    },
  },
  rightPageButton: {
    marginLeft: 'auto',
  },
  cardContainer: {
    alignItems: 'center',
    gap: theme.spacing(2),
    '& > div:last-of-type': {
      marginRight: theme.spacing(-1),
    },
  },
  textContainer: {
    display: 'inline-block',
    maxWidth: '210px',
  },
  title: {
    display: 'inline-block',
  },
  description: {
    display: '-webkit-box',
    fontSize: '12px',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  rightArrow: {
    color: `${theme.palette.grey[500]} !important`,
    height: '16px !important',
    marginLeft: '3px',
    verticalAlign: 'text-bottom !important',
    width: '16px !important',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    textAlign: 'center',
    width: '100%',
  },
  dot: {
    background: theme.palette.grey[300],
    borderRadius: '50%',
    height: '6px',
    marginLeft: '2px',
    marginRight: '2px',
    width: '6px',
  },
  activeDot: {
    background: theme.palette.blue[400],
  },
}));

const CardCarousel = ({ cards, className, onCardClicked }) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    setMaxPage(Math.ceil(cards.length / 3));
  }, [cards]);

  const renderedCards = useMemo(() => {
    const list = cards.slice((page - 1) * 3, page * 3);
    return (
      <Grid container wrap="nowrap" className={classes.cardContainer}>
        {maxPage > 1 && (
          <IconButton
            className={classes.pageButton}
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
            size="large"
          >
            <KeyboardArrowLeft />
          </IconButton>
        )}
        {list.map((card, idx) => (
          <ProgressCard
            key={`CardCarousel-ProgressCard-${idx}`}
            onClick={() => onCardClicked(card)}
            icon={card.icon || <Warning />}
            title={card.title}
            description={card.description}
          />
        ))}
        {maxPage > 1 && (
          <IconButton
            className={clsx(classes.pageButton, classes.rightPageButton)}
            disabled={page === maxPage}
            onClick={() => setPage(page + 1)}
            size="large"
          >
            <KeyboardArrowRight />
          </IconButton>
        )}
      </Grid>
    );
  }, [
    cards,
    maxPage,
    onCardClicked,
    page,
    classes.cardContainer,
    classes.pageButton,
    classes.rightPageButton,
  ]);

  const renderPagination = () => {
    const dots = [];
    for (let i = 1; i <= maxPage; i++) {
      dots.push(
        <Box
          key={`dot_${i}`}
          className={clsx(classes.dot, i === page && classes.activeDot)}
        />,
      );
    }
    return dots;
  };

  return (
    <>
      {renderedCards}
      {maxPage > 1 && (
        <Box className={classes.pagination}>{renderPagination()}</Box>
      )}
    </>
  );
};

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.node,
    }),
  ),
  className: PropTypes.string,
  onCardClicked: PropTypes.func,
};

CardCarousel.defaultProps = {
  cards: [],
  className: '',
  onCardClicked: () => {},
};

export default CardCarousel;
