import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { FlamingoContext } from 'contexts/flamingo';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';

const DESCRIPTION_MAX_LIMIT = 500;

const buildStyles = ({ theme }) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
  charCount: {
    color: theme.palette.grey[400],
  },
  charCountError: {
    color: theme.palette.error.main,
  },
});

const promoteSchema = Yup.object().shape({
  description: Yup.string().max(
    DESCRIPTION_MAX_LIMIT,
    `Description must be ${DESCRIPTION_MAX_LIMIT} characters or less.`,
  ),
});

const validate = makeValidate(promoteSchema);

const PromoteModal = ({ handleClose, handleConfirm }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { activeNetwork } = useContext(FlamingoContext);
  const networkName = activeNetwork?.humanName || 'Global Template';
  const networkLogo = activeNetwork?.logo?.url;

  const [confirmProgress, setConfirmProgress] = useState(false);

  const onSubmit = async (values) => {
    setConfirmProgress(true);
    await handleConfirm(values.description);
  };

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          description: '',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      checkPristine={false}
      content={(formProps) => (
        <Grid sx={{ padding: '120px 32px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.heading}>
              Promotion Description
            </Typography>
            <Typography variant="subtitle1" sx={styles.describe}>
              Tell Us a Bit About the Data That's Being Promoted
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '33px' }}>
            <TextField
              name="description"
              fullWidth={true}
              multiline
              label="Description"
              minRows={8}
              maxRows={8}
              variant="outlined"
            />
            <Typography
              sx={{
                ...styles.charCount,
                ...formProps.values.description?.length
                  > DESCRIPTION_MAX_LIMIT && styles.charCountError,
              }}
              variant="caption"
            >
              {formProps.values.description?.length}/{DESCRIPTION_MAX_LIMIT}
            </Typography>
          </Grid>
        </Grid>
      )}
      confirmText="Promote Data"
      confirmType="submit"
      confirmProgress={confirmProgress}
      handleConfirm={onSubmit}
      handleClose={handleClose}
    />
  );
};

PromoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

PromoteModal.defaultProps = {};

export default PromoteModal;
