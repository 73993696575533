import { Delete, Publish } from '@mui/icons-material';
import { Box, CardMedia, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DropImageIcon from 'assets/drop-image.png';
import GenericModal from 'components/genericModal/genericModal';
import { colorWithAlpha } from 'helpers/color-util';
import { handleDropRejected, handleOnDrop, MINIMUM_IMAGE_HEIGHT, MINIMUM_IMAGE_WIDTH } from 'helpers/media-util';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';

const buildStyles = ({ theme }) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
  },
  dragInactiveContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  dragActiveContainer: {
    alignItems: 'center',
    border: `3px dashed ${theme.palette.blue[400]}`,
    borderRadius: '8px',
    boxShadow: `0 0 10px 0px ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    display: 'flex',
    height: '160px',
    justifyContent: 'center',
  },
  dragIcon: {
    marginRight: theme.spacing(2),
  },
  dragActiveTitle: {
    color: theme.palette.blue[400],
  },
  container: {
    overflow: 'visible',
  },
  imageContainer: {
    cursor: 'default',
    flexBasis: 'auto !important',
    marginRight: '12px',
    maxWidth: 'unset',
    padding: '12px',
    position: 'relative',
    marginTop: '-12px',
    textAlign: 'center',

    '&.deletable:hover': {
      background: theme.palette.blue[50],
      border: `2px solid ${theme.palette.blue[400]}`,
      padding: '10px',
      borderRadius: '8px',
      '& p': {
        fontWeight: 'bold',
      },

      '& .actionBar': {
        visibility: 'visible',
      },
    },
  },
  imageWrap: {
    borderRadius: '8px',
    backgroundColor: theme.palette.white,
    backgroundImage: theme.palette.transparentImageBackground,
    backgroundPosition: '0 0, 3px 3px',
    backgroundSize: '6px 6px',
    color: theme.palette.grey[500],
    overflow: 'hidden',
  },
  image: {
    borderRadius: '8px',
    backgroundSize: 'contain',
    border: `0.5px solid ${theme.palette.grey[400]}`,
  },
  imageName: {
    color: theme.palette.grey[600],
    fontWeight: 400,
    marginTop: '6px',
    marginBottom: '12px',
    display: '-webkit-box',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  icon: {
    marginRight: '22px',
    height: '64px',
    width: '64px',
  },
  uploadIcon: {
    margin: '20px',
  },
  dropZoneContent: {
    display: 'flex',
    alignItems: 'center',
  },
  dropZoneContentWithImage: {
    alignItems: 'center',
    display: 'flex',
  },
  dropZoneIcon: {
    background: theme.palette.grey[100],
    marginRight: '11px',
    width: '64px',
    height: '64px',
  },
  heading: {
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    marginBottom: '16px',

    '& span.MuiFormLabel-asterisk': {
      color: theme.palette.red[600],
      fontSize: '15px',
      fontWeight: 500,
    },
  },
  dropZoneTitle: {
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  fileInfo: {
    color: theme.palette.grey[400],
    fontSize: '12px',
    lineHeight: '18px !important',
    marginTop: '8px',
    textAlign: 'left',
  },
  inlineIconFileInfo: {
    marginTop: '-4px !important',
  },
  actionBar: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '2px',
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.4)}`,
    cursor: 'pointer',
    height: '48px',
    padding: '6px 12px',
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
    width: '52px',
  },
  actionIcon: {
    height: '24px',
    margin: '6px 0',
    width: '24px',
  },
  imageResizedModal: {
    width: '740px',
    height: '476px',
  },
  resizedImage: {
    background: colorWithAlpha(theme.palette.black, 0.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '380px',

    '& img': {
      maxWidth: '740px',
      maxHeight: '380px',
      width: 'max-content',
      height: 'max-content',
    },
  },
  content: {
    height: '96px',
  },
  text: {
    padding: '24px 0 0 24px',
  },
  resizedImageFormContent: {
    paddingTop: 0,

    '& > div': {
      marginBottom: 0,
      padding: 0,
    },
  },
  resizedImageAction: {
    background: theme.palette.white,
  },
});

const ImageUpLoader = ({
                         sx,
                         actionSx,
                         activeUploaderName,
                         name,
                         title,
                         image: initialImage,
                         activeDragPadding,
                         allowDelete = true,
                         display,
                         isIcon,
                         showImage,
                         showImageName,
                         onDragEnter,
                         onDragLeave,
                         onImageUpdated,
                         onImageDeleted,
                         acceptedFiles,
                         minFileSize,
                         maxFileSize,
                         fileInfo,
                         minHeight,
                         maxHeight,
                         minWidth,
                         maxWidth,
                         previewWidth,
                         previewHeight,
                         simulateDrag,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { toastNotificationErrorHook } = useToast();
  const [image, setImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  const handleDelete = useCallback(() => {
    onImageDeleted(image);
    setImage(null);
  }, [image, onImageDeleted]);

  const handleImageResizedConfirmation = (img) => {
    setImage(img);
    onImageUpdated({
      file: img,
      url: img,
    });
    setResizedImage(null);
  };

  const imageRender = useMemo(
    () => (
      <>
        {allowDelete && (
          <Box className="actionBar" sx={{ ...styles.actionBar, left: `${previewWidth / 2 - 12}px` }}>
            <IconButton
              sx={styles.actionIcon}
              onClick={handleDelete}
              onMouseDown={handleDelete}
              aria-label="Delete Image"
              size="large"
            >
              <Delete sx={styles.actionIcon} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            ...styles.imageWrap,
            width: `${previewWidth}px`,
            height: `${previewHeight}px`,
          }}
        >
          {(image?.url || image) && (
            <CardMedia
              image={image?.url || image}
              alt="Image"
              sx={{
                ...styles.image,
                width: `${previewWidth}px`,
                height: `${previewHeight}px`,
              }}
            />
          )}
        </Box>
        {showImageName && (image?.name || image?.file?.name) && (
          <Typography
            variant="body2"
            sx={{ ...styles.imageName, width: `${previewWidth}px` }}
          >
            {image?.name || image?.file?.name || ''}
          </Typography>
        )}
      </>
    ),
    [
      allowDelete,
      styles,
      handleDelete,
      image,
      previewHeight,
      previewWidth,
      showImageName,
    ],
  );

  const ctaRender = useMemo(
    () => (
      <Typography variant="body1" sx={styles.dropZoneTitle}>
        Drag an image into the browser or {display === 'stacked' ? <br /> : ''}
        <Link>browse your device</Link> to
        {!image
          ? ' upload an '
         : display === 'inline'
          ? ' replace the uploaded '
         : (
          <>
            {' '}
            replace the
            <br />
            uploaded{' '}
          </>
        )}
        image
      </Typography>
    ),
    [styles.dropZoneTitle, display, image],
  );

  const fileInfoRender = useMemo(
    () => (
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            ...styles.fileInfo,
            ...display === 'inline'
              && isIcon
              && (image?.url || image)
              && styles.inlineIconFileInfo,
          }}
        >
          {fileInfo}
        </Typography>
      </Grid>
    ),
    [styles, display, fileInfo, image, isIcon],
  );

  const onDrop = useCallback(
    async (files) => {
      if (files.length > 1) {
        toastNotificationErrorHook('Only one image is allowed at a time.');
      } else {
        const onImageResized = (image) => {
          setResizedImage(image);
        };
        handleOnDrop(
          files,
          ([img]) => {
            const { file, url } = img;
            setImage(img);
            onImageUpdated({
              file,
              url,
            });
          },
          toastNotificationErrorHook,
          minWidth,
          minHeight,
          maxWidth,
          maxHeight,
          onImageResized,
        );
      }
    },
    [
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      onImageUpdated,
      toastNotificationErrorHook,
    ],
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      handleDropRejected(
        acceptedFiles,
        rejectedFiles,
        minFileSize,
        maxFileSize,
        toastNotificationErrorHook,
      );
    },
    [acceptedFiles, minFileSize, maxFileSize, toastNotificationErrorHook],
  );

  const renderDropzoneContent = ({
                                   isDragActive,
                                   getRootProps,
                                   getInputProps,
                                 }) => (
    <Box
      sx={{
        ...isDragActive
          ? {
            ...styles.dragActiveContainer,
            ...actionSx,
            padding: Array.isArray(activeDragPadding)
              ? `${activeDragPadding}px`
              : `${activeDragPadding}px 0`,
          }
          : styles.dragInactiveContainer,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && (
        <>
          <img src={DropImageIcon} sx={styles.dragIcon} alt="Drop zone" />
          <Box
            className="MuiTypography-root MuiTypography-h1"
            sx={styles.dragActiveTitle}
          >
            <Link>Drop Image Here</Link>
          </Box>
        </>
      )}
      {!isDragActive && (
        <Grid container sx={styles.container}>
          {showImage && !!image && (
            <Grid
              item
              xs={display === 'inline' ? 3 : 12}
              className={allowDelete ? ' deletable' : ''}
              sx={{
                ...styles.imageContainer,
                width: `${previewWidth + 24}px`,
                height: allowDelete ? 'auto' : `${previewHeight + 24}px`,
              }}
            >
              {imageRender}
            </Grid>
          )}
          <Grid
            item
            xs={showImage && !!image && display === 'inline' ? 9 : 12}
            sx={{
              ...styles.dropZoneContent,
              height: display === 'inline' ? previewHeight : 'auto',
              ...showImage && !!image && styles.dropZoneContentWithImage,
              ...display === 'stacked'
                && !allowDelete
                && !showImageName && {
                  // For icon
                  marginTop: '24px',
                },
            }}
          >
            <Box sx={styles.dropZoneIcon}>
              <Publish sx={styles.uploadIcon} />
            </Box>
            <Grid container>
              <Grid item xs={12}>
                {ctaRender}
              </Grid>
              {display === 'inline' && !isIcon && fileInfoRender}
            </Grid>
          </Grid>
          {(display === 'stacked' || isIcon) && fileInfoRender}
        </Grid>
      )}
    </Box>
  );

  return (
    <Grid container sx={sx}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="body1" sx={styles.heading}>
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Dropzone
          sx={styles.root}
          name={name}
          accept={acceptedFiles}
          minSize={minFileSize}
          maxSize={maxFileSize}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
          onDragEnter={() => onDragEnter(name)}
          onDragLeave={() => onDragLeave(name)}
          data-test="changeImageDropzone"
        >
          {({ getRootProps, getInputProps, isDragActive }) => renderDropzoneContent({
              isDragActive:
                (isDragActive
                  && (activeUploaderName && name
                    ? activeUploaderName === name
                    : true))
                || simulateDrag,
              getRootProps,
              getInputProps,
            })
          }
        </Dropzone>
      </Grid>
      {!!resizedImage && (
        <GenericModal
          open={true}
          body={
            <Grid container sx={styles.imageResizedModal}>
              <Grid item xs={12} sx={styles.resizedImage}>
                <img src={resizedImage} alt="Resized" />
              </Grid>
              <Grid item xs={12} sx={styles.content}>
                <Typography variant="h1" sx={styles.text}>
                  Content Preview
                </Typography>
              </Grid>
            </Grid>
          }
          centered={false}
          confirmText="Close"
          disableCancel={true}
          disableClose={true}
          actionSx={styles.resizedImageAction}
          formContentSx={styles.resizedImageFormContent}
          handleConfirm={() => handleImageResizedConfirmation(resizedImage)}
        />
      )}
    </Grid>
  );
};

ImageUpLoader.propTypes = {
  sx: PropTypes.object,
  activeSx: PropTypes.object,
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  activeDragPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  activeUploaderName: PropTypes.string,
  allowDelete: PropTypes.bool,
  display: PropTypes.oneOf(['inline', 'stacked']),
  isIcon: PropTypes.bool,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onImageUpdated: PropTypes.func.isRequired,
  onImageDeleted: PropTypes.func,
  showImage: PropTypes.bool,
  showImageName: PropTypes.bool,
  acceptedFiles: PropTypes.object,
  minFileSize: PropTypes.number,
  maxFileSize: PropTypes.number,
  fileInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
};

ImageUpLoader.defaultProps = {
  sx: {},
  activeSx: {},
  name: undefined,
  title: null,
  image: null,
  activeDragPadding: 54,
  activeUploaderName: null,
  allowDelete: true,
  display: 'inline',
  isIcon: false,
  showImage: false,
  showImageName: true,
  acceptedFiles: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  minFileSize: 1,
  maxFileSize: 3000000,
  onImageDeleted: () => {},
  fileInfo: (
    <span>
      Supported files include jpeg and png. File size should be no larger than
      3MBs. Image dimensions must be at least {MINIMUM_IMAGE_WIDTH}px by{' '}
      {MINIMUM_IMAGE_HEIGHT}px.
    </span>
  ),
  minWidth: MINIMUM_IMAGE_WIDTH,
  minHeight: MINIMUM_IMAGE_HEIGHT,
  previewWidth: 209,
  previewHeight: 133,
  onDragEnter: () => {},
  onDragLeave: () => {},
};

export default ImageUpLoader;
