import GenericFormModal from 'components/genericModal/genericFormModal';
import { useUpdateNetworkEnablement } from 'hooks/dataHooks/useNetworkConfiguration';
import useToast from 'hooks/useToast';
import { makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import NetworkModalContent from './detail/networkModalContent';

const buildStyles = () => ({
  modal: {
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '20px',
    },
    '& .MuiPaper-root': {
      minWidth: '584px',
    },
  },
});

const EditNetworkModal = ({ network, onClose, onSave }) => {
  const styles = buildStyles();
  const { toastNotificationErrorHook } = useToast();
  const [updateNetworkEnablement] = useUpdateNetworkEnablement(network.id);
  const validate = useMemo(
    () => makeValidate(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          textIdentifier: Yup.string().required('Text Identifier is required'),
          sdkEnabled: Yup.boolean().when(['mobileEnabled', 'kioskEnabled'], {
            is: false,
            then: (schema) => schema.oneOf([true]),
          }),
          mobileEnabled: Yup.boolean().required(),
          kioskEnabled: Yup.boolean().required(),
        }),
      ),
    [],
  );

  return (
    <GenericFormModal
      open
      title="Edit Network"
      sx={styles.modal}
      formParams={{
        initialValues: {
          ...network,
        },
        onSubmit: async ({ kioskEnabled, sdkEnabled, mobileEnabled }) => {
          try {
            const response = await updateNetworkEnablement({
              variables: {
                input: {
                  kioskEnabled,
                  sdkEnabled,
                  mobileEnabled,
                },
              },
            });

            if (!response.errors) {
              onSave({
                ...network,
                kioskEnabled,
                sdkEnabled,
                mobileEnabled,
                configuration: {
                  ...network.configuration,
                },
              });
            } else {
              const { errors } = response;
              const errorText = errors
                .map((e) => e.extensions?.debug?.message)
                .join('; ');
              toastNotificationErrorHook(
                `Error updating ${network.name}: ${errorText}`,
              );
            }
          } catch (e) {
            toastNotificationErrorHook(`Error updating ${network.name}: ${e}`);
          }
        },
        validate,
      }}
      body={<NetworkModalContent edit />}
      confirmText="Save Changes"
      handleClose={onClose}
    />
  );
};

EditNetworkModal.propTypes = {
  network: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default React.memo(EditNetworkModal);
