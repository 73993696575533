import { Box, Grid } from '@mui/material';
import { TextField as FinalTextField } from 'mui-rff';
import React from 'react';
import { Field } from 'react-final-form';

import Condition from 'components/forms/condition';
import InfoPanel from 'components/infoPanel/infoPanel';
import RichTextField from 'components/richTextField/richTextField';
import TextField from 'components/textField/textField';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { capitalize } from 'helpers/lang-util';
import FormSubSection from 'components/forms/formSubSection';
import ColorPalette from 'pages/gozio_colors';

const Details = ({ data }) => (
  <>
    <FormSubSection name="Location ID">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <FinalTextField
            label={getFormLabel('Gozio ID', true)}
            name="externalId"
            variant="outlined"
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </FormSubSection>
    <FormSubSection name="Names">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={6}>
          <FinalTextField
            label={getFormLabel(
              `${
                data?.designation === 'poi'
                  ? 'POI'
                  : capitalize(data?.designation)
              } Name`,
              true,
            )}
            name="name"
            variant="outlined"
          />
        </Grid>
        <Condition when="langES" is={true}>
          <Grid item xs={6}>
            <FinalTextField
              label={getFormLabel(
                `${
                  data?.designation === 'poi'
                    ? 'POI'
                    : capitalize(data?.designation)
                } Name`,
                false,
                true,
              )}
              name="nameES"
              variant="outlined"
            />
          </Grid>
        </Condition>
        <Grid item xs={6}>
          <Field name="shortName">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Short Name', false)}
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Condition when="langES" is={true}>
          <Grid item xs={6}>
            <Field name="shortNameES">
              {({ input, meta }) => (
                <TextField
                  label={getFormLabel('Short Name', false, true)}
                  charLimit={40}
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  {...input}
                  fullWidth
                />
              )}
            </Field>
          </Grid>
        </Condition>
      </Grid>
    </FormSubSection>
    {data?.designation !== 'floor' && (
      <FormSubSection name="Description">
        <Grid container direction="row" spacing={3}>
          <Grid item xs>
            <RichTextField name="description" label="Description" />
          </Grid>
          <Condition when="langES" is={true}>
            <Grid item xs>
              <RichTextField
                name="descriptionES"
                label="Description (Spanish)"
              />
            </Grid>
          </Condition>
        </Grid>
      </FormSubSection>
    )}
    {data?.designation !== 'site' && (
      <FormSubSection name="Parent Information" sx={{ marginTop: '14px' }}>
        <Box sx={{ marginTop: '-8px', marginBottom: '20px' }}>
          <InfoPanel
            title={
              'The information below defines the attached parent site. Making changes to this information may impact other linked locations.'
            }
          />
        </Box>
        <Grid container direction="row" spacing={3}>
          {data.parentSite?.name && (
            <Grid item xs={6}>
              <TextField
                label={
                  <span style={{ color: ColorPalette.grey[600] }}>
                    * Site Name
                  </span>
                }
                value={`${parseByLang(data.parentSite.name)} (${
                  data.parentSite.isMapped ? 'Mapped' : 'Unmapped'
                })`}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
          )}
          {data.parentBuilding?.name && (
            <Grid item xs={6}>
              <TextField
                label={
                  <span style={{ color: ColorPalette.grey[600] }}>
                    * Building Name
                  </span>
                }
                value={`${parseByLang(data.parentBuilding.name)} (${
                  data.parentBuilding.isMapped ? 'Mapped' : 'Unmapped'
                })`}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
          )}
          {data.parentFloor?.name && (
            <Grid item xs={6}>
              <TextField
                label={
                  <span style={{ color: ColorPalette.grey[600] }}>
                    * Floor Name
                  </span>
                }
                value={`${parseByLang(data.parentFloor.name)} (${
                  data.parentFloor.isMapped ? 'Mapped' : 'Unmapped'
                })`}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </FormSubSection>
    )}
  </>
);

export default Details;
