import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  backGround: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'center',
  },
  h1: {
    color: colorWithAlpha(theme.palette.blue[300], 0.1),
    fontSize: '38vw',
    fontWeight: '900',
  },
  dialogWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  textDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitleText: {
    marginBottom: '28px',
    textAlign: 'center',
  },
});

const Page404 = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const path = '/';

  return (
    <Box sx={styles.root}>
      <Box sx={styles.backGround}>
        <Typography variant="h1" sx={styles.h1}>
          404
        </Typography>
      </Box>
      <Box sx={styles.dialogWrap}>
        <Box sx={styles.textDialog}>
          <Typography variant="h1" className="hero">
            This is a bit embarrassing
          </Typography>
          <Box sx={styles.subtitleText}>
            <Typography variant="body1">
              We aren't sure what happened, but we know this
              <br />
              isn't what you were hoping to find.
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={path}
            data-test="page404Page404Home"
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Page404;
