import GenericModal from 'components/genericModal/genericModal';
import { getLabelByLang } from 'helpers/lang-util';
import { hasChildren, isParent } from 'helpers/location-util';
import { useDeleteNetworkPlace } from 'hooks/dataHooks/useSites';
import DeleteLocationChildrenList from 'pages/locations/containers/detail/deleteLocationChildrenList';
import DeleteLocationDependenciesList from 'pages/locations/containers/detail/deleteLocationDependenciesList';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

let deletionFlowStage = 'initial';

const DeleteLocationModal = ({
  handleClose,
  locationData,
  onDeleteSuccess,
}) => {
  const handleDelete = () => {
    deletionFlowStage = 'initial';
    onDeleteSuccess();
  };
  const [confirmText, setConfirmText] = useState('Continue Deleting Location');
  const [confirmProgress, setConfirmProgress] = useState(false);
  const [deleteLocation] = useDeleteNetworkPlace();

  const handleLocationDeletionFlow = async () => {
    const { designation, id } = locationData;

    const goToDeletionStage = () => {
      setBody(<DeleteLocationDependenciesList />);
      deletionFlowStage = 'delete';
      setConfirmText('Yes, Continue Deleting Location');
    };

    if (deletionFlowStage === 'initial' && !isParent(designation)) goToDeletionStage();
    else if (deletionFlowStage === 'initial' && isParent(designation)) {
      if (hasChildren(locationData)) {
        setBody(<DeleteLocationChildrenList locationData={locationData} />);
        deletionFlowStage = 'confirmation';
      } else goToDeletionStage();
    } else if (deletionFlowStage === 'confirmation') goToDeletionStage();
    else if (deletionFlowStage === 'delete') {
      setConfirmProgress(true);
      const response = await deleteLocation({
        variables: {
          id,
        },
      });
      if (!response?.errors) {
        handleDelete();
        handleClose(id);
        setConfirmProgress(false);
      }
    }
  };

  const [body, setBody] = useState(
    `Are you sure you want to delete ${getLabelByLang(locationData?.name)}?`,
  );

  // preventKeyDownEvent is necessary to meet requirement
  // in wireframe P.3.2.2; see SCM-2012, SCM-2013, & SCM-2032
  // TODO: discuss accessibility implications?
  return (
    <GenericModal
      title="Delete Location"
      body={body}
      cancelText="Cancel"
      confirmProgress={confirmProgress}
      confirmText={confirmText}
      handleConfirm={handleLocationDeletionFlow}
      handleClose={() => {
        deletionFlowStage = 'initial';
        handleClose(null);
      }}
      preventKeyDownEvent
    />
  );
};

DeleteLocationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  locationData: PropTypes.object,
  onDeleteSuccess: PropTypes.func.isRequired,
};

DeleteLocationModal.defaultProps = {};

export default React.memo(DeleteLocationModal);
