import { Grid } from '@mui/material';
import ReduxField from 'components/_deprecated/reduxField';
import RenderInput from 'components/_deprecated/renderInput';
import PropTypes from 'prop-types';
import React from 'react';

const ReduxLanguageBasedInput = ({ options, filter, ...rest }) => {
  const displayOptions
    = filter && filter.length > 0
      ? options.filter((opt) => filter.includes(opt.lang))
      : options;
  return (
    <Grid>
      {(displayOptions || []).map((langObj) => {
        const { label, name, customTags, validators } = langObj;
        return (
          <ReduxField
            name={name}
            component={RenderInput}
            label={label}
            {...rest}
            {...customTags}
            key={name}
            customValidators={validators}
          />
        );
      })}
    </Grid>
  );
};

ReduxLanguageBasedInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lang: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      customTags: PropTypes.object,
      validators: PropTypes.arrayOf(PropTypes.func),
    }),
  ),
  filter: PropTypes.array,
};

export default ReduxLanguageBasedInput;
