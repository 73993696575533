import { capitalize } from 'helpers/lang-util';

export const hasChildren = (location) => (location?.buildings?.count || 0)
  + (location?.floors?.count || 0)
  + (location?.pois?.count || 0)
  + (location?.landmarks?.count || 0)
  > 0;
export const formatDesignation = (designation) => {
  if (designation === 'poi') return designation.toUpperCase();
  return capitalize(designation);
};

export const isParent = (designation) => ['building', 'site', 'floor'].includes(designation);

export const allowedCharacterRegex
  = /^[! "#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\\]^_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþ]+$/;
