import {
  buildI18NValues,
  getLanguageLabel,
  PLACEHOLDER_TITLE_MAP,
} from 'components/dashboard/cardLayouts/cardhelper';
import {
  buildLabelByLang,
  buildLanguageSchema,
  LANGUAGE_CODES,
} from 'helpers/lang-util';
import {
  buildIconUrl,
  isValidJSON,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import * as Yup from 'yup';

export const MY_FAVORITES_VISIBILITY_RULES = {
  EMPTY_LOCATIONS:
    '{\n  "==": [\n    {\n      "reduce": [\n        {\n          "var": "user.saved_place_ids"\n        },\n        {\n          "+": [\n            1,\n            {\n              "var": "accumulator"\n            }\n          ]\n        },\n        0\n      ]\n    },\n    0\n  ]\n}',
  EMPTY_PROVIDERS:
    '{\n  "==": [\n    {\n      "reduce": [\n        {\n          "var": "user.saved_provider_ids"\n        },\n        {\n          "+": [\n            1,\n            {\n              "var": "accumulator"\n            }\n          ]\n        },\n        0\n      ]\n    },\n    0\n  ]\n}',
  ACTIVE_LOCATIONS:
    '{\n  ">": [\n    {\n      "reduce": [\n        {\n          "var": "user.saved_place_ids"\n        },\n        {\n          "+": [\n            1,\n            {\n              "var": "accumulator"\n            }\n          ]\n        },\n        0\n      ]\n    },\n    0\n  ]\n}',
  ACTIVE_PROVIDERS:
    '{\n  ">": [\n    {\n      "reduce": [\n        {\n          "var": "user.saved_provider_ids"\n        },\n        {\n          "+": [\n            1,\n            {\n              "var": "accumulator"\n            }\n          ]\n        },\n        0\n      ]\n    },\n    0\n  ]\n}',
};

export const MY_FAVORITES_BUTTON_TEXT = {
  PROVIDERS: 'Add Providers',
  LOCATIONS: 'Add Locations',
};

export const MY_FAVORITES_I18N_FIELDS = [
  {
    key: 'buttonText',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'buttonTextEn',
    label: 'Button',
    charLimit: 15,
  },
  {
    key: 'buttonText',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'buttonTextEs',
    label: 'Button (Spanish)',
    charLimit: 15,
  },
  {
    key: 'title',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'titleEn',
    label: 'Title',
    required: true,
    charLimit: 40,
  },
  {
    key: 'title',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'titleEs',
    label: 'Title (Spanish)',
    charLimit: 40,
  },
  {
    key: 'subtitle',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'subtitleEn',
    label: 'Subtitle',
    required: true,
    charLimit: 40,
  },
  {
    key: 'subtitle',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'subtitleEs',
    label: 'Subtitle (Spanish)',
    charLimit: 40,
  },
];

export const MY_FAVORITES_DISPLAY_TYPES = Object.freeze({
  FAVORITE_LOCATIONS: 'favoriteLocations',
  FAVORITE_PROVIDERS: 'favoriteProviders',
});

export const buildMyFavoritesSchema = (languages) => Yup.object().shape(
  buildLanguageSchema(
    {
      cardGroupType: Yup.string(),
      empty_titleEn: Yup.string()
        .max(24, 'Title must be 24 characters or less')
        .required('Title is required'),
      empty_titleEs: Yup.string().max(
        24,
        'Title must be 24 characters or less',
      ),
      empty_subtitleEn: Yup.string()
        .max(80, 'Subtext must be 80 characters or less')
        .required('Subtitle is required'),
      empty_subtitleEs: Yup.string().max(
        80,
        'Subtext must be 80 characters or less',
      ),
      empty_buttonTextEn: Yup.string()
        .max(14, 'Button Text must be 14 characters or less')
        .required('Button Text is required'),
      empty_buttonTextEs: Yup.string().max(
        14,
        'Button Text must be 14 characters or less',
      ),
      empty_backgroundColor: Yup.string().matches(/^#([A-F0-9]){6}$/i, {
        message: 'Background color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
      empty_textColor: Yup.string().matches(/^#([A-F0-9]){6}$/i, {
        message: 'Text color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
      empty_visibilityRule: Yup.string()
        .nullable()
        .test('isJSON', 'Invalid JSON input', isValidJSON),

      active_titleEn: Yup.string()
        .max(24, 'Title must be 24 characters or less')
        .required('Title is required'),
      active_titleEs: Yup.string().max(
        24,
        'Title must be 24 characters or less',
      ),
      active_backgroundColor: Yup.string().matches(/^#([A-F0-9]){6}$/i, {
        message: 'Background color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
      active_textColor: Yup.string().matches(/^#([A-F0-9]){6}$/i, {
        message: 'Text color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
      active_visibilityRule: Yup.string()
        .nullable()
        .test('isJSON', 'Invalid JSON input', isValidJSON),
    },
    languages,
    {
      empty_buttonTextEn: LANGUAGE_CODES.ENGLISH,
      empty_buttonTextEs: LANGUAGE_CODES.SPANISH,
      empty_subtitleEn: LANGUAGE_CODES.ENGLISH,
      empty_subtitleEs: LANGUAGE_CODES.SPANISH,
      empty_titleEn: LANGUAGE_CODES.ENGLISH,
      empty_titleEs: LANGUAGE_CODES.SPANISH,
      active_titleEn: LANGUAGE_CODES.ENGLISH,
      active_titleEs: LANGUAGE_CODES.SPANISH,
    },
  ),
);

export const buildMyFavoritesInitialValues = (
  { cardGroupId, cardGroupType, card1, card2 },
  languages,
) => ({
  cardGroupId,
  cardGroupType,
  empty_id: card1.id,
  empty_displayType:
    card1.displayType ?? MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS,
  empty_titleEn:
    getLanguageLabel(card1.title, LANGUAGE_CODES.ENGLISH)
    ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.ENGLISH].label,
  empty_titleEs:
    getLanguageLabel(card1.title, LANGUAGE_CODES.SPANISH)
    ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.SPANISH].label,
  empty_subtitleEn:
    getLanguageLabel(card1.subtitle, LANGUAGE_CODES.ENGLISH) ?? '',
  empty_subtitleEs:
    getLanguageLabel(card1.subtitle, LANGUAGE_CODES.SPANISH) ?? '',
  empty_buttonTextEn:
    getLanguageLabel(card1.buttonText, LANGUAGE_CODES.ENGLISH) ?? '',
  empty_buttonTextEs:
    getLanguageLabel(card1.buttonText, LANGUAGE_CODES.SPANISH) ?? '',
  empty_iconUrl: card1.iconUrl,
  empty_backgroundColor: card1.backgroundColor,
  empty_textColor: card1.textColor,
  empty_visibilityRule:
    card1.visibilityRule
    ?? (card1.displayType === MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS
      ? MY_FAVORITES_VISIBILITY_RULES.EMPTY_LOCATIONS
      : MY_FAVORITES_VISIBILITY_RULES.EMPTY_PROVIDERS),

  active_id: card2.id,
  active_titleEn:
    getLanguageLabel(card2.title, LANGUAGE_CODES.ENGLISH)
    ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.ENGLISH].label,
  active_titleEs:
    getLanguageLabel(card2.title, LANGUAGE_CODES.SPANISH)
    ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.SPANISH].label,
  active_backgroundColor: card2.backgroundColor,
  active_textColor: card2.textColor,
  active_visibilityRule:
    card2.visibilityRule
    ?? (card1.displayType === MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS
      ? MY_FAVORITES_VISIBILITY_RULES.ACTIVE_LOCATIONS
      : MY_FAVORITES_VISIBILITY_RULES.ACTIVE_PROVIDERS),
});

export const submitMyFavoritesCard = async ({
                                              iconImage,
                                              updateCard,
                                              values,
                                            }) => {
  const linkValue
    = values.empty_displayType === MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS
    ? 'places'
    : 'doctors';
  return await updateCard({
    variables: {
      emptyCardId: values.empty_id,
      empty: {
        cardType: 'empty',
        displayType: values.empty_displayType,
        title: [
          { lang: LANGUAGE_CODES.ENGLISH, label: values.empty_titleEn },
          { lang: LANGUAGE_CODES.SPANISH, label: values.empty_titleEs },
        ],
        subtitle: [
          { lang: LANGUAGE_CODES.ENGLISH, label: values.empty_subtitleEn },
          { lang: LANGUAGE_CODES.SPANISH, label: values.empty_subtitleEs },
        ],
        buttonText: [
          { lang: LANGUAGE_CODES.ENGLISH, label: values.empty_buttonTextEn },
          { lang: LANGUAGE_CODES.SPANISH, label: values.empty_buttonTextEs },
        ],
        iconUrl: iconImage ? buildIconUrl({ iconImage }) : values.empty_iconUrl,
        backgroundColor: values.empty_backgroundColor,
        textColor: values.empty_textColor,
        visibilityRule: values.empty_visibilityRule,
        linkData: {
          type: 'in_app',
          data: {
            id: linkValue,
          },
        },
      },

      activeCardId: values.active_id,
      active: {
        cardType: 'active',
        title: [
          { lang: LANGUAGE_CODES.ENGLISH, label: values.active_titleEn },
          { lang: LANGUAGE_CODES.SPANISH, label: values.active_titleEs },
        ],
        backgroundColor: values.active_backgroundColor,
        textColor: values.active_textColor,
        visibilityRule: values.active_visibilityRule,
      },
    },
  });
};

export const mapMyFavoritesEditCardToPreview = ({
                                                  languages = [],
                                                  selectedCard,
                                                  values,
                                                }) => {
  const updatedEmptyValues = {
    backgroundColor: values.empty_backgroundColor,
    iconUrl: values.empty_iconUrl,
    textColor: values.empty_textColor,
    ...buildI18NValues(MY_FAVORITES_I18N_FIELDS, languages, values, 'empty_'),
  };

  const updatedActiveValues = {
    backgroundColor: values.active_backgroundColor,
    textColor: values.active_textColor,
    title: buildLabelByLang(values, 'active_titleEn', 'active_titleEs'),
  };
  return {
    card1: {
      ...selectedCard,
      ...updatedEmptyValues,
    },
    card2: {
      ...selectedCard,
      ...updatedActiveValues,
    },
  };
};
