import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewPane: {
    height: '503px',
    width: '876[x',
    padding: '12px',
    backgroundColor: theme.palette.grey[50],
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
  },
  previewInner: {
    width: '100%',
    height: '100%',
    border: '3px solid #FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    color: theme.palette.white,
    backgroundColor: theme.palette.grey[100],
  },
  logoPreview: {
    width: '100%',
    height: '100%',
  },
  imagePlaceholderWrapper: {
    width: '100%',
    height: '100%',
    padding: '16px',
    '& div': {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[500],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& svg': {
      color: theme.palette.grey[600],
    },
  },
});

const CtaPreview = ({ url }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ImagePreview = ({ url }) => {
    if (url) {
      return (
        <Box
          component="img"
          src={url}
          sx={styles.logoPreview}
          alt="Screensaver Logo"
        />
      );
    }
    return (
      <Box sx={styles.imagePlaceholderWrapper}>
        <Box>
          <AddPhotoAlternateIcon />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: '16px', width: '876px', padding: '0 16px' }}
      >
        Kiosk Preview
      </Typography>
      <Box sx={styles.previewPane}>
        <Box sx={styles.previewInner}>
          <ImagePreview url={url} />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CtaPreview);
