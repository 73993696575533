import { gql } from '@apollo/client';

export const CREATE_NETWORK_EXPLORE_PLANEL = gql`
  mutation createNetworkExplorePanel($input: NetworkExplorePanelInputCreate!) {
    createNetworkExplorePanel(input: $input) {
      id
      workspaces {
        name
      }
    }
  }
`;

export const DELETE_NETWORK_EXPLORE_PANEL = gql`
  mutation deleteNetworkExplorePanel($id: ID!) {
    deleteNetworkExplorePanel(id: $id) {
      id
      name
    }
  }
`;

export const FIND_NETWORK_EXPLORE_PANEL = gql`
  query findNetworkExplorePanel($workspaces: AutoGraphMixed) {
    findNetworkExplorePanel( sortBy: { name: asc } ) {
      edges {
        node {
          id
          name
          site {
            id
            name
          }
          validation { status }
          type
          color
          template
          actions {
            id
            name
            type
            list {
              id
              name
            }
            filterLinkDestBySite
            categories {
              id
              name
            }
            custom
            icon {
              image {
                id
                url (protocol: secure)
              }
            }
            order
          }
          workspaces {
            name
          }
        }
      }
    }
    findNetworkExplorePanelCount: findNetworkExplorePanel(where: { type: "site", workspaces: { name: $workspaces } }) {
      count
    }
    findNetworkPlaceCount: findNetworkPlace(where: { designation: "site", isMapped: true, workspaces: { name: $workspaces } }) {
      count
    }
  }
`;

export const FIND_SYSTEM_EXPLORE_PANEL = gql`
  query findSystemExplorePanel {
    findSystemExplorePanel {
        edges {
          node {
            id
            name
            color
            template
            validation { status }
            actions {
              id
              name
              type
              order
              categories {
                id
                name
              }
              icon {
                url (protocol: secure)
                image {
                  id
                  url (protocol: secure)
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_NETWORK_EXPLORE_PANEL = gql`
  query getNetworkExplorePanel($id: ID!) {
    getNetworkExplorePanel(id: $id) {
      id
      name
      validation {
        status
      }
      type
      color
      template
      site {
        id
        name
      }
      actions {
        id
        name
        type
        list {
          id
          name
        }
        filterLinkDestBySite
        categories {
          id
          name
        }
        custom
        icon {
          url (protocol: secure)
          image {
            id
          }
        }
        order
      }
      workspaces {
        name
      }
    }
  }
`;

export const REPLACE_NETWORK_EXPLORE_PANEL_ACTION = gql`
  mutation replaceNetworkExplorePanelAction(
    $panelId: ID!
    $actionId: ID!
    $input: NetworkExplorePanelActionInputUpdate!
  ) {
    replaceNetworkExplorePanelAction(panelId: $panelId, actionId: $actionId, input: $input) {
      id
      name
      icon {
        url (protocol: secure)
        image {
          id
        }
      }
      type
      list {
        id
        name
      }
      filterLinkDestBySite
      categories {
        id
        name
      }
      custom
      order
    }
  }
`;

export const REPLACE_SYSTEM_EXPLORE_PANEL_ACTION = gql`
  mutation replaceSystemExplorePanelAction(
    $panelId: ID!
    $actionId: ID!
    $input: SystemExplorePanelActionInputUpdate!
  ) {
    replaceSystemExplorePanelAction(panelId: $panelId, actionId: $actionId, input: $input) {
      id
      name
      icon {
        url (protocol: secure)
        image {
          id
        }
      }
      type
      list {
        id
        name
      }
      categories {
        id
        name
      }
      order
    }
  }
`;

export const UPDATE_NETWORK_EXPLORE_PANEL = gql`
  mutation updateNetworkExplorePanel($id: ID!, $input: NetworkExplorePanelInputUpdate!) {
    updateNetworkExplorePanel(id: $id, input: $input) {
      id
      name
      site {
        id
        name
      }
      color
      template
      validation { status }
      type
      actions {
        id
        name
        icon {
          url (protocol: secure)
          image {
            id
          }
        }
        type
        list {
          id
          name
        }
        filterLinkDestBySite
        categories {
          id
          name
        }
        custom
        order
      }
      workspaces {
        name
      }
    }
  }
`;

export const UPDATE_SYSTEM_EXPLORE_PANEL = gql`
  mutation updateSystemExplorePanel($id: ID!, $input: SystemExplorePanelInputUpdate!) {
    updateSystemExplorePanel(id: $id, input: $input) {
      id
      name
      color
      template
      validation { status }
      actions {
        id
        name
        type
        icon {
          url
          image {
            id
          }
        }
        order
      }
    }
  }
`;
