import { Archive, Create, Refresh } from '@mui/icons-material';
import { Box, ButtonBase, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { convertDateToString } from 'helpers/date-util';
import { rolePublicName } from 'helpers/permissions-util';
import { useUpdateUser } from 'hooks/dataHooks/useUsers';
import useToast from 'hooks/useToast';
import EditUserModal from 'pages/users/containers/editUserModal';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: { height: '100%', paddingTop: '8px' },
  restoreAction: {
    color: `${theme.palette.blue[400]} !important`,
    cursor: 'pointer',
  },
}));

const getUserName = (user) => `${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`;

const Table = ({
                 data = [],
                 status,
                 onUserCreated,
                 onUserUpdated,
                 loading,
                 search,
               }) => {
  const { networkId } = useParams();
  const { activeNetwork } = useContext(FlamingoContext);
  const isSSOEnabled
    = activeNetwork?.configuration?.features?.singleSignOn === 'on';
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState({});
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showRestoreUserModal, setShowRestoreUserModal] = useState(false);
  const { toastNotificationSuccessHook } = useToast();

  const [updateUser] = useUpdateUser(networkId);

  const getActions = (status, user) => {
    if (status === 'active') {
      return (
        <Box className="hoverUnhide">
          <Tooltip title="Update">
            <Create
              sx={{ marginRight: '8px' }}
              onClick={() => {
                setSelectedUser(user);
                setShowEditUserModal(true);
                if (onUserCreated) {
                  onUserCreated(true);
                }
              }}
            />
          </Tooltip>
          <Tooltip title="Archive">
            <Archive
              onClick={async () => {
                const response = await updateUser({
                  variables: {
                    id: user.id,
                    input: {},
                    meta: { action: 'uninvite' },
                  },
                });
                if (response?.data) {
                  if (onUserUpdated) {
                    onUserUpdated(user);
                  }
                  toastNotificationSuccessHook(
                    `${getUserName(user)} has been archived.`,
                  );
                }
              }}
            />
          </Tooltip>
        </Box>
      );
    }

    if (status === 'invited') {
      return (
        <Box className="hoverUnhide">
          <Tooltip title="Archive">
            <ButtonBase
              sx={{ marginRight: '8px' }}
              onClick={async () => {
                const response = await updateUser({
                  variables: {
                    id: user.id,
                    input: {},
                    meta: { action: 'uninvite' },
                  },
                });
                if (response?.data) {
                  if (onUserUpdated) {
                    onUserUpdated(user);
                  }
                  toastNotificationSuccessHook(
                    `${getUserName(user)} has been archived.`,
                  );
                }
              }}
            >
              <Archive />
            </ButtonBase>
          </Tooltip>
          <Tooltip title="Refresh">
            <ButtonBase
              onClick={async () => {
                const response = await updateUser({
                  variables: {
                    id: user.id,
                    input: {},
                    meta: { action: 'invite' },
                  },
                });
                if (response?.data) {
                  if (onUserUpdated) {
                    onUserUpdated(user);
                  }
                  toastNotificationSuccessHook(
                    `${getUserName(user)} has been ${
                      isSSOEnabled ? 'created' : 'invited'
                    }.`,
                  );
                }
              }}
            >
              <Refresh />
            </ButtonBase>
          </Tooltip>
        </Box>
      );
    }

    if (status === 'inactive') {
      return (
        <Box className="hoverUnhide">
          <Typography
            variant="body2"
            className={classes.restoreAction}
            onClick={() => {
              setSelectedUser(user);
              setShowRestoreUserModal(true);
            }}
          >
            RESTORE
          </Typography>
        </Box>
      );
    }
  };

  const memoizedColumns = useMemo(() => {
    const cols = [
      {
        accessor: 'id',
        Header: 'ID',
        alwaysHidden: true,
      },

      {
        accessor: 'name',
        Header: 'USER NAME',
      },
      {
        accessor: 'roles',
        Header: 'ROLE',
      },
      {
        accessor: 'email',
        Header: 'EMAIL',
      },
      {
        accessor: 'inviteDate',
        Header: 'DATE SENT',
        Cell: ({ cell }) => convertDateToString(cell.value),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        minWidth: 80,
        width: 80,
        disableSortBy: true,
        justifyRight: true,
      },
    ];

    if (status !== 'invited') {
      cols.splice(4, 1);
    }

    return cols;
  }, [status]);

  const matchUser = (user, search) => {
    if (!search) {
      return true;
    }
    if (user?.name?.toLowerCase().indexOf(search) >= 0) {
      return true;
    }
    if (user?.email?.toLowerCase().indexOf(search) >= 0) {
      return true;
    }
    if (user?.roles?.toLowerCase().indexOf(search) >= 0) {
      return true;
    }
    return false;
  };

  const memoizedData = useMemo(
    () => data
        .map((user) => ({
          id: user?.id,
          name: getUserName(user),
          email: user?.email?.address,
          roles: (user?.roles || [])
            .map((role) => rolePublicName(role.name))
            .join(', '),
          inviteDate: user?.invitedAt,
          actions: getActions(status, user),
        }))
        .filter((user) => matchUser(user, search)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, search, status],
  );

  return (
    <Box className={classes.root}>
      <GozioTable
        name="UsersTable"
        columns={memoizedColumns}
        data={memoizedData}
        loading={loading}
        sortBy={[
          status !== 'invited'
            ? { id: 'name', desc: false }
            : { id: 'inviteDate', desc: true },
        ]}
        countTitleText="User"
        emptyContent={<EmptyTable />}
      />
      {showEditUserModal && (
        <EditUserModal
          initialValues={{ user: selectedUser, state: 'edit' }}
          onUserUpdated={() => {
            onUserUpdated();
            setShowEditUserModal(false);
          }}
          onCancelled={() => {
            setShowEditUserModal(false);
          }}
        />
      )}
      {showRestoreUserModal && (
        <EditUserModal
          initialValues={{ user: selectedUser, state: 'restore' }}
          title="Restore User"
          confirmButtonText="Restore"
          onUserUpdated={() => {
            onUserUpdated();
            setShowRestoreUserModal(false);
          }}
          onCancelled={() => {
            setShowRestoreUserModal(false);
          }}
        />
      )}
    </Box>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onUserCreated: PropTypes.func,
  onUserUpdated: PropTypes.func,
  search: PropTypes.string,
};

export default Table;
