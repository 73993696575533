import { gql } from '@apollo/client';
import { fetchApollo } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import { isGlobalTemplate } from 'helpers/network-util';
import {
	filterLangs,
	fromi18nArrayEntries,
	fromi18nArrayEntriesErr,
	toError,
	toi18nArrayEntries,
} from 'helpers/transformer-util';
import { produce } from 'immer';
import _ from 'lodash';
import { fromCategoryRemote } from 'modules/categories';

export const TYPES = typeUtil([
	'READ_SYSTEM_TYPEAHEAD_RULES',
	'READ_SYSTEM_TYPEAHEAD_RULE_BY_ID',
	'CREATE_SYSTEM_TYPEAHEAD',
	'EDIT_SYSTEM_TYPEAHEAD',
	'DELETE_SYSTEM_TYPEAHEAD',
	'READ_NETWORK_TYPEAHEAD_RULES',
	'READ_NETWORK_TYPEAHEAD_RULE_BY_ID',
	'CREATE_NETWORK_TYPEAHEAD',
	'EDIT_NETWORK_TYPEAHEAD',
	'DELETE_NETWORK_TYPEAHEAD',
	'RESET_TYPEAHEAD_FLASH',
]);
const initialState = {
	page: {
		docs: {
			system: [],
			network: [],
		},
	},
	networkLoading: false,
	systemLoading: false,
	byId: {},
	flash: null,
	contentOpen: false,
};

export const fromTypeaheadRemoteErr = (errObject, remoteObject) => {
	const result = {};
	if (errObject.label) {
		result.name = fromi18nArrayEntriesErr(errObject.label, remoteObject.label);
	}
	if (errObject.includeTags) {
		result.includeTags = errObject.includeTags.map((tag, index) => {
			const remotetag = remoteObject.includeTags[index];
			return {
				...tag,
				name: fromi18nArrayEntriesErr(tag.name, remotetag.name),
			};
		});
	}
	return result;
};

export const fromTypeaheadRemote = (typeahead) => {
	const temp = _.cloneDeep(typeahead);
	const result = {
		...temp,
		name: fromi18nArrayEntries(typeahead.label),
		includeTags: typeahead.includeTags.map((tag) => ({
			...tag,
			name: fromi18nArrayEntries(tag.name),
		})),
		tags: typeahead.tags.map((tag) => ({
			...tag,
			name: fromi18nArrayEntries(tag.name),
		})),
		category: typeahead.category ? fromCategoryRemote(typeahead.category) : null,
		curatedList: typeahead.curatedList ? fromCategoryRemote(typeahead.curatedList) : null,
	};

	if (result.label) {
		delete result.label;
	}
	return result;
};
export const toTypeaheadRemote = (typeahead) => {
	const result = {
		...typeahead,
		label: filterLangs(toi18nArrayEntries(typeahead.name)),
	};
	result.includeTags = result.includeTags.map((tag) => produce(tag, (draft) => {
			delete draft.id;
			delete draft.__typename;
			draft.name = toi18nArrayEntries(draft.name);
		}),
	);
	if (result.name) {
		delete result.name;
	}
	delete result.__typename;
	return result;
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TYPES.READ_SYSTEM_TYPEAHEAD_RULES:
			return {
				...state,
				systemLoading: true,
			};
		case TYPES.READ_NETWORK_TYPEAHEAD_RULES:
			return {
				...state,
				networkLoading: true,
			};
		case TYPES.READ_SYSTEM_TYPEAHEAD_RULES_SUCCESS:
			return produce(state, (draft) => {
				const data = action.result.map((typeahead) => fromTypeaheadRemote(typeahead));
				draft.page.docs.system = data;
				draft.systemLoading = false;
			});
		case TYPES.READ_NETWORK_TYPEAHEAD_RULES_SUCCESS:
			return produce(state, (draft) => {
				const data = action.result.typeaheads.map((typeahead) => fromTypeaheadRemote(typeahead));
				draft.page.docs.network = data;
				draft.networkLoading = false;
			});
		case TYPES.READ_SYSTEM_TYPEAHEAD_RULE_BY_ID_SUCCESS:
		case TYPES.READ_NETWORK_TYPEAHEAD_RULE_BY_ID_SUCCESS:
			return produce(state, (draft) => {
				const remote = fromTypeaheadRemote(action.result);
				draft.byId[action.result.id] = remote;
			});
		case TYPES.EDIT_SYSTEM_TYPEAHEAD_SUCCESS:
			return produce(state, (draft) => {
				for (let i = 0; i < draft.page.docs.length; i++) {
					if (draft.page.docs.system[i].id === action.result.id) {
						draft.page.docs.system[i] = fromTypeaheadRemote(action.result);
					}
				}
				draft.flash = {
					status: 'success',
					message: 'Typeahead edited successfully',
				};
			});
		case TYPES.EDIT_NETWORK_TYPEAHEAD_SUCCESS:
			return produce(state, (draft) => {
				for (let i = 0; i < draft.page.docs.length; i++) {
					if (draft.page.docs.network[i].id === action.result.id) {
						draft.page.docs.network[i] = fromTypeaheadRemote(action.result);
					}
				}
				draft.flash = {
					status: 'success',
					message: 'Typeahead edited successfully',
				};
			});

		case TYPES.CREATE_SYSTEM_TYPEAHEAD_SUCCESS:
			return produce(state, (draftState) => {
				draftState.page.docs.system.push(fromTypeaheadRemote(action.result));
				draftState.flash = {
					status: 'success',
					message: 'Typeahead added successfully',
				};
			});
		case TYPES.CREATE_NETWORK_TYPEAHEAD_SUCCESS:
			return produce(state, (draftState) => {
				draftState.page.docs.network.push(fromTypeaheadRemote(action.result));
				draftState.flash = {
					status: 'success',
					message: 'Typeahead added successfully',
				};
			});
		case TYPES.DELETE_SYSTEM_TYPEAHEAD_SUCCESS:
			return produce(state, (draftState) => {
				_.remove(draftState.page.docs.system, (o) => o.id === action.result.id);
				draftState.flash = {
					status: 'success',
					message: 'Typeahead deleted successfully',
				};
			});
		case TYPES.DELETE_NETWORK_TYPEAHEAD_SUCCESS:
			return produce(state, (draftState) => {
				_.remove(draftState.page.docs.network, (o) => o.id === action.result.id);
				draftState.flash = {
					status: 'success',
					message: 'Typeahead deleted successfully',
				};
			});
		case TYPES.READ_SYSTEM_TYPEAHEAD_RULE_BY_ID_ERROR:
		case TYPES.EDIT_SYSTEM_TYPEAHEAD_ERROR:
		case TYPES.CREATE_SYSTEM_TYPEAHEAD_ERROR:
		case TYPES.DELETE_SYSTEM_TYPEAHEAD_ERROR:
		case TYPES.READ_NETWORK_TYPEAHEAD_RULE_BY_ID_ERROR:
		case TYPES.EDIT_NETWORK_TYPEAHEAD_ERROR:
		case TYPES.CREATE_NETWORK_TYPEAHEAD_ERROR:
		case TYPES.DELETE_NETWORK_TYPEAHEAD_ERROR:
		case TYPES.READ_SYSTEM_TYPEAHEAD_RULES_ERROR:
		case TYPES.READ_NETWORK_TYPEAHEAD_RULES_ERROR:
			return {
				...state,
				flash: {
					error: true,
					...toError(action.result, (errObject) => fromTypeaheadRemoteErr(errObject, action.remoteObject),
					),
				},
				networkLoading: false,
				systemLoading: false,
			};
		case TYPES.RESET_TYPEAHEAD_FLASH:
			return produce(state, (draftState) => {
				draftState.flash = null;
			});
		default:
			return state;
	}
};

export const readSystemTypeahead = (params) => (dispatch) => fetchApollo({
		doc: gql`
			query {
				findSystemTypeaheads {
					id
					label
					actionType
					matchBy
					sortBy
					tags {
						id
						name
						weight
					}
					category {
						id
						name
						tags {
							id
							name
							weight
						}
						parent {
							tags {
								id
								name
							}
						}
					}
					includeTags {
						id
						name
						weight
					}
					excludeCategoryTags
				}
			}
		`,
		method: 'query',
		queryName: 'findSystemTypeaheads',
		type: TYPES.READ_SYSTEM_TYPEAHEAD_RULES,
		params,
		dispatch,
	});

export const readNetworkTypeahead = (params) => (dispatch) => {
	if (isGlobalTemplate(params.networkId)) {
		return new Promise((resolve) => {
			dispatch({
				type: `${TYPES.READ_NETWORK_TYPEAHEAD_RULES}_SUCCESS`,
				key: JSON.stringify(params.params),
				result: { typeaheads: [] },
			});
			resolve();
		});
	}

	fetchApollo({
		doc: gql`
			query getNetwork($networkId: ID!) {
        getNetwork(id: $networkId) {
					typeaheads {
						id
						label
						actionType
						matchBy
						sortBy
						tags {
							id
							name
							weight
						}
						category {
							id
							name
							tags {
								id
								name
								weight
							}
						}
						curatedList {
							id
							name
						}
						includeTags {
							id
							name
							weight
						}
						excludeCategoryTags
						network {
							name
							id
						}
					}
				}
			}
		`,
		method: 'query',
		queryName: 'getNetwork',
		type: TYPES.READ_NETWORK_TYPEAHEAD_RULES,
		params,
		variables: { networkId: params.networkId },
		dispatch,
	});
};

export const readSystemTypeaheadById = (params) => (dispatch) => {
	const { id } = params;
	fetchApollo({
		doc: gql`
      query{
        findSystemTypeaheadById(id: "${id}"){
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags{
            id,
            name,
            weight
          },
          category{
            id,
            name,
            tags{
              id,
              name,
              weight
            },
            parent {
              tags {
                id,
                name
              }
            }
          },
          includeTags{
            id,
            name,
            weight
          },
          excludeCategoryTags
        }
      }
    `,
		method: 'query',
		queryName: 'findSystemTypeaheadById',
		type: TYPES.READ_SYSTEM_TYPEAHEAD_RULE_BY_ID,
		params,
		dispatch,
	});
};

export const readNetworkTypeaheadById = (params) => (dispatch) => {
	const { id } = params;
	return fetchApollo({
		doc: gql`
      query{
        findNetworkTypeaheadById(id: "${id}"){
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags{
            id,
            name,
            weight
          },
          category{
            id,
            name,
            tags{
              id,
              name,
              weight
            },
            parent {
              tags {
                id,
                name
              }
            }
          },
          curatedList {
            id
            name
          }
          includeTags{
            id,
            name,
            weight
          },
          excludeCategoryTags,
          network{
            name
          }
        }
      }
    `,
		method: 'query',
		queryName: 'findNetworkTypeaheadById',
		type: TYPES.READ_NETWORK_TYPEAHEAD_RULE_BY_ID,
		params,
		dispatch,
	});
};

const transformNetworkTypeaheadForMutation = (data) => JSON.stringify(data)
		.replace(/"(actionType|matchBy|sortBy|weight)":"([^"]+)"/g, (str) => {
			const matched = str.match(/([^:]+):"([^"]+)"/);
			return `${matched[1]}:${matched[2]}`;
		})
		.replace(/"([^":]+)":/g, (str) => {
			const matched = str.match(/"([^:"]+)":/);
			return `${matched[1]}:`;
		});

export const createSystemTypeahead = (params) => (dispatch) => {
	const remoteObject = toTypeaheadRemote(params.input);
	const input = transformNetworkTypeaheadForMutation(remoteObject);
	return fetchApollo({
		doc: gql`
      mutation {
        createSystemTypeahead(input: ${input}) {
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags{
            id,
            name,
            weight
          },
          category {
            id,
            name,
            tags{
              id,
              name,
              weight
            }
          },
          includeTags {
            id,
            name,
            weight
          },
          excludeCategoryTags
        }
      }
    `,
		method: 'mutate',
		queryName: 'createSystemTypeahead',
		type: TYPES.CREATE_SYSTEM_TYPEAHEAD,
		params,
		remoteObject,
		dispatch,
	});
};

export const createNetworkTypeahead = (params) => (dispatch) => {
	const remoteObject = toTypeaheadRemote(params.input);
	const input = transformNetworkTypeaheadForMutation(remoteObject);
	return fetchApollo({
		doc: gql`
      mutation {
        createNetworkTypeahead(input: ${input}) {
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags {
            id,
            name,
            weight
          },
          category {
            id,
            name,
            tags{
              id,
              name,
              weight
            }
          },
          curatedList {
            id
            name
          }
          includeTags {
            id,
            name,
            weight
          },
          excludeCategoryTags,
          network {
            name
          }
        }
      }
    `,
		method: 'mutate',
		queryName: 'createNetworkTypeahead',
		type: TYPES.CREATE_NETWORK_TYPEAHEAD,
		params,
		remoteObject,
		dispatch,
	});
};

export const editSystemTypeahead = (params) => (dispatch) => {
	const remoteObject = toTypeaheadRemote(params.input);
	delete remoteObject.id;
	const input = transformNetworkTypeaheadForMutation(remoteObject);
	return fetchApollo({
		doc: gql`
      mutation {
        replaceSystemTypeahead(input: ${input}, id: "${params.id}") {
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags {
            id,
            name,
            weight
          },
          category {
            id,
            name,
            tags{
              id,
              name,
              weight
            }
          },
          includeTags {
            id,
            name,
            weight
          },
          excludeCategoryTags
        }
      }
    `,
		method: 'mutate',
		queryName: 'replaceSystemTypeahead',
		type: TYPES.EDIT_SYSTEM_TYPEAHEAD,
		params,
		remoteObject,
		dispatch,
	});
};

export const editNetworkTypeahead = (params) => (dispatch) => {
	const remoteObject = toTypeaheadRemote(params.input);
	delete remoteObject.id;
	const input = transformNetworkTypeaheadForMutation(remoteObject);
	return fetchApollo({
		doc: gql`
      mutation {
        replaceNetworkTypeahead(input: ${input}, id: "${params.id}") {
          id,
          label,
          actionType,
          matchBy,
          sortBy,
          tags {
            id,
            name,
            weight
          },
          category {
            id,
            name,
            tags {
              id,
              name,
              weight
            }
          },
          curatedList {
            id
            name
          }
          includeTags {
            id,
            name,
            weight
          },
          excludeCategoryTags,
          network {
            name
          }
        }
      }
    `,
		method: 'mutate',
		queryName: 'replaceNetworkTypeahead',
		type: TYPES.EDIT_NETWORK_TYPEAHEAD,
		params,
		remoteObject,
		dispatch,
	});
};

export const deleteSystemTypeahead = (params) => (dispatch) => fetchApollo({
		doc: gql`
      mutation{
        deleteSystemTypeahead(id: "${params.id}"){
          id
        }
      }
    `,
		method: 'mutate',
		queryName: 'deleteSystemTypeahead',
		type: TYPES.DELETE_SYSTEM_TYPEAHEAD,
		params,
		dispatch,
	});

export const deleteNetworkTypeahead = (params) => (dispatch) => fetchApollo({
		doc: gql`
      mutation{
        deleteNetworkTypeahead(id: "${params.id}"){
          id
        }
      }
    `,
		method: 'mutate',
		queryName: 'deleteNetworkTypeahead',
		type: TYPES.DELETE_NETWORK_TYPEAHEAD,
		params,
		dispatch,
	});

export const resetTypeaheadFlash = () => (dispatch) => {
	dispatch({
		type: TYPES.RESET_TYPEAHEAD_FLASH,
	});
	return Promise.resolve();
};
