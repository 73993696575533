import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { wrapWithTooltip } from 'helpers/page-util';
import { getTableCountTitle } from 'helpers/table-util';
import ColorPalette from 'pages/gozio_colors';
import AddBuildingModal from 'pages/locations/containers/sections/addBuildingModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

const buildStyles = () => ({
  root: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: 0,
    overflowX: 'auto',
    marginTop: '30.5px',
  },
  addButton: {
    right: '16px',
    height: '32px',
    width: '32px',
  },
  content: { height: 'calc(100vh - 349px)' },
  panelContent: { height: '100%' },
});

const parseCategories = (netPlace) => {
  const list = netPlace?.categories?.map((cat) => getLabelByLang(cat.name)) || [
    '-',
  ];
  return list.join(', ');
};

const BuildingTable = ({ navigate, buildings, isUberAdmin, networkId }) => {
  const BUILDING_COLUMNS = useMemo(
    () => [
      {
        Header: 'Site ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Building Name',
        accessor: 'name', // accessor is the "key" in the data
        width: 150,
        onClick: (data) => {
          navigate(`/network/${networkId}/locations/building/${data.id}`);
        },
        sticky: 'left',
      },
      {
        Header: 'Mapping',
        accessor: 'mapping',
        width: 100,
      },
      {
        Header: 'Street Address',
        accessor: 'streetAddress',
        width: 150,
      },
      {
        Header: 'Categories',
        accessor: 'categories',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
      },
      {
        Header: <span>#&nbsp;of&nbsp;Flrs</span>,
        accessor: 'floors',
        width: 110,
        onClick: (data) => {
          navigate(
            `/network/${networkId}/locations/building/${data.id}?tab=floors`,
          );
        },
      },
      {
        Header: 'Pois',
        accessor: 'pois',
        width: 80,
      },
      {
        Header: 'LDMKs',
        accessor: 'ldmks',
        width: 90,
      },
      {
        Header: 'Default POI Name',
        accessor: 'default',
        width: 160,
      },
      ...isUberAdmin
        ? [
          {
            Header: 'Mapping ID',
            accessor: 'mapId',
            minWidth: 150,
            width: 150,
          },
        ]
        : [],
      {
        Header: 'Gozio ID',
        accessor: 'externalId',
        minWidth: 150,
        width: 150,
      },
    ],
    [isUberAdmin, navigate, networkId],
  );

  const data = buildings.map((building) => ({
    id: building?.node?.id,
    name: wrapWithTooltip(getLabelByLang(building?.node?.name)),
    mapping: building?.node?.isMapped ? 'Mapped' : 'Unmapped',
    streetAddress: wrapWithTooltip(
      building?.node?.geoLocation?.address?.street,
    ),
    status: capitalize(building?.node?.validation?.status || 'Incomplete'),
    categories: wrapWithTooltip(parseCategories(building?.node)),
    floors: building?.node?.floors?.count || 0,
    pois: building?.node?.pois?.count || 0,
    ldmks: building?.node?.landmarks?.count || 0,
    default:
      getLabelByLang(building?.node?.navigation?.defaultPlace?.name) || '',
    mapId: wrapWithTooltip(building?.node?.indoorLocation?.mapId),
    externalId: building?.node?.externalId,
  }));

  return (
    <GozioTable
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns={BUILDING_COLUMNS}
      data={useMemo(() => data, [data])}
      sortBy={[{ id: 'name', desc: false }]}
      sx={{
        borderRadius: '20px',
        boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
          ColorPalette.grey[600],
          0.4,
        )}`,
        width: 'calc(100% - 24px)',
      }}
      countTitle={getTableCountTitle(buildings?.length, 'Building')}
    />
  );
};

const BuildingsPanel = ({
                          site,
                          sx,
                          buildings = [],
                          networkId,
                          isUberAdmin,
                          ...rest
                        }) => {
  const styles = buildStyles();
  const { activeNetwork: network, handleNavigate }
    = useContext(FlamingoContext);
  const [showAddBuilding, setShowAddBuilding] = useState(false);
  const networkName = network?.humanName || 'Global Template';
  const networkLogo = network?.logo?.url;

  const handleBuildingAdded = (data) => {
    setShowAddBuilding(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/building/${data.id}`);
    }
  };

  return (
    <FlamingoPage
      pageName="Buildings"
      sx={{ ...styles.root, ...sx }}
      contentSx={{ ...buildings.length === 0 && styles.panelContent }}
      headerButtons={
        isUberAdmin
        && buildings.length > 0 && (
          <AddCircleButton
            onClick={() => setShowAddBuilding(true)}
            aria-label="Add Building"
            sx={styles.addButton}
            tooltipTitle="Add a Building"
          />
        )
      }
      {...rest}
    >
      {buildings.length > 0 ? (
        <Box sx={styles.content}>
          <BuildingTable
            buildings={buildings}
            isUberAdmin={isUberAdmin}
            networkId={networkId}
            navigate={handleNavigate}
          />
        </Box>
      ) : (
        <EmptyPanel
          title="There are No Buildings for This Site"
          buttonLabel="Add a Building"
          buttonShown={isUberAdmin}
          ButtonIcon={Add}
          buttonClicked={() => setShowAddBuilding(true)}
          sx={{ height: '100%', paddingBottom: '110px' }}
        />
      )}
      {showAddBuilding && (
        <AddBuildingModal
          handleClose={() => setShowAddBuilding(false)}
          handleConfirm={handleBuildingAdded}
          site={site}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}
    </FlamingoPage>
  );
};

BuildingsPanel.propTypes = {
  sx: PropTypes.object,
  networkId: PropTypes.string,
  buildings: PropTypes.array,
  isUberAdmin: PropTypes.bool,
  site: PropTypes.object,
};

BuildingsPanel.defaultProps = {
  sx: {},
};

export default BuildingsPanel;
