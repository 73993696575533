import useToast from 'hooks/useToast';

const useHandleError = (infoText) => {
  const { toastNotificationErrorHook } = useToast();

  const handleError = (
    graphQLErrors,
    networkError,
    customMessage,
    messagePrefix,
    options,
  ) => {
    const errors = [];
    if (messagePrefix) errors.push(messagePrefix);
    if (graphQLErrors || customMessage) {
      console.log(graphQLErrors);
      console.warn(
        `${infoText ? `[${infoText}] ` : ''}graphQLErrors:`,
        graphQLErrors,
      );
      graphQLErrors?.forEach((err) => {
        const { message, statusCode, extensions } = err; // , error, location, path
        if (statusCode === 404) {
          errors.push('The information requested was not found.');
        } else if (statusCode === 409) {
          if (options?.statusHandlers?.[409]) {
            const [proceed, msg] = options.statusHandlers[409](err);
            if (proceed) errors.push(msg || 'The item already exists.');
          } else errors.push('The item already exists.');
        } else if (statusCode === 406) {
          errors.push('Invalid data sent to server.');
        } else if (customMessage) {
          errors.push(
            `${customMessage}: ${extensions?.debug?.message || message}`,
          );
        } else {
          errors.push(extensions?.debug?.message || `Query Error: ${message}`);
        }
      });
      if (customMessage && errors.length === 0) {
        errors.push(customMessage);
      }
    }
    if (networkError) {
      console.warn(
        `${infoText ? `[${infoText}] ` : ''}networkError:`,
        networkError,
      );
      errors.push(`Network Error: ${networkError}`);
    }
    if (errors.length > 0) {
      toastNotificationErrorHook(errors.join(' '));
    }
  };
  return { handleError };
};

export default useHandleError;
