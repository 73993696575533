import { Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, Typography } from '@mui/material';
import Autocomplete from 'components/autocomplete/autoComplete';
import CategorySelection from 'components/categories/categorySelection';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang, hasSpanish } from 'helpers/lang-util';
import normalizePhone from 'helpers/normalizePhone';
import { useCategories, useInApp, useLists } from 'hooks/dataHooks';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';

const ACTION_LINK_TYPE_OPTIONS = [
  { id: 'category', label: 'Category' },
  { id: 'external_app', label: 'External App' },
  { id: 'in_app', label: 'In-App' },
  { id: 'list', label: 'List' },
  { id: 'website', label: 'Website' },
  { id: 'phone', label: 'Phone' },
];

const LinkDestination = ({
                           form,
                           formValues,
                           hasCustomLink,
                           sourceName = 'Custom List',
                           addCustomLink,
                           supportedLinkTypes,
                           supportedAppDestinations,
                           unsupportedAppDestinations,
                         }) => {
  const { categories: categoriesData, loading: categoriesQueryLoading }
    = useCategories();
  const { data: inAppDestinations, loading: systemQueryLoading } = useInApp();
  const { lists: listData } = useLists(true);
  const [appDestinations, setAppDestinations] = useState(null);
  const languages = useActiveNetworkLanguages();

  const hasPlatformSpecificFallbacksFlag = form
    ? form.getFieldState('hasPlatformSpecificFallbacks')?.value
    : formValues?.hasPlatformSpecificFallbacks;

  const isUberAdmin = useCheckGozioAdmin();
  const canAddCustomLink = addCustomLink !== null ? addCustomLink : isUberAdmin;
  useEffect(() => {
    if (inAppDestinations?.length > 0) {
      if (supportedAppDestinations?.length > 0) {
        setAppDestinations(
          inAppDestinations.filter(
            (dest) => supportedAppDestinations.includes(dest.id)
              && (!unsupportedAppDestinations?.length
                || !unsupportedAppDestinations.includes(dest.id)),
          ),
        );
      } else if (unsupportedAppDestinations?.length > 0) {
        setAppDestinations(
          inAppDestinations.filter(
            (dest) => !unsupportedAppDestinations.includes(dest.id),
          ),
        );
      } else {
        setAppDestinations(inAppDestinations);
      }
    }
  }, [inAppDestinations, supportedAppDestinations, unsupportedAppDestinations]);
  const linkOptions = useMemo(() => {
    const options = supportedLinkTypes
      ? ACTION_LINK_TYPE_OPTIONS.filter((action) => supportedLinkTypes.includes(action.id),
      )
      : [...ACTION_LINK_TYPE_OPTIONS];

    if (
      (canAddCustomLink || hasCustomLink)
      && (!supportedLinkTypes || supportedLinkTypes.includes('custom'))
    ) {
      options.push({
        id: 'custom',
        label: 'Custom Gozio Action Type',
        disabled: !canAddCustomLink,
      });
    }
    return options;
  }, [canAddCustomLink, supportedLinkTypes, hasCustomLink]);

  const isLinkTypeSupported = (linkType) => {
    if (!supportedLinkTypes) return true;
    return supportedLinkTypes.includes(linkType);
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="subtitle1">Quicklink</Typography>
      </Grid>
      <Grid item sx={{ marginBottom: '40px' }}>
        <Field name="linkType">
          {({ input }) => (
            <GozioSelect
              input={{ value: input.value, onChange: input.onChange }}
              label={getFormLabel('Quicklink Type', true)}
              options={linkOptions}
              disabled={!isUberAdmin && input.value === 'custom'}
            />
          )}
        </Field>
      </Grid>
      {isLinkTypeSupported('category') && (
        <Condition when="linkType" is="category">
          <FormSubSection
            name={getFormLabel('Selected Categories', true)}
            sx={{ paddingLeft: '24px', position: 'relative' }}
          >
            {categoriesQueryLoading ? (
              <CircularProgress />
            ) : (
              <Field name="categories">
                {({ input }) => (
                  <CategorySelection
                    input={input}
                    sourceName={sourceName}
                    categoriesData={categoriesData}
                  />
                )}
              </Field>
            )}
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('external_app') && (
        <Condition when="linkType" is="external_app">
          <FormSubSection
            name={getFormLabel('External App Details')}
            sx={{ paddingLeft: '24px' }}
          >
            <TextField
              label={getFormLabel('iOS URL', true)}
              name="externaliOSURL"
              variant="outlined"
              type="url"
            />
            <TextField
              label={getFormLabel('Android URL', true)}
              name="externalAndroidURL"
              variant="outlined"
              type="url"
              sx={{ marginTop: '34px' }}
            />
            <TextField
              label={getFormLabel(
                'Fallback URL',
                !hasPlatformSpecificFallbacksFlag,
              )}
              name="externalURL"
              variant="outlined"
              type="url"
              disabled={hasPlatformSpecificFallbacksFlag}
              sx={{ marginTop: '34px' }}
            />
            <Field name="hasPlatformSpecificFallbacks" type="checkbox">
              {({ input, meta }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      sx={{ padding: '4px 4px 4px 8px' }}
                      {...input}
                    />
                  }
                  label="Create Platform Specific Fallbacks"
                  sx={{ marginTop: '12px' }}
                />
              )}
            </Field>
            <Condition when="hasPlatformSpecificFallbacks" is={true}>
              <TextField
                label={getFormLabel('iOS URL Fallback', true)}
                name="fallbackiOSURL"
                variant="outlined"
                type="url"
                sx={{ marginTop: '26px' }}
              />
              <TextField
                label={getFormLabel('Android URL Fallback', true)}
                name="fallbackAndroidURL"
                variant="outlined"
                type="url"
                sx={{ marginTop: '34px' }}
              />
            </Condition>
          </FormSubSection>
          <FormSubSection
            name={getFormLabel('External App Preferences')}
            sx={{ paddingLeft: '24px' }}
          >
            <Field name="externalType">
              {({ input: { value, onChange }, meta }) => (
                <GozioRadioGroup
                  groupName="externalType"
                  alignment="vertical"
                  onChange={onChange}
                  options={[
                    {
                      label: 'Use Native App Browser',
                      value: 'native',
                      description:
                        'The web page will be rendered in an external browser causing the user to leave the application; otherwise the page will render within the mobile app.',
                    },
                    {
                      label: 'Open In-App Browser',
                      value: 'custom',
                      description:
                        'The web page will display in a module above the app so it presents as web with browser tools, but you can close it out and go back to app whenever you’d like.',
                    },
                    {
                      label: 'Open Embedded Web View',
                      value: 'web',
                      description:
                        'The webpage will appear as it if is part of the app. Only use this when the web page is very simple and appears as content only.',
                    },
                  ]}
                  value={value}
                />
              )}
            </Field>
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('in_app') && (
        <Condition when="linkType" is="in_app">
          <FormSubSection
            name={getFormLabel('In-App Details')}
            sx={{ paddingLeft: '24px' }}
          >
            {systemQueryLoading ? (
              <CircularProgress />
            ) : (
              <Field name="inAppDest">
                {({ input, meta }) => (
                  <>
                    <GozioSelect
                      sx={{
                        marginTop: '8px',
                      }}
                      input={input}
                      label={getFormLabel('Select an In-App Destination', true)}
                      error={meta.touched && !!meta.error}
                      options={
                        appDestinations?.length > 0
                          ? appDestinations
                          : [{ id: '', label: 'None' }]
                      }
                    />
                    {meta.touched && !!meta.error && (
                      <FormHelperText error>{meta.error}</FormHelperText>
                    )}
                  </>
                )}
              </Field>
            )}
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('list') && (
        <Condition when="linkType" is="list">
          <FormSubSection
            name={getFormLabel('List Details')}
            sx={{ paddingLeft: '24px' }}
          >
            <Field
              name="listDest"
              component={Autocomplete}
              label="Select a List"
              required={true}
              options={
                listData?.length > 0
                  ? listData.map(({ id, name }) => ({
                    id,
                    label: getLabelByLang(name),
                  }))
                  : [{ id: '', label: 'None' }]
              }
            />
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('website') && (
        <Condition when="linkType" is="website">
          <FormSubSection
            name={getFormLabel('Website Details')}
            sx={{ paddingLeft: '24px' }}
          >
            <TextField
              label={getFormLabel('Website URL', true)}
              name="webUrlEn"
              variant="outlined"
              type="url"
            />
            {hasSpanish(languages) && (
              <TextField
                label={getFormLabel('Website URL (Spanish)', false)}
                name="webUrlEs"
                variant="outlined"
                sx={{ marginTop: '24px' }}
                type="url"
              />
            )}
          </FormSubSection>
          <FormSubSection
            name={getFormLabel('Website Preferences')}
            sx={{ paddingLeft: '24px' }}
          >
            <Field name="webType">
              {({ input: { value, onChange }, meta }) => (
                <GozioRadioGroup
                  groupName="webType"
                  alignment="vertical"
                  onChange={onChange}
                  options={[
                    {
                      label: 'Use Native App Browser',
                      value: 'native',
                      description:
                        'The web page will be rendered in an external browser causing the user to leave the application; otherwise the page will render within the mobile app.',
                    },
                    {
                      label: 'Open In-App Browser',
                      value: 'custom',
                      description:
                        'The web page will display in a module above the app so it presents as web with browser tools, but you can close it out and go back to app whenever you’d like.',
                    },
                    {
                      label: 'Open Embedded Web View',
                      value: 'web',
                      description:
                        'The webpage will appear as it if is part of the app. Only use this when the web page is very simple and appears as content only.',
                    },
                  ]}
                  value={value}
                />
              )}
            </Field>
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('phone') && (
        <Condition when="linkType" is="phone">
          <FormSubSection
            name={getFormLabel('Phone')}
            sx={{ paddingLeft: '24px', marginBottom: '8px' }}
          >
            <Field name="phone" parse={normalizePhone} format={normalizePhone}>
              {({ input: { value, ...rest }, meta }) => (
                <TextField
                  label={getFormLabel('Phone Number', true)}
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  {...rest}
                  value={value || ''}
                  variant="outlined"
                />
              )}
            </Field>
          </FormSubSection>
        </Condition>
      )}
      {isLinkTypeSupported('custom') && (
        <Condition when="linkType" is="custom">
          <FormSubSection
            name={getFormLabel('Custom Gozio Action Type Details')}
            sx={{ paddingLeft: '24px', marginBottom: '8px' }}
          >
            <TextField
              label={getFormLabel('Open Field', true)}
              name="custom"
              variant="outlined"
              type="text"
              disabled={!canAddCustomLink}
            />
          </FormSubSection>
        </Condition>
      )}
    </Grid>
  );
};

LinkDestination.propTypes = {
  form: PropTypes.object,
  formValues: PropTypes.object,
  hasCustomLink: PropTypes.bool,
  sourceName: PropTypes.string,
  addCustomLink: PropTypes.bool,
  supportedLinkTypes: PropTypes.array,
  supportedAppDestinations: PropTypes.array,
  unsupportedAppDestinations: PropTypes.array,
};

LinkDestination.defaultProps = {
  form: null,
  formValues: null,
  hasCustomLink: false,
  addCustomLink: null,
  supportedLinkTypes: null,
  sourceName: 'Custom List',
  supportedAppDestinations: null,
  unsupportedAppDestinations: null,
};

export default React.memo(LinkDestination);
