import { Box, Button, Grid, Typography } from '@mui/material';
import Loading from 'components/loading/loading';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

export const LINK_DOMAIN_SCHEMA = {
  shortUrlDomain: {
    label: 'Default Link Domain',
    schema: Yup.string().required('Default Link Domain is required'),
  },
  alternateShortUrlDomain: {
    label: 'Alternate Short URL Domain',
    schema: Yup.string().required('Alternate Short URL Domain is required'),
  },
  customUrlDomain: { label: 'Custom URL Domain', schema: Yup.string() },
};

export const LINK_DOMAIN_ERROR_MAP = Object.keys(LINK_DOMAIN_SCHEMA).map(
  (key) => [
    key,
    {
      label: LINK_DOMAIN_SCHEMA[key].label,
      section: 'Link Domain',
    },
  ],
);

export const buildLinkDomainInitialValues = (data) => ({
  shortUrlDomain: data?.shortUrlDomain,
  alternateShortUrlDomain: data?.alternateShortUrlDomain,
  customUrlDomain: data?.customUrlDomain,
});

const LinkDomain = ({ disabled, loading, onSaved, sx }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Link Domain</Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="shortUrlDomain"
            label={getFormLabel('Default Link Domain', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="alternateShortUrlDomain"
            label={getFormLabel('Alternate Short URL Domain', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="customUrlDomain"
            label={getFormLabel('Custom URL Domain', true)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box sx={sx.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

LinkDomain.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
};

LinkDomain.defaultProps = {
  disabled: false,
  loading: false,
};

export default LinkDomain;
