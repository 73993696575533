import { Grid, Typography } from '@mui/material';
import Condition from 'components/forms/condition';
import GenericFormModal from 'components/genericModal/genericFormModal';
import { FlamingoContext } from 'contexts/flamingo';
import { getFormLabel } from 'helpers/form-util';
import { buildLabelByLang, buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateList } from 'hooks/dataHooks/useLists';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const buildStyles = () => ({
  heading: {
    fontSize: '28px',
    lineHeight: '40px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
});

const AddListModal = ({ handleClose, languages }) => {
  const styles = buildStyles();

  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const [createList] = useCreateList();

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          langES: Yup.boolean(),
          name: Yup.string().required('Name is required'),
          nameES: Yup.string(),
        },
        languages,
        { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  const handleListAdded = async (data) => {
    const response = await createList({
      variables: {
        input: {
          name: buildLabelByLang(data, 'name', 'nameES'),
        },
      },
    });
    const id = response?.data?.createNetworkCuratedList?.id;
    if (id) {
      handleNavigate(`/network/${networkId}/lists/${id}`);
    } else {
      handleClose();
    }
  };

  const hasSpanishLang = hasSpanish(languages);

  return (
    <GenericFormModal
      title={null}
      open={true}
      formParams={{
        onSubmit: handleListAdded,
        validate,
        initialValues: {
          langES: hasSpanishLang,
          name: '',
          ...hasSpanishLang && {
            nameES: '',
          },
        },
      }}
      body={
        <Grid sx={{ padding: '120px 32px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.heading}>
              Add New List
            </Typography>
            <Typography variant="subtitle1" sx={styles.describe}>
              Tell Us a Bit About the New List
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <TextField
              label={getFormLabel('List Name', true)}
              name="name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Condition when="langES" is={true}>
            <Grid item xs={12} sx={{ marginTop: '34px' }}>
              <TextField
                label={getFormLabel('List Name (Spanish)', false)}
                name="nameES"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Condition>
        </Grid>
      }
      confirmText="SAVE &amp; ADD"
      handleClose={handleClose}
    />
  );
};

AddListModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddListModal;
