import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const GET_NETWORK_NAVIGATION = gql`
  ${fragments.NETWORK_NAVIGATION_ATTRIBUTES}
  query getNetwork($id: ID!) {
    getNetwork(id: $id) {
      id
      navigation {
        ...networkNavigationAttributes
      }
      theme {
        colors {
          key
          value
        }
      }
    }
  }
`;

export const REMOVE_NETWORK_NAVIGATION_ACTION = gql`
  ${fragments.NETWORK_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $actionId: ID!) {
    removeNetworkNavigationAction(id: $id, actionId: $actionId) {
      ...networkNavigationAttributes
    }
  }
`;

export const UPDATE_NETWORK_NAVIGATION = gql`
  ${fragments.NETWORK_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $input: NetworkNavigationInputUpdate!) {
    updateNetworkNavigation(id: $id, input: $input) {
      ...networkNavigationAttributes
    }
  }
`;

export const UPDATE_NETWORK_NAVIGATION_ITEM = gql`
  ${fragments.NETWORK_NAVIGATION_ATTRIBUTES}
  mutation($id: ID!, $input: ActionLinkInputUpdate!) {
    setNetworkNavigationItem(id: $id, item: $input) {
      ...networkNavigationAttributes
    }
  }
`;

export const UPDATE_NETWORK_NAVIGATION_TAB = gql`
  ${fragments.NETWORK_NAVIGATION_ATTRIBUTES}
  mutation ($id: ID!, $input: ActionLinkInputUpdate!) {
    setNetworkNavigationTab(id: $id, tab: $input) {
      ...networkNavigationAttributes
    }
  }
`;
