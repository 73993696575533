import { colorWithAlpha } from 'helpers/color-util';

export const buildDashboardEditStyles = ({ theme }) => ({
  root: {
    height: '100%',
  },
  hidden: {
    position: 'absolute',
    top: '-9999px',
    transform: 'scale(0)',
  },
  header: {
    paddingTop: theme.spacing(7.5),
  },
  actionButtons: {
    textAlign: 'right',
  },
  tabs: {
    padding: '0 24px',
    boxShadow: `0px 4px 4px -3px ${colorWithAlpha(
      theme.palette.grey[600],
      0.3,
    )}`,
    height: '68px',
    position: 'absolute',
    top: 0,
    width: '100%',
    background: theme.palette.white,
    zIndex: 10,
  },
  tabPanel: {
    width: '100%',
    height: '100%',
    padding: '92px 24px 0',
    overflow: 'scroll',
  },
  content: {
    '& form': {
      width: '100%',
    },
  },
  optionArea: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3.5),
  },
  optionFields: {
    marginTop: theme.spacing(1),
  },
  fieldArea: {
    marginBottom: '18px',
  },
  formFields: {
    marginTop: theme.spacing(3.25),
  },
  field: {
    marginBottom: '20px',
  },
  fieldSection: {
    marginBottom: theme.spacing(5),
  },
  displayHeading: {
    marginBottom: theme.spacing(2),
  },
  displaySubheading: {
    color: theme.palette.grey[600],
  },
  datePicker: {
    height: '48px',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  alignmentIcon: {
    width: '36px',
    height: '36px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.blue[200],
    border: `1px solid ${theme.palette.blue[200]}`,
  },
  selectedAlignmentIcon: {
    background: theme.palette.blue[400],
    border: `1px solid ${theme.palette.blue[400]}`,
    color: theme.palette.white,
  },
  radioContainer: {
    marginTop: theme.spacing(1),
  },
  radios: {
    fontSize: '16px',
    color: theme.palette.grey[600],
  },
  backgroundFillText: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5),
  },
  colorFillText: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
    marginTop: theme.spacing(1),
  },
  selectMenuItem: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  selectMenuValue: {
    color: theme.palette.grey[600],
  },
  selectMenuOptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: theme.spacing(2.5),
  },
  selectMenuOptionSubtitle: {
    color: theme.palette.grey[600],
    fontSize: theme.spacing(1.75),
  },
});
