import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GozioTable from 'components/tables/gozioTable';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { maskPhone } from 'helpers/string-util';
import React, { useMemo } from 'react';

const buildStyles = ({ theme }) => ({
  headerStyle: {
    fontWeight: 400,
    lineHeight: '1.1875rem',
    color: theme.palette.grey[600],
    letterSpacing: 0,
    marginBottom: '10px',
  },
  table: {
    padding: 0,
    height: 'auto',
  },
});

const buildPreferences = (value) => {
  const prefs = [];
  if (value.showInDashboardView) prefs.push('Show in Dashboard View');
  if (value.data?.client === 'native') prefs.push('Use Native Browser');
  return prefs.join(', ');
};

const LinkActions = ({ val, canEdit, canDelete, editLink, deleteLink }) => (
  <Box className="hoverUnhide">
    {canEdit && (
      <Tooltip title="Edit">
        <Edit
          onClick={() => {
            editLink(val);
          }}
        />
      </Tooltip>
    )}
    {canDelete && (
      <Tooltip title="Delete">
        <Delete
          onClick={() => {
            deleteLink(val.idx);
          }}
          sx={{ marginLeft: '8px' }}
        />
      </Tooltip>
    )}
  </Box>
);

//
// Phone Links
const PhoneLinks = ({
                      values,
                      styles,
                      canEdit,
                      canDelete,
                      editLink,
                      deleteLink,
                      reorderRows,
                    }) => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 120,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 120,
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
        width: 120,
      },
      {
        Header: 'Preferences',
        accessor: 'preferences',
        width: 150,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 60,
        width: 60,
        disableSortBy: true,
        justifyRight: true,
      },
    ],
    [],
  );

  const processData = (values = [], lang = LANGUAGE_CODES.ENGLISH) => values.map((val) => ({
      idx: val.idx,
      order: val.order,
      name: getLabelByLang(val.name, lang),
      shortName: getLabelByLang(val.shortName, lang),
      phone: maskPhone(val.data.phone),
      preferences: buildPreferences(val),
      actions: (
        <LinkActions
          val={val}
          canEdit={canEdit}
          canDelete={canDelete}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => processData(values), [values]);

  if (!values || values.length === 0) return null;

  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Typography variant="body1" sx={styles.headerStyle}>
        Phone Number
      </Typography>
      <GozioTable
        columns={COLUMNS}
        data={data}
        tableSx={styles.table}
        hidePagination={true}
        draggable={true}
        onDrag={reorderRows}
      />
    </Box>
  );
};

//
// Website links
const WebsiteLinks = ({
                        values,
                        styles,
                        canEdit,
                        canDelete,
                        editLink,
                        deleteLink,
                        reorderRows,
                      }) => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
      },
      {
        Header: 'Preferences',
        accessor: 'preferences',
        maxWidth: 200,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 80,
        width: 80,
        disableSortBy: true,
        justifyRight: true,
      },
    ],
    [],
  );

  const processData = (values = [], lang = LANGUAGE_CODES.ENGLISH) => values.map((val) => ({
      idx: val.idx,
      order: val.order,
      name: getLabelByLang(val.name, lang),
      shortName: getLabelByLang(val.shortName, lang),
      preferences: buildPreferences(val),
      actions: (
        <LinkActions
          val={val}
          canEdit={canEdit}
          canDelete={canDelete}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => processData(values), [values]);

  if (!values || values.length === 0) return null;

  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Typography variant="body1" sx={styles.headerStyle}>
        Website
      </Typography>
      <GozioTable
        columns={COLUMNS}
        data={data}
        tableSx={styles.table}
        hidePagination={true}
        draggable={true}
        onDrag={reorderRows}
      />
    </Box>
  );
};

//
// Appt links
const ApptLinks = ({
                     values,
                     styles,
                     canEdit,
                     canDelete,
                     editLink,
                     deleteLink,
                     reorderRows,
                   }) => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 150,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 150,
      },
      {
        Header: 'Preferences',
        accessor: 'preferences',
        width: 150,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 80,
        width: 80,
        disableSortBy: true,
        justifyRight: true,
      },
    ],
    [],
  );

  const processData = (values = [], lang = LANGUAGE_CODES.ENGLISH) => values.map((val) => ({
      idx: val.idx,
      order: val.order,
      name: getLabelByLang(val.name, lang),
      shortName: getLabelByLang(val.shortName, lang),
      preferences: buildPreferences(val),
      actions: (
        <LinkActions
          val={val}
          canEdit={canEdit}
          canDelete={canDelete}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => processData(values), [values]);

  if (!values || values.length === 0) return null;

  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Typography variant="body1" sx={styles.headerStyle}>
        Appointment
      </Typography>
      <GozioTable
        columns={COLUMNS}
        data={data}
        tableSx={styles.table}
        hidePagination={true}
        draggable={true}
        onDrag={reorderRows}
      />
    </Box>
  );
};

//
// InApp links
const InAppLinks = ({
                      values,
                      styles,
                      appDestinations,
                      canEdit,
                      canDelete,
                      editLink,
                      deleteLink,
                      reorderRows,
                    }) => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 150,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 150,
      },
      {
        Header: 'Link Location',
        accessor: 'location',
        width: 150,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 80,
        width: 80,
        disableSortBy: true,
        justifyRight: true,
      },
    ],
    [],
  );

  const processData = (values = [], lang = LANGUAGE_CODES.ENGLISH) => values.map((val) => ({
      idx: val.idx,
      order: val.order,
      name: getLabelByLang(val.name, lang),
      shortName: getLabelByLang(val.shortName, lang),
      location:
        appDestinations?.find((dest) => dest.id === val.data.id)?.label
        || val.data.id,
      actions: (
        <LinkActions
          val={val}
          canEdit={canEdit}
          canDelete={canDelete}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ),
    }));

  const data = useMemo(
    () => processData(values),
    [values, appDestinations], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!values || values.length === 0) return null;
  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Typography variant="body1" sx={styles.headerStyle}>
        In-App Link
      </Typography>
      <GozioTable
        columns={COLUMNS}
        data={data}
        tableSx={styles.table}
        hidePagination={true}
        draggable={true}
        onDrag={reorderRows}
      />
    </Box>
  );
};

//
// Custom links
const CustomLinks = ({
                       values,
                       styles,
                       isUberAdmin,
                       editLink,
                       deleteLink,
                       reorderRows,
                     }) => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 150,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 150,
      },
      {
        Header: 'Details',
        accessor: 'details',
        width: 150,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 80,
        width: 80,
        disableSortBy: true,
        justifyRight: true,
      },
    ],
    [],
  );

  const processData = (values = [], lang = LANGUAGE_CODES.ENGLISH) => values.map((val) => ({
      idx: val.idx,
      order: val.order,
      name: getLabelByLang(val.name, lang),
      shortName: getLabelByLang(val.shortName, lang),
      details: val.data.url,
      actions: (
        <LinkActions
          val={val}
          canEdit={isUberAdmin}
          canDelete={isUberAdmin}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      ),
    }));

  const data = useMemo(() => processData(values), [values]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!values || values.length === 0) return null;

  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Typography variant="body1" sx={styles.headerStyle}>
        Custom Gozio Action Type
      </Typography>
      <GozioTable
        columns={COLUMNS}
        data={data}
        tableSx={styles.table}
        hidePagination={true}
        draggable={true}
        onDrag={reorderRows}
      />
    </Box>
  );
};

const sortOrder = (a, b) => a.order - b.order;

// main function
const ActionLinkList = ({
                          values,
                          editLink,
                          deleteLink,
                          reorderRows,
                          isUberAdmin,
                          appDestinations,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const subArrays = {};
  values.forEach((val) => {
    const type = val.type;

    if (!subArrays[type]) {
      subArrays[type] = [];
    }
    subArrays[type].push(val);
  });

  return (
    <>
      <PhoneLinks
        values={subArrays.phone?.sort(sortOrder)}
        styles={styles}
        canEdit
        canDelete
        editLink={editLink}
        deleteLink={deleteLink}
        reorderRows={reorderRows}
      />

      <WebsiteLinks
        values={subArrays.website?.sort(sortOrder)}
        styles={styles}
        canEdit
        canDelete
        editLink={editLink}
        deleteLink={deleteLink}
        reorderRows={reorderRows}
      />

      <ApptLinks
        values={subArrays.appointment?.sort(sortOrder)}
        styles={styles}
        canEdit
        canDelete
        editLink={editLink}
        deleteLink={deleteLink}
        reorderRows={reorderRows}
      />

      <InAppLinks
        values={subArrays.in_app?.sort(sortOrder)}
        styles={styles}
        appDestinations={appDestinations}
        canEdit
        canDelete
        editLink={editLink}
        deleteLink={deleteLink}
        reorderRows={reorderRows}
      />

      <CustomLinks
        values={subArrays.custom?.sort(sortOrder)}
        styles={styles}
        isUberAdmin={isUberAdmin}
        editLink={editLink}
        deleteLink={deleteLink}
        reorderRows={reorderRows}
      />
    </>
  );
};

ActionLinkList.propTypes = {};

ActionLinkList.defaultProps = {};

export default React.memo(ActionLinkList);
