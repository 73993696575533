import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FindingCareCard from 'components/dashboard/cardComponent/findingCareCard';
import { dndHover, isCardIncompleteForPackaging } from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const FindingCareCardLayout = ({
                                 id,
                                 index,
                                 card1,
                                 preview,
                                 hovered,
                                 moveCard,
                                 onCardClick,
                                 onDrag,
                                 onHover,
                                 canDragAndDrop,
                               }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
      },
      cardType: FindingCareCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'Finding Care card' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          <FindingCareCard
            title={card1.title}
            backgroundColor={card1.backgroundColor}
            incompleteForPackaging={isCardIncompleteForPackaging(card1)}
            textColor={card1.textColor}
            disabled={card1.disabled}
            quickLinksData={card1.quickLinksData}
            selected={card1.selected}
            clickHandler={() => onCardClick({ cardGroupId: id, key: 'card1', ...card1 })
            }
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

FindingCareCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    clickHandler: PropTypes.func,
    incompleteForPackaging: PropTypes.bool,
    linkBackgroundColor: PropTypes.string,
    quickLinksData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
        iconUrl: PropTypes.string,
        iconTint: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.object,
      }),
    ),
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

FindingCareCardLayout.defaultProps = {
  card1: {
    incompleteForPackaging: false,
    title: 'Card Title',
    clickHandler: () => {},
  },
  preview: false,
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default FindingCareCardLayout;
