import JSONLogicAuthorizer from '@gozio/json-logic-authorizer';
import React, { createContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

const [version, buildNumber, buildDate, commitHash]
= window.flamingo_info || [];

const initialState = {
  activeNetwork: {},
  authenticated: false,
  authorizer: null,
  buildInfo: {
    version,
    buildNumber: buildNumber?.match(/^-?\d+$/)
      ? parseInt(buildNumber) - 2100
      : buildNumber,
    buildDate,
    commitHash,
  },
  globalStates: {
    // Each global state is a map of networkId -> value pairs
    isLanguageSupportImportingMap: {},
    isLanguageSupportExportingMap: {},
  },
  layout: {
    openNetwork: false,
    openDrawer: true,
  },
  user: {},
  flash: null,
  globalTemplate: {
    id: 'Global Template',
    humanName: 'Global Template',
    name: null,
    configuration: null,
    logo: null,
  },
  networks: [],
  workspace: null,

  /**
   * Given a JSON Logic scope or an array of scopes, return true if the scope is allowed, or false otherwise.
   *
   * @param scope {String|Array} - A specific scope or an array of scopes to authorize
   * @param options {Object} - Relevant options for the scope(s)
   * @param options.consolidate {Boolean} - true if the list of scopes must all be allowed
   * @param options.data {Object} - Additional data to use when evaluating rules
   * @param options.map {Boolean} - true to return a map of the results, false to return the boolean for a single scope or an array for a list of scopes
   * @returns {Boolean} - true if allowed, false otherwise
   */
  authorize: (scopes, options = {}) => false,
};

const flamingoAuthorize = (jsonLogicAuthorizer) => {
  const authorize = (scopes, options = {}) => {
    const { consolidate, data, map } = options;
    if (consolidate && map) {
      throw new Error('authorize: Cannot consolidate and map permissions');
    }
    const normalizedScopes = Array.isArray(scopes) ? scopes : [scopes];
    const resultsMap = {};
    const resultsArray = [];
    normalizedScopes.forEach((scope) => {
      if (map) {
        resultsMap[scope] = jsonLogicAuthorizer.authorize(scope, data);
      } else {
        resultsArray.push(jsonLogicAuthorizer.authorize(scope, data));
      }
    });
    if (map) {
      return resultsMap;
    }
    if (resultsArray.length === 0) {
      return [false];
    }
    if (normalizedScopes.length === 1) {
      return resultsArray[0];
    }
    if (consolidate) {
      return !resultsArray.includes(false);
    }
    return resultsArray;
  };
  return authorize;
};

const reducer = (state, action) => {
  const { payload: newValue } = action;
  switch (action.type) {
    case 'SET_NETWORKS': {
      return {
        ...state,
        networks: newValue,
      };
    }
    case 'SET_WORKSPACE': {
      return {
        ...state,
        workspace: newValue,
      };
    }
    case 'UPDATE_AUTHORIZER_CONTEXT': {
      const { authorizer } = state;
      Object.assign(authorizer.context, { workspace: { name: newValue } });
      return {
        ...state,
        authorizer,
      };
    }
    case 'SET_USER':
      const { user } = newValue;
      const authorizer = new JSONLogicAuthorizer(user.jsonLogicPermissions, {
        user,
        roles: user.roles,
        workspace: {
          name: state.workspace,
        },
      });
      return {
        ...state,
        authenticated: newValue.authenticated,
        user: {
          ...state.user,
          ...newValue.user,
        },
        authorizer,
        authorize: flamingoAuthorize(authorizer),
      };
    case 'SET_LAYOUT':
      return {
        ...state,
        layout: {
          ...state.layout,
          ...newValue,
        },
      };
    case 'SET_ACTIVE_NETWORK':
      return {
        ...state,
        activeNetwork: {
          ...state.activeNetwork,
          ...newValue,
        },
      };
    case 'RESET_FLASH':
      return {
        ...state,
        flash: null,
      };
    case 'SET_FLASH_CUSTOM':
      return {
        ...state,
        flash: newValue,
      };
    case 'SET_FLASH_SUCCESS':
      return {
        ...state,
        flash: {
          status: 'success',
          message: newValue,
        },
      };
    case 'SET_FLASH_ERROR':
      return {
        ...state,
        flash: {
          error: true,
          status: 'error',
          message: newValue,
        },
      };
    case 'SET_LANGUAGE_SUPPORT_EXPORTING':
      return {
        ...state,
        globalStates: {
          ...state.globalStates,
          isLanguageSupportExportingMap: {
            ...state.globalStates.isLanguageSupportExportingMap,
            ...{ [newValue.networkId]: newValue.flag },
          },
        },
      };
    case 'SET_LANGUAGE_SUPPORT_IMPORTING':
      return {
        ...state,
        globalStates: {
          ...state.globalStates,
          isLanguageSupportImportingMap: {
            ...state.globalStates.isLanguageSupportImportingMap,
            ...{ [newValue.networkId]: newValue.flag },
          },
        },
      };
    default:
      return state;
  }
};

export const FlamingoContext = createContext(initialState);

export const FlamingoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // TODO: Remove this
  const handleNavigate = (path, options) => {
    navigate(path, options);
  };

  const setNetworks = (payload) => dispatch({
      type: 'SET_NETWORKS',
      payload: [...payload].sort((a, b) => a.humanName > b.humanName ? 1 : -1),
    });
  const setWorkspace = (payload) => dispatch({
      type: 'SET_WORKSPACE',
      payload,
    });
  const updateAuthorizerContext = (payload) => dispatch({
      type: 'UPDATE_AUTHORIZER_CONTEXT',
      payload,
    });
  const setUser = (payload) => dispatch({
      type: 'SET_USER',
      payload,
    });
  const setActiveNetwork = (payload) => dispatch({
      type: 'SET_ACTIVE_NETWORK',
      payload: {
        ...state.activeNetwork,
        ...payload,
      },
    });
  const setOpenDrawer = (payload) => dispatch({
      type: 'SET_LAYOUT',
      payload: {
        ...state.layout,
        openDrawer: payload,
      },
    });
  const setOpenNetwork = (payload) => dispatch({
      type: 'SET_LAYOUT',
      payload: {
        ...state.layout,
        openNetwork: payload,
      },
    });
  const resetNotification = (payload) => dispatch({
      type: 'RESET_FLASH',
      payload,
    });
  const sendCustomNotification = (payload) => dispatch({
      type: 'SET_FLASH_CUSTOM',
      payload,
    });
  const sendSuccessNotification = (payload) => dispatch({
      type: 'SET_FLASH_SUCCESS',
      payload,
    });
  const sendErrorNotification = (payload) => dispatch({
      type: 'SET_FLASH_ERROR',
      payload,
    });

  // Global State Handlers
  const setIsLanguageSupportExporting = (networkId, flag) => dispatch({
      type: 'SET_LANGUAGE_SUPPORT_EXPORTING',
      payload: { networkId, flag },
    });
  const setIsLanguageSupportImporting = (networkId, flag) => dispatch({
      type: 'SET_LANGUAGE_SUPPORT_IMPORTING',
      payload: { networkId, flag },
    });

  return (
    <FlamingoContext.Provider
      value={{
        ...state,
        layout: {
          ...state.layout,
          setOpenDrawer,
          setOpenNetwork,
        },
        activeNetwork: {
          ...state.activeNetwork,
          setActiveNetwork,
        },
        flash: {
          ...state.flash,
          resetNotification,
          sendCustomNotification,
          sendSuccessNotification,
          sendErrorNotification,
        },
        globalStates: {
          ...state.globalStates,
          setIsLanguageSupportExporting,
          setIsLanguageSupportImporting,
        },
        user: {
          ...state.user,
          setUser,
        },
        handleNavigate,
        setNetworks,
        setWorkspace,
        updateAuthorizerContext,
      }}
    >
      {children}
    </FlamingoContext.Provider>
  );
};
