import { useLazyQuery, useMutation, useSuspenseQuery } from '@apollo/client';
import { FlamingoContext } from 'contexts/flamingo';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  CREATE_NETWORK_CURATED_LIST,
  CREATE_NETWORK_CURATED_LIST_SECTION,
  //CREATE_NETWORK_CURATED_LIST_SECTION_LOCATION,
  DELETE_NETWORK_CURATED_LIST,
  DELETE_NETWORK_CURATED_LIST_SECTION,
  //DELETE_NETWORK_CURATED_LIST_SECTION_LOCATION,
  FIND_NETWORK_CURATED_LIST,
  FIND_NETWORK_CURATED_LIST_WITH_LOCATIONS,
  GET_NETWORK_CURATED_LIST,
  GET_NETWORK_CURATED_LIST_AVAILABLE_LOCATIONS,
  UPDATE_NETWORK_CURATED_LIST,
  UPDATE_NETWORK_CURATED_LIST_SECTION,
  //UPDATE_NETWORK_CURATED_LIST_SECTION_LOCATION_ORDER,
  UPDATE_NETWORK_CURATED_LIST_SECTIONS,
} from 'graphql/queries';

export const useLists = (onlyListsWithLocations = false) => {
  const { handleError } = useHandleError('List');
  const { data, error, refetch } = useSuspenseQuery(
    onlyListsWithLocations ? FIND_NETWORK_CURATED_LIST_WITH_LOCATIONS : FIND_NETWORK_CURATED_LIST,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    },
  );

  return {
    error,
    lists: data?.findNetworkCuratedList?.edges.map((item) => item.node) || [],
    refetch,
  };
};

export const useList = (listId) => {
  const { handleError } = useHandleError('List');
  const { data, error, refetch } = useSuspenseQuery(
    GET_NETWORK_CURATED_LIST,
    {
      variables: { id: listId },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    },
  );

  return {
    error,
    list: data?.getNetworkCuratedList,
    refetch,
  };
};

export const useListAvailableLocations = (listId) => {
  const { networkId } = useParams();
  const { handleError } = useHandleError('List');
  const { activeNetwork } = useContext(FlamingoContext);
  const [getListAvailableLocations, { data, error, loading, refetch }]
    = useLazyQuery(GET_NETWORK_CURATED_LIST_AVAILABLE_LOCATIONS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    });

  useEffect(() => {
    if (
      !isGlobalTemplate(activeNetwork?.id)
      && networkId === activeNetwork?.id
    ) {
      getListAvailableLocations({
        variables: { id: listId },
      });
    }
  }, [activeNetwork, getListAvailableLocations, listId, networkId]);

  return {
    error,
    loading,
    list: data?.getNetworkCuratedList,
    getListAvailableLocations,
    refetch,
  };
};

export const useCreateList = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('List');

  return useMutation(CREATE_NETWORK_CURATED_LIST, {
    refetchQueries: [{ query: FIND_NETWORK_CURATED_LIST, variables: {} }],
    onCompleted: (data) => {
      toastNotificationSuccessHook('The List has been created.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateList = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('List');

  return useMutation(UPDATE_NETWORK_CURATED_LIST, {
    onCompleted: (data) => {
      toastNotificationSuccessHook('Changes to this list have been saved.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useDeleteList = () => {
  const { handleError } = useHandleError('List');

  return useMutation(DELETE_NETWORK_CURATED_LIST, {
    refetchQueries: [{ query: FIND_NETWORK_CURATED_LIST, variables: {} }],
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateListSection = (listId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('List');

  return useMutation(CREATE_NETWORK_CURATED_LIST_SECTION, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'A new section has been added to this list.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: GET_NETWORK_CURATED_LIST, variables: { id: listId } }],
  });
};

export const useUpdateListSection = (listId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('List');

  return useMutation(UPDATE_NETWORK_CURATED_LIST_SECTION, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'Changes to this list section have been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: GET_NETWORK_CURATED_LIST, variables: { id: listId } }],
  });
};

export const useMoveSingleListLocationSection = ({
                                                   listId,
                                                   successMessage,
                                                 }) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('List');

  return useMutation(UPDATE_NETWORK_CURATED_LIST_SECTIONS, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        successMessage || 'Changes to this list section have been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: GET_NETWORK_CURATED_LIST, variables: { id: listId } }],
  });
};

export const useDeleteListSection = (listId) => {
  const { handleError } = useHandleError('List');

  return useMutation(DELETE_NETWORK_CURATED_LIST_SECTION, {
    refetchQueries: [{ query: GET_NETWORK_CURATED_LIST, variables: { id: listId } }],
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export default useLists;
