import { Box, Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DetailFormFooter from 'components/forms/detailFormFooter';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { getNetworkPlaceWorkplace } from 'helpers/workspace-util';
import { useUpdateNetworkPlace } from 'hooks/dataHooks';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { useWorkspace } from 'hooks/useWorkspace';
import DetailTableOfContents from 'pages/locations/containers/detail/detailTableOfContents';
import formFieldMap from 'pages/locations/containers/detail/errorModalHelper';
import LocationsHeader from 'pages/locations/containers/locationsHeader';
import RenderedSection from 'pages/locations/containers/renderedSection';
import AddStreetNumberModal from 'pages/locations/containers/sections/addStreetNumberModal';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[100],
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  tabsContainer: {
    height: '100vh',
  },
  tabPanel: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
  },
  panelBody: {
    maxWidth: '1400px',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  scrollBody: {
    margin: '0 24px',
    height: '100%',
    overflowX: 'auto',
  },
  formBody: {
    margin: '0 3px 93px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: '0 24px 500px',
  },
  sidebar: {
    position: 'absolute',
    top: '32px',
    left: '42px',
  },
  content: {
    paddingLeft: '295px',
  },
});

const getInitialTab = (initTab, tabItems) => {
  if (!initTab) return 0;
  if (!isNaN(parseInt(initTab))) return parseInt(initTab);
  for (let i = 0; i < tabItems.length; i++) {
    if (tabItems[i].key === initTab) {
      return i;
    }
  }
  return 0;
};

const DetailContainer = ({
                           networkId,
                           networkPlaceId,
                           sections,
                           initialParser,
                           submitParser,
                           validator,
                           backText,
                           backUrl,
                           closeUrl,
                           tabItems,
                           pageTitle,
                           pageSubtitle,
                           data,
                           breadcrumb,
                           showNetworkLogo,
                         }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const dispatch = useDispatch();
  const { activeNetwork, handleNavigate } = useContext(FlamingoContext);
  const location = useLocation();
  const [initialValues, setInitialValues] = useState();
  const [activeSection, setActiveSection] = useState(location.hash);
  const [showAddStreetNumberModal, setShowAddStreetNumberModal]
    = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { workspace } = useWorkspace();
  const [updatePlace] = useUpdateNetworkPlace();
  const scrollRef = useRef(0);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [currentTab, setCurrentTab] = useState(
    getInitialTab(params.get('tab'), tabItems),
  );
  const isUberAdmin = useCheckGozioAdmin();
  const languages = useActiveNetworkLanguages();

  useEffect(() => {
    if (data?.name && languages?.length && !initialValues) {
      const values = initialParser(data, languages);
      setInitialValues(values);
    }
  }, [data, initialParser, initialValues, languages, setInitialValues]);

  useEffect(() => {
    const scrollElement = document.getElementById('detail-scroll');
    if (scrollElement && currentTab === 0) {
      scrollElement.scrollTop = scrollRef.current;
    }
  }, [currentTab]);

  const tabChange = (value, form) => {
    const { hasValidationErrors } = form.getState();
    if (hasValidationErrors) {
      setIsClosing(true);
      return;
    }
    const params = new URLSearchParams(location.search);
    params.set('tab', value);
    handleNavigate(location.pathname, { state: { search: params.toString() } });
    const scrollElement = document.getElementById('detail-scroll');
    if (scrollElement && value !== 0) {
      scrollRef.current = scrollElement.scrollTop;
    }
    setCurrentTab(value);
  };
  const hasMappingInputsPermissions = isUberAdmin;

  const onSubmit = async (values) => {
    const parsedVal = await submitParser(dispatch, values);
    const { errors } = await updatePlace({
      variables: {
        id: networkPlaceId,
        input: parsedVal,
      },
    });
    errors?.graphQLErrors?.forEach((err) => {
      const { extensions, statusCode } = err; // extensions, statusCode, error, message
      if (
        statusCode === 409
        && extensions.debug.path === 'geoLocation.address.street'
      ) setShowAddStreetNumberModal(true);
    });
  };

  const renderSections = useMemo(
    () => sections.filter((section) => section.conditional
          ? section.conditional({
            hasPermissions: hasMappingInputsPermissions,
            data,
          })
          : true,
      ),
    [data, hasMappingInputsPermissions, sections],
  );

  const renderTabs = () => tabItems.map((tab, idx) => {
      const { mapFunc, Component } = tab;
      if (!mapFunc || !Component) return null;
      return (
        <TabPanel
          key={`tabPanel-${tab.key}`}
          value={currentTab}
          index={idx}
          sx={{
            ...styles.tabPanel,
            ...currentTab === idx && { height: '100%' },
          }}
        >
          <Component
            {...mapFunc({
              panelClass: styles.panelBody,
              isUberAdmin,
              data,
              networkId,
            })}
          />
        </TabPanel>
      );
    });

  const getFormPadding = () => {
    const headerHeight = breadcrumb ? 255 : 205;
    const footerHeight = 69;
    const fieldHeight
      = (data.designation === 'floor'
      || (data.designation === 'poi' && data.isMapped)
        ? 425
        : 200) + 25;
    return {
      paddingBottom: `calc(100vh - ${
        headerHeight + footerHeight + fieldHeight
      }px)`,
    };
  };

  const formDisabled
    = !tabItems[currentTab].enableForm
    && getNetworkPlaceWorkplace(data) !== workspace;

  if (!data || Object.keys(data).length === 0 || !initialValues) {
    return <Loading />;
  }

  // this hidden input below along with autoComplete="off" in form turns off autofill
  return (
    <Form
      subscription={{ submitting: true, pristine: true, validating: true }}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validator(languages)}
    >
      {({ form, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormWatcher
            allowContinueOnError={false}
            formRenderProps={{ form }}
            errorFormFieldMap={formFieldMap}
            isClosing={isClosing}
            isSubmitting={isSubmitting}
            onClose={() => {
              setIsClosing(false);
              setIsSubmitting(false);
            }}
            onContinue={() => {
              setIsClosing(false);
              setIsSubmitting(false);
              return true;
            }}
            onSave={() => {
              setIsClosing(false);
              setIsSubmitting(false);
              return true;
            }}
          />
          <Box key={networkPlaceId} sx={styles.root}>
            <LocationsHeader
              formFieldMap={formFieldMap}
              tabs={{
                value: currentTab,
                onChange: (value) => {
                  tabChange(value, form);
                },
                items: tabItems,
              }}
              networkPlace={data}
              expanded={true}
              form={form}
              isUberAdmin={isUberAdmin}
              backText={backText}
              backUrl={backUrl}
              closeUrl={closeUrl}
              title={pageTitle}
              subtitle={pageSubtitle}
              breadcrumb={breadcrumb}
              icon={showNetworkLogo ? activeNetwork?.logo : null}
            />
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: 'none' }}
            />
            <Box
              sx={{
                ...styles.tabsContainer,
                paddingTop: `${breadcrumb ? 255 : 205}px`,
              }}
            >
              <TabPanel
                value={currentTab}
                index={0}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 0 && { height: '100%' },
                }}
              >
                <Box sx={styles.panelBody}>
                  <Box sx={styles.scrollBody} id="detail-scroll">
                    <Box sx={styles.sidebar}>
                      <DetailTableOfContents
                        items={renderSections}
                        data={data}
                        isUberAdmin={isUberAdmin}
                        activeState={activeSection}
                        setActiveState={setActiveSection}
                      />
                    </Box>
                    <Paper sx={styles.formBody} style={getFormPadding()}>
                      <Box sx={styles.content}>
                        <Grid container direction="column">
                          {renderSections.map((section) => (
                            <RenderedSection
                              key={`section-${section.hash}`}
                              section={section}
                              data={data}
                              form={form}
                              readOnly={formDisabled}
                              isUberAdmin={isUberAdmin}
                              networkId={networkId}
                            />
                          ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
                <DetailFormFooter
                  form={form}
                  readOnly={formDisabled}
                  submitting={submitting}
                  onSubmit={async () => {
                    setIsSubmitting(true);
                    await handleSubmit();
                    setIsSubmitting(false);
                  }}
                />
              </TabPanel>
              {renderTabs()}
            </Box>
            {showAddStreetNumberModal && (
              <AddStreetNumberModal
                handleClose={() => setShowAddStreetNumberModal(false)}
                handleConfirm={(values) => {
                  if (values?.streetNumber) {
                    const { streetNumber } = values;
                    const { street } = form.getState().values;
                    form.change('streetNumber', streetNumber);
                    form.change('street', `${streetNumber} ${street}`);
                  }
                  setShowAddStreetNumberModal(false);
                }}
              />
            )}
          </Box>
          {isUberAdmin && (
            <WorkspaceSwitcher
              leftOffset={0}
              onWorkspaceSwitched={() => {
                handleNavigate(`/network/${networkId}/locations`);
              }}
            />
          )}
        </form>
      )}
    </Form>
  );
};

DetailContainer.propTypes = {
  networkId: PropTypes.string,
  networkPlaceId: PropTypes.string,
  sections: PropTypes.array,
  initialParser: PropTypes.func,
  submitParser: PropTypes.func,
  validate: PropTypes.func,
  backText: PropTypes.string,
  backUrl: PropTypes.string,
  closeUrl: PropTypes.string,
  tabItems: PropTypes.array,
  pageTitle: PropTypes.string,
  pageSubtitle: PropTypes.string,
  data: PropTypes.object,
  breadcrumb: PropTypes.object,
  showNetworkLogo: PropTypes.bool,
  validator: PropTypes.func.isRequired,
};

DetailContainer.defaultProps = {
  showNetworkLogo: false,
};

export default React.memo(DetailContainer);
