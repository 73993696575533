import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import withStyles from '@mui/styles/withStyles';
import DeprecatedModalStyles from 'components/_deprecated/deprecated-modal-styles';
import ReduxLanguageBasedInput from 'components/_deprecated/reduxLanguageBasedInput';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import _ from 'lodash';
import WeightToggle from 'pages/typeaheads/containers/toggleWeight';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

class AddTagModal extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const { handleAddTag, reset } = this.props;
    return handleAddTag(values)
      .then(() => {
        reset();
      })
      .catch((err) => {
        if (err instanceof SubmissionError) {
          throw err;
        } else {
          throw new SubmissionError(err);
        }
      });
  }

  render() {
    const {
      handleSubmit,
      classes,
      validators,
      submitting,
      error,
      open,
      handleClose,
      invalid,
      reset,
      type,
      languages,
    } = this.props;

    const langArr = [
      {
        lang: LANGUAGE_CODES.ENGLISH,
        name: 'name.en',
        label: 'Tag name',
        customTags: { 'data-test': 'tag-name-en' },
        validators: _.get(validators, 'name.en', []),
      },
      {
        lang: LANGUAGE_CODES.SPANISH,
        name: 'name.es',
        label: 'Tag name (Spanish)',
        customTags: { 'data-test': 'tag-name-es' },
        validators: _.get(validators, 'name.es', []),
      },
    ];

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        scroll="body"
      >
        <form className="login-form" onSubmit={handleSubmit(this.submit)}>
          <DialogTitle id="alert-dialog-title" className={classes.titleStyle}>
            Add new tag
          </DialogTitle>
          <DialogContent>
            <ReduxLanguageBasedInput options={langArr} filter={languages} />
            {type !== 'typeahead' && (
              <>
                <Box>
                  <Field
                    name="weight"
                    component={WeightToggle}
                    validate={validators && validators.weight}
                  />
                </Box>
                <p className={classes.weightToggleError}>
                  <span>{error}</span>
                </p>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                handleClose();
              }}
              variant="outlined"
              data-test="add-tag-cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={invalid || submitting}
              data-test="add-tag-save-button"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

AddTagModal.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ form, auth, networks }) => ({
    createTagForm: form.createTag,
    auth,
    networks,
  })),
  reduxForm({
    form: 'createTag',
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  withStyles(DeprecatedModalStyles),
)(AddTagModal);
