import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { wrapWithTooltip } from 'helpers/page-util';
import { getTableCountTitle } from 'helpers/table-util';
import ColorPalette from 'pages/gozio_colors';
import AddFloorModal from 'pages/locations/containers/sections/addFloorModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

const buildStyles = () => ({
  root: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflowX: 'auto',
    marginTop: '12px',
  },
  addButton: {
    right: '16px',
    height: '32px',
    width: '32px',
  },
  content: { height: 'calc(100vh - 375px)' },
  panelContent: { height: '100%' },
});

const buildData = (floors) => floors
    .map((floor, idx) => ({
      id: floor?.node?.id,
      order: idx + 1,
      name: wrapWithTooltip(getLabelByLang(floor?.node?.name)),
      shortName: getLabelByLang(floor?.node?.shortName),
      status: capitalize(floor?.node?.validation?.status || 'Incomplete'),
      pois: floor?.node?.pois?.count || 0,
      ldmks: floor?.node?.landmarks?.count || 0,
      mapId: wrapWithTooltip(floor?.node?.indoorLocation?.mapId),
      externalId: floor?.node?.externalId,
    }))
    .sort((a, b) => a.mapId === b.mapId ? 0 : a.mapId < b.mapId ? -1 : 1);

const FloorsTable = ({ navigate, floors, isUberAdmin, networkId }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(buildData(floors));
  }, [floors]);

  const FLOOR_COLUMNS = useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'order',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: 'Floor Name',
        accessor: 'name', // accessor is the "key" in the data
        onClick: (data) => {
          navigate(`/network/${networkId}/locations/floor/${data.id}`);
        },
        disableSortBy: true,
        sticky: 'left',
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        disableSortBy: true,
        width: 130,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        width: 70,
      },
      {
        Header: <span>#&nbsp;of&nbsp;POIs</span>,
        accessor: 'pois',
        disableSortBy: true,
        width: 80,
      },
      {
        Header: <span>#&nbsp;of&nbsp;LDMKs</span>,
        accessor: 'ldmks',
        disableSortBy: true,
        width: 80,
      },
      {
        Header: 'Floor ID',
        accessor: 'id',
        hidden: true,
      },
      ...isUberAdmin
        ? [
          {
            Header: 'Mapping ID',
            accessor: 'mapId',
            minWidth: 150,
            width: 150,
          },
        ]
        : [],
      {
        Header: 'Gozio ID',
        accessor: 'externalId',
        minWidth: 150,
        width: 150,
      },
    ],
    [navigate, isUberAdmin, networkId],
  );

  return (
    <GozioTable
      columns={FLOOR_COLUMNS}
      data={tableData}
      sx={{
        borderRadius: '20px',
        boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
          ColorPalette.grey[600],
          0.4,
        )}`,
      }}
      hidePagination={true}
      countTitle={getTableCountTitle(floors?.length, 'Floor')}
    />
  );
};

function FloorsPanel({ sx, floors = [], networkId, isUberAdmin, building }) {
  const styles = buildStyles();
  const { activeNetwork: network, handleNavigate }
    = useContext(FlamingoContext);
  const [showAddFloor, setShowAddFloor] = useState(false);
  const networkName = network?.humanName || 'Global Template';
  const networkLogo = network?.logo?.url;

  const handleFloorAdded = (data) => {
    setShowAddFloor(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/floor/${data.id}`);
    }
  };

  return (
    <FlamingoPage
      pageName="Floors"
      sx={{ ...styles.root, ...sx }}
      contentSx={{ ...floors.length === 0 && styles.panelContent }}
      headerButtons={
        isUberAdmin
        && floors.length > 0 && (
          <AddCircleButton
            onClick={() => {
              setShowAddFloor(true);
            }}
            aria-label="Add Floor"
            sx={styles.addButton}
            tooltipTitle="Add a Floor"
          />
        )
      }
    >
      {floors.length > 0 ? (
        <Box sx={styles.content}>
          <FloorsTable
            floors={floors}
            isUberAdmin={isUberAdmin}
            networkId={networkId}
            navigate={handleNavigate}
          />
        </Box>
      ) : (
        <EmptyPanel
          title="There are No Floors for This Building"
          buttonLabel="Add a Floor"
          buttonShown={isUberAdmin}
          ButtonIcon={Add}
          buttonClicked={() => setShowAddFloor(true)}
          sx={{ height: '100%', paddingBottom: '110px' }}
        />
      )}
      {showAddFloor && (
        <AddFloorModal
          handleClose={() => setShowAddFloor(false)}
          handleConfirm={handleFloorAdded}
          building={building}
          floorCount={floors.length}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}
    </FlamingoPage>
  );
}

FloorsPanel.propTypes = {
  sx: PropTypes.object,
};

FloorsPanel.defaultProps = {
  sx: {},
};

export default FloorsPanel;
