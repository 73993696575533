import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    minHeight: '132px',
    overflow: 'hidden',
    padding: theme.spacing(4, 3),
    width: '375px',
  },
  light: {
    backgroundColor: theme.palette.grey[100],
  },
  dark: {
    backgroundColor: theme.palette.grey[500],
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    letterSpacing: ' 0.2px',
    lineHeight: '40px',
  },
  subtitle: {
    fontWeight: 400,
    opacity: 0.65,
  },
  button: {
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(1),
  },
  expandIcon: {
    alignItems: 'center',
    borderRadius: '50%',
    bottom: theme.spacing(1),
    color: theme.palette.white,
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    left: '46%',
    position: 'absolute',
    width: '20px',
  },
  colorBackgrounds: {
    backgroundColor: theme.palette.black,
  },
});

const BannerCard = ({
                      backgroundColor,
                      backgroundImage,
                      buttonLabel,
                      darkTheme,
                      subtitle,
                      title,
                    }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Grid
      container
      key={title}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        ...darkTheme ? styles.dark : styles.light,
        ...styles.root,
        backgroundColor,
        ...backgroundImage && { backgroundImage: `url(${backgroundImage})` },
      }}
    >
      {!!title && (
        <Box sx={styles.lineClamp}>
          <Typography
            variant="subtitle1"
            sx={{
              ...styles.title,
              color: darkTheme ? ColorPalette.white : ColorPalette.grey[600],
            }}
          >
            {title ?? ''}
          </Typography>
        </Box>
      )}
      {!!subtitle && (
        <Box sx={styles.lineClamp}>
          <Typography
            variant="body2"
            sx={{
              ...styles.subtitle,
              color: darkTheme ? ColorPalette.white : ColorPalette.grey[600],
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      )}
      {!!buttonLabel && (
        <Button
          variant="contained"
          sx={{
            ...styles.button,
            backgroundColor: darkTheme ? ColorPalette.grey[500] : '#646b74',
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </Grid>
  );
};

BannerCard.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  buttonLabel: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  darkTheme: PropTypes.bool,
};

BannerCard.defaultProps = {
  backgroundColor: null,
  darkTheme: false,
  title: '',
  subtitle: '',
  buttonLabel: '',
};

export default BannerCard;
