import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  copyright: {
    color: theme.palette.grey[300],
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

function BuildVersion({ buildInfo }) {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [version, buildNumber] = buildInfo;
  const realBuildNumber = buildNumber?.match(/^-?\d+$/)
    ? parseInt(buildNumber) - 2100
    : buildNumber;
  const displayVersion = version ? `v${version}.${realBuildNumber}` : '';

  return (
    <Box sx={styles.copyright}>
      <Typography variant="caption">powered by GOZIO&reg;</Typography>
      <Typography variant="caption">{displayVersion}</Typography>{' '}
    </Box>
  );
}

BuildVersion.propTypes = {
  buildInfo: PropTypes.array,
};

BuildVersion.defaultProps = {
  buildInfo: [],
};

export default BuildVersion;
