const sections = {
	MAPPING_INPUTS: 'Mapping Inputs',
	SITE_DETAILS: 'Details',
	ADDRESS: 'Address',
	SEARCHABILITY: 'Searchability',
	ACTION_LINKS: 'Quicklinks',
	HOURS: 'Hours',
	MEDIA: 'Media',
	MAP_VIS: 'Map Visibility',
};
Object.freeze(sections);

const formFieldMap = new Map([
	/* Mapping Inputs */
	['mapID', { label: 'Map ID', section: sections.MAPPING_INPUTS }],
	['mapBoundary', { label: 'Map Boundary', section: sections.MAPPING_INPUTS }],
	['strictParking', { label: 'Strict Parking', section: sections.MAPPING_INPUTS }],
	['parkingType', { label: 'Parking Type', section: sections.MAPPING_INPUTS }],
	['mapRotation', { label: 'Map Rotation', section: sections.MAPPING_INPUTS }],
	['latitude', { label: 'Latitude', section: sections.MAPPING_INPUTS }],
	['longitude', { label: 'Longitude', section: sections.MAPPING_INPUTS }],
	['xAxis', { label: 'X Axis', section: sections.MAPPING_INPUTS }],
	['yAxis', { label: 'Y Axis', section: sections.MAPPING_INPUTS }],
	/* Details */
	['name', { label: 'Site Name', section: sections.SITE_DETAILS }],
	['nameES', { label: 'Site Name (Spanish)', section: sections.SITE_DETAILS }],
	['shortName', { label: 'Short Name', section: sections.SITE_DETAILS }],
	['shortNameES', { label: 'Short Name (Spanish)', section: sections.SITE_DETAILS }],
	['description', { label: 'Description', section: sections.SITE_DETAILS }],
	['descriptionES', { label: 'Description (Spanish)', section: sections.SITE_DETAILS }],
	/* Address */
	['street', { label: 'Streen Number & Name', section: sections.ADDRESS }],
	['city', { label: 'City', section: sections.ADDRESS }],
	['state', { label: 'State', section: sections.ADDRESS }],
	['zip', { label: 'Zip', section: sections.ADDRESS }],
	['building', { label: 'Building', section: sections.ADDRESS }],
	['floor', { label: 'Floor', section: sections.ADDRESS }],
	['suite', { label: 'Suite', section: sections.ADDRESS }],
	['defaultDestination', { label: 'Default Destination', section: sections.ADDRESS }],
	['siteGeofenceLatitude', { label: 'Latitude', section: sections.ADDRESS }],
	['siteGeofenceLongitude', { label: 'Longitude', section: sections.ADDRESS }],
	['siteGeofenceRadius', { label: 'Radius', section: sections.ADDRESS }],
	['displayLatitude', { label: 'Latitude', section: sections.ADDRESS }],
	['displayLongitude', { label: 'Longitude', section: sections.ADDRESS }],
	['destinationLatitude', { label: 'Destination Latitude', section: sections.ADDRESS }],
	['destinationLongitude', { label: 'Destination Longitude', section: sections.ADDRESS }],
	['departmentIds', { label: 'Department IDs', section: sections.ADDRESS }],
	['providerMatchingRule', { label: 'Provider Matching Rule', section: sections.ADDRESS }],
	/* Media */
	['youTubeUrl', { label: 'YouTube URL', section: sections.MEDIA }],
	/* Map Visibility */
	['visibility', { label: 'Visibility', section: sections.MAP_VIS }],
	['priority', { label: 'Priority', section: sections.MAP_VIS }],
	/* Hours */
	['customHours', { label: 'Custom Hours', section: sections.HOURS }],
]);

export default formFieldMap;
