import FormSubSection from 'components/forms/formSubSection';
import ActionLinksMain from 'components/networkPlace/actionLinks/actionLinksMain';
import { hasSpanish } from 'helpers/lang-util';
import { useInApp } from 'hooks/dataHooks';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const ActionLinks = ({ data, isUberAdmin }) => {
  const languages = useActiveNetworkLanguages();
  const { data: appDestinations } = useInApp();
  return (
    <FormSubSection name="Quicklinks" sx={{ position: 'relative' }}>
      <Field name="actionLinks">
        {({ input: { value, onChange } }) => (
          <ActionLinksMain
            langES={hasSpanish(languages)}
            isUberAdmin={isUberAdmin}
            appDestinations={appDestinations}
            networkIcons={data?.network?.icons}
            sourceName={data?.designation}
            value={value}
            onChange={onChange}
          />
        )}
      </Field>
    </FormSubSection>
  );
};

ActionLinks.propTypes = {
  data: PropTypes.object,
  isUberAdmin: PropTypes.bool,
};

export default React.memo(ActionLinks);
