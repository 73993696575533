import { Box, Grid, Typography } from '@mui/material';
import LinkDestination from 'components/forms/linkDestination';
import TabPanel from 'components/tabPanel/tabPanel';
import { getFormLabel } from 'helpers/form-util';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { TextField as FinalTextField } from 'mui-rff';
import { Condition, SUPPORTED_LINK_TYPES } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import LinkedLocations from 'pages/mobileSettings/dashboard/containers/linkedLocations';
import PropTypes from 'prop-types';
import React from 'react';

export const LinkTabPanel = ({
                               sx,
                               cardGroupType,
                               customLocations,
                               formValues,
                               form,
                               index,
                               locations,
                               networkId,
                               onCustomLocationsAdded,
                               tabValue,
                             }) => {
  const isUberAdmin = useCheckGozioAdmin();

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Grid item xs={12}>
        {cardGroupType === 'expandable' && (
          <LinkedLocations
            form={form}
            locations={locations}
            customLocations={customLocations}
            onCustomLocationsAdded={onCustomLocationsAdded}
          />
        )}
        {cardGroupType !== 'expandable' && (
          <LinkDestination
            form={form}
            hasCustomLink={!!formValues.custom}
            supportedLinkTypes={SUPPORTED_LINK_TYPES}
          />
        )}
        {isUberAdmin
          && (cardGroupType === 'one' || cardGroupType === 'temporary') && (
            <Condition when="linkType" is="custom">
              <Box sx={sx.fieldSection}>
                <Grid item xs>
                  {/* The JSON input is only visible to gozio admins. */}
                  <Typography
                    sx={sx.displayHeading}
                    variant="subtitle1"
                  >
                    Visibility Rule
                  </Typography>
                </Grid>
                <Grid item xs>
                  <FinalTextField
                    label={getFormLabel('JSON Input')}
                    multiline
                    name="visibilityRule"
                    variant="outlined"
                    minRows={7}
                    maxRows={7}
                    sx={{ fontFamily: 'monospace' }}
                  />
                </Grid>
              </Box>
            </Condition>
          )}
      </Grid>
    </TabPanel>
  );
};

LinkTabPanel.propTypes = {
  sx: PropTypes.object,
  cardGroupType: PropTypes.string.isRequired,
  customLocations: PropTypes.array,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  index: PropTypes.number,
  locations: PropTypes.shape({
    allUrgentCare: PropTypes.bool,
    allEmergencyDepartments: PropTypes.bool,
    custom: PropTypes.object,
  }),
  networkId: PropTypes.string.isRequired,
  onCustomLocationsAdded: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

LinkTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  locations: {},
  tabValue: 0,
};
