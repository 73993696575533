import { Backdrop, Box, Button } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: { position: 'relative' },
  speedDial: {
    alignItems: 'flex-end',
    fontSize: '14px',
    position: 'absolute',
    right: 0,
    top: '-20px',
    zIndex: 1150,
  },
  button: {
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[350],
      0.4,
    )}`,
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontFamily: 'Poppins',
    letterSpacing: 'normal',
    marginBottom: theme.spacing(2),
    minWidth: 150,
    textTransform: 'none',
    transition: `${theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    })}, opacity 0.8s`,
    opacity: 1,

    '&:hover': {
      backgroundColor: theme.palette.blue[50],
      boxShadow: theme.shadows[4],
      fontWeight: 700,
    },
  },
  buttonClosed: { opacity: 0, transform: 'scale(0)' },
  backdrop: { zIndex: 1100 },
});

const SpeedDialButton = React.forwardRef(
  ({ id, open, buttonTitle, delay, disabled, onClick, styles }, ref) => (
    <Button
      id={id}
      data-test={`SpeedDialButtonOption-${buttonTitle}`}
      ref={ref}
      variant="contained"
      sx={{
        ...styles.button,
        transitionDelay: `${delay}ms`,
        ...!open && styles.buttonClosed,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  ),
);

SpeedDialButton.propTypes = {
  id: PropTypes.string,
  buttonTitle: PropTypes.string,
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  styles: PropTypes.object.isRequired,
};

SpeedDialButton.defaultProps = {
  delay: 10,
  onClick: () => {},
};

const SpeedDialBackdrop = ({ actions, direction }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={styles.root}>
      <Backdrop open={open} sx={styles.backdrop} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        sx={styles.speedDial}
        data-test="SpeedDialButton"
        direction={direction}
        FabProps={{ size: 'small' }}
        icon={<SpeedDialIcon sx={{ marginTop: '-2px' }} />}
        onClose={(e, eventType) => {
          if (eventType === 'mouseLeave') {
            return;
          }
          handleClose();
        }}
        onClick={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialButton
            key={action.name}
            buttonTitle={action.name}
            disabled={action.disabled}
            onClick={() => {
              if (action.onClick) action.onClick();
              handleClose();
            }}
            styles={styles}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

SpeedDialBackdrop.propTypes = {
  actions: PropTypes.array.isRequired,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

SpeedDialBackdrop.defaultProps = {
  direction: 'down',
};

export default SpeedDialBackdrop;
