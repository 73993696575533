import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import { SCOPES } from 'components/authorization/authorize';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { formatLang } from 'helpers/form-util';
import { capitalize, getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import { useUpdateCategoryTags } from 'hooks/dataHooks/useCategories';
import useToast from 'hooks/useToast';
import AddTagModal from 'pages/categories/containers/addTagModal';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

const buildStyles = () => ({
  addButton: {
    position: 'absolute',
    right: '20px',
    top: '28px',
    height: '36px',
    width: '36px',
  },
});

const getColumns = (networkId, navigate, isInDetail) => [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Category ID',
    accessor: 'categoryId',
    alwaysHidden: true,
  },
  {
    Header: 'Tag Name',
    accessor: 'name',
    minWidth: 250,
    width: 250,
    onClick: isInDetail
      ? undefined
      : (data) => {
        navigate(`/network/${networkId}/categories/${data.categoryId}`);
      },
    sticky: 'left',
  },
  {
    Header: 'Tag Name (Spanish)',
    accessor: 'nameEs',
    minWidth: 250,
    width: 250,
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'Category',
    accessor: 'category',
    minWidth: 250,
    width: 250,
    onClick: (data) => {
      navigate(`/network/${networkId}/categories/${data.categoryId}`);
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    minWidth: 80,
    width: 80,
    disableSortBy: true,
    justifyRight: true,
  },
];

const getWeight = (w) => {
  if (w === 1) return 'low';
  if (w === 3) return 'high';
  return 'medium';
};

const getWeightLabel = (w) => {
  if (w === 'low') return 'Trivial';
  if (w === 'high') return 'High';
  return 'Neutral';
};

const TagListing = ({
                      categoryId,
                      categories,
                      tags,
                      languages,
                      loading,
                      navigate,
                      networkId,
                      search,
                      isInDetail,
                      hasSpanish,
                      isGlobalTemplate,
                    }) => {
  const styles = buildStyles();
  const { authorize } = useContext(FlamingoContext);
  const [currentTag, setCurrentTag] = useState(null);
  const [updateCategoryTags] = useUpdateCategoryTags();
  const { toastNotificationSuccessHook } = useToast();
  const categoryEditPermission
    = isGlobalTemplate && authorize(SCOPES.CATEGORIES.EDIT);

  const memoizedData = useMemo(() => {
    const deleteTag = async (id, catId) => {
      let catTags = tags;
      if (!categoryId) {
        const currCat = categories.find((c) => c.id === catId);
        catTags = currCat?.tags || [];
      }
      const deletedTag = catTags.find((t) => t.id === id);
      const result = await updateCategoryTags({
        variables: {
          id: catId,
          tags: catTags
            .filter((t) => t.id !== id)
            .map((t) => ({ name: t.name, weight: t.weight })),
        },
      });
      if (result.data) {
        toastNotificationSuccessHook(
          `The Tag ${getLabelByLang(deletedTag.name)} has been deleted.`,
        );
      }
      closeTagModal();
    };
    const processTags = () => tags.map((t) => ({
        id: t.id,
        categoryId: categoryId || t.parent?.id,
        name: capitalize(getLabelByLang(t.name)),
        searchIdx: getLabelByLang(t.name).toLowerCase(),
        nameEs: capitalize(getLabelByLang(t.name, LANGUAGE_CODES.SPANISH)),
        category: getLabelByLang(t.parent?.name),
        weight: getWeightLabel(t.weight),
        actions: (
          <Box className="hoverUnhide">
            <Tooltip title="Edit">
              <Edit
                onClick={() => setCurrentTag(t)}
                sx={{ marginRight: '8px' }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete
                onClick={() => deleteTag(t.id, categoryId || t.parent?.id)}
              />
            </Tooltip>
          </Box>
        ),
      }));
    return processTags().filter(
      (item) => !search || item.searchIdx.indexOf(search) >= 0,
    );
  }, [
    categories,
    categoryId,
    search,
    tags,
    toastNotificationSuccessHook,
    updateCategoryTags,
  ]);

  const memoizedColumns = useMemo(() => {
    const columns = getColumns(networkId, navigate, isInDetail);
    if (isInDetail) {
      columns.splice(5, 1);
    }
    if (!hasSpanish) {
      columns.splice(3, 1);
    }
    if (!categoryEditPermission) {
      columns.pop(); // remove action column
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSpanish, categoryEditPermission, networkId, navigate]);

  const count = memoizedData?.length || 0;

  const closeTagModal = () => {
    setCurrentTag(null);
  };

  const saveTag = async (values) => {
    let catTags = tags;
    if (!categoryId) {
      const currCat = categories.find((c) => c.id === values.categoryId);
      catTags = currCat?.tags || [];
    }
    const newTags = catTags.map((t) => ({ ...t, name: [...t.name] }));
    if (values.id) {
      const updatedTag = newTags.find((t) => t.id === values.id);
      updatedTag.name = formatLang('name', values);
      updatedTag.weight = getWeight(values.weight);
    } else {
      newTags.push({
        name: formatLang('name', values),
        weight: getWeight(values.weight),
      });
    }

    const result = await updateCategoryTags({
      variables: {
        id: values.categoryId,
        tags: newTags.map((t) => ({ name: t.name, weight: t.weight })),
      },
    });
    if (result.data) {
      let message;
      if (values.id) {
        message = `All changes within ${values.name} have been saved.`;
      } else {
        message = `The Tag ${values.name} has been created.`;
      }
      toastNotificationSuccessHook(message);
    }
    closeTagModal();
  };

  return (
    <>
      {currentTag && (
        <AddTagModal
          categoryId={categoryId}
          languages={languages}
          tag={currentTag}
          handleSave={saveTag}
          handleClose={closeTagModal}
        />
      )}
      {isInDetail && count === 0 ? (
        <EmptyPanel
          title="You Have No Tags"
          subtitle={
            categoryEditPermission
              ? 'Start creating Tags for this Category.'
              : ''
          }
          buttonShown={categoryEditPermission}
          buttonLabel="Add a Tag"
          ButtonIcon={Add}
          buttonClicked={() => setCurrentTag({})}
        />
      ) : (
        <>
          {isInDetail && categoryEditPermission && (
            <AddCircleButton
              aria-label="add-tag"
              onClick={() => setCurrentTag({})}
              sx={styles.addButton}
              tooltipTitle="Add a Tag"
            />
          )}
          <GozioTable
            name="TagsTable"
            columns={memoizedColumns}
            data={memoizedData}
            sortBy={[{ id: 'name', desc: false }]}
            sx={{ height: '100%', paddingTop: '8px' }}
            loading={loading}
            countTitle={`${getTableCountTitle(count, 'Tag')}${
              isInDetail ? ' Associated With This Category' : ''
            }`}
            emptyContent={<EmptyTable />}
          />
        </>
      )}
    </>
  );
};

TagListing.propTypes = {
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  networkId: PropTypes.string,
  search: PropTypes.bool,
  tags: PropTypes.array,
  isInDetail: PropTypes.bool,
  hasSpanish: PropTypes.bool,
  isGlobalTemplate: PropTypes.bool,
};

TagListing.defaultProps = {
  isInDetail: false,
};

export default React.memo(TagListing);
