import { colorWithAlpha } from 'helpers/color-util';

const DrawerStyles = (theme) => ({
  drawerFooter: {
    alignItems: 'flex-end',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexGrow: 1,
    fontSize: '12px',
    fontWeight: 300,
    justifyContent: 'space-between',
    letterSpacing: '0px',
    padding: '10px',
  },
  nestedList: {
    backgroundColor: colorWithAlpha(theme.palette.blue[600], 0.25),
    color: theme.palette.white,
  },
  nestedListTitle: {
    '&:hover': {
      backgroundColor: colorWithAlpha(theme.palette.blue[600], 0.25),
      color: theme.palette.white,
      fontSize: '14px',
      fontWeight: 700,
    },
    color: colorWithAlpha(theme.palette.white, 0.66),
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    padding: `12px ${theme.spacing(2)}`,
  },
  nestedListTitleSelected: {
    backgroundColor: `${colorWithAlpha(
      theme.palette.blue[600],
      0.25,
    )} !important`,
  },
  selectedSubListItem: {
    '&::before': {
      backgroundColor: theme.palette.white,
      borderRadius: '5px',
      bottom: '7px',
      content: '""',
      left: '7px',
      position: 'absolute',
      top: '7px',
      width: '3px',
    },
    backgroundColor: ['inherit', '!important'],
    color: [theme.palette.white, '!important'],
    fontWeight: [600, '!important'],
    position: 'relative',
  },
  singleListTitleSelected: {
    '&::before': {
      backgroundColor: theme.palette.white,
      borderRadius: '5px',
      bottom: '7px',
      content: '""',
      left: '7px',
      position: 'absolute',
      top: '7px',
      width: '3px',
    },
    backgroundColor: ['inherit', '!important'],
    color: theme.palette.white,
    fontWeight: 700,
    position: 'relative',
  },
  SublistItem: {
    '&:hover': {
      backgroundColor: colorWithAlpha(theme.palette.blue[600], 0.25),
      color: theme.palette.white,
      fontSize: '14px',
      fontWeight: 700,
    },
    color: colorWithAlpha(theme.palette.white, 0.66),
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '1.125rem',
    paddingLeft: theme.spacing(6),
  },
});

export default DrawerStyles;
