import { CheckCircle, LocationCity, LocationOn } from '@mui/icons-material';
import { Autocomplete, Box, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import BuildingIcon from 'components/icons/BuildingIcon';
import { getFormLabel } from 'helpers/form-util';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const getIcon = (type) => {
  if (type === 'site') return <LocationCity />;
  if (type === 'building') return <BuildingIcon />;
  if (type === 'place') return <LocationOn />;
  if (type === 'poi') return <LocationOn />;
  if (type === 'offsite') return <LocationOn />;
  return null;
};

const filterOptions = createFilterOptions({ stringify: (o) => o.label });

const styles = {
  lineOne: {
    lineHeight: '24px',
    fontWeight: 400,
  },
  lineTwo: {
    fontSize: '14px',
    lineHeight: '1.1875rem',
    marginTop: '2px',
    fontWeight: 400,
  },
};

const buildOption = (props, option, state) => (
  <Box {...props} key={`opt-${state.index}`}>
    {option.type && (
      <Box
        component="span"
        sx={{ marginRight: '4px', marginLeft: option.isChild ? '16px' : 0 }}
      >
        {getIcon(option.type)}
      </Box>
    )}
    <Box
      sx={{ flex: 1, paddingLeft: !option.type && option.isChild ? '16px' : 0 }}
    >
      <Box sx={styles.lineOne}>{option.label}</Box>
      {option.label2 && <Box sx={styles.lineTwo}>{option.label2}</Box>}
      {option.label3 && <Box sx={styles.lineTwo}>{option.label3}</Box>}
    </Box>
    {state.selected && <CheckCircle color="primary" sx={{ fontSize: 24 }} />}
  </Box>
);

const nameSort = (a, b) => {
  const result = a.label?.localeCompare(b.label);
  if (result === 0 && a.label2) {
    return a.label2.localeCompare(b.label2);
  }
  return result;
};

const MuiAutocomplete = ({
                           input: { value, onChange, onFocus = () => {}, onBlur = () => {} },
                           meta,
                           options,
                           label,
                           required,
                           helperText,
                           sorted,
                           ...rest
                         }) => {
  const error = meta?.error || meta?.submitError;
  const hasError = !!error && meta?.touched;

  const fullOptions = useMemo(() => {
    const sortedOptions = [...options];
    if (sorted) {
      sortedOptions.sort(nameSort);
    }
    const displayOptions = [];
    sortedOptions.forEach((opt) => {
      displayOptions.push({ ...opt });
      if (opt.children) {
        const sortedChildren = opt.children.sort(nameSort);
        sortedChildren.forEach((child) => {
          displayOptions.push({ ...child, isChild: true });
        });
      }
    });
    return displayOptions;
  }, [options, sorted]);

  const valOpt = fullOptions.find((opt) => opt.id === value) || null;
  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={fullOptions}
      fullWidth
      value={valOpt}
      onChange={(event, newValue) => onChange(newValue?.id || null)}
      getOptionLabel={(opt) => (value && opt.label) || ''}
      renderOption={buildOption}
      onFocus={(event) => onFocus(event)}
      onBlur={(event) => onBlur(event)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={getFormLabel(label, required)}
          variant="outlined"
          helperText={hasError ? meta?.error : helperText}
          error={hasError}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      disabled={fullOptions.length === 0}
      {...rest}
    />
  );
};

MuiAutocomplete.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sorted: PropTypes.bool,
};

MuiAutocomplete.defaultProps = {
  required: false,
  sorted: true,
};

export default React.memo(MuiAutocomplete);
