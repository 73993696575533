import { Box } from '@mui/material';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { wrapWithTooltip } from 'helpers/page-util';
import { getTableCountTitle } from 'helpers/table-util';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import ColorPalette from 'pages/gozio_colors';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const buildStyles = () => ({
  root: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflowX: 'auto',
    marginTop: '30.5px',
  },
  content: { height: 'calc(100vh - 349px)' },
  panelContent: { height: '100%' },
});

const LandmarksTable = ({ landmarks, networkId }) => {
  const isUberAdmin = useCheckGozioAdmin();
  const { handleNavigate } = useContext(FlamingoContext);
  const data = landmarks.map((landmark) => ({
    id: landmark?.node?.id,
    name: wrapWithTooltip(getLabelByLang(landmark?.node?.name)),
    shortName: getLabelByLang(landmark?.node?.shortName),
    status: capitalize(landmark?.node?.validation?.status || 'Incomplete'),
    building: wrapWithTooltip(
      getLabelByLang(landmark?.node?.parentBuilding?.name),
    ),
    floor: wrapWithTooltip(getLabelByLang(landmark?.node?.parentFloor?.name)),
    externalId: landmark?.node?.externalId,
    mapId: wrapWithTooltip(landmark?.node?.indoorLocation?.mapKey),
  }));
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Landmark ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Landmark Name',
        accessor: 'name',
        width: 130,
        onClick: (data) => {
          handleNavigate(`/network/${networkId}/locations/landmark/${data.id}`);
        },
        sticky: 'left',
      },
      {
        Header: 'Landmark Short Name',
        accessor: 'shortName',
        width: 150,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 70,
      },
      {
        Header: 'Parent Building',
        accessor: 'building',
        minWidth: 125,
      },
      {
        Header: 'Parent Floor',
        accessor: 'floor',
        minWidth: 125,
      },
      ...isUberAdmin
        ? [
          {
            Header: 'Mapping ID',
            accessor: 'mapId',
            minWidth: 150,
            width: 150,
          },
        ]
        : [],
      {
        Header: 'Gozio ID',
        accessor: 'externalId',
        minWidth: 150,
        width: 150,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUberAdmin, networkId, landmarks],
  );

  return (
    <GozioTable
      columns={COLUMNS}
      sortBy={[{ id: 'name', desc: false }]}
      data={useMemo(() => data, [data])}
      sx={{
        borderRadius: '20px',
        boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
          ColorPalette.grey[600],
          0.4,
        )}`,
      }}
      countTitle={getTableCountTitle(landmarks?.length, 'Landmark')}
    />
  );
};

const LandmarksPanel = ({ sx, landmarks = [], networkId }) => {
  const styles = buildStyles();

  return (
    <FlamingoPage
      pageName="Landmarks"
      sx={{ ...styles.root, ...sx }}
      contentSx={{ ...landmarks.length === 0 && styles.panelContent }}
    >
      {landmarks.length > 0 ? (
        <Box sx={styles.content}>
          <LandmarksTable landmarks={landmarks} networkId={networkId} />
        </Box>
      ) : (
        <EmptyPanel
          title="There are No Landmarks for This Site"
          sx={{ height: '100%', paddingBottom: '110px' }}
        />
      )}
    </FlamingoPage>
  );
};

LandmarksPanel.propTypes = {
  sx: PropTypes.object,
  landmarks: PropTypes.array,
  networkId: PropTypes.string,
};

LandmarksPanel.defaultProps = {
  sx: {},
};

export default LandmarksPanel;
