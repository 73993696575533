import GenericFormModal from 'components/genericModal/genericFormModal';
import Loading from 'components/loading/loading';
import AddActionLinkModalBody from 'components/networkPlace/actionLinks/addActionLinkModalBody';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useWindowSize from 'hooks/useWindowSize';
import { makeValidate } from 'mui-rff';
import { ACTION_LINK_SCHEMA, initialParser, submitParser } from 'pages/locations/containers/detail/addLinkHelper';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Yup from 'yup';

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minWidth: '506px',
      maxWidth: '1024px',
    },
  },
  content: {
    marginBottom: 0,
    padding: '6px 32px !important',
    overflowY: 'auto',
  },
});

const AddActionLinkModal = ({
                              closeModal,
                              handleSave,
                              networkIcons,
                              langES,
                              isUberAdmin,
                              appDestinations,
                              currentLink,
                            }) => {
  const size = useWindowSize();
  const styles = buildStyles();
  const languages = useActiveNetworkLanguages();

  const validate = languages
    ? makeValidate(
      Yup.object().shape(
        buildLanguageSchema(ACTION_LINK_SCHEMA, languages, {
          name: LANGUAGE_CODES.ENGLISH,
          nameES: LANGUAGE_CODES.SPANISH,
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
          webUrlEn: LANGUAGE_CODES.ENGLISH,
          webUrlEs: LANGUAGE_CODES.SPANISH,
        }),
      ),
    )
    : null;

  const onSubmit = (values) => {
    const parsedVal = submitParser(values);
    handleSave(parsedVal);
  };

  const onClose = () => {
    closeModal();
  };

  const addOrEditQuicklinkText = `${
    currentLink?.id ? 'Edit' : 'Add'
  } Quicklink`;

  const buildContent = useCallback(
    ({ values }) => (
      <AddActionLinkModalBody
        values={values}
        icons={networkIcons}
        appDestinations={appDestinations}
      />
    ),
    [appDestinations, networkIcons],
  );

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <GenericFormModal
      sx={styles.root}
      contentSx={{
        ...styles.content,
        height: size.height < 850 ? `${size.height - 280}px` : 'none',
      }}
      title={addOrEditQuicklinkText}
      formParams={{
        initialValues: {
          ...initialParser(currentLink, languages),
          langES,
          isUberAdmin,
        },
        onSubmit,
        validate,
      }}
      body={buildContent}
      confirmText={addOrEditQuicklinkText}
      handleClose={onClose}
      handleConfirm={(e, formVals) => {
        onSubmit(formVals?.values);
      }}
    />
  );
};

AddActionLinkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  appDestinations: PropTypes.array,
  currentLink: PropTypes.object,
  networkIcons: PropTypes.array,
  langES: PropTypes.bool,
  isUberAdmin: PropTypes.bool,
};

AddActionLinkModal.defaultProps = {
  appDestinations: [],
  currentLink: null,
  networkIcons: [],
};

export default React.memo(AddActionLinkModal);
