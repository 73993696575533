const WORKSPACE_VIEW_LOCALSTORAGE_KEY = 'workspaceView';
export const NORMAL_WORKSPACE_VIEW = 'normal';
export const MINIMIZED_WORKSPACE_VIEW = 'minimized';

export const ALL_WORKSPACES = 'all';
export const DRAFT_WORKSPACE = 'draft';
export const LIVE_WORKSPACE = 'live';
export const DEFAULT_WORKSPACE = LIVE_WORKSPACE;
export const WORKSPACE_KEY = 'workspace';
export const WORKSPACE_STORAGE_KEY = `FLAMINGO_${WORKSPACE_KEY}`;
export const WORKSPACE_SCHEMA_KEYS = {
	LIVE: 'liveWorkspace',
	DRAFT: 'draftWorkspace',
};

export const setWorkspaceView = (view) => {
	localStorage.setItem(WORKSPACE_VIEW_LOCALSTORAGE_KEY, view);
};

export const getWorkspaceView = () => localStorage.getItem(WORKSPACE_VIEW_LOCALSTORAGE_KEY) || NORMAL_WORKSPACE_VIEW;

export const getNetworkPlaceWorkplace = (networkPlace) => {
	if (networkPlace?.workspaces?.length > 0) {
		return networkPlace.workspaces[0].name;
	}

	return null;
};

export const isNetworkPlaceInDraft = (networkPlace) => getNetworkPlaceWorkplace(networkPlace) === DRAFT_WORKSPACE;
