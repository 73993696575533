import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_NETWORK_PLACE = gql`
  mutation($input: NetworkPlaceInputCreate!, $meta: NetworkPlaceInputMeta) {
    createNetworkPlace(input: $input, meta: $meta) {
      id
      name
      isMapped
    }
  }
`;

export const DELETE_NETWORK_PLACE = gql`
  mutation ($id: ID!, $meta: NetworkPlaceInputMeta) {
    deleteNetworkPlace(id: $id, meta: $meta) {
      id
      name
      designation
    }
  }
`;

export const FIND_NETWORK_PLACE = gql`
  query findNetworkPlace(
    $where: NetworkPlaceInputWhere
    $sortBy: NetworkPlaceInputSort
    $limit: Int
    $skip: Int
    $search: String
  ) {
    findNetworkPlace(
      where: $where
      search: $search
      sortBy: $sortBy
      limit: $limit
      skip: $skip
    ) {
      count
      edges {
        node {
          id
          name
          externalId
          shortName
          defaultImage {
            id
          }
          designation
          isMapped
          isPromotionItem
          departmentIds
          validation {
            status
          }
          buildings: children(where: { designation: "building" }) {
            count
          }
          pois: descendants(where: { designation: "poi" }) {
            count
          }
          landmarks: descendants(where: { designation: "landmark" }) {
            count
          }
          geoLocation {
            address {
              street
              city
              state
              zip
            }
          }
          categories {
            id
            name
          }
          indoorLocation {
            mapId
            mapKey
            markerPoint {
              x
              y
            }
          }
          order
          parentSite {
            name
          }
          parentBuilding {
            name
          }
          parentFloor {
            name
          }
          workspaces {
            name
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_PLACE_SITES = gql`
  query findNetworkPlace(
    $where: NetworkPlaceInputWhere
  ) {
    findNetworkPlace(where: $where, sortBy: { name: asc }) {
      count
      edges {
        node {
          id
          name
          designation
          isMapped
          geoLocation {
            address {
              street
              city
              state
              zip
            }
          }
          buildings: children(where: { designation: "building", isMapped: false }) {
            count
            edges {
              node {
                id
                name
                isMapped
              }
            }
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_PLACE_SITES_MAPPED = gql`
  query {
    findNetworkPlace(where: { designation: "site", isMapped: true }, sortBy: { name: asc }) {
      count
      edges {
        node {
          id
          name
          designation
          isMapped
          buildings: children(
            where: { designation: "building", isMapped: true }
            sortBy: { name: asc }
          ) {
            count
            edges {
              node {
                id
                name
                designation
                isMapped
                floors: descendants(
                  where: { designation: "floor", isMapped: true }
                  sortBy: { name: asc }
                ) {
                  count
                  edges {
                    node {
                      id
                      name
                      designation
                      isMapped
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_PLACE = gql`
  ${fragments.NETWORK_PLACE_ATTRIBUTES}
  query getNetworkPlace($id: ID!) {
    getNetworkPlace(id: $id) {
      ...networkPlaceAttributes
    }
  }
`;

export const GET_NETWORK_PLACE_DESCENDANTS = gql`
  query getNetworkPlace($id: ID!, $where: NetworkPlaceInputWhere!) {
    getNetworkPlace(id: $id) {
      id
      pois: descendants(where: $where) {
        count
        edges {
          node {
            id
            name
            shortName
            inheritAddress
            geoLocation {
              address {
                building
                floor
                suite
                street
                city
                state
                zip
              }
            }
            parentSite {
              id
              name
              geoLocation {
                address {
                  building
                  floor
                  suite
                  street
                  city
                  state
                  zip
                }
              }
            }
            parentBuilding {
              id
              name
              inheritAddress
              geoLocation {
                address {
                  building
                  floor
                  suite
                  street
                  city
                  state
                  zip
                }
              }
            }
            parentFloor {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_PLACE_POIS = gql`
  query getNetworkPlace($id: ID!) {
    getNetworkPlace(id: $id) {
      id
      pois: descendants(where: { designation: "poi", isMapped: true }) {
        count
        edges {
          node {
            id
            name
            shortName
            inheritAddress
            geoLocation {
              address {
                building
                floor
                suite
                street
                city
                state
                zip
              }
            }
            parentSite {
              id
              name
              geoLocation {
                address {
                  building
                  floor
                  suite
                  street
                  city
                  state
                  zip
                }
              }
            }
            parentBuilding {
              id
              name
              inheritAddress
              geoLocation {
                address {
                  building
                  floor
                  suite
                  street
                  city
                  state
                  zip
                }
              }
            }
            parentFloor {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_PLACE_WAIT_TIME_TOKEN = gql`
  query {
    findNetworkPlace {
      edges {
        node {
          id
          ... on NetworkPlace {
            name
            designation
            waitTimeToken
          }
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_PLACE = gql`
  ${fragments.NETWORK_PLACE_ATTRIBUTES}
  mutation updateNetworkPlace(
    $id: ID!
    $input: NetworkPlaceInputUpdate
    $meta: NetworkPlaceInputMeta
  ) {
    updateNetworkPlace(id: $id, input: $input, meta: $meta) {
      ...networkPlaceAttributes
    }
  }
`;

export const UPDATE_NETWORK_PLACE_WAIT_TIME_TOKEN = gql`
  mutation updateNetworkPlace($id: ID!, $input: NetworkPlaceInputUpdate) {
    updateNetworkPlace(id: $id, input: $input) {
      id
      name
      designation
      waitTimeToken
    }
  }
`;
