export const maskString = (pattern, value) => {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, (_) => v[i++] || '');
};

export const maskPhone = (value) => maskString('(###) ###-####', value);

export const UriRegexp = /^\w+:(\/?\/?)[^\s]+/i;

export const UrlRegexp
  = /^https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_+.~#?&//=]*)/i;

export const SecureUrlRegexp
  = /^https:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_+.~#?&//=]*)/i;

export const PhoneRegexp = /^\([2-9]\d{2}\) \d{3}-\d{4}$/;

export const getFullNameFromProfile = (profile = {}) => {
  if (!profile.firstName) {
    return '';
  }

  return `${profile.firstName} ${profile.lastName || ''}`;
};
