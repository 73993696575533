import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import GozioTableWithVirtualization from 'components/tables/gozioTableWithVirtualization';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const CATS_COLUMNS = [
  {
    Header: 'Category Name',
    accessor: 'name',
  },
];

const processSubCategories = (categories = [], selectedIds) => categories.map((node) => ({
    id: node.id,
    name: getLabelByLang(node.name),
    isSelected: !!selectedIds?.find((selected) => selected.id === node.id),
  }));

const CategoryTable = ({
                         category,
                         filter,
                         setCurrentCategory,
                         handleRowSelectUpdates,
                         selectedCategories,
                       }) => {
  const categoryData = useMemo(
    () => processSubCategories(category.children, selectedCategories),
    [category.children, selectedCategories],
  );

  const filteredCategoryData = useMemo(() => {
    const lowerFilter = filter.toLowerCase();
    return categoryData.filter(
      (item) => item.name.toLowerCase().indexOf(lowerFilter) >= 0,
    );
  }, [categoryData, filter]);

  return (
    <Box
      sx={{
        paddingTop: '16px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ padding: '0 32px', marginBottom: '16px' }}>
        <Typography
          variant="subtitle1"
          sx={{ color: ColorPalette.grey[500] }}
        >
          {getLabelByLang(category.name)}
        </Typography>
        <Box sx={{ marginTop: '4px' }} onClick={() => setCurrentCategory(null)}>
          <Typography variant="subtitle2" className="backLink">
            <ArrowBack className="backIcon" />
            Back to All Results
          </Typography>
        </Box>
      </Box>
      <Box sx={{ overflow: 'scroll', flex: 1 }}>
        <GozioTableWithVirtualization
          columns={useMemo(() => CATS_COLUMNS, [])} // eslint-disable-line react-hooks/exhaustive-deps
          data={filteredCategoryData}
          sortBy={[{ id: 'name', desc: false }]}
          selectable={true}
          handleRowSelectUpdates={handleRowSelectUpdates}
        />
      </Box>
    </Box>
  );
};

CategoryTable.propTypes = {
  category: PropTypes.object,
  setCurrentCompany: PropTypes.func,
  handleRowSelectUpdates: PropTypes.func,
  selectedCategories: PropTypes.array,
};

CategoryTable.defaultProps = {
  filter: '',
  category: {},
  selectedCategories: [],
  handleCountUpdate: () => {},
  handleRowSelectUpdates: () => {},
};

export default React.memo(CategoryTable);
