import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ className, sx, children, value, index, ...other }) => (
  <Box
    className={className}
    role="tabpanel"
    hidden={value !== index}
    sx={{ ...value !== index && { display: 'none' }, ...sx }}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </Box>
);

TabPanel.propTypes = {
  className: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.any,
};

TabPanel.defaultProps = {
  className: '',
  sx: {},
};

export default TabPanel;
