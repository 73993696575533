import { formatLang, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { allowedCharacterRegex } from 'helpers/location-util';
import { softDeleteImage } from 'helpers/media-util';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  deleteMedia,
  updateMedia,
  uploadMediaImagesAndVideos,
} from 'pages/locations/containers/detail/siteFormHelper';
import {
  DEFAULT_CUSTOM_HOURS,
  filterExceptionHours,
  HOURS_VALIDATION_OBJECT,
} from 'pages/locations/containers/locationsFormHelper';
import * as Yup from 'yup';

export const buildValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          // Landmark DETAILS
          name: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .required('Site name is required'),
          shortName: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
          shortNameES: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),

          // Landmark MEDIA
          youTubeUrl: Yup.string().matches(
            /^$|(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/,
            'We only support YouTube videos at this time.',
          ),

          // HOURS
          ...HOURS_VALIDATION_OBJECT,
        },
        languages,
        {
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

export const initialParser = (LandmarkData, languages) => {
  const tempVal = {
    designation: LandmarkData?.designation,
    saveBack: null,
    isMapped: LandmarkData?.isMapped || false,
    langES: hasSpanish(languages) || false,

    // MAPPING INPUTS
    ...!!LandmarkData?.indoorLocation?.mapKey && {
      mapID: LandmarkData?.indoorLocation?.mapKey,
    },
    xCoord: LandmarkData?.indoorLocation?.indoorPoint?.x || 0,
    yCoord: LandmarkData?.indoorLocation?.indoorPoint?.y || 0,

    // Landmark DETAILS
    externalId: LandmarkData?.externalId,
    ...!!parseByLang(LandmarkData?.name) && {
      name: parseByLang(LandmarkData?.name),
    },
    ...!!parseByLang(LandmarkData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(LandmarkData?.name, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(LandmarkData?.shortName) && {
      shortName: parseByLang(LandmarkData?.shortName),
    },
    ...!!parseByLang(LandmarkData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(LandmarkData?.shortName, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(LandmarkData?.description) && {
      description: parseByLang(LandmarkData?.description),
    },
    ...!!parseByLang(LandmarkData?.description, LANGUAGE_CODES.SPANISH) && {
      descriptionES: parseByLang(
        LandmarkData?.description,
        LANGUAGE_CODES.SPANISH,
      ),
    },

    // Parent Info
    parentSite: LandmarkData?.parentSite
      ? `${parseByLang(LandmarkData.parentSite.name)} (${
        LandmarkData.parentSite.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,
    parentBuilding: LandmarkData?.parentBuilding
      ? `${parseByLang(LandmarkData.parentBuilding.name)} (${
        LandmarkData.parentBuilding.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,
    parentFloor: LandmarkData?.parentFloor
      ? `${parseByLang(LandmarkData.parentFloor.name)} (${
        LandmarkData.parentFloor.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,

    // HOURS
    hoursType: LandmarkData?.contact?.hours?.type ?? 'none',
    customHours: LandmarkData?.contact?.hours?.custom ?? [
      ...DEFAULT_CUSTOM_HOURS,
    ],
    exceptionHours: filterExceptionHours(LandmarkData?.contact?.exceptionHours),

    // Landmark MEDIA
    ...!!LandmarkData?.media && {
      media: LandmarkData.media ?? [],
    },
    ...!!LandmarkData?.defaultImage && {
      defaultImage: LandmarkData.defaultImage,
    },

    // no ids, so we need to track idx as id
    actionLinks:
      LandmarkData?.actionLinks?.map((val, idx) => ({ idx, ...val })) || [],
  };
  return tempVal;
};

const buildActionLink = async (link) => {
  let imageId;
  if (link.type === 'custom') {
    imageId = link.icon?.image?.id;
    if (link.icon?.file) {
      const uploadedImage = await uploadImage(link.icon.file);
      imageId = uploadedImage?.id;
    }
  }
  return {
    ...link,
    __typename: undefined,
    icon: imageId,
    idx: undefined,
  };
};

const prepareActionLinks = async (dispatch, actionLinks) => {
  if (!actionLinks) return [];

  return Promise.all(actionLinks.map((link) => buildActionLink(link)));
};

export const submitParser = async (dispatch, values = {}) => {
  const restValues = {};
  if (values.media) {
    const mediaToBeUploaded = [];
    const mediaToBeUpdated = [];
    const mediaToBeDeleted = [];
    values.media.forEach((m) => {
      if (m.id) {
        if (m.toBeUpdated) {
          mediaToBeUpdated.push(m);
        } else if (m.toBeDeleted) {
          mediaToBeDeleted.push(m);
        }
      } else if (m.file) {
        mediaToBeUploaded.push(m); // image
      } else if (m.type === 'video') {
        mediaToBeUploaded.push(m); // video
      }
    });
    const uploadedImagesAndVideos = mediaToBeUploaded.length
      ? await uploadMediaImagesAndVideos(dispatch, mediaToBeUploaded)
      : [];

    if (mediaToBeUpdated.length) {
      await updateMedia(dispatch, mediaToBeUpdated);
    }

    if (mediaToBeDeleted.length) {
      await deleteMedia(mediaToBeDeleted);
    }

    const updatedList = [];
    let index = 0;
    values.media.forEach((m) => {
      if (m.id) {
        if (!m.toBeDeleted) {
          updatedList.push(m.id);
        }
      } else {
        updatedList.push(uploadedImagesAndVideos[index++].id);
      }
    });
    restValues.media = updatedList;
  }

  let defaultImageId = null;
  if (values.defaultImage) {
    if (values.defaultImage.image?.id) {
      defaultImageId = values.defaultImage.image.id;
    } else if (values.defaultImage.id) {
      defaultImageId = values.defaultImage.id;
    } else if (values.defaultImage.file) {
      const uploadedImage = await uploadImage(values.defaultImage.file);
      defaultImageId = uploadedImage?.id;
    }
  }

  if (values.deletedDefaultImageId) {
    await softDeleteImage(values.deletedDefaultImageId);
  }

  restValues.actionLinks = await prepareActionLinks(values.actionLinks);

  const submitVal = {
    // Landmark DETAILS
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
    description: formatLang('description', values),

    ...!!values.hoursType && {
      contact: {
        hours: {
          type: values.hoursType,
          custom:
            values.customHours && values.customHours.length
              ? values.customHours.map(
                ({ __typename, day, status, hours }) => ({
                  day,
                  status,
                  hours: hours.map(({ __typename, ...rest }) => rest),
                }),
              )
              : [],
        },
        ...!!values.exceptionHours && {
          exceptionHours: values.exceptionHours,
        },
      },
    },
    defaultImage: defaultImageId,
    ...restValues,
  };
  return submitVal;
};
