import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/textField/textField';
import { SUPPORTED_LANGUAGE_CODES } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React from 'react';

const LanguageBasedInput = ({
                              options,
                              filter,
                              values,
                              onChange,
                              onBlur,
                              error,
                              helperText,
                            }) => {
  const displayOptions
    = filter && filter.length > 0
      ? options.filter((opt) => filter.includes(opt.lang))
      : options;

  const theme = useTheme();
  const buildStyles = makeStyles({
    textField: {
      marginBottom: theme.spacing(4),
    },
    helperText: {
      padding: 0,
      marginTop: theme.spacing(3),
    },
    root: {
      marginBottom: theme.spacing(3),
    },
  });
  const classes = buildStyles();

  return (
    <Grid className={classes.root}>
      {(displayOptions || []).map((opt, idx) => {
        const { lang, label, charLimit, textFieldProps, customTags } = opt;
        return (
          <TextField
            label={label}
            key={lang}
            value={values ? values[lang] : undefined}
            fullWidth
            error={error ? error[lang] : undefined}
            helperText={helperText ? helperText[lang] : undefined}
            onChange={onChange ? onChange[lang] : undefined}
            onBlur={onBlur ? onBlur[lang] : undefined}
            charLimit={charLimit}
            customTags={customTags}
            containerClassName={
              idx === displayOptions.length - 1 ? null : classes.textField
            }
            {...textFieldProps}
          />
        );
      })}
    </Grid>
  );
};

LanguageBasedInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lang: PropTypes.oneOf(SUPPORTED_LANGUAGE_CODES).isRequired,
      label: PropTypes.string.isRequired,
      customTags: PropTypes.objectOf(PropTypes.string),
      charLimit: PropTypes.number,
      textFieldProps: PropTypes.object,
    }),
  ).isRequired,
  filter: PropTypes.arrayOf(PropTypes.oneOf(SUPPORTED_LANGUAGE_CODES)),
  values: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.objectOf(PropTypes.func),
  helperText: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.objectOf(PropTypes.bool),
  onBlur: PropTypes.objectOf(PropTypes.func),
};

export default LanguageBasedInput;
