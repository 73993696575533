import { useSuspenseQuery } from '@apollo/client';
import { GET_NETWORK_MOBILE_APPS } from 'graphql/queries';
import useHandleError from 'hooks/useHandleError';

export const useNetworkMobileApps = (networkId, skip = true) => {
  const { handleError } = useHandleError('NetworkConfiguration');

  const { data } = useSuspenseQuery(GET_NETWORK_MOBILE_APPS, {
    context: { headers: { network: networkId } },
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip,
  });

  return {
    mobileApps: data?.getNetwork?.mobileApps || [],
  };
};
