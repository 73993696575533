import { Link, Tooltip } from '@mui/material';
import ViewDetailsModal from 'components/viewDetailsModal/viewDetailsModal';
import { FlamingoContext } from 'contexts/flamingo';
import { convertDateToString } from 'helpers/date-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { formatDesignation } from 'helpers/location-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import PropTypes from 'prop-types';
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const descriptionMap = {
  email: 'Email Gozio Support for assistance.',
  missing: 'Information is missing. Please review this item.',
};

const dataTypeMapper = (userRole) => {
  const description
    = userRole === USER_ROLES.NETWORK_ADMIN
      ? descriptionMap.email
      : descriptionMap.missing;
  return {
    Network: (item, type, bundle) => ({
      name: 'Network',
      dataType: bundle === 'visualControls' ? 'Visual Controls' : 'Network',
      description,
      path: bundle === 'visualControls' ? 'visual-controls' : 'config',
    }),
    NetworkConfiguration: () => ({
      name: 'Network Configuration',
      dataType: 'Network Configuration',
      description: descriptionMap.email,
      path: 'config',
    }),
    SystemConfiguration: () => ({
      name: 'System Configuration',
      dataType: 'System Configuration',
      description: descriptionMap.email,
      path: 'config',
    }),
    NetworkPlace: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: formatDesignation(item.designation),
      description,
      path: `locations/${item.designation}/${item.id}`,
    }),
    Category: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Category',
      description,
      path: item.children
        ? `categories/group/${item.id}`
        : `categories/${item.id}`,
    }),
    NetworkTypeahead: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Typeahead',
      description,
      path: `typeahead/${item.id}/edit`,
    }),
    SystemTypeahead: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Global Typeahead',
      description,
      path: 'typeahead',
    }),
    NetworkCuratedList: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'List',
      description,
      path: `lists/${item.id}`,
    }),
    NetworkDashboardCard: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Dashboard',
      description,
      path: 'mobileDashboard',
    }),
    NetworkExplorePanel: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Explore Panel',
      description,
      path: 'explore',
    }),
    NetworkNavigation: () => ({
      name: 'Bottom Navigation',
      dataType: 'Bottom Navigation',
      description,
      path: 'navigation',
    }),
    KioskTemplate: () => ({
      name: 'Kiosk Template',
      dataType: 'Kiosk Template',
      description,
      path: 'kiosks/template',
    }),
    Kiosk: (item, type) => ({
      name: getLabelByLang(item[`${type}Name`]),
      dataType: 'Kiosk Settings',
      description,
      path: 'kiosks',
    }),
    Provider: (item) => ({
      name: 'Providers',
      dataType: 'Provider',
      description: `There ${item.count > 1 ? 'are' : 'is'} ${
        item.count
      } provider${item.count > 1 ? 's' : ''} missing data. ${
        descriptionMap.email
      }`,
    }),
    Generic: () => ({
      name: 'Generic',
      dataType: 'Generic',
      description,
    }),
  };
};

const getDataFacetType = (errorDetail, userRole) => {
  const { group } = errorDetail.publishingError;
  const dataFacetType
    = group.toLowerCase() !== 'provider'
      ? errorDetail.publishingError?.dataFacet?.type
      : 'Provider';
  if (dataTypeMapper(userRole).hasOwnProperty(dataFacetType)) {
    return dataFacetType;
  }

  return 'Generic';
};

const PublishFailureModal = ({
                               dataPackage,
                               errorDescription,
                               handleClose,
                               loading,
                               open,
                             }) => {
  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const [mappedTableData, setMappedTableData] = useState([]);
  const userRole = useCheckRole();

  useLayoutEffect(() => {
    if (dataPackage) {
      const errorDetails = dataPackage.errorDetails;
      setMappedTableData(
        errorDetails.map((errorDetail) => {
          const { bundle, group } = errorDetail.publishingError;
          const { message } = errorDetail.error;
          const dataFacetItem
            = group.toLowerCase() !== 'provider'
              ? errorDetail.publishingError?.dataFacet?.item
              : { count: errorDetail.publishingError.count };
          const dataFacetType = getDataFacetType(errorDetail, userRole);
          const { name, dataType, description, path } = dataFacetItem
            ? dataTypeMapper(userRole)[dataFacetType](
              dataFacetItem || {},
              dataFacetType,
              bundle,
            )
            : group === 'system'
              ? {
                name: 'Application Error',
                dataType: 'System',
                description:
                  'Try publishing again. If this problem persists, email Gozio Support for assistance.',
              }
              : {
                name: '',
                dataType: '',
                description:
                  'This error refers to an item that no longer exists.',
              };
          return {
            name,
            dataType,
            errorType: capitalize(group),
            description: (
              <>
                <Tooltip title={message}>
                  <span>{description}</span>
                </Tooltip>
              </>
            ),
            path,
            id: dataFacetItem?.id,
          };
        }),
      );
    }
  }, [dataPackage, userRole]);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        alwaysHidden: true,
      },
      {
        Header: 'Path',
        accessor: 'path',
        alwaysHidden: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        onClick: (item) => {
          if (['Global Typehead', 'System Configuration'].includes(item.name)) {
            handleNavigate(`/network/Global%20Template/${item.path}`);
          } else {
            handleNavigate(`/network/${networkId}/${item.path}`);
          }
        },
        isClickable: (item) => item.path !== undefined,
      },
      {
        Header: 'Data Type',
        accessor: 'dataType',
        maxWidth: 150,
      },
      {
        Header: 'Error Type',
        accessor: 'errorType',
        maxWidth: 150,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 300,
      },
    ],
    [handleNavigate, networkId],
  );

  return (
    open && (
      <ViewDetailsModal
        columns={tableColumns}
        data={mappedTableData}
        date={convertDateToString(dataPackage?.createdAt)}
        descriptionTitle="Error Description"
        description={errorDescription}
        handleClose={() => {
          setMappedTableData([]);
          handleClose();
        }}
        messageTitle="Publishing Description"
        message={dataPackage?.message}
        loading={!dataPackage}
        rowsPerPage={10}
        tableTitle={`${dataPackage?.errorDetails?.length} Errors Found`}
        tableMessage={
          <>
            If there are errors you'd like assistance with{' '}
            <Link
              href={`mailto:support@goziohealth.com?subject=Request for support: Publishing Failed #${dataPackage?.externalId}`}
            >
              email Gozio Support
            </Link>{' '}
            for help.
          </>
        }
        userName={`${dataPackage?.createdBy?.profile?.firstName} ${dataPackage?.createdBy?.profile?.lastName}`}
        title={
          dataPackage
            ? `Publishing Failed — Publish #${dataPackage.externalId}`
            : 'Publish'
        }
      />
    )
  );
};

PublishFailureModal.propTypes = {
  dataPackage: PropTypes.object,
  errorDescription: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

PublishFailureModal.defaultProps = {};

export default React.memo(PublishFailureModal);
