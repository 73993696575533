import { Box, Grid, Typography } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import Loading from 'components/loading/loading';
import GozioTable from 'components/tables/gozioTable';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const ViewDetailsModal = ({
                            columns,
                            data,
                            date,
                            descriptionTitle,
                            description,
                            handleClose,
                            loading,
                            messageTitle,
                            message,
                            rowsPerPage,
                            subtitle,
                            tableTitle,
                            tableMessage,
                            title,
                            userName,
                          }) => {
  const modalBody = useMemo(() => {
    if (loading) {
      return <Loading backgroundColor={ColorPalette.white} />;
    }

    return (
      <Box>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        <Box sx={{ marginTop: subtitle ? '32px' : 0 }}>
          {messageTitle && message && (
            <>
              <Typography variant="caption" sx={{ display: 'block' }}>
                {messageTitle}
              </Typography>
              <Typography>{message}</Typography>
            </>
          )}
          {descriptionTitle && description && (
            <>
              <Typography
                variant="caption"
                sx={{ display: 'block', marginTop: '24px' }}
              >
                {descriptionTitle}
              </Typography>
              <Typography>{description}</Typography>
            </>
          )}
          <Grid container sx={{ marginTop: '28px' }}>
            <Grid item xs={6} lg>
              <Typography variant="caption" sx={{ display: 'block' }}>
                Date
              </Typography>
              <Typography>{date}</Typography>
            </Grid>
            <Grid item xs={6} lg>
              <Typography variant="caption" sx={{ display: 'block' }}>
                User
              </Typography>
              <Typography>{userName}</Typography>
            </Grid>
          </Grid>
          {tableTitle && (
            <Typography variant="subtitle1" sx={{ marginTop: '40px' }}>
              {tableTitle}
            </Typography>
          )}
          {tableMessage && (
            <Typography sx={{ marginTop: '8px', marginBottom: '24px' }}>
              {tableMessage}
            </Typography>
          )}
          {columns?.length > 0 && data?.length > 0 && (
            <GozioTable
              sx={{
                height: 600,
                '& .MuiTableFooter-root': {
                  paddingBottom: 0,
                },
              }}
              fullWidth
              columns={columns}
              data={data}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Box>
      </Box>
    );
  }, [
    columns,
    data,
    date,
    description,
    descriptionTitle,
    loading,
    message,
    messageTitle,
    rowsPerPage,
    subtitle,
    tableMessage,
    tableTitle,
    userName,
  ]);

  return (
    <GenericModal
      title={title}
      body={modalBody}
      open={true}
      handleClose={handleClose}
      disableActions={true}
      maxWidth={'md'}
      fullWidth={true}
      scrollable={true}
      formContentSx={{
        overflowY: 'auto',
      }}
    />
  );
};

ViewDetailsModal.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      accessor: PropTypes.string.isRequired,
      cellStyle: PropTypes.object,
      disableClick: PropTypes.bool,
      hidden: PropTypes.bool,
      alwaysShown: PropTypes.bool,
      onClick: PropTypes.func,
      justifyRight: PropTypes.bool,
      render: PropTypes.func, // custom column rendering
      disableSortBy: PropTypes.bool,
      sticky: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.string,
  descriptionTitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  messageTitle: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  tableTitle: PropTypes.string,
  tableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

ViewDetailsModal.defaultProps = {
  columns: [],
  data: [],
  date: '',
  description: null,
  loading: false,
  messageTitle: null,
  message: null,
  rowsPerPage: undefined,
  subtitle: null,
  tableTitle: null,
  tableMessage: null,
  userName: '',
};

export default React.memo(ViewDetailsModal);
