import { Add, AddPhotoAlternate, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import ColorOptions from 'components/colorOptions/colorOptions';
import EmptySection from 'components/emptySection/emptySection';
import FormWatcher from 'components/forms/formWatcher';
import GenericModal from 'components/genericModal/genericModal';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useDeleteNavigationItem, useNavigation, useUpdateNavigation } from 'hooks/dataHooks/useNavigation';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useWindowSize from 'hooks/useWindowSize';
import _ from 'lodash';
import { makeValidate } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import {
  BOTTOM_NAVIGATION_LINKS,
  buildNavigationTab,
  getBottomNavTemplateCount,
  isAboutUsLink,
  MORE_MENU_LINKS,
} from 'pages/mobileSettings/bottomNavigation/bottomNavigationHelper';
import BottomNavPreview from 'pages/mobileSettings/bottomNavigation/containers/BottomNavPreview';
import EditAboutUsPanel from 'pages/mobileSettings/bottomNavigation/containers/EditAboutUsPanel';
import EditBottomNavLinkPanel from 'pages/mobileSettings/bottomNavigation/containers/EditBottomNavLinkPanel';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const TAB_COLUMNS = [
  {
    Header: 'Bottom Nav Link Name',
    accessor: 'name',
    disableSortBy: true,
    width: 232,
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    disableSortBy: true,
    width: 116,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    width: 94,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    width: 96,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
  },
];

const ITEM_COLUMNS = [
  {
    Header: 'More Menu Link Name',
    accessor: 'name',
    disableSortBy: true,
    width: 280,
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    disableSortBy: true,
    width: 130,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    width: 80,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
  },
];

const DEFAULT_MORE_MENU_COLORS = {
  moreMenuBackground: ColorPalette.grey[600],
  moreMenuPressed: ColorPalette.white,
  moreMenuText: ColorPalette.white,
};

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.white,
    position: 'relative',

    '& .MuiTableHead-root': {
      zIndex: 1,
    },
  },
  topContent: {
    background: theme.palette.white,
    display: 'grid',
    minWidth: '300px',
    marginLeft: `-${theme.spacing(3)}`,
    padding: `${theme.spacing(4)} 0 0 0`,
    position: 'fixed',
    top: '68px',
    zIndex: 1000,
  },
  pageTitle: {
    alignItems: 'center',
    alignSelf: 'center',
    gridColumnEnd: 'span 8',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
  },
  tabContainer: {
    background: theme.palette.white,
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginTop: theme.spacing(1),
    zIndex: 2,
  },
  tabs: {
    paddingLeft: theme.spacing(3),
    zIndex: 1003,
  },
  tabPanel: {
    background: theme.palette.white,
    height: '100%',
    margin: `106px ${theme.spacing(3)} 0 -${theme.spacing(3)}`,
    overflowY: 'auto',
    padding: `0 ${theme.spacing(3)} ${theme.spacing(10)} ${theme.spacing(3)}`,
  },
  fieldSection: {
    marginBottom: theme.spacing(4),
  },
  tabName: {
    display: 'flex',
    alignItems: 'center',
  },
  addItemBtn: {
    top: '-8px',
    right: '16px',
    width: '32px',
    height: '32px',
  },
  tabIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '38px',
    justifyContent: 'center',
    marginRight: '10px',
    width: '38px',

    '& img': {
      height: '38px',
      width: '38px',
    },
    '& svg': {
      color: theme.palette.white,
      height: '24px',
      width: '24px',
    },
  },
  placeholderTabIcon: {
    background: theme.palette.grey[350],
    borderRadius: '50%',
  },
  itemsTableContainer: {
    position: 'relative',
    top: '-24px',
  },
  actionBar: {
    background: theme.palette.grey[50],
    borderTop: `2px solid ${colorWithAlpha(ColorPalette.grey[350], 0.3)}`,
    bottom: 0,
    display: 'flex',
    height: '72px',
    justifyContent: 'flex-end',
    padding: '15px',
    position: 'fixed',
  },
  previewContainer: {
    height: '100%',
    overflow: 'scroll',
    position: 'fixed',
    zIndex: 4,
  },
});

const schema = Yup.object().shape({
  fillColor: Yup.string().matches(/^#([A-Fa-f0-9]){6}$/i, {
    message: 'Default Fill Color must match this format: #RRGGBB',
    excludeEmptyString: true,
  }),
  items: Yup.array(),
  selectedColor: Yup.string().matches(/^#([A-Fa-f0-9]){6}$/i, {
    message: 'Selected Fill Color must match this format: #RRGGBB',
    excludeEmptyString: true,
  }),
  tabs: Yup.array(),
  template: Yup.string().oneOf([
    'three_actions',
    'four_actions',
    'five_actions',
  ]),
});

const getQuicklinksProgressMap = (validation, data = {}) => validation?.errors?.reduce(
    (a, b) => ({
      ...a,
      [b?.path[0]]: {
        ...a[b.path[0]],
        [data[b.path[0]]?.[b.path[1]]?.id]: {
          field: b.path[2],
          message: b.message,
          index: b.path[1],
        },
      },
    }),
    {},
  ) || {};

const mapToActionLinkInputIdentityList = (items = [], originalItems = []) => items.map((item, index) => {
    const originalItem = originalItems.find((t) => t.id === item.id);
    if (originalItem) {
      const { data, icon, type } = originalItem;
      return {
        data,
        icon: icon?.image?.id,
        name: item.name,
        order: index,
        type: type || undefined,
      };
    }

    const { data, icon, name, shortName, type } = item;
    return { data, icon: icon?.image?.id, name, order: index, shortName, type };
  });

const mergeEditedDataWithValues = (editedLink, key, values) => {
  if (editedLink) {
    const { [key]: list, ...rest } = values;
    let updatedList;
    if (editedLink.id) {
      updatedList = list.map((n) => n.id === editedLink.id ? editedLink : n);
    } else {
      updatedList = [...list, editedLink];
    }

    return { [key]: updatedList, ...rest };
  }

  return values;
};

const BottomNavigationPageContent = ({ styles, openDrawer }) => {
  const validate = makeValidate(schema);

  const { networkId } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [initialValues, setInitialValues] = useState(null);
  const [progressMap, setProgressMap] = useState({});
  const [selectedTabForEdit, setSelectedTabForEdit] = useState(null);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);
  const [editedTabOrItem, setEditedTabOrItem] = useState(null);
  const [moreMenuItemColors, setMoreMenuItemColors] = useState(
    DEFAULT_MORE_MENU_COLORS,
  );
  const [editPanelTabValue, setEditPanelTabValue] = useState(0);
  const [previewData, setPreviewData] = useState({});

  const size = useWindowSize();
  const { data, configColors, loading } = useNavigation(networkId);
  const [updateNavigation] = useUpdateNavigation(networkId);
  const [deleteNavicationItem] = useDeleteNavigationItem(networkId);

  const languages = useActiveNetworkLanguages();

  const leftWidth = openDrawer ? 586 : 836;

  useEffect(() => {
    if (configColors) {
      const map = DEFAULT_MORE_MENU_COLORS;
      configColors.forEach(({ key, value }) => {
        switch (key) {
          case 'navigation_item_bg_normal':
            map.moreMenuBackground = value;
            break;
          case 'navigation_item_bg_pressed':
            map.moreMenuPressed = value;
            break;
          case 'navigation_item_text':
            map.moreMenuText = value;
            break;
          default:
            // Ignore
            break;
        }
      });
      setMoreMenuItemColors(map);
    }
  }, [configColors]);

  useEffect(() => {
    if (!loading && data) {
      const progressMap = getQuicklinksProgressMap(data.validation, {
        items: data.items,
        tabs: data.tabs,
      });
      setProgressMap(progressMap);
      setInitialValues({
        template: data?.template || 'three_actions',
        fillColor: data?.fillColor || '#C2C3C4',
        selectedColor: data?.selectedColor || '#C2C3C4',
        tabs: data?.tabs || [],
        items: data?.items || [],
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading) {
      const scrollElement = document.getElementsByTagName('main');
      const tabPanelElement = document.getElementById(`tabPanel${currentTab}`);
      const height = size.height - 209;
      if (scrollElement?.length > 0) {
        const mainElement = scrollElement[0];
        mainElement.scrollTop = 0;
        setTimeout(() => {
          mainElement.style.overflow = 'hidden';
          if (tabPanelElement?.clientHeight < height) {
            mainElement.style.overflow = 'hidden';
          } else {
            mainElement.style.overflow = 'auto';
          }
        }, 1);
      }
    }
  }, [currentTab, loading, size]);

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const buildTabs = (values) => {
    const { tabs = [] } = values;
    const templateNum = getBottomNavTemplateCount(values.template);
    if (tabs?.length > 0) {
      return tabs.map((tab, index) => ({
        id: tab.id,
        name: (
          <Box sx={styles.tabName}>
            <Box
              sx={{
                ...styles.tabIcon,
                ...!tab.icon?.url && styles.placeholderTabIcon,
              }}
            >
              {tab.icon?.url ? (
                <img src={tab.icon?.url} alt="icon" />
              ) : (
                <AddPhotoAlternate />
              )}
            </Box>
            <span>{getLabelByLang(tab.name)}</span>
          </Box>
        ),
        progress: progressMap.tabs?.[tab.id] ? 'Incomplete' : 'Complete',
        active: index >= templateNum ? 'No' : 'Yes',
        actions: (
          <Box className="hoverUnhide">
            <Tooltip title="Edit">
              <Edit onClick={() => setSelectedTabForEdit(tab)} />
            </Tooltip>
          </Box>
        ),
        _meta: {
          inactive: index >= templateNum,
        },
      }));
    }
    return [];
  };

  const buildItems = (values) => {
    const { items = [] } = values;
    if (items?.length > 0) {
      return items.map((item, i) => ({
        id: item.id,
        name: (
          <Box sx={styles.tabName}>
            <Box
              sx={{
                ...styles.tabIcon,
                ...!item.icon?.url && styles.placeholderTabIcon,
              }}
            >
              {item.icon?.url ? (
                <img src={item.icon?.url} alt="icon" />
              ) : (
                <AddPhotoAlternate />
              )}
            </Box>
            <span>{getLabelByLang(item.name)}</span>
          </Box>
        ),
        progress: progressMap.items?.[item.id] ? 'Incomplete' : 'Complete',
        actions: (
          <Box
            className="hoverUnhide"
            data-test={isAboutUsLink(item) ? 'about' : null}
          >
            <Tooltip title="Edit">
              <Edit onClick={() => setSelectedItemForEdit(item)} />
            </Tooltip>
            {!isAboutUsLink(item) && (
              <Tooltip title="Delete">
                <Delete onClick={() => setSelectedItemForDelete(item)} />
              </Tooltip>
            )}
          </Box>
        ),
      }));
    }
    return [];
  };

  const reorderTabRows = (onChange, originalList, row1, row2) => {
    if (row1 && row2) {
      const list = Array.from(originalList);
      const from = list.findIndex((item) => item.id === row1.original.id);
      const to = list.findIndex((item) => item.id === row2.original.id);
      [list[from], list[to]] = [list[to], list[from]];
      onChange(list);
    }
  };

  const onSubmit = async (values = {}) => {
    setIsSubmitting(true);
    const updatedData = {
      id: data?.id,
      input: {
        ...values,
        items: mapToActionLinkInputIdentityList(values.items, data?.items),
        tabs: mapToActionLinkInputIdentityList(values.tabs, data?.tabs),
      },
    };

    await updateNavigation({
      variables: updatedData,
    });
    setIsSubmitting(false);
  };

  const handleEditCardCancelled = () => {
    setEditedTabOrItem(null);
    setSelectedTabForEdit(null);
    setSelectedItemForEdit(null);
    setEditPanelTabValue(0);
  };

  const handleEditCardChanged = (data) => {
    setEditedTabOrItem(data);
  };

  const handleEditCardSaved = (updatedTabOrItem, values) => {
    const updatedLink = buildNavigationTab(values, languages);
    setEditedTabOrItem(null);
    if (currentTab === 0) {
      setSelectedTabForEdit(
        Object.assign({ ...updatedTabOrItem }, updatedLink),
      );
    } else {
      setSelectedItemForEdit(
        Object.assign({ ...updatedTabOrItem }, updatedLink),
      );
    }
  };

  const handleDeleteMoreMenuLink = async () => {
    await deleteNavicationItem({
      variables: {
        id: data.id,
        actionId: selectedItemForDelete.id,
      },
    });
    setSelectedItemForDelete(null);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const showEditPanel = !!(selectedTabForEdit || selectedItemForEdit);

  const getIncompleteMessage = useCallback(() => {
    if (isGlobalTemplate(networkId)) {
      return null;
    }
    if (showEditPanel || !data) {
      return null;
    }
    return data?.validation?.status === 'complete'
      ? null
      : 'Bottom Navigation/More Menu is incomplete.';
  }, [data, networkId, showEditPanel]);

  const leftDrawerWidth = openDrawer ? 250 : 0;

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
            handleSubmit,
            form,
            values,
            submitting,
            invalid,
            errors,
            dirty,
          }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                if (values && Object.keys(values).length > 0) {
                  setPreviewData(
                    mergeEditedDataWithValues(
                      editedTabOrItem,
                      selectedTabForEdit ? 'tabs' : 'items',
                      values,
                    ),
                  );
                }
              }}
            />
            <FormWatcher
              formRenderProps={{ form }}
              errorFormFieldMap={
                new Map([
                  [
                    'fillColor',
                    { label: 'Default Fill Color', section: 'Color Options' },
                  ],
                  [
                    'selectedColor',
                    { label: 'Selected Fill Color', section: 'General' },
                  ],
                ])
              }
              isClosing={isClosing}
              isSubmitting={isSubmitting}
              onClose={() => {
                setIsClosing(false);
                setIsSubmitting(false);
              }}
            />

            {!showEditPanel && (
              <>
                <Box
                  sx={{ ...styles.topContent, width: `${leftWidth}px` }}
                >
                  <Box sx={styles.pageTitle}>
                    <Typography
                      variant="h1"
                      display="block"
                      data-test="FlamingoPage-title-Bottom Navigation"
                    >
                      Bottom Navigation
                    </Typography>
                  </Box>
                  <Box
                    sx={{ ...styles.tabContainer, width: `${leftWidth}px` }}
                  >
                    <Tabs
                      sx={styles.tabs}
                      value={currentTab}
                      onChange={handleTabChange}
                      aria-label="bottom nav tabs"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab
                        label="Bottom Navigation"
                        {...tabA11yProps('bottom-nav', 0)}
                      />
                      <Tab
                        label="More Menu"
                        {...tabA11yProps('bottom-nav', 1)}
                      />
                    </Tabs>
                  </Box>
                </Box>
                <TabPanel
                  id="tabPanel0"
                  value={currentTab}
                  index={0}
                  sx={{ ...styles.tabPanel, width: `${leftWidth}px` }}
                >
                  <Box sx={styles.fieldSection}>
                    <Grid
                      item
                      xs
                      sx={{ marginBottom: '32px', marginTop: '24px' }}
                    >
                      <Typography variant="subtitle1">Display</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1">Template Option</Typography>
                    </Grid>
                    <Grid item xs>
                      <Field name="template">
                        {({ input: { value, onChange } }) => (
                          <GozioRadioGroup
                            groupName="template"
                            onChange={onChange}
                            options={[
                              { label: '3 Actions', value: 'three_actions' },
                              { label: '4 Actions', value: 'four_actions' },
                              { label: '5 Actions', value: 'five_actions' },
                            ]}
                            row={true}
                            value={value}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Box>
                  <Box sx={styles.fieldSection}>
                    <Grid item xs sx={{ marginBottom: '24px' }}>
                      <Typography variant="subtitle1">Color Options</Typography>
                    </Grid>
                    <Grid item xs sx={{ width: '260px' }}>
                      <Field name="fillColor">
                        {({ input: { value, onChange } }) => (
                          <ColorOptions
                            initialColor={value}
                            formControl={true}
                            getColor={onChange}
                            label="Default Fill Color"
                            required={true}
                            inputProps={{
                              'data-test': 'defaultFillColor',
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs sx={{ marginTop: '24px', width: '260px' }}>
                      <Field name="selectedColor">
                        {({ input: { value, onChange } }) => (
                          <ColorOptions
                            initialColor={value}
                            formControl={true}
                            getColor={onChange}
                            label="Selected Fill Color"
                            required={true}
                            inputProps={{
                              'data-test': 'selectedFillColor',
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Box>
                  <Box sx={styles.fieldSection}>
                    <Grid
                      item
                      xs
                      sx={{ marginBottom: '24px', paddingTop: '8px' }}
                    >
                      <Typography variant="subtitle1">
                        Bottom Navigation Links
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Field name="tabs">
                        {({ input: { onChange } }) => (
                          <GozioTable
                            sx={{
                              padding: 0,
                            }}
                            columns={TAB_COLUMNS}
                            data={buildTabs(values)}
                            hidePagination={true}
                            draggable={true}
                            onDrag={(row1, row2) => reorderTabRows(onChange, values.tabs, row1, row2)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel
                  id="tabPanel1"
                  value={currentTab}
                  index={1}
                  sx={{ ...styles.tabPanel, paddingTop: '16px', width: `${leftWidth}px` }}
                >
                  <Box
                    sx={{ ...styles.fieldSection, paddingTop: '8px' }}
                  >
                    <Grid
                      item
                      xs
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '28px',
                        paddingTop: '16px',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ display: 'inline-block' }}
                      >
                        {values.items?.length} More Menu Links
                      </Typography>
                      <AddCircleButton
                        onClick={() => {
                          setSelectedItemForEdit({});
                        }}
                        aria-label="Add More Menu Link"
                        sx={styles.addItemBtn}
                        tooltipTitle="Add a More Menu Link"
                      />
                    </Grid>
                    <Grid item xs sx={styles.itemsTableContainer}>
                      {values.items?.length === 0 && (
                        <EmptySection
                          title="There Are No More Menu Links Set Up"
                          buttonLabel="Add A More Menu Link"
                          hasPermission={true}
                          ButtonIcon={Add}
                          buttonClicked={() => {
                            setSelectedItemForEdit({});
                          }}
                        />
                      )}
                      {values.items?.length > 0 && (
                        <Field name="items">
                          {({ input: { onChange } }) => (
                            <GozioTable
                              sx={{
                                padding: 0,
                                width: '100%',
                              }}
                              columns={ITEM_COLUMNS}
                              data={buildItems(values)}
                              hidePagination={true}
                              draggable={true}
                              emptyTitle="There Are No More Menu Links Set Up"
                              onDrag={(row1, row2) => reorderTabRows(
                                  onChange,
                                  values.items,
                                  row1,
                                  row2,
                                )
                              }
                            />
                          )}
                        </Field>
                      )}
                    </Grid>
                  </Box>
                </TabPanel>
                <Box
                  sx={{ ...styles.actionBar, left: `${leftDrawerWidth}px`, width: `${leftWidth}px` }}
                >
                  <Button
                    color="primary"
                    data-test="confirm-button"
                    disabled={_.isEqual(initialValues, values) || submitting}
                    onClick={async () => {
                      await form.submit();
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
          </form>
        )}
      </Form>
      <BottomNavPreview
        fixed
        configColors={moreMenuItemColors}
        data={previewData}
        editPanelTabValue={editPanelTabValue}
        incompleteMessage={getIncompleteMessage()}
        selectedItem={selectedTabForEdit || selectedItemForEdit}
        sx={{
          ...styles.previewContainer,
          left: showEditPanel ? 0 : '836px',
          width: showEditPanel
            ? `${size.width - 568 + leftDrawerWidth}px`
            : `${size.width - 836}px`,
        }}
        title={showEditPanel ? 'Bottom Navigation' : null}
        type={currentTab === 0 ? BOTTOM_NAVIGATION_LINKS : MORE_MENU_LINKS}
      />
      {showEditPanel && !isAboutUsLink(selectedItemForEdit) && (
        <EditBottomNavLinkPanel
          data={selectedTabForEdit || selectedItemForEdit}
          languages={languages}
          navId={data?.id}
          onEditCardCancelled={handleEditCardCancelled}
          onEditCardChanged={handleEditCardChanged}
          onEditCardSaved={handleEditCardSaved}
          title={
            selectedTabForEdit
              ? 'Edit Bottom Navigation Link'
              : selectedItemForEdit.id
                ? 'Edit More Menu Link'
                : 'Add More Menu Link'
          }
          type={currentTab === 0 ? BOTTOM_NAVIGATION_LINKS : MORE_MENU_LINKS}
        />
      )}
      {isAboutUsLink(selectedItemForEdit) && (
        <EditAboutUsPanel
          data={selectedItemForEdit}
          languages={languages}
          navId={data?.id}
          onEditCardCancelled={handleEditCardCancelled}
          onEditCardChanged={handleEditCardChanged}
          onEditCardSaved={handleEditCardSaved}
          onTabValueChanged={setEditPanelTabValue}
          title="Edit About Page"
        />
      )}
      {selectedItemForDelete && (
        <GenericModal
          title="Delete More Menu Link"
          body={`Are you sure you want to delete the More Menu Link ${getLabelByLang(
            selectedItemForDelete.name,
          )}?`}
          open={true}
          handleClose={() => setSelectedItemForDelete(null)}
          handleConfirm={handleDeleteMoreMenuLink}
          confirmText="Delete More Menu Link"
          customTags={{
            confirm: 'BottomNavMoreMenuLinkDeleteModalConfirmButton',
            cancel: 'BottomNavMoreMenuLinkDeleteModalCancelButton',
          }}
        />
      )}
      <WorkspaceSwitcher
        contentWidth={
          showEditPanel ? size.width - 568 - leftDrawerWidth : size.width - 836
        }
        leftOffset={showEditPanel ? 250 : 836 + (openDrawer ? 0 : 250)}
      />
    </>
  );
};

BottomNavigationPageContent.propTypes = {
  openDrawer: PropTypes.bool,
  styles: PropTypes.object.isRequired,
};

BottomNavigationPageContent.defaultProps = {
  openDrawer: false,
};

const BottomNavigationPage = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const {
    layout: { openDrawer },
  } = useContext(FlamingoContext);

  return (
    <LoggedinLayout
      sx={styles.root}
      enableSuspense={true}
      showWorkspaceSwitcher={false}
      supportedWorkspace={LIVE_WORKSPACE}
    >
      <BottomNavigationPageContent styles={styles} openDrawer={openDrawer} />
    </LoggedinLayout>
  );
};

export default BottomNavigationPage;
