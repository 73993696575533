import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeValidate } from 'mui-rff';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useUpdateNetworkPublishingControl } from 'hooks/dataHooks/useNetworkConfiguration';
import { useWorkspace } from 'hooks/useWorkspace';
import { PUBLISHING_STATE_MAP } from 'pages/publishing/publishingHelper';

const publishSettingsSchema = Yup.object().shape({
  setting: Yup.string().oneOf(['enabled', 'gozioAdmin', 'disabled']),
});

const validate = makeValidate(publishSettingsSchema);

export const PublishingSettingsModal = ({
                                          handleClose,
                                          publishingControl,
                                          handleSave,
                                        }) => {
  const [updatePublishingSettings] = useUpdateNetworkPublishingControl();
  const { workspace } = useWorkspace();

  const onSubmit = async (data) => {
    await updatePublishingSettings({
      variables: {
        input: {
          ...workspace !== DRAFT_WORKSPACE && {
            draftWorkspace: publishingControl.draft,
          },
          ...workspace !== LIVE_WORKSPACE && {
            liveWorkspace: publishingControl.live,
          },
          [`${workspace}Workspace`]: data.setting,
        },
      },
    });
    handleSave();
  };

  const radioGroupOptions = [
    {
      label: 'Enabled for All',
      value: PUBLISHING_STATE_MAP.ENABLED,
    },
    {
      label: 'Disabled for All',
      value: PUBLISHING_STATE_MAP.DISABLED,
    },
  ];

  if (workspace === LIVE_WORKSPACE) {
    radioGroupOptions.splice(1, 0, {
      label: 'Enabled for Gozio Admins Only',
      value: PUBLISHING_STATE_MAP.ADMIN,
    });
  }

  return (
    <GenericFormModal
      title="Publishing Settings"
      open={true}
      formParams={{
        onSubmit,
        validate,
        initialValues: {
          setting: publishingControl[workspace],
        },
      }}
      body={
        <Grid container direction="column">
          <Grid item sx={{ marginTop: '-24px' }}>
            <Typography variant="body1">
              Changing the Ability to Publish affects user’s ability to publish
              in the {workspace === DRAFT_WORKSPACE ? 'Draft' : 'Live'}{' '}
              Workspace.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Ability to Publish</Typography>
            <Field name="setting">
              {({ input }) => (
                <GozioRadioGroup
                  {...input}
                  alignment="vertical"
                  options={radioGroupOptions}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      }
      confirmText="Save Settings"
      handleClose={handleClose}
    />
  );
};

PublishingSettingsModal.propTypes = {
  publishingControl: PropTypes.object,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};

PublishingSettingsModal.defaultProps = {
  publishingControl: {},
  handleClose: () => {},
  handleSave: () => {},
};

export default PublishingSettingsModal;
