import { AddPhotoAlternate } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import WarningPanel from 'components/warningPanel/warningPanel';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    padding: '32px 28px',
    maxWidth: '526px',
    paddingLeft: '28px',
    margin: '0 auto',
  },
  header: {
    height: '110px',
  },
  warningIcon: {
    color: theme.palette.orange[400],
    fontSize: '20px',
    marginRight: '6px',
  },
  warningText: {
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.orange[400],
    height: '20px',
  },
  emptyContainer: {
    height: 'calc(100% - 110px)',
    width: '100%',
    padding: '0 24px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewTileWrapper: {
    height: '164px',
    maxWidth: '164px',
  },
  previewTilePane: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '14px 14px 7px 14px',
    height: '140px',
    width: '140px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.25)',
  },
  tileImagePreview: {
    height: '68px',
    width: '68px',
    borderRadius: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '41px',
      width: '41px',
    },
    '& svg': {
      fontSize: '48px',
      color: theme.palette.white,
    },
  },
  tileNameWrap: {
    width: '126px',
    height: '51px',
    paddingLeft: '7px',
    paddingRight: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& h6': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tileName: {
    fontSize: '1.25rem',
    textAlign: 'center',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
}));

const TilePreview = ({ tile, classes }) => (
  <Grid item xs={4} className={classes.previewTileWrapper}>
    <Box
      className={classes.previewTilePane}
      sx={{ backgroundColor: tile.backgroundColor }}
    >
      <Box
        className={classes.tileImagePreview}
        sx={{
          backgroundColor: colorWithAlpha(ColorPalette.grey[600], 0.25),
        }}
      >
        {tile?.icon?.url ? (
          <img src={tile.icon.url} alt={`Tile ${tile.name} Preview`} />
        ) : (
          <AddPhotoAlternate />
        )}
      </Box>
      <Box className={classes.tileNameWrap}>
        <Typography
          variant="subtitle1"
          className={classes.tileName}
          sx={{
            color: ColorPalette.white,
          }}
        >
          {tile.name}
        </Typography>
      </Box>
    </Box>
  </Grid>
);

const KioskDetails = ({ kiosk }) => {
  const classes = useStyles();

  const IncompleteTag = ({ progress }) => (
    <Box sx={{ marginBottom: '32px' }}>
      {progress === 'incomplete' && (
        <WarningPanel
          message={'Kiosk Directory is incomplete.'}
          disableClose={true}
          sx={{ marginLeft: '-2px' }}
        />
      )}
    </Box>
  );

  const tileLength = kiosk?.directory?.tiles?.length || 0;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <IncompleteTag progress={kiosk?.directory?.progress} />
        <Typography variant="subtitle1">Kiosk Directory Preview</Typography>
      </Box>
      {tileLength > 0 ? (
        <Grid container spacing={3}>
          {kiosk?.directory?.tiles
            .sort((a, b) => a.order || 0 - b.order || 0)
            .map((tile, idx) => (
              <TilePreview
                key={`kiosk-tile-${idx}`}
                tile={tile}
                classes={classes}
              />
            ))}
        </Grid>
      ) : (
        <Box className={classes.emptyContainer}>
          <EmptyPanel
            title="There Are No Directory Tiles Set Up"
            subtitle="Start creating this Kiosk’s Directory by adding a tile."
          />
        </Box>
      )}
    </Box>
  );
};

export default KioskDetails;
