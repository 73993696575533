import { Box, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TakeoverHeader from 'components/takeoverHeader/takeoverHeader';
import { colorWithAlpha } from 'helpers/color-util';
import { tabA11yProps } from 'helpers/page-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  headerInner: {
    height: '134px',
    background: theme.palette.blue[400],
  },
  takeoverTabs: {
    background: `linear-gradient(to bottom right, ${theme.palette.gradient.white.start}, ${theme.palette.gradient.white.end})`,
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    position: 'relative',
  },
  whiteIndicator: {
    backgroundColor: theme.palette.white,
  },
}));

const tabItems = [
  {
    key: 'screenSaver',
    title: 'ScreenSaver',
  },
  {
    key: 'main',
    title: 'Main View',
  },
  {
    key: 'cta',
    title: 'App CTA',
  },
];

function TemplateHeader({
                          form,
                          currentTab,
                          handleCurrentTabChange,
                          networkId,
                          onBack,
                        }) {
  const classes = useStyles();
  const backUrl = `/network/${networkId}/kiosks`;

  return (
    <Box className={classes.root}>
      <Box className={classes.headerInner}>
        <TakeoverHeader
          subtitle="Kiosk Templates"
          expanded={false}
          backUrl={backUrl}
          onBack={onBack}
        />
        <Grid
          className={classes.takeoverTabs}
          sx={{ color: ColorPalette.white }}
        >
          <Tabs
            value={currentTab}
            onChange={(e, val) => handleCurrentTabChange(val, form)}
            aria-label="simple tabs"
            indicatorColor="primary"
            textColor={'primary'}
            centered
            sx={{ height: '70px', '& button': { height: '70px' } }}
          >
            {tabItems.map((tab, idx) => (
              <Tab
                key={tab.key}
                sx={{ padding: '1px 0' }}
                label={tab.title}
                {...tabA11yProps('template', idx)}
              />
            ))}
          </Tabs>
        </Grid>
      </Box>
    </Box>
  );
}

//{}
TemplateHeader.propTypes = {
  form: PropTypes.object,
  handleCurrentTabChange: PropTypes.func,
  networkId: PropTypes.string,
  currentTab: PropTypes.number,
  onBack: PropTypes.func,
};

TemplateHeader.defaultProps = {};

export default React.memo(TemplateHeader);
