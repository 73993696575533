import { Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import NetworkLogo from 'components/networkLogo';
import { FlamingoContext } from 'contexts/flamingo';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  root: { width: 216 },
  whiteColor: { color: theme.palette.primary.contrastText },
  menuButton: {
    padding: '0 12px 0 0',
  },
  arrowButton: {
    padding: 0,
  },
  lineClamp: {
    display: '-webkit-box',
    overflow: 'hidden',
    width: 125,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  truncate: {
    width: 125,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function CustomerMenu({ customerName, showLogo, showMenu }) {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const { activeNetwork, layout, networks } = useContext(FlamingoContext);
  const { openDrawer, openNetwork, setOpenDrawer, setOpenNetwork } = layout;

  const lightMode = openDrawer && !openNetwork;

  const handleDrawerToggle = () => {
    if (openNetwork) {
      setOpenNetwork(false);
      setOpenDrawer(true);
    } else {
      setOpenDrawer(!openDrawer);
    }
  };

  const handleToggleNetworks = () => {
    closeSnackbar(); // closes all toast notifications on the screen
    setOpenNetwork(!openNetwork);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={clsx(classes.root, lightMode && classes.whiteColor)}
    >
      {showMenu && (
        <IconButton
          onClick={handleDrawerToggle}
          aria-label="open drawer"
          className={classes.menuButton}
          size="large"
          sx={{ color: 'inherit' }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Grid
        container
        item
        xs
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
      >
        {showLogo && (
          <NetworkLogo
            networkName={activeNetwork?.humanName}
            logoUrl={activeNetwork?.logo?.url}
            size={20}
            lightMode={lightMode}
          />
        )}
        <Typography
          variant="h3"
          className={classes.truncate}
          color={lightMode ? 'inherit' : 'textSecondary'}
        >
          {customerName}
        </Typography>
      </Grid>
      {showMenu && networks.length > 1 && (
        <IconButton
          aria-label="open drawer"
          data-test="CustomerMenu-button-open"
          onClick={handleToggleNetworks}
          className={classes.arrowButton}
          size="large"
          sx={{ color: 'inherit' }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      )}
    </Grid>
  );
}

CustomerMenu.propTypes = {
  customerName: PropTypes.string,
  showLogo: PropTypes.bool,
  showMenu: PropTypes.bool,
};

CustomerMenu.defaultProps = {
  showLogo: true,
  showMenu: true,
};

export default CustomerMenu;
