import { gql } from '@apollo/client';
import { produce } from 'immer';
import _ from 'lodash';

import { fetchApollo } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import { fromi18nArrayEntries, fromi18nArrayEntriesErr, toError } from 'helpers/transformer-util';

export const TYPES = typeUtil(['READ_CATEGORIES']);

const initialState = {
	page: { docs: [] },
};

export const fromCategoryRemote = (category) => ({
	...category,
	name: fromi18nArrayEntries(category.name),
	tags: !category.tags
		? []
		: category.tags.map((tag) => ({
				...tag,
				name: fromi18nArrayEntries(tag.name),
			})),
	sites: !category.places?.edges
		? []
		: category.places.edges
				.filter(({ node }) => node.designation === 'site')
				.map(({ node }) => ({
					...node,
					name: fromi18nArrayEntries(node.name),
				})),
	buildings: !category.places?.edges
		? []
		: category.places.edges
				.filter(({ node }) => node.designation === 'building')
				.map(({ node }) => ({
					...node,
					name: fromi18nArrayEntries(node.name),
				})),
	pois: !category.places?.edges
		? []
		: category.places.edges
				.filter(({ node }) => node.designation === 'poi')
				.map(({ node }) => ({
					...node,
					name: fromi18nArrayEntries(node.name),
				})),
	childrenCount: category.children?.count || 0,
	children: !category.children?.edges
		? []
		: category.children.edges.map(({ node }) => fromCategoryRemote(node)),
	parent: !category.parent ? null : fromCategoryRemote(category.parent),
});

export const fromCategoryRemoteErr = (errObject, remoteObject) => {
	const result = {};
	if (errObject.name) {
		result.name = fromi18nArrayEntriesErr(errObject.name, remoteObject.name);
	}

	if (Array.isArray(errObject.tags)) {
		result.tags = errObject.tags.map((tag, index) => {
			const remotetag = remoteObject.tags[index];
			return {
				...tag,
				name: fromi18nArrayEntriesErr(tag.name, remotetag.name),
			};
		});
	} else {
		result.tags = errObject.tags;
	}
	return result;
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TYPES.READ_CATEGORIES:
			return produce(state, (draft) => {
				draft.page.status = 'loading';
			});

		case TYPES.READ_CATEGORIES_SUCCESS:
			return {
				...state,
				page: {
					...action.params,
					docs: action.result.map((category) => fromCategoryRemote(category)),
					status: 'responded',
				},
			};

		case TYPES.READ_CATEGORIES_ERROR:
			return produce(state, (draft) => {
				draft.flash = {
					error: true,
					status: 'error',
					...toError(action.result, (errObject) => fromCategoryRemoteErr(errObject, action.remoteObject),
					),
				};
				if (action.params.customError) {
					const errorCode = _.get(action, 'result.errors[0].statusCode', 'default');
					draft.flash.message = action.params.customError[errorCode];
				}
			});

		default:
			return state;
	}
};

export const getCategories
	= (params, includeLocations = false) => (dispatch) => {
		let locFields = '';
		if (includeLocations) {
			locFields = `
		places {
			count
			edges {
				node {
					id
					name
					designation
					parentSite {
						id
					}
				}
			}
		}
		`;
		}
		return fetchApollo({
			doc: gql`
		{
			findPrimaryCategories {
				id
				name
				tags {
					id
					name
					weight
				}
				children {
					count
					edges {
						node {
							id
							name
							tags {
								id
								name
								weight
							}
							${locFields}
						}
					}
				}
				${locFields}
			}
		}
		`,
			method: 'query',
			queryName: 'findPrimaryCategories',
			type: TYPES.READ_CATEGORIES,
			params,
			dispatch,
		});
	};
