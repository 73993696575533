import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const GozioInfoTooltip = ({
                            arrow,
                            iconColor,
                            placement,
                            sx,
                            title,
                            tooltipColor,
                            tooltipBackgroundColor,
                            tooltipFontFamily,
                            tooltipFontSize,
                          }) => (
  <Box
    sx={{
      display: 'inline-block',
      ...sx,
    }}
  >
    <Tooltip
      arrow={arrow}
      placement={placement}
      sx={{
        '& .MuiTooltip-arrow': {
          color: tooltipBackgroundColor,
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: tooltipBackgroundColor,
          color: tooltipColor,
          fontFamily: tooltipFontFamily,
          fontSize: tooltipFontSize,
        },
      }}
      title={title}
    >
      <InfoOutlined
        sx={{
          color: iconColor,
          fontSize: '1.25rem',
        }}
      />
    </Tooltip>
  </Box>
);

GozioInfoTooltip.propTypes = {
  arrow: PropTypes.bool,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipColor: PropTypes.string,
  tooltipBackgroundColor: PropTypes.string,
  tooltipFontFamily: PropTypes.string,
  tooltipFontSize: PropTypes.number,
};

// Modeled after the UserPilot CSS, plus UX input
GozioInfoTooltip.defaultProps = {
  arrow: true,
  className: '',
  iconColor: '#0D6AFF',
  placement: 'top-start',
  sx: {},
  tooltipBackgroundColor: '#262B2E', // rgb(243, 243, 243),
  tooltipColor: '#FFFFFF', // rgb(112, 115, 117),
  tooltipFontFamily: 'Roboto',
  tooltipFontSize: 12,
};

export default React.memo(GozioInfoTooltip);
