import { Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LanguageBasedInput from 'components/_deprecated/languageBasedInput';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import { colorWithAlpha } from 'helpers/color-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
    boxShadow: `0px 8px 16px 0px ${colorWithAlpha(theme.palette.black, 0.25)}`,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  cardContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: '16px !important',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardHeader: {
    paddingBottom: theme.spacing(2),
  },
  saveButton: {
    marginLeft: '16px',
  },
  checkboxContainer: {
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    color: theme.palette.grey[400],
  },
}));

const languageNameLabelMap = {
  en: 'Name',
  es: 'Name (Spanish)',
};

const AddOrEditPersonaCard = ({
                                languages,
                                persona = {},
                                checkboxLabel,
                                minNameLength,
                                maxNameLength,
                                handleCancel,
                                handleSavePersona,
                                onUploadIconFailed,
                                onUploadIconStarted,
                              }) => {
  let inputNameMap = {};
  if (persona.name && persona.name.length) {
    inputNameMap = {};
    persona.name.forEach((langLabel) => {
      inputNameMap[langLabel.lang] = langLabel.label;
    });
  }
  const [dirty, setDirty] = useState(false);
  const [invalid, setInvalid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [nameMap, setNameMap] = useState(inputNameMap);

  const [
    isApplyPersonaToAllLocationsAndLists,
    setIsApplyPersonaToAllLocationsAndLists,
  ] = useState(!!persona.isApplyPersonaToAllLocationsAndLists);
  const [uploadedIconUrl, setUploadedIconUrl] = useState(
    persona.icon && persona.icon.url,
  );
  const [uploadedIconFile, setUploadedIconFile] = useState(null);

  const classes = useStyles();

  const handleApplyPersonaToAllLocationsAndLists = (e) => {
    setIsApplyPersonaToAllLocationsAndLists(e.target.checked);
  };

  const validateData = (
    incomingNameMap = nameMap,
    incomingUploadedIconUrl = uploadedIconUrl,
  ) => {
    if (!incomingUploadedIconUrl) {
      return;
    }

    const keys = Object.keys(incomingNameMap);
    let valid = true;
    for (let i = 0; i < keys.length; i++) {
      const val = incomingNameMap[keys[i]];
      if (val.length < minNameLength || val.length > maxNameLength) {
        valid = false;
        break;
      }
    }

    setInvalid(!valid);
  };

  const handleNameChange = (lang, value) => {
    setDirty(true);
    setNameMap({ ...nameMap, [lang]: value });
    validateData(Object.assign(nameMap, { [lang]: value }), uploadedIconUrl);
  };

  const handleSave = () => {
    if (invalid) {
      return;
    }

    setSaving(true);

    handleSavePersona({
      personaId: persona && persona.id,
      isApplyPersonaToAllLocationsAndLists,
      nameMap,
      isDefault: (persona && persona.isDefault) || false,
      publishable: (persona && persona.publishable) || false,
      iconImageId:
        persona && persona.icon && persona.icon.image && persona.icon.image.id,
      iconUrl: uploadedIconUrl,
      iconFile: uploadedIconFile,
    });
  };

  const isExistingPersona = inputNameMap && inputNameMap.en;
  const title = isExistingPersona ? 'Edit persona' : 'Add new persona';

  return (
    <Grid container item xs={12}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContainer}>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.cardHeader}
            >
              <Grid item xs={6}>
                <Typography variant="h2">{title}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={saving}
                  data-test="cancel-button"
                  onClick={handleCancel}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || invalid || saving}
                  className={classes.saveButton}
                  data-test="save-button"
                  onClick={handleSave}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!isExistingPersona && (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isApplyPersonaToAllLocationsAndLists}
                        className={classes.checkbox}
                        color="primary"
                        onChange={handleApplyPersonaToAllLocationsAndLists}
                      />
                    }
                    label={checkboxLabel}
                    className={classes.checkboxContainer}
                  />
                </FormGroup>
              )}
              <LanguageBasedInput
                options={[
                  {
                    lang: LANGUAGE_CODES.ENGLISH,
                    label: languageNameLabelMap.en,
                    customTags: {
                      'data-test': 'PersonaCardNameEnglish',
                    },
                    charLimit: maxNameLength,
                  },
                  {
                    lang: LANGUAGE_CODES.SPANISH,
                    label: languageNameLabelMap.es,
                    customTags: {
                      'data-test': 'PersonaCardNameSpanish',
                    },
                    charLimit: maxNameLength,
                  },
                ]}
                filter={languages}
                values={{
                  en: nameMap.en,
                  es: nameMap.es,
                }}
                onChange={{
                  en: (value) => handleNameChange(LANGUAGE_CODES.ENGLISH, value),
                  es: (value) => handleNameChange(LANGUAGE_CODES.SPANISH, value),
                }}
              />

              <ImageUpLoader
                title="Icon"
                image={uploadedIconUrl ? { url: uploadedIconUrl } : null}
                fileInfo="Icons must be at least 120x120 and less than 50KB"
                onImageUpdated={({ file, url }) => {
                  setDirty(true);
                  setUploadedIconUrl(url);
                  setUploadedIconFile(file);
                  validateData(nameMap, url);
                }}
                maxFileSize={50000}
                minHeight={120}
                minWidth={120}
                activeDragPadding={12}
                allowDelete={false}
                showImage={true}
                previewWidth={64}
                previewHeight={64}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

AddOrEditPersonaCard.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  persona: PropTypes.shape({
    isApplyPersonaToAllLocationsAndLists: PropTypes.bool,
    name: PropTypes.arrayOf(
      PropTypes.shape({
        lang: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    icon: PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
  minNameLength: PropTypes.number,
  maxNameLength: PropTypes.number,
  handleCancel: PropTypes.func.isRequired,
  handleSavePersona: PropTypes.func.isRequired,
  onUploadIconStarted: PropTypes.func,
  onUploadIconFailed: PropTypes.func,
};

AddOrEditPersonaCard.defaultProps = {
  persona: {},
  checkboxLabel: 'Apply persona to all locations and lists',
  minNameLength: 1,
  maxNameLength: 40,
};

export default AddOrEditPersonaCard;
