import { Box } from '@mui/material';
import FormSubSection from 'components/forms/formSubSection';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import InfoPanel from 'components/infoPanel/infoPanel';
import { MINIMUM_IMAGE_HEIGHT, MINIMUM_IMAGE_WIDTH } from 'helpers/media-util';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

const ArrivalImageSection = ({
                               image: arrivalImage,
                               hasDefaultImage,
                               hasPlaceholderImage,
                               onImageUpdated,
                               onImageDeleted,
                             }) => {
  useField('arrivalImage');

  const infoPanel = useMemo(() => {
    if (arrivalImage) {
      return (
        <InfoPanel title="The image below will be used as the Arrival Image." />
      );
    }

    if (hasDefaultImage) {
      return (
        <InfoPanel
          title="The Default Image (seen above) is in use. To customize the Arrival Image, assign an image below." />
      );
    }

    if (hasPlaceholderImage) {
      return (
        <InfoPanel title="If the Arrival Image is not assigned, the Placeholder Image will be used." />
      );
    }

    return (
      <InfoPanel title="No image has been set as a Placeholder or Default Image." />
    );
  }, [arrivalImage, hasDefaultImage, hasPlaceholderImage]);

  return (
    <FormSubSection name="Arrival Image">
      <Box sx={{ marginTop: '-8px', marginBottom: '20px' }}>{infoPanel}</Box>
      <ImageUpLoader
        title="Customize Arrival Image"
        fileInfo={`Supported files include jpeg and png. The recommended file size is between 1MB and 3MBs. File sizes should be no larger than 3MBs. Image dimensions must be at least ${MINIMUM_IMAGE_WIDTH}px by ${MINIMUM_IMAGE_HEIGHT}px.`}
        image={arrivalImage}
        onImageUpdated={onImageUpdated}
        onImageDeleted={onImageDeleted}
        showImage={true}
        maxFileSize={3000000}
        minWidth={MINIMUM_IMAGE_WIDTH}
        minHeight={MINIMUM_IMAGE_HEIGHT}
      />
    </FormSubSection>
  );
};

ArrivalImageSection.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  hasDefaultImage: PropTypes.bool,
  hasPlaceholderImage: PropTypes.bool,
  onImageUpdated: PropTypes.func.isRequired,
  onImageDeleted: PropTypes.func.isRequired,
};

ArrivalImageSection.defaultProps = {
  image: null,
  hasDefaultImage: false,
  hasPlaceholderImage: false,
};

export default ArrivalImageSection;
