import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Field } from 'react-final-form';
import { TextField } from 'mui-rff';
import Asterisk from 'components/asterisk/asterisk';

const NetworkModalContent = ({ edit }) => {
  const checkBoxes = useMemo(() => [
    'Mobile Solution',
    'Kiosk Solution',
    'SDK',
  ].map((label) => (
    <Grid item key={label}>
      <Field name={`${label.split(' ')[0].toLowerCase()}Enabled`}>
        {({ input, meta }) => (
            <Grid item sx={{ marginRight: '32px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    sx={{ padding: '4px 4px 4px 8px' }}
                    checked={input.value}
                    onChange={() => {
                      input.onChange(!input.value);
                    }}
                  />
                }
                label={label}
              />
            </Grid>
          )
        }
      </Field>
    </Grid>
  )), []);
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          helperText={<span>Use uppercase and lowercase letters and spaces only.</span>}
          label={<><Asterisk />Network Name</>}
          name="name"
          variant="outlined"
          fullWidth
          disabled={edit}
        />
      </Grid>
      <Grid item sx={{ marginTop: '20px' }}>
        <TextField
          helperText={<span>Use uppercase and lowercase letters only.</span>}
          label={<><Asterisk />Text Identifier</>}
          name="textIdentifier"
          variant="outlined"
          fullWidth
          disabled={edit}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{ marginTop: '12px', textAlign: 'left' }}
        >
          <Asterisk /> Network Enablement
        </Typography>
        <Grid container sx={{ marginLeft: '4px' }}>
          {checkBoxes}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(NetworkModalContent);
