const base = {
	defaultNetworkId: 'Global Template',
	featureFlag: [],
};

let _result = null;
export const getConfig = () => {
	if (_result) {
		return _result;
	}
	if (window.flamingo_config) {
		_result = {
			...base,
			...window.flamingo_config,
		};
		// console.info('[config]: ', _result);
		return _result;
	}
	return base;
};
