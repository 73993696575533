import { getConfig } from 'helpers/config-util';
import { parseDomain, ParseResultType } from 'helpers/domainParser';
import LogRocket from 'logrocket';

/**
 * An SSO domain for Portal has four levels. For example, `https://gozio-saml.portal.goziohealth.com/` has 4 parts:
 *  - .com is the top level domain (TLD)
 *  - goziohealth is the second level domain (2LD)
 *  - portal is the third level domain (3LD)
 *  - gozio-saml is the fourth level domain (4LD)
 *
 *  When a user goes to Portal with a four-level domain, Portal will map the 4LD to its SSO connection string defined in `SSO_CONNECTION` in `config.js`. If it is defined, we assume that connection string is configured in Auth0 and will redirect the user to Auth0 with that connection string.
 *
 *  When Auth0 receives this login attempt with the SSO connection string, it will reroute the user to the configured SSO login page.
 *
 * @type {Map<String, String>} the mapping of the fourth level domain (4LD) to its SSO connection type.
 */
export const SSO_CONNECTION = new Map(getConfig().SSO_CONNECTION || []);

const useSSOConnection = () => {
  let connection = null;
  const parseResult = parseDomain(window.location.hostname);

  switch (parseResult.type) {
    case ParseResultType.Listed: {
      const { subDomains } = parseResult;

      const result = subDomains.filter((sub) => SSO_CONNECTION.has(sub));
      if (result.length === 1) {
        connection = SSO_CONNECTION.get(result[0]);
        LogRocket.info('[parseDomain] connection: ', connection);
      }
      break;
    }
    case ParseResultType.Reserved:
    case ParseResultType.NotListed: {
      const { hostname } = parseResult;

      LogRocket.info(
        `[parseDomain]: ${hostname} is a reserved or unknown domain`,
      );
      break;
    }
    default:
      const { hostname } = parseResult;

      LogRocket.info(
        `[parseDomain]: ${hostname} is an ip address or invalid domain`,
      );
      break;
  }

  return connection;
};

export default useSSOConnection;
