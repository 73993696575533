import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.blue[500],
  },
  cardGrid: {
    background: theme.palette.white,
    boxShadow: `0px 14px 24px 0px ${colorWithAlpha(theme.palette.black, 0.15)}`,
    borderRadius: '8px',
    width: '452px',
    height: '362px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    textAlign: 'center',
  },
  titleText: {
    padding: `${theme.spacing(1)} ${theme.spacing(9)} !important`,
  },
  bodyText: {
    padding: `${theme.spacing(1)} ${theme.spacing(7)} !important`,
  },
});

const UnauthorizedPage = ({ loginFunc, contactMail }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Grid
      container
      sx={styles.content}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={styles.cardGrid}
      >
        <Grid item sx={styles.titleText}>
          <Typography variant="h1" color="textSecondary">
            Your Credentials Are Not Valid
          </Typography>
        </Grid>
        <Grid item sx={styles.bodyText}>
          <Typography variant="body1">
            Your account is no longer active in the system. Contact your admin
            for further details.
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent={loginFunc ? 'space-between' : 'center'}
        >
          {!!loginFunc && (
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={loginFunc}
            >
              Return to Login
            </Button>
          )}
          <Button
            component="a"
            href={`mailto:${contactMail}`}
            target="_blank"
            size="large"
            variant="contained"
            color="primary"
          >
            CONTACT ADMIN
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

UnauthorizedPage.propTypes = {
  loginFunc: PropTypes.func,
  contactMail: PropTypes.string,
};

UnauthorizedPage.defaultProps = {
  contactMail: 'support@goziohealth.com',
};

export default UnauthorizedPage;
