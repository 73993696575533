import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AreaChart from 'components/analyticsDashboardCard/charts/areaChart';
import BarChart from 'components/analyticsDashboardCard/charts/barChart';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import { DATE_FORMAT, retrieveAnalyticsFilter } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize } from 'helpers/lang-util';
import { tabA11yProps } from 'helpers/page-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useAnalytics } from 'hooks/dataHooks/useAnalytics';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AnalyticsFilters, { ANALYTICS_FILTER_MAP } from './containers/analyticsFilters';
import AnalyticsDownloadButton from './containers/downloadButton';

const buildStyles = ({ theme }) => ({
  handleBack: {
    marginTop: '-16px !important',
  },
  loading: {
    height: '100%',
  },
  content: {
    borderRadius: '20px',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabBody: {
    width: '100%',
    height: '548.5px',
    padding: '24px 24px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRange: {
    marginTop: '2px',
  },
  count: {
    fontSize: '34px',
    marginTop: '10px',
  },
  chartContainer: {
    marginTop: '10.5px',
  },
  customTooltip: {
    background: theme.palette.grey[600],
    borderRadius: '4px',
    padding: '12px 12px',
    maxWidth: '250px',

    '& p': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: 0,
    },
  },
  tooltipContainer: {
    '& span': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'bold',
      margin: '8px 0 0 0',
    },
  },
  tooltipHeading: {
    color: theme.palette.white,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '8px 0 0 0',
    width: '64px',
  },
  xAxisLabel: {
    color: theme.palette.grey[400],
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    marginLeft: '71px',
    marginTop: '1px',
    textAlign: 'center',
  },
});

export const SessionsPage = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const { filter, rangeStart, rangeEnd } = retrieveAnalyticsFilter();
  const [currentTab, setCurrentTab] = useState(0);
  const [dateRangeKey, setDateRangeKey] = useState(null);
  const [dateRangeText, setDateRangeText] = useState(null);

  const backToOverview = useCallback(() => {
    handleNavigate(`/network/${networkId}/analytics`);
  }, [handleNavigate, networkId]);

  const { data, fetch } = useAnalytics({
    networkId,
    endpoint: 'sessions',
  });

  const userRole = useCheckRole();
  const hasPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(userRole);

  const CustomTooltip = ({ active, payload, type }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload ?? {};
      return (
        <Box sx={styles.customTooltip}>
          <p>Session {capitalize(type)}</p>
          {type === 'length' && (
            <Box sx={styles.tooltipContainer}>
              <Box sx={styles.tooltipHeading}>Minutes</Box>
              <span>{data.lengthDisplay}</span>
            </Box>
          )}
          <Box sx={styles.tooltipContainer}>
            <Box sx={styles.tooltipHeading}>
              {type !== 'frequency' ? 'Sessions' : 'Users'}
            </Box>
            <span>{data.sessions?.toLocaleString() ?? 'N/A'}</span>
          </Box>
          {type !== 'frequency' && (
            <Box sx={styles.tooltipContainer}>
              <Box sx={styles.tooltipHeading}>Date</Box>
              <span>{data.fullDate}</span>
            </Box>
          )}
        </Box>
      );
    }

    return null;
  };

  const sessionsChartData = useMemo(
    () => data?.by_day?.map(({ date, total_sessions }) => ({
        sessions: total_sessions,
        date: dayjs(date).format('MMM D'),
        fullDate: dayjs(date).format('MMM D, YYYY'),
      })),
    [data],
  );

  const frequencyBarData = useMemo(
    () => data?.frequency
        ? ['0-1', '2-3', '4-8', '9+'].map((key) => ({
          name: key,
          sessions: data.frequency[key]?.users || 0,
          percentage: data.frequency[key]?.percentage || 0,
        }))
        : null,
    [data],
  );

  const lengthChartData = useMemo(
    () => data?.by_day?.map(
        ({
           average_session_length_display,
           average_session_length_minutes,
           date,
           total_sessions,
         }) => ({
          sessions: total_sessions,
          length: average_session_length_minutes,
          lengthDisplay: average_session_length_display,
          date: dayjs(date).format('MMM D'),
          fullDate: dayjs(date).format('MMM D, YYYY'),
        }),
      ),
    [data],
  );

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName={
          <Box>
            Sessions
            <Box
              sx={{
                display: 'inline-block',
                marginLeft: '8px',
                verticalAlign: 'middle',
              }}
            >
              <InfoTooltip
                title="“Sessions” is a count of how many times all Users open and use the app in the date range shown. Any app activity separated by at least ten minutes is considered a new Session." />
            </Box>
          </Box>
        }
        handleBack={() => backToOverview()}
        handleBackSx={styles.handleBack}
        handleBackTitle="Back to Analytics Overview"
        headerButtons={
          <AnalyticsFilters
            value={filter}
            start={rangeStart}
            end={rangeEnd}
            onChange={async ({ start, end, value, dateRangeText }) => {
              if (dateRangeKey !== value || value === 'custom') {
                const diffDays = dayjs(end).diff(dayjs(start), 'day');
                setDateRangeKey(dateRangeText ?? value);
                if (value === 'custom') {
                  setDateRangeText(
                    `${dayjs(start).format(DATE_FORMAT)} - ${dayjs(end).format(
                      DATE_FORMAT,
                    )}`,
                  );
                } else {
                  setDateRangeText(null);
                }
                await fetch({
                  start,
                  end,
                  points: diffDays < 14 ? diffDays : 14,
                });
              }
            }}
          />
        }
      >
        <Box sx={styles.content}>
          <Grid sx={styles.takeoverTabs}>
            <Tabs
              value={currentTab}
              onChange={(e, idx) => setCurrentTab(idx)}
              aria-label="simple tabs"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Quantity" {...tabA11yProps('analytics', 0)} />
              <Tab label="Frequency" {...tabA11yProps('analytics', 1)} />
              <Tab label="Length" {...tabA11yProps('analytics', 2)} />
            </Tabs>
          </Grid>
          <TabPanel
            value={currentTab}
            index={0}
            sx={{
              ...styles.tabPanel,
              ...currentTab === 0 && { height: 'calc(100% - 69px)' },
            }}
          >
            <Box sx={styles.tabBody}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">Sessions Quantity</Typography>
                <AnalyticsDownloadButton
                  endpoint="sessions"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!sessionsChartData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {sessionsChartData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  <Grid item>
                    <Typography variant="h3" sx={styles.count}>
                      {data?.cumulative_total_sessions?.toLocaleString()
                        ?? 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={styles.dateRange}>
                      {dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={styles.chartContainer}>
                    <AreaChart
                      data={sessionsChartData}
                      dataKey="sessions"
                      xAxisKey="date"
                      yAxisLabel="Number of Sessions"
                      yAxisKey="sessions"
                      width={width - 374}
                      height={370}
                      maxXAxisLabels={14}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip type="quantity" />}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Date
                  </Grid>
                </Grid>
              )}
            </Box>
          </TabPanel>
          <TabPanel
            value={currentTab}
            index={1}
            sx={{
              ...styles.tabPanel,
              ...currentTab === 1 && { height: 'calc(100% - 69px)' },
            }}
          >
            <Box sx={styles.tabBody}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">Session Frequency</Typography>
                <AnalyticsDownloadButton
                  endpoint="sessions"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!frequencyBarData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {frequencyBarData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  {frequencyBarData.map(({ name, percentage }, idx) => (
                    <Grid key={`fr-${idx}`} item sx={{ marginRight: '40px' }}>
                      <Typography variant="h3" sx={styles.count}>
                        {percentage?.toFixed(2)}%
                      </Typography>
                      <Typography variant="body2" sx={styles.dateRange}>
                        {name} Sessions
                      </Typography>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={styles.chartContainer}>
                    <BarChart
                      data={frequencyBarData}
                      dataKey="sessions"
                      xAxisKey="name"
                      yAxisLabel="Number of Users"
                      yAxisKey="sessions"
                      width={width - 408}
                      height={370}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip type="frequency" />}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Number of Sessions
                  </Grid>
                </Grid>
              )}
            </Box>
          </TabPanel>
          <TabPanel
            value={currentTab}
            index={2}
            sx={{
              ...styles.tabPanel,
              ...currentTab === 2 && { height: 'calc(100% - 69px)' },
            }}
          >
            <Box sx={styles.tabBody}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">
                  Average Session Length
                </Typography>
                <AnalyticsDownloadButton
                  endpoint="sessions"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!lengthChartData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {lengthChartData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  <Grid item>
                    <Typography variant="h3" sx={styles.count}>
                      {data?.average_session_length_display ?? 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={styles.dateRange}>
                      {dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={styles.chartContainer}>
                    <AreaChart
                      data={lengthChartData}
                      dataKey="length"
                      xAxisKey="date"
                      yAxisLabel="Time in Minutes"
                      yAxisKey="length"
                      width={width - 374}
                      height={370}
                      maxXAxisLabels={14}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip type="length" />}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Date
                  </Grid>
                </Grid>
              )}
            </Box>
          </TabPanel>
        </Box>
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(SessionsPage);
