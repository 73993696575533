import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NETWORK_PLACE_DESCENDANTS } from 'graphql/queries';

const useNetworkPlaceDescendants = ({ placeId, designation, isMapped }) => {
  const [list, setList] = useState([]);
  const { data, error, loading } = useQuery(GET_NETWORK_PLACE_DESCENDANTS, {
    variables: { id: placeId, where: { designation, isMapped } },
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      setList(
        data?.getNetworkPlace?.pois?.edges?.map((child) => child.node) || [],
      );
    }
  }, [data]);

  return {
    error,
    loading,
    data: list,
  };
};

export default useNetworkPlaceDescendants;
