import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import React, { useMemo } from 'react';

const getColumns = (networkId, navigate) => [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Parent ID',
    accessor: 'parentId',
    alwaysHidden: true,
  },
  {
    Header: 'Category Name',
    accessor: 'name',
    minWidth: 350,
    width: 350,
    onClick: (data) => {
      navigate(`/network/${networkId}/categories/${data.id}`);
    },
    sticky: 'left',
  },
  {
    Header: '# of Locations',
    accessor: 'locCount',
    minWidth: 150,
    width: 150,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    minWidth: 400,
    width: 400,
  },
  {
    Header: 'Category Group',
    accessor: 'group',
    minWidth: 350,
    width: 350,
    onClick: (data) => {
      navigate(`/network/${networkId}/categories/group/${data.parentId}`);
    },
  },
];

const getTags = (tagsArray) => {
  if (!tagsArray || tagsArray.length === 0) return '';
  return tagsArray.map((t) => capitalize(getLabelByLang(t.name))).join(', ');
};
const CategoryListing = ({
                           categories,
                           loading,
                           navigate,
                           networkId,
                           search,
                           isInDetail = false,
                         }) => {
  const processCats = () => categories.map((c) => ({
      id: c.id,
      parentId: c.parent?.id,
      name: getLabelByLang(c.name),
      searchIdx: getLabelByLang(c.name).toLowerCase(),
      group: getLabelByLang(c.parent?.name),
      locCount: c.places?.count || 0,
      tags: getTags(c.tags),
    }));

  const memoizedData = useMemo(
    () => processCats().filter(
        (item) => !search || item.searchIdx.indexOf(search) >= 0,
      ),
    [search, categories], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const memoizedColumns = useMemo(() => {
    const columns = getColumns(networkId, navigate);
    if (!isInDetail) {
      columns.splice(3, 2);
    } else {
      columns.splice(5, 1);
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkId, navigate]);

  const count = memoizedData?.length || 0;

  return (
    <GozioTable
      name="CategoriesTable"
      columns={memoizedColumns}
      data={memoizedData}
      sortBy={[{ id: 'name', desc: false }]}
      sx={{ paddingTop: '8px' }}
      loading={loading}
      countTitle={getTableCountTitle(count, 'Category', 'Categories')}
      emptyContent={<EmptyTable />}
    />
  );
};

export default CategoryListing;
