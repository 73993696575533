import { Box, CardMedia, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    borderRadius: '14px',
    backgroundColor: colorWithAlpha(theme.palette.grey[50], 0.92),
    width: '270px',
  },
  dialogInner: {
    padding: '18px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: `1px solid ${colorWithAlpha(theme.palette.black, 0.15)}`,
  },
  dialogHeader: {
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.palette.black,
    textAlign: 'center',
    marginBottom: '8px',
  },
  image: {
    marginBottom: '8px',
    width: '250px',
    height: '125px',
    backgroundSize: 'cover',
  },
  dialogMessage: {
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.black,
    textAlign: 'center',
  },
  dialogFooter: {
    display: 'flex',
  },
  dialogButton: {
    flex: 1,
    borderRight: `1px solid ${colorWithAlpha(theme.palette.black, 0.15)}`,
    padding: '10px',
    fontSize: '17px',
    lineHeight: '22px',
    color: theme.palette.blue[400],
    textAlign: 'center',
    fontWeight: 400,
  },
}));

const DialogExample = ({ message, image }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          marginBottom: '16px',
          fontWeight: 400,
          color: ColorPalette.grey[600],
        }}
      >
        Mobile Dialog Preview
      </Typography>
      <Box className={classes.dialogBody}>
        <Box className={classes.dialogInner}>
          <Box className={classes.dialogHeader}>Attention</Box>
          {image.url && (
            <CardMedia className={classes.image} image={image.url} />
          )}
          <Box className={classes.dialogMessage}>
            {message?.substring(0, 300)}
          </Box>
        </Box>
        <Box className={classes.dialogFooter}>
          <Box className={classes.dialogButton}>Cancel</Box>
          <Box
            className={classes.dialogButton}
            sx={{ borderWidth: 0, fontWeight: 400 }}
          >
            Ok
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DialogExample.propTypes = {
  message: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DialogExample.defaultProps = {
  message: 'Custom Message Goes Here.',
};

export default React.memo(DialogExample);
