import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColorOptions from 'components/colorOptions/colorOptions';
import ImageUploader from 'components/imageUploader/imageUploader';
import RadioGroup from 'components/radioGroup/radioGroup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

const buildStyles = ({ theme: { palette, spacing } }) => ({
  field: {
    marginBottom: spacing(4),
  },
  heading: {
    marginBottom: spacing(3),
  },
  radioGroup: {
    paddingTop: 0,
  },
  subheading: {
    color: palette.grey[600],
    marginBottom: spacing(1),
  },
});

const FillOptions = ({
                       backgroundColor,
                       textColor,
                       backgroundColorAlphaRequired,
                       backgroundTypeOptions,
                       textColorAlphaRequired,
                       label,
                       disableLabel,
                       onBackgroundColorUpdated,
                       onImageUpdated,
                       onRadioChange,
                       initialOption,
                       disableBackgroundColor,
                       disableTextColor,
                       textColorFieldName,
                       backgroundColorFieldName,
                       backgroundImageFieldName,
                       imageUploaderProps,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [bgOption, setBgOption] = useState(initialOption);

  useEffect(() => {
    setBgOption(initialOption);
  }, [initialOption]);

  return (
    <Grid container direction="column">
      {!disableLabel && (
        <Grid item xs={12} sx={styles.heading}>
          <Typography variant="subtitle1">{label}</Typography>
        </Grid>
      )}
      {!disableTextColor && (
        <>
          <Grid item xs={12} sx={styles.field}>
            <Field name={textColorFieldName || 'textColor'}>
              {({ input: { onChange } }) => (
                <ColorOptions
                  alpha={textColorAlphaRequired ? 'required' : 'none'}
                  initialColor={textColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Text color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={styles.subheading}>
              Background Options
            </Typography>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={{ ...styles.radioGroup, ...bgOption === 'color' && styles.field }}
      >
        <Field name="backgroundType">
          {({ input }) => (
            <RadioGroup
              onChange={(newValue) => {
                if (onRadioChange) onRadioChange(newValue, input);
                setBgOption(newValue);
              }}
              alignment="horizontal"
              value={bgOption}
              options={backgroundTypeOptions}
            />
          )}
        </Field>
      </Grid>
      {bgOption === 'color' && !disableBackgroundColor && (
        <Field name={backgroundColorFieldName || 'backgroundColor'}>
          {({ input }) => (
            <Grid item xs={12} sx={styles.field}>
              <ColorOptions
                alpha={backgroundColorAlphaRequired ? 'required' : 'none'}
                initialColor={backgroundColor}
                formControl={true}
                getColor={(color) => {
                  input.onChange(color);
                  if (onBackgroundColorUpdated) onBackgroundColorUpdated(color);
                }}
                label="Background color"
                required={true}
                inputProps={{
                  'data-test': 'editFillBackgroundColor',
                }}
              />
            </Grid>
          )}
        </Field>
      )}
      {bgOption === 'image' && (
        <Field name={backgroundImageFieldName || 'backgroundImageUrl'}>
          {({ input }) => (
            <Grid item xs={12} sx={styles.field}>
              <ImageUploader
                fileInfo="Image must be at least 1080x312 and less than 1 megabytes"
                image={input.value ? input.value : null}
                minWidth={1080}
                minHeight={312}
                maxFileSize={1000000}
                onImageUpdated={(imageData) => {
                  input.onChange(imageData.url);
                  if (onImageUpdated) onImageUpdated(imageData, input);
                }}
                activeDragPadding={12}
                allowDelete={false}
                display="stacked"
                showImage={true}
                showImageName={false}
                {...imageUploaderProps}
              />
            </Grid>
          )}
        </Field>
      )}
    </Grid>
  );
};

FillOptions.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  disableLabel: PropTypes.bool,
  textColor: PropTypes.string,
  backgroundColorAlphaRequired: PropTypes.bool,
  backgroundTypeOptions: PropTypes.array,
  textColorAlphaRequired: PropTypes.bool,
  onBackgroundColorUpdated: PropTypes.func,
  onImageUpdated: PropTypes.func,
  onRadioChange: PropTypes.func,
  initialOption: PropTypes.oneOf(['image', 'color']),
  disableBackgroundColor: PropTypes.bool,
  disableTextColor: PropTypes.bool,
  textColorFieldName: PropTypes.string,
  backgroundColorFieldName: PropTypes.string,
  backgroundImageFieldName: PropTypes.string,
  imageUploaderProps: PropTypes.object,
};

FillOptions.defaultProps = {
  disableLabel: false,
  label: 'Card Fill Options',
  textColorFieldName: 'textColor',
  backgroundColorFieldName: 'backgroundColor',
  backgroundImageFieldName: 'backgroundImageUrl',
  backgroundTypeOptions: [
    {
      label: 'Use Color As Background',
      value: 'color',
      description: '',
    },
    {
      label: 'Use Image As Background',
      value: 'image',
      description: '',
    },
  ],
  initialOption: 'color',
};

export default FillOptions;
