import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import InfoPanel from 'components/infoPanel/infoPanel';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkPlace, useSites } from 'hooks/dataHooks/useSites';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { makeValidate, Radios, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const addBuildingSchema = Yup.object().shape({
  site: Yup.string().nullable().required('Parent Site is required'),
  buildingName: Yup.string().required('Building name is required'),
  isMapped: Yup.string().required('Mapped status is required'),
});

const validate = makeValidate(addBuildingSchema);

const buildAddress = (site) => {
  if (!site.geoLocation?.address) return 'No address available';
  const { street, suite, city, state, zip } = site.geoLocation.address;
  const address = [];
  if (street) address.push(street);
  if (suite) address.push(`Suite ${suite}`);
  if (city) address.push(city);
  if (state || zip) address.push(`${state || ''} ${zip || ''}`);
  return address.length > 0 ? address.join(', ') : 'No address available';
};

const AddBuildingModal = ({
                            handleClose,
                            handleConfirm,
                            site,
                            networkName,
                            networkLogo,
                          }) => {
  const classes = useStyles();
  const [siteIsMapped, setSiteIsMapped] = useState(site?.isMapped === true);
  const isUberAdmin = useCheckGozioAdmin();
  const [createBuilding] = useCreateNetworkPlace('building');
  const { count, sites } = useSites({
    workspace: isUberAdmin ? '*' : undefined,
  });

  const onSubmit = async (values) => {
    const response = await createBuilding({
      variables: {
        input: {
          name: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.buildingName }],
          inheritAddress: true,
          isMapped: values.isMapped === 'true',
          designation: 'building',
          contact: {
            hours: {
              type: 'none',
            },
          },
        },
        meta: {
          parent: values.site,
        },
      },
    });
    handleConfirm(response?.data?.createNetworkPlace);
  };

  const buildSiteOptions = useCallback(
    () => sites.map((site) => ({
        id: site.id,
        label: `${getLabelByLang(site.name)} (${
          site.isMapped ? 'Mapped' : 'Unmapped'
        })`,
        label2: buildAddress(site),
      })),
    [sites],
  );

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          site: site?.id || '',
          buildingName: '',
          isMapped: siteIsMapped === false ? 'false' : 'true',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={parseByLang(site?.name) || networkName}
      content={({ form }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Building
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Building
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '33px' }}>
            <Field name="site">
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={buildSiteOptions()}
                  label={'Parent Site Selection'}
                  required={true}
                  fullWidth
                  disabled={!!site || count === 0}
                  disableClearable={true}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '24px', textAlign: 'left' }}>
            <Radios
              label={getFormLabel('This Building is', true)}
              name="isMapped"
              color="primary"
              size="small"
              radioGroupProps={{ row: true }}
              required={false}
              data={[
                { label: 'Mapped', value: 'true', disabled: !siteIsMapped },
                { label: 'Unmapped', value: 'false', disabled: siteIsMapped },
              ]}
            />
          </Grid>
          <Condition when="isMapped" is="true">
            <Grid item xs={12}>
              <InfoPanel
                title="You cannot create an Unmapped Building for a Mapped Site."
                sx={{ marginTop: '8px' }}
              />
            </Grid>
          </Condition>
          <Grid item xs={12} sx={{ marginTop: '14px' }}>
            <FinalTextField
              label={getFormLabel('Building Name', true)}
              name="buildingName"
              variant="outlined"
              autoComplete="off"
              fullWidth
            />
          </Grid>
          <Condition when="isMapped" is="false">
            <InfoPanel
              title={
                'Creating a new Building for this site suppresses any building, floor, and suite information that was defined at the site address level.'
              }
              sx={{ marginTop: '8px' }}
            />
          </Condition>
          <FormSpy
            subscription={{ values: true }}
            onChange={(props) => {
              const selectedSiteId = props?.values?.site;
              if (selectedSiteId) {
                const site = sites.find((s) => s.id === selectedSiteId);
                if (site && !site.isMapped) {
                  form.change('isMapped', 'false');
                }
                setSiteIsMapped(site?.isMapped === true);
              }
            }}
          />
        </Grid>
      )}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddBuildingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  networkName: PropTypes.string.isRequired,
  networkLogo: PropTypes.string,
  site: PropTypes.object,
};

AddBuildingModal.defaultProps = {
  networkLogo: null,
};

export default AddBuildingModal;
