import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/loading/loading';
import { FlamingoContext } from 'contexts/flamingo';
import { useSSOConnection } from 'hooks/dataHooks';
import LogRocket from 'logrocket';
import WelcomePage from 'pages/welcome';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

/**
 * Performs a redirect if the user is authenticated.
 *
 * For the SSO flow management:
 * An SSO domain for Portal has four levels. For example, https://gozio-saml.portal.goziohealth.com/ has 4 parts:
 *  - `.com` is the top level domain (TLD)
 *  - `goziohealth` is the second level domain (2LD)
 *  - `portal` is the third level domain (3LD)
 *  - `gozio-saml` is the fourth level domain (4LD)
 *
 * For a network that supports SSO via the feature toggle, the users for that network log in via the SSO login page for the network instead of the standard Auth0 login page. We determine how to route the user to the AWS SSO login page by the SSO_CONNECTION environment variable defined in config.js.
 *
 * The workflow is as follows:
 *
 * 1. An admin invites a user.
 * 2. The new user receives an email with a link that contains the link to Portal. The link has four parts where the 4LD is a string that should be defined in the SSO_CONNECTION environment variable.
 * 3. When a user goes to Portal by clicking on that link, Portal will map the 4LD to its SSO connection string defined in SSO_CONNECTION in config.js. If it is defined, we assume that connection string is configured in Auth0 and will redirect the user to Auth0 with that connection string.
 * 4. Auth0 checks whether the login attempt has a connection string. If the connection string is defined and is configured, the user will be redirected to the configured SSO login page to perform the login.
 */
const Login = () => {
  const { isAuthenticated, error, loginWithRedirect } = useAuth0();
  const ssoConnection = useSSOConnection();
  const { user } = useContext(FlamingoContext);

  if (error) {
    console.error('Auth0 error: ', error);
    return <Navigate to="/unauthorized" />;
  }

  if (!isAuthenticated) {
    if (ssoConnection) {
      LogRocket.log('[Auth0] SSO login redirect');
      loginWithRedirect({
        appState: {
          returnTo: '/',
        },
        authorizationParams: {
          connection: ssoConnection,
          prompt: 'login',
        },
      });
      return <Loading fullPage />;
    }
    return <WelcomePage isAuthenticated={false} />;
  }

  if (user?.networks) {
    if (user.networks.includes('*')) {
      return <Navigate to={'/network/Global Template/welcome'} />;
    }
    return <Navigate to={`/network/${user.networks[0]}/welcome`} />;
  }

  return null;
};

export default Login;
