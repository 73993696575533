import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
  },
  legend: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    padding: '6px 16px 16px 16px',

    '& p': {
      display: 'inline-block',
    },
  },
  dot: {
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '6px',
    width: '16px',
    height: '16px',

    '&:not(:first-child)': {
      marginLeft: '6px',
    },
  },
}));

const DEFAULT_COLOR = ColorPalette.blue[400];

const GozioPieChart = ({
                         sx,
                         colors,
                         data,
                         nameKey,
                         dataKey,
                         dataLabel,
                         width,
                         height,
                         renderLegend,
                         showLegend,
                       }) => {
  const classes = useStyles();

  const Legend = (props) => renderLegend
      ? renderLegend(props)
     : (
      <Box className={classes.legend}>
        {data.map(({ name }, index) => (
          <Fragment key={`leg-${index}`}>
            <Box
              className={classes.dot}
              sx={{
                backgroundColor: colors?.[index] ?? ColorPalette.blue[400],
              }}
            />
            <Typography variant="body2">{name}</Typography>
          </Fragment>
        ))}
      </Box>
    );

  // noinspection JSValidateTypes
  return (
    <Box className={classes.root} style={sx}>
      <ResponsiveContainer width="100%" height={height}>
        <PieChart height={height} data={data}>
          <Pie
            dataKey={dataKey}
            nameKey={nameKey}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={70}
            fill={ColorPalette.blue[400]}
            paddingAngle={0}
            stroke="none"
            strokeWidth={2}
            label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
              const RADIAN = Math.PI / 180;
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              return (
                <text
                  dominantBaseline="central"
                  fill={ColorPalette.grey[600]}
                  fontFamily="Roboto"
                  fontSize={14}
                  fontWeight="bold"
                  textAnchor={x > cx ? 'start' : 'end'}
                  x={x}
                  y={y}
                >
                  {Number(value ?? 0)?.toLocaleString()}
                  {dataLabel}
                </text>
              );
            }}
          >
            {data
              .map((entry, index) => entry?.[nameKey] ? (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors?.[index] ?? DEFAULT_COLOR}
                  />
                ) : null,
              )
              .filter((i) => i)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {showLegend && <Legend data={data} classes={classes} colors={colors} />}
    </Box>
  );
};

GozioPieChart.propTypes = {
  sx: PropTypes.object,
  colors: PropTypes.array,
  data: PropTypes.array,
  dataKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  renderLegend: PropTypes.func,
  showLegend: PropTypes.bool,
};

GozioPieChart.defaultProps = {
  sx: {},
  colors: [],
  data: null,
  width: 270,
  height: 170,
  renderLegend: null,
  showLegend: true,
};

export default GozioPieChart;
