import { useMutation, useQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';

import {
  FIND_NETWORK_PLACE_WAIT_TIME_TOKEN,
  GET_NETWORK_WAIT_TIME,
  UPDATE_NETWORK_WAIT_TIME,
  UPDATE_NETWORK_PLACE_WAIT_TIME_TOKEN,
} from 'graphql/queries';

export const useWaitTimeFeedSettings = (networkId) => {
  const { handleError } = useHandleError('WaitTimeFeed');
  const { loading, data, refetch } = useQuery(GET_NETWORK_WAIT_TIME, {
    context: { headers: { network: networkId } },
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
  return {
    loading,
    data: data?.getNetwork,
    refetch,
  };
};

export const useWaitTimeFeedPlaces = (networkId) => {
  const { handleError } = useHandleError('WaitTimeFeed');
  const { loading, data, refetch } = useQuery(FIND_NETWORK_PLACE_WAIT_TIME_TOKEN, {
    context: { headers: { network: networkId } },
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
  return {
    loading,
    data: data?.findNetworkPlace?.edges.map(({ node }) => node) || [],
    refetch,
  };
};

export const useUpdateWaitTimeFeedSettings = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Kiosk');
  return useMutation(UPDATE_NETWORK_WAIT_TIME, {
    context: { headers: { network: networkId } },
    onCompleted: () => {
      toastNotificationSuccessHook('Wait time feed parameters saved');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateWaitTimeFeedPlace = (networkId) => {
  const { handleError } = useHandleError('Kiosk');

  return useMutation(UPDATE_NETWORK_PLACE_WAIT_TIME_TOKEN, {
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
