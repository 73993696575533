import { Warning } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Authorize, SCOPES } from 'components/authorization/authorize';
import Loading from 'components/loading/loading';
import { FlamingoContext } from 'contexts/flamingo';
import { CLIENT_TYPES } from 'helpers/channel-util';
import { httpRequest } from 'helpers/http-util';
import { capitalize, grammaticallyJoin } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useDataPackage, usePublishingEventsSubscription } from 'hooks/dataHooks/useDataPackage';
import useLogout from 'hooks/useLogout';
import {
  useGetNetworkChannelsAndContentProgress,
  useNetworkChannels,
} from 'hooks/dataHooks/useNetworkChannelsAndContentProgress';
import { useNetworkPublishingControl } from 'hooks/dataHooks/useNetworkConfiguration';
import useCheckGozioAdmin, { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useToast from 'hooks/useToast';
import { useWorkspace } from 'hooks/useWorkspace';
import { useSnackbar } from 'notistack';
import ChannelStatus from 'pages/home/containers/ChannelStatus';
import NetworkDataProgress from 'pages/home/containers/NetworkDataProgress';
import Publish from 'pages/home/containers/Publish';
import PublishFailureModal from 'pages/home/containers/PublishFailureModal';
import PublishModal from 'pages/home/containers/PublishModal';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import { Welcome } from 'pages/welcome';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  content: {
    paddingBottom: theme.spacing(3),
  },
});

const HomePageContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { networkId } = useParams();
  const { closeSnackbar } = useSnackbar();
  const {
    toastNotificationCustomHook,
    toastNotificationSuccessHook,
    toastNotificationErrorHook,
  } = useToast();
  const logout = useLogout();
  const {
    activeNetwork,
    authorize,
    globalStates: {
      isLanguageSupportImportingMap: {
        [networkId]: isLanguageSupportImporting,
      },
    },
  } = useContext(FlamingoContext);
  const networkName = activeNetwork?.humanName ?? null;
  const { workspace } = useWorkspace();
  const userRole = useCheckRole();
  const {
    data: networkContentProgressData,
    refetch: getNetworkContentProgressData,
  } = useGetNetworkChannelsAndContentProgress(networkId);
  const { data: networkChannelsData, refetch: getNetworkChannels }
    = useNetworkChannels(networkId);

  const { data: subscriptionData, loading: subscriptionDataLoading }
    = usePublishingEventsSubscription(networkId);
  const { data: publishingControlData, refetch: refetchPublishingControl }
    = useNetworkPublishingControl(networkId);
  const { data: findDataPackageData, refetch: refetchDataPackages }
    = useDataPackage(networkId, 1);

  const isUberAdmin = useCheckGozioAdmin();

  const [isDataComplete, setIsDataComplete] = useState({
    [LIVE_WORKSPACE]: false,
    [DRAFT_WORKSPACE]: false,
  });
  const [currentWorkspace, setCurrentWorkspace] = useState(workspace);
  const [showPublishStepperModal, setShowPublishStepperModal] = useState(false);
  const [progressData, setProgressData] = useState({});
  const [progressCards, setProgressCards] = useState([]);
  const [noChannelsInSync, setNoChannelsInSync] = useState(false);
  const [locationsComplete, setLocationsComplete] = useState(false);
  const [hasKioskChannel, setHasKioskChannel] = useState(false);
  const [showPublishFailureModal, setShowPublishFailureModal] = useState(false);
  const [errorDescription, setErrorDescription] = useState(null);
  const [networkPublishing, setNetworkPublishing] = useState({});

  const typeMap = useMemo(
    () => ({
      Kiosk: {
        name: 'Kiosk',
        url: `/network/${networkId}/kiosks`,
      },
      KioskTemplate: {
        name: 'Kiosk Template',
        url: `/network/${networkId}/kiosks/template`,
      },
      NetworkDashboard: {
        name: 'Dashboard',
        url: `/network/${networkId}/mobileDashboard`,
      },
      NetworkExplorePanel: {
        name: 'Explore Panel',
        url: `/network/${networkId}/explore`,
      },
      NetworkPlace: {
        name: 'Locations',
        url: `/network/${networkId}/locations/`,
      },
      NetworkNavigation: {
        name: 'Navigation',
        url: `/network/${networkId}/navigation`,
      },
      Workspace: {
        name: 'Workspace',
      },
    }),
    [networkId],
  );

  const mapNetworkContentProgressData = useCallback(
    (data) => {
      if (workspace !== LIVE_WORKSPACE) data = data.filter((item) => ['NetworkExplorePanel', 'NetworkPlace', 'Workspace'].includes(
            item.node.type,
          ),
        );
      const progressMap = {
        live: {},
        draft: {},
      };
      let locationsCompleteValue = false;
      data.forEach((item) => {
        const { node } = item;
        if (
          workspace === LIVE_WORKSPACE
          && node.workspace?.name === LIVE_WORKSPACE
          && node.type === 'NetworkPlace'
          && node.status === 'complete'
        ) locationsCompleteValue = true;
        if (
          node.type === 'Workspace'
          || authorize(SCOPES.NETWORK_DASHBOARD.VIEW_NETWORK_DATA_PROGRESS, {
            data: { doc: { type: node.type } },
          })
        ) {
          progressMap[node.workspace.name][node.type] = {
            name: typeMap[node.type].name,
            url: typeMap[node.type].url,
            ...node,
          };
        }
      });
      setLocationsComplete(locationsCompleteValue);
      return progressMap;
    },
    [authorize, typeMap, workspace],
  );

  useEffect(() => {
    if (
      activeNetwork?.id
      && !isGlobalTemplate(activeNetwork.id)
      && typeof publishingControlData.networkId !== 'undefined'
      && publishingControlData.networkId !== activeNetwork.id
    ) {
      refetchPublishingControl();
    }
  }, [activeNetwork, publishingControlData, refetchPublishingControl]);

  useEffect(() => {
    if (
      activeNetwork?.id
      && networkChannelsData?.findNetworkChannel?.edges?.length
    ) {
      if (
        networkChannelsData?.findNetworkChannel?.edges[0].node?.network?.id
        !== activeNetwork?.id
      ) {
        getNetworkChannels();
        getNetworkContentProgressData();
      }
    }
  }, [
    activeNetwork,
    networkId,
    networkChannelsData,
    getNetworkChannels,
    getNetworkContentProgressData,
  ]);

  useEffect(() => {
    if (networkChannelsData?.findNetworkChannel?.edges?.length) {
      setNetworkPublishing((networks) => ({
        ...networks,
        [networkId]: {
          ...networks[networkId],
          networkChannels: networkChannelsData?.findNetworkChannel?.edges
            .map(({ node: channel }) => ({
              ...channel,
              latestClientVersion: [...channel.packagerDataSpec].sort((a, b) => a.version === b.version ? 0 : a.version > b.version ? -1 : 1,
              )?.[0],
            }))
            .filter(
              (c) => c?.workspaces?.filter((w) => w.name === workspace)?.length,
            )
            .sort((a, b) => a?.label > b?.label ? a?.label < b?.label ? 1 : 0 : -1,
            )
            .filter((channel) => {
              if (!channel.enabled) return false;
              switch (userRole) {
                case USER_ROLES.SDK_ADMIN:
                  return (
                    channel.latestClientVersion.clientType === CLIENT_TYPES.SDK
                  );
                case USER_ROLES.UBER_ADMIN:
                  return true;
                default:
                  return (
                    channel.latestClientVersion.clientType !== CLIENT_TYPES.SDK
                  );
              }
            }),
        },
      }));
    }
  }, [networkChannelsData, networkId, userRole, workspace]);

  useEffect(() => {
    if (currentWorkspace !== workspace) {
      setCurrentWorkspace(workspace);
      getNetworkChannels();
    }
  }, [currentWorkspace, getNetworkChannels, workspace]);

  // Check whether publishing is enabled or disabled for this user and network
  useEffect(() => {
    const setControl = (value) => {
      if (
        networkPublishing[networkId]?.publishingEnabled?.[workspace] !== value
      ) {
        setNetworkPublishing((networks) => ({
          ...networks,
          [networkId]: {
            ...networks[networkId],
            publishingEnabled: {
              ...networks[networkId]?.publishingEnabled,
              [`${workspace}`]: value,
            },
          },
        }));
      }
    };

    switch (publishingControlData[workspace]) {
      case 'disabled':
        setControl(false);
        break;
      case 'enabled':
        setControl(true);
        break;
      case 'gozioAdmin':
        setControl(isUberAdmin === true);
        break;
      default:
        setControl(false);
    }
  }, [
    isUberAdmin,
    networkId,
    networkPublishing,
    publishingControlData,
    workspace,
  ]);

  useEffect(() => {
    if (
      !networkPublishing?.[networkId]?.networkChannels
      && networkPublishing?.[networkId]?.networkChannels?.[0]?.network?.id
      === networkId
    ) {
      setNetworkPublishing((networks) => ({
        ...networks,
        [networkId]: {
          ...networks[networkId],
          networkChannels: networkPublishing?.[
            networkId
            ]?.networkChannels.filter((channel) => {
            if (!channel.enabled) return false;
            switch (userRole) {
              case USER_ROLES.SDK_ADMIN:
                return (
                  channel.latestClientVersion.clientType === CLIENT_TYPES.SDK
                );
              case USER_ROLES.UBER_ADMIN:
                return true;
              default:
                return (
                  channel.latestClientVersion.clientType !== CLIENT_TYPES.SDK
                );
            }
          }),
        },
      }));
    }
  }, [networkId, networkPublishing, userRole]);

  useEffect(() => {
    if (networkPublishing?.[networkId]?.networkChannels?.length) {
      const hasKiosk
        = networkPublishing?.[networkId]?.networkChannels.filter(
          (channel) => channel.latestClientVersion?.clientType === CLIENT_TYPES.KIOSK
            && channel.enabled,
        ).length > 0;
      setHasKioskChannel((currentValue) => {
        if (currentValue !== hasKiosk) return hasKiosk;
        return currentValue;
      });
      const noChannelsSynced = !networkPublishing?.[networkId]?.networkChannels
        .filter((channel) => channel.enabled)
        .map((channel) => channel.isInSync)
        .includes(true);
      setNoChannelsInSync((currentValue) => {
        if (currentValue !== noChannelsSynced) return noChannelsSynced;
        return currentValue;
      });
    }
  }, [networkId, networkPublishing]);

  // Handle the subscription
  useEffect(() => {
    if (!subscriptionDataLoading && subscriptionData?.DataPackage?.event) {
      const { data } = subscriptionData.DataPackage.event;
      if (
        data.progress
        > networkPublishing[data.network]?.dataPackage?.progress
        || !networkPublishing[data.network]
      ) {
        setNetworkPublishing((networks) => ({
          ...networks,
          [data.network]: {
            ...networks[data.network],
            dataPackage: data,
          },
        }));
      }
      if (data.state !== 'packaging') {
        if (data.network === networkId) {
          refetchDataPackages({
            id: data.id,
          });
        }
        // Still need to clean up other network's data even if we aren't viewing it
        const existingDataPackage
          = networkPublishing[data.network]?.dataPackage;
        if (existingDataPackage?.id && data.network !== networkId) {
          setNetworkPublishing((networks) => {
            delete networks[data.network];
            return networks;
          });
        }
      }
    }
  }, [
    networkId,
    networkPublishing,
    refetchDataPackages,
    subscriptionData,
    subscriptionDataLoading,
  ]);

  useEffect(() => {
    if (!workspace || (!isUberAdmin && workspace !== LIVE_WORKSPACE)) return;
    const packageData = findDataPackageData?.findDataPackage?.edges.map(
      (a) => a?.node,
    )[0];
    const packageDataNetworkId = packageData?.network?.id;
    if (!packageData || !packageDataNetworkId) return;
    const existingNetworkDataPackageId
      = networkPublishing[packageDataNetworkId]?.dataPackage?.id;
    if (packageData.state === 'packaging' && !existingNetworkDataPackageId) {
      setNetworkPublishing((networks) => ({
        ...networks,
        [packageDataNetworkId]: {
          ...networks[packageDataNetworkId],
          dataPackage: {
            id: packageData.id,
            progress: packageData.progress,
            state: packageData.state,
          },
        },
      }));
    } else if (
      packageData.state !== 'packaging'
      && existingNetworkDataPackageId
      && packageData.id === existingNetworkDataPackageId
    ) {
      const packageWorkspace = [
        ...new Set(
          packageData.networkChannels.map((channel) => channel.workspaces[0]),
        ),
      ][0];
      if (packageWorkspace === DRAFT_WORKSPACE && !isUberAdmin) return;
      const channelLabels = packageData.networkChannels.map(
        (channel) => channel.label,
      );

      // Show toasts if we are on the same network as the package
      if (packageData.state === 'errored') {
        const numErrors = packageData.errorDetails.length;
        const message = `When attempting to Publish to ${grammaticallyJoin(
          channelLabels,
        )} in the ${capitalize(workspace)} Workspace, there ${
          numErrors > 1 ? 'were' : 'was'
        } ${numErrors} ${numErrors > 1 ? 'errors' : 'error'} found.`;
        setErrorDescription(message);
        toastNotificationCustomHook({
          options: {
            variant: 'error',
            key: packageData.id,
            message: (
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Publishing Failed
                </Typography>
                <Typography variant="body1">{message}</Typography>
                <Link
                  variant="body1"
                  onClick={() => {
                    setShowPublishFailureModal(true);
                    closeSnackbar(packageData.id);
                  }}
                >
                  View Details
                </Link>
              </Box>
            ),
          },
        });
      } else if (packageData?.state === 'packaged') {
        getNetworkChannels();
        toastNotificationSuccessHook(
          `Publishing to the ${grammaticallyJoin(
            channelLabels,
          )} in the ${capitalize(workspace)} Workspace was a success.`,
        );
      }
      setNetworkPublishing((networks) => {
        delete networks[packageDataNetworkId]?.dataPackage;
        return networks;
      });
    }
  }, [
    closeSnackbar,
    findDataPackageData,
    getNetworkChannels,
    isUberAdmin,
    networkId,
    networkPublishing,
    toastNotificationCustomHook,
    toastNotificationSuccessHook,
    workspace,
  ]);

  // Get and set network channel and content progress data
  useEffect(() => {
    if (!networkName || networkName === 'Global Template') return;

    if (networkContentProgressData?.findNetworkContentProgress) {
      const edges = networkContentProgressData.findNetworkContentProgress.edges;
      const locationsData = {
        [LIVE_WORKSPACE]: {
          total: networkContentProgressData.totalLiveLocations.count,
          incomplete: networkContentProgressData.incompleteLiveLocations.count,
        },
        [DRAFT_WORKSPACE]: {
          total: networkContentProgressData.totalDraftLocations.count,
          incomplete: networkContentProgressData.incompleteDraftLocations.count,
        },
      };

      const mappedData = mapNetworkContentProgressData(edges);
      const workspaceData = mappedData[workspace];
      setProgressData(mappedData);
      setIsDataComplete({
        [LIVE_WORKSPACE]: !Object.keys(mappedData[LIVE_WORKSPACE]).find(
          (key) => key !== 'Workspace'
            && mappedData[LIVE_WORKSPACE][key].status === 'incomplete',
        ),
        [DRAFT_WORKSPACE]: !Object.keys(mappedData[DRAFT_WORKSPACE]).find(
          (key) => key !== 'Workspace'
            && mappedData[DRAFT_WORKSPACE][key].status === 'incomplete',
        ),
      });
      setProgressCards(
        Object.keys(workspaceData)
          .filter((key) => {
            if (key === 'Workspace') return false;
            if (workspaceData[key].status === 'complete') return false;
            return authorize(
              SCOPES.NETWORK_DASHBOARD.VIEW_NETWORK_DATA_PROGRESS,
              {
                data: { doc: { type: key } },
              },
            );
          })
          .map((key) => ({
            title: workspaceData[key].name,
            description:
              workspaceData[key].type === 'NetworkPlace'
                ? `${locationsData[workspace].incomplete}/${
                  locationsData[workspace].total
                } are ${capitalize(workspaceData[key].status)}`
                : `is ${capitalize(workspaceData[key].status)}`,
            icon: <Warning />,
            url: workspaceData[key].url,
          })),
      );
    }
  }, [
    authorize,
    locationsComplete,
    mapNetworkContentProgressData,
    networkContentProgressData,
    networkPublishing,
    networkId,
    networkName,
    workspace,
  ]);

  const handlePublishButtonClick = useCallback(() => {
    setShowPublishStepperModal(true);
  }, [setShowPublishStepperModal]);

  const handlePublishModalCompleted = useCallback(
    async (data) => {
      const initiatePublishing = async () => {
        try {
          const response = await httpRequest({
            url: `${process.env.REACT_APP_PUBLISHER_API_URL}/initiator`,
            body: data,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              network: networkId,
              workspace,
            },
            logout,
          });
          if (response.status === 200) {
            // Assume we are going to be getting subscription data back shortly
            setNetworkPublishing((networks) => ({
              ...networks,
              [networkId]: {
                ...networks[networkId],
                dataPackage: {
                  progress: 0,
                },
              },
            }));
          } else {
            toastNotificationErrorHook(
              'Publishing is presently in progress. Check back in 10 mins and try again.',
            );
          }
        } catch (err) {
          console.error(err);
          toastNotificationErrorHook(
            `Unable to initiate publishing: ${err.message ?? err}`,
          );
        }
      };
      setShowPublishStepperModal(false);
      await initiatePublishing();
    },
    [logout, networkId, toastNotificationErrorHook, workspace],
  );

  const isReadyToPublish = useMemo(
    () => ({
      [LIVE_WORKSPACE]:
      isDataComplete?.[LIVE_WORKSPACE]
      || (isUberAdmin
        && locationsComplete
        && networkPublishing?.[networkId]?.networkChannels?.filter(
          (channel) => channel.latestClientVersion.clientType === CLIENT_TYPES.SDK
            && channel.enabled,
        )?.length > 0),
      [DRAFT_WORKSPACE]: isDataComplete?.[DRAFT_WORKSPACE],
    }),
    [
      isDataComplete,
      isUberAdmin,
      locationsComplete,
      networkId,
      networkPublishing,
    ],
  );

  if (isGlobalTemplate(networkId)) {
    return <Welcome />;
  }

  if (!networkName) {
    return <Loading />;
  }

  return (
    <FlamingoPage pageName={`Welcome to ${networkName}`}>
      <Box sx={styles.content}>
        <Grid>
          <Grid item>
            <Authorize
              scope={SCOPES.NETWORK_DASHBOARD.VIEW_NETWORK_DATA_PROGRESS}
              data={{ doc: { type: 'NetworkPlace' } }}
            >
              <NetworkDataProgress
                progressCards={progressCards}
                progressData={progressData}
                isDataComplete={isDataComplete}
                workspace={workspace}
              />
            </Authorize>
            {networkPublishing?.[networkId]?.networkChannels && (
              <Authorize scope={SCOPES.NETWORK_CHANNELS.VIEW}>
                <ChannelStatus
                  networkChannels={
                    networkPublishing?.[networkId]?.networkChannels || []
                  }
                  isUberAdmin={isUberAdmin}
                />
              </Authorize>
            )}
            <Authorize scope={SCOPES.NETWORK_DASHBOARD.CAN_PUBLISH}>
              <Publish
                handlePublishButtonClick={handlePublishButtonClick}
                hasKioskChannel={hasKioskChannel}
                locationsComplete={locationsComplete}
                isReady={isReadyToPublish}
                noChannelsInSync={noChannelsInSync}
                workspace={workspace}
                publishingInProgress={
                  networkPublishing[networkId]?.dataPackage?.progress
                  !== undefined
                    ? networkPublishing[networkId]?.dataPackage.progress
                    : null
                }
                enabled={
                  !isLanguageSupportImporting
                  && (networkPublishing[networkId]?.publishingEnabled?.[
                      workspace
                      ]
                    || false)
                }
              />
            </Authorize>
          </Grid>
        </Grid>
      </Box>
      {showPublishStepperModal && (
        <PublishModal
          networkChannels={
            // If the user is in the live workspace, the network has a kiosk channel, and locations are complete,
            // only the kiosk channel can be published to
            userRole === USER_ROLES.SDK_ADMIN
              ? networkPublishing?.[networkId]?.networkChannels.filter(
                (channel) => channel.latestClientVersion.clientType === CLIENT_TYPES.SDK,
              )
              : workspace === LIVE_WORKSPACE
              && !isDataComplete[LIVE_WORKSPACE]
              && hasKioskChannel
              && locationsComplete
                ? networkPublishing?.[networkId]?.networkChannels
                  .filter(
                    (channel) => channel.latestClientVersion.clientType
                      === CLIENT_TYPES.KIOSK,
                  )
                  .filter(
                    (channel) => isUberAdmin
                      || channel.latestClientVersion.clientType
                      !== CLIENT_TYPES.SDK,
                  )
                : networkPublishing?.[networkId]?.networkChannels.filter(
                  (channel) => isUberAdmin
                    || channel.latestClientVersion.clientType !== CLIENT_TYPES.SDK,
                )
          }
          handleCancel={() => setShowPublishStepperModal(false)}
          handleCompleted={handlePublishModalCompleted}
        />
      )}
      {showPublishFailureModal && (
        <PublishFailureModal
          dataPackage={findDataPackageData?.findDataPackage?.edges[0]?.node}
          errorDescription={errorDescription}
          open={showPublishFailureModal}
          handleClose={() => setShowPublishFailureModal(false)}
        />
      )}
    </FlamingoPage>
  );
};

const HomePage = () => (
  <LoggedinLayout enableSuspense={true}>
    <HomePageContent />
  </LoggedinLayout>
);

export default React.memo(HomePage);
