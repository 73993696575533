import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { buildModalStyles } from 'components/genericModal/modalStyles';
import Loading from 'components/loading/loading';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

function GenericModal({
                        actionSx,
                        body,
                        cancelText,
                        centered,
                        sx,
                        confirmText,
                        confirmType,
                        confirmProgress,
                        customTags: { cancel, confirm },
                        disableActions,
                        disableCancel,
                        disableClose,
                        disableConfirm,
                        formContentSx,
                        greyOutCancel,
                        greyOutConfirm,
                        handleClose,
                        handleConfirm,
                        overrideCancel,
                        open,
                        preventKeyDownEvent,
                        title,
                        additionalLeftButton,
                        additionalRightButton,
                        bodyProgress,
                        scrollable,
                        spaceBetweenDialogActions,
                        ...rest
                      }) {
  const theme = useTheme();
  const styles = buildModalStyles({ theme });

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ...styles.root,
        ...sx,
      }}
      onClose={handleClose}
      {...rest}
    >
      {(title || !disableClose) && (
        <Box
          id="alert-dialog-title"
          sx={{
            ...centered
              ? { ...styles.titleStyle, ...styles.titleCentered }
              : styles.titleStyle,
          }}
        >
          {!disableClose && (
            <IconButton sx={styles.closeBtn} onClick={handleClose} size="large">
              <Close />
            </IconButton>
          )}
          <Typography variant="h1">{title}</Typography>
        </Box>
      )}
      <Box sx={{ ...styles.formContent, ...formContentSx }}>
        <DialogContent
          sx={{
            ...!scrollable && {
              '& .MuiDialogContent-root': styles.nonScrollableContent,
            },
            ...!centered
              ? styles.dialogContent
              : { ...styles.dialogContent, ...styles.dialogContentCentered },
          }}
          data-test="GenericModal-content"
        >
          {bodyProgress ? (
            <Box>
              <Loading
                content=""
                backgroundColor={ColorPalette.white}
                thickness={1.5}
              />
            </Box>
          ) : typeof body === 'string' ? (
            <DialogContentText
              id="alert-dialog-description"
              variant="subtitle1"
              data-test="GenericModal-content-text"
            >
              {body}
            </DialogContentText>
          )
            : body
          }
        </DialogContent>
      </Box>
      {!disableActions && (
        <DialogActions
          sx={{
            ...styles.actions,
            justifyContent: spaceBetweenDialogActions
              ? 'space-between'
              : 'flex-end',
            ...actionSx,
          }}
        >
          <Box>
            {!disableCancel && (
              <Button
                onClick={overrideCancel || handleClose}
                color="primary"
                variant="outlined"
                disabled={greyOutCancel}
                data-test={cancel || 'genericModalCancel'}
                sx={{ minWidth: '120px', marginRight: '12px' }}
              >
                {cancelText || 'Cancel'}
              </Button>
            )}
            {!!additionalLeftButton && additionalLeftButton}
          </Box>
          <Box>
            {!!additionalRightButton && additionalRightButton}
            {!disableConfirm && (
              <Box sx={{ display: 'inline', position: 'relative' }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: '120px' }}
                  type={confirmType || 'button'}
                  onClick={handleConfirm}
                  onKeyDown={
                    !preventKeyDownEvent
                      ? null
                      : (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                  }
                  disabled={greyOutConfirm || confirmProgress}
                  data-test={confirm || 'genericModalConfirm'}
                >
                  {confirmText || 'Confirm'}
                </Button>
                {confirmProgress && (
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                )}
              </Box>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}

GenericModal.propTypes = {
  actionSx: PropTypes.object,
  body: PropTypes.any,
  cancelText: PropTypes.string,
  sx: PropTypes.object,
  confirmText: PropTypes.any,
  confirmType: PropTypes.string,
  centered: PropTypes.bool,
  customTags: PropTypes.object,
  disableActions: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableClose: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  formContentSx: PropTypes.object,
  greyOutCancel: PropTypes.bool,
  greyOutConfirm: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.any,
  additionalLeftButton: PropTypes.any,
  additionalRightButton: PropTypes.any,
  confirmProgress: PropTypes.bool,
  bodyProgress: PropTypes.bool,
  overrideCancel: PropTypes.func,
  preventKeyDownEvent: PropTypes.bool,
  scrollable: PropTypes.bool,
  spaceBetweenDialogActions: PropTypes.bool,
};

GenericModal.defaultProps = {
  actionSx: {},
  sx: {},
  customTags: {
    cancel: null,
    confirm: null,
  },
  centered: false,
  disableActions: false,
  disableClose: false,
  disableCancel: false,
  formContentSx: {},
  open: true,
  title: null,
  confirmProgress: false,
  bodyProgress: false,
  scrollable: false,
  spaceBetweenDialogActions: true,
};

export default GenericModal;
