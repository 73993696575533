import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ItemTypes } from 'helpers/dnd-util';
import { isDefaultImageMatchesMediaItem } from 'helpers/media-util';
import MediaCard from 'pages/locations/containers/sections/mediaCard';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDrop } from 'react-dnd';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const MediaBox = ({
  media,
  defaultImage,
  designation,
  placeholderImage,
  onMediaDeleted,
  onOrderChanged,
  supportsKiosk,
}) => {
  const classes = useStyles();

  const [moveIndex, setMoveIndex] = useState(null);
  const [dropIndex, setDropIndex] = useState(null);
  const [dropTargetIsPlaceholder, setDropTargetIsPlaceholder] = useState(false);

  const hasPlaceholder = !isDefaultImageMatchesMediaItem(
    defaultImage,
    media.filter((m) => !m.toBeDeleted).length > 0 ? media[0] : null,
  );

  const onHover = (item, hoverIndex) => {
    setDropIndex(item.index);
    setMoveIndex(hoverIndex);
  };

  const [, mediaDrop] = useDrop({
    accept: ItemTypes.MEDIA_IMAGE,
    drop: (item) => {
      if (
        item.canBeDefault
        || moveIndex > 0
        || (!item.canBeDefault && moveIndex === 0)
      ) {
        onOrderChanged(item, moveIndex);
      }
      setMoveIndex(null);
    },
    canDrop: (item) => {
      if (dropTargetIsPlaceholder) {
        if (!item.canBeDefault) {
          return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });
  return (
    <Box className={classes.root} ref={mediaDrop}>
      {hasPlaceholder && !defaultImage && !media.filter((m) => !m.toBeDeleted).length && (
        <MediaCard
          key={'media_placeholder'}
          index={-1}
          designation={designation}
          media={placeholderImage}
          isDropTarget={moveIndex === -1 && media[dropIndex].type === 'image'}
          isDefault={false}
          isPlaceholder={true}
          dropIndex={dropIndex}
          onDrop={() => setDropTargetIsPlaceholder(true)}
          onHover={onHover}
          supportsKiosk={supportsKiosk}
        />
      )}
      {media
        .map((m, i) => {
          if (m.toBeDeleted) {
            return null;
          }
          return (
            <MediaCard
              key={`media_${i}`}
              index={i}
              indexLabel={i + 1}
              media={m}
              isDropTarget={
                moveIndex === i
                && !(i === -1 && media[dropIndex].type === 'video')
              }
              isDefault={false} // TODO: Refactor MediaCard to remove references to default images
              isPlaceholder={false}
              dropIndex={dropIndex}
              designation={designation}
              onDrop={() => setDropTargetIsPlaceholder(false)}
              onHover={onHover}
              onMediaDeleted={onMediaDeleted}
              supportsKiosk={supportsKiosk}
            />
          );
        })
        .filter((m) => m)}
    </Box>
  );
};

MediaBox.propTypes = {
  media: PropTypes.array.isRequired,
  defaultImage: PropTypes.object,
  placeholderImage: PropTypes.object,
  designation: PropTypes.oneOf([
    'site',
    'building',
    'floor',
    'poi',
    'landmark',
  ]),
  onMediaDeleted: PropTypes.func.isRequired,
  onOrderChanged: PropTypes.func.isRequired,
  supportsKiosk: PropTypes.bool,
};

MediaBox.defaultProps = {
  defaultImage: null,
  placeholderImage: null,
  supportsKiosk: false,
};

export default MediaBox;
