import { Info } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundColor: theme.palette.grey[400],
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    overflow: 'hidden',
    padding: theme.spacing(0, 2),
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: theme.spacing(1),
  },
  textEllipsisOneLine: {
    WebkitLineClamp: '1 !important',
  },
  textEllipsisTwoLines: {
    WebkitLineClamp: '2 !important',
  },
  overline: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    maxWidth: '240px',
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  icon: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginBottom: theme.spacing(1),
  },
  iconLeft: {
    width: '50px',
    height: '50px',
  },
  iconLeftSmall: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
  },
  iconLeftMedium: {
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(7.5),
  },
  iconLeftLarge: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  iconCenter: {
    width: '40px',
    height: '40px',
  },
  leftTextContainer: {
    marginLeft: theme.spacing(2),
    minWidth: '240px',
    width: '100%',
  },
  maxWidth: {
    width: '100%',
  },
  extraSmall: { height: '66px' },
  small: { height: '96px' },
  smallStandalone: { height: '128px' },
  medium: { height: '175px' },
  large: { height: '214px' },
  extraLarge: { height: '280px' },
  baseTextLeft: {
    textAlign: 'left',
    paddingRight: theme.spacing(6),
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
  },
  textEllipsis: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
  },
  'title-extraSmall': {
    fontSize: '16px',
    WebkitLineClamp: 2,
  },
  'title-small': {
    WebkitLineClamp: 1,
  },
  'title-medium': {
    WebkitLineClamp: 2,
  },
  'title-large': {
    WebkitLineClamp: 2,
  },
  'title-extraLarge': {
    WebkitLineClamp: 4,
  },
});
export const Card = ({
                       backgroundColor,
                       backgroundImageUrl,
                       clickHandler,
                       disabled,
                       iconUrl,
                       incompleteForPackaging,
                       overline,
                       selected,
                       size,
                       standalone,
                       subtitle,
                       sx,
                       textAlignment,
                       textColor,
                       title,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  const isLeftAlign = textAlignment === 'left';

  const renderIncompleteWarning = () => {
    const incompleteStyle = {
      left: standalone ? `${width - 28}px` : `${width - 36}px`,
    };

    if (standalone) {
      return (
        <Box sx={{ ...styles.incompleteWarning, ...incompleteStyle }}>
          <Info />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          ...styles[size],
          ...standalone && styles[`${size}Standalone`],
          position: 'fixed',
          width: `${width}px`,
        }}
      >
        <Box sx={{ ...styles.incompleteWarning, ...incompleteStyle }}>
          <Info />
        </Box>
      </Box>
    );
  };

  const backgroundStyle = {};
  if (backgroundImageUrl) {
    backgroundStyle.backgroundImage = `url(${backgroundImageUrl})`;
  } else {
    backgroundStyle.backgroundColor = backgroundColor;
  }

  const isSmallOrExtraSmall = ['extraSmall', 'small'].includes(size);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return (
    <Grid
      ref={ref}
      container
      direction="column"
      justifyContent="center"
      alignItems={isLeftAlign ? 'flex-start' : 'center'}
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...styles[size],
        ...standalone && styles[`${size}Standalone`],
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...sx,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        ...backgroundStyle,
        color: textColor,
        textAlign: textAlignment,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      {size !== 'extraSmall'
        && (iconUrl ? (
          <Box
            sx={{
              ...styles.icon,
              ...isLeftAlign ? styles.iconLeft : styles.iconCenter,
              ...isLeftAlign && size === 'small' ? styles.iconLeftSmall : '',
              ...isLeftAlign && size === 'medium'
                ? styles.iconLeftMedium
                : '',
              ...isLeftAlign && size === 'extraLarge'
                ? styles.iconLeftLarge
                : '',
              backgroundImage: `url(${iconUrl})`,
            }}
          />
        ) : (
          <AddPhotoAlternateIcon
            sx={{
              ...styles.icon,
              ...isLeftAlign ? styles.iconLeft : styles.iconCenter,
              ...isLeftAlign && size === 'small' ? styles.iconLeftSmall : '',
              ...isLeftAlign && size === 'medium'
                ? styles.iconLeftMedium
                : '',
              ...isLeftAlign && size === 'extraLarge'
                ? styles.iconLeftLarge
                : '',
            }}
          />
        ))}
      <Box
        sx={{
          ...isLeftAlign && styles.leftTextContainer,
          ...!standalone && styles.maxWidth,
        }}
      >
        {!!overline && (isLeftAlign || !isSmallOrExtraSmall) && (
          <Typography
            variant="body1"
            sx={{
              ...isLeftAlign && styles.baseTextLeft,
              ...styles.overline,
              ...!standalone
                && ['medium', 'large', 'extraLarge'].includes(size)
                && styles.textEllipsis,
              ...!standalone && styles.textEllipsisOneLine,
              color: textColor,
            }}
          >
            {overline}
          </Typography>
        )}
        {!!title && (
          <Typography
            variant={standalone ? 'h2' : 'h3'}
            sx={{
              ...styles[`title-${size}`],
              ...isLeftAlign && styles.baseTextLeft,
              ...styles.title,
              ...!standalone
                && ['medium', 'large', 'extraLarge'].includes(size)
                && styles.textEllipsis,
              ...!standalone
                && size === 'medium'
                && styles.textEllipsisOneLine,
              ...!standalone
                && ['large', 'extraLarge'].includes(size)
                && styles.textEllipsisTwoLines,
              color: textColor,
            }}
          >
            {title}
          </Typography>
        )}
        {!!subtitle
          && (isLeftAlign
            || !isSmallOrExtraSmall
            || (!isLeftAlign && size === 'small')) && (
            <Typography
              variant="body1"
              sx={{
                ...isLeftAlign && styles.baseTextLeft,
                ...styles.subtitle,
                ...!standalone
                  && ['medium', 'large', 'extraLarge'].includes(size)
                  && styles.textEllipsis,
                ...!standalone && styles.textEllipsisTwoLines,
                color: textColor,
              }}
            >
              {subtitle}
            </Typography>
          )}
      </Box>
    </Grid>
  );
};

Card.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  iconUrl: PropTypes.string,
  incompleteForPackaging: PropTypes.bool,
  overline: PropTypes.string,
  selected: PropTypes.bool,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  standalone: PropTypes.bool,
  subtitle: PropTypes.string,
  textAlignment: PropTypes.oneOf(['left', 'center']),
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Card.defaultProps = {
  disabled: false,
  incompleteForPackaging: false,
  selected: false,
  standalone: true,
  sx: {},
};

export default Card;
