import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import GozioRadio from 'components/radioGroup/radio';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  label: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.grey[600],
      fontWeight: 'normal',
    },
  },
  disabled: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.grey[400],
    },
  },
  disabledAndSelected: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.grey[600],
    },
  },
  verticalAlignment: {
    '& .MuiFormControlLabel-label': {
      marginLeft: '2px',
      marginTop: '2px',
    },
  },
  description: {
    marginLeft: '28px',
    marginBottom: '24px',
    lineHeight: 1.44,
    fontWeight: 400,
  },
}));

const GozioRadioGroup = ({
                           alignment,
                           disabled,
                           groupName,
                           options,
                           onChange,
                           value,
                         }) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <RadioGroup
      key={groupName}
      name={groupName}
      onChange={handleChange}
      value={value}
      row={alignment === 'horizontal'}
    >
      {options.map((option, index) => (
        <Box key={`${groupName}_${index}`}>
          <FormControlLabel
            className={clsx(
              classes.label,
              alignment === 'vertical' && classes.verticalAlignment,
              disabled
              && (option.value === selectedValue
                ? classes.disabledAndSelected
                : classes.disabled),
            )}
            control={
              <GozioRadio
                checked={selectedValue === option.value}
                disabled={disabled}
                value={option.value}
                color="primary"
              />
            }
            data-test={`radioGroupLabel-${option.value || index}`}
            label={option.label}
          />
          {alignment === 'vertical' && option.description && (
            <Typography className={classes.description} variant="body1">
              {option.description}
            </Typography>
          )}
        </Box>
      ))}
    </RadioGroup>
  );
};

GozioRadioGroup.propTypes = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GozioRadioGroup.defaultProps = {
  alignment: 'horizontal',
};

export default GozioRadioGroup;
