import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from 'components/autocomplete/autoComplete';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { FlamingoContext } from 'contexts/flamingo';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { DEFAULT_KIOSK_BANNER_INTERVAL, useCreateKiosk } from 'hooks/dataHooks/useKiosks';
import { useSites } from 'hooks/dataHooks/useSites';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import * as Yup from 'yup';

import {
  FIND_NETWORK_PLACE_SITES_MAPPED,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const addKioskSchema = Yup.object().shape({
  name: Yup.string().required('Kiosk name is required'),
  site: Yup.string().required('Site is required'),
  building: Yup.string().required('Building is required'),
  floor: Yup.string().required('Floor is required'),
});

const validate = makeValidate(addKioskSchema);

const siteFilter = (s) => {
  if (!s.buildings?.count) return false;
  let hasFloors = false;
  s.buildings.edges.forEach(({ node }) => {
    if (node.floors?.count) hasFloors = true;
  });
  return hasFloors;
};
const AddKioskModal = ({ handleClose, handleConfirm }) => {
  const classes = useStyles();
  const [createKiosk] = useCreateKiosk();
  const { sites, refetch: refetchSites } = useSites({ query: FIND_NETWORK_PLACE_SITES_MAPPED });
  const { activeNetwork } = useContext(FlamingoContext);
  const networkName = activeNetwork?.humanName || 'Global Template';
  const networkLogo = activeNetwork?.logo?.url;

  const siteOptions = useMemo(
    () => sites
        .filter(siteFilter)
        .map((s) => ({ id: s.id, label: parseByLang(s.name) })),
    [sites],
  );

  const buildingOptions = useCallback(
    (siteId) => {
      if (!siteId) return [];
      const site = sites.find((s) => s.id === siteId);
      if (site) {
        return site.buildings.edges
          .filter(({ node }) => node.floors.count > 0)
          .map(({ node }) => ({ id: node.id, label: parseByLang(node.name) }));
      }
      return [];
    },
    [sites],
  );

  const floorOptions = useCallback(
    (siteId, buildingId) => {
      if (!siteId || !buildingId) return [];
      const site = sites.find((s) => s.id === siteId);
      if (site) {
        const building = site.buildings.edges.find(
          ({ node }) => node.id === buildingId,
        );
        if (building) {
          return building.node.floors.edges.map(({ node }) => ({
            id: node.id,
            label: parseByLang(node.name),
          }));
        }
        return [];
      }
      return [];
    },
    [sites],
  );

  const onSubmit = async (values) => {
    const response = await createKiosk({
      variables: {
        input: {
          name: values.name,
          site: values.site,
          building: values.building,
          floor: values.floor,
          banner: {
            interval: DEFAULT_KIOSK_BANNER_INTERVAL,
          },
        },
      },
    });

    handleConfirm(response?.data?.createKiosk);
  };

  useEffect(() => {
    refetchSites();
  }, [refetchSites]);

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          name: '',
          site: '',
          building: '',
          floor: '',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      content={({ form, values }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Kiosk
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Kiosk
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <FinalTextField
              label={getFormLabel('Name', true)}
              name="name"
              variant="outlined"
              autoComplete="off"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <Field name="site">
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={siteOptions}
                  label={'Site'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
            <OnChange name="site">
              {(value, previous) => {
                if (previous && value !== previous) {
                  form.change('building', undefined);
                  form.change('floor', undefined);
                }
              }}
            </OnChange>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <Field name="building">
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={buildingOptions(values.site)}
                  label={'Building'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
            <OnChange name="building">
              {(value, previous) => {
                if (previous && value !== previous) {
                  form.change('floor', undefined);
                }
              }}
            </OnChange>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px', marginBottom: '9px' }}>
            <Field name="floor">
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={floorOptions(values.site, values.building)}
                  label={'Floor'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
      confirmText="Add Kiosk"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddKioskModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

AddKioskModal.defaultProps = {};

export default React.memo(AddKioskModal);
