import { gql } from '@apollo/client';

export const CREATE_NETWORK_PROMOTION = gql`
  mutation($input: NetworkPromotionInputCreate!) {
    createNetworkPromotion(input: $input) {
      id
    }
  }
`;

export const CREATE_NETWORK_PROMOTION_ITEM = gql`
  mutation($input: NetworkPromotionItemInputCreate!) {
    createNetworkPromotionItem(input: $input) {
      id
      type
      item {
        ... on NetworkPlace {
          id
        }
      }
    }
  }
`;

export const DELETE_NETWORK_PROMOTION_ITEM = gql`
  mutation($id: ID!) {
    deleteNetworkPromotionItem(id: $id) {
      id
      type
      item {
        ... on NetworkPlace {
          id
        }
      }
    }
  }
`;

export const FIND_NETWORK_PROMOTION_ITEMS = gql`
  query findNetworkPromotionItem {
    findNetworkPromotionItem {
      count
      edges {
        node {
          id
          item {
            ... on NetworkPlace {
              id
              name
              designation
              isMapped
            }
          }
          includeAllChildren
          createdBy {
            profile {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_PROMOTIONS = gql`
  query findNetworkPromotion {
    findNetworkPromotion {
      count
      edges {
        node {
          id
          sequenceNumber
          description
          createdBy {
            profile {
              firstName
              lastName
            }
          }
          createdAt
          errors {
            type
            description
          }
        }
      }
    }
  }
`;
