import { Box, Grid, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import ImageUploader from 'components/imageUploader/imageUploader';
import RichTextField from 'components/richTextField/richTextField';
import { getFormLabel } from 'helpers/form-util';
import ScreensaverPreview from 'pages/kiosks/containers/template/ScreensaverPreview';
import React from 'react';
import { Field } from 'react-final-form';

const TemplateScreensaver = ({ form }) => {
  const values = form.getState().values;
  return (
    <Box sx={{ paddingBottom: '24px' }}>
      <ScreensaverPreview
        color={values.screensaverBackgroundColor}
        url={values.screensaverLogo?.url}
        cta={values.screensaverCta}
      />
      <Typography
        variant="subtitle1"
        sx={{ marginTop: '40px', marginBottom: '16px' }}
      >
        Screensaver Preferences
      </Typography>
      {/* so we will get dirty flag on change */}
      <Field name="screensaverLogo">{() => null}</Field>
      <ImageUploader
        title={getFormLabel('Network Logo', true)}
        image={values.screensaverLogo}
        onImageUpdated={(file) => {
          form.change('screensaverLogo', {
            url: file.url,
            file: file.file,
            name: file.name,
          });
        }}
        onImageDeleted={() => {
          form.change('screensaverLogo', null);
        }}
        showImage={true}
        fileInfo="Supported files includes jpeg and png. Image dimensions must be 430px by 430px."
        minHeight={430}
        maxHeight={430}
        minWidth={430}
        maxWidth={430}
        previewHeight={118}
        previewWidth={118}
        maxFileSize={Infinity}
      />

      <Grid spacing={3} container sx={{ marginTop: '36px' }}>
        <Grid item xs={6}>
          <RichTextField name="screensaverCta" label={'Screensaver CTA'} />
        </Grid>
        <Grid item xs={5}>
          <Field name="screensaverBackgroundColor">
            {({ input, meta }) => (
              <ColorOptions
                initialColor={input.value}
                getColor={input.onChange}
                label={'Screen Background Color'}
                required={true}
                formControl={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateScreensaver;
