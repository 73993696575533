import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TakeoverHeader from 'components/takeoverHeader/takeoverHeader';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  headerInner: {
    height: '140px',
    background: theme.palette.blue[400],
  },
  itemType: {
    color: theme.palette.white,
    marginTop: '16px',
  },
  linkAlias: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    borderRadius: '8px',
    display: 'inline-block',
    height: '40px',
    marginLeft: '12px',
    padding: '8px 12px',
    verticalAlign: 'middle',
  },
});

const DeepLinkHeader = ({ config, handleBack, title, values }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={styles.root}>
      <Box sx={styles.headerInner}>
        <TakeoverHeader
          onBack={handleBack}
          backText="Back to Link Manager"
          showTitle={false}
          subtitle={title}
          itemType={
            <Box sx={styles.itemType}>
              {config?.shortUrlDomain}/
              <Box sx={styles.linkAlias}>{values.linkAlias}</Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

DeepLinkHeader.propTypes = {
  config: PropTypes.shape({
    shortUrlDomain: PropTypes.string,
  }).isRequired,
  handleBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object,
};

DeepLinkHeader.defaultProps = {
  values: {},
};

export default DeepLinkHeader;
