import { useTheme } from '@mui/material/styles';
import { ErrorFallback } from 'components/errorFallback/errorFallback';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const buildStyles = ({ theme }) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
});

const onError = (error, info) => {
  console.error('Unexpected error: ', error);
  console.error('Unexpected error info: ', info);
  if (process.env.REACT_APP_LOG_ROCKET) {
    LogRocket.captureException(error, {
      extra: {
        info: info,
      },
    });
  }
};

export const StaticLayout = ({ children }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <main style={styles.content}>
      <ErrorBoundary fallbackRender={ErrorFallback} onError={onError}>
        {children}
      </ErrorBoundary>
    </main>
  );
};

StaticLayout.propTypes = {
  children: PropTypes.any,
};

export default StaticLayout;
