import createDecorator from 'final-form-submit-listener';
import { makeValidate } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import TileDrawer from 'pages/kiosks/containers/tileDrawer';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Tile Name is required'),
  tileColor: Yup.string()
    .nullable()
    .matches(/^#[a-fA-F0-9]{6}$/, {
      excludeEmptyString: false,
      message: 'Must be a valid hex color',
    }),
  type: Yup.string().nullable(),
});

const validate = makeValidate(schema);

const EditTile = React.memo(
  ({ currentTile, closeTileDrawer, onSubmit, pois }) => {
    const submitListener = useMemo(
      () => createDecorator({
          afterSubmitSucceeded: (formApi) => {
            formApi.reset(formApi.getState().values);
          },
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    return (
      <Form
        subscription={{ submitting: true, invalid: true, dirty: true }}
        decorators={[submitListener]}
        initialValues={{
          name: currentTile?.name || '',
          tileIcon: currentTile?.icon,
          tileColor: currentTile?.backgroundColor || ColorPalette.grey[300],
          tileType: currentTile?.type,
          title: currentTile?.title,
          description: currentTile?.description || '',
          selectedPOIs: (currentTile?.poiItems || [])
            .map((p) => {
              const poi = pois.find((poi) => poi.id === p.poi?.id);
              if (poi) {
                return { order: p.order, ...poi };
              }
              return null;
            })
            .filter((item) => item),
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <TileDrawer
              closeTileDrawer={closeTileDrawer}
              pois={pois}
              currentTile={currentTile}
              {...formProps}
            />
          </form>
        )}
      </Form>
    );
  },
);

export default EditTile;
