import { ApolloClient, InMemoryCache } from '@apollo/client';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import _ from 'lodash';
import LogRocket from 'logrocket';

export const UNAUTHENTICATED = 'UNAUTHENTICATED';

const toErrorResult = (errorResult) => {
  if (_.isString(errorResult)) {
    return {
      message: errorResult,
    };
  }
  const { networkError, graphQLErrors } = errorResult;
  let errMsg;
  const uniqueErrors = [];
  if (networkError && networkError.result && networkError.result.errors) {
    // dedup
    networkError.result.errors.forEach((el) => {
      if (uniqueErrors.indexOf(el.message, uniqueErrors) === -1) uniqueErrors.push(el.message);
    });
    errMsg = uniqueErrors.join(' ');
  } else if (networkError && networkError.result) {
    errMsg = networkError.result.message;
  } else if (networkError) {
    errMsg = networkError.message;
  } else if (graphQLErrors) {
    // dedup
    graphQLErrors.forEach((el) => {
      if (uniqueErrors.indexOf(el.message, uniqueErrors) === -1) uniqueErrors.push(el.message);
    });
    errMsg = uniqueErrors.join(' ');
  }
  return {
    ...errorResult.response,
    message: errMsg,
  };
};

export const fetchApollo = (params) => {
  const {
    method,
    dispatch,
    type,
    queryName,
    doc,
    urlParam,
    variables = {},
  } = params;
  const key = JSON.stringify(params.params);

  dispatch({
    type,
    key,
  });

  let responded = false;
  const headers = params.headers || {};
  const token = localStorage.getItem('token') || '';
  headers.Authorization = `Bearer ${token}`;
  if (!headers.network) {
    headers.network = params?.params?.networkId || 'Global Template';
  }
  if (!headers.lang) {
    headers.lang = params?.params?.lang || '*';
  }
  if (!headers.workspace) {
    headers.workspace = params?.params?.workspace || LIVE_WORKSPACE;
  }

  let uri = `${process.env.REACT_APP_GRAPH_API_URL}`;
  if (urlParam) {
    uri = `${uri}?${urlParam}`;
  }
  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    headers,
    connectToDevTools: false,
    name: 'Flamingo-legacy',
    version: '1.0',
  });
  return new Promise((resolve, reject) => {
    switch (method) {
      case 'query':
        resolve(
          client.query({
            query: doc,
            variables: variables,
          }),
        );
        break;
      case 'mutate':
        resolve(
          client.mutate({
            mutation: doc,
            variables: variables,
            errorPolicy: 'all',
          }),
        );
        break;
      default:
        reject({
          message: `method ${method} not supported`,
        });
    }
  })
    .then((response) => {
      if (responded) {
        return;
      }

      if (response.errors) {
        return Promise.reject(response);
      }
      const { data } = response;
      const result = data && data[queryName];

      responded = true;
      dispatch({
        type: `${type}_SUCCESS`,
        result,
        uri,
        key,
        method,
        params: params.params,
        remoteObject: params.remoteObject,
      });
      return result;
    })
    .catch((errorResult) => {
      const errObj = {};

      if (errorResult.hasOwnProperty('errors')) {
        errObj.graphQLErrors = errorResult.errors;
        errObj.response = errorResult;
      } else {
        errObj.graphQLErrors = errorResult.graphQLErrors;
        errObj.networkError = errorResult.networkError;
      }
      if (responded) {
        return;
      }

      responded = true;

      LogRocket.captureException(errorResult, {
        tags: {
          fetchApi: 'Apollo',
          statusCode: errorResult.networkError
            ? errorResult.networkError.statusCode
            : null,
        },
      });

      if (!!errObj.networkError && errObj.networkError.statusCode === 401) dispatch({
          type: 'DESTROY_SESSION_ERROR',
        });

      dispatch({
        type: `${type}_ERROR`,
        result: toErrorResult(errObj),
        uri,
        key,
        method,
        params: params.params,
        remoteObject: params.remoteObject,
      });
      return Promise.reject(errObj);
    });
};

export const fetchApi = (params) => {
  params.headers = params.headers || {};

  const { url, method, dispatch, type, headers, host } = params;

  dispatch({
    type,
  });

  return new Promise((resolve, reject) => {
    let body = params.body;
    if (headers['Content-Type'] === 'application/json') {
      body = params.body ? JSON.stringify(params.body) : null;
    }
    const token = localStorage.getItem('token') || '';
    headers.Authorization = `Bearer ${token}`;

    resolve(
      fetch(`${host || process.env.REACT_APP_API_URL}${url}`, {
        method,
        headers,
        body,
      }),
    );
  })
    .then((response) => {
      if (response.status === 401 && type === 'DESTROY_SESSION') {
        // destroy session short circuit
        dispatch({
          type: 'DESTROY_SESSION_ERROR',
        });
      }
      if (response.status === 401) {
        dispatch({
          type: UNAUTHENTICATED,
        });
      } else if (response.status >= 400) {
        return response.json().then((result) => {
          const error = {
            result,
            params: params.params,
            status: response.status,
          };
          dispatch({
            type: `${type}_ERROR`,
            ...error,
          });
          return Promise.resolve(error);
        });
      } else {
        return response
          .text()
          .then((text) => {
            if (text) {
              return JSON.parse(text);
            }
            return text;
          })
          .then((result) => {
            dispatch({
              type: `${type}_SUCCESS`,
              result,
              url,
              method,
              params: params.params,
            });
            return Promise.resolve(result);
          });
      }
    })
    .catch((err) => {
      console.log('err', err);
      LogRocket.captureException(err, { tags: { fetchApi: 'rest' } });

      const result = {
        ...err,
        message: err.message ? err.message : `${err}`,
      };
      const error = {
        result,
        params: params.params,
        status: 503,
      };
      dispatch({
        type: `${type}_ERROR`,
        ...error,
      });
      return Promise.resolve(error);
    });
};
