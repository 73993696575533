import { useMutation } from '@apollo/client';
import { getLabelByLang } from 'helpers/lang-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useMemo } from 'react';
import { useCustomQuery } from 'hooks/dataHooks/useCustomQuery';
import {
  FIND_NETWORK_PLACE,
  GET_NETWORK_PLACE,
  UPDATE_NETWORK_PLACE,
} from 'graphql/queries';

export const useUpdateNetworkPlace = (
  gql = UPDATE_NETWORK_PLACE,
  showNotification = true,
) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Network Place');

  return useMutation(gql, {
    //refetchQueries: [GET_NETWORK_PLACE],
    onCompleted: (data) => {
      if (showNotification) {
        const name = getLabelByLang(data?.updateNetworkPlace?.name);
        toastNotificationSuccessHook(
          `Your progress on the ${
            data?.updateNetworkPlace?.designation ?? 'location'
          } ${getLabelByLang(name)} has been saved.`,
        );
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, null, null, {
        statusHandlers: {
          409: ({ extensions }) => {
            if (!['geoLocation.address.street'].includes(extensions.debug.path)) return true;
            return false;
          },
        },
      }),
  });
};

const useNetworkPlace = ({ id, gql }) => useCustomQuery(gql || GET_NETWORK_PLACE, {
  dataFn: useMemo(() => (data) => data?.getNetworkPlace, []),
  initialState: {},
  skip: !id,
  skipFn: useMemo(() => (data) => data?.id !== undefined, []),
  useQueryOptions: {
    variables: {
      id,
    },
  },
});

export const useNetworkPlaces = ({ variables={} }) => useCustomQuery(FIND_NETWORK_PLACE, {
  dataFn: useMemo(() => (data) => data?.findNetworkPlace, []),
  initialState: [],
  skipFn: useMemo(() => (data) => data?.edges?.length > 0, []),
  suspense: true,
  useQueryOptions: {
    variables: {
      ...variables,
    },
  },
});

export default useNetworkPlace;
