import { AddPhotoAlternate, Clear, Info, KeyboardArrowDown, KeyboardArrowUp, LocationOn } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundColor: theme.palette.grey[400],
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  waitTimeRoot: {
    maxHeight: '118px',
  },
  baseTextLeft: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '50px',
    width: '50px',
    marginLeft: theme.spacing(2),
  },
  nonExpandableIcon: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '50px',
    width: '50px',
    marginLeft: theme.spacing(1),
  },
  waitTimeTitle: {
    marginTop: '6px',
    marginBottom: '4px',
    textAlign: 'center',
  },
  waitTimeText: {
    background: colorWithAlpha(theme.palette.grey[600], 0.5),
    width: '60px',
    height: '60px',
    marginLeft: theme.spacing(1.5),
    paddingTop: '14px',
    borderRadius: '50% !important',
    textAlign: 'center',
    paddingRight: '0 !important',
  },
  waitTimeUnitText: {
    fontSize: '11px',
    paddingRight: '0 !important',
  },
  expandableIcon: {
    height: '50px',
    width: '50px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.6),
  },
  actionArea: {
    textAlign: 'left',
    paddingTop: '36px',
    paddingLeft: '12px',
    width: '48px',
    height: '100%',
  },
  actionAreaWaitTimeOffset: {
    paddingTop: '48px',
  },
  expandedActionArea: {
    backgroundColor: theme.palette.grey[500],
    textAlign: 'left',
    paddingTop: '48px',
    paddingLeft: '12px',
    width: '48px',
    height: '100%',
  },
  actionIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  small: { height: '118px' },
  medium: { height: '175px' },
  large: { height: '214px' },
  content: {
    height: '90%',
  },
  expandableContent: {
    marginLeft: 0,
  },
});

export const SpecialtyCard = ({
                                backgroundColor,
                                backgroundImageUrl,
                                cardIndex,
                                clickHandler,
                                disabled,
                                iconUrl,
                                iconText,
                                incompleteForPackaging,
                                isExpandable,
                                isTemporary,
                                overline,
                                selected,
                                size,
                                subtitle,
                                textColor = ColorPalette.white,
                                textAlignment,
                                title,
                                waitTimeInMinutes,
                                waitTimeLabel,
                              }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [isExpanded] = useState(false);
  const hasWaitTime = !!waitTimeLabel;

  const canBeExpanded = isExpandable && cardIndex === 0;
  const isLeftAlign = textAlignment === 'left';

  const backgroundStyle = {};
  if (!isExpandable && backgroundImageUrl) {
    backgroundStyle.backgroundImage = `url(${backgroundImageUrl})`;
  } else {
    backgroundStyle.backgroundColor = backgroundColor;
  }

  return (
    <Grid
      alignItems="center"
      spacing={0}
      container
      direction="row"
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...styles[size],
        ...hasWaitTime && styles.waitTimeRoot,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        ...backgroundStyle,
        color: textColor,
      }}
    >
      {incompleteForPackaging && (
        <Box sx={styles.incompleteWarning}>
          <Info />
        </Box>
      )}
      {isExpandable && (
        <Grid item xs={3}>
          {hasWaitTime && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="caption" sx={styles.waitTimeTitle}>
                {waitTimeLabel}
              </Typography>
              <Box sx={styles.waitTimeText}>
                <Typography variant="h1" sx={{ lineHeight: 0.75 }}>
                  {waitTimeInMinutes}
                </Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  MINS
                </Typography>
              </Box>
              <Typography variant="caption">{iconText}</Typography>
            </Box>
          )}
          {!hasWaitTime && (
            <Box sx={{ textAlign: 'center' }}>
              {iconUrl && (
                <Box
                  sx={{
                    ...styles.icon,
                    backgroundImage: `url(${iconUrl})`,
                  }}
                />
              )}
              {!iconUrl && <LocationOn sx={styles.expandableIcon} />}
              <Typography variant="caption" display="block">
                {iconText}
              </Typography>
            </Box>
          )}
        </Grid>
      )}
      {!isExpandable && (
        <Grid item xs={3}>
          {size !== 'extraSmall'
            && (iconUrl ? (
              <Box
                sx={{
                  ...styles.nonExpandableIcon,
                  backgroundImage: `url(${iconUrl})`,
                }}
              />
            ) : (
              <AddPhotoAlternate sx={styles.nonExpandableIcon} />
            ))}
        </Grid>
      )}
      <Grid
        container
        item
        xs={isExpandable || isTemporary ? 7 : 10}
        direction="column"
        justifyContent="center"
        alignItems={isLeftAlign ? 'flex-start' : 'center'}
        sx={{
          ...styles.content,
          ...isExpandable && styles.expandableContent,
        }}
      >
        {!!overline && (
          <Typography
            variant="body1"
            sx={{ ...isLeftAlign && styles.baseTextLeft, color: textColor }}
          >
            {overline}
          </Typography>
        )}
        {!!title && (
          <Typography
            variant="h2"
            sx={{
              ...isLeftAlign && styles.baseTextLeft,
              ...styles.title,
              color: textColor,
            }}
          >
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body1"
            sx={{ ...isLeftAlign && styles.baseTextLeft, color: textColor }}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>
      {canBeExpanded && (
        <Grid
          item
          xs={1}
          sx={{
            ...isExpanded ? styles.expandedActionArea : styles.actionArea,
            ...hasWaitTime && styles.actionAreaWaitTimeOffset,
          }}
        >
          {isExpanded && <KeyboardArrowUp sx={styles.actionIcon} />}
          {!isExpanded && <KeyboardArrowDown sx={styles.actionIcon} />}
        </Grid>
      )}
      {isExpandable && cardIndex > 0 && (
        <Grid item xs={1} sx={styles.expandedActionArea}></Grid>
      )}
      {isTemporary && (
        <Grid item xs={1} sx={styles.actionArea}>
          <Clear sx={styles.actionIcon} />
        </Grid>
      )}
    </Grid>
  );
};

SpecialtyCard.propTypes = {
  cardIndex: PropTypes.number,
  backgroundColor: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  iconUrl: PropTypes.string,
  iconText: PropTypes.string,
  incompleteForPackaging: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isTemporary: PropTypes.bool,
  onExpandableClicked: PropTypes.func,
  overline: PropTypes.string,
  selected: PropTypes.bool,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  subtitle: PropTypes.string,
  textAlignment: PropTypes.oneOf(['left', 'center']),
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  waitTimeInMinutes: PropTypes.number,
  waitTimeLabel: PropTypes.string,
};

SpecialtyCard.defaultProps = {
  cardIndex: 0,
  clickHandler: () => {},
  disabled: false,
  iconText: '',
  incompleteForPackaging: false,
  isExpandable: false,
  isTemporary: false,
  onExpandableClicked: null,
  selected: false,
  textAlignment: 'left',
  title: 'Card title',
  waitTimeInMinutes: 30,
};

export default SpecialtyCard;
