import { Add, AddCircle, Delete, Edit, Warning } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptySection from 'components/emptySection/emptySection';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import Select from 'components/selector/selector';
import GozioTable from 'components/tables/gozioTable';
import dayjs from 'dayjs';
import { convertDateToString, isPastDate } from 'helpers/date-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import {
  DEFAULT_CUSTOM_HOURS,
  EXCEPTION_TYPE_TO_LABEL_MAP,
  HOURS_OF_OPERATIONS,
} from 'pages/locations/containers/locationsFormHelper';
import AddOrEditHoursExceptionModal from 'pages/locations/containers/sections/AddOrEditHoursExceptionModal';
import CustomHours from 'pages/locations/containers/sections/customHours';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Exception Name',
    accessor: 'name',
    width: 350,
  },
  {
    Header: 'Date(s)',
    accessor: 'date',
    minWidth: 220,
    width: 220,
  },
  {
    Header: 'Operating Schedule',
    accessor: 'operatingSchedule',
    disableSortBy: true,
    minWidth: 170,
    width: 170,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    minWidth: 66,
    width: 66,
    disableSortBy: true,
    justifyRight: true,
  },
];

const buildStyles = ({ theme }) => ({
  select: { width: '405px' },
  addItemBtn: {
    float: 'right',
    top: '-20px',
  },
  selectedType: {
    fontWeight: 700,
  },
  selectedIcon: {
    color: theme.palette.blue[400],
    paddingLeft: '311px',
  },
  warningContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  warningIcon: {
    color: theme.palette.warning.light,
    fontSize: '24px',
    marginRight: '4px',
  },
});

const Hours = ({ form, values }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  useField('customHours');
  useField('exceptionHours');
  const [customHours, setCustomHours] = useState([...DEFAULT_CUSTOM_HOURS]);
  const [exceptionHours, setExceptionHours] = useState([]);
  const [showExceptionModal, setShowExceptionModal] = useState(false);
  const [selectedExceptionIndex, setSelectedExceptionIndex] = useState(null);

  useEffect(() => {
    setCustomHours(
      values?.customHours?.length
        ? values.customHours
        : [...DEFAULT_CUSTOM_HOURS],
    );
  }, [values, setCustomHours]);

  useEffect(() => {
    setExceptionHours(
      values?.exceptionHours?.length ? values.exceptionHours : [],
    );
  }, [values, setExceptionHours]);

  const handleCustomHoursChanges = (updatedData) => {
    setCustomHours(updatedData);
    form.change('customHours', updatedData);
  };

  const designation = useMemo(() => values.designation === 'poi'
    ? 'POI'
    : capitalize(values.designation), [values.designation]);

  const expiredExceptionWarning = useMemo(
    () => (
      <Box sx={styles.warningContainer}>
        <Warning sx={styles.warningIcon} />
        <Typography variant="body2">This exception has expired.</Typography>
      </Box>
    ),
    [styles],
  );

  const buildDateRange = useCallback(
    ({ startDate, endDate }) => {
      const start = startDate ? dayjs(startDate) : null;
      const end = endDate ? dayjs(endDate) : null;
      if (start && end) {
        if (isPastDate(end.toDate())) {
          return expiredExceptionWarning;
        }

        if (start.isSame(end)) {
          return convertDateToString(startDate, 'MMM D, YYYY');
        }

        return `${convertDateToString(
          startDate,
          'MMM D',
        )} - ${convertDateToString(endDate, 'MMM D, YYYY')}`;
      }

      if (start) {
        if (isPastDate(start.toDate())) {
          return expiredExceptionWarning;
        }

        return convertDateToString(startDate, 'MMM D, YYYY');
      }

      return '';
    },
    [expiredExceptionWarning],
  );

  const handleDeleteException = useCallback(
    (index) => {
      const updatedExceptionHours = [...exceptionHours];
      updatedExceptionHours.splice(index, 1);
      setExceptionHours(updatedExceptionHours);
      form.change('exceptionHours', updatedExceptionHours);
    },
    [exceptionHours, form],
  );

  const exceptionsData = useMemo(
    () => exceptionHours?.map((exceptionHour, index) => ({
      id: exceptionHour.id,
      name: getLabelByLang(exceptionHour.name),
      date: buildDateRange(exceptionHour),
      operatingSchedule:
        EXCEPTION_TYPE_TO_LABEL_MAP[exceptionHour.type] ?? '',
      actions: (
        <Box className="hoverUnhide">
          <Tooltip title="Edit">
            <Edit
              onClick={() => {
                setSelectedExceptionIndex(index);
                setShowExceptionModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Delete
              onClick={() => {
                handleDeleteException(index);
              }}
            />
          </Tooltip>
        </Box>
      ),
    })),
    [buildDateRange, exceptionHours, handleDeleteException],
  );

  const handleExceptionModalClosed = () => {
    setSelectedExceptionIndex(null);
    setShowExceptionModal(false);
  };

  const handleExceptionSaved = (exception, index) => {
    let updatedExceptionHours = [...exceptionHours];
    if (index === null) {
      updatedExceptionHours = updatedExceptionHours.concat(exception);
    } else {
      updatedExceptionHours[index] = exception;
    }
    form.change('exceptionHours', updatedExceptionHours);
    setExceptionHours(updatedExceptionHours);

    handleExceptionModalClosed();
  };

  return (
    <>
      <FormSubSection name="Operating Schedule">
        <Grid item xs={6}>
          <Field
            sx={styles.select}
            component={Select}
            name="hoursType"
            label="Hours of Operation"
            required={true}
            disableClearable={true}
            sorted={false}
            options={HOURS_OF_OPERATIONS}
          />
        </Grid>
        <Condition when="hoursType" is="custom">
          <CustomHours
            sx={{
              marginTop: '40px',
            }}
            data={customHours}
            onChange={handleCustomHoursChanges}
            title="Custom Weekly Hours"
          />
        </Condition>
      </FormSubSection>
      <Condition when="hoursType" not="none">
        <FormSubSection
          name="Exceptions"
          headingContent={
            exceptionHours.length > 0 ? (
              <IconButton
                sx={styles.addItemBtn}
                color="primary"
                aria-label="Add Exception"
                onClick={() => setShowExceptionModal(true)}
                size="large"
              >
                <AddCircle sx={{ fontSize: '38px' }} />
              </IconButton>
            ) : null
          }
        >
          <Grid item xs={12}>
            {exceptionHours.length === 0 && (
              <EmptySection
                title={`This ${designation} Has No Exceptions`}
                buttonLabel="Add Exception"
                hasPermission={true}
                ButtonIcon={Add}
                buttonClicked={() => setShowExceptionModal(true)}
              />
            )}
            {exceptionHours.length > 0 && (
              <GozioTable
                sx={{
                  width: '100%',
                  padding: 0,
                }}
                name="ExceptionsTable"
                columns={COLUMNS}
                data={exceptionsData}
                sortBy={[{ id: 'name', desc: false }]}
              />
            )}
          </Grid>
        </FormSubSection>
      </Condition>

      {showExceptionModal && (
        <AddOrEditHoursExceptionModal
          existingExceptions={exceptionHours}
          handleClose={handleExceptionModalClosed}
          handleExceptionSaved={handleExceptionSaved}
          index={selectedExceptionIndex}
        />
      )}
    </>
  );
};

Hours.propTypes = {
  form: PropTypes.object,
  values: PropTypes.object,
};

Hours.defaultProps = {
  form: {},
  values: {},
};

export default Hours;
