import Asterisk from 'components/asterisk/asterisk';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import React from 'react';
import * as Yup from 'yup';

export const parseByLang = (i18nArray = [], lang = LANGUAGE_CODES.ENGLISH) => Array.isArray(i18nArray)
    ? i18nArray.find((i) => i.lang === lang)?.label
    : null;

export const getFormLabel = (name, required = false, spanish = false) => (
  <>
    {required && name && <Asterisk />}
    {` ${name}`}
    {spanish && ' (Spanish)'}
  </>
);

export const formatLang = (name, values) => {
  const i18nArray = [];
  i18nArray.push({
    lang: LANGUAGE_CODES.ENGLISH,
    label: values[`${name}`] || '',
  });
  if (values.langES && !!values[`${name}ES`]) i18nArray.push({
      lang: LANGUAGE_CODES.SPANISH,
      label: values[`${name}ES`] || '',
    });
  return i18nArray;
};

export const convertMetersToFeet = (value, decimalPlaces = 2) => isNaN(value) ? value : (value * 3.2808).toFixed(decimalPlaces);

export const convertFeetToMeters = (value, decimalPlaces = 2) => isNaN(value) ? value : (value / 3.2808).toFixed(decimalPlaces);

export const isDirtyOrHasErrors = (form) => {
  const { dirty, errors } = form.getState();
  return dirty || Object.keys(errors).length;
};

export const getExternalAppFormValidationSchema = {
  externalURL: Yup.string()
    .nullable()
    .test('externalURLTest', 'Invalid fallback URL', function(value) {
      const { createError, parent, path } = this;
      if (parent.linkType !== 'external_app') {
        return true;
      }
      if (!parent.hasPlatformSpecificFallbacks && !value) {
        return createError({
          path,
          message: 'Fallback URL is required.',
        });
      }
      if (value && !value.match(/^\w+:(\/?\/?)[^\s]+/i)) {
        return createError({
          path,
          message: 'URI must be valid',
        });
      }
      return true;
    }),
  externalType: Yup.string()
    .nullable()
    .when(['linkType', 'hasPlatformSpecificFallbacks'], {
      is: (linkType, hasPlatformSpecificFallbacks) => linkType === 'external_app' && hasPlatformSpecificFallbacks,
      then: (schema) => schema.required('Please select an External App preference.'),
    }),
  externaliOSURL: Yup.string()
    .nullable()
    .when('linkType', {
      is: 'external_app',
      then: (schema) => schema
          .required('iOS URL is required.')
          .matches(/^\w+:(\/?\/?)[^\s]+/i, {
            message: 'URI must be valid',
            excludeEmptyString: true,
          }),
    }),
  externalAndroidURL: Yup.string()
    .nullable()
    .when('linkType', {
      is: 'external_app',
      then: (schema) => schema
          .required('Android URL is required.')
          .matches(/^\w+:(\/?\/?)[^\s]+/i, {
            message: 'URI must be valid',
            excludeEmptyString: true,
          }),
    }),
  fallbackiOSURL: Yup.string()
    .nullable()
    .when(['linkType', 'hasPlatformSpecificFallbacks'], {
      is: (linkType, hasPlatformSpecificFallbacks) => linkType === 'external_app' && hasPlatformSpecificFallbacks,
      then: (schema) => schema
          .required('iOS URL Fallback is required.')
          .matches(/^\w+:(\/?\/?)[^\s]+/i, {
            message: 'URI must be valid',
            excludeEmptyString: true,
          }),
    }),
  fallbackAndroidURL: Yup.string()
    .nullable()
    .when(['linkType', 'hasPlatformSpecificFallbacks'], {
      is: (linkType, hasPlatformSpecificFallbacks) => linkType === 'external_app' && hasPlatformSpecificFallbacks,
      then: (schema) => schema
          .required('Android URL Fallback is required.')
          .matches(/^\w+:(\/?\/?)[^\s]+/i, {
            message: 'URI must be valid',
            excludeEmptyString: true,
          }),
    }),
  hasPlatformSpecificFallbacks: Yup.bool(),
};

export const getWebsiteValidationSchema = {
  webUrlEn: Yup.string().when('linkType', {
    is: 'website',
    then: (schema) => schema
        .url('URL must include http or https, e.g. https://www.goziohealth.com')
        .required('Website URL is required.'),
  }),
  webUrlEs: Yup.string()
    .nullable()
    .when('linkType', {
      is: 'website',
      then: (schema) => schema.url(
          'URL must include http or https, e.g. https://www.goziohealth.com',
        ),
    }),
  webType: Yup.string().when('linkType', {
    is: 'website',
    then: (schema) => schema.required('Selection is required'),
  }),
};
