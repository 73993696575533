import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import FlamingoAppBar from 'components/navigation/flamingoAppBar';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    background: theme.palette.grey[50],
    flexGrow: 1,
    height: 'calc(100vh - 70px)',
    overflow: 'auto',
    padding: theme.spacing(3),
    position: 'relative',
  },
  openSideBar: {
    marginLeft: '250px',
    width: 'calc(100vw - 251px)',
  },
}));

export const LoggedOutLayout = ({ className, children }) => {
  const classes = useStyles();

  return (
    <>
      <FlamingoAppBar isAuthenticated={false} />
      <Container
        component="main"
        disableGutters
        maxWidth={false}
        className={clsx(classes.content, classes.openSideBar, className)}
      >
        {children}
      </Container>
    </>
  );
};

LoggedOutLayout.propTypes = {
  className: PropTypes.string,
};

LoggedOutLayout.defaultProps = {
  className: '',
};

export default LoggedOutLayout;
