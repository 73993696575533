import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  title: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  infoIconContainer: {
    float: 'right',
    position: 'relative',
    height: '24px',
  },
  infoIcon: {
    color: colorWithAlpha(theme.palette.black, 0.54),
    width: '24px',
    height: '24px',
  },
});

const FormSubSection = ({
                          id,
                          name,
                          headingContent,
                          children,
                          sx,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={{ paddingBottom: '40px', ...sx }}>
      <Box>
        <Typography sx={styles.title} variant="subtitle1" id={id}>
          {name ?? ''}
        </Typography>
        {!!headingContent && headingContent}
      </Box>
      {children}
    </Box>
  );
};

FormSubSection.propTypes = {
  name: PropTypes.node,
  children: PropTypes.any,
  headingContent: PropTypes.object,
  id: PropTypes.string,
  sx: PropTypes.object,
};

FormSubSection.defaultProps = {
  sx: {},
};

export default FormSubSection;
