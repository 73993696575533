import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from 'components/dashboard/cardComponent/card';
import SpecialtyCard from 'components/dashboard/cardComponent/specialtyCard';
import { dndHover, isCardIncompleteForPackaging } from 'components/dashboard/cardLayouts/cardhelper';
import { colorWithAlpha } from 'helpers/color-util';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  topExpandedCard: {
    borderBottom: `1px solid ${colorWithAlpha(theme.palette.black, 0.75)}`,

    '& div': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  middleExpandedCard: {
    borderBottom: `1px solid ${colorWithAlpha(theme.palette.black, 0.75)}`,
    '& div': {
      borderRadius: 0,
    },
  },
  bottomExpandedCard: {
    paddingBottom: theme.spacing(0.5),
    '& div': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const OneCardLayout = ({
                         id,
                         index,
                         card1,
                         card2,
                         card3,
                         card4,
                         size,
                         preview,
                         hovered,
                         moveCard,
                         onCardClick,
                         onHover,
                         onDrag,
                         canDragAndDrop,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [isExpanded, setIsExpanded] = useState(false);

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
        card2,
        card3,
        card4,
        size,
      },
      cardType: OneCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  let cardSize = 'small';
  let cardTextAlignment = null;
  switch (size) {
    case 'short':
      cardTextAlignment = card1.textAlignment || 'left';
      cardSize = 'small';
      break;
    case 'medium':
      cardTextAlignment = card1.textAlignment || 'center';
      cardSize = 'medium';
      break;
    case 'tall':
      cardTextAlignment = card1.textAlignment || 'center';
      cardSize = 'large';
      break;
    default:
      break;
  }

  const allCards = [];
  if (card1) allCards.push(card1);
  if (card2) allCards.push(card2);
  if (card3) allCards.push(card3);
  if (card4) allCards.push(card4);

  const onExpandableClicked = (isExpandedableClicked) => {
    setIsExpanded(isExpandedableClicked);
  };

  const renderSpecialCards = () => {
    const cards = isExpanded ? allCards : [card1];
    const isExpandable = cards[0].isExpandable;
    return cards
      .map((card, i) => {
        if (card) {
          let cardStyles = styles.cardGroup;
          if (isExpanded) {
            if (i === 0) {
              cardStyles = styles.topExpandedCard;
            } else if (i + 1 === cards.length) {
              cardStyles = styles.bottomExpandedCard;
            } else {
              cardStyles = styles.middleExpandedCard;
            }
          }

          const isTemporary = card.dismissable || !!card.expires;

          return (
            <Tooltip
              key={`ct_${i}`}
              title={
                preview && !hovered
                  ? isTemporary
                    ? 'Temporary card'
                    : card.waitTimeLabel
                      ? 'Expandable card with wait times'
                      : 'Expandable card'
                  : ''
              }
              placement="bottom"
            >
              <Grid
                key={`sc_${i}`}
                item
                xs={12}
                sx={{
                  ...cardStyles,
                  ...hovered && styles.fade,
                  ...isDragging && styles.dragging,
                }}
              >
                <SpecialtyCard
                  cardIndex={i}
                  size={cardSize}
                  title={card.title}
                  subtitle={card.subtitle}
                  overline={card.overline}
                  backgroundImageUrl={card.backgroundImageUrl}
                  backgroundColor={card.backgroundColor}
                  clickHandler={() => onCardClick({
                      cardGroupId: id,
                      key: 'card1',
                      size: 'small',
                      ...card1,
                    })
                  }
                  iconUrl={card.iconUrl}
                  iconText={card.iconText}
                  incompleteForPackaging={isCardIncompleteForPackaging(card1)}
                  textAlignment={card.textAlignment || 'left'}
                  textColor={card.textColor}
                  waitTimeLabel={card.waitTimeLabel}
                  isExpandable={isExpandable}
                  isTemporary={isTemporary}
                  onExpandableClicked={onExpandableClicked}
                  disabled={card.disabled}
                  selected={card.selected}
                />
              </Grid>
            </Tooltip>
          );
        }
        return null;
      })
      .filter((c) => c);
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      ref={ref}
      sx={{
        ...styles.cardGroup,
        ...isDragging && styles.dragging,
        ...preview && styles.preview,
        opacity,
      }}
    >
      {card1.isExpandable || card1.dismissable || !!card1.expires
        ? renderSpecialCards()
       : (
        <Tooltip title={preview && !hovered ? 'Single card' : ''}>
          <Grid item xs sx={styles.card}>
            <Card
              standalone={true}
              size={cardSize}
              title={card1.title}
              subtitle={card1.subtitle}
              overline={card1.overline}
              backgroundImageUrl={card1.backgroundImageUrl}
              backgroundColor={card1.backgroundColor}
              iconUrl={card1.iconUrl}
              incompleteForPackaging={isCardIncompleteForPackaging(card1)}
              textAlignment={cardTextAlignment}
              textColor={card1.textColor}
              disabled={card1.disabled}
              selected={card1.selected}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card1',
                  size: cardSize,
                  ...card1,
                })
              }
            />
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
};

OneCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  size: PropTypes.oneOf(['short', 'medium', 'tall']).isRequired,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    waitTimeInMinutes: PropTypes.number,
    waitTimeLabel: PropTypes.string,
    isExpandable: PropTypes.bool,
    dismissable: PropTypes.bool,
    expires: PropTypes.any,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textAlignment: PropTypes.oneOf(['left', 'center']),
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card2: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    waitTimeInMinutes: PropTypes.number,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card3: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    waitTimeInMinutes: PropTypes.number,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card4: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    waitTimeInMinutes: PropTypes.number,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

OneCardLayout.defaultProps = {
  card1: {
    incompleteForPackaging: false,
    title: 'Card title',
    clickHandler: () => {},
  },
  preview: false,
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default OneCardLayout;
