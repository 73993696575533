import { Info } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    overflow: 'hidden',
    padding: '30px 0 0 16px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.white}`,
    display: 'flex',
    height: '72px',
    marginTop: '-30px',
  },
  text: {
    alignSelf: 'center',
    display: '-webkit-box',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    width: '100%',
  },
  linkContainer: {
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.white}`,
    height: '50px',

    '& p': {
      paddingLeft: '18px',
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  placeholderIcon: {
    width: '22px',
    height: '22px',

    '& img, svg': {
      width: '22px',
      height: '22px',
    },
  },
  icon: {
    width: '22px',
    height: '22px',

    '& img': {
      width: '22px',
      height: '22px',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
});

const renderIcon = ({ iconUrl, iconFile }, styles, linkBackgroundColor) => {
  const icon = iconUrl ?? iconFile;

  if (icon) {
    return (
      <Box sx={{ ...styles.icon, background: linkBackgroundColor }}>
        <img src={icon} alt="Quicklink Icon" />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.placeholderIcon, background: linkBackgroundColor }}>
      <AddPhotoAlternateIcon />
    </Box>
  );
};

export const QuicklinkListQuicklinksCard = ({
                                              backgroundColor,
                                              clickHandler,
                                              disabled,
                                              incompleteForPackaging,
                                              quickLinksData,
                                              selected,
                                              textColor,
                                              title,
                                            }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  const linkBackgroundColor
    = quickLinksData?.length > 0 ? quickLinksData[0].iconTint : backgroundColor;

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box sx={{ ...styles.incompleteWarning, left: `${width - 54}px` }}>
        <Info />
      </Box>
    </Box>
  );

  /**
   * @type {React.ReactNode}
   */
  const quicklinks = useMemo(() => {
    if (!quickLinksData?.length) {
      return (
        <Grid
          container
          sx={{
            ...styles.linkContainer,
            '& p': {
              color: textColor,
            },
          }}
        >
          <Grid item xs={1}>
            {renderIcon({}, styles, linkBackgroundColor)}
          </Grid>
          <Grid item xs={11} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" noWrap>
              {' '}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    const max = Math.min(quickLinksData?.length, 12);
    const links = [];
    for (let i = 0; i < max; i++) {
      links.push(
        <Grid
          key={`qli_${i}`}
          container
          sx={{
            ...styles.linkContainer,
            '& p': {
              color: textColor,
            },
          }}
        >
          <Grid item xs={1}>
            {renderIcon(quickLinksData[i], styles)}
          </Grid>
          <Grid item xs={11} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" noWrap>
              {getLabelByLang(quickLinksData[i].name)}
            </Typography>
          </Grid>
        </Grid>,
      );
    }
    return links;
  }, [styles, linkBackgroundColor, quickLinksData, textColor]);

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={12} sx={styles.titleContainer}>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.text, color: textColor }}
        >
          {title}
        </Typography>
      </Grid>
      {quicklinks}
    </Grid>
  );
};

QuicklinkListQuicklinksCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  quickLinksData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      iconUrl: PropTypes.string,
      iconTint: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.object,
    }),
  ),
  selected: PropTypes.bool,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

QuicklinkListQuicklinksCard.defaultProps = {
  disabled: false,
  incompleteForPackaging: false,
  quickLinksData: [],
  selected: false,
};

export default QuicklinkListQuicklinksCard;
