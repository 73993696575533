import { Grid, Typography } from '@mui/material';
import FormSubSection from 'components/forms/formSubSection';
import MultiSelectWithChips from 'components/multiSelectWithChips/multiSelectWithChips';
import { getFormLabel } from 'helpers/form-util';
import { TextField as FinalTextField } from 'mui-rff';
import React from 'react';
import { Field } from 'react-final-form';

export const DEEP_LINK_ANALYTICS_TAGS_SCHEMA = {};

export const buildAnalyticsTagsInitialValues = ({ config, data }) => ({
  analyticsTags: data?.analytics?.tags,
  analyticsFeature: data?.analytics?.feature,
  analyticsChannel: data?.analytics?.channel,
  analyticsCampaign: data?.analytics?.campaign,
});

export const buildAnalyticsTagsUpdateInput = ({ values }) => ({
  analytics: {
    campaign: values.analyticsCampaign,
    channel: values.analyticsChannel,
    feature: values.analyticsFeature,
    tags: values.analyticsTags,
  },
});

const AnalyticsTagsSection = () => (
  <FormSubSection name="Analytics Tags">
    <Grid container direction="row" spacing={4.5}>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <Typography variant="body1">
          We suggest incorporating analytic tags capable of filtering and
          comparing the effectiveness of different links. To add, type and
          separate by comma.
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <Field
          name="analyticsTags"
          component={MultiSelectWithChips}
          label="Tags"
          helperMessage={null}
        />
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <FinalTextField
          label={getFormLabel('Feature')}
          name="analyticsFeature"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <FinalTextField
          label={getFormLabel('Channel')}
          name="analyticsChannel"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <FinalTextField
          label={getFormLabel('Campaign')}
          name="analyticsCampaign"
          variant="outlined"
        />
      </Grid>
    </Grid>
  </FormSubSection>
);

export default AnalyticsTagsSection;
