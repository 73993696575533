import { Grid } from '@mui/material';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import Select from 'components/selector/selector';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import ExtraInfo from 'pages/locations/containers/sections/extraInstructions/extraInformational';
import React from 'react';
import { Field } from 'react-final-form';

const InstructOptions = [
  {
    label: 'None',
    id: 'none',
  },
  {
    label: 'Informational',
    id: 'info',
  },
  {
    label: 'Optional Reroute',
    id: 'rerouteOption',
  },
  {
    label: 'Mandatory Reroute With Message',
    id: 'rerouteMessage',
  },
  {
    label: 'Mandatory Reroute Without Message',
    id: 'rerouteMandatory',
  },
];

const ExtraInstructions = ({ form, data, values }) => {
  const { network, parentSite } = data;
  return (
    <>
      <FormSubSection
        name="Navigation Instructions"
        sx={{ position: 'relative' }}
      >
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <Field
              component={Select}
              name="extraDirective"
              label="Navigation Instruction Type"
              required={true}
              disableClearable={true}
              options={
                data.parentBuilding
                  ? InstructOptions
                  : InstructOptions.slice(0, 2)
              }
            />
          </Grid>
        </Grid>
      </FormSubSection>

      <Condition when="extraDirective" not={'none'}>
        <ExtraInfo
          form={form}
          values={values}
          poiId={data.id}
          network={network}
          parent={parentSite}
        />
      </Condition>

      <FormSubSection name="Disclaimer">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={values.langES ? 6 : 12}>
            <Field name="extraDisclaimer">
              {({ input, meta }) => (
                <TextField
                  label="Message Field"
                  charLimit={300}
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  {...input}
                  fullWidth
                  multiline
                  rows="5"
                />
              )}
            </Field>
          </Grid>
          <Condition when="langES" is={true}>
            <Grid item xs={6}>
              <Field name="extraDisclaimerES">
                {({ input, meta }) => (
                  <TextField
                    label={getFormLabel('Message Field', false, true)}
                    charLimit={300}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    {...input}
                    fullWidth
                    multiline
                    rows="5"
                  />
                )}
              </Field>
            </Grid>
          </Condition>
        </Grid>
      </FormSubSection>
    </>
  );
};

export default React.memo(ExtraInstructions);
