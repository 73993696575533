import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkPlace, useSites } from 'hooks/dataHooks/useSites';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
  bodyText: {
    color: theme.palette.grey[600],
    display: 'inline-block',
    fontWeight: 'normal',
  },
  fieldRow: {
    marginTop: '24px',
  },
}));

const addUnmappedPoiSchema = Yup.object().shape({
  site: Yup.string().required('Site is required'),
  building: Yup.string()
    .nullable()
    .when('radioSelection', {
      is: 'building',
      then: (schema) => schema.required('Building is required'),
    }),
  poiName: Yup.string().required('POI name is required'),
  radioSelection: Yup.string().oneOf(['site', 'building']),
});

const validate = makeValidate(addUnmappedPoiSchema);

const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const buildSiteOptions = (list, unmappedOnly = true) => list
    .map((item) => {
      if (unmappedOnly) {
        if (item.isMapped) {
          return null;
        }
      } else if (!item.buildings?.count) {
        return null;
      }
      return {
        id: item.id,
        label: `${getLabelByLang(item.name)} (${
          item.isMapped ? 'Mapped' : 'Unmapped'
        })`,
      };
    })
    .filter((i) => i)
    .sort(nameSort);

const buildUnmappedBuildingOptions = (list) => list.edges
    .map(({ node }) => {
      if (node.isMapped) {
        return null;
      }
      return {
        id: node.id,
        label: `${getLabelByLang(node.name)} (Unmapped)`,
      };
    })
    .filter((i) => i)
    .sort(nameSort);

const AddUnmappedPoiModal = ({
                               handleClose,
                               handleConfirm,
                               networkName,
                               networkLogo,
                             }) => {
  const classes = useStyles();
  const isUberAdmin = useCheckGozioAdmin();
  const [createPoi] = useCreateNetworkPlace('poi');
  const { refetch: refetchSites, sites } = useSites({
    workspace: isUberAdmin ? '*' : undefined,
  });
  const unmappedSiteOptions = buildSiteOptions(sites);
  const hasUnmappedSites = unmappedSiteOptions.length > 0;
  const hasUnmappedBuildings = !!sites?.find((site) => site.buildings?.count);

  const getUnmappedBuildingOptions = (siteId) => {
    const site = sites.find((s) => s.id === siteId);
    return site ? buildUnmappedBuildingOptions(site.buildings) : [];
  };

  const onSubmit = async (values) => {
    const response = await createPoi({
      variables: {
        input: {
          name: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.poiName }],
          designation: 'poi',
          inheritAddress: true,
          contact: {
            hours: {
              type: 'none',
            },
          },
        },
        meta: {
          parent:
            values.radioSelection === 'site' ? values.site : values.building,
        },
      },
    });
    handleConfirm(response?.data?.createNetworkPlace);
  };

  useEffect(() => {
    refetchSites();
  }, [refetchSites]);

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          site: null,
          building: null,
          poiName: '',
          radioSelection: hasUnmappedSites ? 'site' : 'building',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      content={({ form }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Unmapped POI
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Unmapped POI
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '40px' }}>
            <Asterisk />{' '}
            <Typography className={classes.bodyText} variant="body1">
              This POI’s Parent location is the
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              onChange={(e) => {
                form.change('radioSelection', e.target.value);
              }}
              row={true}
              value={form.getState()?.values?.radioSelection}
            >
              <FormControlLabel
                key={'site'}
                control={<Radio value="site" color="primary" />}
                label="Site"
                disabled={!hasUnmappedSites}
              />
              <FormControlLabel
                key={'building'}
                control={<Radio value="building" color="primary" />}
                label="Building"
                disabled={!hasUnmappedBuildings}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} className={classes.fieldRow}>
            <Field name="site" subscription={{ value: true }}>
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={unmappedSiteOptions}
                  label={'Parent Site Selection'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
          </Grid>
          <Condition when="radioSelection" is="building">
            <Grid item xs={12} className={classes.fieldRow}>
              <Field name="building">
                {({ input, meta }) => (
                  <Autocomplete
                    input={input}
                    meta={meta}
                    options={getUnmappedBuildingOptions(
                      form.getState()?.values?.site,
                    )}
                    label={'Parent Building Selection'}
                    required={true}
                    fullWidth
                    disableClearable={true}
                  />
                )}
              </Field>
            </Grid>
          </Condition>
          <Grid item xs={12} className={classes.fieldRow}>
            <FinalTextField
              label={getFormLabel('POI Name', true)}
              name="poiName"
              variant="outlined"
              autoComplete="off"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddUnmappedPoiModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  networkName: PropTypes.string.isRequired,
  networkLogo: PropTypes.string,
};

AddUnmappedPoiModal.defaultProps = {
  networkLogo: null,
};

export default React.memo(AddUnmappedPoiModal);
