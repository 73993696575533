import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    borderRadius: '8px',
    height: '326px',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '25px',
    textAlign: 'center',
    margin: `${theme.spacing(1)} 0`,
  },
  button: {
    margin: `${theme.spacing(1)} 0`,
  },
});

const EmptySection = ({
                        sx,
                        title,
                        buttonLabel,
                        buttonClicked,
                        ButtonIcon,
                        hasPermission,
                      }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Grid
      container
      key={title}
      justifyContent="center"
      sx={{ ...styles.root, ...sx }}
    >
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {!!title && (
          <Typography variant="subtitle1" sx={styles.title}>
            {title}
          </Typography>
        )}
        {!!buttonLabel && !!buttonClicked && hasPermission && (
          <Button
            variant="outlined"
            color="primary"
            sx={styles.button}
            onClick={buttonClicked}
            startIcon={ButtonIcon ? <ButtonIcon /> : null}
          >
            {buttonLabel}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

EmptySection.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonClicked: PropTypes.func,
  ButtonIcon: PropTypes.object,
  hasPermission: PropTypes.bool,
};

EmptySection.defaultProps = {
  ButtonIcon: null,
  sx: {},
  hasPermission: false,
};

export default EmptySection;
