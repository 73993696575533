import { Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OpenPlatformImage from 'assets/open-platform.png';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[500],
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: '148px',
    overflow: 'hidden',
    padding: '24px 24px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  text: {
    color: theme.palette.white,
    display: '-webkit-box',
    marginLeft: '40px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  image: {
    width: '100px',
    height: '100px',

    '& img': {
      width: '100px',
      height: '100px',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
});

export const OpenPlatformCard = ({
                                   clickHandler,
                                   disabled,
                                   incompleteForPackaging,
                                   selected,
                                   cardName,
                                 }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box sx={{ ...styles.incompleteWarning, left: `${width - 54}px` }}>
        <Info />
      </Box>
    </Box>
  );

  return (
    <Grid
      ref={ref}
      container
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
      }}
      onClick={clickHandler}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={12} sx={styles.titleContainer}>
        <Box sx={styles.image}>
          <img src={OpenPlatformImage} alt="Open Platform" />
        </Box>
        <Typography variant="subtitle1" sx={styles.text}>
          {cardName}
        </Typography>
      </Grid>
    </Grid>
  );
};

OpenPlatformCard.propTypes = {
  cardName: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  selected: PropTypes.bool,
};

OpenPlatformCard.defaultProps = {
  cardName: 'Card Title',
  disabled: false,
  incompleteForPackaging: false,
  selected: false,
};

export default OpenPlatformCard;
