import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import AutoComplete from 'components/autocomplete/autoComplete';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const AddCategoryGroupModel = ({
                                 languages,
                                 networkName,
                                 networkLogo,
                                 type = 'group',
                                 handleSave,
                                 handleClose,
                                 updating = false,
                                 groups = [],
                               }) => {
  const classes = useStyles();
  const groupOptions = useMemo(
    () => groups
        .map((c) => ({ id: c.id, label: parseByLang(c.name) }))
        .sort(nameSort),
    [groups],
  );

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          name: Yup.string().required('Name is required'),
          nameES: Yup.string().required('Name (Spanish) is required'),
          parentId: Yup.string().when('type', {
            is: 'category',
            then: (schema) => schema.required('Category Group is required'),
          }),
        },
        languages,
        { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  return (
    <TakeOverModal
      formParams={{
        onSubmit: handleSave,
        initialValues: {
          type,
          langES: true,
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      content={({ form, values }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Category{type === 'group' ? ' Group' : ''}
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Category
              {type === 'group' ? ' Group' : ''}
            </Typography>
          </Grid>

          {type === 'category' && (
            <Grid item xs={12} sx={{ marginTop: '34px' }}>
              <Field name="parentId">
                {({ input, meta }) => (
                  <AutoComplete
                    input={input}
                    meta={meta}
                    options={groupOptions}
                    label={'Category Group'}
                    required={true}
                    fullWidth
                    disableClearable={true}
                  />
                )}
              </Field>
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <TextField
              label={getFormLabel(
                `Category${type === 'group' ? ' Group' : ''} Name`,
                true,
              )}
              name="name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <TextField
              label={getFormLabel(
                `Category${type === 'group' ? ' Group' : ''} Name`,
                networkName === 'Global Template',
                true,
              )}
              name="nameES"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      greyOutCancel={updating}
      greyOutConfirm={updating}
      confirmText={'Save & Add'}
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddCategoryGroupModel.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddCategoryGroupModel;
