import { Box, Grid, Typography } from '@mui/material';
import AnalyticsDashboardCard, {
  ANALYTICS_DASHBOARD_CHART_TYPES,
} from 'components/analyticsDashboardCard/analyticsDashboardCard';
import { FlamingoContext } from 'contexts/flamingo';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ANALYTICS_FILTER_MAP } from './analyticsFilters';
import AnalyticsDownloadButton from './downloadButton';

const DEFAULT_CARD_WIDTH = 400;

const AnalyticsOverviewCards = ({
                                  data,
                                  dateRange,
                                  dateRangeKey,
                                  dateRangeText,
                                  networkId,
                                }) => {
  const { handleNavigate } = useContext(FlamingoContext);

  const {
    activeUsersData,
    appUsageData,
    deviceTypesData,
    navigationEventsData,
    newAppUsersData,
    sessionsData,
  } = data;

  return (
    <Grid
      alignContent="flex-start"
      alignItems="flex-start"
      container
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item>
        <AnalyticsDashboardCard
          dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.AREA}
          count={newAppUsersData?.total_new_users}
          title="Mobile Installations"
          tooltip={
            <Box>
              A “mobile installation” counts when a mobile device downloads the
              app and an anonymous ID token is registered on Gozio’s servers.
              This anonymous ID is used throughout the Insights platform to
              represent a “User.” It is possible that a person has multiple
              devices. In this case they would register as a “User” for each
              device.
            </Box>
          }
          chartHeading={`${newAppUsersData?.percent_new}%`}
          chartText="Mobile Installations Over Product Lifetime"
          dataKey="sum"
          data={newAppUsersData?.rollingData ?? null}
          onClick={() => handleNavigate(
              `/network/${networkId}/analytics/mobile-installations`,
            )
          }
        />
      </Grid>
      <Grid item>
        <AnalyticsDashboardCard
          dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.PIE}
          colors={[ColorPalette.chart.blue, ColorPalette.chart.green]}
          count={activeUsersData?.total_active_users}
          title="Total Users"
          tooltip="New Users used the app for the first time within the date range shown. Returning Users used the app at least once prior to the date range shown."
          nameKey="name"
          dataKey="value"
          data={
            activeUsersData
              ? [
                {
                  name: 'New Users',
                  value: activeUsersData.total_new_users,
                },
                {
                  name: 'Returning Users',
                  value: activeUsersData.total_established_users,
                },
              ]
              : null
          }
          onClick={() => handleNavigate(`/network/${networkId}/analytics/total-users`)
          }
        />
      </Grid>
      <Grid item>
        <AnalyticsDashboardCard
          dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.AREA}
          count={sessionsData?.cumulative_total_sessions}
          title="Sessions"
          tooltip="“Sessions” is a count of how many times all Users open and use the app in the date range shown. Any app activity separated by at least ten minutes is considered a new Session."
          chartHeading={`${sessionsData?.average_session_length_display} minutes`}
          chartText="Average Session Length"
          dataKey="sum"
          data={sessionsData?.rollingData ?? null}
          onClick={() => handleNavigate(`/network/${networkId}/analytics/sessions`)
          }
        />
      </Grid>
      <Grid item>
        <AnalyticsDashboardCard
          dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.PIE}
          colors={[
            ColorPalette.chart.blue,
            ColorPalette.chart.green,
            ColorPalette.chart.yellow,
          ]}
          count={navigationEventsData?.totalSessions}
          title="Navigation Events"
          tooltip="Navigation Events are categorized in three types: Indoor, Outdoor, or Mixed. “Indoor” utilizes Gozio’s indoor-wayfinding only, “Outdoor” utilizes third-party navigation only, and “Mixed” uses a combination of Gozio indoor-wayfinding and third-party navigation. This report shows the number of sessions that fall within each Navigation Event type."
          nameKey="name"
          dataKey="value"
          data={
            navigationEventsData
              ? [
                {
                  name: 'Mixed',
                  value: navigationEventsData.totalMixedSessions,
                },
                {
                  name: 'Outdoor',
                  value: navigationEventsData.totalOutdoorsSessions,
                },
                {
                  name: 'Indoor',
                  value: navigationEventsData.totalIndoorsSessions,
                },
              ]
              : null
          }
          onClick={() => handleNavigate(`/network/${networkId}/analytics/navigation-events`)
          }
        />
      </Grid>
      <Grid item>
        <AnalyticsDashboardCard
          dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.PIE}
          colors={[ColorPalette.chart.blue, ColorPalette.chart.green]}
          title="Device Platform"
          tooltip="Device Platforms are broken down by the two major operating systems seen in mobile application usage: iOS and Android. This report shows the percentage make up of each operating system for your mobile platform."
          nameKey="name"
          dataKey="value"
          dataLabel="%"
          data={
            deviceTypesData
              ? [
                {
                  name: 'Android',
                  value: Number(
                    Number(deviceTypesData.androidPercent).toFixed(1),
                  ),
                },
                {
                  name: 'iOS',
                  value: Number(
                    Number(deviceTypesData.iosPercent).toFixed(1),
                  ),
                },
              ]
              : null
          }
        />
      </Grid>
      {(!appUsageData?.bottomNavigation?.items
        || (appUsageData?.bottomNavigation?.items
          && appUsageData?.bottomNavigation?.items.length > 0)) && (
        <Grid item>
          <AnalyticsDashboardCard
            width={DEFAULT_CARD_WIDTH}
            chartType={ANALYTICS_DASHBOARD_CHART_TYPES.PIE}
            colors={[
              ColorPalette.chart.blue,
              ColorPalette.chart.green,
              ColorPalette.chart.yellow,
              ColorPalette.chart.red,
              ColorPalette.chart.purple,
            ]}
            count={appUsageData?.totalBottomNavigationClickEvents}
            dataKey="value"
            data={
              appUsageData?.bottomNavigation?.items
                ? appUsageData?.bottomNavigation?.items
                  .filter((item, i) => i < 5)
                  .map(({ name, clicks }) => ({
                    name,
                    value: Number(clicks),
                  }))
                : null
            }
            dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
            nameKey="name"
            renderLegend={({ data, classes, colors }) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    rowGap: '16px',
                    marginBottom: '10px',
                  }}
                >
                  {data.map(({ name }, index) => (
                    <Box
                      key={`leg-${index}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '33.33%',
                      }}
                    >
                      <Box
                        className={classes.dot}
                        sx={{
                          backgroundColor:
                            colors?.[index] ?? ColorPalette.blue[400],
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '80%',
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <AnalyticsDownloadButton
                    dataType="BottomTabClick"
                    endpoint="usage"
                    fileNameDetail="bottom_nav_tab_usage"
                    networkId={networkId}
                    range={{
                      start: dateRange?.start,
                      end: dateRange?.end,
                    }}
                    showIcon={false}
                  />
                </Box>
              </>
            )}
            title="Bottom Navigation Usage"
            tooltip="Bottom Navigation Usage tracks user interactions on the sticky, quick-access buttons at the bottom of your mobile application, showing which buttons are clicked most frequently."
          />
        </Grid>
      )}
      {(!appUsageData?.dashboardCards?.items
        || (appUsageData?.dashboardCards?.items
          && appUsageData?.dashboardCards?.items.length > 0)) && (
        <Grid item>
          <AnalyticsDashboardCard
            width={DEFAULT_CARD_WIDTH}
            chartType={ANALYTICS_DASHBOARD_CHART_TYPES.TABLE}
            data={
              appUsageData?.dashboardCards?.items
                ? appUsageData?.dashboardCards?.items.filter((item, i) => i < 4)
                : null
            }
            dataKey="clicks"
            dataLabel="name"
            dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
            itemLabel="Card Name"
            onClick={() => handleNavigate(
                `/network/${networkId}/analytics/dashboard-card-usage`,
              )
            }
            title="Dashboard Card Usage"
            tooltip="Dashboard Card Usage tracks user interaction with Dashboard Cards on your mobile application's landing page, revealing which cards are clicked most over time."
          />
        </Grid>
      )}
      {(!appUsageData?.moreMenu?.items
        || (appUsageData?.moreMenu?.items
          && appUsageData?.moreMenu?.items.length > 0)) && (
        <Grid item>
          <AnalyticsDashboardCard
            width={DEFAULT_CARD_WIDTH}
            chartType={ANALYTICS_DASHBOARD_CHART_TYPES.TABLE}
            data={
              appUsageData?.moreMenu?.items
                ? appUsageData?.moreMenu?.items.filter((item, i) => i < 4)
                : null
            }
            dataKey="clicks"
            dataLabel="name"
            dateRangeLabel={dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
            itemLabel="Menu Item"
            onClick={() => handleNavigate(`/network/${networkId}/analytics/more-menu-usage`)
            }
            title="More Menu Usage"
            tooltip={`More Menu Usage tracks user interactions with the "More" quick access button's Menu Items within your mobile application, revealing which items are clicked most over time.`}
          />
        </Grid>
      )}
      {/* <Grid item>
        <AnalyticsDashboardCard
          width={DEFAULT_CARD_WIDTH}
          chartType={ANALYTICS_DASHBOARD_CHART_TYPES.TABLE}
          data={
            appUsageData?.banner?.items
            ? appUsageData?.banner?.items.filter((item, i) => i < 4)
            : null
          }
          dataKey="clicks"
          dataLabel="name"
          dateRangeLabel={
            dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]
          }
          onClick={() => {}}
          title="Banner Message Usage"
          tooltip="SCM-3808 does not provide tooltip text for this item"
        />
      </Grid> */}
    </Grid>
  );
};

AnalyticsOverviewCards.propTypes = {
  data: PropTypes.object,
  dateRangeKey: PropTypes.string,
  dateRangeText: PropTypes.string,
  networkId: PropTypes.string,
  dateRange: PropTypes.object,
};

AnalyticsOverviewCards.defaultProps = {
  data: {},
};
export default React.memo(AnalyticsOverviewCards);
