import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeValidate } from 'mui-rff';
import { Field } from 'react-final-form';
import GenericFormModal from 'components/genericModal/genericFormModal';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang } from 'helpers/lang-util';
import GozioSelect from 'components/selector/selector';
import { useMoveSingleListLocationSection } from 'hooks/dataHooks/useLists';

const addListSchema = Yup.object().shape({
	sectionId: Yup.string().required('Section is required'),
});

const validate = makeValidate(addListSchema);

const MoveLocationModal = ({
	handleClose,
	handleSaved,
	list,
	location,
}) => {
	const currentSection = list.sections
		.filter((section) => section.locations
			.map((location) => location.id)
				.includes(location.id))[0];

	const [moveSingleLocationSection] = useMoveSingleListLocationSection({
    listId: list.id,
		successMessage: `The location "${getLabelByLang(location.name)}" has been moved.`,
	});

	const handleMoveLocation = async ({ sectionId }) => {
		const targetSection = list.sections.filter((section) => section.id === sectionId)[0];
		const currentSectionLocationIds = currentSection.locations.map((l) => l.id).filter((id) => id !== location.id);
		const targetSectionLocationIds = targetSection.locations.map((l) => l.id).concat(location.id);

		await moveSingleLocationSection({
			variables: {
				currentSectionId: currentSection.id,
				targetSectionId: sectionId,
				currentSectionData: {
					locations: currentSectionLocationIds,
				},
				targetSectionData: {
					locations: targetSectionLocationIds,
				},
			},
		});
		handleSaved();
	};

	return (
		<GenericFormModal
			fullWidth
			maxWidth={'sm'}
			title={null}
			formParams={{
				onSubmit: handleMoveLocation,
				validate,
				initialValues: {
					sectionId: currentSection.id,
				},
			}}
			body={
				<Grid container direction="column">
					<Grid item xs={12}>
						<Typography variant="h1">
							Move Location
						</Typography>
						<Typography variant="subtitle1" sx={{ marginTop: '16px' }}>
							{`Where would you like to move location "${getLabelByLang(location.name)}" to?`}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '34px' }}>
						<Field name="sectionId">
							{({ input, meta }) => (
								<GozioSelect
									label={getFormLabel('Select Section', true)}
									options={list.sections.map((section) => ({ id: section.id, label: getLabelByLang(section.name) }))}
									input={input}
									meta={meta}
								/>
							)}
						</Field>
					</Grid>
				</Grid>
			}
			confirmText={'Move Location'}
			handleClose={handleClose}
		/>
	);
};

MoveLocationModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	handleSaved: PropTypes.func.isRequired,
	list: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

MoveLocationModal.defaultProps = {};

export default MoveLocationModal;
