import { Box } from '@mui/material';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { getTableCountTitle, LOCATIONS_TABLE_NAME } from 'helpers/table-util';
import React, { useMemo } from 'react';

const LOCATIONS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Location Name',
    accessor: 'name',
    minWidth: 170,
    width: 170,
    sticky: 'left',
  },
  {
    Header: 'Type',
    accessor: 'type',
    minWidth: 90,
    width: 90,
  },
  {
    Header: 'Site',
    accessor: 'site',
    minWidth: 170,
    width: 170,
  },
  {
    Header: 'Building',
    accessor: 'building',
    minWidth: 170,
    width: 170,
  },
  {
    Header: 'Floor',
    accessor: 'floor',
    minWidth: 170,
    width: 170,
  },
  {
    Header: 'network',
    accessor: 'network',
    minWidth: 170,
    width: 170,
  },
];

const LocationListing = ({ locations, networkId }) => {
  const processData = () => locations.map(({ node }) => ({
      id: node.id,
      name: capitalize(getLabelByLang(node.name)),
      type: capitalize(node.designation === 'poi' ? 'POI' : node.designation),
      site: getLabelByLang(node.parentSite?.name),
      building: getLabelByLang(node.parentBuilding?.name),
      floor: getLabelByLang(node.parentFloor?.name),
      network: node.network?.name,
    }));

  const memoizedData = useMemo(
    () => processData(),
    [locations], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const memoizedColumns = useMemo(() => {
    const columns = [...LOCATIONS_COLUMNS];
    if (!isGlobalTemplate(networkId)) {
      columns.pop();
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkId]);

  const count = memoizedData?.length || 0;
  if (count === 0) {
    return (
      <EmptyPanel title="You Have No Locations Attached to This Category" />
    );
  }
  return (
    <Box sx={{ height: '100%' }}>
      <GozioTable
        name={LOCATIONS_TABLE_NAME}
        columns={memoizedColumns}
        data={memoizedData}
        sortBy={[{ id: 'name', desc: false }]}
        sx={{ height: '100%', paddingTop: '8px' }}
        countTitle={`${getTableCountTitle(
          count,
          'Location',
        )} Attached To This Category`}
      />
    </Box>
  );
};
export default React.memo(LocationListing);
