import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import PageHelpers from 'helpers/pageHelpers';
import { useNetworkPlace } from 'hooks/dataHooks';
import filter from 'lodash/filter';
import { buildValidator, initialParser, submitParser } from 'pages/locations/containers/detail/buildingFormHelper';
import FloorsPanel from 'pages/locations/containers/detail/floorsPanel';
import DetailContainer from 'pages/locations/containers/detailContainer';
import ActionLinks from 'pages/locations/containers/sections/actionLinks';
import Address from 'pages/locations/containers/sections/address';
import Details from 'pages/locations/containers/sections/details';
import Hours from 'pages/locations/containers/sections/hours';
import MappingInputs from 'pages/locations/containers/sections/mappingInputs';
import MapVisibility from 'pages/locations/containers/sections/mapVisibility';
import Media from 'pages/locations/containers/sections/media';
import Searchability from 'pages/locations/containers/sections/searchability';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const sections = [
  {
    text: 'Mapping Inputs',
    hash: 'mapping',
    conditional: ({ hasPermissions, data }) => hasPermissions && data?.isMapped,
    component: MappingInputs,
  },
  {
    text: 'Building Details',
    hash: 'building-details',
    conditional: () => true,
    component: Details,
  },
  {
    text: 'Address',
    hash: 'address',
    conditional: () => true,
    component: Address,
  },
  {
    text: 'Media',
    hash: 'media',
    conditional: () => true,
    component: Media,
  },
  {
    text: 'Map Visibility',
    hash: 'mapVisibility',
    conditional: () => true,
    component: MapVisibility,
  },
  {
    text: 'Searchability',
    hash: 'searchability',
    conditional: () => true,
    component: Searchability,
  },
  {
    text: 'Quicklinks',
    hash: 'links',
    conditional: () => true,
    component: ActionLinks,
  },
  {
    text: 'Hours',
    hash: 'hours',
    conditional: () => true,
    component: Hours,
  },
];

const BuildingDetailPage = () => {
  const { networkId, buildingId } = useParams();
  const { data: place, error } = useNetworkPlace({ id: buildingId });

  const tabItems = [
    { key: 'building', title: 'Building Information' },
    {
      key: 'floors',
      title: 'Floors',
      mapFunc: ({ panelClass, isUberAdmin, data, networkId }) => ({
        sx: panelClass,
        networkId,
        isUberAdmin,
        building: data,
        floors: filter(data?.floors?.edges || []),
      }),
      Component: FloorsPanel,
    },
  ];

  if (place.isMapped === false) {
    tabItems.pop();
  }

  const buildBreadcrumb = () => {
    const { parentSite } = place;
    const parents = [];
    if (parentSite) parents.push({
        name: getLabelByLang(parentSite.name) || 'Site Name Unavailable',
        link: `/network/${networkId}/locations/site/${parentSite.id}`,
      });

    return { title: getLabelByLang(place?.name), parents };
  };

  if (error) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  if (isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  return (
    <PageHelpers showWorkspaceSwitcher={false}>
      <DetailContainer
        networkId={networkId}
        networkPlaceId={buildingId}
        sections={sections}
        initialParser={initialParser}
        submitParser={submitParser}
        validator={buildValidator}
        backText="Back to Site"
        data={place}
        backUrl={`/network/${networkId}/locations/site/${place?.parentSite?.id}?tab=buildings`}
        closeUrl={`/network/${networkId}/locations`}
        tabItems={tabItems}
        pageTitle={`${place?.network?.name}, ${getLabelByLang(
          place?.parentSite?.name,
        )}`}
        pageSubtitle={getLabelByLang(place?.name)}
        breadcrumb={buildBreadcrumb()}
      />
    </PageHelpers>
  );
};

export default BuildingDetailPage;
