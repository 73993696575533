import { Box, Button, Typography } from '@mui/material';
import ColorPalette from 'pages/gozio_colors';
import React from 'react';

const buildStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  backGround: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'center',
  },
  h1: {
    color: ColorPalette.blue[600],
    opacity: 0.1,
    fontSize: '15vw',
    fontWeight: '900',
  },
  dialogWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  textDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitleText: {
    marginBottom: '28px',
    textAlign: 'center',
  },
});

export const ErrorFallback = () => {
  const styles = buildStyles();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.backGround}>
        <Typography variant="h1" sx={styles.h1}>
          Ooops
        </Typography>
      </Box>
      <Box sx={styles.dialogWrap}>
        <Box sx={styles.textDialog}>
          <Typography variant="h1" className="hero">
            This is a bit embarrassing
          </Typography>
          <Box sx={styles.subtitleText}>
            <Typography variant="body1">
              We&apos;re sorry to see that you encountered an error.
              <br />
              Please let us know by contacting support.
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = '/';
            }}
            data-test="ErrorHome"
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
