import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_USER = gql`
  mutation createUser($input: UserCreateInput!, $meta: UserMetaInput) {
    createUser(input: $input, meta: $meta) {
      id
    }
  }
`;

export const FIND_USER = gql`
  ${fragments.USER_ATTRIBUTES}
  query {
    findUser {
      ...userAttributes
    }
  }
`;

export const GET_NETWORK_USERS = gql`
  ${fragments.USER_ATTRIBUTES}
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      users {
        ...userAttributes
      }
    }
    system {
      roles {
        name
        internal
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserUpdateInput, $meta: UserMetaInput) {
    updateUser(input: $input, meta: $meta, id: $id) {
      id
      email {
        address
      }
      roles {
        name
      }
      state
      profile {
        firstName
        lastName
      }
    }
  }
`;
