import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { capitalize, getLabelByLang } from 'helpers/lang-util';

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		fotnFamily: 'Poppins',
	},
	padding: {
		marginBottom: spacing(2),
		marginTop: spacing(2),
		'& li': {
			padding: 0,
		},
	},
}));

const generateListItems = (data) => {
	const designationMap = {
		buildings: 'Building',
		floors: 'Floor',
		pois: 'POI',
		landmarks: 'Landmark',
	};
	const locationChildren = [];
	for (const designation in data) if (data[designation]?.count > 0) locationChildren.push({
				designation: designationMap[designation],
				count: data[designation].count,
			});
	return locationChildren.map(({ count, designation }) => (
			<ListItem
				disableGutters
				key={`${count}_${designation}`}
			>
				<ListItemText>
					<Typography variant="subtitle1">{`${count} ${designation + (count > 1 ? 's' : '')}`}</Typography>
				</ListItemText>
			</ListItem>
		),
	);
};

const DeleteLocationChildrenList = ({
	locationData,
}) => {
	const classes = useStyles();
	const {
		buildings,
		floors,
		pois,
		landmarks,
		designation,
		name,
	} = locationData;
	return (
		<Box>
			<Typography variant="subtitle1">
				The following locations are attached to this {`${capitalize(designation)}`}.
			</Typography>
			<List classes={{ root: classes.padding }}>
				{generateListItems({ buildings, floors, pois, landmarks })}
			</List>
			<Typography variant="subtitle1">
				Are you sure you want to delete {`${getLabelByLang(name)}`} and all of its children?
			</Typography>
		</Box>
	);
};

DeleteLocationChildrenList.propTypes = {
	locationData: PropTypes.object,
};

DeleteLocationChildrenList.defaultProps = {};

export default DeleteLocationChildrenList;
