import { parseByLang } from 'helpers/form-util';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

export const kioskSchema = Yup.object().shape({
	name: Yup.string().required('Kiosk name is required'),
});

export const initialParser = (data) => ({
	name: data?.name,
	assignedId: data?.assignedId,
	siteName: parseByLang(data?.site?.name),
	buildingName: parseByLang(data?.building?.name),
	floorId: data?.floor?.id,
});

export const validate = makeValidate(kioskSchema);
