import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddTextIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainBackground from 'assets/kiosk-template-bg.png';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewPane: {
    height: '503px',
    width: '876px',
    padding: '12px',
    backgroundColor: theme.palette.grey[50],
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
  },
  previewInner: {
    width: '100%',
    height: '100%',
    border: '3px solid #FFFFFF',
    position: 'relative',
  },
  top: {
    width: '100%',
    height: '426px',
    background: `url("${MainBackground}") no-repeat center top`,
    backgroundSize: 'stretch',
  },
  footer: {
    width: '100%',
    height: '47px',
  },
  cta: {
    position: 'absolute',
    top: '230px',
    left: '8px',
    height: '77px',
    width: '97px',
    overflow: 'hidden',
    '& p': {
      color: theme.palette.grey[600],
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  ctaImage: {
    position: 'absolute',
    height: '106px',
    width: '100px',
    bottom: '48px',
    left: '6px',
  },
  ctaPlaceholder: {
    position: 'absolute',
    height: '106px',
    width: '68px',
    bottom: '48px',
    left: '23px',
    '& img': {
      height: '100%',
      width: '100%',
      backgroundSize: 'contain',
    },
  },
  footerGraphic: {
    position: 'absolute',
    height: '31px',
    width: '114px',
    bottom: '15px',
    right: '1px',
    '& img': {
      height: '100%',
      width: '100%',
      backgroundSize: 'stretch',
    },
  },
  searchPlaceholderWrapper: {
    position: 'absolute',
    height: '44px',
    width: '263px',
    top: '10px',
    right: '8px',
    backgroundColor: 'transparent',
  },
  searchBox: {
    position: 'absolute',
    height: '28px',
    width: '247px',
    top: '18px',
    right: '16px',
    borderRadius: '3.5px',
    border: '0.5px solid #bdbdbd',
    backgroundColor: theme.palette.white,
    padding: '3px 7px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',

    '& svg': {
      fontSize: '16px',
      color: theme.palette.grey[500],
      marginRight: '5px',
    },
    '& p': {
      color: theme.palette.grey[600],
      fontSize: '14px',
      lineHeight: 1.1,
      fontWeight: 400,
    },
  },
  poweredBy: {
    position: 'absolute',
    bottom: 0,
    right: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.grey[600],
  },
  copyright: {
    fontWeight: 700,
  },
  placeholderWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.grey[600],
    },
  },
});

const MainPreview = ({
                       color,
                       networkUrl,
                       ctaImageUrl,
                       cta,
                       searchPlaceholder,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const CtaBox = ({ cta }) => {
    if (cta && cta !== '') return <Typography>{cta}</Typography>;

    return (
      <Box sx={styles.placeholderWrapper}>
        <AddTextIcon />
      </Box>
    );
  };

  const CtaGraphic = ({ url }) => {
    if (url) return (
        <Box
          component="img"
          src={url}
          sx={styles.ctaImage}
          alt="Application Graphic"
        />
      );

    return (
      <Box sx={styles.ctaPlaceholder}>
        <Box sx={styles.placeholderWrapper}>
          <AddPhotoAlternateIcon />
        </Box>
      </Box>
    );
  };

  const FooterGraphic = ({ url }) => {
    if (url) return <img src={url} alt="Footer Graphic" />;

    return (
      <Box
        sx={{
          ...styles.placeholderWrapper,
          paddingLeft: '10px',
          justifyContent: 'flex-start',
        }}
      >
        <AddPhotoAlternateIcon />
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: '16px', width: '876px', padding: '0 16px' }}
      >
        Kiosk Preview
      </Typography>
      <Box sx={styles.previewPane}>
        <Box sx={styles.previewInner}>
          <Box sx={styles.top}>
            <Box sx={styles.searchBox}>
              <SearchIcon />
              <Typography>{searchPlaceholder}</Typography>
            </Box>
            {(!searchPlaceholder || searchPlaceholder.length === 0) && (
              <Box sx={styles.searchPlaceholderWrapper}>
                <Box sx={styles.placeholderWrapper}>
                  <AddTextIcon />
                </Box>
              </Box>
            )}
            <Box sx={styles.cta}>
              <CtaBox cta={cta} />
            </Box>
            <CtaGraphic url={ctaImageUrl} />
          </Box>
          <Box sx={{ ...styles.footer, backgroundColor: color || '#FFFFFF' }}>
            <Box sx={styles.footerGraphic}>
              <FooterGraphic url={networkUrl} />
            </Box>
            <Box sx={styles.poweredBy}>
              <Typography variant="subtitle2" sx={{ fontSize: '8px' }}>
                powered by GOZIO
              </Typography>
              <Typography variant="subtitle2" sx={styles.copyright}>
                &reg;
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MainPreview);
