import GozioTableWithVirtualization from 'components/tables/gozioTableWithVirtualization';
import { getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const processSubCategories = (categories = [], selectedIds) => categories
    .map((cat) => {
      const parentName = getLabelByLang(cat.name);
      return cat.children?.map((node) => {
        const catName = getLabelByLang(node.name);
        return {
          id: node.id,
          name: catName,
          parentName: parentName,
          searchIdx: catName.toLowerCase(),
          isSelected: !!selectedIds.find((selected) => selected.id === node.id),
        };
      });
    })
    .flat();

const filterData = (data, filter) => {
  const lowerFilter = filter.toLowerCase();
  return data.filter((item) => item.searchIdx.indexOf(lowerFilter) >= 0);
};
const CategoriesTable = ({
  visible,
  categories,
  handleCountUpdate,
  filter,
  handleRowSelectUpdates,
  selectedCategories,
}) => {
  const CATS_COLUMNS = useMemo(
    () => [
      {
        Header: 'Category Name',
        accessor: 'name',
      },
      {
        Header: 'Category Group Name',
        accessor: 'parentName',
      },
    ],
    [],
  );

  const fullData = useMemo(
    () => processSubCategories(categories, selectedCategories),
    [categories, selectedCategories],
  );

  const filteredData = useMemo(() => {
    const retData = filterData(fullData, filter);
    setTimeout(() => handleCountUpdate(retData.length), 50);
    return retData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullData, filter]);

  return visible ? (
    <GozioTableWithVirtualization
      columns={CATS_COLUMNS} // eslint-disable-line react-hooks/exhaustive-deps
      data={filteredData}
      sortBy={[{ id: 'name', desc: false }]}
      selectable={true}
      handleRowSelectUpdates={handleRowSelectUpdates}
    />
  ) : null;
};

CategoriesTable.propTypes = {
  visible: PropTypes.bool,
  filter: PropTypes.string,
  categories: PropTypes.array,
  selectedCategories: PropTypes.array,
  handleCountUpdate: PropTypes.func,
  handleRowSelectUpdates: PropTypes.func,
};

CategoriesTable.defaultProps = {
  visible: true,
  filter: '',
  categories: [],
  selectedCategories: [],
  handleCountUpdate: () => {},
  handleRowSelectUpdates: () => {},
};

export default React.memo(CategoriesTable);
