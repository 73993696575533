import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { formatDesignation } from 'helpers/location-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useUpdateListSection } from 'hooks/dataHooks/useLists';
import { useWorkspace } from 'hooks/useWorkspace';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const LocationsTabPanel = ({
                             sx,
                             tabValue,
                             data,
                             searchParam,
                             onAddLocationClicked,
                             onLocationDeleted,
                             onMoveLocationClicked,
                             onAddSectionClicked,
                           }) => {
  const { workspace } = useWorkspace();
  const [updateListSection] = useUpdateListSection(data.id);
  const columns = useMemo(() => {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        alwaysHidden: true,
      },
      {
        Header: 'Section',
        accessor: 'section',
        width: 170,
      },
      {
        Header: 'Location Name',
        accessor: 'name',
        width: 145,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 95,
      },
      {
        Header: 'Site',
        accessor: 'site',
        width: 110,
      },
      {
        Header: 'Building',
        accessor: 'building',
        width: 110,
      },
      {
        Header: 'Floor',
        accessor: 'floor',
        width: 110,
      },
      {
        Header: 'Workspace',
        accessor: 'workspace',
        hidden: workspace !== DRAFT_WORKSPACE,
        width: 120,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        width: 84,
        maxWidth: 84,
        disableSortBy: true,
        justifyRight: true,
      },
    ];
    return columns;
  }, [workspace]);

  const [mappedLocationsData, setMappedLocationsData] = useState(null);

  const mapLocations = useCallback(
    (listData) => {
      const deleteSectionLocation = async (section, locationId) => {
        const { id, locations } = section;
        return await updateListSection({
          variables: {
            id,
            input: {
              locations: locations
                .filter((location) => location.id !== locationId)
                .map((location) => location.id),
            },
          },
        });
      };

      const { sections } = listData;
      const mappedLocations = [];
      if (!sections) return null;
      sections?.forEach((section) => {
        const { locations } = section;
        locations?.forEach((location) => {
          const parents = [];
          let x = { ...location };
          while (x.parent) {
            parents.push(x.parent);
            x = x.parent;
          }
          const actionsAvailable = location.workspaces[0].name === workspace;
          const mappedLocation = {
            id: location.id,
            section: getLabelByLang(section.name),
            name: getLabelByLang(location.name),
            type: formatDesignation(location.designation),
            workspace: capitalize(location.workspaces[0].name),
            site:
              getLabelByLang(
                parents.filter((parent) => parent.designation === 'site')[0]
                  ?.name,
              ) || null,
            building:
              getLabelByLang(
                parents.filter((parent) => parent.designation === 'building')[0]
                  ?.name,
              ) || null,
            floor:
              getLabelByLang(
                parents.filter((parent) => parent.designation === 'floor')[0]
                  ?.name,
              ) || null,
            actions: actionsAvailable ? (
              <Box className="hoverUnhide">
                <Tooltip title="Move">
                  <Edit
                    onClick={() => {
                      onMoveLocationClicked(location);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Delete
                    onClick={async () => {
                      const result = await deleteSectionLocation(
                        section,
                        location.id,
                      );
                      if (!result.errors && onLocationDeleted) onLocationDeleted();
                    }}
                  />
                </Tooltip>
              </Box>
            ) : null,
          };
          mappedLocations.push(mappedLocation);
        });
      });
      return mappedLocations;
    },
    [onMoveLocationClicked, onLocationDeleted, updateListSection, workspace],
  );

  const locationsCount = useMemo(
    () => `${mappedLocationsData?.length} Location${
        mappedLocationsData?.length !== 1 ? 's' : ''
      }`,
    [mappedLocationsData],
  );

  const hasSections = useMemo(() => data?.sections?.length > 0, [data]);
  const hasLocations = mappedLocationsData?.length;

  useEffect(() => {
    if (data && searchParam) {
      const mappedLocations = mapLocations(data).filter((location) => {
        const searchableFields = Object.keys(location).filter(
          (key) => !['id', 'actions'].includes(key),
        );
        for (const field of searchableFields) {
          const value = location[field];
          if (
            value
            && value.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1
          ) return true;
        }
        return false;
      });
      setMappedLocationsData(mappedLocations);
    } else if (data && !searchParam) {
      setMappedLocationsData(mapLocations(data));
    }
  }, [data, mapLocations, searchParam]);

  return (
    <TabPanel
      value={tabValue}
      index={0}
      sx={{
        ...sx.tabPanel,
        ...tabValue === 0 && { height: 'calc(100% - 69px)' },
      }}
    >
      <Box sx={sx.innerTabPanel}>
        <Box className="subtitle">
          <Typography variant="subtitle1">{locationsCount}</Typography>
        </Box>
        <Box sx={sx.innerTabPanelContent}>
          {hasLocations ? (
            <GozioTable
              name="ListsTable"
              columns={columns}
              data={mappedLocationsData || []}
              sx={{
                padding: '0 0 12px 0',
              }}
              sortBy={[{ id: 'section', desc: false }]}
              emptyContent={<EmptyTable />}
              hidePagination={true}
              loading={data === null}
            />
          ) : (
            <Box sx={sx.emptyContainer}>
              <EmptyPanel
                large
                title="You Have No Locations in This List"
                subtitle={
                  !hasSections
                    ? workspace === LIVE_WORKSPACE
                      ? 'You must add a section before adding a location. Start this list by creating a section.'
                      : 'You must add a section before adding a location. Start this list by creating a section in the Live Workspace.'
                    : 'Start adding locations to this list.'
                }
                buttonLabel={!hasSections ? 'Add a Section' : 'Add a Location'}
                ButtonIcon={Add}
                buttonShown={workspace === LIVE_WORKSPACE}
                buttonClicked={() => {
                  if (!hasSections) onAddSectionClicked();
                  else onAddLocationClicked();
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </TabPanel>
  );
};

LocationsTabPanel.propTypes = {
  sx: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onAddLocationClicked: PropTypes.func.isRequired,
  onAddSectionClicked: PropTypes.func.isRequired,
  onLocationDeleted: PropTypes.func,
  onMoveLocationClicked: PropTypes.func.isRequired,
  searchParam: PropTypes.string,
  tabValue: PropTypes.number,
};

LocationsTabPanel.defaultProps = {};

export default React.memo(LocationsTabPanel);
