import { routerMiddleware } from 'connected-react-router';
import { setStore } from 'helpers/store-util';
import { createBrowserHistory as createHistory } from 'history';
import LogRocket from 'logrocket';
import createRootReducer from 'modules';
import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';

export const history = createHistory();

const initialState = {};

const composeEnhancers
  = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })
    : compose;

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
      LogRocket.reduxMiddleware(),
      // save({ states: ['flamingo'] })
    ),
  ),
);

setStore(store);

export default store;
