import { tabA11yProps } from 'helpers/page-util';
import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import TakeoverHeader from 'components/takeoverHeader/takeoverHeader';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  tabs: {
    height: '70px',

    '& > div > div': {
      height: '100%',

      '& button': {
        margin: '0 24px',
        minWidth: 'fit-content',
      },
    },
  },
  headerInner: {
    height: '205px',
    background: theme.palette.blue[400],
  },
  takeoverTabs: {
    background: `linear-gradient(to bottom right, ${theme.palette.gradient.white.start}, ${theme.palette.gradient.white.end})`,
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.25)}`,
    position: 'relative',
  },
  whiteIndicator: {
    backgroundColor: theme.palette.white,
  },
  bcInner: {
    width: '100%',
    maxWidth: 1400,
    margin: '0 auto',
    padding: '0 40px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
}));

function LocationsHeader({
                           networkPlace,
                           expanded,
                           tabs,
                           backText,
                           backUrl,
                           closeUrl,
                           title,
                           subtitle,
                           icon,
                           breadcrumb,
                           onBack = () => {},
                         }) {
  const classes = useStyles();
  const designation
    = networkPlace.designation === 'poi'
      ? 'POI'
      : capitalize(networkPlace?.designation);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    tabs.onChange(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerInner}>
        <TakeoverHeader
          onBack={onBack}
          backText={backText}
          backUrl={backUrl}
          closeUrl={closeUrl}
          icon={icon}
          networkName={networkPlace?.network?.name}
          title={title}
          subtitle={subtitle}
          itemType={`${
            networkPlace.isMapped ? 'Mapped' : 'Unmapped'
          } ${designation}`}
        />
        {tabs.items.length > 1 && (
          <Grid
            className={classes.takeoverTabs}
            sx={{
              background: !expanded && 'transparent',
              color: ColorPalette.white,
            }}
          >
            <Tabs
              className={classes.tabs}
              value={tabs.value}
              onChange={handleChange}
              aria-label="simple tabs"
              indicatorColor="primary"
              classes={{ indicator: !expanded && classes.whiteIndicator }}
              textColor={expanded ? 'primary' : 'inherit'}
              centered
            >
              {tabs.items.map((tab, idx) => (
                <Tab
                  key={tab.key}
                  sx={{ padding: '1px 0' }}
                  label={tab.title}
                  {...tabA11yProps('locations', idx)}
                />
              ))}
            </Tabs>
          </Grid>
        )}
      </Box>
      {breadcrumb && (
        <Box className={classes.bcInner}>
          <Breadcrumb parents={breadcrumb.parents} title={breadcrumb.title} />
        </Box>
      )}
    </Box>
  );
}

LocationsHeader.propTypes = {
  networkPlace: PropTypes.object,
  expanded: PropTypes.bool,
  tabs: PropTypes.object,
  backText: PropTypes.string,
  backUrl: PropTypes.string,
  closeUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mappedTitle: PropTypes.string,
  icon: PropTypes.object,
  onBack: PropTypes.func,
  toggleErrorModal: PropTypes.func,
};

LocationsHeader.defaultProps = {
  expanded: true,
  onBack: () => {},
  toggleErrorModal: () => {},
};

export default React.memo(LocationsHeader);
