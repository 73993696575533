import { Slider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const getSliderStyles
  = ({ centerFirstLabel, centerLastLabel }) => (theme) => ({
      root: {
        borderRadius: 0,
        color: theme.palette.blue[400],
        height: '8px',
      },
      thumb: {
        height: '20px',
        width: '20px',
        backgroundColor: theme.palette.white,
        boxShadow: `0 1px 2px 0 ${colorWithAlpha(theme.palette.black, 0.45)}`,
        border: `1px solid ${theme.palette.white}`,
        marginTop: '-6px',
        marginLeft: '-10px',
        zIndex: 2,

        '&:focus, &:hover, &$active': {
          boxShadow: `0 1px 2px 0 ${colorWithAlpha(theme.palette.black, 0.45)}`,
        },
      },
      active: {},
      mark: {
        backgroundColor: theme.palette.grey[300],
        borderRadius: 0,
        width: '2px',
        height: '20px',
      },
      markActive: {
        backgroundColor: theme.palette.grey[300],
      },
      markLabel: {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        marginTop: '6px',
        transform: 'translateX(-50%)',

        '&[data-index="0"]': {
          transform: centerFirstLabel ? 'translateX(-50%)' : 'none',
        },
        '&:nth-last-child(2)': {
          transform: centerLastLabel ? 'translateX(-50%)' : 'translateX(-100%)',
        },
      },
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: '8px',
        borderRadius: '0px',
        zIndex: 1,
      },
      rail: {
        backgroundColor: theme.palette.grey[300],
        borderRadius: 0,
        height: '8px',
        opacity: 1,
      },
    });

const GozioSlider = ({
                       input: { value, onChange },
                       className,
                       centerFirstLabel,
                       centerLastLabel,
                       disabled,
                       min,
                       max,
                       step,
                       marks,
                       valueLabelDisplay,
                     }) => {
  const marksCount = marks?.length ?? 0;
  const realMin = min ?? (marksCount > 0 ? marks[0].value : 1);
  const realMax = max ?? (marksCount > 1 ? marks[marksCount - 1].value : 100);
  const realStep = step ?? 1;

  const StyledSlider = React.memo(
    withStyles(getSliderStyles({ centerFirstLabel, centerLastLabel }))(Slider),
  );

  return (
    <StyledSlider
      className={className}
      value={value}
      disabled={disabled}
      min={realMin}
      max={realMax}
      onChange={(e, newValue) => onChange(newValue)}
      step={realStep}
      marks={marks}
      valueLabelDisplay={valueLabelDisplay}
    />
  );
};

GozioSlider.propTypes = {
  className: PropTypes.string,
  centerFirstLabel: PropTypes.bool,
  centerLastLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  valueLabelDisplay: PropTypes.string,
};

GozioSlider.defaultProps = {
  className: '',
  centerFirstLabel: false,
  centerLastLabel: false,
  disabled: false,
  marks: [],
  min: null,
  max: null,
  step: null,
  valueLabelDisplay: 'off',
};

export default GozioSlider;
