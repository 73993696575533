import { Clear } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LabeledCheckbox from 'components/labeledCheckbox/labeledCheckbox';
import { capitalize } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: '8px',
  },
  content: {
    background: theme.palette.white,
    borderRadius: '8px',
    padding: '32px',
    top: 'calc(50% - 179px)',
    left: 'calc(50% - 223px)',
    position: 'relative',
    height: '340px',
    width: '446px',
    zIndex: 1,
  },
  close: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  days: {
    marginTop: '10px',
  },
  row: {
    marginTop: '6px',
  },
  actionContainer: {
    background: theme.palette.grey[50],
    borderRadius: '0 0 8px 8px',
    bottom: 0,
    left: 0,
    padding: '14px 16px',
    position: 'absolute',
    width: '446px',
    height: '69px',
  },
  applyButton: {
    float: 'right',
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
  },
}));

const DAY_LIST = [
  'everyday',
  'Wednesday',
  'Sunday',
  'Thursday',
  'Monday',
  'Friday',
  'Tuesday',
  'Saturday',
];

const DEFAULT_CHECKED_DAYS = {
  everyday: false,
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
};

const CopyHoursPopover = ({
                            anchor,
                            day,
                            disabledDaysLookup,
                            onChange,
                            onClose,
                            open,
                          }) => {
  const classes = useStyles();

  const [checkedDays, setCheckedDays] = useState(DEFAULT_CHECKED_DAYS);

  useEffect(() => {
    setCheckedDays({
      ...DEFAULT_CHECKED_DAYS,
      [day]: true,
    });
  }, [day]);

  const everyDayLabel = useMemo(
    () => Object.keys(disabledDaysLookup).length > 0
        ? 'Every Available Day'
        : 'Every Day',
    [disabledDaysLookup],
  );

  const handleCheckboxChange = (selectedDay) => {
    if (selectedDay === 'everyday') {
      const checked = !checkedDays.everyday;
      const map = { ...checkedDays };
      DAY_LIST.forEach((key) => {
        const disabledKey = key.toLowerCase();
        if (key !== day && !disabledDaysLookup[disabledKey]) {
          map[key] = checked;
        }
      });
      setCheckedDays(map);
    } else {
      setCheckedDays({
        ...checkedDays,
        everyday: false,
        [selectedDay]: !checkedDays[selectedDay],
      });
    }
  };

  const disableActionButtons = useMemo(
    () => Object.values(checkedDays).filter((v) => v).length === 0,
    [checkedDays],
  );

  const handleClearValues = () => {
    setCheckedDays({
      everyday: false,
      Sunday: day === 'Sunday',
      Monday: day === 'Monday',
      Tuesday: day === 'Tuesday',
      Wednesday: day === 'Wednesday',
      Thursday: day === 'Thursday',
      Friday: day === 'Friday',
      Saturday: day === 'Saturday',
    });
  };

  const handleApplyHours = () => {
    onChange(
      day.toLowerCase(),
      Object.keys(checkedDays)
        .map((d) => d !== 'everyday' && checkedDays[d] && d !== day
            ? d.toLowerCase()
            : null,
        )
        .filter((d) => d),
    );
    setCheckedDays(DEFAULT_CHECKED_DAYS);
  };

  return (
    <Popover
      id={`copypop_${day}`}
      open={open}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.popover,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <Box className={classes.content}>
        <IconButton className={classes.close} onClick={onClose} size="large">
          <Clear />
        </IconButton>
        <Typography variant="subtitle1">
          Apply {capitalize(day)}'s Hours to Other Days
        </Typography>
        <Grid container className={classes.days}>
          {DAY_LIST.map((d, index) => {
            const key = d.toLowerCase();
            const isSelectedDay = key === day;
            return (
              <Grid key={`dl_${index}`} className={classes.row} item xs={6}>
                <LabeledCheckbox
                  color="primary"
                  label={d === 'everyday' ? everyDayLabel : d}
                  onChange={() => handleCheckboxChange(d)}
                  checked={checkedDays[d] || isSelectedDay}
                  disabled={isSelectedDay || disabledDaysLookup[key]}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box className={classes.actionContainer}>
          <Button
            color="primary"
            variant="outlined"
            disabled={disableActionButtons}
            onClick={handleClearValues}
          >
            CLEAR
          </Button>
          <Button
            className={classes.applyButton}
            color="primary"
            variant="contained"
            disabled={disableActionButtons}
            onClick={handleApplyHours}
          >
            APPLY HOURS
          </Button>
        </Box>
      </Box>{' '}
    </Popover>
  );
};

CopyHoursPopover.propTypes = {
  anchor: PropTypes.object,
  day: PropTypes.string.isRequired,
  disabledDaysLookup: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

CopyHoursPopover.defaultProps = {
  anchor: null,
  disabledDaysLookup: {},
  open: false,
};

export default CopyHoursPopover;
