import { useLazyQuery } from '@apollo/client';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import FormSubSection from 'components/forms/formSubSection';
import ImageUploader from 'components/imageUploader/imageUploader';
import InfoPanel from 'components/infoPanel/infoPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { getFormLabel } from 'helpers/form-util';
import {
  getYouTubeIdFromUrl,
  getYouTubeThumbnail,
  MINIMUM_IMAGE_HEIGHT,
  MINIMUM_IMAGE_WIDTH,
} from 'helpers/media-util';
import useHandleError from 'hooks/useHandleError';
import { TextField as FinalTextField } from 'mui-rff';
import ArrivalImageSection from 'pages/locations/containers/sections/arrivalImageSection';
import DefaultImageSection from 'pages/locations/containers/sections/defaultImageSection';
import MediaBox from 'pages/locations/containers/sections/mediaBox';
import MediaEmptyPlaceholder from 'pages/locations/containers/sections/mediaEmptyPlaceholder';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { FIND_KIOSK_COUNT } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    borderRadius: '8px',
    textAlign: 'center',
    width: '100%',
    height: '160px',
  },
  imageUploaderContainer: {
    alignItems: 'center',
    border: `1.5px dashed ${theme.palette.grey[400]}`,
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '160px',
    justifyContent: 'center',
    padding: '0 40px',
  },
  activeImageUploader: {
    marginLeft: '-40px',
    minWidth: 'calc(100% + 80px) !important',
    paddingRight: '0 !important',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  dragActiveTitle: {
    color: theme.palette.blue[400],
    display: 'inline-block',
  },
  dragIcon: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
    width: '72px',
    height: '72px',
  },
  dropZoneIcon: {
    background: theme.palette.grey[100],
    marginRight: theme.spacing(2),
    padding: '20px',
    width: '64px',
    height: '64px',
  },
  dropZoneTitle: {
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
  },
  link: {
    fontWeight: 500,
  },
  uploadSpec: {
    color: theme.palette.grey[500],
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: theme.spacing(0.5),
    textAlign: 'left',
  },
  videoField: {
    width: 'calc(100% - 130px)',
  },
  videoCaption: {
    textAlign: 'right',
    paddingRight: '130px',
  },
  videoButton: {
    marginLeft: '12px',
    marginTop: '7px',
    width: '118px',
  },
  mediaContainer: {
    border: 0,
    borderRadius: '8px',
    boxShadow: 'none',
    position: 'relative',
    width: '100%',
    marginTop: '8px',
    marginLeft: 0,
    paddingRight: '10px',
  },
  image: {
    borderRadius: '8px',
    display: 'inline-block',
    marginTop: '22px',
    marginRight: '22px',
    width: '209px',
    height: '133px',
  },
}));

const Media = ({ data, form, values }) => {
  const { activeNetwork } = useContext(FlamingoContext);
  const classes = useStyles();
  const { handleError } = useHandleError('Media');
  useField('media');
  useField('defaultImage');
  useField('youTubeUrl');

  const videoUrlRef = useRef(null);

  const media = form?.getState()?.values?.media || [];

  const [placeholderImage, setPlaceHolderImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);

  const [getKiosk] = useLazyQuery(FIND_KIOSK_COUNT, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  useEffect(() => {
    if (data?.designation === 'poi' && data?.parentSite?.id) {
      getKiosk({ variables: { where: { site: { id: data.parentSite.id } } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.defaultImage?.id) {
      setDefaultImage(data.defaultImage);
    } else {
      setDefaultImage(null);
    }
  }, [data, setDefaultImage]);

  useEffect(() => {
    if (data?.network?.placeholders) {
      const image = data?.network?.placeholders.find((p) => p.type === 'place' ? p.image : null,
      );
      setPlaceHolderImage(image);
    } else {
      setPlaceHolderImage(null);
    }
  }, [data, setPlaceHolderImage]);

  const assignDefaultImage = (image) => {
    setDefaultImage(image);
    form.mutators.setValue('defaultImage', image);
  };

  const handleMediaDeleted = (deletedMedia) => {
    const updatedMedia = media
      .map((m, index) => {
        if (deletedMedia.id) {
          if (m.id === deletedMedia.id) {
            return Object.assign({ toBeDeleted: true }, m);
          }
          return m;
        }

        if (deletedMedia.type === 'image') {
          if (deletedMedia.image.url !== m.image?.url) {
            return m;
          } else if (deletedMedia.dropOrder !== m.dropOrder) {
            return m;
          }
        } else if (deletedMedia.video.url !== m.video?.url) {
          return m;
        }
        return null;
      })
      .filter((m) => m);
    form.mutators.setValue('media', updatedMedia);
  };

  const handleMediaOrder = (mediaItem, moveIndex, inputMedia = media) => {
    if (inputMedia.length > 1) {
      const reorderedMedia = Array.from(inputMedia);
      const cutOut = reorderedMedia.splice(mediaItem.index, 1)[0];
      reorderedMedia.splice(moveIndex, 0, cutOut);
      form.mutators.setValue('media', reorderedMedia);
    }
  };

  const handleDefaultImageUpdated = (image) => {
    if (image) {
      if (data?.defaultImage) {
        form.mutators.setValue('deletedDefaultImageId', data.defaultImage.id);
      }
      assignDefaultImage(image);
      form.mutators.setValue('defaultImage', image);
    }
  };

  const handleDefaultImageDeleted = () => {
    if (data?.defaultImage) {
      form.mutators.setValue('deletedDefaultImageId', data.defaultImage.id);
    }
    assignDefaultImage(null);
    form.mutators.setValue('defaultImage', null);
  };

  const handleArrivalImageUpdated = (image) => {
    if (image) {
      if (data?.navigation?.arrivalImage) {
        form.mutators.setValue(
          'deletedArrivalImageId',
          data.navigation.arrivalImage.id,
        );
      }
      form.mutators.setValue('arrivalImage', image);
    }
  };

  const handleArrivalImageDeleted = () => {
    if (data?.navigation?.arrivalImage) {
      form.mutators.setValue(
        'deletedArrivalImageId',
        data.navigation.arrivalImage.id,
      );
    }
    form.mutators.setValue('arrivalImage', null);
  };

  const handleAddVideo = () => {
    const updatedMedia = Array.from(media);
    const url = videoUrlRef.current.value.trim();
    const youTubeId = getYouTubeIdFromUrl(url);
    updatedMedia.push({
      type: 'video',
      video: {
        youtubeId: youTubeId,
        thumbnail: { url: getYouTubeThumbnail(youTubeId) },
        url,
      },
    });
    form.mutators.setValue('media', updatedMedia);
    form.mutators.setValue('youTubeUrl', '');
  };

  const renderInfoPanel = () => {
    if (defaultImage) {
      return (
        <InfoPanel
          title="The Default Image will be used as the main image. All other media will show up on the location’s carousel." />
      );
    }

    if (placeholderImage) {
      if (media?.filter((m) => !m.toBeDeleted).length > 0) {
        return (
          <InfoPanel
            title="If a Default Image is not added, the Placeholder Image will be used. All other media will show up on the location’s carousel." />
        );
      }

      return (
        <InfoPanel title="If a Default Image is not added, the Placeholder Image will be used." />
      );
    }

    return (
      <InfoPanel
        title="An Image is required. Add an image or assign a Placeholder Image for all Locations."
        type="warning"
      />
    );
  };

  const mediaSectionTitle = useMemo(() => {
    switch (data?.designation) {
      case 'site':
        return 'Site\'s';
      case 'building':
        return 'Building\'s';
      case 'floor':
        return 'Floor\'s';
      case 'poi':
        return 'POI\'s';
      case 'landmark':
        return 'Landmark\'s';
      default:
        return '';
    }
  }, [data]);

  return (
    <>
      <FormSubSection
        name={
          <span>
            <Asterisk /> Add Images
          </span>
        }
      >
        <Grid item xs={12}>
          <Box className={classes.imageUploaderContainer}>
            <ImageUploader
              activeClassName={classes.activeImageUploader}
              onImageUpdated={({ file, url }) => {
                const updatedMedia = Array.from(media);
                const newMedia = { file, type: 'image', image: { url }, dropOrder: updatedMedia.length };
                updatedMedia.push(newMedia);
                form.mutators.setValue('media', [...updatedMedia]);
                form.mutators.setValue('youTubeUrl', '');
              }}
              activeDragPadding={[40, 40]}
              fileInfo={`Supported files include jpeg and png. The recommended file size is between 1MB and 3MBs. File sizes should be no larger than 3MBs. Image dimensions must be at least ${MINIMUM_IMAGE_WIDTH}px by ${MINIMUM_IMAGE_HEIGHT}px.`}
              maxFileSize={3000000}
              minWidth={MINIMUM_IMAGE_WIDTH}
              minHeight={MINIMUM_IMAGE_HEIGHT}
              data-test="imageUploaderDropzone"
            />
          </Box>
        </Grid>
      </FormSubSection>
      <FormSubSection name="Add YouTube Video">
        <Field name="youTubeUrl">
          {({ input, meta }) => (
            <>
              <Grid item xs={12}>
                <FinalTextField
                  className={classes.videoField}
                  label={getFormLabel('YouTube URL', false)}
                  name="youTubeUrl"
                  variant="outlined"
                  inputProps={{ ref: videoUrlRef }}
                />
                <Button
                  className={classes.videoButton}
                  variant="outlined"
                  color="primary"
                  disabled={
                    !input.value || (meta.error && meta.error.length > 0)
                  }
                  onClick={handleAddVideo}
                >
                  ADD VIDEO
                </Button>
              </Grid>
              {(!meta.error || meta.error.length === 0) && (
                <Box className={classes.videoCaption}>
                  <Typography variant="caption">
                    We only support YouTube videos at this time.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Field>
      </FormSubSection>
      <FormSubSection name={`${mediaSectionTitle} Media`}>
        <Box sx={{ marginTop: '-8px' }}>{renderInfoPanel()}</Box>
        {media.filter((m) => !m.toBeDeleted).length === 0 && !placeholderImage && (
          <Box className={classes.mediaContainer}>
            <MediaEmptyPlaceholder />
          </Box>
        )}
        {(placeholderImage
          || media.filter((m) => !m.toBeDeleted).length > 0) && (
          <Card className={classes.mediaContainer}>
            <MediaBox
              media={media}
              defaultImage={defaultImage}
              designation={data?.designation}
              placeholderImage={placeholderImage}
              onMediaDeleted={handleMediaDeleted}
              onOrderChanged={handleMediaOrder}
              supportsKiosk={activeNetwork.kioskEnabled}
            />
          </Card>
        )}
      </FormSubSection>
      <DefaultImageSection
        image={values?.defaultImage}
        placeholderImage={placeholderImage}
        onImageUpdated={handleDefaultImageUpdated}
        onImageDeleted={handleDefaultImageDeleted}
        supportsKiosk={activeNetwork.kioskEnabled}
      />
      {data?.designation === 'poi' && (
        <ArrivalImageSection
          hasDefaultImage={!!values?.defaultImage}
          hasPlaceholderImage={!!placeholderImage}
          image={values?.arrivalImage}
          onImageUpdated={handleArrivalImageUpdated}
          onImageDeleted={handleArrivalImageDeleted}
        />
      )}
    </>
  );
};

Media.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  values: PropTypes.object,
};

Media.defaultProps = {
  data: {},
  form: {},
  values: {},
};

export default Media;
