import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptyTable from 'components/tables/emptyTable';
import GlobalFilter from 'components/tables/globalFilter';
import { buildTableStyles } from 'components/tables/gozioTableStyles';
import { getSelectColumn, processCellProps, processHeaderProps } from 'components/tables/tableHelpers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';

const GozioTableWithVirtualization = ({
                                        sx,
                                        tableSx,
                                        data,
                                        columns,
                                        selectable,
                                        filterable,
                                        sortBy,
                                        handleRowSelectUpdates,
                                      }) => {
  const theme = useTheme();
  const styles = buildTableStyles({ theme });
  const tableBodyRef = useRef(null);
  const [tableBodyRect, setTableBodyRect] = useState({ height: 400 });

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      onResizeDebounce();
    }),
  );

  const onResizeDebounce = useAsyncDebounce(() => {
    if (tableBodyRef?.current) {
      setTableBodyRect({ height: tableBodyRef.current.clientHeight });
    }
  }, 250);

  useEffect(() => {
    const observer = resizeObserver.current;
    const ref = tableBodyRef.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tableBodyRef, resizeObserver]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        sortBy,
      },
      handleRowSelectUpdates,
    },
    filterable && useGlobalFilter,
    useSortBy,
    useFlexLayout,
    (hooks) => {
      if (selectable) {
        hooks.allColumns.push((columns) => [
          getSelectColumn(styles),
          ...columns,
        ]);
      }
    },
  );

  const HeaderCell = ({ column }) => {
    const { headerSx, ...restProps }
      = column.id === 'selection'
        ? processHeaderProps(column.getHeaderProps(), column)
        : processHeaderProps(
          column.getHeaderProps(column.getSortByToggleProps()),
          column,
        );
    return (
      <TableCell
        component="div"
        sx={{
          ...styles.header,
          ...column.hidden && styles.hidden,
          ...column.id === 'selection' && styles.noRightPad,
          ...headerSx,
        }}
        {...restProps}
      >
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {column.id !== 'selection' ? (
            <TableSortLabel
              sx={{
                ...styles.headerLabel,
                ...!column.canSort && styles.noSort,
                ...column.justifyRight && styles.justifyRight,
              }}
              active={column.isSorted}
              direction={column.isSortedDesc ? 'desc' : 'asc'}
              hideSortIcon={!column.canSort}
            >
              {column.render('Header')}
            </TableSortLabel>
          )
            : column.render('Header')
          }
        </Box>
      </TableCell>
    );
  };

  const HeaderRow = ({ row, headerRowIdx }) => (
    <TableRow {...row.getHeaderGroupProps()} component="div">
      {row.headers.map((column, headerColIdx) => (
        <HeaderCell
          key={`th_${headerRowIdx}_${headerColIdx}`}
          column={column}
          component="div"
        />
      ))}
    </TableRow>
  );

  const Cell = ({ cell, rowIdx }) => {
    const headerCol = cell.column;
    const { cellSx, ...restProps } = processCellProps(
      cell.getCellProps(),
      headerCol,
      rows[rowIdx].values,
    );

    return (
      <TableCell
        component="div"
        sx={{
          ...headerCol.onClick && styles.highlightOnHover,
          ...headerCol.hidden && styles.hidden,
          ...headerCol.id === 'selection' && styles.noRightPad,
          ...cellSx,
        }}
        {...restProps}
      >
        <Box
          sx={{
            ...styles.cell,
            ...headerCol.justifyRight && styles.justifyRight,
          }}
        >
          <Box sx={styles.cellContent}>{cell.render('Cell')}</Box>
        </Box>
      </TableCell>
    );
  };

  const VirtualizedRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow {...row.getRowProps({ style })} component="div">
          {row.cells.map((cell, colIdx) => (
            <Cell
              key={`td_${index}_${colIdx}`}
              cell={cell}
              rowIdx={index}
              component="div"
            />
          ))}
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows],
  );

  const renderTable = () => (
    <Box
      sx={{
        padding: '0 12px',
        height: '100%',
        ...styles.tableWrap,
        ...tableSx,
      }}
    >
      <Table {...getTableProps()} sx={styles.flexTable} component="div">
        <TableHead component="div">
          {headerGroups.map((row, headerRowIdx) => (
            <HeaderRow
              key={`th_${headerRowIdx}`}
              row={row}
              headerRowIdx={headerRowIdx}
              component="div"
            />
          ))}
        </TableHead>

        <TableBody
          ref={tableBodyRef}
          {...getTableBodyProps()}
          sx={{ overflow: 'hidden', flex: 1 }}
          component="div"
        >
          {/* {rows.map((row, rowIdx) => (
						<Row key={`tr_${rowIdx}`} row={row} rowIdx={rowIdx} />
					))} */}
          <FixedSizeList
            height={tableBodyRect.height}
            width={'100%'}
            itemCount={rows.length}
            itemSize={72}
            overscanCount={1}
          >
            {VirtualizedRow}
          </FixedSizeList>
        </TableBody>
      </Table>
    </Box>
  );

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      {filterable && (
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          numRecs={rows.length}
        />
      )}
      {rows.length === 0 ? (
        <EmptyTable />
      ) : (
        <TableContainer sx={styles.tableContainer}>
          {renderTable()}
        </TableContainer>
      )}
    </Box>
  );
};

GozioTableWithVirtualization.propTypes = {
  sx: PropTypes.object,
  tableSx: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string.isRequired,
      cellStyle: PropTypes.object,
      customSort: PropTypes.func,
      disableClick: PropTypes.bool,
      hidden: PropTypes.bool,
      onClick: PropTypes.func,
      render: PropTypes.func, // custom column rendering
      disableSortBy: PropTypes.bool,
      sticky: PropTypes.oneOf(['left', 'right']),
      maxWidth: PropTypes.number,
      minWidth: PropTypes.number,
      width: PropTypes.number,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  selectable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortBy: PropTypes.array,
  handleRowSelectUpdates: PropTypes.func,
};

GozioTableWithVirtualization.defaultProps = {
  sx: {},
  tableSx: {},
  columns: [],
  data: [],
  selectable: false,
  filterable: false,
  disableSortBy: false,
  sortBy: [],
  handleRowSelectUpdates: (ids) => {},
};

export default React.memo(GozioTableWithVirtualization);
