import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DrawerStyles from 'components/navigation/drawer-styles';
import PropTypes from 'prop-types';
import React from 'react';

const CollapseListItem = React.memo((props) => {
  const {
    classes,
    disabled,
    label,
    sectionName,
    subSections,
    isSelected,
    handleClick,
  } = props;
  if (disabled) return null;
  return (
    <>
      <ListItem
        button
        data-test={`${label?.toLowerCase() ?? sectionName.toLowerCase()}-link`}
        onClick={handleClick}
        selected={isSelected}
        classes={{
          root: classes.nestedListTitle,
          selected: classes.nestedListTitleSelected,
        }}
      >
        <ListItemText
          disableTypography
          classes={{
            primary: classes.nestedListTitle,
          }}
          primary={sectionName}
        />
        {isSelected ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={isSelected}
        timeout="auto"
        unmountOnExit
        classes={{ wrapper: classes.nestedList }}
      >
        <List component="div" disablePadding>
          {subSections
            && subSections.map(
              (item, index) => !item.disabled && (
                  <ListItem
                    button
                    data-test={`${item.name.toLowerCase()}-link`}
                    onClick={item.link}
                    key={index}
                    selected={item.selected}
                    classes={{
                      root: classes.SublistItem,
                      selected: classes.selectedSubListItem,
                    }}
                  >
                    <ListItemText
                      disableTypography
                      classes={{}}
                      primary={item.name}
                    />
                  </ListItem>
                ),
            )}
        </List>
      </Collapse>
    </>
  );
});

CollapseListItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  sectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subSections: PropTypes.array,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
};

CollapseListItem.defaultProps = {
  handleClick: () => {},
  label: null,
  sectionName: null,
  subSections: [],
  isSelected: false,
  disabled: false,
};

export default withStyles(DrawerStyles)(CollapseListItem);
