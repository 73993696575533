import { AddPhotoAlternate, Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getLanguageLabel } from 'components/dashboard/cardLayouts/cardhelper';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: '283px',
    overflow: 'hidden',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  button: {
    borderRadius: '18px',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '26px',
    marginLeft: '16px',
    padding: '4.5px 12px 2px 12px',
    textTransform: 'uppercase',
    width: 'fit-content',
  },
  disabled: {
    opacity: 0.5,
  },
  subtitle: {
    display: '-webkit-box',
    overflow: 'hidden',
    marginLeft: '16px',
    marginTop: '-7.5px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    marginLeft: '16px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  titleSection: {
    height: '24px',
  },
  subtitleSection: {
    height: '22px',
  },
  imageSection: {
    backgroundColor: theme.palette.grey[350],
    height: '164px',

    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
  placeholderImage: {
    height: '80px',
    width: '80px',
    margin: '42px auto',

    '& svg': {
      color: theme.palette.white,
      height: '80px',
      width: '80px',
    },
  },
});

export const A2cImageCard = ({
                               backgroundColor,
                               buttonText,
                               buttonBackgroundColor,
                               buttonTextColor,
                               clickHandler,
                               disabled,
                               iconUrl,
                               incompleteForPackaging,
                               selected,
                               subtitle,
                               textColor,
                               title,
                             }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box
        sx={{
          ...styles.incompleteWarning,
          left: `${width - 38}px`,
          top: '6px',
        }}
      >
        <Info />
      </Box>
    </Box>
  );

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={12} sx={styles.imageSection}>
        {iconUrl && <img alt="icon" src={iconUrl} />}
        {!iconUrl && (
          <Box sx={styles.placeholderImage}>
            <AddPhotoAlternate />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={{ ...styles.titleSection, backgroundColor }}>
        <Typography
          variant="subtitle1"
          sx={{
            ...styles.title,
            color: textColor,
            marginTop: subtitle ? '1.5px' : '-4.5px',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ ...styles.subtitleSection, backgroundColor }}>
        <Typography
          variant="body1"
          sx={{ ...styles.subtitle, color: textColor }}
        >
          {getLanguageLabel(subtitle) ?? ''}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={styles.textSection}>
        <Box
          sx={{
            ...styles.button,
            backgroundColor: buttonBackgroundColor,
            color: buttonTextColor,
          }}
        >
          {getLanguageLabel(buttonText) ?? ''}
        </Box>
      </Grid>
    </Grid>
  );
};

A2cImageCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  iconUrl: PropTypes.string,
  incompleteForPackaging: PropTypes.bool,
  selected: PropTypes.bool,
  subtitle: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

A2cImageCard.defaultProps = {
  buttonText: '',
  disabled: false,
  iconUrl: null,
  incompleteForPackaging: false,
  selected: false,
  subtitle: '',
};

export default A2cImageCard;
