import { Button, CircularProgress, Typography } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const UnsavedModal = ({
                        confirmText,
                        continueAndDeleteText,
                        open,
                        onClose,
                        onSave,
                        onContinue,
                        message,
                      }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <GenericModal
      open={open}
      handleClose={onClose}
      title="Unsaved Changes"
      body={
        !isSubmitting ? (
          <Typography
            variant="body1"
            sx={{ fontSize: '1.125rem', marginTop: '16px' }}
            gutterBottom
          >
            {message
              || 'Would you like to save your progress before navigating off of this page?'}
          </Typography>
        ) : (
          <CircularProgress />
        )
      }
      additionalRightButton={
        <Button
          variant="outlined"
          color="primary"
          onClick={onContinue}
          sx={{ marginRight: '12px' }}
          disabled={isSubmitting}
          data-test="ContinueDeleteBtn"
        >
          {continueAndDeleteText || 'Continue & Delete Changes'}
        </Button>
      }
      confirmText={confirmText}
      disableClose={isSubmitting}
      greyOutCancel={isSubmitting}
      greyOutConfirm={isSubmitting}
      handleConfirm={async () => {
        setIsSubmitting(true);
        await onSave();
        setIsSubmitting(false);
      }}
    />
  );
};

UnsavedModal.propTypes = {
  confirmText: PropTypes.string,
  continueAndDeleteText: PropTypes.string,
};

UnsavedModal.defaultProps = {
  confirmText: 'Save',
  continueAndDeleteText: 'Continue & Delete Changes',
};

export default UnsavedModal;
