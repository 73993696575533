import { AddPhotoAlternate } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    fontSize: '14px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '72px',

    '& img': {
      width: '28px',
      height: '28px',
    },
  },
});

const NavLink = ({ textColor, name, icon }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={styles.root}>
      {icon?.url ? <img src={icon?.url} alt="icon" /> : <AddPhotoAlternate />}
      <Box sx={{ color: textColor }}>{getLabelByLang(name)}</Box>
    </Box>
  );
};

NavLink.propTypes = {
  name: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  icon: PropTypes.shape({
    url: PropTypes.string,
  }),
  textColor: PropTypes.string,
};

NavLink.defaultProps = {
  name: [],
  icon: {},
  textColor: ColorPalette.white,
};

export default NavLink;
