import { Add, Close, Error } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GenericModal from 'components/genericModal/genericModal';
import Loading from 'components/loading/loading';
import Notifier from 'components/notifier/notifier';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import _ from 'lodash';
import { uploadImageToService } from 'modules/media';
import {
  createNetworkPersona,
  deleteNetworkPersona,
  getPersonas,
  resetPersnoaFlash,
  updateNetworkPersona,
} from 'modules/personas';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddOrEditPersonaCard from 'pages/network/personas/containers/addOrEditPersonaCard';
import EmptyPersona from 'pages/network/personas/containers/emptyPersona';
import PersonaCard from 'pages/network/personas/containers/personaCard';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '-20px',
    marginRight: 0,
  },
  message: {
    flexGrow: 1,
    color: theme.palette.grey[500],
    paddingTop: '8px',
  },
  closeButton: {
    color: colorWithAlpha(theme.palette.black, 0.54),
    marginTop: '6px',
    marginLeft: '64px',
    cursor: 'pointer',
  },
  modalText: {
    '& p': {
      color: colorWithAlpha(theme.palette.black, 0.6),
      lineHeight: '24px',
    },
  },
  notification: {
    background: theme.palette.white,
    borderRadius: '8px',
    boxShadow: `0px 12px 24px 0px ${colorWithAlpha(theme.palette.black, 0.15)}`,
    height: '56px',
    padding: '12px 16px',
    display: 'flex',
    margin: 'auto',
    position: 'absolute',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
  },
  addOrEditContainer: {
    position: 'absolute',
    top: '328px',
    width: '553px',
  },
  addOrEditContainerRightEdge: {
    position: 'absolute',
    top: '328px',
    left: '-286px',
    width: '553px',
  },
}));

const PLACEHOLDER_PERSONA = {
  id: 'placeholder1',
  name: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Persona name' }],
  icon: {},
  isSelected: true,
  isDefault: true,
  publishable: true,
};

const DEFAULT_PERSONAS = [PLACEHOLDER_PERSONA];

export const Personas = ({
                           dispatch,
                           createNetworkPersona,
                           updateNetworkPersona,
                           deleteNetworkPersona,
                           getPersonas,
                           loading,
                           flash,
                           media,
                           personas = [],
                         }) => {
  const { networkId } = useParams();
  const [currentNetworkId, setCurrentNetworkId] = useState(networkId);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [placeholderCard, setPlaceholderCard] = useState(null);
  const [isInitialPersona, setIsInitialPersona] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [ready, setReady] = useState(!loading);
  const { activeNetwork } = useContext(FlamingoContext);
  const personasFeature
    = activeNetwork?.configuration?.features?.personas ?? 'off';

  const classes = useStyles();

  const isPersonasEnabled = useCallback(
    () => personasFeature === 'on',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkId, personasFeature],
  );

  const isUberAdmin = useCheckGozioAdmin();

  useEffect(() => {
    getPersonas({ networkId });
  }, [networkId, getPersonas]);

  useEffect(() => {
    if (currentNetworkId !== networkId) {
      setCurrentNetworkId(networkId);
      getPersonas({ networkId });
    } else {
      if (!isPersonasEnabled()) {
        setReady(true);
        setIsInitialPersona(false);
        return;
      }

      if (loading) getPersonas({ networkId });
    }

    setReady(!loading);
    if (!loading) {
      if (personas && personas.length) {
        setShowForm(false);
        setIsInitialPersona(true);
      } else {
        setIsInitialPersona(false);
      }
    }
  }, [
    loading,
    currentNetworkId,
    networkId,
    personas,
    getPersonas,
    isPersonasEnabled,
  ]);

  const closeInfoModal = () => {
    setShowInfoModal(false);
    setIsInitialPersona(true);
    setShowForm(true);
  };

  const handleUploadIconStarted = () => {
    setMessage(null);
  };

  const handleUploadIconFailed = (errorMessage) => {
    setMessage(errorMessage);
  };

  const handleAddPersona = () => {
    setPlaceholderCard(PLACEHOLDER_PERSONA);
    setSelectedPersona(PLACEHOLDER_PERSONA);
    setShowForm(true);
  };

  const handleEditPersona = (persona) => {
    setSelectedPersona(persona);
    setShowForm(true);
  };

  const handlePublishingStatus = (persona) => {
    if (persona) {
      updateNetworkPersona({
        networkId,
        personaId: persona.id,
        input: {
          name: persona.name,
          icon: _.get(persona, 'icon.image.id'),
          isDefault: persona.isDefault,
          publishable: !persona.publishable,
        },
      });
    }
  };

  const showDeletePersonaModal = (persona) => {
    setSelectedPersona(persona);
    setShowDeleteModal(true);
  };

  const handleDeletePersona = () => {
    deleteNetworkPersona({
      networkId,
      personaId: selectedPersona.id,
    });
    setSelectedPersona(null);
    setShowDeleteModal(false);
  };

  const handleApplyToAllContent = () => {};

  const handleSavePersona = ({
                               personaId,
                               isApplyPersonaToAllLocationsAndLists,
                               nameMap,
                               isDefault,
                               publishable,
                               iconImageId,
                               iconFile,
                             }) => new Promise((resolve, reject) => {
      if (iconFile) {
        resolve(uploadImageToService(iconFile));
      } else {
        resolve();
      }
    })
      .then((image) => {
        const error = _.get(media, 'flash.error');
        if (error) {
          return Promise.reject(media.flash);
        }

        const nameInput = [];
        Object.keys(nameMap).forEach((lang) => {
          nameInput.push({ lang, label: nameMap[lang] });
        });

        const imageId = image?.id || iconImageId;
        if (personaId) {
          return updateNetworkPersona({
            networkId,
            personaId,
            input: {
              name: nameInput,
              icon: imageId,
              isDefault,
              publishable,
            },
          });
        }

        const createdPublished = personas.length === 0 ? true : publishable;
        return createNetworkPersona({
          networkId,
          name: nameInput,
          icon: imageId,
          publishable: createdPublished,
        });
      })
      .then(() => {
        setPlaceholderCard(null);
        setShowForm(false);
      })
      .catch((err) => {
        console.error('handleSavePersona: ', err);
        setPlaceholderCard(null);
        setShowForm(false);
      });

  const handleCancelSavePersona = () => {
    if (placeholderCard) {
      setPlaceholderCard(null);
    }
    setSelectedPersona(null);
    setShowForm(false);
  };

  const languages = useActiveNetworkLanguages();

  const getEnglishName = (personaNameList) => {
    if (personaNameList && personaNameList.length) {
      const englishName = personaNameList.find(
        (p) => p.lang === LANGUAGE_CODES.ENGLISH,
      );
      return englishName && englishName.label;
    }

    return null;
  };

  const getAddOrEditClassName = (index) => {
    if (index < 2) {
      return classes.addOrEditContainer;
    }

    return classes.addOrEditContainerRightEdge;
  };

  if (!isUberAdmin || !isPersonasEnabled) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  if (!ready) {
    return <Loading />;
  }

  if (isInitialPersona) {
    const list = personas.length ? personas : DEFAULT_PERSONAS;
    let personaList = list;

    if (placeholderCard) {
      personaList = list.concat(DEFAULT_PERSONAS);
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h1"
            sx={{ marginBottom: '38px' }}
            data-test="FlamingoPage-title-Personas"
          >
            Personas
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            onClick={handleAddPersona}
            data-test="personaAddPersonaBtn"
          >
            <Add />
            Add Persona
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', flexWrap: 'nowrap', marginTop: '34px' }}
        >
          {personaList.map((persona, index) => (
            <Box
              key={persona.id}
              sx={{ marginRight: '24px', position: 'relative' }}
            >
              <PersonaCard
                name={getEnglishName(persona.name)}
                iconUrl={persona.icon.url}
                bottomLabel={
                  <>
                    <Typography variant="body1" align="center" gutterBottom>
                      0 daily active users
                    </Typography>
                    <Typography variant="body1" align="center">
                      0 visible locations
                    </Typography>
                  </>
                }
                options={{
                  isSelected:
                    selectedPersona && persona.id === selectedPersona.id,
                  isDefault: persona.isDefault,
                  showInPublishing: persona.publishable,
                }}
                handleEditPersona={() => handleEditPersona(persona)}
                handleApplyToAllContent={handleApplyToAllContent}
                handlePublishingStatus={() => handlePublishingStatus(persona)}
                handleDeletePersona={() => showDeletePersonaModal(persona)}
              />
              {showForm
                && !placeholderCard
                && selectedPersona
                && persona.id === selectedPersona.id && (
                  <Box className={getAddOrEditClassName(index)}>
                    <AddOrEditPersonaCard
                      languages={languages}
                      persona={selectedPersona}
                      onUploadIconStarted={handleUploadIconStarted}
                      onUploadIconFailed={handleUploadIconFailed}
                      handleSavePersona={handleSavePersona}
                      handleCancel={handleCancelSavePersona}
                    />
                  </Box>
                )}
              {showForm
                && placeholderCard
                && persona.id === placeholderCard.id && (
                  <Box className={getAddOrEditClassName(index)}>
                    <AddOrEditPersonaCard
                      languages={languages}
                      persona={{}}
                      onUploadIconStarted={handleUploadIconStarted}
                      onUploadIconFailed={handleUploadIconFailed}
                      handleSavePersona={handleSavePersona}
                      handleCancel={handleCancelSavePersona}
                    />
                  </Box>
                )}
            </Box>
          ))}
        </Grid>
        <Notifier
          flash={flash}
          clearFlash={() => {
            dispatch(resetPersnoaFlash());
          }}
        />
        {message && (
          <Grid item xs={12}>
            <Box className={classes.notification}>
              <Error
                sx={{
                  fontSize: '32px',
                  marginRight: '9px',
                  color: ColorPalette.red[500],
                }}
              />
              <Typography variant="body1" className={classes.message}>
                {message}
              </Typography>
              <Close
                className={classes.closeButton}
                sx={{
                  marginTop: '3px',
                }}
                onClick={() => setMessage(false)}
              />
            </Box>
          </Grid>
        )}
        {showDeleteModal && (
          <GenericModal
            title={`Delete ${
              selectedPersona.name.find(
                (n) => n.lang === LANGUAGE_CODES.ENGLISH,
              ).label
            }`}
            body={
              <Box className={classes.modalText}>
                <Typography variant="body1">
                  This action does not remove any content, but it will
                  <br />
                  delete this persona and all references to it.
                </Typography>
              </Box>
            }
            open={true}
            handleClose={() => {
              setShowDeleteModal(false);
            }}
            handleConfirm={handleDeletePersona}
            confirmText="DELETE"
          />
        )}
      </Grid>
    );
  }

  return (
    <Box className={classes.root}>
      <EmptyPersona
        enabled={personasFeature === 'on'}
        handleAddPersona={() => {
          setShowInfoModal(true);
        }}
      />
      {showInfoModal && (
        <GenericModal
          title="Managing personas"
          body={
            <Box className={classes.modalText}>
              <Typography variant="body1">
                Personas will not automatically be applied to content.
                Visibility settings can be managed on individual pages.
              </Typography>
              <Typography variant="h4">PRO TIP</Typography>
              <Typography variant="body1">
                Use the checkbox when creating a persona or click the settings
                icon on a card to quickly apply a persona to all locations and
                lists.
              </Typography>
            </Box>
          }
          open={true}
          handleConfirm={closeInfoModal}
          disableCancel={true}
          confirmText="Got It"
        />
      )}
    </Box>
  );
};

const PersonasPage = (props) => (
  <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
    <Personas {...props} />
  </LoggedinLayout>
);

PersonasPage.propTypes = {
  createNetworkPersona: PropTypes.func.isRequired,
  updateNetworkPersona: PropTypes.func.isRequired,
  deleteNetworkPersona: PropTypes.func.isRequired,
  getPersonas: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  flash: PropTypes.object,
  personaFeatures: PropTypes.object,
  personas: PropTypes.array.isRequired,
};

PersonasPage.defaultProps = {
  createNetworkPersona: () => {},
  updateNetworkPersona: () => {},
  deleteNetworkPersona: () => {},
  getPersonas: () => {},
  loading: false,
  flash: null,
  personaFeatures: {},
  personas: [],
};

const mapStateToProps = ({ media, personas }) => ({
  loading: personas.loading,
  flash: personas.flash,
  media,
  personas: _.get(personas, 'personas', []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getPersonas: (params) => dispatch(getPersonas(params)),
  createNetworkPersona: (params) => dispatch(createNetworkPersona(params)),
  updateNetworkPersona: (params) => dispatch(updateNetworkPersona(params)),
  deleteNetworkPersona: (params) => dispatch(deleteNetworkPersona(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonasPage);
