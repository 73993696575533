import { ArrowForward, Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MapImage from 'assets/care-near-me.png';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: '283px',
    overflow: 'hidden',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  titleSection: {
    height: '80px',
  },
  text: {
    display: '-webkit-box',
    marginTop: '30px',
    marginLeft: '16px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  seeMoreText: {
    marginTop: '30px',
    marginRight: '16px',
    textAlign: 'right',
  },
  image: {
    margin: '26px 0 0 0',
    height: '203px',
    width: '100%',

    '& img': {
      height: '203px',
      width: '100%',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
});

export const A2cMapCard = ({
                             backgroundColor,
                             clickHandler,
                             disabled,
                             incompleteForPackaging,
                             selected,
                             textColor,
                             title,
                           }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box
        sx={{
          ...styles.incompleteWarning,
          left: `${width - 38}px`,
          top: '6px',
        }}
      >
        <Info />
      </Box>
    </Box>
  );

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={8} sx={styles.titleSection}>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.text, color: textColor }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={styles.titleSection}>
        <Typography
          variant="body1"
          sx={{ ...styles.seeMoreText, color: textColor }}
        >
          <ArrowForward />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.image}>
          <img src={MapImage} alt="Care Near Me" />
        </Box>
      </Grid>
    </Grid>
  );
};

A2cMapCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  selected: PropTypes.bool,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

A2cMapCard.defaultProps = {
  disabled: false,
  incompleteForPackaging: false,
  selected: false,
};

export default A2cMapCard;
