import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GozioLogo from 'assets/gozio-logo.svg';
import PhoneFrame1x from 'assets/mobile-preview-phone.png';
import PhoneFrame2x from 'assets/mobile-preview-phone@2x.png';
import PhoneFrame3x from 'assets/mobile-preview-phone@3x.png';
import GozioListItem from 'components/listItem/gozioListItem';
import WarningPanel from 'components/warningPanel/warningPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import {
  BOTTOM_NAVIGATION_LINKS,
  getBottomNavTemplateCount,
  isAboutUsLink,
  MORE_MENU_LINKS,
} from 'pages/mobileSettings/bottomNavigation/bottomNavigationHelper';
import NavLink from 'pages/mobileSettings/bottomNavigation/containers/NavLink';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[50],
    borderLeft: `2px solid ${colorWithAlpha(ColorPalette.grey[350], 0.3)}`,
    display: 'flex',
    justifyContent: 'center',
    minWidth: '444px',
    textAlign: 'center',
    top: '68px',
  },
  pageTitle: {
    position: 'absolute',
    top: '32px',
  },
  content: {
    marginTop: '87px',
    textAlign: 'left',
    position: 'relative',
    width: '360px',
  },
  moreMenuTop: {
    background: theme.palette.grey[350],
    height: '50px',
    position: 'absolute',
    width: '360px',
    zIndex: 3,
  },
  previewContainer: {
    width: '360px',
    position: 'relative',
    top: '-24px',
  },
  moreMenuContent: {
    paddingLeft: '12px',
    position: 'absolute',
    width: '360px',
    zIndex: 3,
  },
  moreMenuTitle: {
    color: theme.palette.white,
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '24px',
    marginBottom: '16px',
  },
  listItem: {
    background: 'unset',
    marginTop: '8px',
    minHeight: '48px',
    height: '527px',
    overflowY: 'auto',

    '& .MuiGrid-root': {
      background: 'unset',
    },

    '& .MuiListItem-root': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      minHeight: '48px',
    },

    '& .MuiListItemText-root': {
      '& p': {
        color: 'unset',
      },
    },
  },
  itemIcon: {
    height: '26px',
    width: '26px',

    '& svg': {
      color: theme.palette.white,
      height: '18px',
      width: '18px',
    },
  },
  itemLabel: {
    color: theme.palette.white,
    display: '-webkit-box',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: '8px',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  previewContent: {
    '& img': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      height: '685px',
      position: 'absolute',
      top: '42px',
      width: '100%',
      zIndex: 2,
    },
  },
  shadowContainer: {
    background: theme.palette.grey[50],
    boxShadow: '0 0 4px 0 rgba(38, 43, 46, 0.25)',
    height: '654px',
    position: 'absolute',
    top: '72px',
    width: '100%',
    zIndex: 1,
  },
  cancelIcon: {
    color: theme.palette.grey[300],
    float: 'right',
    height: '30px',
    position: 'relative',
    right: '-4px',
    top: '-28px',
    width: '30px',
  },
  cancelIconBackdrop: {
    background: theme.palette.grey[500],
    float: 'right',
    height: '16px',
    position: 'relative',
    right: '19px',
    top: '-21px',
    width: '16px',
  },
  supportingImageContainer: {
    background: theme.palette.grey[350],
    borderTop: `3px solid ${theme.palette.grey[300]}`,
    height: '397px',
    position: 'absolute',
    top: '104px',
    width: '360px',
    zIndex: 3,

    '& img': {
      width: '100%',
      height: '100%',
      marginTop: theme.spacing(-0.25),
    },
  },
  placeholderIcon: {
    color: theme.palette.white,
    margin: '158px auto 0 auto',
    width: '81px',
    height: '81px',

    '& svg': {
      width: '81px',
      height: '81px',
    },
  },
  aboutPageContainer: {
    background: theme.palette.white,
    height: '233px',
    position: 'absolute',
    top: '499px',
    width: '360px',
    zIndex: 3,
    paddingLeft: '15px',

    '& h6': {
      fontWeight: 200,
    },
  },
  logo: {
    width: '68px',
    height: '45px',

    '& img': {
      width: '68px',
      height: '45px',
    },
  },
  logoText: {
    fontWeight: '700 !important',
    marginTop: '4px',
  },
  logoLink: {
    marginTop: '4px',
    color: ColorPalette.blue[400],
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
  },
  bottomSection: {
    background: theme.palette.white,
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.25)',
    height: '89.5px',
    paddingTop: theme.spacing(2),
    position: 'absolute',
    top: '726px',
    width: '360px',
  },
  navLink: {
    width: '80px',
  },
});

const BottomNavPreview = ({
                            configColors,
                            data,
                            editPanelTabValue,
                            fixed,
                            incompleteMessage,
                            selectedItem,
                            sx,
                            title,
                            type,
                          }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const {
    layout: { openDrawer },
  } = useContext(FlamingoContext);

  const isAboutUs = isAboutUsLink(selectedItem);

  const leftOffset = openDrawer ? 250 : 0;
  const moreMenuTop = title && !isAboutUs ? 140 : 80;
  const isMoreMenu = type === MORE_MENU_LINKS;

  const navLinks = useMemo(() => {
    if (data?.tabs?.length > 0) {
      const size = getBottomNavTemplateCount(data.tabTemplate || data.template);
      return data.tabs.slice(0, size).map((tab, index) => (
        <Grid key={`navlink-${index}`} item xs sx={styles.navLink}>
          <NavLink icon={tab.icon} textColor={data.fillColor} name={tab.name} />
        </Grid>
      ));
    }

    return <></>;
  }, [styles.navLink, data]);

  const aboutUsSupportingImageUrl = useMemo(() => {
    if (!isAboutUs) {
      return null;
    }
    const aboutUsItem = data?.items?.find((item) => isAboutUsLink(item));
    if (aboutUsItem?.supportingImageUrlEn) {
      return aboutUsItem.supportingImageUrlEn;
    }

    return selectedItem?.supportingImage?.en?.url;
  }, [data, isAboutUs, selectedItem]);

  const moreMenuLinks = useMemo(() => {
    if (data?.items?.length > 0) {
      return data.items.map((item, index) => ({
        id: item.id,
        order: index,
        iconSx: styles.itemIcon,
        iconUrl: item.icon?.url,
        label: getLabelByLang(item.name),
        labelSx: styles.itemLabel,
        showDefaultIcon: true,
      }));
    }
  }, [styles.itemIcon, styles.itemLabel, data]);

  const previewContent = useMemo(() => {
    if (editPanelTabValue === 1) {
      return (
        <>
          <Box sx={styles.shadowContainer} />
          <Box sx={styles.previewContent}>
            <img
              src={PhoneFrame1x}
              srcSet={`${PhoneFrame2x} 2x, ${PhoneFrame3x} 3x`}
              alt="phone"
            />
          </Box>
          <Box sx={styles.supportingImageContainer}>
            {!aboutUsSupportingImageUrl && (
              <Box sx={styles.placeholderIcon}>
                <AddPhotoAlternateIcon />
              </Box>
            )}
            {!!aboutUsSupportingImageUrl && (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img src={aboutUsSupportingImageUrl} alt="Supporting Image" />
            )}
          </Box>
          <Grid container sx={styles.aboutPageContainer}>
            <Grid item xs={12} sx={{ marginTop: '18px' }}>
              <Typography variant="subtitle2">
                End user license agreement
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '8px' }}>
              <Typography variant="subtitle2">Privacy Policy</Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '12px' }}>
              <Typography variant="subtitle2">Legal Notes</Typography>
            </Grid>
            <Grid item xs={3} sx={{ ...styles.logo, marginTop: '6px' }}>
              <img src={GozioLogo} alt="Gozio Health" />
            </Grid>
            <Grid item xs={9} sx={{ marginTop: '6px' }}>
              <Typography variant="subtitle2" sx={styles.logoText}>
                Powered by Gozio Health
              </Typography>
              <Typography variant="subtitle2" sx={styles.logoLink}>
                Learn more about Gozio
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        {isMoreMenu && (
          <Box
            sx={{
              ...styles.moreMenuContent,
              background: configColors.moreMenuBackground,
              color: configColors.moreMenuText,
              height: `${isAboutUs ? 622.5 : 591}px`,
              top: `${isAboutUs ? 104.5 : 136}px`,

              '& .MuiListItem-root:hover p': {
                color: configColors.moreMenuText,
              },
            }}
          >
            <Typography sx={styles.moreMenuTitle} variant="body1">
              More Actions
            </Typography>
            <GozioListItem
              sx={styles.listItem}
              enableReorder={false}
              options={moreMenuLinks}
              canHover={false}
              showTooltip={false}
            />
          </Box>
        )}
        <Box sx={styles.shadowContainer} />
        <Box sx={styles.previewContent}>
          <img
            src={PhoneFrame1x}
            srcSet={`${PhoneFrame2x} 2x, ${PhoneFrame3x} 3x`}
            alt="phone"
          />
        </Box>
      </>
    );
  }, [
    aboutUsSupportingImageUrl,
    styles,
    configColors,
    editPanelTabValue,
    isAboutUs,
    isMoreMenu,
    moreMenuLinks,
  ]);

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      {title && (
        <Box sx={{ ...styles.pageTitle, left: 16 + leftOffset }}>
          <Typography variant="h1" display="block">
            {title}
          </Typography>
        </Box>
      )}
      <Box sx={{ ...styles.content, height: fixed ? '1000px' : undefined }}>
        {(isMoreMenu || isAboutUs) && (
          <Box sx={{ ...styles.moreMenuTop, top: `${moreMenuTop}px` }}></Box>
        )}
        {incompleteMessage && (
          <WarningPanel
            message={incompleteMessage}
            disableClose={true}
            sx={{ position: 'absolute', top: '-60px' }}
          />
        )}
        <Typography variant="subtitle1">Mobile Preview</Typography>
        <Box sx={styles.previewContainer}>
          {previewContent}
          <Box sx={styles.bottomSection}>
            <Grid container>{navLinks}</Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BottomNavPreview.propTypes = {
  configColors: PropTypes.object,
  data: PropTypes.object,
  editPanelTabValue: PropTypes.number,
  fixed: PropTypes.bool,
  incompleteMessage: PropTypes.string,
  selectedItem: PropTypes.object,
  title: PropTypes.string,
  sx: PropTypes.object,
  type: PropTypes.oneOf([BOTTOM_NAVIGATION_LINKS, MORE_MENU_LINKS]),
};

BottomNavPreview.defaultProps = {
  configColors: {},
  data: {},
  editPanelTabValue: 0,
  fixed: false,
  incompleteMessage: null,
  title: null,
  sx: {},
  type: BOTTOM_NAVIGATION_LINKS,
};

export default BottomNavPreview;
