import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.white}`,
    borderRadius: '8px',
    display: 'inline-block',
    padding: '11px',
    verticalAlign: 'top',
    width: '231px',
  },
  content: {
    alignItems: 'center',
    background: theme.palette.grey[100],
    border: `1.5px dashed ${theme.palette.grey[400]}`,
    borderRadius: '8px',
    color: theme.palette.grey[400],
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
    height: '133px',
    justifyContent: 'center',
    textAlign: 'center',
    width: '209px',
  },
  description: {
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    marginTop: theme.spacing(1.5),
    textAlign: 'center',
  },
}));

const MediaEmptyPlaceholder = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        No Image
        <br />
        Available
      </Box>
      <Box className={classes.description}>
        <Asterisk /> An Image is required for
        <br />
        Publishing.
      </Box>
    </Box>
  );
};

export default MediaEmptyPlaceholder;
