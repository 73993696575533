import { Box, Grid, Modal, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DetailFormFooter from 'components/forms/detailFormFooter';
import TabPanel from 'components/tabPanel/tabPanel';
import { useCreateNetworkDeepLink, useUpdateNetworkDeepLink } from 'hooks/dataHooks/useNetworkNetworkDeepLink';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import DeepLinkHeader from 'pages/deepLinks/deepLink/deepLinkHeader';
import AnalyticsTagsSection, {
  buildAnalyticsTagsInitialValues,
  buildAnalyticsTagsUpdateInput,
  DEEP_LINK_ANALYTICS_TAGS_SCHEMA,
} from 'pages/deepLinks/deepLink/sections/analyticsTagsSection';
import LinkContentSection, {
  buildLinkContentInitialValues,
  buildLinkContentUpdateInput,
  DEEP_LINK_LINK_CONTENT_SCHEMA,
} from 'pages/deepLinks/deepLink/sections/linkContentSection';
import LinkNameSection, {
  buildLinkNameInitialValues,
  buildLinkNameUpdateInput,
  DEEP_LINK_LINK_NAME_SCHEMA,
} from 'pages/deepLinks/deepLink/sections/linkNameSection';
import { mergeMaps } from 'pages/deepLinks/deeplinkHelper';
import DetailTableOfContents from 'pages/locations/containers/detail/detailTableOfContents';
import RenderedSection from 'pages/locations/containers/renderedSection';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[100],
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  tabsContainer: {
    height: '100vh',
  },
  tabPanel: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  panelBody: {
    maxWidth: '1400px',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  scrollBody: {
    margin: '0 24px',
    height: '100%',
    overflowX: 'auto',
  },
  formBody: {
    margin: '0 3px 93px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: '0 24px 500px',
  },
  sidebar: {
    position: 'absolute',
    top: '32px',
    left: '42px',
  },
  content: {
    paddingLeft: '295px',
  },
});

const SECTIONS = [
  {
    text: 'Name Your Link',
    hash: 'linkName',
    component: LinkNameSection,
    schema: DEEP_LINK_LINK_NAME_SCHEMA,
    buildInitialValues: buildLinkNameInitialValues,
    buildUpdateInput: buildLinkNameUpdateInput,
  },
  {
    text: 'Analytics Tags',
    hash: 'analyticsTags',
    component: AnalyticsTagsSection,
    schema: DEEP_LINK_ANALYTICS_TAGS_SCHEMA,
    buildInitialValues: buildAnalyticsTagsInitialValues,
    buildUpdateInput: buildAnalyticsTagsUpdateInput,
  },
  {
    text: 'Link Content',
    hash: 'linkContent',
    component: LinkContentSection,
    schema: DEEP_LINK_LINK_CONTENT_SCHEMA,
    buildInitialValues: buildLinkContentInitialValues,
    buildUpdateInput: buildLinkContentUpdateInput,
  },
];

const DEEP_LINK_SCHEMA = Yup.object().shape(mergeMaps(SECTIONS, 'schema'));

const AddOrEditDeepLinkModal = ({
                                  config,
                                  data,
                                  handleClose,
                                  networkId,
                                  onSave,
                                }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const location = useLocation();

  const [initialValues, setInitialValues] = useState(null);
  const [linkContentImage, setLinkContentImage] = useState(null);
  const [activeSection, setActiveSection] = useState(location.hash);
  const scrollRef = useRef(0);

  const [createNetworkDeepLink] = useCreateNetworkDeepLink();
  const [updateNetworkDeepLink] = useUpdateNetworkDeepLink();

  const isEdit = data?.id;

  useEffect(() => {
    if (!initialValues) {
      setInitialValues(
        mergeMaps(SECTIONS, 'buildInitialValues', { config, data }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    const scrollElement = document.getElementById('detail-scroll');
    if (scrollElement) {
      scrollElement.scrollTop = scrollRef.current;
    }
  }, []);
  const validate = makeValidate(DEEP_LINK_SCHEMA);

  const onSubmit = async (values) => {
    let uploadedImage = null;
    if (linkContentImage?.file) {
      uploadedImage = await uploadImage(linkContentImage.file);
      setLinkContentImage(null);
    }

    const sectionInputs = mergeMaps(SECTIONS, 'buildUpdateInput', {
      values,
      uploadedImage,
    });
    const input = {
      ...sectionInputs,
      referringLink: `https://${initialValues.shortUrlDomain}/${sectionInputs.linkAlias}`,
      deepLinkConfig: config.id,
      isVisible: sectionInputs.isVisible === undefined ? true : sectionInputs.isVisible,
    };

    if (data?.id) {
      delete input.linkAlias;
      await updateNetworkDeepLink({
        variables: {
          id: data.id,
          input,
        },
      });
    } else {
      await createNetworkDeepLink({
        variables: {
          input,
        },
      });
    }

    onSave();
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="addOrEditDeepLinkModal"
      aria-describedby={`${isEdit ? 'Edit' : 'Add'} a deep link`}
    >
      <Box>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ form, handleSubmit, invalid, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box sx={styles.root}>
                <DeepLinkHeader
                  config={config}
                  handleBack={handleClose}
                  title={isEdit ? 'Edit Deep Link' : 'Create Deep Link'}
                  values={values}
                />
                <Box
                  sx={{
                    ...styles.tabsContainer,
                    paddingTop: '205px',
                  }}
                >
                  <TabPanel value={0} index={0} sx={styles.tabPanel}>
                    <Box sx={styles.panelBody}>
                      <Box sx={styles.scrollBody} id="detail-scroll">
                        <Box sx={styles.sidebar}>
                          <DetailTableOfContents
                            items={SECTIONS}
                            data={data}
                            activeState={activeSection}
                            setActiveState={setActiveSection}
                          />
                        </Box>
                        <Paper
                          sx={{
                            ...styles.formBody,
                            paddingBottom: `calc(100vh - 499px)`,
                          }}
                        >
                          <Box sx={styles.content}>
                            <Grid container direction="column">
                              {SECTIONS.map((section) => (
                                <RenderedSection
                                  key={`section-${section.hash}`}
                                  section={section}
                                  config={config}
                                  data={data}
                                  form={form}
                                  networkId={networkId}
                                  {...(section.text === 'Link Content' && {
                                    compProps: {
                                      onImageUploaded: ({ file, url }) => {
                                        setLinkContentImage({
                                          file,
                                          iconUrl: url,
                                        });
                                      },
                                    },
                                  })}
                                />
                              ))}
                            </Grid>
                          </Box>
                        </Paper>
                      </Box>
                    </Box>
                    <DetailFormFooter
                      confirmText="Save & Continue"
                      disableConfirm={submitting || pristine || invalid}
                      showBuildVersion={false}
                      submitting={submitting}
                      onCancel={handleClose}
                      onSubmit={async () => {
                        await handleSubmit();
                      }}
                    />
                  </TabPanel>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </Box>
    </Modal>
  );
};

AddOrEditDeepLinkModal.propTypes = {
  config: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  networkId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

AddOrEditDeepLinkModal.defaultProps = {
  data: null,
};

export default AddOrEditDeepLinkModal;
