import { Box } from '@mui/material';
import {
  LargeToggleButton,
  LargeToggleButtonGroup,
} from 'components/toggleButtonGroup/toggleButtonGroup';
import React from 'react';

const renderLargeToggle = ({ input, options, allowUnselect = true }) => {
  const handleChange = (e, val) => {
    if (val === null && !allowUnselect) {
      e.preventDefault();
    } else {
      const option = options.find((opt) => opt.value === val);
      if (!option || option.disabled) {
        e.preventDefault();
      } else {
        input.onChange(val);
      }
    }
  };
  return (
    <LargeToggleButtonGroup
      exclusive
      value={input.value}
      onChange={handleChange}
    >
      {options.map((opt) => (
        <LargeToggleButton key={opt.value} value={opt.value}>
          <Box>{opt.label}</Box>
        </LargeToggleButton>
      ))}
    </LargeToggleButtonGroup>
  );
};

export default renderLargeToggle;
