import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import GenericModal from 'components/genericModal/genericModal';
import Loading from 'components/loading/loading';
import GozioTable from 'components/tables/gozioTable';
import { getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import {
  useUpdateWaitTimeFeedPlace,
  useUpdateWaitTimeFeedSettings,
  useWaitTimeFeedPlaces,
  useWaitTimeFeedSettings,
} from 'hooks/dataHooks/useWaitTimeFeed';
import useToast from 'hooks/useToast';
import EditFeedModal from 'pages/network/configuration/containers/editFeedModal';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const buildStyles = () => ({
  root: { height: '100%' },
  addButton: {
    marginLeft: '16px',
    marginRight: '4px',
    paddingTop: '12px!important',
    width: '36px',
    height: '36px',
  },
  settingsRow: {
    padding: '24px',
  },
  tableWrap: {
    height: 'calc(100% - 154px)',
  },
});

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    minWidth: 80,
    width: 80,
    disableSortBy: true,
    justifyRight: true,
  },
];

const URL_REGEX = new RegExp(
  '^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$',
  'i',
);

const isUrl = (str) => !str || URL_REGEX.test(str);

const WaitTimeFeed = ({ networkId }) => {
  const styles = buildStyles();
  const [editedFeed, setEditedFeed] = useState(null);
  const [deletedFeed, setDeletedFeed] = useState(null);
  const [timeFeedUrl, setTimeFeedUrl] = useState('');
  const { toastNotificationSuccessHook } = useToast();
  const [timeConnectorUrl, setTimeConnectorUrl] = useState('');
  const {
    data: settingsData,
    loading: settingsLoading,
    refetch: getWaitTimeFeedSettings,
  } = useWaitTimeFeedSettings(networkId);
  const {
    data: placesData,
    loading: placesLoading,
    refetch: getWaitTimeFeedPlaces,
  } = useWaitTimeFeedPlaces(networkId);
  const [updateWaitTimeFeed] = useUpdateWaitTimeFeedSettings(networkId);
  const [updateNetworkPlace] = useUpdateWaitTimeFeedPlace(networkId);

  useEffect(() => {
    if (settingsData) {
      const connectorUrl = settingsData.waittimeEndpointUrl || '';
      setTimeConnectorUrl(connectorUrl);
      const feedUrl = settingsData.waittimeFeed || '';
      setTimeFeedUrl(feedUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData]);

  useEffect(() => {
    getWaitTimeFeedSettings();
    getWaitTimeFeedPlaces();
  }, [getWaitTimeFeedSettings, getWaitTimeFeedPlaces]);

  const showEdit = (feed) => {
    setEditedFeed(feed);
  };

  const doDelete = async () => {
    const result = await updateNetworkPlace({
      variables: {
        id: deletedFeed.id,
        input: {
          waitTimeToken: null,
        },
      },
    });
    if (result.data) toastNotificationSuccessHook('Wait time feed deleted');
    setDeletedFeed(null);
  };

  const showDelete = (feed) => {
    setDeletedFeed(feed);
  };

  const showAdd = () => {
    setEditedFeed({});
  };

  const processData = () => placesData
      .map((place) => ({
        id: place?.id,
        location: getLabelByLang(place?.name),
        key: place.waitTimeToken,
        actions: (
          <Box className="hoverUnhide">
            <Tooltip title="Edit">
              <Edit
                onClick={() => showEdit(place)}
                sx={{ marginRight: '8px' }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete onClick={() => showDelete(place)} />
            </Tooltip>
          </Box>
        ),
      }))
      .filter((p) => p.key);

  const memoizedData = useMemo(
    () => processData(),
    [placesData, networkId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const submitTimeFeed = async () => {
    await updateWaitTimeFeed({
      variables: {
        input: {
          waittimeEndpointUrl: timeConnectorUrl,
          waittimeFeed: timeFeedUrl,
        },
      },
    });
  };

  const count = memoizedData?.length || 0;

  if (settingsLoading) return <Loading />;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.settingsRow}>
        <Typography variant="subtitle1" sx={{ marginBottom: '26px' }}>
          Feed Parameters
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Wait Time Connector"
            variant="outlined"
            onChange={(e) => setTimeConnectorUrl(e.target.value)}
            type="url"
            sx={{ width: '33%' }}
            value={timeConnectorUrl}
          />
          <TextField
            label="Wait Time Feed"
            variant="outlined"
            onChange={(e) => setTimeFeedUrl(e.target.value)}
            type="url"
            sx={{ width: '33%', marginLeft: '24px' }}
            value={timeFeedUrl}
          />
          <Button
            onClick={submitTimeFeed}
            variant="contained"
            color="primary"
            disabled={!isUrl(timeConnectorUrl) || !isUrl(timeFeedUrl)}
            sx={{ marginLeft: '24px', minWidth: '120px' }}
            placeholder="Time Feed"
            data-test="setFeedBtn"
          >
            SET FEED
          </Button>
        </Box>
      </Box>
      <Box sx={styles.tableWrap}>
        <GozioTable
          name="FeedTable"
          columns={COLUMNS}
          data={memoizedData}
          loading={placesLoading}
          sortBy={[{ id: 'location', desc: false }]}
          countTitle={getTableCountTitle(count, 'Wait Time Feed Record')}
          headerActions={
            <AddCircleButton
              onClick={() => showAdd()}
              aria-label="add-color"
              sx={styles.addButton}
              tooltipTitle="Add a Wait Time Feed"
            />
          }
          filterable={true}
        />
      </Box>
      {editedFeed && (
        <EditFeedModal
          feed={editedFeed}
          locations={placesData}
          handleClose={() => setEditedFeed(null)}
          networkId={networkId}
        />
      )}
      {deletedFeed && (
        <GenericModal
          title="Delete Wait Time Feed"
          body={`Are you sure feed ${
            deletedFeed.waitTimeToken
          } for ${getLabelByLang(
            deletedFeed.name,
          )} is no longer in use and you want to proceed with deleting it?`}
          open={true}
          handleClose={() => setDeletedFeed(null)}
          handleConfirm={doDelete}
          confirmText="Delete Wait Time Feed"
          customTags={{
            confirm: 'FeedDeleteModalConfirmButton',
            cancel: 'FeedDeleteModalCancelButton',
          }}
        />
      )}
    </Box>
  );
};

WaitTimeFeed.propTypes = {
  networkId: PropTypes.string.isRequired,
};

export default WaitTimeFeed;
