import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { makeValidate } from 'mui-rff';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import { useUpdateNetworkConfigurationGozioLinkControl } from 'hooks/dataHooks/useNetworkConfiguration';
import { useWorkspace } from 'hooks/useWorkspace';

const publishSettingsSchema = Yup.object().shape({
  setting: Yup.string().oneOf(['enabled', 'gozioAdmin', 'disabled']),
});

const validate = makeValidate(publishSettingsSchema);

const DEEP_LINK_STATE_MAP = {
	ENABLED: 'enabled',
	ADMIN: 'gozioAdmin',
	DISABLED: 'disabled',
};

export const DeepLinksControlModal = ({
                                          handleClose,
                                          deepLinksControl,
                                          handleSave,
                                        }) => {
  const [updateNetworkConfigurationGozioLinkControl] = useUpdateNetworkConfigurationGozioLinkControl();
  const { workspace } = useWorkspace();
  const onSubmit = async (data) => {
    await updateNetworkConfigurationGozioLinkControl({
      variables: {
        id: deepLinksControl.id,
        input: {
          gozioLinkControl: { [`${workspace}Workspace`]: data.setting },
        },
      },
    });
    handleSave();
  };

  const radioGroupOptions = [
    {
      label: 'Enabled for All',
      value: DEEP_LINK_STATE_MAP.ENABLED,
    },
    {
      label: 'Enabled for Gozio Admins Only',
      value: DEEP_LINK_STATE_MAP.ADMIN,
    },
    {
      label: 'Disabled for All',
      value: DEEP_LINK_STATE_MAP.DISABLED,
    },
  ];

  return (
    <GenericFormModal
      title="Link Manager Settings"
      open={true}
      formParams={{
        onSubmit,
        validate,
        initialValues: {
          setting: deepLinksControl?.data?.liveWorkspace,
        },
      }}
      body={
        <Grid container direction="column">
          <Grid item sx={{ marginTop: '-24px' }}>
            <Typography variant="body1">
              Lorem ipsum
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Ability to configure Links</Typography>
            <Field name="setting">
              {({ input }) => (
                <GozioRadioGroup
                  {...input}
                  alignment="vertical"
                  options={radioGroupOptions}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      }
      confirmText="Save Settings"
      handleClose={handleClose}
    />
  );
};

export default DeepLinksControlModal;
