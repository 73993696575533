import { Add, GroupAdd } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    flexGrow: 1,
    width: '100%',
  },
  content: {
    minHeight: 'calc(100vh - 112px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.grey[500],
    width: '128px',
    height: '128px',
  },
  subtitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  button: {
    marginTop: '24px',
  },
}));

const EmptyPersona = ({
                        enabled,
                        isActive,
                        buttonColor,
                        buttonSize,
                        handleAddPersona,
                      }) => {
  const classes = useStyles();

  if (enabled) {
    if (isActive) {
      return (
        <Box className={classes.root}>
          <Grid container className={classes.content}>
            <Grid item xs={12}>
              <GroupAdd className={classes.icon} />
              <Typography variant="h1">
                You haven’t added any personas
              </Typography>
              <Button
                variant="contained"
                color={buttonColor}
                size={buttonSize}
                className={classes.button}
                onClick={handleAddPersona}
                data-test="emptyPersonaAddPersonaBtn"
              >
                <Add />
                Add Persona
              </Button>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box className={classes.root}>
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <GroupAdd className={classes.icon} />
            <Typography variant="h1" className="hero">
              You don’t have any personas yet
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              No personas have been added for your network. Please contact your
              <br />
              Gozio representative for more information.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <GroupAdd className={classes.icon} />
          <Typography variant="h1" className={classes.title}>
            Personas is not enabled
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Personas create customized app experiences for the different
            audiences of
            <br />
            your mobile app. Contact your Gozio representative to learn more.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

EmptyPersona.propTypes = {
  enabled: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  handleAddPersona: PropTypes.func,
};

EmptyPersona.defaultProps = {
  enabled: false,
  buttonColor: 'primary',
  buttonSize: 'large',
};

export default EmptyPersona;
