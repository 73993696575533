import { Box, Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const I18N_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'More Menu Link Name',
    required: true,
    charLimit: 45,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'More Menu Link Name (Spanish)',
    required: false,
    charLimit: 45,
  },
];

export const AboutUsGeneralTabPanel = ({
                                         classes,
                                         index,
                                         languages,
                                         networkId,
                                         onIconUploaded,
                                         tabValue,
                                       }) => (
  <TabPanel id="tabPanel-general" className={classes.tabPanel} value={tabValue} index={index}>
    <Box className={classes.fieldArea}>
      <Grid item xs>
        <Typography variant="subtitle1">General</Typography>
      </Grid>
      <Grid item xs className={classes.formFields}>
        {I18N_FIELDS.map(({ langKey, lang, label, charLimit, required }, i) => {
          if (!languages.includes(lang) && !isGlobalTemplate(networkId)) {
            return null;
          }
          return (
            <Grid item xs={12} className={classes.field} key={`field_${i}`}>
              <Field name={langKey}>
                {({ input, meta }) => (
                  <TextField
                    label={
                      required ? (
                        <span>
                          <Asterisk /> {label}
                        </span>
                      )
                        : label

                    }
                    charLimit={charLimit}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    {...input}
                    fullWidth
                  />
                )}
              </Field>
            </Grid>
          );
        }).filter((l) => l)}
      </Grid>
    </Box>
    <Box className={classes.fieldSection}>
      <Grid item xs>
        <Typography variant="subtitle1">Link Display</Typography>
      </Grid>
      <Grid item xs sx={{ marginTop: '16px' }}>
        <Field name="iconUrl">
          {({ input: { onChange, value } }) => (
            <ImageUpLoader
              title={
                <span>
                  <Asterisk /> Supporting Icon
                </span>
              }
              display="inline"
              image={value ? { url: value } : null}
              fileInfo="Supported files includes jpeg and png. File size should be no larger than 50KB. Image dimensions must be at least 120px by 120px."
              isIcon={true}
              onImageUpdated={({ file, url }) => {
                onChange(url);
                onIconUploaded({ file, url });
              }}
              maxFileSize={50000}
              minHeight={120}
              minWidth={120}
              allowDelete={false}
              showImage={true}
              previewWidth={64}
              previewHeight={64}
            />
          )}
        </Field>
      </Grid>
    </Box>
  </TabPanel>
);

AboutUsGeneralTabPanel.propTypes = {
  classes: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  networkId: PropTypes.string,
  onIconUploaded: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

AboutUsGeneralTabPanel.defaultProps = {
  classes: {},
  index: 0,
  tabValue: 0,
};
