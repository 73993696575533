import { useMutation, useSuspenseQuery } from '@apollo/client';
import { getLabelByLang } from 'helpers/lang-util';
import { formatDesignation } from 'helpers/location-util';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useWorkspace } from 'hooks/useWorkspace';
import {
  FIND_NETWORK_PLACE_SITES,
  CREATE_NETWORK_PLACE,
  DELETE_NETWORK_PLACE,
} from 'graphql/queries';

export const useSites = ({
                           networkId,
                           query = FIND_NETWORK_PLACE_SITES,
                           workspace = null,
                         }) => {
  const { workspace: realWorkspace } = useWorkspace();
  const { handleError } = useHandleError('Sites');
  const { data, error, refetch } = useSuspenseQuery(query, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
    variables: {
      where: {
        designation: 'site',
        workspaces: {
          name: workspace ?? realWorkspace,
        },
      },
    },
  });
  return {
    count: data?.findNetworkPlace?.count || 0,
    error,
    refetch,
    sites: data?.findNetworkPlace?.edges?.map(({ node }) => node) || [],
  };
};

export const useCreateNetworkPlace = (type = 'site') => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkPlace');

  return useMutation(CREATE_NETWORK_PLACE, {
    refetchQueries: [{ query: FIND_NETWORK_PLACE_SITES, variables: {} }],
    onCompleted: (data) => {
      toastNotificationSuccessHook(`The ${type} has been created.`);
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useDeleteNetworkPlace = ({
                                        onCompleted,
                                        onCompletedMessage,
                                        onError: onErrorCustomCallback,
                                        onErrorMessage,
                                      } = {}) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkPlace');
  return useMutation(DELETE_NETWORK_PLACE, {
    // eslint-disable-next-line no-unsafe-optional-chaining
    onCompleted:
      onCompleted
      || ((data) => {
        const { designation, name } = data?.deleteNetworkPlace ?? {};
        toastNotificationSuccessHook(
          onCompletedMessage
          || `The ${formatDesignation(designation)} "${getLabelByLang(
            name,
          )}" has been deleted.`,
        );
      }),
    onError: ({ graphQLErrors, networkError }) => {
      if (onErrorCustomCallback) onErrorCustomCallback({ graphQLErrors, networkError });
      handleError(graphQLErrors, networkError, null, onErrorMessage);
    },
  });
};

export default useSites;
