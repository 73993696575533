import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import LinkDestination from 'components/forms/linkDestination';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import TextField from 'components/textField/textField';
import { buildLabelByLang, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { useUpdateNavigationItem, useUpdateNavigationTab } from 'hooks/dataHooks/useNavigation';
import useToast from 'hooks/useToast';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  BOTTOM_NAVIGATION_LINKS,
  buildNavigationTab,
  getBottomNavLinkSchema,
  MORE_MENU_LINKS,
} from 'pages/mobileSettings/bottomNavigation/bottomNavigationHelper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiPaper-root': {
      height: '100%',
      overflowX: 'hidden',
    },
  },
  content: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  fieldArea: {
    marginTop: theme.spacing(3.25),
  },
  formFields: {
    marginTop: theme.spacing(3.25),
  },
  field: {
    marginBottom: theme.spacing(4),
  },
  fieldSection: {
    marginBottom: theme.spacing(4),
  },
}));

export const TAB_I18N_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'Bottom Navigation Link Name',
    required: true,
    charLimit: 10,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'Bottom Navigation Link Name (Spanish)',
    required: false,
    charLimit: 10,
  },
];

export const ITEM_I18N_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'More Menu Link Name',
    required: true,
    charLimit: 45,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'More Menu Link Name (Spanish)',
    required: false,
    charLimit: 45,
  },
];

const buildInitialValues = (data, languages) => {
  const values = {
    data: data?.data,
    langES: hasSpanish(languages),
    iconUrl: data?.icon?.url || null,
    linkType: data?.type,
    nameEn: getLabelByLang(data?.name),
    nameEs: getLabelByLang(data?.name, LANGUAGE_CODES.SPANISH),
    type: data?.type,
    categories: [],
    inAppDest: '',
    listDest: '',
    externalURL: '',
    externalType: '',
    externaliOSURL: '',
    externalAndroidURL: '',
    fallbackiOSURL: '',
    fallbackAndroidURL: '',
    hasPlatformSpecificFallbacks: false,
    phone: '',
    webUrlEn: '',
    webUrlEs: '',
    webType: '',
    custom: '',
  };
  switch (data.type) {
    case 'category':
      values.categories = data.categories;
      break;
    case 'in_app':
      values.inAppDest = data.data.id || '';
      break;
    case 'list':
      values.listDest = data.data.id || '';
      break;
    case 'external_app':
      values.externalURL = data.data.url;
      values.externalAndroidURL = data.data.androidUrl;
      values.externaliOSURL = data.data.iosUrl;
      values.externalType = data.data.client;
      values.fallbackAndroidURL = data.data.androidFallbackUrl;
      values.fallbackiOSURL = data.data.iosFallbackUrl;
      values.hasPlatformSpecificFallbacks = !!(
        data.data.androidFallbackUrl || data.data.iosFallbackUrl
      );
      break;
    case 'website':
      values.webUrlEn = data.data.url?.en;
      if (hasSpanish(languages)) {
        values.webUrlEs = data.data.url?.es;
      }
      values.webType = data.data.client;
      break;
    case 'phone':
      values.phone = data.data.phone;
      break;
    case 'custom':
      values.custom = data.data.url;
      break;
    default:
      break;
  }

  return values;
};

const EditBottomNavLinkPanel = ({
                  data,
                  languages,
                  navId,
                  onEditCardCancelled,
                  onEditCardChanged,
                  onEditCardSaved,
                  title: incomingTitle,
                  type,
                }) => {
  const classes = useStyles();
  const validate = makeValidate(getBottomNavLinkSchema(type, languages));

  const [title] = useState(incomingTitle);
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  const { networkId } = useParams();
  const { toastNotificationErrorHook, toastNotificationSuccessHook }
    = useToast();
  const [updateNavigationTab] = useUpdateNavigationTab(networkId);
  const [updateNavigationItem] = useUpdateNavigationItem(networkId);

  useEffect(() => {
    if (data && Object.keys(initialValues).length === 0) {
      setInitialValues(buildInitialValues(data, languages));
    }
  }, [data, languages, initialValues]);

  const onSubmit = async (values = {}) => {
    setIsSubmitting(true);
    const updatedData = {
      id: data?.id,
      name: buildLabelByLang(values, 'nameEn', 'nameEs'),
      order: data?.order,
      type: values.type || undefined,
      ...buildNavigationTab(values, languages),
    };

    if (uploadedIcon?.file) {
      const iconImage = await uploadImage(
        uploadedIcon.file,
        toastNotificationErrorHook,
      );
      updatedData.icon = iconImage?.id;
    } else {
      updatedData.icon = data?.icon?.image?.id;
    }

    const updateFunc
      = type === BOTTOM_NAVIGATION_LINKS
        ? updateNavigationTab
        : updateNavigationItem;
    const result = await updateFunc({
      variables: {
        id: navId,
        input: updatedData,
      },
    });

    setUploadedIcon(null);
    if (result.data) {
      toastNotificationSuccessHook(
        <>
          Your progress on the{' '}
          {type === BOTTOM_NAVIGATION_LINKS ? 'Bottom Navigation' : 'More Menu'}{' '}
          Link
          <strong style={{ marginLeft: '4px', marginRight: '4px' }}>
            {values.nameEn}
          </strong>
          has been saved.
        </>,
      );

      const dataKey = type === BOTTOM_NAVIGATION_LINKS ? 'tabs' : 'items';
      const resultKey = isGlobalTemplate(networkId) ? 'System' : 'Network';
      const list = result.data[`set${resultKey}NavigationItem`]
        ? result.data[`set${resultKey}NavigationItem`][dataKey]
        : result.data[`set${resultKey}NavigationTab`][dataKey];
      const tabOrItem = data?.id
        ? list.find((n) => n.id === data.id)
        : list[list.length - 1];
      setInitialValues(buildInitialValues(tabOrItem, languages));
      onEditCardSaved(tabOrItem, values);
    }
    setIsSubmitting(false);
  };

  const buildNavLinkValues = (form, iconUrl = null) => {
    const { nameEn, nameEs, ...rest } = form.getState().values;
    const icon = iconUrl
      ? { url: iconUrl }
      : uploadedIcon
        ? { url: uploadedIcon.iconUrl }
        : data.icon;

    return {
      id: data.id,
      name: buildLabelByLang({ nameEn, nameEs }, 'nameEn', 'nameEs'),
      icon,
      ...rest,
    };
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const i18nFields
    = type === BOTTOM_NAVIGATION_LINKS ? TAB_I18N_FIELDS : ITEM_I18N_FIELDS;

  const handleConfirm = async (form) => {
    await form.submit();
    await form.reset();
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={data.id ? validate : null}
    >
      {({
          handleSubmit,
          form,
          values,
          submitting,
          pristine,
          invalid,
          errors,
        }) => (
        <form
          className={classes.root}
          onSubmit={handleSubmit}
          onChange={() => onEditCardChanged(buildNavLinkValues(form))}
        >
          <FormWatcher
            formRenderProps={{ form }}
            errorFormFieldMap={
              new Map([
                [
                  'nameEn',
                  {
                    label:
                      type === BOTTOM_NAVIGATION_LINKS
                        ? 'Bottom Navigation Link Name'
                        : 'More Menu Link Name',
                    section: 'General',
                  },
                ],
                [
                  'nameEs',
                  {
                    label:
                      type === BOTTOM_NAVIGATION_LINKS
                        ? 'Bottom Navigation Link Name (Spanish)'
                        : 'More Menu Link Name (Spanish)',
                    section: 'General',
                  },
                ],
                [
                  'externaliOSURL',
                  { label: 'iOS URL', section: 'External App Details' },
                ],
                [
                  'externalAndroidURL',
                  { label: 'Android URL', section: 'External App Details' },
                ],
                [
                  'externalURL',
                  { label: 'Fallback URL', section: 'External App Details' },
                ],
                [
                  'webUrlEn',
                  { label: 'Website URL', section: 'Website Details' },
                ],
                [
                  'webUrlEs',
                  {
                    label: 'Website URL (Spanish)',
                    section: 'Website Details',
                  },
                ],
                [
                  'webType',
                  { label: 'Web Type', section: 'Website Preferences' },
                ],
                ['phone', { label: 'Phone Number', section: 'Phone' }],
              ])
            }
            isClosing={isClosing}
            isSubmitting={isSubmitting}
            onClose={() => {
              setIsClosing(false);
              setIsSubmitting(false);
            }}
            onClosed={() => {
              setIsClosing(false);
              setIsSubmitting(false);
              onEditCardCancelled();
            }}
            onCorrect={() => setIsClosing(false)}
            onContinue={onEditCardCancelled}
          />

          <GozioDrawer
            open={true}
            disableCancel={true}
            greyOutConfirm={pristine || submitting || invalid}
            onClose={() => setIsClosing(true)}
            onConfirm={() => handleConfirm(form)}
            title={title}
            top={70}
            width={568}
          >
            <Box className={classes.content}>
              <Box className={classes.fieldArea}>
                <Grid item xs>
                  <Typography variant="subtitle1">General</Typography>
                </Grid>
                <Grid item xs className={classes.formFields}>
                  {i18nFields
                    .map(({ langKey, lang, label, charLimit, required }, i) => {
                      if (
                        !languages.includes(lang)
                        && !isGlobalTemplate(networkId)
                      ) {
                        return null;
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          className={classes.field}
                          key={`field_${i}`}
                        >
                          <Field name={langKey}>
                            {({ input, meta }) => (
                              <TextField
                                label={
                                  required ? (
                                    <span>
                    <Asterisk /> {label}
                  </span>
                                  )
                                    : label

                                }
                                charLimit={charLimit}
                                helperText={meta.touched ? meta.error : ''}
                                error={meta.touched && !!meta.error}
                                {...input}
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>
                      );
                    })
                    .filter((l) => l)}
                </Grid>
              </Box>
              <Box className={classes.fieldSection}>
                <Grid item xs>
                  <Typography variant="subtitle1">Display</Typography>
                </Grid>
                <Grid item xs sx={{ marginTop: '16px' }}>
                  <Field name="iconUrl">
                    {({ input: { onChange, value } }) => (
                      <ImageUpLoader
                        title={
                          <span>
                            <Asterisk /> Supporting Icon
                          </span>
                        }
                        display="inline"
                        image={value ? { url: value } : null}
                        isIcon={true}
                        fileInfo="Supported files includes jpeg and png. File size should be no larger than 50KB. Image dimensions must be at least 120px by 120px."
                        onImageUpdated={({ file, url }) => {
                          setUploadedIcon({ file, iconUrl: url });
                          onChange(url);
                          onEditCardChanged(buildNavLinkValues(form, url));
                        }}
                        maxFileSize={50000}
                        minHeight={120}
                        minWidth={120}
                        allowDelete={false}
                        showImage={true}
                        previewWidth={64}
                        previewHeight={64}
                      />
                    )}
                  </Field>
                </Grid>
              </Box>
              <Box className={classes.fieldSection}>
                <LinkDestination
                  form={form}
                  hasCustomLink={!!initialValues.custom}
                  initialValues={initialValues}
                  supportedAppDestinations={
                    type === BOTTOM_NAVIGATION_LINKS
                      ? [
                        'dashboard',
                        'doctors',
                        'feedback',
                        'places',
                        'more_menu',
                        'mychart',
                      ]
                      : null
                  }
                  unsupportedAppDestinations={
                    type === BOTTOM_NAVIGATION_LINKS ? null : ['about']
                  }
                  supportedLinkTypes={
                    type === BOTTOM_NAVIGATION_LINKS
                      ? ['in_app', 'custom']
                      : null
                  }
                />
              </Box>
            </Box>
          </GozioDrawer>
        </form>
      )}
    </Form>
  );
};

EditBottomNavLinkPanel.propTypes = {
  navId: PropTypes.string,
  data: PropTypes.object.isRequired,
  onEditCardCancelled: PropTypes.func.isRequired,
  onEditCardChanged: PropTypes.func,
  onEditCardSaved: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([BOTTOM_NAVIGATION_LINKS, MORE_MENU_LINKS]),
};

EditBottomNavLinkPanel.defaultProps = {
  navId: null,
  onEditCardChanged: () => {
  },
  type: BOTTOM_NAVIGATION_LINKS,
};

export default EditBottomNavLinkPanel;
