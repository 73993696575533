import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CatChip from 'components/categories/catChip';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  selectedText: {
    color: theme.palette.grey[500],
    lineHeight: '23px',
    marginLeft: '2px',
  },
  numberText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: 0,
    marginLeft: '4px',
    marginRight: '12px',
    color: theme.palette.grey[500],
  },
}));

const calcFirstLineCatCount = (categories) => {
  if (categories.length === 0) return 0;
  let width = 80;
  let idx = 0;
  do {
    width = width - categories[idx].name.length - 10;
    if (width >= 0) idx++;
  } while (categories[idx] && width >= 0);
  return idx;
};

const CategorySelections = ({
                              selectedCategories,
                              title,
                              updateCategories,
                            }) => {
  const classes = useStyles();
  const deleteCategory = (currCatId) => {
    updateCategories(selectedCategories.filter((cat) => cat.id !== currCatId));
  };
  const firstLineCatCount = calcFirstLineCatCount(selectedCategories);
  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={
            selectedCategories.length > firstLineCatCount ? (
              <ExpandMoreIcon />
            ) : null
          }
        >
          <Typography variant="h4" className={classes.selectedText}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.numberText}>
            ({selectedCategories.length})
          </Typography>
          {selectedCategories.slice(0, firstLineCatCount).map((cat, idx) => (
            <CatChip
              key={`selected-chip-${idx}-${cat.id}`}
              category={cat}
              deleteCategory={deleteCategory}
            />
          ))}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {selectedCategories
              .slice(firstLineCatCount, selectedCategories.length)
              .map((cat, idx2) => (
                <CatChip
                  key={`selected-chip-${idx2}-${cat.id}`}
                  category={cat}
                  deleteCategory={deleteCategory}
                />
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

CategorySelections.propTypes = {
  selectedCategories: PropTypes.array,
  title: PropTypes.string,
  updateCategories: PropTypes.func,
};

CategorySelections.defaultProps = {
  title: 'Selected Categories',
  selectedCategories: [],
  updateCategories: () => {},
};

export default React.memo(CategorySelections);
