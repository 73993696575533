import { formatLang, parseByLang } from 'helpers/form-util';
import { hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { maskPhone, PhoneRegexp } from 'helpers/string-util';
import * as Yup from 'yup';

export const ACTION_LINK_SCHEMA = {
  linkType: Yup.string().required('Type is required'),
  name: Yup.string()
    .required('Name is required')
    .max(25, 'Name must be 25 characters or less'),
  nameES: Yup.string().max(25, 'Name must be 25 characters or less'),
  shortName: Yup.string()
    .required('Short Name is required')
    .max(10, 'Short Name must be 10 characters or less'),
  shortNameES: Yup.string().max(10, 'Short Name must be 10 characters or less'),
  phone: Yup.string()
    .nullable()
    .when('linkType', {
      is: 'phone',
      then: (schema) => schema
          .required('Phone Number is required')
          .matches(PhoneRegexp, 'Invalid phone number'),
    }),
  webUrlEn: Yup.string()
    .nullable()
    .when('linkType', {
      is: (linkType) => linkType === 'website' || linkType === 'appointment',
      then: (schema) => schema
          .required('Website URL is required')
          .url(
            'URL must include http or https, e.g. https://www.goziohealth.com',
          ),
    }),
  webUrlEs: Yup.string()
    .nullable()
    .when('linkType', {
      is: (linkType) => linkType === 'website' || linkType === 'appointment',
      then: (schema) => schema
          .notRequired()
          .url(
            'URL must include http or https, e.g. https://www.goziohealth.com',
          ),
    }),
  location: Yup.string()
    .nullable()
    .when('linkType', {
      is: (linkType) => linkType === 'in_app',
      then: (schema) => schema.required('Link Location is required'),
    }),
  custom: Yup.string()
    .nullable()
    .when('linkType', {
      is: (linkType) => linkType === 'custom',
      then: (schema) => schema.required('Open Field is required'),
    }),
  icon: Yup.mixed()
    .nullable()
    .when('linkType', {
      is: (linkType) => linkType === 'custom',
      then: (schema) => schema.required('Icon is required'),
    }),
};

export const initialParser = (LinkData, languages) => {
  if (!LinkData) return {};

  return {
    linkType: LinkData.type,
    showInDash: LinkData.showInDashboardView === true,
    phone: LinkData.data?.phone ? maskPhone(LinkData.data?.phone) : undefined,
    webUrlEn: LinkData.data?.url?.en,
    ...hasSpanish(languages) && {
      webUrlEs: LinkData.data?.url?.es,
    },
    nativeBrowser: LinkData.data?.client === 'native',
    location: LinkData.data?.id,
    icon: LinkData.icon,
    ...LinkData.type === 'custom' && {
      custom: LinkData.data?.url,
    },
    order: LinkData.order,
    idx: LinkData.idx,
    // ...!!parseByLang(LinkData?.name) && {
    name: parseByLang(LinkData?.name),
    // },
    ...!!parseByLang(LinkData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(LinkData?.name, LANGUAGE_CODES.SPANISH),
    },
    // ...!!parseByLang(LinkData?.shortName) && {
    shortName: parseByLang(LinkData?.shortName),
    // },
    ...!!parseByLang(LinkData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(LinkData?.shortName, LANGUAGE_CODES.SPANISH),
    },
  };
};

export const submitParser = (values = {}) => {
  const submitVal = {
    type: values.linkType,
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
    showInDashboardView: values.showInDash === true,
    order: values.order,
    idx: values.idx,
  };
  if (values.linkType === 'phone') {
    submitVal.data = { phone: values.phone?.replace(/[-)(\s]/g, '') };
  } else if (
    values.linkType === 'website'
    || values.linkType === 'appointment'
  ) {
    submitVal.data = {
      url: {
        en: values.webUrlEn ?? '',
        ...values.langES && {
          es: values.webUrlEs ?? '',
        },
      },
      client: values.nativeBrowser ? 'native' : 'custom',
    };
  } else if (values.linkType === 'in_app') {
    submitVal.showInDashboardView = undefined;
    submitVal.data = { id: values.location };
  } else if (values.linkType === 'custom') {
    submitVal.showInDashboardView = undefined;
    submitVal.icon = values.icon;
    submitVal.data = { url: values.custom };
  }
  return submitVal;
};
