import { Box, List, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SCOPES } from 'components/authorization/authorize';
import BuildVersion from 'components/forms/buildVersion';
import CollapseListItem from 'components/navigation/collapseListItem';
import CustomerMenu from 'components/navigation/customerMenu';
import SingleListItem from 'components/navigation/singleListItem';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { featureFlagCheck, USER_ROLES } from 'helpers/permissions-util';
import { ALL_WORKSPACES, DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import { useSearchParams } from 'hooks/useSearchParams';
import { useWorkspace } from 'hooks/useWorkspace';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '250px',
    background: `linear-gradient(-225deg, ${theme.palette.blue[400]} 0%, ${theme.palette.blue[500]} 100%)`,
  },
  list: {
    height: 'inherit',
    overflowY: 'auto',
    paddingBottom: '300px',
  },
  spacer: {
    alignItems: 'center',
    boxShadow: `0px 2px 1px -1px ${colorWithAlpha(
      theme.palette.grey[600],
      0.24,
    )}`,
    display: 'flex',
    height: '70px',
    justifyContent: 'center',
    padding: '0 14px',
  },
  drawerFooter: {
    alignItems: 'flex-end',
    bottom: 0,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    left: 0,
    padding: '10px',
    position: 'fixed',
    width: '250px',
  },
}));

const getDrawerItemValue = (location) => {
  if (location?.pathname) {
    const pathname = location.pathname;

    if (
      ['config', 'features', 'visual-controls', 'personas'].find(
        (path) => pathname.split('/').pop() === path,
      )
    ) {
      return 'networkNavOpen';
    }

    if (
      ['configuration', 'linkManager'].find(
        (path) => pathname.split('/').pop() === path && pathname.indexOf('deepLinks') !== -1,
      )
    ) {
      return 'deepLinksOpen';
    }

    if (
      ['categories', 'lists', 'typeahead'].find(
        (path) => pathname.split('/').pop() === path,
      )
    ) {
      return 'contentOpen';
    }

    if (
      ['pushNotification', 'bannerMessage'].find(
        (path) => pathname.split('/').pop() === path,
      )
    ) {
      return 'messagingCenter';
    }

    if (
      ['mobileDashboard', 'explore', 'navigation'].find(
        (path) => pathname.split('/').pop() === path && pathname.indexOf('analytics') === -1,
      )
    ) {
      return 'mobileOpen';
    }
  }
};

const SectionDrawer = React.memo(({ customerName, networkId, showMenu }) => {
  const classes = useStyles();
  const location = useLocation();
  const { closeSnackbar } = useSnackbar();
  const [drawerItem, setDrawerItem] = useState(getDrawerItemValue(location));
  const { activeNetwork, authorize, handleNavigate }
    = useContext(FlamingoContext);

  const networkFeatures = activeNetwork?.configuration?.features || {};
  const isGlobal = isGlobalTemplate(networkId);

  const { searchParams } = useSearchParams();
  const { workspace } = useWorkspace();
  const userRole = useCheckRole();

  const redirect
    = ({ url, retainSearchParams = true, isRelativePath = true }) => (e) => {
        e.preventDefault();
        closeSnackbar(); // closes all open toast notifications
        if (isRelativePath) {
          handleNavigate(`/network/${networkId}/${url}`);
        } else {
          window.open(url, '_blank');
        }
      };

  const handleClickContent = (name, route) => (e) => {
    e.preventDefault();
    const value = drawerItem === name ? 'clear' : name;
    setDrawerItem(value);
    if (route) {
      closeSnackbar(); // closes all open toast notifications
      handleNavigate(`/network/${networkId}/${route}`, {
        search: searchParams.toString(),
      });
    }
  };

  const supportsWorkspace = (item) => {
    if (item.workspace === ALL_WORKSPACES) {
      return true;
    }

    return item.workspace === workspace;
  };

  const isLiveWorkspace = workspace === LIVE_WORKSPACE;
  // const gozioLinkControl = activeNetwork?.configuration?.gozioLinkControl[WORKSPACE_SCHEMA_KEYS.LIVE];
  // const isUberAdmin = userRole === USER_ROLES.UBER_ADMIN;

  return (
    <Paper component="nav" square elevation={15} className={classes.root}>
      <Box className={classes.spacer}>
        <CustomerMenu
          customerName={customerName}
          showLogo={showMenu}
          showMenu={showMenu}
        />
      </Box>
      {showMenu && (
        <List disablePadding className={classes.list}>
          <SingleListItem
            sectionName="Home"
            handleClick={handleClickContent('dashboardOpen', 'welcome')}
            isSelected={location?.pathname.indexOf('/welcome') >= 0}
            disabled={featureFlagCheck('dashboardOff') || isGlobal}
          />
          {isLiveWorkspace
            && userRole === USER_ROLES.UBER_ADMIN
            && !isGlobal && (
              <CollapseListItem
                sectionName="Network"
                handleClick={handleClickContent('networkNavOpen')}
                isSelected={drawerItem === 'networkNavOpen'}
                subSections={[
                  {
                    name: 'Configuration',
                    link: redirect({ url: 'config' }),
                    selected: location?.pathname.split('/').pop() === 'config',
                    disabled: featureFlagCheck('configOff'),
                  },
                  {
                    name: 'Features',
                    link: redirect({ url: 'features' }),
                    selected: location?.pathname.indexOf('/features') >= 0,
                    disabled: featureFlagCheck('featuresOff') || isGlobal,
                  },
                  {
                    name: 'Visual Controls',
                    link: redirect({ url: 'visual-controls' }),
                    selected:
                      location?.pathname.indexOf('/visual-controls') >= 0,
                    disabled: featureFlagCheck('visualControlsOff') || isGlobal,
                  },
                  {
                    name: 'Personas',
                    link: redirect({ url: 'personas' }),
                    selected: location?.pathname.indexOf('/personas') >= 0,
                    disabled: networkFeatures.personas !== 'on' || isGlobal,
                  },
                ]}
              />
            )}
          {isLiveWorkspace
            && userRole === USER_ROLES.UBER_ADMIN
            && isGlobal && (
              <CollapseListItem
                sectionName="Configuration"
                handleClick={handleClickContent('configurationOpen')}
                isSelected={drawerItem === 'configurationOpen'}
                subSections={[
                  {
                    name: 'System Environments',
                    link: redirect({ url: 'configuration/environments' }),
                    selected:
                      location?.pathname.indexOf(
                        '/configuration/environments',
                      ) >= 0,
                    // disabled:
                    //   featureFlagCheck('featuresOff')
                    //   || isGlobal,
                  },
                  {
                    name: 'Networks',
                    link: redirect({ url: 'configuration/networks' }),
                    selected:
                      location?.pathname.indexOf('/configuration/networks')
                      >= 0,
                    disabled: featureFlagCheck('networksConfigOff'),
                  },
                ]}
              />
            )}
            {/* https://goziohealth.atlassian.net/browse/SCM-4116 */}
          {/* {isLiveWorkspace
            && !isGlobal
            && (
              <CollapseListItem
                sectionName="Deep Links"
                isSelected={drawerItem === 'deepLinksOpen'}
                handleClick={handleClickContent('deepLinksOpen')}
                subSections={[
                  {
                    name: 'Link Manager',
                    link: redirect({ url: 'deepLinks/linkManager' }),
                    selected:
                      location?.pathname.indexOf('deepLinks/linkManager') >= 0,
                    disabled: (isUberAdmin && gozioLinkControl === 'disabled') || (!isUberAdmin && gozioLinkControl !== 'enabled'),
                  },
                  {
                    name: 'Configuration',
                    link: redirect({ url: 'deepLinks/configuration' }),
                    selected: location?.pathname.indexOf('deepLinks/configuration') >= 0,
                    disabled: userRole !== USER_ROLES.UBER_ADMIN,
                  },
                ]}
              />
            )} */}
          <SingleListItem
            sectionName="Locations"
            handleClick={handleClickContent('locationsOpen', 'locations')}
            isSelected={location?.pathname.indexOf('/locations') >= 0}
            disabled={featureFlagCheck('locationsOff') || isGlobal}
          />
          <CollapseListItem
            disabled={
              (userRole === USER_ROLES.SDK_ADMIN && !isLiveWorkspace)
              || (userRole === USER_ROLES.SDK_ADMIN
                && featureFlagCheck('categoryOff'))
            }
            sectionName="Content"
            isSelected={drawerItem === 'contentOpen'}
            handleClick={handleClickContent('contentOpen')}
            subSections={[
              {
                name: 'Categories',
                link: redirect({ url: 'categories' }),
                selected: location?.pathname.indexOf('/categories') >= 0,
                disabled: featureFlagCheck('categoryOff'),
                workspace: LIVE_WORKSPACE,
              },
              {
                name: 'Lists',
                link: redirect({ url: 'lists' }),
                selected: location?.pathname.indexOf('/lists') >= 0,
                disabled:
                  featureFlagCheck('listsOff')
                  || isGlobal
                  || userRole === USER_ROLES.SDK_ADMIN,
                workspace: ALL_WORKSPACES,
              },
              // {
              //   name: 'Typeahead',
              //   link: redirect({ url: 'typeahead' }),
              //   selected: location?.pathname.indexOf('/typeahead') >= 0,
              //   disabled:
              //     featureFlagCheck('typeaheadOff')
              //     || userRole !== USER_ROLES.UBER_ADMIN,
              //   workspace: LIVE_WORKSPACE,
              // },
            ].filter((item) => supportsWorkspace(item))}
          />
          {isLiveWorkspace
            && !isGlobal
            && authorize(
              [SCOPES.BANNER_MESSAGE.VIEW, SCOPES.PUSH_NOTIFICATIONS.VIEW],
              { consolidate: true },
            )
            && (networkFeatures.pushNotifications === 'on'
              || networkFeatures.banners === 'on') && (
              <CollapseListItem
                sectionName="Messaging Center"
                isSelected={drawerItem === 'messagingCenter'}
                handleClick={handleClickContent('messagingCenter')}
                subSections={[
                  {
                    name: 'Push notifications',
                    link: redirect({ url: 'pushNotification' }),
                    selected:
                      location?.pathname.indexOf('/pushNotification') >= 0,
                    disabled: networkFeatures.pushNotifications !== 'on',
                  },
                  {
                    name: 'Banner message',
                    link: redirect({ url: 'bannerMessage' }),
                    selected: location?.pathname.indexOf('/bannerMessage') >= 0,
                    disabled: networkFeatures.banners !== 'on',
                  },
                ]}
              />
            )}
          {(userRole !== USER_ROLES.SDK_ADMIN && !isGlobal)
            && (userRole === USER_ROLES.UBER_ADMIN
              || (userRole === USER_ROLES.NETWORK_ADMIN && isLiveWorkspace)) && (
              <CollapseListItem
                sectionName="Mobile Settings"
                handleClick={handleClickContent('mobileOpen')}
                isSelected={drawerItem === 'mobileOpen'}
                subSections={[
                  {
                    name: 'Dashboard',
                    link: redirect({ url: 'mobileDashboard' }),
                    selected:
                      location?.pathname.indexOf('/mobileDashboard') >= 0,
                    disabled: featureFlagCheck('dashboardOff') || isGlobal,
                    workspace: LIVE_WORKSPACE,
                  },
                  {
                    name: 'Explore Panel',
                    link: redirect({
                      url: isGlobal ? 'explore/Global Template' : 'explore',
                    }),
                    selected: location?.pathname.indexOf('/explore') >= 0,
                    disabled:
                      featureFlagCheck('explorePanelOff')
                      || (userRole !== USER_ROLES.UBER_ADMIN
                        && !isLiveWorkspace)
                      || userRole === USER_ROLES.SDK_ADMIN,
                    workspace: ALL_WORKSPACES,
                  },
                  {
                    name: 'Bottom Navigation',
                    link: redirect({ url: 'navigation' }),
                    selected:
                      location?.pathname.indexOf('/navigation') >= 0
                      && location?.pathname.indexOf('analytics') === -1,
                    disabled: featureFlagCheck('navigationOff'),
                    workspace: LIVE_WORKSPACE,
                  },
                ].filter((item) => supportsWorkspace(item))}
              />
            )}
          {isLiveWorkspace && (
            <SingleListItem
              sectionName="Kiosk Settings"
              handleClick={handleClickContent('kiosksOpen', 'kiosks')}
              isSelected={location?.pathname.indexOf('/kiosks') >= 0}
              disabled={
                featureFlagCheck('kiosksOff')
                || isGlobal
                || !activeNetwork.kioskEnabled
              }
            />
          )}
          {isLiveWorkspace && (
            <SingleListItem
              type="Network"
              sectionName="Users"
              handleClick={handleClickContent('usersOpen', 'users')}
              isSelected={location?.pathname.indexOf('/users') >= 0}
              disabled={featureFlagCheck('usersOff')}
            />
          )}
          {userRole === USER_ROLES.UBER_ADMIN
            && workspace === DRAFT_WORKSPACE && (
              <SingleListItem
                type="Network"
                sectionName="Promotion"
                handleClick={handleClickContent('promotionOpen', 'promotion')}
                isSelected={location?.pathname.indexOf('/promotion') >= 0}
                disabled={isGlobal}
              />
            )}
          {userRole === USER_ROLES.UBER_ADMIN ? (
            <CollapseListItem
              sectionName="Publishing"
              handleClick={handleClickContent('publishingOpen')}
              isSelected={
                drawerItem === 'publishingOpen'
                || location?.pathname.indexOf('/publishingHistory') >= 0
                || location?.pathname.indexOf('/channelManagement') >= 0
              }
              disabled={isGlobal}
              subSections={[
                {
                  name: 'Publishing History',
                  link: redirect({ url: 'publishingHistory' }),
                  selected:
                    location?.pathname.indexOf('/publishingHistory') >= 0,
                  disabled: featureFlagCheck('publishingOff'),
                  workspace: ALL_WORKSPACES,
                },
                {
                  name: 'Channel Management',
                  link: redirect({ url: 'channelManagement' }),
                  selected:
                    location?.pathname.indexOf('/channelManagement') >= 0,
                  workspace: ALL_WORKSPACES,
                },
              ].filter((item) => supportsWorkspace(item))}
            />
          ) : (
            <SingleListItem
              type="Network"
              sectionName="Publishing History"
              handleClick={handleClickContent(
                'publishingOpen',
                'publishingHistory',
              )}
              isSelected={location?.pathname.indexOf('/publishingHistory') >= 0}
              disabled={
                featureFlagCheck('publishingOff')
                || !isLiveWorkspace
                || isGlobal
              }
            />
          )}
          {[USER_ROLES.UBER_ADMIN, USER_ROLES.NETWORK_ADMIN].includes(
              userRole,
            )
            && !isGlobal
            && isLiveWorkspace && (
              <SingleListItem
                type="Network"
                sectionName="Analytics"
                handleClick={handleClickContent('analyticsOpen', 'analytics')}
                isSelected={location?.pathname.indexOf('/analytics') >= 0}
              />
            )}
        </List>
      )}

      <footer className={classes.drawerFooter}>
        <BuildVersion buildInfo={window.flamingo_info} />
      </footer>
    </Paper>
  );
});

SectionDrawer.propTypes = {
  customerName: PropTypes.string.isRequired,
  showMenu: PropTypes.bool,
  networkId: PropTypes.string,
};

SectionDrawer.defaultProps = {
  showMenu: true,
};

export default SectionDrawer;
