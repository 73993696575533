import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioSelect from 'components/selector/selector';
import { FlamingoContext } from 'contexts/flamingo';
import { rolePublicName, USER_ROLES } from 'helpers/permissions-util';
import { useCreateUser } from 'hooks/dataHooks/useUsers';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  role: Yup.string().required('Role is required.'),
  emailAddress: Yup.string()
    .email('Email Address must be a valid email.')
    .required('Email Address is required.'),
});
const validate = makeValidate(schema);

const buildStyles = ({ theme }) => ({
  content: {
    paddingTop: theme.spacing(2),
  },
  secondRow: {
    paddingTop: `${theme.spacing(2)} !important`,
  },
});

const AddUserModal = ({ onModalClose, initialValues, roles }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const { activeNetwork } = useContext(FlamingoContext);
  const isSSOEnabled
    = activeNetwork?.configuration?.features?.singleSignOn === 'on';

  const [initialVal, setInitialVal] = useState({
    firstName: '',
    lastName: '',
    role: roles.length === 1 ? roles[0] : '',
    emailAddress: '',
  });

  const { toastNotificationSuccessHook } = useToast();

  const [updating, setUpdating] = useState(false);
  const { handleError } = useHandleError('Users');

  const [createUser] = useCreateUser({
    networkId,
    onCompleted: () => {
      setUpdating(false);
    },
    onError: ({ graphQLErrors, networkError }) => {
      setUpdating(false);
      handleError(graphQLErrors, networkError);
    },
  });

  const onSubmit = async ({ firstName, lastName, role, emailAddress }) => {
    setUpdating(true);
    const response = await createUser({
      variables: {
        input: {
          firstName,
          lastName,
          role,
          emailAddress,
        },
        meta: { action: 'invite' },
      },
    });
    if (response?.data) {
      toastNotificationSuccessHook(
        `${firstName} ${lastName} has been ${
          isSSOEnabled ? 'created' : 'invited'
        }.`,
      );
    }
    onModalClose(true);
  };

  useEffect(() => {
    if (initialValues) {
      setInitialVal(initialValues);
    }
  }, [initialValues]);

  const renderedRoles = useMemo(() => {
    if (roles === null) {
      return (
        <Field
          name="role"
          component={({ input }) => (
            <GozioSelect
              input={input}
              label="Role"
              options={[
                {
                  id: USER_ROLES.NETWORK_ADMIN,
                  label: rolePublicName(USER_ROLES.NETWORK_ADMIN),
                },
                {
                  id: USER_ROLES.UBER_ADMIN,
                  label: rolePublicName(USER_ROLES.UBER_ADMIN),
                },
              ]}
            />
          )}
        />
      );
    }

    return (
      <Field
        name="role"
        component={({ input }) => (
          <GozioSelect
            input={input}
            label="Role"
            disabled={roles.length === 1}
            options={roles.map((role) => ({
              id: role,
              label: rolePublicName(role),
            }))}
          />
        )}
        required={true}
      />
    );
  }, [roles]);

  return (
    <GenericFormModal
      title="Add User"
      open={true}
      formParams={{
        onSubmit,
        validate,
        initialValues: initialVal,
      }}
      body={
        <Grid container spacing={2} sx={styles.content}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              name="firstName"
              variant="outlined"
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              name="lastName"
              variant="outlined"
              required={true}
            />
          </Grid>
          <Grid item xs={6} sx={styles.secondRow}>
            <TextField label="Email" name="emailAddress" variant="outlined" />
          </Grid>
          <Grid item xs={6} sx={styles.secondRow}>
            {renderedRoles}
          </Grid>
        </Grid>
      }
      confirmProgress={updating}
      confirmText="ADD USER"
      greyOutCancel={updating}
      greyOutConfirm={updating}
      handleClose={() => onModalClose(false)}
    />
  );
};

AddUserModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  roles: PropTypes.array,
};

AddUserModal.defaultProps = {
  roles: null,
};

export default React.memo(AddUserModal);
