export const LANGUAGE_CODES = {
  ENGLISH: 'en',
  SPANISH: 'es',
};
Object.freeze(LANGUAGE_CODES);

export const SUPPORTED_LANGUAGE_CODES = [
  LANGUAGE_CODES.ENGLISH,
  LANGUAGE_CODES.SPANISH,
];

export const hasSpanish = (languages = []) => languages.includes(LANGUAGE_CODES.SPANISH);

export const getLabelByLang = (
  i18nArray = [],
  lang = LANGUAGE_CODES.ENGLISH,
) => {
  if (Array.isArray(i18nArray)) {
    return i18nArray.find((i) => i.lang === lang)?.label;
  }

  if (typeof i18nArray === 'object') {
    // null is typeof object...need to make sure we handle below
    return i18nArray?.[lang] ?? '';
  }

  if (typeof i18nArray === 'string') return i18nArray;

  return '';
};

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const buildLabelByLang = (values = {}, enKey, esKey) => {
  const i18nLabel = [];
  if (values[enKey]) {
    i18nLabel.push({ lang: LANGUAGE_CODES.ENGLISH, label: values[enKey] });
  }
  if (values[esKey]) {
    i18nLabel.push({ lang: LANGUAGE_CODES.SPANISH, label: values[esKey] });
  }
  return i18nLabel;
};

export const grammaticallyJoin = (arrayOfStrings) => {
  let result = arrayOfStrings[0];
  if (arrayOfStrings.length > 2) {
    const lastString = arrayOfStrings.pop();
    result = `${arrayOfStrings.join(', ')}, and ${lastString}`;
  } else if (arrayOfStrings.length === 2) {
    result = arrayOfStrings.join(' and ');
  }
  return result;
};

/**
 * Return the validation schema with fields that are supported by the specified languages.
 *
 * @param schema the validation schema
 * @param languages the supported languages
 * @param fieldToLangMap a mapping of a field to the language that supports it. A field that is not included is language-independent. E.g. { 'nameEs': 'es' }
 */
export const buildLanguageSchema = (
  schema,
  languages = [],
  fieldToLangMap = {},
) => {
  if (languages?.length === 0) {
    throw new Error(`Invalid parameter in buildLanguageSchema: languages: ${languages}`);
  }

  const validationSchema = {};
  for (const field of Object.keys(schema)) {
    if (fieldToLangMap[field]) {
      if (languages.includes(fieldToLangMap[field])) {
        validationSchema[field] = schema[field];
      }
    } else {
      validationSchema[field] = schema[field];
    }
  }
  return validationSchema;
};
