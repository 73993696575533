import CreateIcon from '@mui/icons-material/Create';
import { Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Notifier from 'components/notifier/notifier';
import { LANGUAGE_CODES, SUPPORTED_LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { produce } from 'immer';
import _ from 'lodash';
import {
  createNetworkTypeahead,
  createSystemTypeahead,
  editNetworkTypeahead,
  editSystemTypeahead,
  readNetworkTypeaheadById,
  readSystemTypeaheadById,
  resetTypeaheadFlash,
} from 'modules/typeahead';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import Step1 from 'pages/typeaheads/containers/category-typeahead-steps/step-1-label';
import Step2 from 'pages/typeaheads/containers/category-typeahead-steps/step-2-search';
import Step3 from 'pages/typeaheads/containers/category-typeahead-steps/step-3-land';
import Step4 from 'pages/typeaheads/containers/category-typeahead-steps/step-4-sorted';
import Step5 from 'pages/typeaheads/containers/category-typeahead-steps/step-5-tags';
import TypeHeadStyles from 'pages/typeaheads/containers/typeahead-styles';
import validate from 'pages/typeaheads/containers/validate';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';

function edtiStepIcon(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '24px',
        height: '24px',
      }}
    >
      <svg
        style={{
          fill: ColorPalette.blue[400],
          width: '100%',
          height: '100%',
        }}
      >
        <circle cx="12" cy="12" r="12" />
      </svg>
      <CreateIcon
        sx={{
          position: 'absolute',
          fill: ColorPalette.white,
          left: '50%',
          width: '16px',
          transform: 'translateX(-50%)',
        }}
      />
    </Box>
  );
}

class TypeaheadWizard extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.checkTypeahead = this.checkTypeahead.bind(this);
    this.submit = this.submit.bind(this);
    // this.isValid = this.isValid.bind(this);
    this.stepContent = this.stepContent.bind(this);
  }

  handleNext(values) {
    const { activeStep } = this.props.typeaheadForm.values;
    this.props.change('activeStep', activeStep + 1);
  }

  handleBack() {
    const { activeStep } = this.props.typeaheadForm.values;
    this.props.change('activeStep', activeStep - 1);
  }

  handleReset() {
    this.props.change('activeStep', 0);
  }

  handleCancel() {
    const { navigate } = this.props;

    const networkId = window.location.pathname.split('/')[2];

    this.props.reset();
    navigate(`/network/${networkId}/typeahead`);
  }

  handleSave(values) {
    let input = produce(values, (draft) => {
      delete draft.activeStep;
      delete draft.tags;
      if (draft.matchBy !== 'tag') {
        draft.excludeCategoryTags = [];
        draft.includeTags = [];
      }
      if (draft.actionType === 'list') draft.targetId = draft.curatedList.id;
      if (draft.actionType === 'category') draft.targetId = draft.category.id;
      if (draft.includeTags.length > 0) {
        draft.includeTags.map(
          (tag, idx) => (draft.includeTags[idx].weight = 'medium')
        );
      }
      delete draft.curatedList;
      delete draft.category;
      delete draft.network;
    });
    return new Promise((resolve, reject) => {
      const networkId = window.location.pathname.split('/')[2];
      if (window.location.pathname.endsWith('/edit')) {
        let action = null;

        if (isGlobalTemplate(this.props.activeNetwork.id)) {
          action = editSystemTypeahead;
        } else {
          input = produce(input, (draft) => {
            draft.networkId = this.props.activeNetwork.id;
          });
          action = editNetworkTypeahead;
        }
        this.props
          .dispatch(
            action({
              input,
              id: window.location.pathname.split('/')[4],
              networkId,
            })
          )
          .then(() => {
            if (this.props.typeahead.flash.error) {
              if (this.props.typeahead.flash.submissionErrors) {
                reject(this.props.typeahead.flash.submissionErrors);
              } else {
                reject(
                  new SubmissionError({
                    _error: this.props.typeahead.flash.message,
                  })
                );
              }
            } else {
              this.handleCancel();
              resolve();
            }
          });
      } else {
        let action = null;
        if (isGlobalTemplate(this.props.activeNetwork.id)) {
          action = createSystemTypeahead;
        } else {
          input = produce(input, (draft) => {
            draft.networkId = this.props.activeNetwork.id;
          });
          action = createNetworkTypeahead;
        }
        this.props
          .dispatch(
            action({
              input,
              networkId,
            })
          )
          .then(() => {
            if (this.props.typeahead.flash.error) {
              if (this.props.typeahead.flash.submissionErrors) {
                reject(this.props.typeahead.flash.submissionErrors);
              } else {
                reject(
                  new SubmissionError({
                    _error: this.props.typeahead.flash.message,
                  })
                );
              }
            } else {
              this.handleCancel();
              resolve();
            }
          });
      }
    });
  }

  checkTypeahead() {
    const networkId = window.location.pathname.split('/')[2];
    const typeaheadId = window.location.pathname.split('/')[4];
    const { system, network } = this.props.typeahead.page.docs;
    const typeahead = system
      .concat(network)
      .filter((rules) => rules.id === typeaheadId)[0];
    const that = this;
    if (!typeahead) {
      let action = null;
      if (!isGlobalTemplate(networkId)) {
        action = readNetworkTypeaheadById;
      } else {
        action = readSystemTypeaheadById;
      }
      this.props
        .dispatch(
          action({
            id: typeaheadId,
            networkId,
          })
        )
        ?.then(() => {
          const remote = that.props.typeahead.byId[typeaheadId];
          const initObj = {
            ...remote,
            activeStep: 0,
          };
          this.props.initialize(initObj);
        });
    } else {
      const initObj = {
        ...typeahead,
        activeStep: 0,
      };
      this.props.initialize(initObj);
    }
  }

  // the old isValid function, pulls validation errors manually from the redux store. decprecated.
  // isValid() {
  //   const values =
  //     (this.props.typeaheadForm && this.props.typeaheadForm.values) || {};
  //   const validationErrors = validate(values);
  //   const keys = _.keys(validationErrors);
  //   if (keys.length > 0) {
  //     return false;
  //   }
  //   return true;
  // }

  submit(values) {
    const self = this;
    return new Promise((resolve, reject) => {
      const validationErrors = validate(values);
      const keys = _.keys(validationErrors);
      if (keys.length > 0) {
        reject(new SubmissionError(validationErrors));
      } else {
        if (values.activeStep === 3) {
          if (values.matchBy === 'tag') {
            self.handleNext();
          } else {
            resolve(self.handleSave(values));
          }
        } else if (values.activeStep === 4) {
          resolve(self.handleSave(values));
        } else {
          self.handleNext();
        }
        resolve();
      }
    });
  }

  componentDidMount() {
    if (window.location.pathname.endsWith('/edit')) {
      this.checkTypeahead();
    }
  }

  pageTitle() {
    if (window.location.pathname.endsWith('/edit')) {
      return 'Edit typeahead rule';
    }
    return 'Create new typeahead rule';
  }

  stepContent(languages) {
    const networkId = window.location.pathname.split('/')[2];
    const formValues
      = (this.props.typeaheadForm && this.props.typeaheadForm.values) || {};
    switch (formValues.activeStep) {
      case 0:
        return (
          <Step1
            handleCancel={this.handleCancel}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            languages={languages}
          />
        );
      case 1:
        return (
          <Step2
            handleCancel={this.handleCancel}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
          />
        );
      case 2:
        return (
          <Step3
            handleCancel={this.handleCancel}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            network={networkId}
          />
        );
      case 3:
        return (
          <Step4
            handleCancel={this.handleCancel}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            handleSave={this.handleSave}
          />
        );
      case 4:
        return (
          <Step5
            handleCancel={this.handleCancel}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            handleSave={this.handleSave}
            languages={languages}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      activeNetwork,
      classes,
      handleSubmit,
      dispatch,
      typeahead: { flash },
    } = this.props;

    const networkId = window.location.pathname.split('/')[2];

    const languages = isGlobalTemplate(networkId)
      ? SUPPORTED_LANGUAGE_CODES
      : _.get(activeNetwork, 'configuration.features.languages', [
          LANGUAGE_CODES.ENGLISH,
        ]);

    const formValues
      = (this.props.typeaheadForm && this.props.typeaheadForm.values) || {};
    const { activeStep } = formValues;
    const steps
      = formValues.matchBy === 'tag' ? ['', '', '', '', ''] : ['', '', '', ''];
    return (
      <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
        <Notifier
          flash={flash}
          clearFlash={() => {
            dispatch(resetTypeaheadFlash());
          }}
        />
        <Typography variant="h1" className={classes.title}>
          {this.pageTitle()}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={`${label}_${index}`}>
                {index === activeStep ? (
                  <StepLabel StepIconComponent={edtiStepIcon} />
                ) : (
                  <StepLabel />
                )}
              </Step>
            ))}
          </Stepper>
          <form onSubmit={handleSubmit(this.submit)}>
            <Box className={classes.stepContent}>
              {this.stepContent(languages)}
            </Box>
          </form>
        </Box>
      </LoggedinLayout>
    );
  }
}

export default compose(
  connect(
    ({ auth, typeahead, form, flamingo }) => ({
      auth,
      typeahead,
      typeaheadForm: form.typeaheadForm,
      initialValues: {
        activeStep: 0,
        matchBy: null,
        actionType: 'category',
        sortBy: null,
        excludeCategoryTags: [],
        includeTags: [],
        category: null,
      },
      activeNetwork: _.get(flamingo, 'activeNetwork', {}),
      navigate: (url) => {
        window.location.href = url;
      },
    }),
    (dispatch) => ({
      dispatch,
    })
  ),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: false,
  }),
  withStyles(TypeHeadStyles)
)(TypeaheadWizard);
