import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { MINIMUM_SCREEN_HEIGHT, MINIMUM_SCREEN_WIDTH } from 'helpers/page-util';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100vw',
  },
  content: {
    height: '428px',
  },
  oops: {
    color: theme.palette.blue[600],
    fontSize: '15vw',
    fontWeight: '900',
    height: '100px',
    margin: '0 0 64px 0',
    opacity: 0.1,

    '& span': {
      position: 'relative',
      top: '20px',
      display: 'inline-block',
      animation: '$bounce 0.3s ease infinite alternate',
      fontSize: '80px',
      textShadow:
        '0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4)',

      '&:nth-child(2)': {
        animationDelay: '0.1s',
      },
      '&:nth-child(3)': {
        animationDelay: '0.2s',
      },
      '&:nth-child(4)': {
        animationDelay: '0.3s',
      },
      '&:nth-child(5)': {
        animationDelay: '0.4s',
      },
      '&:nth-child(6)': {
        animationDelay: '0.5s',
      },
      '&:nth-child(7)': {
        animationDelay: '0.6s',
      },
    },
  },
  resContainer: {
    top: '-152px',
    position: 'relative',
  },
  res: {
    fontSize: '8em',
    margin: 0,
    lineHeight: 'normal',
    padding: 0,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  res1: {
    color: 'transparent',
    WebkitTextStroke: `2px ${theme.palette.blue[300]}`,
  },
  res2: {
    color: theme.palette.blue[300],
    animation: '$wavy 4s ease-in-out infinite',
  },
  '@keyframes bounce': {
    '100%': {
      top: 0,
      textShadow:
        '0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc, 0 50px 25px rgba(0, 0, 0, 0.2)',
    },
  },
  '@keyframes wavy': {
    '0%': {
      clipPath:
        'polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%)',
    },
    '50%': {
      clipPath:
        'polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%)',
    },
    '100%': {
      clipPath:
        'polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%)',
    },
  },
}));

const RESOLUTION_TEXT = `${MINIMUM_SCREEN_WIDTH}x${MINIMUM_SCREEN_HEIGHT}`;

export const UnsupportedResolution = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h1" className={classes.oops}>
          <span>O</span>
          <span>o</span>
          <span>o</span>
          <span>p</span>
          <span>s</span>
          <span>!</span>
        </Typography>
        <Typography variant="h1">
          Portal requires a minimum content window size of:
        </Typography>
      </Box>
      <Box className={classes.resContainer}>
        <h2 className={clsx(classes.res, classes.res1)}>{RESOLUTION_TEXT}</h2>
        <h2 className={clsx(classes.res, classes.res2)}>{RESOLUTION_TEXT}</h2>
      </Box>
    </Box>
  );
};
