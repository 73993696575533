import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { wrapWithTooltip } from 'helpers/page-util';
import {
  getTableCountTitle,
  LOCATIONS_POI_TABLE_NAME,
  persistTableSearch,
  retrieveTableFilters,
  retrieveTablePageSize,
  retrieveTableSearch,
} from 'helpers/table-util';
import ColorPalette from 'pages/gozio_colors';
import AddPoiModal from 'pages/locations/containers/sections/addPoiModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: 0,
    overflowX: 'auto',
    marginTop: '30.5px',
  },
  buttonContainer: {
    marginLeft: theme.spacing(3),
  },
  addButton: {
    right: '16px',
    height: '32px',
    width: '32px',
  },
  content: { height: 'calc(100vh - 334px)' },
  panelContent: { height: '100%' },
});

const parseCategories = (netPlace) => {
  const list = netPlace?.categories?.map((cat) => getLabelByLang(cat.name)) || [
    '-',
  ];
  return list.join(', ');
};

const getPoiColumns = (
  networkId,
  navigate,
  isUberAdmin,
  parentBuildings,
  parentFloors,
) => [
  {
    Header: 'POI ID',
    accessor: 'id',
    hidden: true,
  },
  {
    Header: <span>POI&nbsp;Name</span>,
    accessor: 'name',
    width: 130,
    onClick: (data) => {
      navigate(`/network/${networkId}/locations/poi/${data.id}`);
    },
    sticky: 'left',
  },
  {
    Header: <span>POI&nbsp;Short&nbsp;Name</span>,
    accessor: 'shortName',
    width: 150,
    filterAccessor: 'parentLocations',
    filterTitle: 'Parent',
    filters: {
      type: 'parentLocations',
      parentBuildings,
      parentFloors,
    },
  },
  {
    Header: 'Mapping',
    accessor: 'mapping',
    width: 110,
    filters: {
      type: 'select',
      options: [
        { value: true, label: 'Mapped' },
        { value: false, label: 'Unmapped' },
      ],
    },
  },
  {
    Header: <span>Street&nbsp;Address</span>,
    accessor: 'streetAddress',
    width: 150,
  },
  {
    Header: 'Categories',
    accessor: 'categories',
    filters: {
      type: 'categories',
    },
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    width: 110,
    filters: {
      type: 'select',
      options: [
        { value: 'complete', label: 'Complete' },
        { value: 'incomplete', label: 'Incomplete' },
      ],
    },
  },
  {
    Header: <span>Parent&nbsp;Building</span>,
    accessor: 'building',
    minWidth: 125,
  },
  {
    Header: <span>Parent&nbsp;Floor</span>,
    accessor: 'floor',
    minWidth: 125,
  },
  ...isUberAdmin
    ? [
      {
        Header: 'Mapping ID',
        accessor: 'mapId',
        minWidth: 150,
        width: 150,
      },
    ]
    : [],
  {
    Header: 'Gozio ID',
    accessor: 'externalId',
    minWidth: 150,
    width: 150,
  },
];

const buildPoiData = (poi) => ({
  id: poi?.node?.id,
  name: wrapWithTooltip(getLabelByLang(poi?.node?.name)),
  shortName: getLabelByLang(poi?.node?.shortName),
  mapping: poi?.node?.isMapped ? 'Mapped' : 'Unmapped',
  streetAddress: wrapWithTooltip(poi?.node?.geoLocation?.address?.street),
  categories: wrapWithTooltip(parseCategories(poi?.node)),
  progress: capitalize(poi?.node?.validation?.status || 'Incomplete'),
  building: wrapWithTooltip(getLabelByLang(poi?.node?.parentBuilding?.name)),
  floor: wrapWithTooltip(getLabelByLang(poi?.node?.parentFloor?.name)),
  mapId: wrapWithTooltip(poi?.node?.indoorLocation?.mapKey),
  externalId: poi?.node?.externalId,
});

const filterPoi = (poi, filters = []) => {
  for (const filter of filters) {
    switch (filter.name) {
      case 'parentLocations':
        for (const element of filter.values) {
          if (poi.node[element.type]?.id !== element.value) {
            return null;
          }
        }
        break;
      case 'categories':
        if (
          poi.node.categories.filter(({ id }) => filter.values.find(({ value }) => id === value),
          )?.length === 0
        ) {
          return null;
        }
        break;
      case 'mapping':
        if (
          filter.values.length === 1
          && poi.node.isMapped !== filter.values[0].value
        ) {
          return null;
        }
        break;
      case 'progress':
        if (
          filter.values.length === 1
          && poi.node.validation?.status !== filter.values[0].value
        ) {
          return null;
        }
        break;
      default:
        break;
    }
  }

  return poi;
};

const POIPanel = ({
                    sx,
                    pois = [],
                    buildings = [],
                    networkId,
                    isUberAdmin,
                    site,
                    ...rest
                  }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { handleNavigate } = useContext(FlamingoContext);
  const [showAddPOI, setShowAddPOI] = useState(false);
  const [tableParams, setTableParams] = useState({
    sortBy: { id: 'name', desc: false },
    pageSize: retrieveTablePageSize(LOCATIONS_POI_TABLE_NAME),
    pageIndex: 0,
  });
  const [searchParam, setSearchParam] = useState(
    retrieveTableSearch(LOCATIONS_POI_TABLE_NAME),
  );
  const [filters, setFilters] = useState(
    retrieveTableFilters(LOCATIONS_POI_TABLE_NAME),
  );

  const { activeNetwork: network } = useContext(FlamingoContext);
  const networkName = network?.humanName || 'Global Template';
  const networkLogo = network?.logo?.url;

  const filteredPois = useMemo(() => {
    let data = pois;
    if (filters?.length > 0) {
      data = pois.filter((poi) => filterPoi(poi, filters));
    }
    if (searchParam) {
      // Search based on the following priorities: Gozio ID (highest weight), Name, and Mapping ID
      const keywords = searchParam.toLowerCase();
      data = data
        .map((poi) => {
          const targets = [
            { weight: 5, target: poi?.node?.externalId?.toString() },
            {
              weight: 3,
              target: getLabelByLang(poi?.node?.name)?.toLowerCase(),
            },
            { weight: 1, target: poi?.node?.indoorLocation?.mapKey },
          ];
          const retPoi = { ...poi, weight: 0 };
          for (let i = 0; i < targets.length; i++) {
            const { weight, target } = targets[i];
            if (target && target.indexOf(keywords) !== -1) {
              if (target === keywords) {
                retPoi.weight += weight + 8;
              } else if (i === 0 && target.startsWith(keywords)) {
                retPoi.weight += weight + 2;
              } else {
                retPoi.weight += weight;
              }
            }
          }
          return retPoi.weight === 0 ? null : retPoi;
        })
        .filter((p) => p);
      data.sort((a, b) => {
        const weightSort = b.weight - a.weight;
        if (weightSort !== 0) {
          return weightSort;
        }
        const gozioIdSort = a.node?.externalId - b.node?.externalId;
        if (gozioIdSort !== 0) {
          return gozioIdSort;
        }
        const nameSort = getLabelByLang(a.node?.name).localeCompare(
          getLabelByLang(b.node?.name),
        );
        if (nameSort !== 0) {
          return nameSort;
        }
        return a.node?.indoorLocation?.mapKey?.localeCompare(
          b.node?.indoorLocation?.mapKey,
        );
      });
    }

    return data.map(buildPoiData);
  }, [pois, filters, searchParam]);

  const handlePOIAdded = (data) => {
    setShowAddPOI(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/poi/${data.id}`);
    }
  };

  const isAddPoiDisabled = useMemo(
    () => site.isMapped || buildings.length === 0,
    [site, buildings],
  );

  const columns = useMemo(() => {
    const buildingMap = {};
    const floorMap = {};
    pois.forEach((poi) => {
      if (poi.node.parentBuilding?.id) {
        buildingMap[poi.node.parentBuilding.id] = {
          id: poi.node.parentBuilding.id,
          label: getLabelByLang(poi.node.parentBuilding.name),
          label2: getLabelByLang(poi.node.parentSite.name),
        };
      }
      if (poi.node.parentFloor?.id) {
        floorMap[poi.node.parentFloor.id] = {
          id: poi.node.parentFloor.id,
          buildingId: poi.node.parentBuilding.id,
          label: getLabelByLang(poi.node.parentFloor.name),
          label2: `${getLabelByLang(
            poi.node.parentSite.name,
          )}, ${getLabelByLang(poi.node.parentBuilding.name)}`,
        };
      }
    });
    return getPoiColumns(
      networkId,
      handleNavigate,
      isUberAdmin,
      Object.values(buildingMap),
      Object.values(floorMap),
    );
  }, [networkId, handleNavigate, isUberAdmin, pois]);

  return (
    <FlamingoPage
      pageName="POIs"
      sx={{ ...styles.root, ...sx }}
      contentSx={{
        ...filteredPois?.length === 0 && styles.panelContent,
      }}
      searchValue={searchParam}
      onSearchChange={(keywords) => {
        setTableParams({
          ...tableParams,
          pageIndex: 0,
          sortBy: keywords.length === 0 ? { id: 'name', desc: false } : {},
        });
        setSearchParam(keywords);
        persistTableSearch(LOCATIONS_POI_TABLE_NAME, keywords);
        if (tableParams.gotoPage) {
          tableParams.gotoPage(0);
        }
      }}
      headerButtons={
        isUberAdmin
        && filteredPois?.length > 0 && (
          <Box sx={styles.buttonContainer}>
            <AddCircleButton
              onClick={() => {
                setShowAddPOI(true);
              }}
              aria-label="Add POI"
              sx={styles.addButton}
              disabled={isAddPoiDisabled}
              tooltipTitle="Add a POI"
            />
          </Box>
        )
      }
      {...rest}
    >
      <Box sx={styles.content}>
        <GozioTable
          name={LOCATIONS_POI_TABLE_NAME}
          sx={{
            borderRadius: '20px',
            boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
              ColorPalette.grey[600],
              0.4,
            )}`,
            width: 'calc(100% - 24px)',
          }}
          columns={columns}
          countTitle={getTableCountTitle(
            filteredPois?.length,
            searchParam || filters?.length > 0 ? 'Result' : 'POI',
          )}
          data={filteredPois}
          currentPage={tableParams.pageIndex}
          rowsPerPage={tableParams.pageSize}
          sortBy={[
            { ...tableParams.sortBy, clearSort: searchParam.length > 0 },
          ]}
          onFiltersChanged={(updatedFilters) => {
            setTableParams({
              ...tableParams,
              pageIndex: 0,
            });
            setFilters(updatedFilters);
          }}
          emptyContent={
            searchParam || filters?.length > 0 ? (
              <EmptyTable />
            ) : (
              <EmptyPanel
                title="There are No POIs for This Site"
                buttonDisabled={isAddPoiDisabled}
                buttonShown={!site.isMapped}
                buttonLabel="Add a POI"
                ButtonIcon={Add}
                buttonClicked={() => setShowAddPOI(true)}
                sx={{ height: '100%', paddingBottom: '110px' }}
              />
            )
          }
        />
      </Box>
      {isUberAdmin && showAddPOI && (
        <AddPoiModal
          handleClose={() => setShowAddPOI(false)}
          handleConfirm={handlePOIAdded}
          buildings={buildings}
          site={site}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}
    </FlamingoPage>
  );
};

POIPanel.propTypes = {
  sx: PropTypes.object,
  pois: PropTypes.array,
  buildings: PropTypes.array,
  networkId: PropTypes.string,
  isUberAdmin: PropTypes.bool,
  site: PropTypes.shape({
    isMapped: PropTypes.bool,
  }),
};

POIPanel.defaultProps = {
  sx: {},
};

export default POIPanel;
