import dayjs from 'dayjs';

export const STANDARD_DATE_REGEX
  = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/;

export const STANDARD_DATE_FORMAT = 'MM/DD/YYYY';

export const convertDateToString = (date, format = 'MMM D, YYYY, h:mmA') => {
  if (!date) {
    return null;
  }

  return dayjs(date).format(format);
};

export const convertStringToMMDDYYYY = (isoString) => new Date(isoString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const sortDates = (a, b) => dayjs(a.date, 'MM/DD/YYYY h:mmA').diff(dayjs(b.date, 'MM/DD/YYYY h:mmA'));

export const isPastDate = (value) => value?.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

export const isFutureDate = (value) => value?.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);

export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};
