import { useMutation, useQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import {
  FIND_SYSTEM_ENVIRONMENT,
  SET_SYSTEM_ENVIRONMENT,
} from 'graphql/queries';

export const useGetEnvironment = () => {
  const { handleError } = useHandleError('Environment');
  const { loading, data, refetch } = useQuery(FIND_SYSTEM_ENVIRONMENT, {
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
  return {
    refetch,
    loading,
    data: data?.system?.environments || [],
  };
};

export const useSetEnvironment = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Environment');
  return useMutation(SET_SYSTEM_ENVIRONMENT, {
    onCompleted: () => {
      toastNotificationSuccessHook('Environment saved');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
