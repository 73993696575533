import { useSuspenseQuery } from '@apollo/client';
import GozioTable from 'components/tables/gozioTable';
import { convertDateToString } from 'helpers/date-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import useHandleError from 'hooks/useHandleError';
import React, { useMemo } from 'react';
import { GET_NETWORK_BANNER_MESSAGE_HISTORY } from 'graphql/queries';

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Date Published',
    accessor: 'publishedDate',
    Cell: ({ cell }) => convertDateToString(cell.value),
  },
  {
    Header: 'Date Unpublished',
    accessor: 'unpublishedDate',
    Cell: ({ cell }) => convertDateToString(cell.value),
  },
  { Header: 'Message Title', accessor: 'title' },
  { Header: 'Status', accessor: 'status' },
];

const History = ({ networkId }) => {
  const { handleError } = useHandleError('BannerMessage');

  const { data } = useSuspenseQuery(GET_NETWORK_BANNER_MESSAGE_HISTORY, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    context: { headers: { network: networkId } },
    variables: { networkId: networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  const processHistory = () => data?.getNetwork?.bannerMessages
      ?.map((message) => ({
        id: message.id,
        title: getLabelByLang(message.title),
        publishedDate: message.publishedDate,
        unpublishedDate: message.unpublishedDate,
        status: capitalize(
          message.status === 'published' ? 'archived' : message.status,
        ),
      }))
      .filter((m) => m.status !== 'Draft');

  const memoizedData = useMemo(
    () => processHistory(),
    [data], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const count = memoizedData?.length || 0;

  return (
    <GozioTable
      name="CategoriesTable"
      columns={COLUMNS}
      data={memoizedData}
      sortBy={[{ id: 'publishedDate', desc: true }]}
      sx={{ paddingTop: '8px' }}
      countTitle={getTableCountTitle(count, 'Banner Message')}
    />
  );
};

export default History;
