import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptySection from 'components/emptySection/emptySection';
import InfoPanel from 'components/infoPanel/infoPanel';
import ActionLinkList from 'components/networkPlace/actionLinks/actionLinkList';
import AddActionLinkModal from 'components/networkPlace/actionLinks/addActionLinkModal';
import { ACTION_LINK_MENU_ITEMS } from 'components/networkPlace/actionLinks/addActionLinkModalBody';
import AddNewActionLinkModal from 'components/networkPlace/actionLinks/addNewActionLinkModal';
import { capitalize } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme }) => ({
  addButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    position: 'absolute',
    right: 0,
    top: '-8px',
  },
});

const ActionLinksMain = ({
                           value,
                           onChange,
                           appDestinations,
                           langES,
                           isUberAdmin,
                           networkIcons,
                           sourceName,
                         }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [showLinksModal, setShowLinksModal] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);

  const handleSave = (actionLink) => {
    if (actionLink.idx === undefined) {
      const orders = value
        .filter((val) => val.type === actionLink.type)
        .map((val) => val.order);
      actionLink.order = orders.length > 0 ? Math.max(...orders) + 1 : 0;
      const indices = value.map((val) => val.idx);
      actionLink.idx = indices.length > 0 ? Math.max(...indices) + 1 : 0;
      onChange([...value, actionLink]);
    } else {
      onChange(
        value.map((val, idx) => idx === actionLink.idx ? actionLink : val),
      );
    }

    setCurrentLink(null);
    setShowLinksModal(false);
  };

  const closeLinkModal = () => {
    setCurrentLink(null);
    setShowLinksModal(false);
  };

  const editLink = (currLink) => {
    setCurrentLink(currLink);
    setShowLinksModal(true);
  };

  const deleteLink = (deleteIndex) => {
    onChange(value.filter((val) => deleteIndex !== val.idx));
  };

  const reorderRows = (rowOne, rowTwo) => {
    if (!rowOne || !rowTwo) return;

    const newVals = value.map((val) => ({ ...val }));
    newVals.sort((a, b) => {
      if (a.type !== b.type) {
        const aIndex = ACTION_LINK_MENU_ITEMS.findIndex(({ id }) => a.type === id);
        const bIndex = ACTION_LINK_MENU_ITEMS.findIndex(({ id }) => b.type === id);
        return aIndex - bIndex;
      }

      return a.order - b.order;
    });

    const newRowOne = newVals.find((val) => val.idx === rowOne.original.idx);
    const newRowOneIndex = newVals.findIndex((val) => val.idx === rowOne.original.idx);
    if (newRowOne) {
      newRowOne.order = rowTwo.original.order;
    }

    const newRowTwo = newVals.find((val) => val.idx === rowTwo.original.idx);
    const newRowTwoIndex = newVals.findIndex((val) => val.idx === rowTwo.original.idx);
    if (newRowTwo) {
      newRowTwo.order = rowOne.original.order;
    }

    [newVals[newRowOneIndex], newVals[newRowTwoIndex]] = [newVals[newRowTwoIndex], newVals[newRowOneIndex]];

    onChange(newVals);
  };

  return (
    <>
      {value.length > 0 && (
        <AddCircleButton
          onClick={() => {
            setShowLinksModal(true);
          }}
          sx={styles.addButton}
          data-test="LocationAddActionLinkBtn"
          tooltipTitle="Add Quicklink"
        />
      )}
      <Grid item container>
        <Grid item xs>
          {value.length === 0 ? (
            <EmptySection
              title={`This ${
                sourceName === 'poi' ? 'POI' : capitalize(sourceName)
              } Has No Quicklinks`}
              buttonLabel={'Add Quicklink'}
              ButtonIcon={Add}
              buttonClicked={() => setShowLinksModal(!showLinksModal)}
              hasPermission
              //hasPermission={createPermission || isUberAdmin}
            />
          ) : (
            <>
              <InfoPanel
                sx={{ marginBottom: '24px' }}
                title="The order of Quicklink types displayed below is the order that will appear in the mobile application."
              />
              <ActionLinkList
                values={value}
                appDestinations={appDestinations}
                isUberAdmin={isUberAdmin}
                editLink={editLink}
                deleteLink={deleteLink}
                reorderRows={reorderRows}
              />
            </>
          )}
        </Grid>
      </Grid>
      {showLinksModal
        && (currentLink ? (
          <AddActionLinkModal
            closeModal={closeLinkModal}
            handleSave={handleSave}
            currentLink={currentLink}
            langES={langES}
            isUberAdmin={isUberAdmin}
            appDestinations={appDestinations}
            networkIcons={networkIcons}
          />
        ) : (
          <AddNewActionLinkModal
            closeModal={closeLinkModal}
            handleSave={(selectedLink) => {
              closeLinkModal();
              setCurrentLink(selectedLink);
              setShowLinksModal(true);
            }}
            isUberAdmin={isUberAdmin}
          />
        ))}
    </>
  );
};

ActionLinksMain.propTypes = {
  appDestinations: PropTypes.array,
  isUberAdmin: PropTypes.bool,
  langES: PropTypes.bool,
  networkIcons: PropTypes.array,
  onChange: PropTypes.func,
  sourceName: PropTypes.string,
  value: PropTypes.any,
};

ActionLinksMain.defaultProps = {
  networkIcons: [],
  sourceName: 'site',
};

export default React.memo(ActionLinksMain);
