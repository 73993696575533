/**
 * Returns an 8-character hex color with alpha
 * @param hexColor the hex color (from ColorPalette)
 * @param alpha 0 to 1
 */
export const colorWithAlpha = (hexColor, alpha = 1) => {
	const transparency = alpha > 1 ? 1 : alpha < 0 ? 0 : alpha;
	const hexAlpha = Math.floor(transparency * 255)
		.toString(16)
		.toUpperCase();
	return `${hexColor}${hexAlpha}`;
};

export const isLightColor = (color, hspMedian = 127.5) => {
	let b, g, r;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +`0x${ color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	// range is 0 (black) - 255 (white), so 127.5 is right in middle. That's
	// default above, but can adjust if only want to do for darker items
	if (hsp > hspMedian) {
		return true;
	}
		return false;
};
