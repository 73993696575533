import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import GenericModal from 'components/genericModal/genericModal';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize } from 'helpers/lang-util';
import {
  DRAFT_WORKSPACE,
  getWorkspaceView,
  LIVE_WORKSPACE,
  MINIMIZED_WORKSPACE_VIEW,
  NORMAL_WORKSPACE_VIEW,
  setWorkspaceView,
} from 'helpers/workspace-util';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkspace } from 'hooks/useWorkspace';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.white,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.4)}`,
    margin: '0 auto',
    position: 'fixed',
    bottom: 0,
    width: '376px',
    height: '54px',
    zIndex: 1001,
  },
  content: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  icon: {
    marginTop: '6px',
    paddingLeft: '4px',
    paddingRight: '4px',
    cursor: 'pointer',
  },
  workspaceText: {
    fontWeight: 'bold',
  },
  liveButton: {
    fontSize: '14px',
    marginLeft: '20.5px',
  },
  draftButton: {
    fontSize: '14px',
    marginLeft: '15px',
  },
  minimizedRoot: {
    alignItems: 'center',
    background: theme.palette.blue[400],
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.4)}`,
    color: theme.palette.white,
    margin: '0 auto',
    position: 'fixed',
    bottom: 0,
    width: '28px',
    height: '16px',
    zIndex: 999,

    '& svg': {
      margin: '0 auto',
      width: '20px',
      height: '20px',
    },
  },
  minimizedIcon: {
    marginTop: '-1px',
    paddingLeft: '4px',
    paddingRight: '4px',
    cursor: 'pointer',
  },
}));

const SWITCH_TO_DRAFT_BODY
  = 'All work you do from this point forward will be saved to the Draft Workspace. This is a separate workspace, specifically dedicated to service Gozio Admins. Once in the Draft Workspace, you cannot edit information inside the Live Workspace. You can only add and edit new information. Are you sure you want to switch workspaces?';

const SWITCH_TO_LIVE_BODY
  = 'All work you do from this point forward will be saved to the Live Workspace. This is a separate workspace, specifically dedicated to service all users. Once in the Live Workspace, you cannot see or edit information inside the Draft Workspace. You can only add and edit new information. Are you sure you want to switch workspaces?';

const WorkspaceSwitcher = ({
                             contentWidth,
                             detectDrawerVisibility,
                             leftOffset,
                             rightOffset,
                             onWorkspaceSwitched,
                           }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const closeSnackbar = useMemo(
    () => snackbar ? snackbar.closeSnackbar : () => {},
    [snackbar],
  ); // Storybook workaround

  const [view, setView] = useState(getWorkspaceView());
  const [showModal, setShowModal] = useState(false);

  const {
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const size = useWindowSize();
  const { workspace, setWorkspace } = useWorkspace();

  const [actualOffset, setActualOffset] = useState(leftOffset);

  useEffect(() => {
    if (detectDrawerVisibility) {
      setActualOffset(openDrawer ? leftOffset : leftOffset - 250);
    } else {
      setActualOffset(leftOffset);
    }
  }, [detectDrawerVisibility, leftOffset, openDrawer]);

  const toggleView = () => {
    const updatedView
      = view === NORMAL_WORKSPACE_VIEW
        ? MINIMIZED_WORKSPACE_VIEW
        : NORMAL_WORKSPACE_VIEW;
    setWorkspaceView(updatedView);
    setView(updatedView);
  };

  const handleSwitchWorkspace = useCallback(() => {
    const updatedWorkspace
      = workspace === DRAFT_WORKSPACE ? LIVE_WORKSPACE : DRAFT_WORKSPACE;
    closeSnackbar(); // closes all open toast notifications
    setWorkspace(updatedWorkspace);
    setShowModal(false);
    onWorkspaceSwitched();
  }, [
    onWorkspaceSwitched,
    workspace,
    setWorkspace,
    setShowModal,
    closeSnackbar,
  ]);

  const switcherWidth = view === 'minimized' ? 28 : 376;
  const actualContentWidth = contentWidth || size.width - actualOffset;
  const leftStyle = `calc(${actualOffset}px + (${
    actualContentWidth - switcherWidth - rightOffset
  }px / 2))`;

  if (view === 'minimized') {
    return (
      <Box
        className={classes.minimizedRoot}
        sx={{
          left: leftStyle,
        }}
      >
        <Box className={classes.minimizedIcon} onClick={toggleView}>
          <ExpandMore />
        </Box>
      </Box>
    );
  }

  const capitalizedWorkspace = capitalize(workspace);

  return (
    <Grid
      container
      className={classes.root}
      sx={{
        left: leftStyle,
      }}
    >
      <Grid item xs={12} className={classes.content}>
        <Box className={classes.icon} onClick={toggleView}>
          <ExpandLess />
        </Box>
        <Typography variant="body2">
          You are working in
          <br />
          the{' '}
          <span
            className={classes.workspaceText}
            data-test="WorkspaceSwitcher-label"
          >
            {capitalizedWorkspace} Workspace
          </span>
        </Typography>
        <Button
          className={clsx(
            workspace === LIVE_WORKSPACE
              ? classes.liveButton
              : classes.draftButton,
          )}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setShowModal(true)}
          data-test="WorkspaceSwitcher-button-switch"
        >
          SWITCH WORKSPACES
        </Button>
      </Grid>

      {showModal && (
        <GenericModal
          title="You Are About to Switch Workspaces"
          body={
            workspace === DRAFT_WORKSPACE
              ? SWITCH_TO_LIVE_BODY
              : SWITCH_TO_DRAFT_BODY
          }
          cancelText="CANCEL"
          confirmText={`SWITCH TO ${
            workspace === LIVE_WORKSPACE ? 'DRAFT' : 'LIVE'
          } WORKSPACE`}
          handleClose={() => setShowModal(false)}
          handleConfirm={() => handleSwitchWorkspace()}
        />
      )}
    </Grid>
  );
};

WorkspaceSwitcher.propTypes = {
  contentWidth: PropTypes.number,
  leftOffset: PropTypes.number,
  rightOffset: PropTypes.number,
  detectDrawerVisibility: PropTypes.bool,
  onWorkspaceSwitched: PropTypes.func,
};

WorkspaceSwitcher.defaultProps = {
  contentWidth: null,
  detectDrawerVisibility: true,
  leftOffset: 250,
  rightOffset: 0,
  onWorkspaceSwitched: () => {},
};

export default React.memo(WorkspaceSwitcher);
