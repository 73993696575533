import { useTheme } from '@mui/material/styles';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { buildLabelByLang, getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { DRAFT_WORKSPACE } from 'helpers/workspace-util';
import { useSites } from 'hooks/dataHooks';
import { useExplorePanel, useExplorePanels, useSystemExplorePanel } from 'hooks/dataHooks/useExplorePanel';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkspace } from 'hooks/useWorkspace';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import EditExplorePanelPanel from 'pages/mobileSettings/explorePanel/containers/EditExplorePanelPanel';
import PanelPreview from 'pages/mobileSettings/explorePanel/containers/PanelPreview';
import PanelView from 'pages/mobileSettings/explorePanel/containers/PanelView';
import { getAvailableSites } from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = ({ theme: { palette } }) => ({
  root: {
    background: palette.white,
    overflowY: 'hidden !important',
    position: 'relative',
  },
  previewContainer: {
    height: '100%',
    overflow: 'scroll',
    position: 'fixed',
    top: '68px',
    zIndex: 4,
  },
});

const ExplorePanelDetailPageContent = ({ styles }) => {
  const size = useWindowSize();
  const {
    handleNavigate,
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const { networkId, panelId } = useParams();
  const userRole = useCheckRole();
  const languages = useActiveNetworkLanguages();
  const { workspace } = useWorkspace();

  const { sites } = useSites({ networkId });
  const { data: explorePanels } = useExplorePanels(networkId);
  const { data: systemExplorePanel } = useSystemExplorePanel(networkId);
  const { data: networkExplorePanel } = useExplorePanel(
    networkId,
    panelId,
    workspace,
  );

  const isGlobal = isGlobalTemplate(networkId);
  const data = isGlobal ? systemExplorePanel : networkExplorePanel;

  const [editedQuicklink, setEditedQuicklink] = useState(null);
  const [previewData, setPreviewData] = useState(data);

  const siteOptions = useMemo(
    () => getAvailableSites(explorePanels, sites),
    [sites, explorePanels],
  );

  const leftDrawerWidth = openDrawer ? 250 : 0;

  const handleEditCardChanged = (quicklink) => {
    const { actions, ...rest } = previewData;
    const updatedActions = actions.map((action) => action.id === quicklink.id ? quicklink : action,
    );
    setPreviewData({
      ...rest,
      actions: updatedActions,
    });
  };

  const handleEditCardSaved = (values, quicklink) => {
    setEditedQuicklink({
      id: editedQuicklink.id,
      order: editedQuicklink.order,
      filterLinkDestBySite: values.filterLinkDestBySite,
      icon:
        values.iconUrl === editedQuicklink?.icon?.url
          ? editedQuicklink.icon
          : { url: values.iconUrl },
      type: values.linkType,
      name: buildLabelByLang(values, 'nameEn', 'nameEs'),
      categories: values.linkType === 'category' ? values.categories || [] : [],
      list: values.linkType === 'list' ? { id: values.listDest || '' } : null,
      custom: values.linkType === 'custom' ? values.custom : null,
    });

    const { actions, ...rest } = previewData;
    const updatedActions = actions.map((action) => action.id === quicklink.id ? quicklink : action,
    );

    setPreviewData({
      ...rest,
      actions: updatedActions,
    });
  };

  useEffect(() => {
    if (data && !previewData) {
      setPreviewData(data);
    }
  }, [data, previewData]);

  if (userRole !== USER_ROLES.UBER_ADMIN && workspace === DRAFT_WORKSPACE) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PanelView
        data={data}
        hidden={!!editedQuicklink}
        onQuickLinkEdited={(quicklink) => setEditedQuicklink(quicklink)}
        onPanelChanged={(panel) => setPreviewData(panel)}
        onClose={() => handleNavigate(`/network/${networkId}/explore`)}
        availableSites={siteOptions}
        panel={data}
      />
      <PanelPreview
        fixed
        data={previewData ?? data}
        incompleteMessage={
          !editedQuicklink && data?.validation?.status === 'incomplete'
            ? 'This Explore Panel is incomplete.'
            : null
        }
        sx={{
          ...styles.previewContainer,
          left: editedQuicklink ? 0 : '836px',
          width: editedQuicklink
            ? `${size.width - 568 + leftDrawerWidth}px`
            : `${size.width - 836}px`,
        }}
        title={editedQuicklink ? getLabelByLang(previewData?.name) : null}
      />
      {editedQuicklink && (
        <EditExplorePanelPanel
          data={editedQuicklink}
          languages={languages}
          panelId={data?.id}
          panelType={data?.type}
          onEditCardCancelled={() => setEditedQuicklink(null)}
          onEditCardChanged={handleEditCardChanged}
          onEditCardSaved={handleEditCardSaved}
          title="Edit Explore Panel Quicklink"
        />
      )}
      {!isGlobal && (
        <WorkspaceSwitcher
          contentWidth={
            editedQuicklink
              ? size.width - 568 - leftDrawerWidth
              : size.width - 836
          }
          leftOffset={editedQuicklink ? 250 : 836 + (openDrawer ? 0 : 250)}
        />
      )}
    </>
  );
};

ExplorePanelDetailPageContent.propTypes = {
  styles: PropTypes.object.isRequired,
};

const ExplorePanelDetailPage = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  return (
    <LoggedinLayout
      sx={styles.root}
      enableSuspense={true}
      showWorkspaceSwitcher={false}
    >
      <ExplorePanelDetailPageContent styles={styles} />
    </LoggedinLayout>
  );
};

export default memo(ExplorePanelDetailPage);
