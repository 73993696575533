import { Close, ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  errorIcon: {
    color: theme.palette.warning.light,
    marginRight: '4px',
    fontSize: '24px',
  },
  closeButton: {
    color: theme.palette.grey[400],
    marginLeft: '64px',
    cursor: 'pointer',
  },
  warningMessage: {
    color: theme.palette.warning.light,
  },
});

const WarningPanel = ({ message, disableClose, handleClose, sx, ...rest }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={{ ...styles.root, ...sx }} {...rest}>
      <ErrorOutline sx={styles.errorIcon} />
      <Typography variant="subtitle2" sx={styles.warningMessage}>
        {message}
      </Typography>
      {!disableClose && <Close sx={styles.closeButton} onClick={handleClose} />}
    </Box>
  );
};

WarningPanel.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disableClose: PropTypes.bool,
  handleClose: PropTypes.func,
  sx: PropTypes.object,
};
WarningPanel.defaultProps = {
  sx: {},
};

export default WarningPanel;
