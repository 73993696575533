import { fetchApi } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import { fromi18nArrayEntriesErr, toError } from 'helpers/transformer-util';

export const TYPES = typeUtil(['START_PACKAGER', 'RESET_PACKAGER']);

const initialState = { loading: false, publishing: {} };

const fromPublishingRemoteErr = (errObject, remoteObject) => {
  const result = {};
  if (errObject.name) {
    result.name = fromi18nArrayEntriesErr(
      (errObject || {}).name,
      (remoteObject || {}).name,
    );
  }

  if (Array.isArray(errObject.tags)) {
    result.tags = errObject.tags.map((tag, index) => {
      const remotetag = remoteObject.tags[index];
      return {
        ...tag,
        name: fromi18nArrayEntriesErr(tag.name, remotetag.name),
      };
    });
  } else {
    result.tags = errObject.tags;
  }
  return result;
};

export const startPackager = (params) => (dispatch) => {
  const { networkId, message } = params;
  return fetchApi({
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/packager',
    body: {
      networkId,
      message,
    },
    params,
    type: TYPES.START_PACKAGER,
    dispatch,
  });
};

export const resetPackager = () => (dispatch) => {
  dispatch({
    type: TYPES.RESET_PACKAGER,
  });
  return Promise.resolve();
};

export const downloadPackage = (params) => (dispatch) => {
  const { zipUrl } = params;
  if (zipUrl) {
    const a = document.createElement('a');
    a.href = zipUrl;
    a.click();
    return Promise.resolve();
  }

  return Promise.reject(new Error('zipUrl is not specified.'));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.START_PACKAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        publishing: {
          packager: action.result,
          networkId: action.params.networkId,
        },
      };

    case TYPES.RESET_PACKAGER:
      return {
        ...state,
        loading: false,
        publishing: {},
      };

    case TYPES.START_PACKAGER_ERROR:
      return {
        ...state,
        loading: false,
        flash: {
          error: true,
          status: 'error',
          ...toError(action.result, (errObject) => fromPublishingRemoteErr(errObject, action.remoteObject),
          ),
        },
      };

    default:
      return state;
  }
};
