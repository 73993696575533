import { Box, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import { SCOPES } from 'components/authorization/authorize';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { getClientTypeLabel } from 'helpers/channel-util';
import { colorWithAlpha } from 'helpers/color-util';
import { convertStringToMMDDYYYY } from 'helpers/date-util';
import { tabA11yProps } from 'helpers/page-util';
import { getFullNameFromProfile } from 'helpers/string-util';
import { useNetworkChannels, useNetworkDataVersions } from 'hooks/dataHooks/useNetworkChannelsAndContentProgress';
import { useWorkspace, useWorkspaceData } from 'hooks/useWorkspace';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddOrEditDataChannelModal from 'pages/publishing/containers/addOrEditDataChannel';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const sortByVersion = (a, b) => {
  if (a.version === b.version) {
    return 0;
  }
  return a.version > b.version ? -1 : 1;
};

const activeChannelsColumns = ({ onChannelNameClick }) => [
  {
    Header: 'ID',
    accessor: 'id',
    hidden: true,
  },
  {
    Header: 'isDefault',
    accessor: 'isDefault',
    hidden: true,
  },
  {
    Header: 'Workspace',
    accessor: 'workspace',
    hidden: true,
  },
  {
    Header: 'Data Version',
    accessor: 'dataVersion',
    hidden: true,
  },
  {
    Header: 'internal',
    accessor: 'internal',
    hidden: true,
  },
  {
    Header: 'Name',
    accessor: 'label',
    minWidth: 200,
    cellStyle: {
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
    },
    onClick: (channelRowData) => onChannelNameClick(channelRowData),
    alwaysShown: true,
    sticky: 'left',
  },
  {
    Header: 'Type',
    accessor: 'clientType',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Purpose',
    accessor: 'purpose',
    minWidth: 200,
  },
  {
    Header: 'Facing',
    accessor: 'facing',
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    minWidth: 180,
    disableSortBy: true,
  },
  {
    Header: 'Last Updated',
    accessor: 'updatedAt',
    minWidth: 140,
  },
];

const archivedChannelsColumns = ({ onChannelNameClick }) => [
  {
    Header: 'ID',
    accessor: 'id',
    hidden: true,
  },
  {
    Header: 'Name',
    accessor: 'label',
    minWidth: 200,
    cellStyle: {
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
    },
    onClick: (channelRowData) => onChannelNameClick(channelRowData),
    alwaysShown: true,
    sticky: 'left',
  },
  {
    Header: 'Type',
    accessor: 'clientType',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Archived Reason',
    accessor: 'archivedReason',
    minWidth: 200,
  },
  {
    Header: 'Facing',
    accessor: 'facing',
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'Archived By',
    accessor: 'archivedBy',
    minWidth: 180,
    disableSortBy: true,
  },
  {
    Header: 'Archived Date',
    accessor: 'archivedAt',
    minWidth: 140,
  },
];

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  tableContainer: {
    height: 'calc(100vh - 275px)',
    marginTop: '20px',
    position: 'relative',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    height: 'calc(100% - 69px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
}));

const ChannelManagementPageContent = () => {
  const classes = useStyles();
  const { networkId } = useParams();
  const { authorize } = useContext(FlamingoContext);
  const [currentTab, setCurrentTab] = useState(0);
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channels, setChannels] = useState({ active: [], archived: [] });
  const { workspace } = useWorkspace();
  const { workspaces } = useWorkspaceData();
  const { data: networkDataVersions } = useNetworkDataVersions(networkId);
  const { data: networkChannelsData } = useNetworkChannels(networkId);

  useEffect(() => {
    if (networkChannelsData) {
      const channelData = networkChannelsData?.findNetworkChannel?.edges
        .map(({ node: channel }) => {
          const latestClientVersion = [...channel.packagerDataSpec].sort(
            sortByVersion,
          )?.[0];
          return {
            ...channel,
            name: channel.label,
            label: `${channel.label}${channel.isDefault ? ' (Default)' : ''}`,
            dataVersion: `v${latestClientVersion.version}`,
            workspace,
            archivedAt: convertStringToMMDDYYYY(channel.updatedAt),
            archivedBy: getFullNameFromProfile(channel.archivedBy?.profile),
            latestClientVersion,
            clientType: getClientTypeLabel(latestClientVersion.clientType),
            facing: channel.internal ? 'Internal' : 'External',
            updatedAt: convertStringToMMDDYYYY(channel.updatedAt),
            updatedBy: getFullNameFromProfile(channel.updatedBy?.profile),
            versions: channel.packagerDataSpec
              .map((p) => `v${p.version}`)
              .join(', '),
          };
        })
        .filter((c) => c.workspaces.filter((w) => w.name === workspace).length);

      const channels = channelData.reduce(
        (a, b) => b.enabled
            ? {
              ...a,
              active: [...a.active, b],
            }
            : {
              ...a,
              archived: [...a.archived, b],
            },
        { active: [], archived: [] },
      );
      setChannels(channels);
    }
  }, [networkChannelsData, workspace]);

  const onChannelNameClick = (channelRowData) => {
    const found = networkChannelsData?.findNetworkChannel?.edges.find(
      ({ node: channel }) => channel.id === channelRowData?.id,
    );
    setSelectedChannel(found ? found.node : null);
    setShowChannelModal(true);
  };

  const tabList = [
    {
      key: 'active-channels',
      label: 'Active Channels',
      data: channels.active,
      countTitleText: 'Active Channel',
      columns: activeChannelsColumns({
        ...authorize(SCOPES.NETWORK_CHANNELS.UPDATE) && {
          onChannelNameClick,
        },
      }),
    },
    {
      key: 'archived-channels',
      label: 'Archived Channels',
      data: channels.archived,
      countTitleText: 'Archived Channel',
      columns: archivedChannelsColumns({
        ...authorize(SCOPES.NETWORK_CHANNELS.UPDATE) && {
          onChannelNameClick,
        },
      }),
    },
  ];

  return (
    <FlamingoPage
      pageName="Channel Management"
      headerButtons={
        <AddCircleButton
          disabled={!authorize(SCOPES.NETWORK_CHANNELS.CREATE)}
          onClick={() => {
            setSelectedChannel(null);
            setShowChannelModal(true);
          }}
          tooltipTitle="Add a Data Channel"
        />
      }
    >
      <Box className={classes.content}>
        <Grid className={classes.takeoverTabs}>
          <Tabs
            value={currentTab}
            onChange={(e, idx) => setCurrentTab(idx)}
            aria-label="channelManagementTabs"
            indicatorColor="primary"
            textColor="primary"
          >
            {tabList.map(({ key, label, data }, index) => (
              <Tab
                key={`tab-${key}`}
                label={
                  data?.length > 0
                    ? label
                   : (
                    <Tooltip
                      title="Archived channels will appear here. Currently, no channels have been archived"
                      sx={{ pointerEvents: 'auto' }}
                    >
                      <span>{label}</span>
                    </Tooltip>
                  )
                }
                {...tabA11yProps('channelManagement', index)}
                disabled={data?.length === 0}
              />
            ))}
          </Tabs>
        </Grid>
        {tabList.map(({ key, label, data, countTitleText, columns }, index) => (
          <TabPanel
            key={`tabPanel-${key}`}
            value={currentTab}
            index={index}
            className={classes.tabPanel}
          >
            <Box className={classes.tabBody}>
              <Box className={classes.tableContainer}>
                <GozioTable
                  sx={{
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                  }}
                  data={data}
                  countTitleText={countTitleText}
                  columns={columns}
                />
              </Box>
            </Box>
          </TabPanel>
        ))}
      </Box>
      {showChannelModal && (
        <AddOrEditDataChannelModal
          channels={channels}
          existingChannel={selectedChannel}
          networkDataVersions={networkDataVersions}
          handleConfirm={() => {
            setSelectedChannel(null);
            setShowChannelModal(false);
          }}
          handleClose={(isLast) => {
            if (isLast) {
              setCurrentTab(0);
            }
            setSelectedChannel(null);
            setShowChannelModal(false);
          }}
          workspaceData={workspaces}
        />
      )}
    </FlamingoPage>
  );
};

const ChannelManagementPage = () => (
  <LoggedinLayout enableSuspense={true}>
    <ChannelManagementPageContent />
  </LoggedinLayout>
);

export default React.memo(ChannelManagementPage);
