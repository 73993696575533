import { Box, Button, Grid, Typography } from '@mui/material';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import Loading from 'components/loading/loading';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const MAXIMUM_IMAGE_WIDTH = 1200;
const MAXIMUM_IMAGE_HEIGHT = 630;
const MINIMUM_IMAGE_WIDTH = 600;
const MINIMUM_IMAGE_HEIGHT = 315;

export const LINK_CONTENT_SCHEMA = {
  linkContentTitle: {
    label: 'Link Title',
    schema: Yup.string(),
  },
  linkContentDescription: { label: 'Link Description', schema: Yup.string() },
  linkContentImageUrl: { label: 'Supporting Image', schema: Yup.string() },
};

export const LINK_CONTENT_ERROR_MAP = Object.keys(LINK_CONTENT_SCHEMA).map(
  (key) => [
    key,
    {
      label: LINK_CONTENT_SCHEMA[key].label,
      section: 'Link Content',
    },
  ],
);

export const buildLinkContentInitialValues = (data) => ({
  linkContentTitle: data?.openGraphMeta?.title,
  linkContentDescription: data?.openGraphMeta?.contentDescription,
  linkContentImageUrl: data?.openGraphMeta?.imageUrl,
});

const LinkContent = ({ disabled, loading, onImageUploaded, onSaved, sx }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Link Content</Typography>
        </Grid>
        <Grid item xs={6} sx={{ ...sx.row, marginTop: '-16px' }}>
          <Typography variant="body1" sx={{ color: ColorPalette.black }}>
            The information provided below informs Redirect Views, as well as
            the visual presentation of shared links within other applications.
          </Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="linkContentTitle"
            label={getFormLabel('Link Title')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="linkContentDescription"
            label={getFormLabel('Link Description')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <Typography variant="subtitle1">Add Supporting Image</Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: '-8px' }}>
          <Field name="linkContentImageUrl">
            {({ input: { onChange, value } }) => (
              <ImageUpLoader
                title={
                  <span>
                    Supporting Image
                  </span>
                }
                display="stacked"
                previewWidth={120}
                image={value ? { url: value } : null}
                fileInfo={
                  <span>
                    Supported files include jpeg and png. File size should be no
                    smaller than 1MB and no larger than 3MBs. Recommended image
                    size should be {MAXIMUM_IMAGE_WIDTH} x{' '}
                    {MAXIMUM_IMAGE_HEIGHT} and the smallest recommended size
                    would be {MINIMUM_IMAGE_WIDTH} x {MINIMUM_IMAGE_HEIGHT}.
                  </span>
                }
                onImageUpdated={({ file, url }) => {
                  onChange(url);
                  onImageUploaded({ file, url });
                }}
                maxFileSize={3000000}
                maxWidth={MAXIMUM_IMAGE_WIDTH}
                maxHeight={MAXIMUM_IMAGE_HEIGHT}
                minHeight={MINIMUM_IMAGE_HEIGHT}
                minWidth={MINIMUM_IMAGE_WIDTH}
                allowDelete={false}
                showImage={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box sx={sx.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

LinkContent.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onImageUploaded: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
};

LinkContent.defaultProps = {
  disabled: false,
  loading: false,
};

export default LinkContent;
