import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MyFavoritesActiveStateCard from 'components/dashboard/cardComponent/myFavoritesActiveStateCard';
import MyFavoritesEmptyStateCard from 'components/dashboard/cardComponent/myFavoritesEmptyStateCard';
import { dndHover, isCardIncompleteForPackaging } from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const SIZE_TO_CARD_SIZE = {
  short: 'small',
  medium: 'medium',
  tall: 'large',
};

const MyFavoritesCardLayout = ({
                                 id,
                                 index,
                                 card1,
                                 card2,
                                 configColors,
                                 isActiveState,
                                 size,
                                 preview,
                                 hovered,
                                 moveCard,
                                 onCardClick,
                                 onDrag,
                                 onHover,
                                 canDragAndDrop,
                               }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
        card2,
        size,
      },
      cardType: MyFavoritesCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const isIncompleteForPackaging = useMemo(
    () => isCardIncompleteForPackaging(card1)
      || isCardIncompleteForPackaging(card2),
    [card1, card2],
  );

  const cardSize = SIZE_TO_CARD_SIZE[size];

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'Favorites card' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          {!isActiveState && (
            <MyFavoritesEmptyStateCard
              displayType={card1.displayType}
              title={card1.title}
              subtitle={card1.subtitle}
              buttonBackgroundColor={configColors.dashboard_card_button_bg}
              buttonText={card1.buttonText}
              buttonTextColor={configColors.dashboard_card_button_text}
              iconUrl={card1.iconUrl}
              backgroundColor={card1.backgroundColor}
              textColor={card1.textColor}
              incompleteForPackaging={isIncompleteForPackaging}
              disabled={card1.disabled}
              preview={preview}
              selected={card1.selected}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card1',
                  size: cardSize,
                  ...card1,
                })
              }
            />
          )}
          {isActiveState && (
            <MyFavoritesActiveStateCard
              title={card2.title}
              backgroundColor={card2.backgroundColor}
              textColor={card2.textColor}
              incompleteForPackaging={isIncompleteForPackaging}
              disabled={card2.disabled}
              selected={card2.selected}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card2',
                  size: cardSize,
                  ...card2,
                })
              }
            />
          )}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

MyFavoritesCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  isActiveState: PropTypes.bool,
  size: PropTypes.oneOf(['short', 'medium', 'tall']).isRequired,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    displayType: PropTypes.oneOf(['favoriteLocations', 'favoriteProviders']),
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    selected: PropTypes.bool,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string,
    visibilityRule: PropTypes.string,
  }),
  card2: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    incompleteForPackaging: PropTypes.bool,
    selected: PropTypes.bool,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string,
    visibilityRule: PropTypes.string,
  }),
  configColors: PropTypes.shape({
    dashboard_card_button_bg: PropTypes.string,
    dashboard_card_button_text: PropTypes.string,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

MyFavoritesCardLayout.defaultProps = {
  card1: {
    incompleteForPackaging: false,
    title: 'Card title',
    clickHandler: () => {},
  },
  card2: {
    incompleteForPackaging: false,
    title: 'Card title',
    clickHandler: () => {},
  },
  configColors: {},
  preview: false,
  isActiveState: false,
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default MyFavoritesCardLayout;
