import { Typography } from '@mui/material';
import React from 'react';

const EmptyTable = (props) => (
	<Typography
		variant="h1"
		className="hero"
		sx={{
			height: '100%',
			minHeight: '200px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
		{props.title || 'No Results Found'}
	</Typography>
);

export default EmptyTable;
