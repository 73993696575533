import { connectRouter } from 'connected-react-router';
import categories from 'modules/categories';
import flamingo from 'modules/flamingo';
import lists from 'modules/lists';
import media from 'modules/media';
import personas from 'modules/personas';
import publishing from 'modules/publishing';
import typeahead from 'modules/typeahead';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

export default (history) => combineReducers({
    categories,
    form: formReducer,
    lists,
    media,
    personas,
    publishing,
    router: connectRouter(history),
    typeahead,
    flamingo,
  });
