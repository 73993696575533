import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang, hasSpanish } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useGetCategoryGroup } from 'hooks/dataHooks/useCategories';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import CategoryListing from 'pages/categories/containers/categoryListing';
import GeneralTab from 'pages/categories/containers/generalTab';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  headerWrap: {
    marginTop: '8px',
    marginBottom: '24px',
  },
  headerCaption: {
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  content: {
    height: 'calc(100vh - 235px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
}));

const CategoryGroupPage = () => {
  const classes = useStyles();
  const { networkId, categoryId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const [currentTab, setCurrentTab] = useState(0);
  const { loading, data: category } = useGetCategoryGroup({ id: categoryId });

  const languages = useActiveNetworkLanguages();
  const hasSpanishLang = hasSpanish(languages);
  const groupName = getLabelByLang(category.name);

  return (
    <LoggedinLayout loading={loading} supportedWorkspace={LIVE_WORKSPACE}>
      <Box className={classes.headerWrap}>
        <Typography variant="subtitle2" className={classes.headerCaption}>
          Category Group
        </Typography>
        <Typography variant="h1">{groupName}</Typography>
        <BreadCrumb
          parents={[
            { name: 'Categories', link: `/network/${networkId}/categories` },
          ]}
          title={groupName}
        />
      </Box>
      <Box className={classes.content}>
        <Grid className={classes.takeoverTabs}>
          <Tabs
            value={currentTab}
            onChange={(e, idx) => setCurrentTab(idx)}
            aria-label="simple tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Categories" {...tabA11yProps('category-group', 0)} />
            <Tab label="General" {...tabA11yProps('category-group', 1)} />
          </Tabs>
        </Grid>
        <TabPanel
          value={currentTab}
          index={0}
          className={classes.tabPanel}
          sx={{ ...currentTab === 0 && { height: 'calc(100% - 69px)' } }}
        >
          <Box className={classes.tabBody}>
            <CategoryListing
              categories={category?.children || []}
              navigate={handleNavigate}
              networkId={networkId}
              isInDetail={true}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
          className={classes.tabPanel}
          sx={{ ...currentTab === 1 && { height: 'calc(100% - 69px)' } }}
        >
          <Box className={classes.tabBody}>
            <GeneralTab
              category={category}
              languages={languages}
              networkId={networkId}
              hasSpanish={hasSpanishLang}
              isGlobalTemplate={isGlobalTemplate(networkId)}
            />
          </Box>
        </TabPanel>
      </Box>
    </LoggedinLayout>
  );
};

export default React.memo(CategoryGroupPage);
