import { fetchApi } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import { toError } from 'helpers/transformer-util';

export const TYPES = typeUtil([
  'UPLOAD_FILE',
  'COPY_IMAGE_BY_URL',
  'CREATE_IMAGE',
  'UPDATE_IMAGE',
  'CREATE_MEDIA_IMAGE',
  'CREATE_MEDIA_VIDEO',
  'UPDATE_MEDIA',
  'RESET_MEDIA',
]);

const initialState = {
  uploadedFiles: [],
  erroredFiles: [],
  image: null,
  flash: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        image: null,
        flash: null,
        uploadedFiles: action.result,
      };
    case TYPES.UPLOAD_FILE_ERROR:
      return {
        ...state,
        image: null,
        flash: null,
        erroredFiles: action.result,
      };
    case TYPES.COPY_IMAGE_BY_URL_SUCCESS:
    case TYPES.CREATE_IMAGE_SUCCESS:
    case TYPES.UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        flash: null,
        image: action.result,
      };
    case TYPES.CREATE_MEDIA_IMAGE_SUCCESS:
    case TYPES.CREATE_MEDIA_VIDEO_SUCCESS:
    case TYPES.UPDATE_MEDIA_SUCCESS:
      return {
        ...state,
        flash: null,
        media: action.result,
      };
    case TYPES.COPY_IMAGE_BY_URL_ERROR:
    case TYPES.CREATE_IMAGE_ERROR:
    case TYPES.UPDATE_IMAGE_ERROR:
    case TYPES.CREATE_MEDIA_IMAGE_ERROR:
    case TYPES.CREATE_MEDIA_VIDEO_ERROR:
    case TYPES.UPDATE_MEDIA_ERROR:
      const err = toError(action.result, (path) => path);
      err.message = `Error saving icon: ${err.message}`;
      return {
        ...state,
        flash: {
          error: true,
          ...err,
        },
      };
    case TYPES.RESET_MEDIA:
      return {
        ...state,
        flash: null,
        uploadedFiles: [],
        erroredFiles: [],
        image: null,
      };
    default:
      return state;
  }
};

export const uploadedFiles = (files) => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_FILE_SUCCESS,
    result: files,
  });
  return Promise.resolve();
};

export const erroredFiles = (files) => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_FILE_ERROR,
    result: files,
  });
  return Promise.resolve();
};

export const uploadMediaImageToService = (imageData) => (dispatch) => {
  if (!imageData) {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }
  const formData = new FormData();
  formData.append('image', imageData);
  return fetchApi({
    headers: {
      Accept: 'application/json',
    },
    method: 'post',
    url: '/media/image',
    body: formData,
    params: imageData,
    type: TYPES.CREATE_MEDIA_IMAGE,
    host: process.env.REACT_APP_IMAGE_SERVICE,
    dispatch,
  });
};

export const uploadMediaVideoToService = (url) => (dispatch) => {
  if (!url) {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  return fetchApi({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/media/video/url',
    body: { url },
    type: TYPES.CREATE_MEDIA_VIDEO,
    host: process.env.REACT_APP_IMAGE_SERVICE,
    dispatch,
  });
};

export const updateMediaImageToService
  = (mediaId, type, file, imageId) => (dispatch) => {
    if (!file) {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }

    const token = localStorage.getItem('token') || '';

    const formData = new FormData();
    formData.append('image', file);
    return fetch(`${process.env.REACT_APP_IMAGE_SERVICE}/image/${imageId}`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
      params: file,
    });
  };

export const cloneMediaImageByUrl = (url) => (dispatch) => {
  if (!url) {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  return fetchApi({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: `/image/url`,
    body: { url },
    type: TYPES.COPY_IMAGE_BY_URL,
    host: process.env.REACT_APP_IMAGE_SERVICE,
    dispatch,
  });
};

export const updateMediaVideoToService = (mediaId, type, url) => (dispatch) => {
  if (!url) {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  return fetchApi({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/media/${mediaId}/url`,
    body: { type, url },
    type: TYPES.UPDATE_MEDIA,
    host: process.env.REACT_APP_IMAGE_SERVICE,
    dispatch,
  });
};

export const uploadImageToService
  = (imageData, toastNotificationError = null) => async (dispatch) => {
      if (!imageData) {
        return new Promise((resolve, reject) => {
          resolve(null);
        });
      }
      const formData = new FormData();
      formData.append('image', imageData);
      const response = await fetchApi({
        headers: {
          Accept: 'application/json',
        },
        method: 'post',
        url: '/image',
        body: formData,
        params: imageData,
        type: TYPES.CREATE_IMAGE,
        host: process.env.REACT_APP_IMAGE_SERVICE,
        dispatch,
      });
      if (response?.result?.message || !response.url) {
        if (toastNotificationError) {
          toastNotificationError(
            response.result?.message || 'Error uploading the image!',
          );
        } else {
          throw new Error(
            response.result?.message || 'Error uploading the image!',
          );
        }
      }
      return response;
    };

export const uploadImage = async (imageData, toastNotificationError = null) => {
  if (!imageData) {
    return null;
  }
  const formData = new FormData();
  formData.append('image', imageData);
  const response = await fetchApi({
    headers: {
      Accept: 'application/json',
    },
    method: 'post',
    url: '/image',
    body: formData,
    params: imageData,
    type: TYPES.CREATE_IMAGE,
    host: process.env.REACT_APP_IMAGE_SERVICE,
    dispatch: () => {},
  });
  if (response?.result?.message || !response.url) {
    if (toastNotificationError) {
      toastNotificationError(
        response.result?.message || 'Error uploading the image!',
      );
    } else {
      throw new Error(response.result?.message || 'Error uploading the image!');
    }
  }
  return response;
};

export const resetUploadedMedia = () => (dispatch) => {
  dispatch({
    type: TYPES.RESET_MEDIA,
  });
  return Promise.resolve();
};
