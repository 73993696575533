import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GozioTableWithVirtualization from 'components/tables/gozioTableWithVirtualization';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const getSubs = (children = []) => {
  const names = children
    ?.map((node) => node && getLabelByLang(node.name))
    .filter((l) => l);
  return names.join(', ');
};

const processCategories = (list = []) => list.map((cat) => {
    const catName = getLabelByLang(cat.name);
    return {
      categoryId: cat.id,
      name: catName,
      children: getSubs(cat.children),
      searchIdx: catName.toLowerCase(),
      caret: (
        <ArrowForwardIosIcon
          sx={{ fontSize: '16px', color: ColorPalette.grey[400] }}
        />
      ),
    };
  });

const filterData = (data, filter) => {
  const lowerFilter = filter.toLowerCase();
  return data.filter((item) => item.searchIdx.indexOf(lowerFilter) >= 0);
};

const GroupsTable = ({
  visible,
  categories,
  handleCountUpdate,
  filter,
  setCurrentCategory,
}) => {
  const handleSelect = (data) => {
    const currentCat = categories.find((cat) => cat.id === data.categoryId);
    setCurrentCategory(currentCat);
  };

  const GROUPS_COLUMNS = useMemo(
    () => [
      {
        accessor: 'categoryId',
        hidden: true,
      },
      {
        Header: 'Category Group Name',
        accessor: 'name',
        onClick: handleSelect,
        maxWidth: 200,
      },
      {
        Header: 'Categories',
        accessor: 'children',
        onClick: handleSelect,
      },
      {
        Header: '',
        accessor: 'caret',
        disableSortBy: true,
        maxWidth: 40,
        onClick: handleSelect,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fullData = useMemo(
    () => processCategories(categories),
    [categories],
  );
  const filteredData = useMemo(() => {
    const retData = filterData(fullData, filter);
    setTimeout(() => handleCountUpdate(retData.length), 50);
    return retData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullData, filter]);

  return visible ? (
    <GozioTableWithVirtualization
      columns={GROUPS_COLUMNS} // eslint-disable-line react-hooks/exhaustive-deps
      data={filteredData}
      sortBy={[{ id: 'name', desc: false }]}
    />
  ) : null;
};

GroupsTable.propTypes = {
  visible: PropTypes.bool,
  filter: PropTypes.string,
  categories: PropTypes.array,
  handleCountUpdate: PropTypes.func,
  setCurrentCategory: PropTypes.func,
};

GroupsTable.defaultProps = {
  visible: true,
  filter: '',
  categories: [],
  handleCountUpdate: () => {},
  setCurrentCategory: () => {},
};

export default React.memo(GroupsTable);
