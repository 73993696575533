import { useMutation } from '@apollo/client';
import {
  CREATE_NETWORK_DEEP_LINK,
  CREATE_NETWORK_DEEP_LINK_CONFIGURATION,
  FIND_NETWORK_DEEP_LINK,
  FIND_NETWORK_DEEP_LINK_CONFIGURATION,
  UPDATE_NETWORK_DEEP_LINK,
  UPDATE_NETWORK_DEEP_LINK_CONFIGURATION,
} from 'graphql/queries';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';

import { useSuspenseQueryWrapper } from 'hooks/dataHooks/useQueryWrapper';

export const useFindNetworkDeepLinkConfiguration = (networkId) => useSuspenseQueryWrapper(FIND_NETWORK_DEEP_LINK_CONFIGURATION, {
  variables: {
    where: {
      network: {
        id: networkId,
      },
    },
  },
  dataFn: (data) => data?.findNetworkDeepLinkConfiguration?.edges?.map((item) => item.node)?.[0] || null,
  skipFn: (data) => data && Object.keys(data).length > 0,
});

export const useCreateNetworkDeepLinkConfiguration = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkDeepLinkConfiguration');

  return useMutation(CREATE_NETWORK_DEEP_LINK_CONFIGURATION, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(`The configuration has been saved.`);
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNetworkDeepLinkConfiguration = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkDeepLinkConfiguration');

  return useMutation(UPDATE_NETWORK_DEEP_LINK_CONFIGURATION, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(`The configuration has been updated.`);
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useFindNetworkDeepLink = (networkId) => useSuspenseQueryWrapper(FIND_NETWORK_DEEP_LINK, {
  variables: { where: { network: { id: networkId } } },
  dataFn: (data) => data?.findNetworkDeepLink?.edges?.map((item) => item.node) || null,
  skipFn: (data) => data && Object.keys(data).length > 0,
});

export const useCreateNetworkDeepLink = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkDeepLink');

  return useMutation(CREATE_NETWORK_DEEP_LINK, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(`The deep link has been saved.`);
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNetworkDeepLink = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkDeepLink');

  return useMutation(UPDATE_NETWORK_DEEP_LINK, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(`The deep link has been updated.`);
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
