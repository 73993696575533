import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from 'components/dashboard/cardComponent/card';
import { MyChartQuickLinksCard } from 'components/dashboard/cardComponent/myChartQuickLinksCard';
import {
  dndHover,
  isCardIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const SIZE_TO_CARD_SIZE = {
  short: 'small',
  medium: 'medium',
  tall: 'large',
};

const getTextAlignment = (size, textAlignment = null) => {
  let cardTextAlignment = null;
  switch (size) {
    case 'short':
      cardTextAlignment = textAlignment || 'left';
      break;
    case 'medium':
      cardTextAlignment = textAlignment || 'center';
      break;
    case 'tall':
      cardTextAlignment = textAlignment || 'center';
      break;
    default:
      break;
  }
  return cardTextAlignment;
};

const MyChartCardLayout = ({
                             id,
                             index,
                             card1,
                             card2,
                             showQuickLinks,
                             size,
                             preview,
                             hovered,
                             moveCard,
                             onCardClick,
                             onDrag,
                             onHover,
                             canDragAndDrop,
                           }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
        card2,
        size,
      },
      cardType: MyChartCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const isIncompleteForPackaging = useMemo(
    () => isCardIncompleteForPackaging(card1)
      || isCardIncompleteForPackaging(card2),
    [card1, card2],
  );

  const cardSize = SIZE_TO_CARD_SIZE[size];

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'MyChart cards' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          {showQuickLinks && (
            <MyChartQuickLinksCard
              size={cardSize}
              title={card2.title}
              backgroundColor={card2.backgroundColor}
              incompleteForPackaging={isIncompleteForPackaging}
              quickLinksData={card2.quickLinksData || []}
              textAlignment={getTextAlignment(size, 'left')}
              textColor={card2.textColor}
              disabled={card1.disabled}
              preview={preview}
              selected={card1.selected}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card2',
                  size: cardSize,
                  ...card2,
                })
              }
            />
          )}
          {!showQuickLinks && (
            <Card
              standalone={true}
              size={cardSize}
              title={card1.title}
              subtitle={card1.subtitle}
              overline={card1.overline}
              backgroundImageUrl={card1.backgroundImageUrl}
              backgroundColor={card1.backgroundColor}
              iconUrl={card1.iconUrl}
              incompleteForPackaging={isIncompleteForPackaging}
              textAlignment={getTextAlignment(size, card1.textAlignment)}
              textColor={card1.textColor}
              disabled={card1.disabled}
              selected={card1.selected}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card1',
                  size: cardSize,
                  ...card1,
                })
              }
            />
          )}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

MyChartCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  showQuickLinks: PropTypes.bool,
  size: PropTypes.oneOf(['short', 'medium', 'tall']).isRequired,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textAlignment: PropTypes.oneOf(['left', 'center']),
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card2: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textAlignment: PropTypes.oneOf(['left', 'center']),
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

MyChartCardLayout.defaultProps = {
  card1: {
    incompleteForPackaging: false,
    title: 'Card title',
    clickHandler: () => {},
  },
  card2: {
    incompleteForPackaging: false,
    title: 'Card title',
    clickHandler: () => {},
  },
  preview: false,
  showQuickLinks: false,
  size: 'medium',
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default MyChartCardLayout;
