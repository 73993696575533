import { Box, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SCOPES } from 'components/authorization/authorize';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import Banner from 'pages/messaging/containers/bannerMessage/banner';
import History from 'pages/messaging/containers/bannerMessage/history';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { Suspense, useContext, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 186px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
}));

const BannerMessagePageContent = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const { networkId } = useParams();
  const { activeNetwork, authorize } = useContext(FlamingoContext);
  const banners = activeNetwork?.configuration?.features?.banners || null;

  if (isGlobalTemplate(networkId) || !authorize(SCOPES.BANNER_MESSAGE.VIEW)) {
    return <Navigate to="/" />;
  }

  if (banners !== 'on') {
    return (
      <EmptyPanel
        title="The Banners Feature is Not Enabled"
        subtitle="Contact your Gozio representative to learn more."
      />
    );
  }

  return (
    <FlamingoPage pageName="Banner Message">
      <Box className={classes.content}>
        <Grid className={classes.takeoverTabs}>
          <Tabs
            value={currentTab}
            onChange={(e, idx) => setCurrentTab(idx)}
            aria-label="simple tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="New Banner" {...tabA11yProps('banner', 0)} />
            <Tab label="History" {...tabA11yProps('banner', 1)} />
          </Tabs>
        </Grid>
        <TabPanel
          value={currentTab}
          index={0}
          className={classes.tabPanel}
          sx={{
            ...currentTab === 0 && {
              height: 'calc(100% - 69px)',
            },
          }}
        >
          <Box
            className={classes.tabBody}
            sx={{
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingBottom: '8px',
            }}
          >
            <Suspense fallback={<Loading />}>
              <Banner networkId={networkId} />
            </Suspense>
          </Box>
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
          className={classes.tabPanel}
          sx={{
            ...currentTab === 1 && {
              height: 'calc(100% - 69px)',
            },
          }}
        >
          <Box className={classes.tabBody}>
            <Suspense fallback={<Loading />}>
              <History networkId={networkId} />
            </Suspense>
          </Box>
        </TabPanel>
      </Box>
    </FlamingoPage>
  );
};

const BannerMessagePage = () => (
  <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
    <BannerMessagePageContent />
  </LoggedinLayout>
);

export default BannerMessagePage;
