import { Box, Tooltip } from '@mui/material';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import React, { useMemo } from 'react';

const getColumns = (networkId, navigate) => [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Category Group Name',
    accessor: 'name',
    minWidth: 350,
    width: 250,
    onClick: (data) => {
      navigate(`/network/${networkId}/categories/group/${data.id}`);
    },
    sticky: 'left',
  },
  {
    Header: 'Categories',
    accessor: 'categories',
    minWidth: 600,
    width: 600,
    disableSortBy: true,
  },
];

const CategoryGroupListing = ({
                                groups,
                                loading,
                                networkId,
                                navigate,
                                search,
                              }) => {
  const processGroups = () => groups.map((g) => {
      const catNames = g?.children
        ?.map((c) => getLabelByLang(c?.name))
        .join(', ');

      return {
        id: g.id,
        name: getLabelByLang(g.name),
        searchIdx: getLabelByLang(g.name).toLowerCase(),
        categories: (
          <Tooltip title={catNames.length > 200 ? catNames : ''}>
            <Box>{catNames}</Box>
          </Tooltip>
        ),
      };
    });

  const memoizedData = useMemo(
    () => processGroups().filter((item) => item.searchIdx.indexOf(search) >= 0),
    [groups, search], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const memoizedColumns = useMemo(() => {
    const columns = getColumns(networkId, navigate);
    return columns;
  }, [networkId, navigate]);

  const count = memoizedData?.length || 0;
  return (
    <GozioTable
      name="CategoryGroupsTable"
      columns={memoizedColumns}
      data={memoizedData}
      sortBy={[{ id: 'name', desc: false }]}
      sx={{ paddingTop: '8px' }}
      loading={loading}
      countTitle={getTableCountTitle(count, 'Category Group')}
      emptyContent={<EmptyTable />}
    />
  );
};

CategoryGroupListing.propTypes = {};

CategoryGroupListing.defaultProps = {};

export default CategoryGroupListing;
