export const CLIENT_TYPES = {
  KIOSK: 'kiosk',
  MOBILE: 'mobile',
  SDK: 'sdk',
};

export const getClientTypeLabel = (clientType) => {
  const clientTypeLabels = {
    kiosk: 'Kiosk',
    mobile: 'Mobile',
    sdk: 'SDK',
  };
  return clientTypeLabels[clientType?.toLowerCase()];
};
