import { CheckCircle } from '@mui/icons-material';
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = ({ theme }) => ({
  customSelect: {
    '& > div > svg': {
      display: 'none',
    },
  },
  selectDisabled: {
    color: theme.palette.grey[500],
    fontWeight: 300,
    '& span.MuiInputLabel-asterisk': {
      color: theme.palette.grey[400],
    },
  },
  item: {
    display: 'flex',
    minHeight: '48px',
  },
  error: {
    '& label.MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& svg': {
      color: theme.palette.error.main,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
  popover: {
    '& .MuiPaper-root.MuiMenu-paper': {
      borderRadius: 0,
      marginTop: '8px',

      '& .MuiList-root': {
        padding: 0,
      },
    },
  },
});

const GozioSelect = ({
                       sx,
                       menuItemSx,
                       input,
                       options: incomingOptions,
                       label,
                       isUberAdmin,
                       disabled,
                       displayEmpty,
                       maxHeight,
                       fullHeight,
                       fullWidth,
                       multiple,
                       error,
                       renderValue,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const {
    value = multiple ? [] : '',
    onChange = () => {},
    onClick = () => {},
  } = input ?? {
    value: multiple ? [] : '',
  };
  const [multiValue, setMultiValue] = useState(
    Array.isArray(value) ? value : [],
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (multiple) {
      setMultiValue(Array.isArray(value) ? value : []);
    }
  }, [multiple, value]);

  useEffect(() => {
    const list = incomingOptions;
    if (displayEmpty) {
      list.unshift({ id: '', label: <span> </span> });
    }
    setOptions(list);
  }, [incomingOptions, displayEmpty]);

  const hasValue = () => {
    if (options?.length === 0) {
      return false;
    }

    if (options.find((opt) => opt.id === value)) {
      return true;
    }
  };

  const renderMenuItem = (item) => {
    if (multiple) {
      return (
        <>
          <Checkbox checked={multiValue.includes(item.id)} />
          {item.label}
        </>
      );
    }

    return (
      <>
        {item.label}
        {item.id && item.id === value && (
          <CheckCircle color="primary" sx={{ fontSize: '20px' }} />
        )}
      </>
    );
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        ...styles.root,
        ...error && styles.error,
        ...sx,
      }}
    >
      <InputLabel
        sx={{ ...disabled && styles.selectDisabled }}
        disableAnimation
      >
        {label}
      </InputLabel>
      <Select
        value={multiple ? multiValue : hasValue() ? value : ''}
        onChange={(e) => {
          if (multiple) {
            setMultiValue(e.target.value);
          }
          onChange(e.target.value);
        }}
        onClick={(e) => onClick(e.target.value)}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          PopoverClasses: {
            root: styles.popover,
          },
        }}
        sx={{
          ...styles.customSelect,

          '&.MuiPopover-root': {
            '& .MuiList-root': {
              maxHeight: fullHeight ? 'none' : maxHeight,
            },
          },
        }}
        disabled={disabled}
        native={false}
        displayEmpty={true}
        renderValue={renderValue}
        multiple={multiple}
      >
        {options.map((item, index) => !item.adminOnly || isUberAdmin ? (
            <MenuItem
              key={`menuOpt-${item.id}`}
              value={item.id}
              disabled={item.hasOwnProperty('disabled') ? item.disabled : false}
              disableRipple
              sx={{
                ...styles.item,
                ...menuItemSx,
                ...!multiple && { justifyContent: 'space-between' },

                '&.MuiIconButton-label': {
                  ...multiple && { paddingRight: '12px' },
                },
              }}
            >
              {renderMenuItem(item)}
            </MenuItem>
          ) : null,
        )}
      </Select>
    </FormControl>
  );
};

GozioSelect.propTypes = {
  sx: PropTypes.object,
  menuItemSx: PropTypes.object,
  options: PropTypes.array,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  label: PropTypes.node,
  isUberAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  maxHeight: PropTypes.number,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

GozioSelect.defaultProps = {
  sx: {},
  menuItemSx: {},
  options: [],
  isUberAdmin: false,
  disabled: false,
  displayEmpty: false,
  error: false,
  fullWidth: true,
  fullHeight: false,
  maxHeight: 290,
  multiple: false,
};

export default React.memo(GozioSelect);
