import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

const useStyles = makeStyles(({ spacing }) => ({
  padding: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
    '& li': {
      padding: 0,
    },
  },
}));

const DeleteLocationDependenciesList = () => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="subtitle1">
        There may be dependencies that exist. Deleting now could affect the
        following areas within Portal.
      </Typography>
      <List classes={{ padding: classes.padding }}>
        <ListItem disableGutters>
          <ListItemText>
            <Typography variant="subtitle1">Content Groups</Typography>
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>
            <Typography variant="subtitle1">Messaging Center</Typography>
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>
            <Typography variant="subtitle1">Mobile Settings</Typography>
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>
            <Typography variant="subtitle1">Kiosk Settings</Typography>
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>
            <Typography variant="subtitle1">
              Reroutes &amp; Default Destinations in other Locations
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Typography variant="subtitle1">
        Are you sure you want to continue deleting?
      </Typography>
    </Box>
  );
};

export default DeleteLocationDependenciesList;
