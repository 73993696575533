import { colorWithAlpha } from 'helpers/color-util';

const DeprecatedModalStyles = (theme) => ({
  buttonGroupTitle: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '0.1px',
    marginTop: '22px',
  },
  contentTextStyle: {
    '& h6': {
      fontWeight: 'initial',
    },
    color: theme.palette.grey[500],
    fontSize: '14px',
  },
  deleteButton: {
    width: '100%',
  },
  dialogContent: {
    flex: 1,
    overflowX: 'hidden',
    '&.MuiDialogContent-root:first-child': {
      paddingTop: 0,
    },
  },
  lastToggleButton: {
    borderRightStyle: 'solid',
  },
  selected: {
    backgroundColor: `${colorWithAlpha(
      theme.palette.blue[100],
      0.5,
    )} !important`,
    border: `1px ${theme.palette.grey[300]} solid !important`,
    padding: '0 16px 0 16px',
    textTransform: 'none',
  },
  titleStyle: {
    padding: '32px 32px 0',
    '& h2': {
      color: theme.palette.grey[500],
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '39px',
    },
    '& svg': {
      color: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
  toggleButton: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 700,
    height: '40px',
    letterSpacing: '1.28px',
    paddingLeft: '18px',
    paddingRight: '18px',
    textTransform: 'uppercase',
  },
  toggleGroup: {
    background: 'inherit',
    boxShadow: 'none',
    display: 'block',
    marginBottom: '20px',
    marginTop: '15px',
  },
  toolTipWidth: {
    maxWidth: '195px',
  },
  weightToggleError: {
    color: 'red',
    fontSize: '0.75em',
    marginLeft: '8px',
  },
  actions: {
    background: theme.palette.grey[100],
    margin: 0,
    padding: theme.spacing(2),
    borderRadius: '0 0 8px 8px',
  },
  root: {
    position: 'relative',
  },
  closeBtn: {
    padding: theme.spacing(2),
    position: 'absolute',
    right: 0,
    top: 0,

    '& svg': {
      fontSize: '22px',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  dialogContentCentered: {
    flex: 1,
    overflowX: 'hidden',
    marginBottom: theme.spacing(15),
    textAlign: 'center',
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 80px)',
  },
  titleCentered: {
    padding: 0,
    '& h2': {
      color: theme.palette.grey[500],
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '39px',
    },
    '& svg': {
      color: colorWithAlpha(theme.palette.black, 0.54),
    },
    marginTop: `${theme.spacing(15)} !important`,
    textAlign: 'center',
  },
});

export default DeprecatedModalStyles;
