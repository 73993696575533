import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  charCount: {
    color: theme.palette.grey[400],
  },
  charCountError: {
    color: theme.palette.error.main,
  },
  container: {
    width: '100%',
  },
  textfield: {
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: theme.palette.grey[500],
    },
  },
}));

const MaterialTextField = ({
                             charLimit,
                             disabled,
                             error,
                             value,
                             onChange,
                             helperText,
                             variant,
                             containerClassName,
                             onBlur,
                             customTags,
                             inputProps,
                             label,
                             placeholder,
                             ...rest
                           }) => {
  const classes = useStyles();
  const [uncontrolledCharCount, setUncontrolledCharCount] = useState(0);

  if (charLimit) {
    inputProps.maxLength = charLimit;
  } else {
    delete inputProps.maxLength;
  }

  const textFieldOnChange = (e) => {
    setUncontrolledCharCount(e.target.value.length);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const charCount = () => value ? value.length : uncontrolledCharCount;

  const charOverLimit = () => {
    if (!charLimit) {
      return false;
    }
    return charCount() > charLimit;
  };

  return (
    <Box className={`${classes.container} ${containerClassName}`}>
      <TextField
        className={classes.textfield}
        label={label}
        placeholder={placeholder ?? (typeof label === 'string' ? label : '')}
        value={value}
        error={error}
        {...rest}
        onChange={textFieldOnChange}
        variant={variant}
        onBlur={onBlur}
        inputProps={{ ...inputProps, ...customTags }}
        disabled={disabled}
      />
      {charLimit ? (
        <Typography
          className={`${classes.charCount} ${
            charOverLimit() ? classes.charCountError : null
          }`}
          variant="caption"
        >
          Character Count {charCount()}/{charLimit}
        </Typography>
      ) : null}
      {helperText ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : null}
    </Box>
  );
};

MaterialTextField.propTypes = {
  label: PropTypes.node,
  charLimit: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  containerClassName: PropTypes.string,
  customTags: PropTypes.objectOf(PropTypes.string),
  inputProps: PropTypes.object,
};

MaterialTextField.defaultProps = {
  charLimit: null,
  disabled: false,
  inputProps: {},
  customTags: {},
  label: '',
  placeholder: null,
  variant: 'outlined',
};

export default MaterialTextField;
