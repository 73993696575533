import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { getFormLabel } from 'helpers/form-util';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = () => ({
  root: {
    width: '140px',
    height: '56px',
  },
});

const TimePicker = ({
                      sx,
                      disabled,
                      label,
                      onChange,
                      required,
                      value,
                    }) => {
  const styles = buildStyles();

  const parsedValue = value ? value.split(':') : null;
  const [selectedTime, setSelectedTime] = useState(
    value
      ? dayjs(`${dayjs().year()}-01-01T${parsedValue[0]}:${parsedValue[1]}`)
      : null,
  );

  useEffect(() => {
    setSelectedTime(
      value
        ? dayjs(`${dayjs().year()}-01-01T${parsedValue[0]}:${parsedValue[1]}`)
        : null,
    );
  }, [parsedValue, value]);

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiTimePicker
          disabled={disabled}
          label={getFormLabel(label, required)}
          onChange={(newValue) => {
            setSelectedTime(newValue);
            onChange(newValue);
          }}
          value={selectedTime}
        />
      </LocalizationProvider>
    </Box>
  );
};

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string, // HH:MM (24-hour format)
};

TimePicker.defaultProps = {
  disabled: false,
  onChange: () => {},
  required: false,
  sx: {},
  value: null,
};

export default TimePicker;
