import { Box, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Authorize, SCOPES } from 'components/authorization/authorize';
import Loading from 'components/loading/loading';
import SpeedDialBackdrop from 'components/speedDial/speedDialBackdrop';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { formatLang } from 'helpers/form-util';
import { hasSpanish } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useCategoryGroups, useCreateCategory } from 'hooks/dataHooks/useCategories';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import AddCategoryModel from 'pages/categories/containers/addCategoryModel';
import CategoryGroupListing from 'pages/categories/containers/categoryGroupListing';
import CategoryListing from 'pages/categories/containers/categoryListing';
import TagListing from 'pages/categories/containers/tagListing';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 'calc(100vh - 190px)',
    borderRadius: '20px',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
  speedDialContainer: {
    marginLeft: theme.spacing(5),
  },
}));

const CategoriesPageContent = () => {
  const classes = useStyles();
  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const [addCategoryModal, setAddCategoryModal] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchParam, setSearchParam] = useState('');
  const [createCategory, { loading: saving }] = useCreateCategory();
  const { groups, categories } = useCategoryGroups(networkId);

  useEffect(() => {
    setSearchParam('');
  }, [networkId]);

  const { activeNetwork } = useContext(FlamingoContext);

  const languages = useActiveNetworkLanguages();
  const hasSpanishLang = hasSpanish(languages);

  const tags = useMemo(
    () => categories.reduce(
        (tags, cat) => tags.concat(
            cat?.tags?.map((c) => ({
              ...c,
              parent: { id: cat.id, name: cat.name },
            })) || [],
          ),
        [],
      ),
    [categories],
  );

  const createNewCategory = async (values) => {
    const response = await createCategory({
      variables: {
        input: {
          parent: values.parentId,
          name: formatLang('name', values),
        },
      },
    });
    if (response?.data?.createCategory?.id) {
      setAddCategoryModal(null);
    }
  };

  return (
    <FlamingoPage
      pageName="Categories"
      onSearchChange={(search) => setSearchParam(search.toLowerCase())}
      headerButtons={
        <Grid container>
          {isGlobalTemplate(networkId) && (
            <Authorize scope={SCOPES.CATEGORIES.CREATE}>
              <Box className={classes.speedDialContainer}>
                <SpeedDialBackdrop
                  direction="down"
                  actions={[
                    {
                      name: 'Category',
                      onClick: () => setAddCategoryModal('category'),
                    },
                    {
                      name: 'Category Group',
                      onClick: () => setAddCategoryModal('group'),
                    },
                  ]}
                />
              </Box>
            </Authorize>
          )}
        </Grid>
      }
    >
      <Box className={classes.content}>
        <Grid className={classes.takeoverTabs}>
          <Tabs
            value={currentTab}
            onChange={(e, idx) => setCurrentTab(idx)}
            aria-label="simple tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Category Groups" {...tabA11yProps('category', 0)} />
            <Tab label="Categories" {...tabA11yProps('category', 1)} />
            <Tab label="Tags" {...tabA11yProps('category', 2)} />
          </Tabs>
        </Grid>
        <TabPanel
          value={currentTab}
          index={0}
          className={classes.tabPanel}
          sx={{
            ...currentTab === 0 && {
              height: 'calc(100% - 69px)',
            },
          }}
        >
          <Box className={classes.tabBody}>
            <Suspense fallback={<Loading />}>
              <CategoryGroupListing
                groups={groups}
                navigate={handleNavigate}
                networkId={networkId}
                search={searchParam}
              />
            </Suspense>
          </Box>
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
          className={classes.tabPanel}
          sx={{
            ...currentTab === 1 && {
              height: 'calc(100% - 69px)',
            },
          }}
        >
          <Box className={classes.tabBody}>
            <Suspense fallback={<Loading />}>
              <CategoryListing
                categories={categories}
                navigate={handleNavigate}
                networkId={networkId}
                search={searchParam}
              />
            </Suspense>
          </Box>
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={2}
          className={classes.tabPanel}
          sx={{
            ...currentTab === 2 && {
              height: 'calc(100% - 69px)',
            },
          }}
        >
          <Box className={classes.tabBody}>
            <Suspense fallback={<Loading />}>
              <TagListing
                categories={categories}
                languages={languages}
                tags={tags}
                navigate={handleNavigate}
                networkId={networkId}
                search={searchParam}
                hasSpanish={hasSpanishLang}
                isGlobalTemplate={isGlobalTemplate(networkId)}
              />
            </Suspense>
          </Box>
        </TabPanel>
      </Box>
      {addCategoryModal && (
        <AddCategoryModel
          languages={languages}
          type={addCategoryModal}
          networkName={activeNetwork?.humanName}
          networkLogo={activeNetwork?.logo}
          groups={groups}
          handleSave={createNewCategory}
          handleClose={() => setAddCategoryModal(null)}
          updating={saving}
        />
      )}
    </FlamingoPage>
  );
};

const CategoriesPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <CategoriesPageContent />
  </LoggedinLayout>
);

export default React.memo(CategoriesPage);
