import useLogout from 'hooks/useLogout';
import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { downloadAnalyticsFile } from 'helpers/analytics-util';
import { FlamingoContext } from 'contexts/flamingo';
import DownloadButton from 'components/downloadButton/downloadButton';

const AnalyticsDownloadButton = ({ endpoint, range, networkId, dataType, fileNameDetail, showIcon }) => {
  const logout = useLogout();
  const [fetching, setFetching] = useState(false);
  const { activeNetwork } = useContext(FlamingoContext);

  return (
    <Grid item>
      <DownloadButton
        downloadButtonText="Download Data"
        handleDownload={async () => {
          setFetching(true);
          await downloadAnalyticsFile({
            dataType,
            endDate: range?.end,
            endpoint,
            fileNameDetail,
            networkId,
            networkName: activeNetwork?.name,
            onLogout: logout,
            startDate: range?.start,
          });
          setFetching(false);
        }}
        downloadInProgress={fetching}
        showIcon={showIcon}
      />
    </Grid>
  );
};

export default React.memo(AnalyticsDownloadButton);
