import { Box } from '@mui/material';
import LinkTypeAppointment from 'components/networkPlace/actionLinks/linkTypeAppointment';
import LinkTypeCustom from 'components/networkPlace/actionLinks/linkTypeCustom';
import LinkTypeInApp from 'components/networkPlace/actionLinks/linkTypeInApp';
import LinkTypePhone from 'components/networkPlace/actionLinks/linkTypePhone';
import LinkTypeWebsite from 'components/networkPlace/actionLinks/linkTypeWebsite';
import PropTypes from 'prop-types';
import React from 'react';

export const ACTION_LINK_MENU_ITEMS = [
  { id: 'phone', label: 'Phone Number', component: LinkTypePhone },
  { id: 'website', label: 'Website', component: LinkTypeWebsite },
  { id: 'appointment', label: 'Appointment', component: LinkTypeAppointment },
  { id: 'in_app', label: 'In-App Link', component: LinkTypeInApp },
  {
    id: 'custom',
    label: 'Custom Gozio Action Type',
    adminOnly: true,
    component: LinkTypeCustom,
  },
];

const AddActionLinkModalBody = ({ values, icons, appDestinations }) => {
  const LinkComponent = ACTION_LINK_MENU_ITEMS.find(
    (item) => values.linkType === item.id,
  )?.component;

  return LinkComponent ? (
    <Box
      sx={{
        paddingBottom: '32px',
        width: '914px',
      }}
    >
      <LinkComponent
        linkOptions={ACTION_LINK_MENU_ITEMS}
        icons={icons}
        isUberAdmin={values.isUberAdmin}
        appDestinations={appDestinations}
        data-test="ActionLinkTypeSelect"
      />
    </Box>
  ) : null;
};

AddActionLinkModalBody.propTypes = {
  appDestinations: PropTypes.array,
  icons: PropTypes.array,
  values: PropTypes.object,
};

AddActionLinkModalBody.defaultProps = {
  appDestinations: [],
};

export default React.memo(AddActionLinkModalBody);
