import MultiSelectWithChips from 'components/multiSelectWithChips/multiSelectWithChips';
import PropTypes from 'prop-types';
import React from 'react';

const AdditionalTags = ({ input, label, sx }) => (
  <MultiSelectWithChips
    label={label}
    sx={sx}
    input={input}
    errorMessage="You have tags with the same name."
    helperMessage="Put a comma in between each tag to add multiple tags"
  />
);

AdditionalTags.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

AdditionalTags.defaultProps = {
  sx: {},
};

export default AdditionalTags;
