import { Autorenew, DeleteForever } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhoneFrame from 'assets/phone-frame-panel.svg';
import { extractPropertiesForRendering } from 'components/dashboard/cardLayouts/cardhelper';
import WarningPanel from 'components/warningPanel/warningPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  cardGroup: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  processingArea: {
    position: 'absolute',
    top: '-8px',
    left: 0,
    paddingTop: '12px',
    color: theme.palette.grey[600],
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '4px',
    width: '375px',
    height: '48px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  creatingArea: {
    background: theme.palette.blue[50],
    border: `2px dashed ${theme.palette.blue[300]}`,
  },
  deleteArea: {
    background: theme.palette.red[50],
    border: `2px dashed ${theme.palette.red[200]}`,
  },
  deletingArea: {
    background: theme.palette.grey[300],
    border: `2px dashed ${theme.palette.grey[400]}`,
  },
  imageContainer: {
    position: 'relative',
    maxHeight: '888px',
    width: '376px',
  },
  image: {
    position: 'absolute',
    top: '42px',
    left: 0,
    width: '376px',
    height: '812px',
    zIndex: 1,
  },
  previewContent: {
    position: 'absolute',
    top: '132px',
    left: '8px',
    width: '360px',
    height: '612px',
    maxHeight: '612px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    borderBottom: `1px solid ${colorWithAlpha(theme.palette.black, 0.1)}`,
    background: theme.palette.white,
    textAlign: 'center',
    color: colorWithAlpha(theme.palette.black, 0.6),
    zIndex: 2,
  },
  previewContentBody: {
    lineHeight: '1.25rem',
  },
  previewContentTitle: {
    paddingTop: '269px',
    marginBottom: '7px',
  },
  selectablePreview: {
    cursor: 'pointer',
  },
  subtitleContainer: {
    marginBottom: `-${theme.spacing(2)}`,
    marginLeft: theme.spacing(1),
    position: 'relative',
    width: '376px',
  },
  trashIcon: {
    fontSize: '18px',
    marginTop: '-3px',
  },
  warningMessage: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const DashboardPreview = ({
                            sx,
                            cardGroups,
                            configColors,
                            editPanelTabValue,
                            incompleteForPackaging,
                            isCardGroupCreating,
                            onCardClick,
                            onCardGroupDeleted,
                            onCardGroupDrop,
                            onCardGroupMoved,
                            selectedCard,
                            modifiedSelectedCardProps,
                            updateCardGroups,
                          }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [newCardGroupIndex, setNewCardGroupIndex] = useState(null);
  const [showDeleteArea, setShowDeleteArea] = useState(false);
  const [isCardGroupDeleting, setIsCardGroupDeleting] = useState(false);
  const [, deleteDrop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    drop: async (item, monitor) => {
      setNewCardGroupIndex(null);
      if (item.preview) {
        setIsCardGroupDeleting(true);
        await onCardGroupDeleted(item);
        setIsCardGroupDeleting(false);
      }
    },
    canDrop: (item) => !selectedCard && item.preview,
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });

  const [{ hovered: dropHovered }, addDrop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    drop: (item, monitor) => {
      setNewCardGroupIndex(null);
      setShowDeleteArea(false);
      if (!item.preview) {
        onCardGroupDrop(
          item,
          newCardGroupIndex === null ? cardGroups.length : newCardGroupIndex,
        );
      } else {
        updateCardGroups();
      }
    },
    canDrop: (item) => !showDeleteArea && !selectedCard,
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });

  const moveCard = (dragIndex, hoverIndex, item) => {
    setNewCardGroupIndex(null);
    setShowDeleteArea(false);
    if (typeof dragIndex !== 'undefined') {
      if (item.preview) {
        onCardGroupMoved(dragIndex, hoverIndex);
      }
    }
  };

  const onDrag = (item, isDragging) => {
    if (isDragging) {
      if (!(isCardGroupCreating || isCardGroupDeleting)) {
        setShowDeleteArea(true);
      }
    } else {
      setShowDeleteArea(false);
    }
  };

  const onHover = useCallback(
    (item, hoverIndex) => {
      const exist = cardGroups.find((c) => c.id === item.id);
      if (exist) {
        if (!(isCardGroupCreating || isCardGroupDeleting)) {
          setShowDeleteArea(true);
          setNewCardGroupIndex(null);
        }
      } else {
        setNewCardGroupIndex(hoverIndex);
      }
    },
    [cardGroups, isCardGroupCreating, isCardGroupDeleting],
  );

  const renderProcessingArea = (text, sx) => (
    <Box sx={{ ...styles.processingArea, ...sx }} data-test="processing">
      <Grid container justifyContent="center" sx={{ marginTop: '1px' }}>
        <Grid item sx={{ marginTop: '-2px' }}>
          <Autorenew sx={styles.rotateIcon} />
        </Grid>
        <Grid item sx={{ marginLeft: '8px' }}>
          {text}
        </Grid>
      </Grid>
    </Box>
  );

  const renderCardGroups = () => cardGroups.map(({ cardType: CardType, id, type, cardProps }, i) => {
      const updatedCardProps
        = !!modifiedSelectedCardProps && id === selectedCard.cardGroupId
          ? {
            ...cardProps,
            card1: { ...cardProps.card1, ...modifiedSelectedCardProps.card1 },
            ...!!cardProps.card2 && {
              card2: {
                ...cardProps.card2,
                ...modifiedSelectedCardProps.card2,
              },
            },
            ...!!cardProps.card3 && {
              card3: {
                ...cardProps.card3,
                ...modifiedSelectedCardProps.card3,
              },
            },
            ...!!cardProps.card4 && {
              card4: {
                ...cardProps.card4,
                ...modifiedSelectedCardProps.card4,
              },
            },
          }
          : cardProps;
      const props = extractPropertiesForRendering(
        updatedCardProps,
        selectedCard,
        id === selectedCard?.cardGroupId ? editPanelTabValue : 0,
        type,
      );
      const funcProps = selectedCard
        ? {}
        : {
          hovered: dropHovered,
          moveCard,
          onCardClick,
          onDrag,
          onHover,
        };
      return (
        <CardType
          key={id}
          configColors={configColors}
          id={id}
          index={i}
          preview={true}
          canDragAndDrop={!selectedCard}
          {...props}
          {...funcProps}
        />
      );
    });

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <Box sx={styles.subtitleContainer}>
        {incompleteForPackaging && (
          <WarningPanel
            sx={styles.warningMessage}
            message="The Dashboard is incomplete."
            disableClose={true}
          />
        )}
        <Typography variant="subtitle1">Mobile Preview</Typography>
      </Box>
      <Box sx={styles.imageContainer}>
        {isCardGroupCreating
          && renderProcessingArea(
            'Adding Card. System Processing...',
            styles.creatingArea,
          )}
        {isCardGroupDeleting
          && renderProcessingArea(
            'Deleting Card. System Processing...',
            styles.deletingArea,
          )}
        {showDeleteArea && (
          <Box
            ref={deleteDrop}
            sx={{ ...styles.processingArea, ...styles.deleteArea }}
            data-test="delete-area"
          >
            <Grid container justifyContent="center" sx={{ marginTop: '1px' }}>
              <Grid item sx={{ marginTop: '2px' }}>
                <DeleteForever sx={styles.trashIcon} />
              </Grid>
              <Grid item>Delete Card</Grid>
            </Grid>
          </Box>
        )}
        <img src={PhoneFrame} alt="Preview" style={styles.image} />
        <Box
          ref={addDrop}
          sx={{
            ...styles.previewContent,
            ...!selectedCard && styles.selectablePreview,
          }}
          data-test="preview-content"
        >
          {cardGroups.length > 0 && renderCardGroups()}
          {cardGroups.length === 0 && (
            <>
              <Typography variant="h2" sx={styles.previewContentTitle}>
                Nothing to see here
              </Typography>
              <Typography variant="body1" sx={styles.previewContentBody}>
                Double-click or drag and drop to add a<br />
                card type to your layout.
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

DashboardPreview.propTypes = {
  configColors: PropTypes.object,
  incompleteForPackaging: PropTypes.bool,
  cardGroups: PropTypes.array,
  editPanelTabValue: PropTypes.number,
  isCardGroupCreating: PropTypes.bool,
  onCardClick: PropTypes.func.isRequired,
  onCardGroupDrop: PropTypes.func.isRequired,
  onCardGroupDeleted: PropTypes.func.isRequired,
  onCardGroupMoved: PropTypes.func.isRequired,
  selectedCard: PropTypes.shape({
    backgroundColor: PropTypes.string,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    waitTimeInMinutes: PropTypes.number,
    waitTimeLabel: PropTypes.string,
    isExpandable: PropTypes.bool,
    dismissable: PropTypes.bool,
    expires: PropTypes.any,
    overline: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    subtitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    ]),
    textAlignment: PropTypes.oneOf(['left', 'center']),
    textColor: PropTypes.string,
    title: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
  }),
  modifiedSelectedCardProps: PropTypes.object,
  sx: PropTypes.object,
  updateCardGroups: PropTypes.func.isRequired,
};

DashboardPreview.defaultProps = {
  cardGroups: [],
  configColors: {},
  editPanelTabValue: 0,
  incompleteForPackaging: false,
  isCardGroupCreating: false,
  modifiedSelectedCardProps: null,
  selectedCard: null,
  sx: {},
};

export default DashboardPreview;
