import { useLazyQuery, useSuspenseQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import { useEffect } from 'react';
import {
  GET_NETWORK,
  GET_NETWORK_VALIDATION,
} from 'graphql/queries';

export const useNetworkById = ({ networkId, shouldExecute = false }) => {
  const [getNetwork, { data, error, loading }] = useLazyQuery(GET_NETWORK, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (shouldExecute) {
      getNetwork({ variables: { id: networkId } });
    }
  }, [shouldExecute, networkId, getNetwork]);

  return {
    error,
    loading,
    network: data?.getNetwork ?? null,
  };
};

export const useNetworkValidation = (networkId) => {
  const { handleError } = useHandleError('NetworkConfiguration');

  const { data, error, refetch } = useSuspenseQuery(GET_NETWORK_VALIDATION, {
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    error,
    data: data?.getNetwork?.validation || null,
    refetch,
  };
};
