import withStyles from '@mui/styles/withStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import clsx from 'clsx';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import React from 'react';

export const LargeToggleButton = withStyles(StepStyles)(
  ({ classes, className = '', ...other }) => (
    <ToggleButton
      classes={{
        root: classes.button,
        label: classes.label,
        selected: classes.selected,
      }}
      className={className}
      {...other}
    />
  ),
);

export const LargeToggleButtonGroup = withStyles(StepStyles)(
  ({ classes, className = '', ...other }) => (
    <ToggleButtonGroup
      className={clsx(classes.toggleGroup, className)}
      {...other}
    />
  ),
);

export const SmallToggleButton = withStyles(StepStyles)(
  ({ classes, className = '', ...other }) => (
    <ToggleButton
      classes={{
        root: classes.smallButton,
        label: classes.label,
        selected: classes.selected,
      }}
      className={className}
      {...other}
    />
  ),
);

export const SmallToggleButtonGroup = withStyles(StepStyles)(
  ({ classes, className = '', ...other }) => (
    <ToggleButtonGroup
      className={clsx(classes.toggleGroup, className)}
      {...other}
    />
  ),
);
