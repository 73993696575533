import { Box, Link, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    letterSpacing: '0.45px',
    lineHeight: '20px',
    color: theme.palette.grey[350],
    fontFamily: 'Poppins',
    fontWeight: 500,
    margin: '0 16px',
  },
  parentText: {
    color: theme.palette.grey[350],

    '&:hover': {
      color: theme.palette.blue[400],
      textDecoration: 'underline',
    },
  },
  titleText: {
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
});

const MAX_TITLE_LENGTH = 34;

const convertTitle = (title, visibleTitleLength) => {
  if (title.length > visibleTitleLength) {
    return {
      name: `${title.substr(0, visibleTitleLength)}...`,
      tooltip: title,
    };
  }

  return {
    name: title,
    tooltip: null,
  };
};

const BreadCrumb = ({
                      parents: incomingParents,
                      title: incomingTitle,
                      visibleTitleLength,
                    }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [parents, setParents] = useState([]);
  const [title, setTitle] = useState({ name: '', tooltip: null });

  useEffect(() => {
    setTitle(convertTitle(incomingTitle, visibleTitleLength));
  }, [incomingTitle, visibleTitleLength]);

  useEffect(() => {
    if (incomingParents) {
      setParents(
        incomingParents.map(({ name, ...rest }) => ({
          title: convertTitle(name, visibleTitleLength),
          ...rest,
        })),
      );
    } else {
      setParents([]);
    }
  }, [incomingParents, visibleTitleLength]);

  return (
    <Box sx={styles.root}>
      {parents.map((p, idx) => (
        <Box key={`bc-parent-${idx}`} sx={{ display: 'flex' }}>
          <Link href={p.link}>
            <Typography variant="subtitle2" sx={styles.parentText}>
              {p.title.tooltip && (
                <Tooltip title={p.title.tooltip}>
                  <span>{p.title.name}</span>
                </Tooltip>
              )}
              {!p.title.tooltip && p.title.name}
            </Typography>
          </Link>{' '}
          <Box component="span" sx={styles.separator}>
            &gt;
          </Box>
        </Box>
      ))}
      <Typography variant="subtitle2" sx={styles.titleText}>
        {title.tooltip && (
          <Tooltip title={title.tooltip}>
            <span>{title.name}</span>
          </Tooltip>
        )}
        {!title.tooltip && title.name}
      </Typography>
    </Box>
  );
};

BreadCrumb.propTypes = {
  parents: PropTypes.array,
  title: PropTypes.string,
  visibleTitleLength: PropTypes.number,
};

BreadCrumb.defaultProps = {
  parents: [],
  title: '',
  visibleTitleLength: MAX_TITLE_LENGTH,
};

export default BreadCrumb;
