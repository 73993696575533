import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import ReduxLanguageBasedInput from 'components/_deprecated/reduxLanguageBasedInput';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import validate from 'pages/typeaheads/containers/validate';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

class StepperLabel extends React.Component {
  render() {
    const { classes, handleCancel, languages, valid } = this.props;
    const customTags = {
      nameEn: {
        'data-test': 'typeaheadStep1EnInput',
      },
      nameEs: {
        'data-test': 'typeaheadStep1EsInput',
      },
      cancel: {
        'data-test': 'typeaheadStep1CancelButton',
      },
      back: {
        'data-test': 'typeaheadStep1BackButton',
      },
      next: {
        'data-test': 'typeaheadStep1NextButton',
      },
    };

    const langArr = [
      {
        lang: LANGUAGE_CODES.ENGLISH,
        name: 'name.en',
        label: 'Typeahead label',
        customTags: { ...customTags.nameEn, required: true, alphaNum: true },
      },
      {
        lang: LANGUAGE_CODES.SPANISH,
        name: 'name.es',
        label: 'Typeahead label (Spanish)',
        customTags: { ...customTags.nameEn, required: false },
      },
    ];

    return (
      <Box>
        <Typography variant="subtitle1" className={classes.title}>
          First, add a label
        </Typography>
        <Typography variant="body2" className={classes.contentText}>
          The label is what a user sees in the typeahead results. It's also how
          you will find this entry within the content manager
        </Typography>
        <ReduxLanguageBasedInput
          options={langArr}
          filter={languages}
          className={classes.textField}
          InputProps={{ classes: { root: classes.inputBaseRoot } }}
        />
        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            {...customTags.cancel}
          >
            CANCEL
          </Button>
          <Box sx={{ float: 'right', display: 'inline-block' }}>
            <Button variant="outlined" disabled>
              BACK
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!valid}
              sx={{ marginRight: 0 }}
              {...customTags.next}
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

StepperLabel.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  connect(
    ({ form, networks }) => ({
      typeaheadForm: form.typeaheadForm,
      networks,
    }),
    (dispatch) => ({
      dispatch,
    }),
  ),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: false,
    validate,
    enableReinitialize: false,
  }),
  withStyles(StepStyles),
)(StepperLabel);
