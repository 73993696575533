import { gql } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import TabPanel from 'components/tabPanel/tabPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import Environment from 'pages/configuration/containers/environment';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 186px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    height: 'calc(100% - 69px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
}));

export const GET_ENVS = gql`
  query {
    system {
      environments {
        env
        dataUrl
        versionUrl
        searchUrl
        networkUrl
      }
    }
  }
`;

const SystemEnvironmentsPage = (props) => {
  const classes = useStyles();
  const { networkId } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  if (!isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage pageName="Configuration">
        <Box className={classes.content}>
          <Grid className={classes.takeoverTabs}>
            <Tabs
              value={currentTab}
              onChange={(e, idx) => setCurrentTab(idx)}
              aria-label="simple tabs"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="System Environment"
                {...tabA11yProps('environments', 0)}
              />
            </Tabs>
          </Grid>
          <TabPanel value={currentTab} index={0} className={classes.tabPanel}>
            <Box className={classes.tabBody}>
              <Environment />
            </Box>
          </TabPanel>
        </Box>
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(SystemEnvironmentsPage);
