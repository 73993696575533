import { useEffect, useState } from 'react';
import {
  skipToken,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';
import useHandleError from 'hooks/useHandleError';

const defaultOptions = {
  errorPolicy: 'all',
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true,
};

export const useWrapper = (gql, options={}) => {
  const {
    dataFn=(d) => d,
    skip=false,
    skipFn=() => false,
    suspense=false,
    ...rest
  } = options;

  const [queryResponseData, setQueryResponseData] = useState(undefined);
  const { handleError } = useHandleError('GraphQL Error');

  const skipQuery = skip || skipFn(dataFn(queryResponseData));
  const skipQueryOptions = suspense ? skipToken : { skip: true };
  const useQueryFn = suspense ? useSuspenseQuery : useQuery;

  const queryResponse = useQueryFn(gql,
    skipQuery ? skipQueryOptions : {
      ...defaultOptions,
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
      ...rest,
    },
  );
  const { data, ...queryResponseProps } = queryResponse;

  useEffect(() => {
    if (data !== undefined) setQueryResponseData(data);
  }, [data]);

  return {
    data: !skipQuery ? dataFn(data) : dataFn(queryResponseData),
    refetchData: async (variables) => {
      const response = await queryResponse.refetch(variables);
      return dataFn(response.data);
    },
    ...queryResponseProps,
  };
};


export const useQueryWrapper = useWrapper;

export const useSuspenseQueryWrapper = (gql, options={}) => useWrapper(gql, { ...options, suspense: true });
