import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import AutoComplete from 'components/autocomplete/autoComplete';
import renderLargeToggle from 'components/renderLargeToggle';
import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import _ from 'lodash';
import { getCategories } from 'modules/categories';
import { getLists } from 'modules/lists';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

const flattenCategories = (cats) => {
  const flattenedCats = [];
  cats.forEach((cat) => {
    flattenedCats.push({
      id: cat.id,
      name: { ...cat.name },
      tags: [...cat.tags],
    });
    if (cat.childrenCount > 0) {
      cat.children.forEach((child) => {
        flattenedCats.push({
          id: child.id,
          name: { ...child.name },
          tags: [...child.tags],
        });
      });
    }
  });
  return flattenedCats;
};
class StepperLand extends React.Component {
  constructor(props) {
    super(props);
    const values = (props.typeaheadForm && props.typeaheadForm.values) || {};
    const categoryValues = _.get(values, 'category', null);
    const listValues = _.get(values, 'curatedList', null);
    this.state = {
      category: categoryValues?.id,
      curatedList: listValues?.id,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { networkId },
      },
      workspace,
    } = this.props;
    this.props.dispatch(getCategories({ networkId, workspace }));
    if (!isGlobalTemplate(networkId)) {
      this.props.dispatch(getLists({ networkId, workspace }));
    }
  }

  selectedValueHandler = (value) => {
    const { categories } = this.props;
    const flattenedCategories = flattenCategories(categories);
    this.setState({ category: value });
    this.props.change(
      'category',
      flattenedCategories.find((c) => c.id === value),
    );
    this.props.change('excludeCategoryTags', []);
  };

  selectedListValueHandler = (value) => {
    const { lists } = this.props;
    this.setState({ curatedList: value });
    this.props.change(
      'curatedList',
      lists.find((l) => l.id === value),
    );
    this.props.change('excludeCategoryTags', []);
  };

  categoryDropDown = (currCategory, categories) => {
    const opts = categories.map((cat) => {
      const retCat = {
        id: cat.id,
        label: getLabelByLang(cat.name),
      };
      if (cat.childrenCount > 0) {
        const children = cat.children.map((child) => ({
          id: child.id,
          label: getLabelByLang(child.name),
        }));
        retCat.children = children;
      }
      return retCat;
    });
    return (
      <AutoComplete
        options={opts}
        label="Category"
        input={{ value: currCategory, onChange: this.selectedValueHandler }}
        disableClearable={true}
      />
    );
  };

  listDropDown = (currList, lists) => {
    const opts = lists.map((list) => ({
      id: list.id,
      label: getLabelByLang(list.name),
    }));
    return (
      <AutoComplete
        options={opts}
        label="Lists"
        input={{ value: currList, onChange: this.selectedListValueHandler }}
        disableClearable={true}
      />
    );
  };

  render() {
    const { classes, valid, categories, lists, actionType, network }
      = this.props;
    const { category, curatedList } = this.state;
    const options = isGlobalTemplate(network)
      ? [{ label: 'Category', value: 'category' }]
      : [
          { label: 'Category', value: 'category' },
          { label: 'List', value: 'list' },
        ];
    const customTags = {
      categoryList: {
        'data-test': 'typeaheadStep3CategoryListButton',
      },
      categorySelect: {
        'data-test': 'typeaheadStep3CategorySelect',
      },
      next: {
        'data-test': 'typeaheadStep3NextButton',
      },
      back: {
        'data-test': 'typeaheadStep3BackButton',
      },
      cancel: {
        'data-test': 'typeaheadStep3CancelButton',
      },
    };
    return (
      <Box>
        <Typography className={classes.title}>
          Where does the user land?
        </Typography>
        <Typography className={classes.contentText}>
          When selecting a typeahead rule, a user will be directed to a results
          page. Make a selection below to determine what the user will see in
          these results.
        </Typography>

        <Field
          name="actionType"
          component={renderLargeToggle}
          allowUnselect={false}
          customTags={customTags}
          options={options}
        />

        <Box>
          {isGlobalTemplate(network) || actionType === 'category'
            ? this.categoryDropDown(category, categories)
            : this.listDropDown(curatedList, lists)}
        </Box>

        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={this.props.handleCancel}
            {...customTags.cancel}
          >
            CANCEL
          </Button>
          <Box sx={{ float: 'right', display: 'inline-block' }}>
            <Button
              variant="outlined"
              onClick={this.props.handleBack}
              {...customTags.back}
            >
              BACK
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!valid}
              {...customTags.next}
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

const selector = formValueSelector('typeaheadForm');

const mapStateToProps = (state, ownProps) => {
  const { flamingo } = state;
  return {
    typeaheadForm: state.form.typeaheadForm,
    categories: state.categories?.page?.docs || [],
    actionType: selector(state, 'actionType'),
    lists: state.lists?.page?.docs || [],
    workspace: flamingo?.workspace || LIVE_WORKSPACE,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: false,
    enableReinitialize: false,
  }),
  withStyles(StepStyles),
)(StepperLand);
