import { Box, Button, Grid, Typography } from '@mui/material';
import Loading from 'components/loading/loading';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

export const ANALYTICS_SCHEMA = {
  analyticsFeature: {
    label: 'Feature',
    schema: Yup.string().required('Feature is required'),
  },
  analyticsChannel: {
    label: 'Channel',
    schema: Yup.string().required('Channel is required'),
  },
};

export const ANALYTICS_ERROR_MAP = Object.keys(ANALYTICS_SCHEMA).map((key) => [
  key,
  {
    label: ANALYTICS_SCHEMA[key].label,
    section: 'Analytics',
  },
]);

export const buildAnalyticsInitialValues = (data) => ({
  analyticsFeature: data?.analytics?.feature,
  analyticsChannel: data?.analytics?.channel,
});

const Analytics = ({ disabled, loading, onSaved, sx }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Analytics</Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="analyticsFeature"
            label={getFormLabel('Feature', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="analyticsChannel"
            label={getFormLabel('Channel', true)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box sx={sx.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

Analytics.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
};

Analytics.defaultProps = {
  disabled: false,
  loading: false,
};

export default Analytics;
