import { Box, Typography } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import GozioTableWithVirtualization from 'components/tables/gozioTableWithVirtualization';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '673px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& .MuiDialogContent-root': {
        width: '100%',
        padding: 0,
      },
    },

    '& .MuiDialogContent-root': {
      marginBottom: 0,
    },
  },
});

const TAGS_COLUMNS = [
  {
    Header: 'Tag Name',
    accessor: 'name',
  },
];

const CategoryTagTableEditModal = ({
                                     confirmText,
                                     disableCancel,
                                     handleClose,
                                     handleSave,
                                     selectedCategory,
                                     setShowModal,
                                     showModal,
                                   }) => {
  const styles = buildStyles();
  const { tags } = selectedCategory;
  const [excludedTags, setExcludedTags] = useState(
    tags?.filter((t) => !t.checked).map((t) => t.id) || [],
  );
  const handleRowSelectUpdates = (updates) => {
    let newTags = [].concat(excludedTags);
    for (const tag of updates) {
      const { checked, id } = tag;
      if (typeof id !== 'string') continue;
      if (checked) {
        newTags = newTags.filter((t) => t !== id);
      } else if (!excludedTags.includes(id)) {
        newTags = newTags.concat(id);
      }
    }
    setExcludedTags(newTags);
  };
  const data = useMemo(
    () => tags?.map((t) => ({
        id: t.id,
        name: capitalize(getLabelByLang(t.name)),
        isSelected: !excludedTags.includes(t.id),
      })),
    [excludedTags, tags],
  );
  return (
    showModal && (
      <>
        <GenericModal
          title={'Manage Tags'}
          sx={styles.root}
          scrollable
          body={
            <>
              <Typography
                variant="subtitle1"
                sx={{ paddingTop: '8px', paddingLeft: '34px' }}
              >
                "{getLabelByLang(selectedCategory.name)}" Category
              </Typography>
              <Box sx={{ overflow: 'scroll', flex: 1, marginTop: '8px' }}>
                <GozioTableWithVirtualization
                  sx={{
                    padding: '0 24px',
                  }}
                  columns={TAGS_COLUMNS}
                  data={data}
                  sortBy={[{ id: 'name', desc: false }]}
                  selectable={true}
                  handleRowSelectUpdates={handleRowSelectUpdates}
                />
              </Box>
            </>
          }
          open={showModal}
          handleClose={handleClose}
          handleConfirm={() => {
            handleSave(
              excludedTags,
              tags?.filter((t) => !excludedTags.includes(t.id)).map((t) => t.id),
            );
            setShowModal(false);
          }}
          confirmText={confirmText}
          disableCancel={disableCancel}
        />
      </>
    )
  );
};

CategoryTagTableEditModal.propTypes = {
  confirmText: PropTypes.string,
  disableCancel: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  selectedCategory: PropTypes.object.isRequired,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
};

CategoryTagTableEditModal.defaultProps = {};

export default CategoryTagTableEditModal;
