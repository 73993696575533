import { memo } from 'react';
import { DndProvider as ReactDndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const ItemTypes = {
  MEDIA_IMAGE: 'MEDIA_IMAGE',
  DASHBOARD_CARD: 'DASHBOARD_CARD',
};

export const DndProvider = memo(({ children }) => (
  <ReactDndProvider backend={HTML5Backend}>{children}</ReactDndProvider>
));

export const buildUseDragParams = ({
                                     id,
                                     canDragAndDrop,
                                     cardProps,
                                     cardType,
                                     index,
                                     onDrag,
                                     preview,
                                   }) => ({
  type: ItemTypes.DASHBOARD_CARD,
  item: () => {
    const item = {
      id,
      cardProps,
      cardType,
      index,
      preview,
      type: ItemTypes.DASHBOARD_CARD,
    };
    onDrag(item, true);
    return item;
  },
  end: ({ id }) => {
    onDrag({ id }, false);
  },
  canDrag: () => canDragAndDrop,
  collect: (monitor) => ({
    isDragging: !!monitor.isDragging(),
  }),
});
