import { FormControl, FormControlLabel, FormLabel, Switch as MuiSwitch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	toggleLabel: {
		color: theme.palette.grey[500],
	},
	switch: {
		marginLeft: '2px',

		'& .MuiIconButton-root:hover': {
			backgroundColor: 'transparent !important',
		},
	},
}));

const FormSwitch = ({
	input: { value, onChange },
	title,
	labelOn,
	labelOff,
	...rest
}) => {
	const classes = useStyles();
	return (
		<FormControl component="fieldset">
			{title && (
				<FormLabel component="legend" sx={{ marginBottom: '5px' }}>
					{title}
				</FormLabel>
			)}
			<FormControlLabel
				control={
					<MuiSwitch
						className={classes.switch}
						color="primary"
						checked={value || false}
						onChange={(e) => onChange(e.target.checked)}
						{...rest}
					/>
				}
				label={value === true ? labelOn : labelOff}
				labelPlacement="end"
				classes={{ label: classes.toggleLabel }}
			/>
		</FormControl>
	);
};

FormSwitch.propTypes = {
	title: PropTypes.string,
	labelOn: PropTypes.string,
	labelOff: PropTypes.string,
};

export default FormSwitch;
