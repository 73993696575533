import { Box, Button, CircularProgress, Grid, Modal, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loading from 'components/loading/loading';
import NetworkLogo from 'components/networkLogo';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

const buildStyles = ({ theme }) => ({
  root: {
    zIndex: theme.zIndex.modal,
    background: theme.palette.grey[100],
    height: '100vh',
    width: '100%',
    display: 'grid',
    gridTemplateRows: '70px 1fr',
  },
  header: {
    background: theme.palette.blue[400],
  },
  content: { placeSelf: 'center' },
  modalPaper: {
    overflow: 'hidden',
    width: '686px',
  },
  modalActions: {
    background: theme.palette.grey[100],
    height: '70px',
    padding: theme.spacing(2),
    position: 'relative',
  },
});

const TakeOverModal = ({
                         actionSx,
                         cancelText,
                         sx,
                         checkPristine,
                         confirmText,
                         confirmType,
                         confirmProgress,
                         content,
                         contentSx,
                         customButtonText,
                         customButtonVariant,
                         disableActions,
                         disableCancel,
                         disableConfirm,
                         disableCustomButton,
                         greyOutCancel,
                         greyOutConfirm,
                         greyOutCustomButton,
                         handleClose,
                         handleConfirm,
                         handleCustomButton,
                         header,
                         formParams,
                         open,
                         modalPaperSx,
                         networkName,
                         logo,
                         showLogo,
                         bodyProgress,
                         ...rest
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={{ ...styles.root, ...sx }} {...rest}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={styles.header}
        >
          {showLogo && <NetworkLogo networkName={networkName} logoUrl={logo} />}
          <Typography variant="subtitle2" sx={{ color: ColorPalette.white }}>
            {header}
          </Typography>
        </Grid>
        <Box sx={{ ...styles.content, contentSx }}>
          <Paper sx={{ ...styles.modalPaper, ...modalPaperSx }}>
            <Form {...formParams}>
              {(formVals) => (
                <form onSubmit={formVals.handleSubmit}>
                  <Box>
                    {bodyProgress ? (
                      <Box sx={{ padding: '40px' }}>
                        <Loading
                          content=""
                          backgroundColor={ColorPalette.white}
                          thickness={1.5}
                        />
                      </Box>
                    )
                      : content(formVals)
                    }
                  </Box>
                  {!disableActions && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      sx={{ ...styles.modalActions, ...actionSx }}
                    >
                      {!disableCancel && (
                        <Button
                          onClick={handleClose}
                          color="primary"
                          sx={{ minWidth: '120px' }}
                          variant="outlined"
                          disabled={greyOutCancel}
                          data-test="TakeOverModal-button-cancel"
                        >
                          {cancelText || 'Cancel'}
                        </Button>
                      )}
                      {!disableCustomButton && (
                        <Button
                          onClick={() => handleCustomButton(formVals.values)}
                          color="primary"
                          sx={{ marginLeft: '16px', minWidth: '120px' }}
                          variant={customButtonVariant}
                          disabled={
                            typeof greyOutCustomButton === 'function'
                              ? greyOutCustomButton(formVals?.values)
                              : greyOutCustomButton
                          }
                          data-test="TakeOverModal-button-custom"
                        >
                          {customButtonText || 'Custom'}
                        </Button>
                      )}
                      {!disableConfirm && (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            minWidth: '120px',
                            position: 'absolute',
                            right: '16px',
                          }}
                          type={confirmType || 'button'}
                          onClick={(e) => handleConfirm
                              ? handleConfirm(formVals.values)
                              : formVals.handleSubmit(e)
                          }
                          data-test="TakeOverModal-button-confirm"
                          disabled={
                            greyOutConfirm
                            || confirmProgress
                            || formVals.submitting
                            || (checkPristine && formVals.pristine)
                            || formVals.invalid
                          }
                          startIcon={
                            confirmProgress || formVals.submitting ? (
                              <CircularProgress size={16} thickness={4} />
                            ) : null
                          }
                        >
                          {confirmText || 'Confirm'}
                        </Button>
                      )}
                    </Grid>
                  )}
                </form>
              )}
            </Form>
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
};

TakeOverModal.propTypes = {
  header: PropTypes.node,
  bodyProgress: PropTypes.bool,
  content: PropTypes.func,
  actionSx: PropTypes.object,
  cancelText: PropTypes.string,
  contentSx: PropTypes.object,
  confirmText: PropTypes.any,
  confirmType: PropTypes.string,
  confirmProgress: PropTypes.bool,
  customButtonText: PropTypes.any,
  customButtonVariant: PropTypes.string,
  disableActions: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  disableCustomButton: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleCustomButton: PropTypes.func,
  greyOutCancel: PropTypes.bool,
  greyOutConfirm: PropTypes.bool,
  greyOutCustomButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  checkPristine: PropTypes.bool,
  logo: PropTypes.string,
  modalPaperSx: PropTypes.object,
  open: PropTypes.bool,
  networkName: PropTypes.string,
  formParams: PropTypes.object,
  showLogo: PropTypes.bool,
  sx: PropTypes.object,
};

TakeOverModal.defaultProps = {
  open: true,
  actionSx: {},
  content: () => {},
  contentSx: {},
  customButtonVariant: 'outlined',
  disableCustomButton: true,
  formParams: {},
  checkPristine: true,
  greyOutCancel: false,
  greyOutConfirm: false,
  greyOutCustomButton: false,
  logo: null,
  showLogo: true,
  confirmProgress: false,
  bodyProgress: false,
  modalPaperSx: {},
  networkName: '',
  sx: {},
};

export default TakeOverModal;
