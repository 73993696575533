import { Grid, Typography } from '@mui/material';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioSlider from 'components/slider/slider';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const getTagWeight = (weight) => {
  if (weight === 'low') return 1;
  if (weight === 'high') return 3;
  return 2;
};

const buildStyles = () => ({
  modal: {
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '20px',
    },
  },
});

const AddTagModal = ({
                       categoryId,
                       languages,
                       tag,
                       handleSave,
                       handleClose,
                     }) => {
  const styles = buildStyles();

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          name: Yup.string().required('Name is required'),
          nameES: Yup.string().required('Name (Spanish) is required'),
        },
        languages,
        { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  return (
    <GenericFormModal
      title={tag.name ? 'Edit Tag' : 'Add Tag'}
      open={true}
      sx={styles.modal}
      formParams={{
        onSubmit: handleSave,
        validate,
        initialValues: {
          id: tag.id,
          name: parseByLang(tag.name),
          nameES: parseByLang(tag.name, LANGUAGE_CODES.SPANISH),
          weight: getTagWeight(tag.weight),
          categoryId: categoryId || tag.parent?.id,
          langES: true,
        },
      }}
      body={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label={getFormLabel('Tag Name', true)}
              name="name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item sx={{ marginTop: '18px' }}>
            <TextField
              label={getFormLabel('Tag Name', true, true)}
              name="nameES"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item sx={{ paddingBottom: 0 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, marginTop: '10px', marginBottom: '6px' }}
            >
              {getFormLabel(
                'How Important Is It to See This Tag on the Map?',
                true,
              )}
            </Typography>
            <Field name="weight">
              {({ input: { onChange, value } }) => (
                <GozioSlider
                  input={{
                    value,
                    onChange,
                  }}
                  marks={[
                    { value: 1, label: 'Trivial' },
                    { value: 2, label: 'Neutral' },
                    { value: 3, label: 'High' },
                  ]}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      }
      confirmText={tag.name ? 'Save' : 'Add Tag'}
      handleClose={handleClose}
    />
  );
};

export default AddTagModal;
