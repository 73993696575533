import { formatLang, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { allowedCharacterRegex } from 'helpers/location-util';
import { softDeleteImage } from 'helpers/media-util';
import { uploadImage, uploadImageToService } from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  deleteMedia,
  updateMedia,
  uploadMediaImagesAndVideos,
} from 'pages/locations/containers/detail/siteFormHelper';
import {
  DEFAULT_CUSTOM_HOURS,
  filterExceptionHours,
  HOURS_VALIDATION_OBJECT,
} from 'pages/locations/containers/locationsFormHelper';
import * as Yup from 'yup';

export const buildValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          // MAPPING INPUTS
          mapID: Yup.number()
            .typeError('Must be a numeric value')
            .min(1, 'Value must be greater than 1'),

          // BUILDING DETAILS
          name: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .required('Site name is required'),
          shortName: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
          shortNameES: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),

          // BUILDING ADDRESS
          street: Yup.string().nullable(),
          streetNumber: Yup.string().nullable(),
          floor: Yup.string().nullable(),
          suite: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string()
            .nullable()
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code'),
          displayLatitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          displayLongitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          destinationLatitude: Yup.number()
            .nullable()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          destinationLongitude: Yup.number()
            .nullable()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          departmentIds: Yup.array().of(Yup.string()),

          // BUILDING PROVIDER MATCHING
          providerMatchingRule: Yup.string().when('providerMatchingEnabled', {
            is: true,
            then: (schema) => schema
                .typeError('Not a valid JSON string')
                .required('JSON input is required'),
            otherwise: (schema) => schema.nullable(),
          }),

          // BUILDING MEDIA
          youTubeUrl: Yup.string().matches(
            /^$|(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/,
            'We only support YouTube videos at this time.',
          ),

          // BUILDING MAP VISIBILITY
          visibility: Yup.string().oneOf(['always', 'onsite', 'never']),
          priority: Yup.number(),

          // BUILDING SEARCHABILITY
          searchability: Yup.string().oneOf(['always', 'onsite', 'never']),

          // HOURS
          ...HOURS_VALIDATION_OBJECT,
        },
        languages,
        {
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

const parseAddress = (data) => {
  const inheritAddress
    = data?.inheritAddress === false ? 'override' : 'inherit';
  const autoPopulateCoords
    = data?.metadata?.ui?.autoPopulateCoordinatesFromAddress;

  const defaultAddress = data?.geoLocation?.address;

  const retVal = { inheritAddress, autoPopulateCoords };
  retVal.street = defaultAddress?.street;
  retVal.floor = defaultAddress?.floor;
  retVal.suite = defaultAddress?.suite;
  retVal.city = defaultAddress?.city;
  retVal.state = defaultAddress?.state;
  retVal.zip = defaultAddress?.zip;

  if (data?.parentSite?.isMapped) {
    retVal.parentLat = data?.parentSite?.geoLocation?.markerPoint?.lat;
    retVal.parentLong = data?.parentSite?.geoLocation?.markerPoint?.lng;
  } else {
    retVal.parentLat = data?.parentSite?.geoLocation?.navigationPoint?.lat;
    retVal.parentLong = data?.parentSite?.geoLocation?.navigationPoint?.lng;
  }
  retVal.parentAddress = data?.parentSite?.geoLocation?.address;

  retVal.displayLatitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLat
      : data?.geoLocation?.markerPoint?.lat;
  retVal.displayLongitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLong
      : data?.geoLocation?.markerPoint?.lng;
  retVal.destinationLatitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLat
      : data?.geoLocation?.navigationPoint?.lat;
  retVal.destinationLongitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLong
      : data?.geoLocation?.navigationPoint?.lng;

  return retVal;
};

export const initialParser = (BuildingData, languages) => {
  const tempVal = {
    designation: BuildingData?.designation,
    saveBack: null,
    isMapped: BuildingData?.isMapped || false,
    langES: hasSpanish(languages) || false,

    // MAPPING INPUTS
    ...!!BuildingData?.indoorLocation?.mapId && {
      mapID: BuildingData?.indoorLocation?.mapId,
    },
    xCoord: BuildingData?.indoorLocation?.markerPoint?.x || 0,
    yCoord: BuildingData?.indoorLocation?.markerPoint?.y || 0,

    // BUILDING DETAILS
    externalId: BuildingData?.externalId,
    ...!!parseByLang(BuildingData?.name) && {
      name: parseByLang(BuildingData?.name),
    },
    ...!!parseByLang(BuildingData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(BuildingData?.name, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(BuildingData?.shortName) && {
      shortName: parseByLang(BuildingData?.shortName),
    },
    ...!!parseByLang(BuildingData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(BuildingData?.shortName, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(BuildingData?.description) && {
      description: parseByLang(BuildingData?.description),
    },
    ...!!parseByLang(BuildingData?.description, LANGUAGE_CODES.SPANISH) && {
      descriptionES: parseByLang(
        BuildingData?.description,
        LANGUAGE_CODES.SPANISH,
      ),
    },

    // Parent Info
    parentSite: BuildingData?.parentSite
      ? `${parseByLang(BuildingData.parentSite.name)} (${
        BuildingData.parentSite.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,

    //  BUILDING ADDRESS
    ...parseAddress(BuildingData),

    ...!!BuildingData?.navigation?.defaultPlace && {
      defaultDestination: BuildingData.navigation.defaultPlace.id,
    },
    ...!!BuildingData?.departmentIds && {
      departmentIds: BuildingData.departmentIds ?? [],
    },

    providerMatchingEnabled: BuildingData?.matching?.enabled || false,
    providerMatchingRule:
      JSON.stringify(BuildingData?.matching?.rule, null, 2) || '',

    // HOURS
    hoursType: BuildingData?.contact?.hours?.type ?? 'none',
    customHours: BuildingData?.contact?.hours?.custom ?? [
      ...DEFAULT_CUSTOM_HOURS,
    ],
    exceptionHours: filterExceptionHours(BuildingData?.contact?.exceptionHours),

    // BUILDING MEDIA
    ...!!BuildingData?.media && {
      media: BuildingData.media ?? [],
    },
    ...!!BuildingData?.defaultImage && {
      defaultImage: BuildingData.defaultImage,
    },

    // MAP VISIBILITY
    visibility: BuildingData?.visibility ?? 'always',
    priority: BuildingData?.priority ?? 50,

    // SEARCHABILITY
    searchability: BuildingData?.searchability ?? 'always',

    // Categories
    ...!!BuildingData?.categories && {
      categories:
        BuildingData.categories?.map((cat) => ({
          id: cat.id,
          name: parseByLang(cat.name),
        })) || [],
    },
    excludeCategoryTags: BuildingData?.excludeCategoryTags || [],

    // Tags
    ...parseTags(BuildingData?.tags?.flatMap((item) => item.name)),

    // no ids, so we need to track idx as id
    actionLinks:
      BuildingData?.actionLinks?.map((val, idx) => ({ idx, ...val })) || [],
  };
  return tempVal;
};

const parseTags = (tagArray) => {
  const tagsEn = [];
  const tagsEs = [];
  if (tagArray) {
    tagArray.forEach((tag) => {
      if (tag.lang === LANGUAGE_CODES.ENGLISH) {
        tagsEn.push(tag.label);
      } else if (tag.lang === LANGUAGE_CODES.SPANISH) {
        tagsEs.push(tag.label);
      }
    });
  }
  return { tags: { en: tagsEn, es: tagsEs } };
};

const buildActionLink = async (link) => {
  let imageId;
  if (link.type === 'custom') {
    imageId = link.icon?.image?.id;
    if (link.icon?.file) {
      const uploadedImage = await uploadImage(link.icon.file);
      imageId = uploadedImage?.id;
    }
  }
  return {
    ...link,
    __typename: undefined,
    icon: imageId,
    idx: undefined,
  };
};

const prepareActionLinks = async (actionLinks) => {
  if (!actionLinks) return [];

  return Promise.all(actionLinks.map((link) => buildActionLink(link)));
};

export const submitParser = async (dispatch, values = {}) => {
  const geolocationValues = { address: {} };
  ['suite', 'floor', 'street', 'streetNumber', 'city', 'state', 'zip'].forEach(
    (fieldName) => {
      geolocationValues.address[fieldName] = values.hasOwnProperty(fieldName)
        ? values[fieldName]
        : null;
    },
  );

  const restValues = {};
  if (values.media) {
    const mediaToBeUploaded = [];
    const mediaToBeUpdated = [];
    const mediaToBeDeleted = [];
    values.media.forEach((m) => {
      if (m.id) {
        if (m.toBeUpdated) {
          mediaToBeUpdated.push(m);
        } else if (m.toBeDeleted) {
          mediaToBeDeleted.push(m);
        }
      } else if (m.file) {
        mediaToBeUploaded.push(m); // image
      } else if (m.type === 'video') {
        mediaToBeUploaded.push(m); // video
      }
    });
    const uploadedImagesAndVideos = mediaToBeUploaded.length
      ? await uploadMediaImagesAndVideos(dispatch, mediaToBeUploaded)
      : [];

    if (mediaToBeUpdated.length) {
      await updateMedia(dispatch, mediaToBeUpdated);
    }

    if (mediaToBeDeleted.length) {
      await deleteMedia(mediaToBeDeleted);
    }

    const updatedList = [];
    let index = 0;
    values.media.forEach((m) => {
      if (m.id) {
        if (!m.toBeDeleted) {
          updatedList.push(m.id);
        }
      } else {
        updatedList.push(uploadedImagesAndVideos[index++].id);
      }
    });
    restValues.media = updatedList;
  }

  let defaultImageId = null;
  if (values.defaultImage) {
    if (values.defaultImage.image?.id) {
      defaultImageId = values.defaultImage.image.id;
    } else if (values.defaultImage.id) {
      defaultImageId = values.defaultImage.id;
    } else if (values.defaultImage.file) {
      const uploadedImage = await dispatch(
        uploadImageToService(values.defaultImage.file),
      );
      defaultImageId = uploadedImage?.id;
    }
  }

  if (values.deletedDefaultImageId) {
    await softDeleteImage(values.deletedDefaultImageId);
  }

  restValues.categories = values.categories?.map((cat) => cat.id) || [];
  restValues.excludeCategoryTags = values.excludeCategoryTags || [];

  if (values.tags.en.length > 0) {
    restValues.tags = values.tags.en.map((tag) => ({
      name: [{ lang: LANGUAGE_CODES.ENGLISH, label: tag }],
    }));
  } else {
    restValues.tags = [];
  }

  if (values.langES) {
    restValues.tags = [
      ...restValues.tags,
      ...values.tags.es.map((tag) => ({
        name: [{ lang: LANGUAGE_CODES.SPANISH, label: tag }],
      })),
    ];
  }

  restValues.actionLinks = await prepareActionLinks(values.actionLinks);

  const submitVal = {
    // MAPPING INPUTS
    ...values.isMapped && {
      indoorLocation: {
        mapId: Number(values?.mapID) || null,
        // indoorLocation.markerPoint values are read-only, so don't send them, per SCM-2882
      },
    },

    // BUILDING DETAILS
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
    description: formatLang('description', values),

    // BUILDING ADDRESS
    inheritAddress: values.inheritAddress !== 'override',
    metadata: {
      ui: {
        autoPopulateCoordinatesFromAddress: values.autoPopulateCoords,
      },
    },

    geoLocation: {
      ...{
        markerPoint: {
          lat: values.displayLatitude ? Number(values.displayLatitude) : null,
          lng: values.displayLongitude ? Number(values.displayLongitude) : null,
        },
      },
      ...!values.isMapped && {
        navigationPoint: {
          lat: values.destinationLatitude
            ? Number(values.destinationLatitude)
            : null,
          lng: values.destinationLongitude
            ? Number(values.destinationLongitude)
            : null,
        },
      },
      ...geolocationValues,
    },
    ...!!values.defaultDestination && {
      navigation: {
        defaultPlace: values.defaultDestination,
      },
    },
    ...!!values.departmentIds && {
      departmentIds: values.departmentIds,
    },
    ...!!values.hoursType && {
      contact: {
        hours: {
          type: values.hoursType,
          custom:
            values.customHours && values.customHours.length
              ? values.customHours.map(
                ({ __typename, day, status, hours }) => ({
                  day,
                  status,
                  hours: hours.map(({ __typename, ...rest }) => rest),
                }),
              )
              : [],
        },
        ...!!values.exceptionHours && {
          exceptionHours: values.exceptionHours,
        },
      },
    },
    matching: {
      enabled: values.providerMatchingEnabled || false,
      rule:
        values.providerMatchingRule && values.providerMatchingRule !== ''
          ? JSON.parse(values.providerMatchingRule)
          : {},
    },
    defaultImage: defaultImageId,
    searchability: values.searchability,
    visibility: values.visibility,
    priority: values.priority,
    ...restValues,
  };
  return submitVal;
};
