import _ from 'lodash';

const validate = (values) => {
	const validationErrors = {};
	let failure = false;
	switch (values.activeStep) {
		case 1:
			if (!values.matchBy) {
				validationErrors.matchBy = 'Required';
				failure = true;
			}
			break;
		case 2:
			if (!values.actionType) {
				_.set(validationErrors, 'actionType', 'Required');
				failure = true;
			} else if (values.actionType === 'category') {
					if (!_.has(values, 'category.name.en') || !values.category.name.en) {
						_.set(validationErrors, 'category.name.en', 'Required');
						failure = true;
					}
				} else if (
						!_.has(values, 'curatedList.name.en')
						|| !values.curatedList.name.en
					) {
						_.set(validationErrors, 'curatedList.name.en', 'Required');
						failure = true;
					}
			break;
		case 3:
			if (!values.sortBy) {
				validationErrors.sortBy = 'Required';
				failure = true;
			}
			break;
		case 4:
			// if (values.category.tags.length === 0 && values.includeTags.length === 0) {
			//   _.set(validationErrors, 'category.tags', 'Required');
			//   validationErrors.includeTags = 'Required';
			//   failure = true;
			// }
			break;
		default:
	}
	if (failure) {
		return validationErrors;
	}
	return {};
};

export default validate;
