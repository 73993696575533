import { Box, Grid, InputAdornment, Link } from '@mui/material';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import FormSubSectionTitle from 'components/forms/formSubSectionTitle';
import InfoPanel from 'components/infoPanel/infoPanel';
import LocationInput from 'components/locations/locationInput/locationInput';
import DepartmentIdTags from 'components/networkPlace/departmentIdTags';
import NumberField from 'components/numberField/numberField';
import GozioSwitch from 'components/switch/formSwitch';
import { getFormLabel } from 'helpers/form-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { Radios, TextField as FinalTextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import { DEFAULT_RADIUS_IN_METERS } from 'pages/locations/containers/detail/siteFormHelper';
import { ADDRESS_FIELDS, US_STATE_CODES } from 'pages/locations/containers/locationsFormHelper';
import GoogleMapsLink from 'pages/locations/containers/sections/googleMapsLink';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const hasStreetAddress = (values) => values.inheritAddress !== 'inherit'
  && ADDRESS_FIELDS.some((field) => !!values[field]);

const getPoiLabel = (poi) => getLabelByLang(poi.node?.name);

const getPoiDescription = (poi) => {
  let label2 = '';
  if (poi.node?.parentBuilding?.name?.length) {
    label2 = getLabelByLang(poi.node?.parentBuilding?.name);
  }

  if (poi.node?.parentFloor?.name?.length) {
    if (label2.length) {
      label2 += ', ';
    }
    label2 += getLabelByLang(poi.node?.parentFloor?.name);
  }

  return label2;
};

const ClearComponent = ({ text = 'Clear Fields', onClick }) => (
  <Box
    sx={{
      display: 'inline-block',
      borderLeft: `2px solid ${ColorPalette.grey[300]}`,
      fontWeight: 'bold',
      fontSize: '0.875rem',
      lineHeight: '1rem',
      paddingLeft: '16px',
    }}
  >
    <Link
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {text}
    </Link>
  </Box>
);

const Address = ({ form, data, values, isUberAdmin }) => {
  const mappedPois = data?.pois?.edges?.filter((d) => d.node.isMapped) || [];
  const { designation, isMapped } = data;

  const headingContent = {
    headingContent: (
      <FormSpy>
        {(props) => hasStreetAddress(props.values) ? (
            <ClearComponent onClick={onClearAddress} />
          ) : null
        }
      </FormSpy>
    ),
  };

  const autoCompleteCallback = (values) => {
    const isMapped = form.getFieldState('isMapped')?.value === true;
    const autoPopulate
      = form.getFieldState('autoPopulateCoords')?.value === true;

    form.batch(() => {
      form.change('streetNumber', null);
      form.change('street', values.streetAddress);
      form.change('city', values.city);
      form.change('state', values.state);
      form.change('zip', values.zip);
      form.change('building', values.building);
      form.change('floor', values.floor);
      form.change('suite', values.suite);

      if (autoPopulate) {
        form.change('displayLatitude', values.latitude);
        form.change('displayLongitude', values.longitude);
        if (isMapped) {
          form.change('siteGeofenceLatitude', values.latitude);
          form.change('siteGeofenceLongitude', values.longitude);
        } else {
          form.change('destinationLatitude', values.latitude);
          form.change('destinationLongitude', values.longitude);
        }
      }
    });
  };
  const onClearAddress = () => {
    form.change('streetNumber', undefined);
    form.change('street', undefined);
    form.change('building', undefined);
    form.change('floor', undefined);
    form.change('suite', undefined);
    form.change('city', undefined);
    form.change('state', undefined);
    form.change('zip', undefined);
  };

  const onClearDisplayGeo = () => {
    form.change('displayLatitude', undefined);
    form.change('displayLongitude', undefined);
    form.change('autoPopulateCoords', false);
  };

  const onClearDestinationGeo = () => {
    form.change('destinationLatitude', undefined);
    form.change('destinationLongitude', undefined);
    form.change('autoPopulateCoords', false);
  };

  const onClearSiteGeo = () => {
    form.change('siteGeofenceLatitude', undefined);
    form.change('siteGeofenceLongitude', undefined);
    form.change('siteGeofenceRadius', DEFAULT_RADIUS_IN_METERS);
    form.change('autoPopulateCoords', false);
  };

  const adjustAutoPopulate = (value, previous) => {
    if (value?.length > 0 && previous && value !== previous) {
      form.change('autoPopulateCoords', false);
    }
  };

  const isInheritAddress = values?.inheritAddress === 'inherit';
  return (
    <>
      <FormSubSection name="Street Address" {...headingContent}>
        {designation === 'poi' && isMapped && (
          <Box sx={{ marginTop: '-8px', marginBottom: '20px' }}>
            <InfoPanel title="Overriding a Mapped POI address will update the display address only. Navigation will not be impacted." />
          </Box>
        )}
        <Grid container direction="row" spacing={3}>
          {designation !== 'site' && (
            <Grid item xs={3}>
              <Radios
                label="Parent Street Address"
                name="inheritAddress"
                color="primary"
                size="small"
                radioGroupProps={{ row: true }}
                required={false}
                data={[
                  { label: 'Inherit', value: 'inherit' },
                  { label: 'Override', value: 'override' },
                ]}
              />
              <OnChange name="inheritAddress">
                {(value, previous) => {
                  if (value === 'inherit' && value !== previous && previous) {
                    if (values.autoPopulateCoords) {
                      form.change(
                        'displayLatitude',
                        values.parentLat || undefined
                      );
                      form.change(
                        'displayLongitude',
                        values.parentLong || undefined
                      );
                      form.change(
                        'destinationLatitude',
                        values.parentLat || undefined
                      );
                      form.change(
                        'destinationLongitude',
                        values.parentLong || undefined
                      );
                    }
                  }

                  if (values.parentAddress) {
                    ['suite', 'street', 'city', 'state', 'zip'].forEach(
                      (fieldName) => {
                        form.change(
                          fieldName,
                          values.parentAddress[fieldName] || undefined
                        );
                      }
                    );

                    if (designation === 'poi' && isMapped) {
                      form.change('building', values.parentBuilding);
                      form.change('floor', values.parentFloor);
                    }
                  }
                }}
              </OnChange>
            </Grid>
          )}

          <Grid item xs={designation === 'poi' && isMapped ? 9 : 6}>
            <LocationInput
              label={getFormLabel('Street Number & Name', true)}
              name="street"
              disabled={isInheritAddress}
              autoCompleteCallback={autoCompleteCallback}
            />
          </Grid>
          {designation === 'poi' && (
            <Condition when="isMapped" is={true}>
              <Grid item xs={6}>
                <FinalTextField
                  label={getFormLabel('Building', false)}
                  name="building"
                  variant="outlined"
                  disabled={isInheritAddress}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={3}>
                <FinalTextField
                  label={getFormLabel('Floor', false)}
                  name="floor"
                  variant="outlined"
                  disabled={isInheritAddress}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={3}>
                <FinalTextField
                  label={getFormLabel('Suite', false)}
                  name="suite"
                  variant="outlined"
                  disabled={isInheritAddress}
                  autoComplete="new-password"
                />
              </Grid>
            </Condition>
          )}
          <Condition when="isMapped" is={false}>
            {designation === 'site' && (
              <Grid item xs={6}>
                <FinalTextField
                  label={getFormLabel('Building Name', false)}
                  name="building"
                  variant="outlined"
                  disabled={isInheritAddress}
                  autoComplete="new-password"
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <FinalTextField
                label={getFormLabel('Floor', false)}
                name="floor"
                variant="outlined"
                disabled={isInheritAddress}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={3}>
              <FinalTextField
                label={getFormLabel('Suite', false)}
                name="suite"
                variant="outlined"
                disabled={isInheritAddress}
                autoComplete="new-password"
              />
            </Grid>
          </Condition>
          <Grid item xs={3}>
            <FinalTextField
              label={getFormLabel('City', true)}
              name="city"
              variant="outlined"
              disabled={isInheritAddress}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={Autocomplete}
              name="state"
              label={'State'}
              required={true}
              disableClearable={true}
              disabled={isInheritAddress}
              options={US_STATE_CODES.map((state) => ({
                id: state,
                label: state,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <FinalTextField
              label={getFormLabel('Zip', true)}
              name="zip"
              variant="outlined"
              disabled={isInheritAddress}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </FormSubSection>

      {(data.designation === 'site'
        || designation === 'building'
        || designation === 'poi'
        || !isMapped) && (
        <FormSubSection name="Coordinates" description="Coordinates">
          <FormSubSectionTitle
            sx={{
              marginTop: 0,
              marginBottom: '-6px',
            }}
            title={'Auto-populate Coordinates from Street Address'}
            tooltip={
              'If "On" Coordinates will be pulled from any Address Selected in the Street Number & Name Field. If "Off" Coordinates will need to be entered manually.'
            }
            id="coordinates"
          />
          <Grid container direction="row">
            <Grid item xs={6} id="up-coords-autopop">
              <Field
                name="autoPopulateCoords"
                component={GozioSwitch}
                labelOn="On"
                labelOff="Off"
              />
            </Grid>
            <OnChange name="autoPopulateCoords">
              {(value, previous) => {
                if (
                  value !== previous
                  && value
                  && values.inheritAddress === 'inherit'
                ) {
                  form.change('displayLatitude', values.parentLat || undefined);
                  form.change(
                    'displayLongitude',
                    values.parentLong || undefined
                  );
                  form.change(
                    'destinationLatitude',
                    values.parentLat || undefined
                  );
                  form.change(
                    'destinationLongitude',
                    values.parentLong || undefined
                  );
                }
              }}
            </OnChange>
          </Grid>
          {designation === 'site' && (
            <Condition when="isMapped" is={true}>
              <FormSubSectionTitle
                title={'Site Geofence'}
                tooltip={
                  'These fields are what the Mobile App uses to determine whether a user is “Onsite” or “Offsite.” Any mobile user within the defined radius is considered to be “Onsite.”​'
                }
                onClear={
                  (values.siteGeofenceLatitude
                    || values.siteGeofenceLongitude)
                  && onClearSiteGeo
                }
                id="up-site-geofence"
              />

              <Grid container direction="row" spacing={3}>
                <Grid item xs={3}>
                  <NumberField
                    label={getFormLabel('Latitude', true)}
                    name="siteGeofenceLatitude"
                    InputProps={{ inputProps: { step: 'any' } }}
                    variant="outlined"
                  />
                  <OnChange
                    name="siteGeofenceLatitude"
                    children={adjustAutoPopulate}
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberField
                    label={getFormLabel('Longitude', true)}
                    name="siteGeofenceLongitude"
                    InputProps={{ inputProps: { step: 'any' } }}
                    variant="outlined"
                  />
                </Grid>
                <OnChange
                  name="siteGeofenceLongitude"
                  children={adjustAutoPopulate}
                />
                <Grid item xs={3}>
                  <Field name="siteGeofenceRadius">
                    {({ input }) => (
                      <NumberField
                        label={getFormLabel('Radius', true)}
                        name="siteGeofenceRadius"
                        step="any"
                        value={input.value}
                        onChange={input.onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Meters
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    )}
                  </Field>
                  <OnChange
                    name="siteGeofenceRadius"
                    children={adjustAutoPopulate}
                  />
                </Grid>
                <GoogleMapsLink
                  latitudeFieldName="siteGeofenceLatitude"
                  longitudeFieldName="siteGeofenceLongitude"
                />
              </Grid>
            </Condition>
          )}

          <FormSubSectionTitle
            title={'Display Geo'}
            tooltip={
              'Determines where the pin will show on the google map view.'
            }
            onClear={
              (values.displayLatitude || values.displayLongitude)
              && onClearDisplayGeo
            }
            id="up-display-geo"
          />
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <NumberField
                label={getFormLabel('Latitude', true)}
                name="displayLatitude"
                InputProps={{ inputProps: { step: 'any' } }}
                variant="outlined"
                disabled={isInheritAddress}
              />
              <OnChange name="displayLatitude" children={adjustAutoPopulate} />
            </Grid>
            <Grid item xs={3}>
              <NumberField
                label={getFormLabel('Longitude', true)}
                name="displayLongitude"
                InputProps={{ inputProps: { step: 'any' } }}
                variant="outlined"
                disabled={isInheritAddress}
              />
              <OnChange name="displayLongitude" children={adjustAutoPopulate} />
            </Grid>
            {
              <GoogleMapsLink
                latitudeFieldName="displayLatitude"
                longitudeFieldName="displayLongitude"
              />
            }
          </Grid>

          {designation !== 'poi' && (
            <>
              <Condition when="isMapped" is={false}>
                <FormSubSectionTitle
                  title={'Destination Geo'}
                  tooltip={
                    'Setting this lat/long determines the specific the user will be navigated to for the specified location.'
                  }
                  onClear={
                    (values.destinationLatitude
                      || values.destinationLongitude)
                    && onClearDestinationGeo
                  }
                  id="up-destination-geo"
                />
                <Grid item container direction="row" spacing={3}>
                  <Grid item xs={3}>
                    <NumberField
                      label={getFormLabel('Latitude', true)}
                      name="destinationLatitude"
                      InputProps={{ inputProps: { step: 'any' } }}
                      variant="outlined"
                    />
                  </Grid>
                  <OnChange
                    name="destinationLatitude"
                    children={adjustAutoPopulate}
                  />
                  <Grid item xs={3}>
                    <NumberField
                      label={getFormLabel('Longitude', true)}
                      name="destinationLongitude"
                      InputProps={{ inputProps: { step: 'any' } }}
                      variant="outlined"
                    />
                    <OnChange
                      name="destinationLongitude"
                      children={adjustAutoPopulate}
                    />
                  </Grid>
                  {
                    <GoogleMapsLink
                      latitudeFieldName="destinationLatitude"
                      longitudeFieldName="destinationLongitude"
                    />
                  }
                </Grid>
              </Condition>
            </>
          )}
        </FormSubSection>
      )}

      {designation !== 'poi' && (
        <Condition when="isMapped" is={true}>
          <FormSubSection
            name="Assign Default Destination"
            description="Default Destination"
          >
            {mappedPois.length === 0 && (
              <InfoPanel
                title={
                  'There must be a POI before you can assign a Default POI Destination.'
                }
                sx={{ marginTop: '-8px', marginBottom: '16px' }}
              />
            )}
            <Grid container>
              <Grid item xs={6}>
                <Field
                  component={Autocomplete}
                  name="defaultDestination"
                  label="Default POI Destination"
                  options={mappedPois.map((d) => ({
                    label: getPoiLabel(d),
                    label2: getPoiDescription(d),
                    id: d.node?.id,
                  }))}
                  autoComplete={true}
                  disableClearable={true}
                  required={true}
                />
              </Grid>
            </Grid>
          </FormSubSection>
        </Condition>
      )}
      <FormSubSection name="Department IDs" description="Department IDs">
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Field
              component={DepartmentIdTags}
              label={'Department IDs'}
              name="departmentIds"
            />
          </Grid>
        </Grid>
      </FormSubSection>

      {isUberAdmin && (
        <FormSubSection
          name="Provider Matching"
          description="Provider Matching"
        >
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Field
                name="providerMatchingEnabled"
                component={GozioSwitch}
                title={`Use this ${
                  designation === 'poi' ? 'POI' : capitalize(designation)
                } for Provider Matching`}
                labelOn="Yes"
                labelOff="No"
              />
            </Grid>
            <Condition when="providerMatchingEnabled" is={true}>
              <Grid item xs={12}>
                <FinalTextField
                  label={getFormLabel('JSON Input', true)}
                  multiline
                  name="providerMatchingRule"
                  variant="outlined"
                  minRows={11}
                  maxRows={11}
                  sx={{ fontFamily: 'monospace' }}
                />
              </Grid>
            </Condition>
          </Grid>
        </FormSubSection>
      )}
    </>
  );
};

Address.propTypes = {
  values: PropTypes.object,
  form: PropTypes.object.isRequired,
  isUberAdmin: PropTypes.bool,
};

Address.defaultProps = {
  values: {},
  isUberAdmin: false,
};

export default Address;
