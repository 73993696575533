import { Settings } from '@mui/icons-material';
import { Box, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import InfoPanel from 'components/infoPanel/infoPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useFindNetworkConfigurationGozioLinkControl } from 'hooks/dataHooks/useNetworkConfiguration';
import { useNetworkMobileApps } from 'hooks/dataHooks/useNetworkMobileApps';
import {
  useCreateNetworkDeepLinkConfiguration,
  useFindNetworkDeepLinkConfiguration,
  useUpdateNetworkDeepLinkConfiguration,
} from 'hooks/dataHooks/useNetworkNetworkDeepLink';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import Analytics, {
  ANALYTICS_SCHEMA,
  ANALYTICS_ERROR_MAP,
  buildAnalyticsInitialValues,
} from 'pages/deepLinks/tabPanels/analytics';
import LinkContent, {
  buildLinkContentInitialValues,
  LINK_CONTENT_SCHEMA,
  LINK_CONTENT_ERROR_MAP,
} from 'pages/deepLinks/tabPanels/linkContent';
import LinkDomain, {
  buildLinkDomainInitialValues,
  LINK_DOMAIN_SCHEMA,
  LINK_DOMAIN_ERROR_MAP,
} from 'pages/deepLinks/tabPanels/linkDomain';
import RequiredRedirects, {
  buildRequiredRedirectsInitialValues,
  REQUIRED_REDIRECTS_ERROR_MAP,
  REQUIRED_REDIRECTS_SCHEMA,
} from 'pages/deepLinks/tabPanels/requiredRedirects';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { Suspense, useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import DeepLinksControlModal from './deepLinksControlModal';

const buildStyles = ({ theme }) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 194px)',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  settingsButton: {
    '& svg': {
      color: theme.palette.grey[500],
    },
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    overflowY: 'auto',
    paddingBottom: '90px',
    width: '100%',
  },
  tabPanelContainer: {
    padding: '24px',
  },
  formControlLabel: {
    '& fieldset': {
      marginRight: '-20px',
      paddingLeft: '12px',
    },
  },
  row: {
    marginRight: '50%',
  },
  separator: {
    borderTop: '1px solid #e8e8ea',
    marginTop: 0,
  },
  footer: {
    backgroundColor: theme.palette.white,
    bottom: 0,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: `0 -2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.3)}`,
    height: '70px',
    padding: '13px 24px',
    position: 'absolute',
    right: 0,
    textAlign: 'right',
    width: '100%',
    zIndex: 100,
  },
});

const ERROR_FORM_FIELD_MAP = new Map([
  ...REQUIRED_REDIRECTS_ERROR_MAP,
  ...LINK_CONTENT_ERROR_MAP,
  ...LINK_DOMAIN_ERROR_MAP,
  ...ANALYTICS_ERROR_MAP,
]);

const ConfigurationContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const {
    activeNetwork,
    networks,
    setNetworks,
  } = useContext(FlamingoContext);

  const { networkId } = useParams();
  const [showDeepLinksControlModal, setShowDeepLinksControlModal]
    = useState(false);
  const [linkContentImage, setLinkContentImage] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [dirtyTabList, setDirtyTabList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [neverCompleted, setNeverCompleted] = useState(false);

  const { data, loading, refetch }
    = useFindNetworkConfigurationGozioLinkControl(networkId);
  const { data: configData } = useFindNetworkDeepLinkConfiguration(networkId);
  const { mobileApps } = useNetworkMobileApps(
    networkId,
    !!configData?.network?.mobileApps,
  );
  const [createNetworkDeepLinkConfiguration]
    = useCreateNetworkDeepLinkConfiguration();
  const [updateNetworkDeepLinkConfiguration]
    = useUpdateNetworkDeepLinkConfiguration();

  const initialValues = {
    ...buildRequiredRedirectsInitialValues(configData),
    ...buildLinkContentInitialValues(configData),
    ...buildLinkDomainInitialValues(configData),
    ...buildAnalyticsInitialValues(configData),
  };

  const networkConfiguration = useMemo(() => {
    if (!loading && data?.findNetworkConfiguration?.edges[0]?.node) return data?.findNetworkConfiguration?.edges[0]?.node;
  }, [data, loading]);
  const deepLinksControlData = networkConfiguration?.gozioLinkControl;

  const requiredRedirectsSchema = Object.keys(REQUIRED_REDIRECTS_SCHEMA).reduce((a, b) => ({
    ...a,
    [b]: REQUIRED_REDIRECTS_SCHEMA[b].schema,
  }), {});

  const linkContentSchema = Object.keys(LINK_CONTENT_SCHEMA).reduce((a, b) => ({
    ...a,
    [b]: LINK_CONTENT_SCHEMA[b].schema,
  }), {});


  const linkDomainSchema = Object.keys(LINK_DOMAIN_SCHEMA).reduce((a, b) => ({
    ...a,
    [b]: LINK_DOMAIN_SCHEMA[b].schema,
  }), {});

  const analyticsSchema = Object.keys(ANALYTICS_SCHEMA).reduce((a, b) => ({
    ...a,
    [b]: ANALYTICS_SCHEMA[b].schema,
  }), {});

  const validate = makeValidate(
    Yup.object().shape({
      ...requiredRedirectsSchema,
      ...linkContentSchema,
      ...linkDomainSchema,
      ...analyticsSchema,
    })
  );

  const tabList = useMemo(
    () => [
      {
        label: 'Required Redirects',
        component: RequiredRedirects,
        buildUpdateInput: (values) => ({
          android: {
            packageName: values.androidPackageName,
            playStoreUrl: values.androidPlayStoreUrl,
            sha256CertFingerprints: values.androidSha256CertFingerprints,
            uriScheme: values.androidUriScheme,
          },
          branding: {
            networkName: values.brandingNetworkName,
          },
          desktopDeepview: values.desktopDeepview,
          ios: {
            appStoreId: values.iosAppStoreId,
            appStoreUrl: values.iosAppStoreUrl,
            bundleIds: values.iosBundleIds,
            teamId: values.iosTeamId,
            uriScheme: values.iosUriScheme,
          },
          mobileAppId: values.mobileAppId,
          webUrl: values.webUrl,
        }),
      },
      {
        label: 'Link Content',
        component: LinkContent,
        buildUpdateInput: (values, uploadedImage) => ({
          openGraphMeta: {
            contentDescription: values.linkContentDescription,
            imageUrl: uploadedImage
              ? uploadedImage.secureUrl
              : values.linkContentImageUrl,
            title: values.linkContentTitle,
          },
        }),
      },
      {
        label: 'Link Domain',
        component: LinkDomain,
        buildUpdateInput: (values) => ({
          shortUrlDomain: values.shortUrlDomain,
          alternateShortUrlDomain: values.alternateShortUrlDomain,
          customUrlDomain: values.customUrlDomain,
        }),
      },
      {
        label: 'Analytics',
        component: Analytics,
        buildUpdateInput: (values) => ({
          analytics: {
            feature: values.analyticsFeature,
            channel: values.analyticsChannel,
          },
        }),
      },
    ],
    [],
  );

  const onClose = ({ errors }) => {
    const errorKeys = Object.keys(errors ?? {});
    if (errorKeys.length > 0) {
      if (requiredRedirectsSchema.hasOwnProperty(errorKeys[0])) {
        setCurrentTab(
          tabList.findIndex(({ label }) => label === 'Required Redirects'),
        );
      } else if (linkContentSchema.hasOwnProperty(errorKeys[0])) {
        setCurrentTab(
          tabList.findIndex(({ label }) => label === 'Link Content'),
        );
      } else if (linkDomainSchema.hasOwnProperty(errorKeys[0])) {
        setCurrentTab(
          tabList.findIndex(({ label }) => label === 'Link Domain'),
        );
      } else if (analyticsSchema.hasOwnProperty(errorKeys[0])) {
        setCurrentTab(tabList.findIndex(({ label }) => label === 'Analytics'));
      }
    }
    setIsClosing(false);
    setIsSubmitting(false);
  };

  const onSubmit = async (values) => {
    if (dirtyTabList.length > 0) {
      let input = {};

      let uploadedImage;
      if (linkContentImage?.file) {
        uploadedImage = await uploadImage(linkContentImage.file);
        setLinkContentImage(null);
      }

      dirtyTabList.forEach((tabValue) => {
        input = {
          ...input,
          ...tabList[tabValue].buildUpdateInput(values, uploadedImage),
        };
      });

      if (configData?.id) {
        await updateNetworkDeepLinkConfiguration({
          variables: {
            id: configData.id,
            input,
          },
        });
      } else {
        await createNetworkDeepLinkConfiguration({
          variables: {
            input,
          },
        });
      }
      setDirtyTabList([]);
    }
  };

  return (
    <FlamingoPage
      pageName="Configuration"
      sx={{
        '.MuiBox-root': {
          justifyContent: 'flex-start',
        },
      }}
      headerButtons={
        <Grid container justifyContent="flex-start">
          <IconButton
            sx={styles.settingsButton}
            onClick={() => setShowDeepLinksControlModal(true)}
            data-test="PublishingHistoryPage-button-settings"
            size="large"
          >
            <Settings />
          </IconButton>
          {neverCompleted && (
              <InfoPanel
                title="Deep Links configuration is incomplete. You must complete all required fields before enabling Link Manager."
                type="warning"
                boxShadow={true}
                fullWidth={false}
              />
            )}
        </Grid>
      }
    >
      <Box sx={styles.content}>
        <Suspense fallback={<Loading />}>
          <Form
            subscription={{
              submitting: true,
              pristine: true,
              validating: true,
              errors: true,
              values: true,
            }}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({ form, handleSubmit, errors }) => {
              const formState = form.getState();
              const fields = { ...formState.initialValues, ...formState.values };
              for (const k of Object.keys(fields)) {
                const v = fields[k];
                if ((v == null || (Array.isArray(v) && !v.length)) && !formState.submitSucceeded) {
                  setNeverCompleted(true);
                  break;
                }
              }
              if (formState.submitSucceeded && !formState.hasValidationErrors) setNeverCompleted(false);
              return (
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                  <FormWatcher
                    formRenderProps={{ form }}
                    errorFormFieldMap={ERROR_FORM_FIELD_MAP}
                    isClosing={isClosing}
                    isSubmitting={isSubmitting}
                    onClose={() => onClose({ errors })}
                    onSave={() => {
                      setIsClosing(false);
                      setIsSubmitting(false);
                      return true;
                    }}
                    onFormChanged={() => {
                      if (!dirtyTabList.includes(currentTab)) {
                        const tempTabList = [...dirtyTabList, currentTab];
                        tempTabList.sort();
                        setDirtyTabList(tempTabList);
                      }
                    }}
                  />
                  <Grid sx={styles.takeoverTabs}>
                    <Tabs
                      value={currentTab}
                      onChange={(e, idx) => {
                        setCurrentTab(idx);
                      }}
                      aria-label="simple tabs"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {tabList.map(({ label }, index) => (
                        <Tab
                          key={`tab-${index}`}
                          label={label}
                          {...tabA11yProps('deeplinkconfig', index)}
                        />
                      ))}
                    </Tabs>
                  </Grid>
                  {tabList.map(
                    (
                      { component: TabContentComponent, label, schema },
                      index,
                    ) => (
                      <TabPanel
                        key={`tabPanel-${index}`}
                        value={currentTab}
                        index={index}
                        sx={{
                          ...styles.tabPanel,
                          ...currentTab === index && {
                            height: 'calc(100% - 69px)',
                          },
                        }}
                      >
                        <Box sx={styles.tabBody}>
                          <TabContentComponent
                            mobileApps={
                              mobileApps?.length > 0
                                ? mobileApps
                                : configData?.network?.mobileApps
                            }
                            {...(label === 'Link Content' && {
                              onImageUploaded: ({ file, url }) => {
                                setLinkContentImage({ file, iconUrl: url });
                              },
                            })}
                            onSaved={async () => {
                              setIsSubmitting(true);
                              await form.submit();
                              setIsSubmitting(false);
                            }}
                            sx={styles}
                          />
                        </Box>
                      </TabPanel>
                    ),
                  )}
                </form>
              );
            }}
          </Form>
        </Suspense>
      </Box>
      {showDeepLinksControlModal && (
        <DeepLinksControlModal
          handleClose={() => setShowDeepLinksControlModal(false)}
          handleSave={async () => {
            const response = await refetch();
            activeNetwork.setActiveNetwork({
              ...activeNetwork,
              configuration: {
                ...activeNetwork.configuration,
                gozioLinkControl: {
                  ...response.findNetworkConfiguration.edges[0].node
                    .gozioLinkControl,
                },
              },
            });

            setNetworks(
              [...networks].map((network) => {
                if (network.id !== activeNetwork.id) return network;
                return {
                  ...network,
                  configuration: {
                    ...network.configuration,
                    gozioLinkControl: {
                      ...response.findNetworkConfiguration.edges[0].node
                        .gozioLinkControl,
                    },
                  },
                };
              }),
            );
            setShowDeepLinksControlModal(false);
          }}
          deepLinksControl={{
            id: networkConfiguration?.id,
            data: deepLinksControlData,
          }}
        />
      )}
    </FlamingoPage>
  );
};

const DeepLinkConfigurationPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <ConfigurationContent />
  </LoggedinLayout>
);

export default DeepLinkConfigurationPage;
