import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AutoComplete from 'components/autocomplete/autoComplete';
import Loading from 'components/loading/loading';
import GozioSlider from 'components/slider/slider';
import { colorWithAlpha } from 'helpers/color-util';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import GoogleMapsLink from 'pages/locations/containers/sections/googleMapsLink';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

export const GENERAL_CONFIG_SCHEMA = {
  latitude: Yup.number().nullable().required('Latitude is required'),
  longitude: Yup.number().nullable().required('Longitude is required'),
  zoom: Yup.number().nullable().required('Zoom Level is required'),
  timezone: Yup.string().nullable().required('Timezone is required'),
};

export const GENERAL_CONFIG_ERROR_MAP = [
  [
    'latitude',
    {
      label: 'Latitude',
      section: 'General',
    },
  ],
  [
    'longitude',
    {
      label: 'Longitude',
      section: 'General',
    },
  ],
  [
    'zoom',
    {
      label: 'Zoom Level',
      section: 'General',
    },
  ],
  [
    'timezone',
    {
      label: 'Timezone',
      section: 'General',
    },
  ],
];

const TIMEZONE_OPTIONS = [
  'America/Anchorage',
  'America/Chicago',
  'America/Detroit',
  'America/Denver',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/New_York',
  'Pacific/Honolulu',
].map((label) => ({ id: label, label }));

const buildStyles = ({ theme }) => ({
  root: {
    padding: '24px',
  },
  formControlLabel: {
    '& fieldset': {
      marginRight: '-20px',
      paddingLeft: '12px',
    },
  },
  footer: {
    backgroundColor: theme.palette.white,
    bottom: 0,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: `0 -2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.3)}`,
    height: '70px',
    padding: '13px 24px',
    position: 'absolute',
    right: 0,
    textAlign: 'right',
    width: '100%',
    zIndex: 100,
  },
});

const GeneralConfig = ({ disabled, loading, onSaved }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const timezoneMarks = useMemo(() => {
    const list = [];
    for (let i = 1; i <= 18; i++) {
      list.push({ value: i, label: i.toString() });
    }
    return list;
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={styles.root}>
        <Grid item xs={12} sx={{ marginBottom: '26px' }}>
          <Typography variant="subtitle1">Location Map</Typography>
        </Grid>
        <Grid item xs={3} sx={{ paddingRight: '12px' }}>
          <TextField
            name="latitude"
            label={getFormLabel('Latitude', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: '12px' }}>
          <TextField
            name="longitude"
            label={getFormLabel('Longitude', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: '24px' }}>
          <GoogleMapsLink
            latitudeFieldName="latitude"
            longitudeFieldName="longitude"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '8px', marginTop: '24px' }}>
          <Typography variant="body1">
            {getFormLabel('Zoom Level', true)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingRight: '6px' }}>
          <Field name="zoom">
            {({ input }) => (
              <GozioSlider
                input={input}
                marks={timezoneMarks}
                centerFirstLabel={true}
                centerLastLabel={true}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: '24px' }}>
          <GoogleMapsLink
            latitudeFieldName="latitude"
            longitudeFieldName="longitude"
            title="Show Zoom Level in Maps"
            zoomFieldName="zoom"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '40px' }}>
          <Typography variant="subtitle1">Timezone</Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: '26px' }}>
          <Field name="timezone">
            {({ input, meta }) => (
              <AutoComplete
                input={input}
                meta={meta}
                options={TIMEZONE_OPTIONS}
                label="Timezone"
                required={true}
                fullWidth
                disableClearable={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box sx={styles.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

GeneralConfig.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
};

GeneralConfig.defaultProps = {
  disabled: false,
  loading: false,
};

export default GeneralConfig;
