import { Box } from '@mui/material';
import FormSubSection from 'components/forms/formSubSection';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import InfoPanel from 'components/infoPanel/infoPanel';
import { MINIMUM_IMAGE_HEIGHT, MINIMUM_IMAGE_WIDTH } from 'helpers/media-util';
import { useMemo } from 'react';
import { useField } from 'react-final-form';

const DefaultImageSection = ({
                               image: defaultImage,
                               placeholderImage,
                               onImageUpdated,
                               onImageDeleted,
                               supportsKiosk,
                             }) => {
  useField('defaultImage');

  const infoPanel = useMemo(() => {
    if (defaultImage) {
      let title = 'The image below will be used as the Default Image';
      if (supportsKiosk) {
        title = `${title} and Kiosk Image.`;
      } else {
        title = `${title}.`;
      }
      return <InfoPanel title={title} />;
    }

    if (placeholderImage) {
      return (
        <InfoPanel title="If the Default Image is not assigned, the Placeholder Image will be used." />
      );
    }

    return (
      <InfoPanel title="No image has been set as a Placeholder or Default Image." />
    );
  }, [defaultImage, placeholderImage, supportsKiosk]);

  return (
    <FormSubSection name="Default Image">
      <Box sx={{ marginTop: '-8px', marginBottom: '20px' }}>{infoPanel}</Box>
      <Box sx={{ marginBottom: '48px' }}>
        <ImageUpLoader
          allowDelete
          title="Customize Default Image"
          display="inline"
          fileInfo={`Supported files include jpeg and png. The recommended file size is between 1MB and 3MBs. File sizes should be no larger than 3MBs. Image dimensions must be at least ${MINIMUM_IMAGE_WIDTH}px by ${MINIMUM_IMAGE_HEIGHT}px.`}
          image={defaultImage}
          onImageUpdated={onImageUpdated}
          onImageDeleted={onImageDeleted}
          showImage={true}
          maxFileSize={3000000}
          minWidth={MINIMUM_IMAGE_WIDTH}
          minHeight={MINIMUM_IMAGE_HEIGHT}
          previewWidth={185}
          previewHeight={109}
        />
      </Box>
    </FormSubSection>
  );
};

export default DefaultImageSection;
