import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import FormWatcher from 'components/forms/formWatcher';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import InfoPanel from 'components/infoPanel/infoPanel';
import TabPanel from 'components/tabPanel/tabPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import {
  useCreateNetworkIcon,
  useCreateNetworkPlaceholder,
  useNetworkVisualControls,
  useUpdateNetworkIcon,
  useUpdateNetworkPlaceholder,
} from 'hooks/dataHooks/useNetworkVisualControls';
import useToast from 'hooks/useToast';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 'calc(100vh - 183px)',
    borderRadius: '20px',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    height: 'calc(100% - 143px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    padding: '32px',
  },
  tabBody: {
    height: '100%',
    width: '100%',
    paddingBottom: '70px',
  },
  fieldSection: {
    '&:not(:first-child)': {
      marginTop: '40px',
    },
  },
  footer: {
    backgroundColor: theme.palette.white,
    bottom: '25px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: `0 -2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.3)}`,
    height: '70px',
    padding: '13px 24px',
    position: 'absolute',
    right: '25px',
    textAlign: 'right',
    width: 'calc(100% - 48px)',
    zIndex: 100,
  },
}));

export const visualControlsSchema = Yup.object().shape({
  provider: Yup.string().required('Physician image is required'),
  place: Yup.string().required('Location Media image is required'),
  phone: Yup.string().required('Phone Number icon is required'),
  external: Yup.string().required('Website icon is required'),
  appointment: Yup.string().required('Appointments icon is required'),
  'in-app': Yup.string().required('In-App Link icon is required'),
});

export const validate = makeValidate(visualControlsSchema);

const formFieldMap = new Map([
  ['provider', { label: 'Physician', section: '' }],
  ['place', { label: 'Location Media', section: '' }],
  ['phone', { label: 'Phone Number', section: 'Location Quicklinks' }],
  ['external', { label: 'Website', section: 'Location Quicklinks' }],
  ['appointment', { label: 'Appointments', section: 'Location Quicklinks' }],
  ['in-app', { label: 'In-App Link', section: 'Location Quicklinks' }],
]);

const VisualControlsPageContent = () => {
  const classes = useStyles();

  const { networkId } = useParams();
  const { toastNotificationSuccessHook, toastNotificationErrorHook }
    = useToast();

  const { data, refetch } = useNetworkVisualControls(networkId);
  const [createNetworkPlaceholder] = useCreateNetworkPlaceholder(networkId);
  const [updateNetworkPlaceholder] = useUpdateNetworkPlaceholder(networkId);
  const [createNetworkIcon] = useCreateNetworkIcon(networkId);
  const [updateNetworkIcon] = useUpdateNetworkIcon(networkId);

  const [initialValues, setInitialValues] = useState();
  const [activeUploaderName, setActiveUploaderName] = useState(null);
  const [uploadedImages, setUploadedImages] = useState({});
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isComplete = useMemo(() => {
    const validation = data?.getNetwork?.validation;
    if (validation?.status === 'complete') {
      return true;
    }

    if (validation?.errors?.length > 0) {
      return !validation.errors.find(
        ({ context: { key, value } }) => value === 'visualControls',
      );
    }

    return true;
  }, [data]);

  useEffect(() => {
    if (data?.getNetwork && !initialValues) {
      const values = {
        provider: null,
        place: null,
        phone: null,
        external: null,
        appointment: null,
        'in-app': null,
      };
      if (data.getNetwork.placeholders) {
        data.getNetwork.placeholders.forEach(({ type, url }) => {
          values[type] = url;
        });
      }
      if (data.getNetwork.icons) {
        data.getNetwork.icons.forEach(({ type, url }) => {
          values[type] = url;
        });
      }
      setInitialValues(values);
    }
  }, [
    data?.getNetwork,
    data?.getNetwork?.placeholders,
    data?.getNetwork?.icons,
    initialValues,
  ]);

  const onDragEnter = (name) => {
    setActiveUploaderName(name);
  };

  const handleSubmit = async (values) => {
    try {
      const keys = Object.keys(uploadedImages);
      const results = await Promise.all(
        keys.map(async (key) => {
          const { file } = uploadedImages[key];
          const image = await uploadImage(file);
          switch (key) {
            case 'place':
            case 'provider': // Placeholders
              const existingPlaceholder = data.getNetwork.placeholders.find(
                ({ type }) => type === key,
              );
              if (existingPlaceholder) {
                return updateNetworkPlaceholder({
                  variables: {
                    id: existingPlaceholder.id,
                    input: {
                      image: image.id,
                      type: key,
                    },
                  },
                });
              }
              return createNetworkPlaceholder({
                variables: {
                  input: {
                    imageId: image.id,
                    type: key,
                  },
                },
              });
            default:
              // Icons
              const existingIcon = data.getNetwork.icons.find(
                ({ type }) => type === key,
              );
              if (existingIcon) {
                return updateNetworkIcon({
                  variables: {
                    id: existingIcon.id,
                    input: {
                      image: image.id,
                      type: key,
                    },
                  },
                });
              }
              return createNetworkIcon({
                variables: {
                  input: {
                    imageId: image.id,
                    type: key,
                  },
                },
              });
          }
        }),
      );

      let hasErrors = false;
      results.forEach((response) => {
        if (response?.errors) {
          hasErrors = true;
          response.errors.forEach((err) => toastNotificationErrorHook(err.message),
          );
        }
      });

      if (!hasErrors) {
        await refetch();
        setActiveUploaderName(null);
        setInitialValues(null);
        setUploadedImages({});
        toastNotificationSuccessHook('The Media Library has been saved.');
      }
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  };

  return (
    <FlamingoPage
      pageName="Visual Controls"
      headerButtons={
        !isComplete && (
          <InfoPanel
            title="Network Settings is incomplete. Please complete the required fields before publishing."
            type="warning"
            boxShadow={true}
            fullWidth={false}
            sx={{ marginTop: '-20px' }}
          />
        )
      }
    >
      <Box className={classes.content}>
        <Grid className={classes.takeoverTabs}>
          <Tabs
            value={0}
            aria-label="media library tab"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Media Library"
              {...tabA11yProps('visual-controls', 0)}
            />
          </Tabs>
        </Grid>
        <TabPanel value={0} index={0} className={classes.tabPanel}>
          <Form
            subscription={{
              submitting: true,
              pristine: true,
              validating: true,
              errors: true,
            }}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
          >
            {({
                form,
                handleSubmit,
                submitting,
                pristine,
                invalid,
                errors,
              }) => (
              <form onSubmit={handleSubmit}>
                <FormWatcher
                  formRenderProps={{ form }}
                  errorFormFieldMap={formFieldMap}
                  isClosing={isClosing}
                  isSubmitting={isSubmitting}
                  onClose={() => {
                    setIsClosing(false);
                    setIsSubmitting(false);
                  }}
                  onContinue={async () => {
                    setUploadedImages({});
                    setActiveUploaderName(null);
                    setIsClosing(false);
                    setIsSubmitting(false);
                  }}
                  onSave={() => {
                    setIsClosing(false);
                    setIsSubmitting(false);
                  }}
                />
                <Box className={classes.tabBody}>
                  <Box className={classes.fieldSection}>
                    <Grid item xs>
                      <Typography variant="subtitle1">Physician</Typography>
                    </Grid>
                    <Grid item xs sx={{ marginTop: '16px' }}>
                      <Field name="provider">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="provider"
                            title={
                              <span>
                                <Asterisk /> Placeholder Image
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            fileInfo="Supported files includes jpeg and png. Image dimensions must be at least 70px by 70px."
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                provider: {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            minHeight={70}
                            minWidth={70}
                            activeDragPadding={6}
                            allowDelete={false}
                            showImage={true}
                            previewWidth={118}
                            previewHeight={118}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Box>
                  <Box className={classes.fieldSection}>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        Location Media
                      </Typography>
                    </Grid>
                    <Grid item xs sx={{ marginTop: '16px' }}>
                      <Field name="place">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="place"
                            title={
                              <span>
                                <Asterisk /> Placeholder Image
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                place: {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            activeDragPadding={12}
                            allowDelete={false}
                            showImage={true}
                            previewWidth={186}
                            previewHeight={116}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Box>
                  <Grid container className={classes.fieldSection}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Location Quicklinks
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ marginTop: '16px', paddingRight: '24px' }}
                    >
                      <Field name="phone">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="phone"
                            title={
                              <span>
                                <Asterisk /> Phone Number
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                phone: {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            activeDragPadding={12}
                            allowDelete={false}
                            fileInfo="Supported files includes jpeg and png. Image dimensions must be at least 110px by 110px."
                            showImage={true}
                            isIcon={true}
                            minWidth={110}
                            minHeight={110}
                            previewWidth={64}
                            previewHeight={64}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ marginTop: '16px', paddingLeft: '24px' }}
                    >
                      <Field name="external">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="external"
                            title={
                              <span>
                                <Asterisk /> Website
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                external: {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            activeDragPadding={12}
                            allowDelete={false}
                            fileInfo="Supported files includes jpeg and png. Image dimensions must be at least 110px by 110px."
                            showImage={true}
                            isIcon={true}
                            minWidth={110}
                            minHeight={110}
                            previewWidth={64}
                            previewHeight={64}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ marginTop: '32px', paddingRight: '24px' }}
                    >
                      <Field name="appointment">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="appointment"
                            title={
                              <span>
                                <Asterisk /> Appointments
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                appointment: {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            activeDragPadding={12}
                            allowDelete={false}
                            fileInfo="Supported files includes jpeg and png. Image dimensions must be at least 110px by 110px."
                            showImage={true}
                            isIcon={true}
                            minWidth={110}
                            minHeight={110}
                            previewWidth={64}
                            previewHeight={64}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ marginTop: '32px', paddingLeft: '24px' }}
                    >
                      <Field name="in-app">
                        {({ input: { onChange, value } }) => (
                          <ImageUpLoader
                            name="in-app"
                            title={
                              <span>
                                <Asterisk /> In-App Link
                              </span>
                            }
                            display="inline"
                            image={value ? { url: value } : null}
                            onImageUpdated={({ file, url }) => {
                              setUploadedImages({
                                ...uploadedImages,
                                'in-app': {
                                  file,
                                  url: null,
                                },
                              });
                              onChange(url);
                            }}
                            activeUploaderName={activeUploaderName}
                            onDragEnter={onDragEnter}
                            activeDragPadding={12}
                            allowDelete={false}
                            fileInfo="Supported files includes jpeg and png. Image dimensions must be at least 110px by 110px."
                            showImage={true}
                            isIcon={true}
                            minWidth={110}
                            minHeight={110}
                            previewWidth={64}
                            previewHeight={64}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.footer}>
                  <Button
                    color="primary"
                    data-test="save-button"
                    disabled={
                      submitting
                      || pristine
                      || invalid
                      || Object.keys(errors ?? {}).length > 0
                    }
                    onClick={async () => await form.submit()}
                    sx={{ width: '120px' }}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </TabPanel>
      </Box>
    </FlamingoPage>
  );
};

const VisualControlsPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <VisualControlsPageContent />
  </LoggedinLayout>
);

export default VisualControlsPage;
