import { CancelRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhoneFrame1x from 'assets/mobile-preview-phone.png';
import PhoneFrame2x from 'assets/mobile-preview-phone@2x.png';
import PhoneFrame3x from 'assets/mobile-preview-phone@3x.png';
import WarningPanel from 'components/warningPanel/warningPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import PanelPreviewLink from 'pages/mobileSettings/explorePanel/containers/PanelPreviewLink';
import { getExplorePanelTemplateCount } from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[50],
    borderLeft: `2px solid ${colorWithAlpha(ColorPalette.grey[350], 0.3)}`,
    display: 'flex',
    minWidth: '444px',
    justifyContent: 'center',
    textAlign: 'center',
    top: '68px',
  },
  pageTitle: {
    position: 'absolute',
    top: theme.spacing(4),
  },
  content: {
    marginTop: '87px',
    textAlign: 'left',
    position: 'relative',
    width: '360px',
  },
  previewContainer: {
    width: '360px',
    position: 'relative',
    top: '-24px',
  },
  listItem: {
    background: 'unset',
    marginTop: theme.spacing(1),
    minHeight: '48px',
    height: '527px',
    overflowY: 'auto',

    '& .MuiGrid-root': {
      background: 'unset',
    },

    '& .MuiListItem-root': {
      // borderBottom: `1px solid ${colorWithAlpha(theme.palette.grey[200], 0.3)}`,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      minHeight: '48px',
    },

    '& .MuiListItemText-root': {
      '& p': {
        color: 'unset',
      },
    },
  },
  itemIcon: {
    height: '26px',
    width: '26px',

    '& svg': {
      color: theme.palette.white,
      height: '18px',
      width: '18px',
    },
  },
  itemLabel: {
    color: theme.palette.white,
    display: '-webkit-box',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  shadowContainer: {
    background: theme.palette.grey[50],
    boxShadow: '0 0 4px 0 rgba(38, 43, 46, 0.25)',
    height: '654px',
    position: 'absolute',
    top: '72px',
    width: '100%',
    zIndex: 1,
  },
  image: {
    height: '685px',
    position: 'absolute',
    top: '42px',
    width: '100%',
    zIndex: 2,
  },
  explorePanelContainer: {
    background: theme.palette.grey[50],
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    boxShadow: '0 0 4px 0 rgba(38, 43, 46, 0.25)',
    height: '168px',
    position: 'absolute',
    top: '558px',
    width: '360px',
    zIndex: 2,
  },
  explorePanelHeading: {
    height: '58px',
  },
  explorePanelTitle: {
    color: theme.palette.grey[600],
    padding: '18px 16px 0 16px',
  },
  cancelIcon: {
    color: theme.palette.grey[300],
    float: 'right',
    height: '30px',
    position: 'relative',
    right: '-4px',
    top: '-28px',
    width: '30px',
  },
  cancelIconBackdrop: {
    background: theme.palette.grey[500],
    float: 'right',
    height: '16px',
    position: 'relative',
    right: '19px',
    top: '-21px',
    width: '16px',
  },
  supportingImageContainer: {
    background: theme.palette.grey[350],
    borderTop: `3px solid ${theme.palette.grey[300]}`,
    height: '397px',
    position: 'absolute',
    top: '104px',
    width: '360px',
    zIndex: 3,
  },
  placeholderIcon: {
    color: theme.palette.white,
    margin: '158px auto 0 auto',
    width: '81px',
    height: '81px',

    '& svg': {
      width: '81px',
      height: '81px',
    },
  },
  supportingImage: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(-0.25),
  },
  logoText: {
    fontWeight: '700 !important',
    marginTop: '4px',
  },
  logoLink: {
    marginTop: '4px',
    color: ColorPalette.blue[400],
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
  },
  explorePanelLinks: {
    borderTop: `1px solid ${colorWithAlpha(theme.palette.grey[600], 0.2)}`,
    height: '110px',
    width: '360px',
  },
  explorePanelLinkRow: {
    alignItems: 'center',
    height: '90px',
  },
  explorePanelLinkRow2: {
    marginTop: '12px',
  },
  bottomSection: {
    background: theme.palette.white,
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.25)',
    height: '90px',
    position: 'absolute',
    top: '726px',
    width: '360px',
  },
});

const PanelPreview = ({ sx, data, fixed, incompleteMessage, title }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const {
    layout: { openDrawer },
  } = useContext(FlamingoContext);

  const leftOffset = openDrawer ? 250 : 0;

  const panelLinks = useMemo(() => {
    const count = getExplorePanelTemplateCount(data.template);
    const rowCount = count / 4;
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <Grid
          key={`link_row_${i}`}
          container
          sx={{
            ...styles.explorePanelLinkRow,
            ...i > 0 && styles.explorePanelLinkRow2,
          }}
        >
          {data?.actions
            ?.slice(i * 4, i === 0 ? 4 : data?.actions?.length)
            .map((action, index) => (
              <Grid key={`link_${i}_${index}`} item xs={3}>
                <PanelPreviewLink
                  backgroundColor={data?.color}
                  name={action.name}
                  icon={action.icon}
                  isMiniIcon={i > 0}
                />
              </Grid>
            ))}
        </Grid>,
      );
    }
    return rows;
  }, [styles, data]);

  const previewContent = useMemo(
    () => (
      <>
        <Box sx={styles.shadowContainer} />
        <img
          style={styles.image}
          src={PhoneFrame1x}
          srcSet={`${PhoneFrame2x} 2x, ${PhoneFrame3x} 3x`}
          alt="phone"
        />
        {data?.actions?.length > 0 && (
          <Box sx={styles.explorePanelContainer}>
            <Box sx={styles.explorePanelHeading}>
              <Typography sx={styles.explorePanelTitle} variant="subtitle1">
                {getLabelByLang(data.name)}
              </Typography>
              <Box sx={styles.cancelIconBackdrop} />
              <CancelRounded sx={styles.cancelIcon} />
            </Box>
            <Box sx={styles.explorePanelLinks}>{panelLinks}</Box>
          </Box>
        )}
      </>
    ),
    [styles, data, panelLinks],
  );

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      {title && (
        <Box sx={{ ...styles.pageTitle, left: `16px + ${leftOffset}px` }}>
          <Typography variant="h1" display="block">
            {title}
          </Typography>
        </Box>
      )}
      <Box sx={{ ...styles.content, height: fixed ? '1000px' : undefined }}>
        {incompleteMessage && (
          <WarningPanel
            message={incompleteMessage}
            disableClose={true}
            sx={{ position: 'absolute', top: '-60px' }}
          />
        )}
        <Typography variant="subtitle1">Mobile Preview</Typography>
        <Box sx={styles.previewContainer}>
          {previewContent}
          <Box sx={styles.bottomSection}></Box>
        </Box>
      </Box>
    </Box>
  );
};

PanelPreview.propTypes = {
  data: PropTypes.object,
  fixed: PropTypes.bool,
  incompleteMessage: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
};

PanelPreview.defaultProps = {
  data: {},
  fixed: false,
  incompleteMessage: null,
  sx: {},
  title: null,
};

export default PanelPreview;
