import { Box, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BuildVersion from 'components/forms/buildVersion';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  footer: {
    backgroundColor: theme.palette.grey[50],
    bottom: 0,
    boxShadow: `0px -2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[350],
      0.3,
    )}`,
    height: '69px',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  cancelBtn: {
    bottom: theme.spacing(2),
    left: theme.spacing(3),
    position: 'absolute',
  },
  saveBtn: {
    bottom: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(3),
    minWidth: '119px',
  },
  progress: {
    color: theme.palette.white,
  },
});

function DetailFormFooter({
                            confirmText,
                            disableConfirm,
                            onCancel,
                            showBuildVersion,
                            submitting,
                            readOnly,
                            onSubmit,
                          }) {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={styles.footer}>
      {showBuildVersion && <BuildVersion />}
      {onCancel && (
        <Button
          color="primary"
          data-test="cancel-button"
          onClick={onCancel}
          variant="outlined"
          sx={styles.cancelBtn}
        >
          CANCEL
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={styles.saveBtn}
        onClick={onSubmit}
        disabled={disableConfirm || submitting || readOnly}
        startIcon={
          submitting ? (
            <CircularProgress size={16} thickness={4} sx={styles.progress} />
          ) : null
        }
      >
        {readOnly ? 'Read Only' : confirmText}
      </Button>
    </Box>
  );
}

DetailFormFooter.propTypes = {
  confirmText: PropTypes.string,
  disableConfirm: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showBuildVersion: PropTypes.bool,
  submitting: PropTypes.bool,
};

DetailFormFooter.defaultProps = {
  confirmText: 'Save',
  disableConfirm: false,
  onCancel: null,
  readOnly: false,
  showBuildVersion: true,
  submitting: false,
};

export default DetailFormFooter;
