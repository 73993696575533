import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import EmptySection from 'components/emptySection/emptySection';
import GozioListItem from 'components/listItem/gozioListItem';
import TabPanel from 'components/tabPanel/tabPanel';
import { buildLabelByLang, getLabelByLang, hasSpanish } from 'helpers/lang-util';
import useCheckGozioAdmin, { useCheckRole } from 'hooks/useCheckGozioAdmin';
import ColorPalette from 'pages/gozio_colors';
import { QuicklinkModal } from 'pages/mobileSettings/dashboard/containers/quicklinkModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useField } from 'react-final-form';

export const QuicklinkListQuicklinksTabPanel = ({
                                                  sx,
                                                  formValues,
                                                  form,
                                                  index,
                                                  languages = [],
                                                  networkId,
                                                  onChange: onFormChange,
                                                  tabValue,
                                                }) => {
  useField('qlQuickLinks');

  const isUberAdmin = useCheckGozioAdmin();
  const quickLinksLength = formValues?.qlQuickLinks?.length || 0;

  const quickLinksAddPermission = ['uber-admin', 'network-admin'].includes(
    useCheckRole(),
  );

  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const [uploadedIconData, setUploadedIconData] = useState(null);
  const [selectedQuickLink, setSelectedQuickLink] = useState(null);

  const handleShowQuickLinkModal = (link = null) => {
    setUploadedIconData(null);
    setSelectedQuickLink(link);
    setShowQuickLinkModal(true);
  };

  const handleEditQuickLinkClicked = (id, editIndex) => {
    handleShowQuickLinkModal({
      ...formValues.qlQuickLinks.find((link) => typeof id === 'undefined' ? link.index === editIndex : link.id === id,
      ),
    });
  };

  const handleDeleteQuickLinkClicked = (id, deleteIndex) => {
    let updatedQuickLinksData = [...formValues.qlQuickLinks];
    updatedQuickLinksData = updatedQuickLinksData
      .filter((link) => typeof id === 'undefined' ? link.index !== deleteIndex : link.id !== id,
      )
      .map(({ index, ...rest }, idx) => ({
        index: idx,
        ...rest,
      }));
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
  };

  const handleQuicklinkAddedOrEdited = (data) => {
    const updatedQuickLinksData = formValues?.qlQuickLinks
      ? [...formValues.qlQuickLinks]
      : [];
    const updatedData = {
      name: buildLabelByLang(data, 'nameEn', 'nameEs'),
      iconFile: uploadedIconData?.file,
      iconUrl: uploadedIconData?.iconUrl || data.iconUrl,
      type: data.linkType,
      data: {},
      validation: data.validation,
    };
    switch (data.linkType) {
      case 'category':
        updatedData.data.id = data.categories?.map((c) => c.id);
        break;
      case 'in_app':
        updatedData.data.id = data.inAppDest;
        break;
      case 'list':
        updatedData.data.id = data.listDest;
        break;
      case 'external_app':
        updatedData.data = {
          client: data.externalType ? data.externalType : '',
          url: data.externalURL ? data.externalURL : '',
          androidUrl: data.externalAndroidURL ? data.externalAndroidURL : '',
          iosUrl: data.externaliOSURL ? data.externaliOSURL : '',
        };

        if (data.hasPlatformSpecificFallbacks) {
          updatedData.data.androidFallbackUrl = data.fallbackAndroidURL ?? null;
          updatedData.data.iosFallbackUrl = data.fallbackiOSURL ?? null;
        } else {
          updatedData.data.androidFallbackUrl = null;
          updatedData.data.iosFallbackUrl = null;
        }
        break;
      case 'website':
        updatedData.data = {
          client: data.webType ?? '',
          url: {
            en: data.webUrlEn ?? '',
            ...hasSpanish(languages) && {
              es: data.webUrlEs ?? '',
            },
          },
        };
        break;
      case 'custom':
        updatedData.data.url = data.custom;
        break;
      default:
        break;
    }
    if (selectedQuickLink) {
      updatedData.id = selectedQuickLink.id;
      if (selectedQuickLink.iconFile) {
        updatedData.iconFile = selectedQuickLink.iconFile;
      }
      const index = updatedQuickLinksData.findIndex(
        (link) => link.id === selectedQuickLink.id,
      );
      updatedData.index = index;
      updatedQuickLinksData[index] = updatedData;
    } else {
      updatedData.index = updatedQuickLinksData.length;
      updatedQuickLinksData.push(updatedData);
    }
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
    setShowQuickLinkModal(false);
  };

  const handleUpdateOptions = (
    updatedListOptions,
    sourceIndex,
    targetIndex,
  ) => {
    const updatedQuicklinks = [...formValues.qlQuickLinks];
    updatedQuicklinks[sourceIndex].index = targetIndex;
    updatedQuicklinks[targetIndex].index = sourceIndex;
    [updatedQuicklinks[sourceIndex], updatedQuicklinks[targetIndex]] = [
      updatedQuicklinks[targetIndex],
      updatedQuicklinks[sourceIndex],
    ];
    form.change('qlQuickLinks', updatedQuicklinks);
    onFormChange();
  };

  const buildListOptions = () => {
    let options = [];
    if (quickLinksLength > 0) {
      const editAction = [
        {
          Icon: Edit,
          hover: 'Edit',
          onClick: (id, idx) => handleEditQuickLinkClicked(id, idx),
        },
      ];
      const editAndDeleteActions = [
        ...editAction,
        {
          Icon: Delete,
          hover: 'Delete',
          onClick: (id, idx) => handleDeleteQuickLinkClicked(id, idx),
        },
      ];

      options = formValues.qlQuickLinks.map((link, index) => ({
        id: link.id,
        order: index,
        iconUrl: link.iconUrl,
        label: getLabelByLang(link.name),
        labelSx: sx.quickLinkLabel,
        actions:
          isUberAdmin || link.type !== 'custom'
            ? editAndDeleteActions
            : editAction,
        validation: link.validation,
      }));
    }
    return options;
  };

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '14px',
          paddingTop: '5px',
        }}
      >
        <Typography variant="subtitle1" className="required">
          {quickLinksLength} Quicklinks
        </Typography>
        {quickLinksLength > 0 && (
          <IconButton
            onClick={() => handleShowQuickLinkModal()}
            aria-label="add-tile"
            sx={{ padding: 0 }}
            size="large"
          >
            <AddCircle
              sx={{
                color: ColorPalette.primary.main,
                fontSize: '40px',
              }}
            />
          </IconButton>
        )}
      </Box>
      {quickLinksLength === 0 ? (
        <EmptySection
          title="There Are No Quicklinks Set Up"
          buttonLabel="Add a Quicklink"
          hasPermission={quickLinksAddPermission}
          ButtonIcon={Add}
          buttonClicked={() => handleShowQuickLinkModal()}
        />
      ) : (
        <GozioListItem
          listHeaderName="QuickLink Name"
          progressHeaderName="Progress"
          enableReorder={true}
          options={buildListOptions()}
          updateOptions={handleUpdateOptions}
        />
      )}
      {showQuickLinkModal && (
        <QuicklinkModal
          sx={sx}
          onSubmit={handleQuicklinkAddedOrEdited}
          languages={languages}
          networkId={networkId}
          onClose={() => setShowQuickLinkModal(false)}
          onIconUploaded={setUploadedIconData}
          iconWidthAndHeight={104}
          selectedQuickLink={selectedQuickLink}
          supportedLinkTypes={[
            'category',
            'external_app',
            'in_app',
            'list',
            'website',
            'custom',
          ]}
        />
      )}
    </TabPanel>
  );
};

QuicklinkListQuicklinksTabPanel.propTypes = {
  sx: PropTypes.object,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  networkId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

QuicklinkListQuicklinksTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
