import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_NETWORK = gql`
  mutation createNetwork($input: NetworkInputCreate!) {
    createNetwork(input: $input) {
      id
      sdkEnabled
      mobileEnabled
      kioskEnabled
    }
  }
`;

export const CREATE_NETWORK_ICON = gql`
  mutation createNetworkIcon($input: NetworkIconInputIdentity!) {
    createNetworkIcon(input: $input) {
      id
      type
      url(protocol: secure)
    }
  }
`;

export const CREATE_NETWORK_PLACEHOLDER = gql`
  mutation createNetworkPlaceholder($input: NetworkPlaceholderInputIdentity!) {
    createNetworkPlaceholder(input: $input) {
      id
      type
      url(protocol: secure)
    }
  }
`;

export const CREATE_NETWORK_THEME_COLOR = gql`
  mutation createNetworkThemeColors($networkId: ID!, $colors: [ColorInputIdentity]!) {
    createNetworkThemeColors(networkId: $networkId, colors: $colors) {
      id
      theme {
        colors {
          key
          value
        }
      }
    }
  }
`;

export const DELETE_NETWORK_THEME_COLOR = gql`
  mutation deleteNetworkThemeColor($networkId: ID!, $color: ColorInputIdentity!) {
    deleteNetworkThemeColor(networkId: $networkId, color: $color) {
      id
      theme {
        colors {
          key
          value
        }
      }
    }
  }
`;

export const FIND_NETWORK = gql`
  query findNetwork {
    findNetwork {
      edges {
        node {
          id
          name
          humanName
          kioskEnabled
          mobileEnabled
          sdkEnabled
          goLiveMobile
          isActive
          configuration {
            id
            gozioLinkControl {
              liveWorkspace
            }
            publishingControl {
              liveWorkspace
              draftWorkspace
            }
            features {
              appointmentAvailability
              banners
              kiosk
              languages
              myChart
              myFavorites
              openPlatform
              personas
              providerIntegration
              pushNotifications
              quicklinkList
              singleSignOn
            }
          }
          icons {
            type
            image {
              id
              url
            }
            id
          }
          logo {
            url(protocol: none)
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_CONFIGURATION_PUBLISHING_CONTROL = gql`
  query findNetworkConfiguration {
    findNetworkConfiguration {
      edges {
        node {
          id
          network {
            id
          }
          publishingControl {
            draftWorkspace
            liveWorkspace
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL = gql`
  query findNetworkConfiguration {
    findNetworkConfiguration {
      edges {
        node {
          id
          network {
            id
          }
          gozioLinkControl {
            liveWorkspace
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_MOBILE_APP_TOKENS = gql`
  query {
    findNetworkMobileApp {
      edges {
        node {
          token
          type
        }
      }
    }
}
`;

export const GET_NETWORK = gql`
  query getNetwork($id: ID!) {
    getNetwork(id: $id) {
      id
      name
      humanName
      kioskEnabled
      goLiveMobile
      configuration {
        id
        gozioLinkControl {
          liveWorkspace
        }
        publishingControl {
          liveWorkspace
          draftWorkspace
        }
        features {
          appointmentAvailability
          banners
          kiosk
          languages
          myChart
          myFavorites
          openPlatform
          personas
          providerIntegration
          pushNotifications
          quicklinkList
          singleSignOn
        }
      }
      icons {
        type
        image {
          url
        }
        id
      }
      logo {
        url(protocol: none)
      }
    }
  }
`;

export const GET_NETWORK_CONFIG = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      map {
        latitude
        longitude
        zoom
      }
      timezone
      kioskEnabled
      mobileEnabled
      analyticsProjectId
      goLiveMobile
      mobile {
        analyticsEnabled
      }
      goLiveKiosk
      kiosk {
        analyticsProjectId
        analyticsEnabled
      }
      validation {
        status
        errors {
          message
          path
          type
          context {
            key
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_CONFIGURATION_VERSIONS = gql`
  ${fragments.NETWORK_CONFIGURATION_VERSION_ATTRIBUTES}
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      ...networkConfigurationVersionAttributes
    }
  }
`;

export const GET_NETWORK_CONFIGURATION_FEATURES = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      configuration {
        id
        features {
          appointmentAvailability
          banners
          kiosk
          languages
          myChart
          myFavorites
          openPlatform
          personas
          providerIntegration
          pushNotifications
          quicklinkList
          singleSignOn
          kiosk
        }
      }
    }
  }
`;

export const GET_NETWORK_DATA_VERSIONS = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      name
      kioskEnabled
      mobileEnabled
      sdkEnabled
      mobileDataVersions
      kioskDataVersions
      sdkDataVersions
    }
  }
`;

export const GET_NETWORK_MOBILE_APPS = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      mobileApps {
        id
        name
        type
      }
    }
  }
`;

export const GET_NETWORK_THEME_COLORS = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      theme {
        colors {
          key
          value
        }
      }
    }
  }
`;

export const GET_NETWORK_VALIDATION = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      validation {
        status
        errors {
          message
          path
          type
          context {
            key
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_VISUAL_CONTROLS = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      placeholders {
        id
        type
        url(protocol: secure)
      }
      icons {
        id
        tint(withTheme: true)
        type
        url(protocol: secure)
      }
      validation {
        status
        errors {
          message
          path
          type
          context {
            key
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_WAIT_TIME = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      id
      waittimeEndpointUrl
      waittimeFeed
    }
  }
`;

export const SET_NETWORK_THEME_COLOR = gql`
  mutation setNetworkThemeColor($networkId: ID!, $color: ColorInputIdentity!) {
    setNetworkThemeColor(networkId: $networkId, color: $color) {
      id
      theme {
        colors {
          key
          value
        }
      }
    }
  }
`;

export const UPDATE_NETWORK = gql`
  mutation updateNetwork($input: NetworkInputUpdate) {
    updateNetwork(input: $input) {
      isActive
    }
  }
`;

export const UPDATE_NETWORK_CONFIG = gql`
  mutation updateNetwork($input: NetworkInputUpdate) {
    updateNetwork(input: $input) {
      id
      map {
        latitude
        longitude
        zoom
      }
      timezone

      kioskEnabled
      mobileEnabled
      analyticsProjectId
      goLiveMobile
      mobile {
        analyticsEnabled
      }
      goLiveKiosk
      kiosk {
        analyticsProjectId
        analyticsEnabled
      }
    }
  }
`;

export const UPDATE_NETWORK_CONFIGURATION_FEATURES = gql`
  mutation updateNetworkConfiguration($networkConfigurationId: ID!, $input: NetworkConfigurationInputUpdate) {
    updateNetworkConfiguration(id: $networkConfigurationId, input: $input) {
      id
      features {
        appointmentAvailability
        banners
        kiosk
        languages
        myChart
        myFavorites
        openPlatform
        personas
        providerIntegration
        pushNotifications
        quicklinkList
        singleSignOn
        kiosk
      }
    }
  }
`;

export const UPDATE_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL = gql`
  mutation UpdateNetworkConfiguration($id: ID!, $input: NetworkConfigurationInputUpdate!) {
    updateNetworkConfiguration(id: $id, input: $input) {
      gozioLinkControl {
        liveWorkspace
        id
      }
      id
    }
  }
`;

export const UPDATE_NETWORK_CONFIGURATION_PUBLISHING_CONTROL = gql`
  mutation setNetworkPublishing ($input: NetworkPublishingInput!){
    setNetworkPublishing(input: $input) {
      configuration {
        id
        publishingControl {
          liveWorkspace
          draftWorkspace
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_ENABLEMENT = gql`
  mutation updateNetwork($input: NetworkInputUpdate) {
    updateNetwork(input: $input) {
      mobileEnabled
      kioskEnabled
      sdkEnabled
    }
  }
`;

export const UPDATE_NETWORK_ICON = gql`
  mutation updateNetworkIcon($id: ID!, $input: NetworkIconInputUpdate!) {
    updateNetworkIcon(id: $id, input: $input) {
      id
      tint(withTheme: true)
      type
      url(protocol: secure)
    }
  }
`;

export const UPDATE_NETWORK_PLACEHOLDER = gql`
  mutation updateNetworkPlaceholder($id: ID!, $input: NetworkPlaceholderInputUpdate!) {
    updateNetworkPlaceholder(id: $id, input: $input) {
      id
      type
      url(protocol: secure)
    }
  }
`;

export const UPDATE_NETWORK_VERSION_CONTROL = gql`
  ${fragments.NETWORK_CONFIGURATION_VERSION_ATTRIBUTES}
  mutation setNetworkVersionControl($input: NetworkVersionControlInput!) {
    setNetworkVersionControl(input: $input) {
      ...networkConfigurationVersionAttributes
    }
  }
`;

export const UPDATE_NETWORK_WAIT_TIME = gql`
  mutation updateNetwork($input: NetworkInputUpdate) {
    updateNetwork(input: $input) {
      id
      waittimeEndpointUrl
      waittimeFeed
    }
  }
`;
