import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GenericModal from 'components/genericModal/genericModal';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { getTableCountTitle } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useUpdateNetwork } from 'hooks/dataHooks/useNetworkConfiguration';
import useToast from 'hooks/useToast';
import AddNetworkModal from 'pages/configuration/containers/addNetworkModal';
import EditNetworkModal from 'pages/configuration/containers/editNetworkModal';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useContext, useMemo, useState } from 'react';

const NetworksPage = () => {
  const { toastNotificationErrorHook, toastNotificationSuccessHook }
    = useToast();
  const { networks, setNetworks } = useContext(FlamingoContext);
  const [selectedNetworkForEdit, setSelectedNetworkForEdit] = useState(null);
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal]
    = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [confirmProgress, setConfirmProgress] = useState(false);
  const [updateNetwork] = useUpdateNetwork();

  const columns = useMemo(
    () => [
      {
        Header: 'Network Name',
        accessor: 'name',
        minWidth: 190,
      },
      {
        Header: 'Text Identifier',
        accessor: 'textIdentifier',
        minWidth: 190,
      },
      {
        Header: 'Network Enablement',
        accessor: 'enablement',
        minWidth: 220,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        minWidth: 60,
        width: 60,
        disableGlobalFilter: true,
        disableSortBy: true,
        justifyRight: true,
        Cell: ({ row }) => (
          <Box className="hoverUnhide">
            <Tooltip title="Edit Network">
              <Edit onClick={() => setSelectedNetworkForEdit(row.original)} />
            </Tooltip>
            <Tooltip title="Archive Network">
              <Delete
                onClick={() => {
                  setShowArchiveConfirmationModal(row.original);
                }}
              />
            </Tooltip>
          </Box>
        ),
      },
    ],
    [],
  );

  const data = networks?.map((network) => ({
    ...network,
    name: network.humanName,
    textIdentifier: network.name,
    enablement: [
      network.kioskEnabled && 'Kiosk Solution',
      network.mobileEnabled && 'Mobile Solution',
      network.sdkEnabled && 'SDK Support',
    ]
      .filter((x) => ![false, null].includes(x))
      .join(', '),
    actions: null,
  }));

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName="Networks"
        headerButtons={
          <AddCircleButton
            tooltipTitle="Add Network"
            onClick={() => setShowAddModal(true)}
          />
        }
      >
        <Box sx={{ height: 'calc(100vh - 186px)', position: 'relative' }}>
          <GozioTable
            loading={networks.length === 0}
            name="NetworksTable"
            columns={columns}
            data={data}
            sx={{
              borderRadius: '20px',
              boxShadow: `0px 2px 4px 0px ${ColorPalette.grey[600]}40`,
            }}
            sortBy={[{ id: 'name', desc: false }]}
            countTitle={getTableCountTitle(networks?.length, 'Network')}
            emptyContent={
              <Box>
                <EmptyPanel
                  title="You have no networks"
                  subtitle="Add a new network to get started"
                  buttonLabel="Add Network"
                  buttonShown={true}
                  buttonClicked={() => setShowAddModal(true)}
                />
              </Box>
            }
            hidePagination={true}
          />
        </Box>
        {selectedNetworkForEdit && (
          <EditNetworkModal
            onClose={() => {
              setSelectedNetworkForEdit(null);
            }}
            onSave={(network) => {
              setNetworks(
                networks.map((n) => n.id === network.id ? { ...n, ...network } : n,
                ),
              );
              setSelectedNetworkForEdit(false);
              toastNotificationSuccessHook(
                `Successfully updated network "${network.name}"`,
              );
            }}
            network={selectedNetworkForEdit}
          />
        )}
        {showAddModal && (
          <AddNetworkModal
            onClose={() => {
              setShowAddModal(false);
            }}
            onSave={(networkDetails) => {
              setShowAddModal(false);
              setNetworks([...networks, networkDetails]);
              toastNotificationSuccessHook(
                `Successfully created new network "${networkDetails.humanName}"`,
              );
            }}
          />
        )}
        {showArchiveConfirmationModal && (
          <GenericModal
            title="Archive Network"
            confirmText="Yes, archive network"
            body={`Are you sure you want to archive network “${showArchiveConfirmationModal.humanName}”? All network data will be retained, but the network will no longer be accessible in Portal.`}
            confirmProgress={confirmProgress}
            handleConfirm={async () => {
              setConfirmProgress(true);
              try {
                await updateNetwork({
                  variables: {
                    input: {
                      isActive: false,
                    },
                  },
                  context: {
                    headers: { network: showArchiveConfirmationModal.id },
                  },
                });
                toastNotificationSuccessHook(
                  `Network "${showArchiveConfirmationModal.humanName}" has been successfully archived.`,
                );
                setNetworks(
                  networks.filter(
                    (n) => n.id !== showArchiveConfirmationModal.id,
                  ),
                );
              } catch (e) {
                toastNotificationErrorHook(
                  `Error archiving network ${showArchiveConfirmationModal.humanName}`,
                );
              }
              setConfirmProgress(false);
              setShowArchiveConfirmationModal(null);
            }}
            handleClose={() => setShowArchiveConfirmationModal(null)}
          />
        )}
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(NetworksPage);
