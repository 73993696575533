import { Clear } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chip from 'components/chip/chip';
import InputSelector from 'components/inputSelector/inputSelector';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  regularChip: {
    marginTop: '8px',
    marginRight: '8px',
  },
  text: {
    fontWeight: 400,
    lineHeight: '1.1875 rem',
    color: theme.palette.grey[500],
    marginTop: '24px',
  },
});

const SelectedChip = ({ tag, onDelete, sx }) => (
  <Chip
    label={tag}
    state="default"
    variant="secondary"
    clickableIcon={<Clear />}
    onIconClick={onDelete}
    sx={sx}
  />
);

SelectedChip.propTypes = {
  tag: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

SelectedChip.defaultProps = {
  sx: {},
};

const MultiSelectWithChips = ({
                                input: { onChange, value },
                                label,
                                errorMessage,
                                helperMessage,
                                selectedChipsLabel,
                                buttonLabel,
                                numericOnly,
                                sx,
                              }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Grid item container sx={sx}>
      <Grid item xs>
        <InputSelector
          label={label}
          value={Array.isArray(value) ? value : []}
          handleValueChange={onChange}
          errorMessage={errorMessage}
          helperMessage={helperMessage}
          buttonLabel={buttonLabel}
          numericOnly={numericOnly}
          includeExistingValues={true}
        />
        {selectedChipsLabel && value && value.length > 0 && (
          <Box sx={{ marginBottom: '12px' }}>
            {selectedChipsLabel && (
              <Typography variant="body1" sx={styles.text}>
                {selectedChipsLabel}
              </Typography>
            )}

            {value.map((tag) => (
              <SelectedChip
                key={`selected-chip-${tag}`}
                tag={tag}
                onDelete={(e) => {
                  const updatedTags = value.filter((t) => t !== tag);
                  onChange(updatedTags);
                }}
                sx={styles.regularChip}
              />
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

MultiSelectWithChips.propTypes = {
  buttonLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  helperMessage: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  numericOnly: PropTypes.bool,
  selectedChipsLabel: PropTypes.string,
  sx: PropTypes.object,
};

MultiSelectWithChips.defaultProps = {
  numericOnly: false,
  sx: {},
};

export default React.memo(MultiSelectWithChips);
