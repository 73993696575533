import { useMutation, useQuery } from '@apollo/client';
import { FlamingoContext } from 'contexts/flamingo';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import get from 'lodash/get';
import { useContext } from 'react';
import {
  FIND_NETWORK_DASHBOARD_CARDS,
  GET_NETWORK_CONFIGURATION_FEATURES,
  UPDATE_NETWORK_CONFIGURATION_FEATURES,
} from 'graphql/queries';

export const useNetworkFeatures = (networkId) => {
  const { handleError } = useHandleError('Features');
  return useQuery(GET_NETWORK_CONFIGURATION_FEATURES, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    context: { headers: { network: networkId } },
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNetworkFeatures = (networkId) => {
  const { handleError } = useHandleError('Features');
  const { toastNotificationErrorHook } = useToast();
  const { activeNetwork } = useContext(FlamingoContext);
  return useMutation(UPDATE_NETWORK_CONFIGURATION_FEATURES, {
    refetchQueries: [
      { query: GET_NETWORK_CONFIGURATION_FEATURES, variables: { networkId } },
      { query: FIND_NETWORK_DASHBOARD_CARDS, variables: { networkId } },
    ],
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    update: (cache, { data: { updateNetworkConfiguration } }) => {
      try {
        cache.writeQuery({
          query: UPDATE_NETWORK_CONFIGURATION_FEATURES,
          variables: { networkId },
          data: { updateNetworkConfiguration },
        });
        const networkFeatures = get(
          updateNetworkConfiguration,
          'features',
          {},
        );
        activeNetwork.setActiveNetwork({
          ...activeNetwork,
          configuration: {
            ...activeNetwork.configuration,
            features: {
              ...activeNetwork?.configuration?.features,
              ...networkFeatures,
            },
          },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
        toastNotificationErrorHook(`Error writing to cache: ${error}`);
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
