import { useMutation, useSuspenseQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import {
  CREATE_NETWORK_ICON,
  CREATE_NETWORK_PLACEHOLDER,
  GET_NETWORK_VISUAL_CONTROLS,
  UPDATE_NETWORK_ICON,
  UPDATE_NETWORK_PLACEHOLDER,
} from 'graphql/queries';

export const useNetworkVisualControls = (networkId) => {
  const { handleError } = useHandleError('Visual Controls');
  return useSuspenseQuery(GET_NETWORK_VISUAL_CONTROLS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    context: { headers: { network: networkId } },
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateNetworkPlaceholder = (networkId) => {
  const { handleError } = useHandleError('Visual Controls');
  return useMutation(CREATE_NETWORK_PLACEHOLDER, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNetworkPlaceholder = (networkId) => {
  const { handleError } = useHandleError('Visual Controls');
  return useMutation(UPDATE_NETWORK_PLACEHOLDER, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateNetworkIcon = (networkId) => {
  const { handleError } = useHandleError('Visual Controls');
  return useMutation(CREATE_NETWORK_ICON, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNetworkIcon = (networkId) => {
  const { handleError } = useHandleError('Visual Controls');
  return useMutation(UPDATE_NETWORK_ICON, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
