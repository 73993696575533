import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import CatChip from 'components/categories/catChip';
import SearchabilityModal from 'components/categories/searchabilityModal';
import EmptySection from 'components/emptySection/emptySection';
import { capitalize } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CategoryTagTable from './categoryTagTable';

const buildStyles = ({ theme }) => ({
  addButton: {
    position: 'absolute',
    right: 0,
    top: '-7px',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  text: {
    fontWeight: 400,
    lineHeight: '1.1875 rem',
    color: theme.palette.grey[500],
  },
});

const CategorySelection = ({
                             callToAction,
                             categoriesData,
                             excludeCategoryTags,
                             handleSaveTags,
                             input: { onChange, value },
                             sourceName,
                             locationCategories,
                             detailed,
                           }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [showCatModal, setShowCatModal] = useState(false);

  const modalSuccess = (updatedCats) => {
    onChange(updatedCats);
    setTimeout(() => {
      setShowCatModal(false);
    }, 1);
  };

  const deleteCategory = (id) => {
    const updatedCats = value.filter((cat) => cat.id !== id);
    onChange(updatedCats);
  };

  return (
    <>
      {value?.length > 0 && (
        <AddCircleButton
          onClick={() => {
            setShowCatModal(true);
          }}
          sx={styles.addButton}
          tooltipTitle="Add Categories"
        />
      )}
      <Grid item container>
        <Grid item xs>
          {value.length === 0 ? (
            <EmptySection
              title={
                callToAction
                || `There Are No Categories Applied to This ${
                  sourceName === 'poi' ? 'POI' : capitalize(sourceName)
                }`
              }
              buttonLabel={'Search & Apply Category(s)'}
              buttonClicked={() => setShowCatModal(!showCatModal)}
              hasPermission={true}
            />
          ) : detailed === false ? (
            <Box>
              <Typography variant="body1" sx={styles.text}>
                Selected Category(s)
              </Typography>
              {value.map((cat, idx) => (
                <CatChip
                  key={`selected-chip-${idx}-${cat.id}`}
                  category={cat}
                  deleteCategory={deleteCategory}
                  sx={{ marginTop: '10px' }}
                />
              ))}
            </Box>
          ) : (
            <CategoryTagTable
              data={categoriesData}
              deleteCategory={deleteCategory}
              excludeCategoryTags={excludeCategoryTags}
              handleSaveTags={handleSaveTags}
              locationCategories={locationCategories}
            />
          )}
        </Grid>
      </Grid>
      {showCatModal && (
        <SearchabilityModal
          setShowModal={setShowCatModal}
          handleSave={modalSuccess}
          defaultSelectedCategories={Array.isArray(value) ? value : []}
          categoriesData={categoriesData}
        />
      )}
    </>
  );
};

CategorySelection.propTypes = {
  categoriesData: PropTypes.array,
  callToAction: PropTypes.string,
  detailed: PropTypes.bool,
  excludeCategoryTags: PropTypes.array,
  handleSaveTags: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  locationCategories: PropTypes.array,
  sourceName: PropTypes.string,
};

CategorySelection.defaultProps = {
  categoriesData: [],
  callToAction: null,
  detailed: false,
  excludeCategoryTags: [],
  handleSaveTags: () => {},
  locationCategories: [],
  sourceName: 'site',
};

export default CategorySelection;
