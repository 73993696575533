import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AreaChart from 'components/analyticsDashboardCard/charts/areaChart';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import Loading from 'components/loading/loading';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import { DATE_FORMAT, retrieveAnalyticsFilter } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useAnalytics } from 'hooks/dataHooks/useAnalytics';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AnalyticsFilters, { ANALYTICS_FILTER_MAP } from './containers/analyticsFilters';
import AnalyticsDownloadButton from './containers/downloadButton';

const buildStyles = ({ theme }) => ({
  handleBack: {
    marginTop: '-16px !important',
  },
  loading: {
    height: '100%',
  },
  chartContainer: {
    background: theme.palette.white,
    borderRadius: '20px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    height: '546px',
    padding: '20px 20px 31px 20px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRange: {
    marginTop: '8px',
  },
  count: {
    fontSize: '34px',
    marginTop: '10px',
  },
  tableContent: {
    height: 'calc(100vh - 766px)',
    minHeight: '400px',
    marginTop: '12px',
    marginBottom: '20px',
    position: 'relative',
  },
  table: {
    borderRadius: '20px',
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
  },
  customTooltip: {
    background: theme.palette.grey[600],
    borderRadius: '4px',
    padding: '12px 12px',
    maxWidth: '250px',

    '& p': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: 0,
    },
  },
  tooltipContainer: {
    '& span': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'bold',
      margin: '8px 0 0 0',
    },
  },
  tooltipHeading: {
    color: theme.palette.white,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '8px 0 0 0',
    width: '48px',
  },
  emptyContainer: {
    height: '100%',
  },
  dot: {
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '6px',
    width: '16px',
    height: '16px',

    '&:not(:first-child)': {
      marginLeft: '6px',
    },
  },
  xAxisLabel: {
    color: theme.palette.grey[400],
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    marginLeft: '71px',
    marginTop: '1px',
    textAlign: 'center',
  },
});

export const TotalUsersPage = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const { filter, rangeStart, rangeEnd } = retrieveAnalyticsFilter();
  const [dateRangeKey, setDateRangeKey] = useState(null);
  const [dateRangeText, setDateRangeText] = useState(null);

  const backToOverview = useCallback(() => {
    handleNavigate(`/network/${networkId}/analytics`);
  }, [handleNavigate, networkId]);

  const { data: totalUsersData, fetch: fetchTotalUsersData } = useAnalytics({
    networkId,
    detailed: true,
    endpoint: 'activeusers',
  });

  const userRole = useCheckRole();
  const hasPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(userRole);

  const CustomTooltip = React.memo(({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload ?? {};
      return (
        <Box sx={styles.customTooltip}>
          <p>Total Users</p>
          <Box sx={styles.tooltipContainer}>
            <Box
              sx={{
                ...styles.dot,
                backgroundColor: ColorPalette.chart.green,
                marginBottom: '4px',
              }}
            />
            <Box sx={styles.tooltipHeading}>Returning Users</Box>
            <span>{data?.returningUsers?.toLocaleString()}</span>
          </Box>
          <Box sx={styles.tooltipContainer}>
            <Box
              sx={{
                ...styles.dot,
                backgroundColor: ColorPalette.chart.blue,
                marginBottom: '4px',
              }}
            />
            <Box sx={styles.tooltipHeading}>New Users</Box>
            <span>{data?.newUsers?.toLocaleString()}</span>
          </Box>
          <Box sx={styles.tooltipContainer}>
            <Box sx={styles.tooltipHeading}>Date</Box>
            <span>{data.fullDate}</span>
          </Box>
        </Box>
      );
    }

    return null;
  });

  const chartData = useMemo(
    () => totalUsersData?.total_users_by_day?.map(
        ({ day, returningUsers, newUsers, activeUsers }) => ({
          returningUsers,
          newUsers,
          activeUsers,
          date: dayjs(day).format('MMM D'),
          fullDate: dayjs(day).format('MMM D, YYYY'),
        }),
      ),
    [totalUsersData],
  );

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName={
          <Box>
            Total Users
            <Box
              sx={{
                display: 'inline-block',
                marginLeft: '8px',
                verticalAlign: 'middle',
              }}
            >
              <InfoTooltip
                title={
                  <Box>
                    New Users used the app for the first time within the date
                    range shown. Returning Users used the app at least once
                    prior to the date range shown.
                  </Box>
                }
              />
            </Box>
          </Box>
        }
        handleBack={() => backToOverview()}
        handleBackSx={styles.handleBack}
        handleBackTitle="Back to Analytics Overview"
        sx={styles.root}
        headerButtons={
          <AnalyticsFilters
            value={filter}
            start={rangeStart}
            end={rangeEnd}
            onChange={async ({ start, end, value }) => {
              if (dateRangeKey !== value || value === 'custom') {
                const diffDays = dayjs(end).diff(dayjs(start), 'day');
                setDateRangeKey(dateRangeText ?? value);
                if (value === 'custom') {
                  setDateRangeText(
                    `${dayjs(start).format(DATE_FORMAT)} - ${dayjs(end).format(
                      DATE_FORMAT
                    )}`
                  );
                } else {
                  setDateRangeText(null);
                }
                await fetchTotalUsersData({
                  start,
                  end,
                  points: diffDays < 14 ? diffDays : 14,
                });
              }
            }}
          />
        }
      >
        <Grid
          alignContent="flex-start"
          alignItems="flex-start"
          container
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            <Box sx={{ ...styles.chartContainer, width: `${width - 299}px` }}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">
                  Total Users Over Time
                </Typography>
                <AnalyticsDownloadButton
                  endpoint="activeusers"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!chartData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {chartData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  <Grid item>
                    <Typography variant="h3" sx={styles.count}>
                      {totalUsersData.total_active_users?.toLocaleString()}
                    </Typography>
                    <Typography variant="body2" sx={styles.dateRange}>
                      {dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginLeft: '40px', marginBottom: '10px' }}>
                    <Typography variant="h3" sx={styles.count}>
                      {totalUsersData.total_new_users?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Box
                          sx={{
                            ...styles.dot,
                            backgroundColor: ColorPalette.chart.blue,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">New Users</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: '40px', marginBottom: '10px' }}>
                    <Typography variant="h3" sx={styles.count}>
                      {totalUsersData.total_established_users?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Box
                          sx={{
                            ...styles.dot,
                            backgroundColor: ColorPalette.chart.green,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Returning Users</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AreaChart
                      data={chartData}
                      dataKey="activeUsers"
                      xAxisKey="date"
                      yAxisLabel="Number of Users"
                      yAxisKey="activeUsers"
                      width={width - 370}
                      height={370}
                      maxXAxisLabels={14}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip />}
                      linearData={[
                        {
                          dataKey: 'returningUsers',
                          color: ColorPalette.chart.green,
                        },
                        {
                          dataKey: 'newUsers',
                          color: ColorPalette.chart.blue,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Date
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(TotalUsersPage);
