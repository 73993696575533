import { IconButton, SpeedDialIcon, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  addIcon: {
    color: '#ffffff',
    backgroundColor: '#0D6AFF',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 700,
    height: '40px',
    width: '40px',
    lineHeight: 0,
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      backgroundColor: '#0845A6',
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[350],
      boxShadow: 'unset',
    },
  },
});

const AddCircleButton = ({ disabled = false, onClick, sx, tooltipTitle }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const buttonContent = tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <SpeedDialIcon />
    </Tooltip>
  ) : (
    <SpeedDialIcon />
  );

  return (
    <IconButton
      sx={{ ...styles.addIcon, ...sx }}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      size="large"
    >
      {buttonContent}
    </IconButton>
  );
};

AddCircleButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

AddCircleButton.defaultProps = {
  sx: {},
};

export default React.memo(AddCircleButton);
