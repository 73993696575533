// Image Spec: https://goziohealth.atlassian.net/wiki/spaces/EN/pages/693272592/Flamingo+Standards+Conventions
export const MINIMUM_IMAGE_WIDTH = 1280;
export const MINIMUM_IMAGE_HEIGHT = 720;

export const convertBytesToMbsOrKbs = (filesize) => {
  if (filesize >= 1000000) {
    return `${filesize / 1000000.0} megabytes`;
  }

  if (filesize >= 1000) {
    return `${filesize / 1000.0} kilobytes`;
  }

  return `${filesize} bytes`;
};

export const getYouTubeIdFromUrl = (youTubeUrl) => {
  const match = youTubeUrl.match(
    /(youtube.com|youtu.be)\/(watch)?(\?v=)?([^&]+)?.*/,
  );
  return match?.[4] ?? null;
};

export const getYouTubeThumbnail = (youTubeId) => `https://img.youtube.com/vi/${youTubeId}/mqdefault.jpg`;

export const getYoutTubeVideoLinkName = (youTubeUrl) => {
  if (youTubeUrl) {
    const index = youTubeUrl.indexOf('/watch');
    if (index !== -1) {
      return `${youTubeUrl.substring(0, index)}/\n${youTubeUrl.substring(
        index + 1,
      )}`;
    }

    return youTubeUrl;
  }

  return null;
};

const convertToBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.onload = function(e) {
    callback(null, e.target.result);
  };
  reader.onerror = function(e) {
    callback(e);
  };
  reader.readAsDataURL(file);
};

const getScaledImageDimensions = ({
                                    imageWidth,
                                    imageHeight,
                                    minWidth,
                                    maxWidth,
                                    minHeight,
                                    maxHeight,
                                  }) => {
  let width = imageWidth;
  let height = imageHeight;

  const hasMinSize
    = typeof minWidth !== 'undefined' && typeof minHeight !== 'undefined';
  const hasMaxSize
    = typeof maxWidth !== 'undefined' && typeof maxHeight !== 'undefined';

  if (!hasMinSize && !hasMaxSize) {
    return { width: imageWidth, height: imageHeight };
  }

  if (minWidth === minHeight && width !== height) {
    if (width > height) {
      width = height;
    } else {
      height = width;
    }
  }

  let ratio;
  if (hasMaxSize) {
    ratio = hasMinSize
      ? Math.min(width / minWidth, height / minHeight)
      : Math.min(width / maxWidth, height / maxHeight);
  } else {
    ratio = Math.min(minWidth / width, minHeight / height);
  }

  if (ratio !== 1) {
    width *= ratio;
    height *= ratio;
  }

  if (width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  } else if (height > maxHeight) {
    width *= maxHeight / height;
    height = maxHeight;
  }

  // Stretch as a last resort
  if (width < minWidth) {
    width = minWidth;
  }
  if (width > maxWidth) {
    width = maxWidth;
  }
  if (height < minHeight) {
    height = minHeight;
  }
  if (height > maxHeight) {
    height = maxHeight;
  }

  return {
    width: Math.round(width),
    height: Math.round(height),
  };
};

const resizeImage = ({
                       image,
                       minWidth,
                       minHeight,
                       maxWidth,
                       maxHeight,
                       onError,
                       callback,
                     }) => {
  convertToBase64(image, (err, base64Image) => {
    if (err) {
      /// handle error
      return;
    }

    const img = new Image();
    img.src = base64Image;
    const canvas = document.createElement('canvas');
    const { width, height } = getScaledImageDimensions({
      imageWidth: img.width,
      imageHeight: img.height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
    });

    if (
      (minWidth && width < minWidth)
      || (minHeight && height < minHeight)
      || (maxWidth && width > maxWidth)
      || (maxHeight && height > maxHeight)
    ) {
      onError(
        `File ${image.name} was rejected because of invalid file dimensions.`,
      );
    } else {
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      callback(canvas.toDataURL());
    }
  });
};

export const handleOnDrop = (
  files,
  onSuccess,
  onError,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  onImageResized,
) => {
  if (files.length === 0) {
    onError('Please upload an image.');
  }

  const successFiles = [];
  let count = 0;
  files.forEach((file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = async () => {
        if (
          (minWidth && img.width < minWidth)
          || (minHeight && img.height < minHeight)
          || (maxWidth && img.width > maxWidth)
          || (maxHeight && img.height > maxHeight)
        ) {
          try {
            resizeImage({
              image: file,
              minWidth,
              minHeight,
              maxWidth,
              maxHeight,
              onError,
              callback: (img) => onImageResized(img),
            });
          } catch (err) {
            onError(`File ${file.name} was rejected: ${err}`);
          }
        } else {
          successFiles.push({ file, data: reader.result, url: img.src });
        }

        count += 1;
        if (count === files.length) {
          if (successFiles.length > 0) {
            onSuccess(successFiles);
          }
        }
      };

      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  });
};

export const handleDropRejected = (
  acceptedFiles,
  rejectedFiles,
  minFileSize,
  maxFileSize,
  toastNotificationError,
) => {
  rejectedFiles.forEach((rejectedFile) => {
    if (rejectedFile.errors) {
      rejectedFile.errors.forEach((error) => {
        toastNotificationError(error.message);
      });
    }
  });
};

export const softDeleteImage = async (imageId) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token') || '';

  return await fetch(`${baseUrl}/image/${imageId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const softDeleteMedia = async (mediaId) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token') || '';

  return await fetch(`${baseUrl}/media/${mediaId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const validateYouTubeUrl = (url) => {
  if (!url) {
    return true;
  }
  return url.match(/^$|(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/);
};

export const isDefaultImageMatchesMediaItem = (defaultImage, mediaItem) => {
  if (!defaultImage || !mediaItem?.image) {
    return false;
  }

  if (mediaItem.type === 'video') {
    return false;
  }

  if (defaultImage.id) {
    return defaultImage.id === mediaItem.image.id;
  }
  return true;
};
