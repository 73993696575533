import { gql } from '@apollo/client';

export const FIND_DATA_PACKAGE = gql`
  query findDataPackage($workspaces: AutoGraphMixed, $limit: Int, $skip: Int, $sortBy: DataPackageInputSort) {
    findDataPackage(
      where: { networkChannels: { workspaces: { name: $workspaces } } }
      sortBy: $sortBy
      limit: $limit
      skip: $skip
    ) {
      count
      edges {
        node {
          id
          externalId
          createdAt
          createdBy {
            profile {
              firstName
              lastName
            }
          }
          state
          message
          revertible
          revertMessage
          revertedAt
          revertedBy {
            profile {
              firstName
              lastName
            }
          }
          networkChannels {
            id
            label
          }
          zipUrl
        }
      }
    }
  }
`;

export const GET_DATA_PACKAGE = gql`
  query ($workspaces: AutoGraphMixed, $id: AutoGraphMixed, $limit: Int) {
    findDataPackage(
      where: { networkChannels: { workspaces: { name: $workspaces } }, id: $id }
      limit: $limit,
      sortBy: { externalId: desc },
    ) {
      edges {
        node {
          id
          createdAt
          createdBy {
            profile {
              firstName
              lastName
            }
          }
          externalId
          message
          network {
            id
          }
          progress
          state
          networkChannels {
            id
            label
            workspaces {
              id
              name
            }
          }
          errorDetails {
            publishingError {
              bundle
              group
              dataFacet {
                type
                item {
                  ... on Category {
                    id
                    CategoryName: name
                  }
                  ... on Network {
                    Network: name
                  }
                  ... on NetworkPlace {
                    id
                    NetworkPlaceName: name
                    designation
                  }
                  ... on NetworkTypeahead {
                    id
                    NetworkTypeaheadName: label
                  }
                  ... on SystemTypeahead {
                    id
                    SystemTypeaheadName: label
                  }
                  ... on NetworkCuratedList {
                    id
                    NetworkCuratedListName: name
                  }
                  ... on NetworkDashboardCard {
                    id
                    NetworkDashboardCardName: title
                  }
                  ... on NetworkExplorePanel {
                    id
                    NetworkExplorePanelName: name
                  }
                  ... on NetworkNavigation {
                    id
                  }
                  ... on Kiosk {
                    id
                    KioskName: name
                  }
                }
              }
            }
            error {
              message
            }
          }
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_DATA_PACKAGE = gql`
  subscription {
    DataPackage(
      on: [create, update, delete]
    ) {
      event {
        data {
          state
          progress
          network
          id
        }
      }
    }
  }
`;

export const UPDATE_DATA_PACKAGE = gql`
  mutation updateDataPackage(
    $dataPackageId: ID!
    $input: DataPackageUpdateInput!
    $meta: DataPackageMetaInput
  ) {
    updateDataPackage(id: $dataPackageId, input: $input, meta: $meta) {
      id
      state
      isBad
      externalId
      zipUrl
      progress
      message
      createdAt
      modifiedAt
      errorDetails {
        error {
          message
        }
      }
    }
  }
`;
