import { Clear } from '@mui/icons-material';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import Chip from 'components/chip/chip';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = () => ({
  infoText: {
    float: 'right',
  },
  selectChip: {
    marginRight: '8px',
  },
});

const regEx = /^\d+$/;

const InputSelector = ({
                         label,
                         value,
                         handleValueChange,
                         options,
                         errorOnDups,
                         errorMessage,
                         helperMessage,
                         buttonLabel,
                         numericOnly,
                         includeExistingValues,
                       }) => {
  const styles = buildStyles();
  const [textEntry, setTextEntry] = useState('');
  const [tempTags, setTempTags] = useState([]);
  const [helperMessageOverride, setHelperMessageOverride] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (includeExistingValues) {
      setTempTags(value ?? []);
    }
  }, [includeExistingValues, value]);

  const checkErrors = (text) => {
    let errMsg = null;
    if (text && text.length > 0) {
      if (numericOnly && !regEx.test(text)) {
        errMsg = 'Valid values must be numeric only.';
      }
      if (errorOnDups && (value.includes(text) || tempTags.includes(text))) {
        errMsg = errorMessage;
      }
    }
    setError(errMsg);
  };

  const handleAddTempTags = (text) => {
    const tempText = `${textEntry || text}`.split(',');
    if ((error && !text) || (textEntry?.trim()?.length === 0 && !text)) {
      return;
    }
    if (tempText?.length > 0) {
      const tokens = [...new Set(tempText)];
      const tagsToAdd = tokens.filter(
        (tag) => !value.includes(tag)
          && !tempTags.includes(tag)
          && (!numericOnly || regEx.test(tag))
          && tag.length > 0,
      );
      const tagsExcluded = tempText.filter((tag) => value.includes(tag));
      const tagsInvalidated = tokens.filter(
        (tag) => numericOnly && !regEx.test(tag),
      );
      const updatedTempTags = [...tempTags, ...tagsToAdd];
      setTempTags(updatedTempTags);
      handleValueChange(updatedTempTags);
      if (text) {
        handleAddTags(null, updatedTempTags);
        let helperMsg = null;
        if (tagsExcluded.length) {
          helperMsg = `The following items were not added because they already exist: ${tagsExcluded.join(
            ', ',
          )}`;
        }
        if (tagsInvalidated.length) {
          helperMsg = `${
            helperMsg ? `${helperMsg}. ` : ''
          }The following items were not added because they are not valid: ${tagsInvalidated.join(
            ', ',
          )}.`;
        }
        if (helperMsg) setHelperMessageOverride(helperMsg);
      } else {
        setTextEntry('');
      }
    }
  };

  const handleAddTags = (e, tags) => {
    const tagsToAdd = tags || tempTags;
    if (error && !tags) {
      return;
    }
    const newTags = [...new Set([...value, ...tagsToAdd])];
    if (textEntry.length > 0) newTags.push(textEntry);
    handleValueChange(newTags);
    setTempTags([]);
    setTextEntry('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === ',') {
      e.preventDefault();
      setTextEntry('');
      handleAddTempTags();
    } else if (e.key === 'Enter') {
      handleTextChange('');
    }
  };

  const handleTextChange = (text) => {
    setTextEntry(text);
    if (text.includes(',')) {
      setError(null);
      handleAddTempTags(text);
    } else {
      checkErrors(text);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        <Autocomplete
          multiple
          options={options}
          fullWidth
          freeSolo
          value={tempTags}
          onChange={(e, newVal) => {
            setHelperMessageOverride('');
            if (!newVal.length) {
              setError(null);
            }
            if (!error) {
              setTempTags(newVal);
              setTextEntry('');
              setError(null);
              if (!buttonLabel) {
                handleValueChange(newVal);
              }
            }
          }}
          renderTags={(value, getTagProps) => value.map((option, index) => {
              const { onDelete, ...rest } = getTagProps({ index });
              return (
                <Chip
                  label={option}
                  state={'default'}
                  variant={'secondary'}
                  clickableIcon={<Clear />}
                  onIconClick={onDelete}
                  sx={styles.selectChip}
                  {...rest}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                value: textEntry,
              }}
              variant="outlined"
              label={label}
              value={textEntry}
              error={!!(error && error.length > 0)}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleTextChange(e.target.value)}
              helperText={error}
            />
          )}
        />
        {!error && (
          <Typography sx={styles.infoText} variant="caption">
            {helperMessageOverride || helperMessage}
          </Typography>
        )}
      </Box>
      {buttonLabel && (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleAddTags}
          disabled={
            !!error || (textEntry.length === 0 && tempTags.length === 0)
          }
          sx={{ marginLeft: '12px', marginTop: '7px' }}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

InputSelector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  errorOnDups: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
  numericOnly: PropTypes.bool,
  includeExistingValues: PropTypes.bool,
};

InputSelector.defaultProps = {
  options: [],
  errorOnDups: true,
  errorMessage: 'You have items with the same name.',
  helperMessage: 'Put a comma in between each item to add multiple items.',
  buttonLabel: null,
  numericOnly: false,
  includeExistingValues: false,
};

export default InputSelector;
