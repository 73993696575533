import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loading from 'components/loading/loading';
import FlamingoAppBar from 'components/navigation/flamingoAppBar';
import { FlamingoContext } from 'contexts/flamingo';
import { isGlobalTemplate } from 'helpers/network-util';
import { MINIMUM_SCREEN_HEIGHT, MINIMUM_SCREEN_WIDTH } from 'helpers/page-util';
import PageHelpers from 'helpers/pageHelpers';
import { ALL_WORKSPACES } from 'helpers/workspace-util';
import useWindowSize from 'hooks/useWindowSize';
import { UnsupportedResolution } from 'pages/layouts/unsupportedResolution';
import PropTypes from 'prop-types';
import React, { Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  content: {
    background: theme.palette.grey[50],
    flexGrow: 1,
    height: 'calc(100vh - 70px)',
    overflow: 'auto',
    padding: theme.spacing(3),
    position: 'relative',
  },
  openSideBar: {
    marginLeft: '250px',
    width: 'calc(100vw - 251px)',
  },
});

export const LoggedinLayout = ({
                                 enableSuspense,
                                 loading = false,
                                 showWorkspaceSwitcher,
                                 supportedWorkspace,
                                 sx,
                                 children,
                               }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const {
    activeNetwork,
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const { networkId } = useParams();
  const { width, height } = useWindowSize();

  const isGlobal = isGlobalTemplate(networkId);
  const showUnsupportedResolution = process.env
    .REACT_APP_HIDE_UNSUPPORTED_RESOLUTION
    ? process.env.REACT_APP_HIDE_UNSUPPORTED_RESOLUTION === 'false'
    : true;

  if (
    showUnsupportedResolution
    && (width < MINIMUM_SCREEN_WIDTH || height < MINIMUM_SCREEN_HEIGHT)
  ) {
    return <UnsupportedResolution />;
  }

  const renderContent = () => {
    if (loading || !activeNetwork?.id) {
      return <Loading />;
    }

    if (enableSuspense) {
      return <Suspense fallback={<Loading />}>{children}</Suspense>;
    }

    return children;
  };

  return (
    <>
      <FlamingoAppBar networkId={networkId} />
      <Container
        key={networkId}
        component="main"
        disableGutters
        maxWidth={false}
        sx={{
          ...styles.content,
          ...openDrawer && styles.openSideBar,
          ...sx,
        }}
      >
        <PageHelpers
          showWorkspaceSwitcher={showWorkspaceSwitcher && !isGlobal}
          supportedWorkspace={supportedWorkspace}
        >
          {renderContent()}
        </PageHelpers>
      </Container>
    </>
  );
};

LoggedinLayout.propTypes = {
  children: PropTypes.any,
  enableSuspense: PropTypes.bool,
  loading: PropTypes.bool,
  showWorkspaceSwitcher: PropTypes.bool,
  supportedWorkspace: PropTypes.oneOf(['all', 'live', 'draft']),
  sx: PropTypes.object,
};

LoggedinLayout.defaultProps = {
  enableSuspense: false,
  loading: false,
  showWorkspaceSwitcher: true,
  supportedWorkspace: ALL_WORKSPACES,
  sx: {},
};

export default LoggedinLayout;
