import { gql } from '@apollo/client';

export const CREATE_NETWORK_DASHBOARD_CARD_GROUP = gql`
  mutation createNetworkDashboardCardGroup($input: CreateNetworkDashboardCardGroupInput!) {
    createNetworkDashboardCardGroup(input: $input) {
      id
      cards {
        id
        backgroundColor
        buttonText
        cardType
        dismissable
        displayType
        expires
        iconText
        iconUrl
        iconUrl2
        measurements {
          height
        }
        overline
        subtitle
        subtitle2
        textAlignment
        textColor
        title
        waitTimes {
          included
          showWhenCollapsed
        }
        visibilityRule
        validation {
          status
          errors {
            message
            path
          }
        }
        sortingOptions {
          type
          title
        }
      }
    }
  }
`;

export const DELETE_NETWORK_DASHBOARD_CARD_GROUP = gql`
  mutation deleteNetworkDashboardCardGroup($input: DeleteNetworkDashboardCardGroupInput!) {
    deleteNetworkDashboardCardGroup(input: $input) {
      id
    }
  }
`;

export const FIND_NETWORK_DASHBOARD_CARDS = gql`
  query findNetworkDashboardsByNetworkId($networkId: ID!) {
    findNetworkDashboardsByNetworkId(id: $networkId) {
      id
      cardGroups {
        id
        type
        cards {
          id
          backgroundColor
          backgroundImageUrl
          buttonText
          cardName
          dismissable
          displayType
          expires
          iconText
          iconUrl
          iconUrl2
          linkData {
            type
            categories {
              id
              name
            }
            data
          }
          measurements {
            height
          }
          myChartUrl
          overline
          subtitle
          subtitle2
          textAlignment
          textColor
          title
          waitTimes {
            included
            label
            showWhenCollapsed
          }
          locations {
            allUrgentCare
            allEmergencyDepartments
            custom {
              sites {
                id
                name
                geoLocation {
                  address {
                    street
                    city
                    state
                    zip
                  }
                }
              }
              places {
                id
                name
                name
                geoLocation {
                  address {
                    street
                    city
                    state
                    zip
                  }
                }
                parentSite {
                  name
                }
                parentBuilding {
                  name
                }
                parentFloor {
                  name
                }
              }
              buildings {
                id
                name
                parentSite {
                  id
                  name
                }
              }
            }
          }
          module {
            moduleId
            data
          }
          quickLinksData {
            id
            data
            name
            iconUrl
            iconTint
            url
            type
            categories {
              id
              name
            }
          }
          sortingOptions {
            type
            title
          }
          validation {
            status
            errors {
              message
              path
            }
          }
          visibilityRule
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_DASHBOARD = gql`
  mutation updateNetworkDashboard($input: UpdateNetworkDashboardInput!) {
    updateNetworkDashboard(input: $input) {
      id
    }
  }
`;

export const UPDATE_NETWORK_DASHBOARD_CARD = gql`
  mutation($id: ID!, $input: NetworkDashboardCardInputUpdate!) {
    updateNetworkDashboardCard(id: $id, input: $input) {
      id
      title
    }
  }
`;

export const UPDATE_NETWORK_DASHBOARD_CARD_MYCHART = gql`
  mutation($loginCardId: ID!, $login: NetworkDashboardCardInputUpdate!, $quicklinksCardId: ID!, $quicklinks: NetworkDashboardCardInputUpdate!) {
    login: updateNetworkDashboardCard(id: $loginCardId, input: $login) {
      id
      title
      overline
      subtitle
      validation {
        status
        errors {
          message
          path
        }
      }
      visibilityRule
    }
    quicklinks: updateNetworkDashboardCard(id: $quicklinksCardId, input: $quicklinks) {
      id
      title
      overline
      subtitle
      validation {
        status
        errors {
          message
          path
        }
      }
      quickLinksData {
        id
        name
        iconUrl
        url
      }
      visibilityRule
    }
  }
`;

export const UPDATE_NETWORK_DASHBOARD_CARD_MY_FAVORITES = gql`
  mutation($emptyCardId: ID!, $empty: NetworkDashboardCardInputUpdate!, $activeCardId: ID!, $active: NetworkDashboardCardInputUpdate!) {
    empty: updateNetworkDashboardCard(id: $emptyCardId, input: $empty) {
      id
      cardType
      title
      subtitle
      buttonText
      iconUrl
      backgroundColor
      textColor
      visibilityRule
      validation {
        status
        errors {
          message
          path
        }
      }
    }
    active: updateNetworkDashboardCard(id: $activeCardId, input: $active) {
      id
      cardType
      title
      backgroundColor
      textColor
      visibilityRule
    }
  }
`;
