import { Box, Grid, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import TabPanel from 'components/tabPanel/tabPanel';
import dayjs from 'dayjs';
import { getFormLabel } from 'helpers/form-util';
import { Checkboxes } from 'mui-rff';
import {
  Condition,
  getDateHelperText,
  renderFields,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

export const GeneralTabPanel = ({
                                  cardGroupType,
                                  sx,
                                  dismissable,
                                  expires,
                                  hasWaitTimes,
                                  index,
                                  languages = [],
                                  size,
                                  tabValue,
                                }) => (
  <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
    <Box sx={sx.fieldArea}>
      <Grid item xs>
        <Typography sx={sx.displayHeading} variant="h3">
          General
        </Typography>
      </Grid>
      <Grid item xs sx={sx.formFields}>
        {renderFields({
          cardGroupType,
          sx,
          hasWaitTimes,
          languages,
          size,
        })}
      </Grid>
    </Box>
    {hasWaitTimes && (
      <Box sx={sx.optionArea}>
        <Grid item xs>
          <Typography variant="subtitle1">
            Wait Time Card Preferences
          </Typography>
        </Grid>
        <Grid item xs sx={sx.optionFields}>
          <Field name="waitTimesShowWhenCollapsed" type="checkbox">
            {({ input }) => (
              <Checkboxes
                name="waitTimesShowWhenCollapsed"
                data={[{ label: 'Show Wait Times When Collapsed' }]}
                color="primary"
                {...input}
              />
            )}
          </Field>
        </Grid>
      </Box>
    )}
    {(dismissable || !!expires) && (
      <Box sx={sx.optionArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            Temporary Card Preferences
          </Typography>
        </Grid>
        <Grid item xs sx={sx.optionFields}>
          <Field name="dismissable" type="checkbox">
            {({ input }) => (
              <Checkboxes
                name="dismissable"
                data={[{ label: 'Dismissable' }]}
                color="primary"
                {...input}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs sx={{ alignItems: 'center', display: 'flex' }}>
          <Field name="expiresFlag" type="checkbox">
            {({ input }) => (
              <Checkboxes
                name="expiresFlag"
                data={[{ label: 'Expires on' }]}
                color="primary"
                {...input}
              />
            )}
          </Field>
          <Box sx={{ display: 'flex', marginLeft: '-10.5px' }}>
            <InfoTooltip title="The Temporary Card will expire at the end of the day selected for “Expires On”." />
          </Box>
        </Grid>
        <Condition when="expiresFlag" is={true}>
          <Grid item xs>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Field
                name="expires"
                validate={(value) => value ? undefined : 'Required'}
                dateFormat="MM/dd/yyyy"
              >
                {({ input: { onChange, value } }) => (
                  <DatePicker
                    name="expires"
                    sx={sx.datePicker}
                    disablePast={true}
                    label={getFormLabel('Select Day', true)}
                    value={dayjs(value)}
                    onChange={(date) => onChange(
                        date
                          ? new Date(
                            date.year(),
                            date.month(),
                            date.date(),
                            23,
                            59,
                            59,
                          )
                          : null,
                      )
                    }
                    slotProps={{
                      textField: {
                        helperText: getDateHelperText(value),
                      },
                    }}
                  />
                )}
              </Field>
            </LocalizationProvider>
          </Grid>
        </Condition>
      </Box>
    )}
  </TabPanel>
);

GeneralTabPanel.propTypes = {
  cardGroupType: PropTypes.string.isRequired,
  sx: PropTypes.object,
  dismissable: PropTypes.bool,
  expires: PropTypes.any,
  hasWaitTimes: PropTypes.bool,
  index: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  tabValue: PropTypes.number,
};

GeneralTabPanel.defaultProps = {
  sx: {},
  index: 0,
  tabValue: 0,
};
