import { Lock } from '@mui/icons-material';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoPanel from 'components/infoPanel/infoPanel';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    padding: theme.spacing(3),
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    '& > p': {
      marginTop: theme.spacing(1),
    },
  },
  container: {
    gap: theme.spacing(2),
  },
  item: {
    alignItems: 'center',
    flexBasis: 'max-content !important',
    flexGrow: '1 !important',
  },
  publishButton: {
    display: 'inline-block',
    flexBasis: 'content',
    minWidth: '96px',
    textAlign: 'right',

    '& button': {
      letterSpacing: 'revert',
    },
  },
});

const Publish = ({
                   handlePublishButtonClick,
                   hasKioskChannel,
                   isReady,
                   locationsComplete,
                   noChannelsInSync,
                   publishingInProgress,
                   workspace,
                   enabled,
                 }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [subHeadingText, setSubHeadingText] = useState(null);
  const [bodyText, setBodyText] = useState(null);
  const [infoPanelText, setInfoPanelText] = useState(null);
  const publishButtonWidth = enabled ? 88 : 227;

  useEffect(() => {
    if (!enabled) {
      if (workspace === DRAFT_WORKSPACE) {
        setSubHeadingText('Publish the Network’s Data in the Draft Workspace');
      } else {
        setSubHeadingText('Publish the Network’s Data in the Live Workspace');
      }
      setBodyText(null);
      setInfoPanelText(
        <span>
          You are not able to publish at this time.{' '}
          <Link href="mailto:support@goziohealth.com">Email Gozio Support</Link>{' '}
          for help.
        </span>,
      );
      return;
    }

    // User is in the draft workspace
    if (workspace && workspace === DRAFT_WORKSPACE) {
      setSubHeadingText('Publish the Network’s Data in the Draft Workspace');

      // Both workspaces have complete data
      if (isReady[LIVE_WORKSPACE] && isReady[DRAFT_WORKSPACE]) {
        // No channels in sync
        if (noChannelsInSync) {
          setBodyText(
            'Both workspaces are complete. You are ready to publish.',
          );
          setInfoPanelText(
            'New information has been added/updated on the network. Publish again to keep the channels up to date.',
          );
        } else {
          // At least one channel in sync
          setBodyText('Both workspaces are complete. You are able to publish.');
          setInfoPanelText(null);
        }
      } else if (isReady[DRAFT_WORKSPACE] && !isReady[LIVE_WORKSPACE]) {
        // Draft has complete data, live does not
        // Same regardless of channel status
        setBodyText(null);
        setInfoPanelText(
          'You cannot publish until all the network data has been completed. Access the Live Workspace to finalize any incomplete work.',
        );
      } else if (!isReady[DRAFT_WORKSPACE] && isReady[LIVE_WORKSPACE]) {
        // Draft incomplete, live complete
        setBodyText(null);
        setInfoPanelText(
          'You cannot publish until all the network data has been completed in the Draft Workspace.',
        );
      } else if (!isReady[DRAFT_WORKSPACE] && !isReady[LIVE_WORKSPACE]) {
        // Neither Complete
        setBodyText(null);
        setInfoPanelText(
          'You cannot publish until all the network data has been completed in both workspaces.',
        );
      }
    } else if (workspace && workspace === LIVE_WORKSPACE) {
      setSubHeadingText('Publish the Network’s Data in the Live Workspace');
      if (!isReady[LIVE_WORKSPACE]) {
        // In live, if there is a kiosk channel and locations are complete, the user can publish to the kiosk channel
        if (locationsComplete && hasKioskChannel) {
          setBodyText(null);
          setInfoPanelText(
            'The Kiosk Live Channel can be published. All other channels require additional information.',
          );
        } else {
          setBodyText(null);
          setInfoPanelText(
            'You cannot publish until all the network data has been completed in the Live Workspace.',
          );
        }
      } else if (enabled) {
        setBodyText('The Live Workspace is complete. You are able to publish.');
        setInfoPanelText(null); // At least one channel in sync
        // No channels in sync
        if (noChannelsInSync) {
          setInfoPanelText(
            'New information has been added/updated on the network. Publish again to keep the channels up to date.',
          );
        }
      }
    }

    if (enabled && publishingInProgress !== null) {
      setBodyText(null);
      setInfoPanelText('Publishing in progress. System processing...');
    }
  }, [
    enabled,
    isReady,
    hasKioskChannel,
    locationsComplete,
    noChannelsInSync,
    publishingInProgress,
    setBodyText,
    setInfoPanelText,
    setSubHeadingText,
    workspace,
  ]);

  const readyToPublish = useMemo(() => {
    if (publishingInProgress !== null) {
      return false;
    }

    if (workspace === DRAFT_WORKSPACE) {
      return isReady[LIVE_WORKSPACE] && isReady[DRAFT_WORKSPACE];
    }

    return isReady[LIVE_WORKSPACE]
      ? true
      : locationsComplete && hasKioskChannel;
  }, [
    hasKioskChannel,
    isReady,
    locationsComplete,
    publishingInProgress,
    workspace,
  ]);

  const content = useMemo(() => {
    if (bodyText && !infoPanelText) {
      return (
        <>
          <Grid item xs={9} sx={styles.item}>
            <Typography variant="subtitle1">{subHeadingText}</Typography>
            <Typography
              variant="body1"
              sx={{
                display: infoPanelText ? 'block' : 'inline-flex',
                marginRight: '16px',
                marginTop: '6px',
                width: `calc(100% - ${publishButtonWidth + 16}px)`,
              }}
            >
              {bodyText}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              ...styles.item,
              textAlign: 'right',
            }}
          >
            <Button
              startIcon={enabled ? undefined : <Lock />}
              variant="contained"
              color="primary"
              onClick={() => handlePublishButtonClick({})}
              data-test="Publish-button"
              disabled={!readyToPublish || !enabled}
            >
              {enabled ? 'Publish' : 'Publishing Is Disabled'}
            </Button>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{subHeadingText}</Typography>
        </Grid>
        {!!bodyText && (
          <Grid
            item
            xs={12}
            sx={{
              ...styles.item,
              ...infoPanelText
                || (publishingInProgress !== null && {
                  marginTop: '-12px',
                }),
            }}
          >
            <Typography
              variant="body1"
              sx={{
                display:
                  infoPanelText || publishingInProgress !== null
                    ? 'block'
                    : 'inline-flex',
                marginRight: '16px',
                marginTop: 0,
                width: `calc(100% - ${publishButtonWidth + 16}px)`,
              }}
            >
              {bodyText}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            ...publishingInProgress !== null && { alignItems: 'end' },
          }}
        >
          {(infoPanelText || publishingInProgress !== null) && (
            <InfoPanel
              sx={{
                marginRight: '16px',
                marginTop: 0,
                padding: '4px 12px',
                verticalAlign: 'middle',
                width: `calc(100% - ${publishButtonWidth + 16}px)`,
              }}
              title={infoPanelText}
              progress={publishingInProgress}
            />
          )}
          <Box sx={styles.publishButton}>
            <Button
              startIcon={enabled ? undefined : <Lock />}
              variant="contained"
              color="primary"
              onClick={() => handlePublishButtonClick({})}
              data-test="Publish-button"
              disabled={!readyToPublish || !enabled}
            >
              {enabled ? 'Publish' : 'Publishing Is Disabled'}
            </Button>
          </Box>
        </Grid>
      </>
    );
  }, [
    bodyText,
    styles,
    enabled,
    handlePublishButtonClick,
    infoPanelText,
    publishButtonWidth,
    publishingInProgress,
    readyToPublish,
    subHeadingText,
  ]);

  return (
    <Paper sx={styles.root}>
      <Grid
        container
        direction="row"
        sx={{
          ...styles.container,
          alignItems:
            (isReady[LIVE_WORKSPACE] || isReady[DRAFT_WORKSPACE])
            && !infoPanelText
              ? 'center'
              : 'flex-end',
        }}
      >
        {content}
      </Grid>
    </Paper>
  );
};

Publish.propTypes = {
  enabled: PropTypes.bool,
  handlePublishButtonClick: PropTypes.func,
  hasKioskChannel: PropTypes.bool,
  isReady: PropTypes.object,
  locationsComplete: PropTypes.bool,
  noChannelsInSync: PropTypes.bool,
  publishingInProgress: PropTypes.number,
  workspace: PropTypes.string,
};

export default React.memo(Publish);
