import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import FormSubSection from 'components/forms/formSubSection';
import TextField from 'components/textField/textField';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import { getFormLabel } from 'helpers/form-util';
import { SecureUrlRegexp } from 'helpers/string-util';
import { TextField as FinalTextField } from 'mui-rff';
import { generateRandomString } from 'pages/deepLinks/deeplinkHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

export const DEEP_LINK_LINK_NAME_SCHEMA = {
  linkName: Yup.string().required('Link Title is required'),
  shortUrlDomain: Yup.string().required('Link Domain is required'),
  linkAlias: Yup.string()
    .required('Link Alias is required')
    .max(50, 'Link Alias must be 50 characters or less'),
  webUrl: Yup.string()
    .nullable()
    .matches(
      SecureUrlRegexp,
      'URL must be https, e.g. https://www.goziohealth.com',
    ),
};

export const buildLinkNameInitialValues = ({ config, data }) => ({
  desktopDeepview: data?.isDesktopDeepview ?? config?.desktopDeepview,
  linkName: data?.linkName,
  linkAlias: data?.linkAlias ?? generateRandomString(10),
  shortUrlDomain: config.shortUrlDomain,
  webUrl: data?.webUrl,
  deepLinkActionUrl: data?.linkData?.gozioLink,
});

export const buildLinkNameUpdateInput = ({ values }) => ({
  isDesktopDeepview: values.desktopDeepview,
  linkName: values.linkName,
  linkAlias: values.linkAlias,
  webUrl: values.webUrl,
  linkData: {
    gozioLink: values.deepLinkActionUrl,
  },
});

const LinkNameSection = ({ data }) => (
  <FormSubSection name="Name Your Link">
    <Grid container direction="row" spacing={4.5}>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <Typography variant="body1">
          Begin by naming your link and creating an easily memorable alias for
          the link. This link name will surface in the Link Manger table for you
          and your team.
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <FinalTextField
          label={getFormLabel('Link Title', true)}
          name="linkName"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <FinalTextField
          label={getFormLabel('Link Domain', true)}
          name="shortUrlDomain"
          variant="outlined"
          disabled={true}
        />
      </Grid>
      <Grid item xs={3} sx={{ marginRight: '50%' }}>
        <Field name="linkAlias">
          {({ input, meta }) => (
            <TextField
              label={getFormLabel('Link Alias', true)}
              charLimit={50}
              helperText={meta.touched ? meta.error : ''}
              error={meta.touched && !!meta.error}
              {...input}
              fullWidth
              disabled={!!data?.id}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Typography variant="subtitle1">Link Location</Typography>
          <InfoTooltip
            sx={{ paddingLeft: '8px' }}
            title={`Further tailor your deep link’s destination here. If you’ve set your Link Location within Configuration, this option lets you override it with an optional URL, directing users to your desired landing page.`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
        <Field name="desktopDeepview" type="checkbox">
          {({ input, meta }) => (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  sx={{ padding: '4px 4px 4px 8px' }}
                  {...input}
                />
              }
              label="Gozio Redirect Page"
              sx={{ marginTop: '12px' }}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%', paddingTop: '16px !important' }}>
        <FinalTextField
          label={getFormLabel('Optional Web URL')}
          name="webUrl"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <FinalTextField
          label={getFormLabel('Deeplink Action URL')}
          name="deepLinkActionUrl"
          variant="outlined"
        />
      </Grid>
    </Grid>
  </FormSubSection>
);

LinkNameSection.propTypes = {
  data: PropTypes.object,
};

LinkNameSection.defaultProps = {
  data: {},
};

export default LinkNameSection;
