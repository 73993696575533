import { Grid } from '@mui/material';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import ImageUploader from 'components/imageUploader/imageUploader';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang } from 'helpers/lang-util';
import { MINIMUM_IMAGE_HEIGHT, MINIMUM_IMAGE_WIDTH } from 'helpers/media-util';
import useNetworkPlaceDescendants from 'hooks/dataHooks/useNetworkPlaceDescendants';
import DialogExample from 'pages/locations/containers/sections/extraInstructions/dialogExample';
import SupportingImage from 'pages/locations/containers/sections/extraInstructions/supportingImage';
import React from 'react';
import { Field } from 'react-final-form';

const LABEL_INFO = {
  info: 'Informational',
  rerouteOption: 'Optional Reroute with Message',
  rerouteMessage: 'Mandatory Reroute with Message',
  rerouteMandatory: 'Mandatory Reroute without Message',
};

const poiSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
    ? -1
    : 0;

const buildLabel2 = (poi) => {
  const label = [];
  if (poi.parentBuilding) label.push(`Building ${getLabelByLang(poi.parentBuilding.name)}`);
  if (poi.parentFloor) label.push(`Floor ${getLabelByLang(poi.parentFloor.name)}`);
  if (label.length === 0) return null;
  return label.join(', ');
};
const buildLabel3 = (poi) => {
  const label = [];
  let addrFields = !poi.parentBuilding?.inheritAddress
    ? poi.parentBuilding
    : poi.parentSite;
  addrFields = addrFields?.geoLocation?.address;
  if (!addrFields) return null;

  if (addrFields.street) label.push(addrFields.street);
  if (addrFields.suite) label.push(addrFields.suite);
  if (addrFields.city) label.push(addrFields.city);
  if (addrFields.state) label.push(addrFields.state);
  return label.length > 0 || addrFields.zip
    ? `${label.join(', ')} ${addrFields.zip}`
    : null;
};

const PoiSelection = React.memo(({ pois, currentPOI }) => {
  const buildPoiOptions = () => pois
      .filter((poi) => poi.id !== currentPOI)
      .map((poi) => ({
        id: poi.id,
        label: getLabelByLang(poi.name),
        label2: buildLabel2(poi),
        label3: buildLabel3(poi),
      }))
      .sort(poiSort);

  return (
    <Grid item xs={12}>
      <Field name="extraDestination">
        {({ input, meta }) => (
          <Autocomplete
            input={input}
            meta={meta}
            options={buildPoiOptions()}
            label={'Reroute Location'}
            required={true}
            fullWidth
            disableClearable={true}
          />
        )}
      </Field>
    </Grid>
  );
});

const ExtraInformational = ({ form, values, network, parent, poiId }) => {
  const { data: pois } = useNetworkPlaceDescendants({
    placeId: parent?.id,
    designation: 'poi',
    isMapped: true,
  });

  const handleSupportImageDeleted = () => {
    form.mutators.setValue('extraImage', null);
  };

  return (
    <>
      <FormSubSection name={`${LABEL_INFO[values.extraDirective]} Details`}>
        <Grid container direction="row" spacing={3}>
          {parent && (
            <Condition when="extraDirective" not="info">
              <PoiSelection pois={pois} currentPOI={poiId} />
            </Condition>
          )}
          <Condition when="extraDirective" not="rerouteMandatory">
            <Grid item xs={values.langES ? 6 : 12}>
              <Field name="extraMessage">
                {({ input, meta }) => (
                  <TextField
                    label={getFormLabel('Message Field', true)}
                    charLimit={300}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    {...input}
                    fullWidth
                    multiline
                    rows="5"
                  />
                )}
              </Field>
            </Grid>
            <Condition when="langES" is={true}>
              <Grid item xs={6}>
                <Field name="extraMessageES">
                  {({ input, meta }) => (
                    <TextField
                      label={getFormLabel('Message Field', false, true)}
                      charLimit={300}
                      helperText={meta.touched ? meta.error : ''}
                      error={meta.touched && !!meta.error}
                      {...input}
                      fullWidth
                      multiline
                      rows="5"
                    />
                  )}
                </Field>
              </Grid>
            </Condition>
            <Field name="extraImage">
              {({ input }) => (
                <>
                  <Grid item xs={6}>
                    <SupportingImage
                      image={input.value}
                      onImageDeleted={handleSupportImageDeleted}
                    />

                    <ImageUploader
                      onImageUpdated={(file) => {
                        form.change('extraImage', {
                          url: file.url,
                          file: file.file,
                          name: file.name,
                        });
                      }}
                      display="stacked"
                      fileInfo={`Supported files include jpeg and png. The recommended file size is between 1MB and 3MBs. File sizes should be no larger than 3MBs. Image dimensions must be at least ${MINIMUM_IMAGE_WIDTH}px by ${MINIMUM_IMAGE_HEIGHT}px.`}
                      showImage={false}
                      activeDragPadding={24}
                      maxFileSize={3000000}
                      minWidth={MINIMUM_IMAGE_WIDTH}
                      minHeight={MINIMUM_IMAGE_HEIGHT}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DialogExample
                      message={values.extraMessage}
                      image={input.value}
                    />
                  </Grid>
                </>
              )}
            </Field>
          </Condition>
        </Grid>
      </FormSubSection>
    </>
  );
};

export default React.memo(ExtraInformational);
