import { AddPhotoAlternate, ArrowBack, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Link, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Asterisk from 'components/asterisk/asterisk';
import AutoComplete from 'components/autocomplete/autoComplete';
import ColorOptions from 'components/colorOptions/colorOptions';
import FormWatcher from 'components/forms/formWatcher';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { buildLabelByLang, getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { useUpdateExplorePanel, useUpdateSystemExplorePanel } from 'hooks/dataHooks/useExplorePanel';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkspace } from 'hooks/useWorkspace';
import { makeValidate } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import {
  buildExplorePanelSchema,
  GENERAL_TAB_I18N_FIELDS,
  getExplorePanelTemplateCount,
  getProgress,
  mapToPanelAction,
} from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

const COLUMNS = [
  {
    Header: 'Quicklink Name',
    accessor: 'name',
    disableSortBy: true,
    width: 232,
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    disableSortBy: true,
    width: 116,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    width: 94,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    width: 96,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
  },
];

const useTabClassOverrides = makeStyles(({ spacing }) => ({
  root: {
    marginRight: spacing(10),
  },
}));

const useStyles = makeStyles((theme) => ({
  backArrow: {
    marginRight: '6px',
    fontSize: '20px',
    color: ColorPalette.grey[400],
  },
  back: {
    color: theme.palette.grey[400],
    lineHeight: '20px',
  },
  hidden: {
    visibility: 'hidden',
  },
  topContent: {
    background: theme.palette.white,
    display: 'grid',
    minWidth: 300,
    marginLeft: `-${theme.spacing(3)}`,
    padding: `${theme.spacing(4)} 0 0 0`,
    position: 'fixed',
    top: '68px',
    zIndex: 1000,
  },
  pageTitle: {
    alignSelf: 'center',
    flexDirection: 'column',
    gridColumnEnd: 'span 8',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
  },
  tabContainer: {
    background: theme.palette.white,
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginTop: theme.spacing(1),
    zIndex: 2,
  },
  tabs: {
    paddingLeft: theme.spacing(3),
    zIndex: 2,
  },
  quicklinksTabPanel: {
    background: theme.palette.white,
    overflowY: 'auto',
    padding: `1px ${theme.spacing(3)} 191px ${theme.spacing(3)}`,
  },
  generalTabPanel: {
    background: theme.palette.white,
    height: '100%',
    minHeight: '888px',
    overflowY: 'hidden',
    padding: `151px ${theme.spacing(3)} 191px ${theme.spacing(3)}`,
  },
  quicklinksContent: {},
  fieldArea: {
    marginTop: theme.spacing(3.25),
  },
  fieldSection: {
    marginBottom: theme.spacing(4),
  },
  tabName: {
    display: 'flex',
    alignItems: 'center',
  },
  addItemBtn: {
    float: 'right',
    top: '-20px',
  },
  tabIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '38px',
    justifyContent: 'center',
    marginRight: '10px',
    width: '38px',

    '& img': {
      height: '38px',
      width: '38px',
    },
    '& svg': {
      color: theme.palette.white,
      height: '24px',
      width: '24px',
    },
  },
  placeholderTabIcon: {
    background: theme.palette.grey[350],
    borderRadius: '50%',
  },
  actionBar: {
    background: theme.palette.grey[50],
    borderTop: `2px solid ${colorWithAlpha(ColorPalette.grey[350], 0.3)}`,
    bottom: 0,
    display: 'flex',
    height: '72px',
    justifyContent: 'flex-end',
    left: '250px',
    padding: '15px',
    position: 'fixed',
  },
}));

const buildInitialValues = (data) => data
    ? {
      nameEn: getLabelByLang(data?.name),
      nameEs: getLabelByLang(data?.name, LANGUAGE_CODES.SPANISH),
      site: data?.site?.id || null,
      template: data?.template || 'four_actions',
      color: data?.color || '#C2C3C4',
      actions: data?.actions || [],
    }
    : null;

const PanelView = ({
                     availableSites,
                     data,
                     hidden,
                     onClose,
                     onQuickLinkEdited,
                     onPanelChanged,
                     panel,
                   }) => {
  const { networkId } = useParams();
  const isGlobal = isGlobalTemplate(networkId);

  const size = useWindowSize();
  const classes = useStyles();
  const tabClasses = useTabClassOverrides();

  const {
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const [currentTab, setCurrentTab] = useState(0);
  const [initialValues, setInitialValues] = useState(buildInitialValues(data));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [forceFormWatching, setForceFormWatching] = useState(false);
  const [leftOffset, setLeftOffset] = useState(586);

  const languages = useActiveNetworkLanguages();

  const { workspace } = useWorkspace();

  useEffect(() => {
    if (data?.actions) {
      setInitialValues(buildInitialValues(data));
    }
  }, [data]);

  const [updateExplorePanel] = useUpdateExplorePanel(
    networkId,
    getLabelByLang(panel.name),
  );
  const [updateSystemExplorePanel] = useUpdateSystemExplorePanel(networkId);

  const validate = makeValidate(buildExplorePanelSchema(languages));

  useEffect(() => {
    if (panel?.workspaces?.[0] && panel.workspaces[0].name !== workspace) {
      onClose();
    }
  }, [onClose, panel, workspace]);

  useEffect(() => {
    const scrollElement = document.getElementsByTagName('main');
    const tabPanelElement = document.getElementById(`tabPanel${currentTab}`);
    const height = window.innerHeight - 209;
    if (scrollElement?.length > 0) {
      const mainElement = scrollElement[0];
      mainElement.scrollTop = 0;
      setTimeout(() => {
        if (tabPanelElement?.clientHeight < height) {
          mainElement.style.overflow = 'hidden';
        } else {
          mainElement.style.overflow = 'auto';
        }
      }, 1);
    }
  }, [currentTab, size]);

  useEffect(() => {
    setLeftOffset(openDrawer ? 586 : 836);
  }, [openDrawer]);

  const handleBackToPanels = () => {
    setIsClosing(true);
    setForceFormWatching(true);
    onClose();
  };

  const onSubmit = async (values = {}) => {
    setIsSubmitting(true);
    // eslint-disable-next-line no-unused-vars
    const { nameEn, nameEs, actions, ...rest } = values;
    const updatedData = {
      id: data?.id,
      input: {
        ...rest,
        name: buildLabelByLang(values, 'nameEn', 'nameEs'),
        actions: mapToPanelAction(actions, data?.actions),
      },
    };

    const updateFunction = isGlobal
      ? updateSystemExplorePanel
      : updateExplorePanel;
    const { data: newData } = await updateFunction({
      variables: updatedData,
    });
    if (newData?.updateNetworkExplorePanel) {
      setInitialValues(buildInitialValues(newData.updateNetworkExplorePanel));
      onPanelChanged(newData.updateNetworkExplorePanel);
    }
  };

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const buildLinks = (form, values, dirty) => {
    const actions = values.actions || [];
    const templateNum = getExplorePanelTemplateCount(values.template);
    if (actions?.length > 0) {
      return actions.map((quicklink, index) => {
        const isActive = index < templateNum;
        return {
          id: quicklink.id,
          name: (
            <Box className={classes.tabName}>
              <Box
                className={clsx(
                  classes.tabIcon,
                  !quicklink.icon?.url && classes.placeholderTabIcon,
                )}
              >
                {quicklink.icon?.url ? (
                  <img src={quicklink.icon?.url} alt="icon" />
                ) : (
                  <AddPhotoAlternate />
                )}
              </Box>
              <span>{getLabelByLang(quicklink.name)}</span>
            </Box>
          ),
          progress: getProgress(quicklink),
          active: isActive ? 'Yes' : 'No',
          actions: (
            <Box className="hoverUnhide">
              <Tooltip title="Edit">
                <Edit
                  onClick={() => {
                    onQuickLinkEdited(quicklink);
                  }}
                />
              </Tooltip>
            </Box>
          ),
          _meta: {
            inactive: !isActive,
          },
        };
      });
    }
    return [];
  };

  const reorderRows = (form, onChange, values, row1, row2) => {
    if (row1 && row2) {
      const { nameEn, nameEs, actions, ...rest } = values;
      const list = Array.from(actions);
      const from = list.findIndex((item) => item.id === row1.original.id);
      const to = list.findIndex((item) => item.id === row2.original.id);
      [list[from], list[to]] = [list[to], list[from]];
      onChange(list);
      onPanelChanged({
        name: buildLabelByLang({ nameEn, nameEs }, 'nameEn', 'nameEs'),
        actions: list,
        ...rest,
      });
    }
  };

  const siteListContent = useMemo(() => {
    let options = Array.from(availableSites);

    // If the Explore Panel is already associated with a site,
    // add it to the autocomplete list so that it can be the
    // default selected value
    if (data?.site?.id) {
      options.push({
        id: data?.site?.id,
        label: getLabelByLang(data?.site?.name),
      });
    }

    if (availableSites.length === 0 && options.length === 0) {
      options = [{ id: '', label: 'None' }];
    }

    return (
      <Box sx={{ marginTop: '8px' }}>
        <Field
          name="site"
          component={({ input, meta }) => (
            <AutoComplete
              input={input}
              meta={meta}
              label={'Site'}
              required={true}
              options={options}
            />
          )}
          inputProps={{
            'data-test': 'ExploreGeneralTabSite',
          }}
        />
      </Box>
    );
  }, [data, availableSites]);

  const generalTabFields = useMemo(
    () => GENERAL_TAB_I18N_FIELDS.map(
        ({ langKey, lang, label, charLimit, required }) => {
          if (!languages.includes(lang)) {
            return null;
          }
          return (
            <Grid
              item
              xs={12}
              className={classes.fieldSection}
              key={`field_${langKey}`}
            >
              <Field name={langKey}>
                {({ input, meta }) => (
                  <TextField
                    label={
                      required ? (
                        <span>
                          <Asterisk /> {label}
                        </span>
                      )
                        : label

                    }
                    charLimit={charLimit}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    {...input}
                    fullWidth
                    disabled={isGlobal}
                  />
                )}
              </Field>
            </Grid>
          );
        },
      ).filter((l) => l),
    [classes.fieldSection, isGlobal, languages],
  );

  return (
    <Box className={hidden ? classes.hidden : ''}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
            handleSubmit,
            form,
            values,
            submitting,
            invalid,
            errors,
            dirty,
          }) => (
          <form
            onSubmit={handleSubmit}
            style={{ height: '100%' }}
            onChange={() => {
              const { active, values } = form.getState();
              const { nameEn, nameEs, ...rest } = values;
              if (active !== 'site') {
                // Don't re-render when typing into autocomplete
                onPanelChanged({
                  type: panel.type,
                  name: buildLabelByLang(
                    { nameEn, nameEs },
                    'nameEn',
                    'nameEs',
                  ),
                  nameEn,
                  nameEs,
                  ...rest,
                });
              }
            }}
          >
            {!hidden && (
              <FormWatcher
                forceFormWatching={forceFormWatching}
                forceFormWatchingCallback={() => onClose()}
                formRenderProps={{ form }}
                errorFormFieldMap={
                  new Map(
                    [
                      [
                        'template',
                        { label: 'Template Options', section: 'Quicklinks' },
                      ],
                    ],
                    [
                      [
                        'color',
                        { label: 'Background Color', section: 'Quicklinks' },
                      ],
                    ],
                  )
                }
                isClosing={isClosing}
                isSubmitting={isSubmitting}
                onClose={() => {
                  setIsClosing(false);
                  setIsSubmitting(false);
                }}
              />
            )}
            <Box className={classes.topContent} style={{ width: leftOffset }}>
              <Box className={classes.pageTitle}>
                <Typography variant="h1" display="block">
                  {getLabelByLang(panel.name)}
                </Typography>
                {!isGlobal && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1px',
                    }}
                  >
                    <ArrowBack
                      className={classes.backArrow}
                      onClick={handleBackToPanels}
                    />
                    <Link
                      onClick={handleBackToPanels}
                      variant="subtitle2"
                      data-test="backTo"
                      className={classes.back}
                      sx={{ color: 'inherit' }}
                    >
                      Back to Explore Panels
                    </Link>
                  </Box>
                )}
              </Box>
              <Box
                className={classes.tabContainer}
                sx={{ width: `${leftOffset}px` }}
              >
                <Tabs
                  className={classes.tabs}
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="quicklinks"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    classes={tabClasses}
                    label="Quicklinks"
                    {...tabA11yProps('bottom-nav', 0)}
                  />
                  <Tab label="General" {...tabA11yProps('bottom-nav', 1)} />
                </Tabs>
              </Box>
            </Box>
            <TabPanel
              id="tabPanel0"
              className={classes.quicklinksTabPanel}
              value={currentTab}
              index={0}
              sx={{
                width: `${leftOffset}px`,
                height: `calc(${size.height}px - 118px)`,
                margin: `${isGlobal ? '124px' : '150px 24px 0 -24px'}`,
              }}
            >
              <Box className={classes.quicklinksContent}>
                <Box className={classes.fieldSection}>
                  <Grid
                    item
                    xs
                    sx={{ marginBottom: '24px', marginTop: '24px' }}
                  >
                    <Typography variant="subtitle1">Display</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">Template Option</Typography>
                  </Grid>
                  <Grid item xs>
                    <Field name="template">
                      {({ input: { value, onChange } }) => (
                        <GozioRadioGroup
                          groupName="template"
                          onChange={onChange}
                          options={[
                            { label: '4 Actions', value: 'four_actions' },
                            { label: '8 Actions', value: 'eight_actions' },
                          ]}
                          row={true}
                          value={value}
                        />
                      )}
                    </Field>
                  </Grid>
                </Box>
                <Box className={classes.fieldSection}>
                  <Grid item xs sx={{ marginBottom: '24px' }}>
                    <Typography variant="subtitle1">Color Options</Typography>
                  </Grid>
                  <Grid item xs sx={{ width: '260px' }}>
                    <Field name="color">
                      {({ input: { value, onChange } }) => (
                        <ColorOptions
                          initialColor={value}
                          formControl={true}
                          getColor={onChange}
                          label="Background Color"
                          required={true}
                          inputProps={{
                            'data-test': 'backgroundColor',
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Box>
                <Box className={classes.fieldSection}>
                  <Grid
                    item
                    xs
                    sx={{ marginBottom: '24px', paddingTop: '8px' }}
                  >
                    <Typography variant="subtitle1">Quicklinks</Typography>
                  </Grid>
                  <Grid item xs>
                    <Field name="actions">
                      {({ input: { onChange } }) => (
                        <GozioTable
                          sx={{
                            padding: 0,
                          }}
                          columns={COLUMNS}
                          data={buildLinks(form, values, dirty)}
                          hidePagination={true}
                          draggable={true}
                          onDrag={(row1, row2) => reorderRows(form, onChange, values, row1, row2)
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel
              id="tabPanel1"
              className={classes.generalTabPanel}
              value={currentTab}
              index={1}
              sx={{
                width: `${leftOffset}px`,
                margin: `${isGlobal ? '-26px' : '0 24px 0 -24px'}`,
              }}
            >
              <Box className={classes.fieldArea}>
                <Grid item xs>
                  <Typography variant="subtitle1">General</Typography>
                </Grid>
                <Grid item xs className={classes.fieldArea}>
                  {generalTabFields}
                </Grid>
              </Box>
              {panel.type === 'site' && siteListContent}
            </TabPanel>
            <Box
              className={classes.actionBar}
              sx={{ left: openDrawer ? '250px' : 0, width: `${leftOffset}px` }}
            >
              <Button
                color="primary"
                data-test="confirm-button"
                disabled={!dirty || submitting || invalid}
                onClick={async () => {
                  setIsSubmitting(true);
                  await onSubmit(values);
                  setIsSubmitting(false);
                }}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  );
};

PanelView.propTypes = {
  availableSites: PropTypes.array,
  hidden: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onQuickLinkEdited: PropTypes.func,
  onPanelChanged: PropTypes.func,
  panel: PropTypes.object.isRequired,
};

PanelView.defaultProps = {
  availableSites: [],
  hidden: false,
  onClose: () => {},
  onQuickLinkEdited: () => {},
  onPanelChanged: () => {},
};

export default PanelView;
