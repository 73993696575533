import { Paper } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import { isGlobalTemplate } from 'helpers/network-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { convertRemoteUsersData, useUsers } from 'hooks/dataHooks/useUsers';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import AddUserModal from 'pages/users/containers/addUserModal';
import UsersTable from 'pages/users/containers/UsersTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const UsersPageContent = () => {
  const { networkId } = useParams();
  const [activeNetworkId, setActiveNetworkId] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const isGlobal = isGlobalTemplate(networkId);

  useEffect(() => {
    setSearchParam('');
  }, [networkId]);

  const { data, refetch } = useUsers(networkId);

  const roles = useMemo(() => {
    const map = {};
    const users = data?.users || [];
    if (isGlobal) {
      users.forEach((user) => {
        user.roles.forEach((role) => {
          if (role.internal) {
            map[role.name] = true;
          }
        });
      });
    } else if (data?.roles?.length) {
      data?.roles.forEach((role) => {
        if (!role.internal) {
          map[role.name] = true;
        }
      });
    }

    return Object.keys(map).sort();
  }, [data, isGlobal]);

  useEffect(() => {
    if (data?.users?.length) {
      if (users === null || activeNetworkId !== networkId) {
        setActiveNetworkId(networkId);
        setUsers(data.users || []);
      }
    }
  }, [activeNetworkId, data, networkId, users]);

  return (
    <FlamingoPage
      pageName="Users"
      onSearchChange={(search) => setSearchParam(search.toLowerCase())}
      headerButtons={
        <AddCircleButton
          onClick={() => setShowAddUserModal(true)}
          tooltipTitle="Add a User"
        />
      }
    >
      <Paper>
        <UsersTable
          data={users || []}
          onUserUpdated={async () => {
            const refetchedData = await refetch();
            const updatedUserData = refetchedData?.data
              ? convertRemoteUsersData(
                refetchedData.data,
                isGlobalTemplate(networkId),
              ).users
              : [];
            setUsers(updatedUserData);
          }}
          searchParam={searchParam}
        />
      </Paper>
      {showAddUserModal && (
        <AddUserModal
          roles={roles}
          onModalClose={async (hasSubmitted) => {
            if (hasSubmitted) {
              const refetchedData = await refetch();
              const updatedUserData = refetchedData?.data
                ? convertRemoteUsersData(refetchedData.data, isGlobal).users
                : [];
              setUsers(updatedUserData);
            }
            setShowAddUserModal(false);
          }}
        />
      )}
    </FlamingoPage>
  );
};

const UsersPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <UsersPageContent />
  </LoggedinLayout>
);

export default UsersPage;
