import { Box } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useNetworkPlace } from 'hooks/dataHooks';
import { DEFAULT_KIOSK_BANNER_INTERVAL, useKiosk, useUpdateKiosk } from 'hooks/dataHooks/useKiosks';
import { uploadImage } from 'modules/media';
import EditTile from 'pages/kiosks/containers/editTile';
import { initialParser, validate } from 'pages/kiosks/containers/formHelper';
import KioskDetails from 'pages/kiosks/containers/kioskDetails';
import KioskPreview from 'pages/kiosks/containers/kioskPreview';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import {
  GET_NETWORK_PLACE_POIS,
  UPDATE_KIOSK_DIRECTORY,
} from 'graphql/queries';

const buildTileInput = (t, orderOverride) => ({
  name: t.name,
  backgroundColor: t.backgroundColor,
  icon: t.icon?.id || null,
  type: t.type,
  order: Number.isInteger(orderOverride) ? orderOverride : t.order,
  title: t.title || null,
  description: t.description || null,
  poiItems:
    t.type === 'poigroup'
      ? (t.poiItems || []).map((p) => ({ poi: p.poi?.id, order: p.order }))
      : null,
});

const KioskDetailPageContent = () => {
  const [currentTile, setCurrentTile] = useState(null);
  const [deletedTile, setDeletedTile] = useState(null);
  const [kiosk, setKiosk] = useState({});
  const { networkId, kioskId } = useParams();
  const {
    handleNavigate,
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const [updateKiosk] = useUpdateKiosk();
  const [updateKioskTile] = useUpdateKiosk(
    UPDATE_KIOSK_DIRECTORY,
    'All changes within the tile have been saved.',
  );
  const [deleteKioskTile] = useUpdateKiosk(
    UPDATE_KIOSK_DIRECTORY,
    'The tile has been successfully deleted.',
  );
  const [reorderKioskTiles] = useUpdateKiosk(UPDATE_KIOSK_DIRECTORY, '');
  const { data, refetchData } = useKiosk(kioskId);
  const { data: place } = useNetworkPlace({ id: kiosk?.site?.id, gql: GET_NETWORK_PLACE_POIS });

  useEffect(() => {
    if (data && !kiosk.id) setKiosk(data);
  }, [data, kiosk]);

  useEffect(() => {
    if (kiosk.network && kiosk.network?.id !== networkId) {
      handleNavigate(`/network/${networkId}/kiosks`);
    }
  }, [handleNavigate, kiosk, networkId]);

  const memoizedPOIs = useMemo(
    () => place?.pois?.edges?.map(({ node }) => node) || [],
    [place],
  );

  const memoizedCloseTileDrawer = useCallback(() => setCurrentTile(null), []);

  const saveTile = async (values) => {
    const iconId = await getTileIconId(values.tileIcon);
    const updatedTile = {
      backgroundColor: values.tileColor || null,
      name: values.name,
      icon: iconId,
      type: values.tileType,
      order: Number.isInteger(currentTile?.order)
        ? currentTile.order
        : kiosk?.directory?.tiles?.length || 0,
      title: values.title || null,
      description: values.description || null,
      poiItems: (values.selectedPOIs || []).map((p) => ({
        poi: p.id,
        order: p.order,
      })),
    };
    const tiles = (kiosk.directory.tiles || []).map((t) => {
      if (currentTile?.assignedId !== t.assignedId) {
        return buildTileInput(t);
      }
      return updatedTile;
    });
    if (!Number.isInteger(currentTile?.order)) tiles.push(updatedTile);
    await updateKioskTile({
      variables: {
        id: kiosk.id,
        input: {
          directory: {
            tiles,
          },
        },
      },
    });
    const updatedKiosk = await refetchData({ id: kioskId });
    setKiosk(updatedKiosk);
  };

  const memoizedSaveTile = useCallback(
    (values) => {
      saveTile(values);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTile],
  );

  const deleteTile = async () => {
    const newTiles
      = kiosk?.directory?.tiles?.filter((tile) => tile.id !== deletedTile.id)
      || [];
    const savedTiles = newTiles.map((t, idx) => buildTileInput(t, idx + 1));

    await deleteKioskTile({
      variables: {
        id: kiosk.id,
        input: {
          directory: {
            tiles: savedTiles,
          },
        },
      },
    });
    setDeletedTile(null);
  };

  const memoizedDeleteTile = useCallback(
    (tile) => {
      setDeletedTile(tile);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kiosk],
  );

  const memoizedReorderTiles = useCallback(
    (rowOne, rowTwo) => {
      if (!rowOne || !rowTwo) return;
      const savedTiles = kiosk?.directory?.tiles?.map((t, idx) => {
        let order = idx;
        if (idx === rowOne.rowIdx) {
          order = rowTwo.index;
        } else if (idx === rowTwo.index) {
          order = rowOne.rowIdx;
        }
        return buildTileInput(t, order);
      });

      reorderKioskTiles({
        variables: {
          id: kiosk.id,
          input: {
            directory: {
              tiles: savedTiles,
            },
          },
        },
      });
    },
    [kiosk, reorderKioskTiles],
  );

  const memoizedCurrentTile = useMemo(
    () => currentTile
        ? kiosk?.directory?.tiles?.find((tile) => tile.id === currentTile.id)
        || {}
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTile],
  );

  const saveDetails = async (values) => {
    await updateKiosk({
      variables: {
        id: kioskId,
        input: {
          name: values.name,
          floor: values.floorId,
          banner: {
            interval: kiosk?.banner?.interval ?? DEFAULT_KIOSK_BANNER_INTERVAL,
          },
        },
      },
    });
  };

  const getTileIconId = async (tileIcon) => {
    let returnId = null;
    if (tileIcon) {
      if (tileIcon.id) {
        returnId = tileIcon.id;
      } else if (tileIcon.file) {
        const uploadedImage = await uploadImage(tileIcon.file);
        returnId = uploadedImage?.id;
      }
    }
    return returnId;
  };

  const onEditTile = (tile) => {
    setCurrentTile(tile);
  };

  return (
    <>
      {deletedTile && (
        <GenericModal
          title="Delete Tile"
          body={'Are you sure you want to delete this tile?'}
          open={!!deletedTile}
          handleClose={() => setDeletedTile(null)}
          handleConfirm={deleteTile}
          confirmText="Delete"
          customTags={{
            confirm: 'KioskTileDeleteModalConfirmButton',
            cancel: 'KioskTileDeleteModalCancelButton',
          }}
        />
      )}
      <Form
        subscription={{ submitting: true, pristine: true, validating: true }}
        initialValues={initialParser(kiosk)}
        onSubmit={saveDetails}
        validate={validate}
      >
        {({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
              <KioskDetails
                kiosk={kiosk}
                networkId={networkId}
                form={form}
                onEditTile={onEditTile}
                onDeleteTile={memoizedDeleteTile}
                onReorderTiles={memoizedReorderTiles}
              />
              <KioskPreview kiosk={kiosk} networkId={networkId} />
            </Box>
          </form>
        )}
      </Form>
      <EditTile
        currentTile={memoizedCurrentTile}
        pois={memoizedPOIs}
        closeTileDrawer={memoizedCloseTileDrawer}
        onSubmit={memoizedSaveTile}
      />
      <WorkspaceSwitcher
        detectDrawerVisibility={false}
        leftOffset={openDrawer ? 767 : 517}
      />
    </>
  );
};

const KioskDetailPage = () => (
  <LoggedinLayout
    enableSuspense={true}
    showWorkspaceSwitcher={false}
    supportedWorkspace={LIVE_WORKSPACE}
    sx={{ padding: 0 }}
  >
    <KioskDetailPageContent />
  </LoggedinLayout>
);

export default KioskDetailPage;
