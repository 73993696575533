import MultiSelectWithChips from 'components/multiSelectWithChips/multiSelectWithChips';
import PropTypes from 'prop-types';
import React from 'react';

const DepartmentIdTags = ({ input, label, sx }) => (
  <MultiSelectWithChips
    label={label}
    sx={sx}
    input={input}
    errorMessage="You have duplicate Department IDs"
    helperMessage="Put a comma in between each Department ID to add multiple Department IDs"
    numericOnly={false}
  />
);

DepartmentIdTags.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

DepartmentIdTags.defaultProps = {
  sx: {},
};

export default DepartmentIdTags;
