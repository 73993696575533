const sections = {
	SCREENSAVER: 'Screensaver Tab',
	MAIN: 'Main View Tab',
	CTA: 'App CTA Tab',
};
Object.freeze(sections);

const formFieldMap = new Map([
	/* Mapping Inputs */
	['screensaverBackgroundColor', { label: 'Background Color', section: sections.SCREENSAVER }],
	['mainCtaText', { label: 'Application CTA Text', section: sections.MAIN }],
	['mainBackgroundColor', { label: 'Footer Color', section: sections.MAIN }],
	['mainSearchPlaceholderText', { label: 'Search Placeholder Text', section: sections.MAIN }],
]);

export default formFieldMap;
