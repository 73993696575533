import { Box, Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import LinkDestination from 'components/forms/linkDestination';
import GenericStepperModal from 'components/genericModal/genericStepperModal';
import ImageUploader from 'components/imageUploader/imageUploader';
import { getExternalAppFormValidationSchema, getWebsiteValidationSchema, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import useWindowSize from 'hooks/useWindowSize';
import { makeValidate } from 'mui-rff';
import {
  QUICKLINK_NAME_FIELDS,
  renderFields,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const buildStyles = () => ({
  quicklinkModalContent: {
    textAlign: 'left',
  },
});

export const QuicklinkModal = ({
                                 sx,
                                 iconWidthAndHeight,
                                 languages,
                                 networkId,
                                 onClose,
                                 onIconUploaded,
                                 onSubmit,
                                 selectedQuickLink,
                                 supportedLinkTypes,
                               }) => {
  const { height } = useWindowSize();
  const modalSx = buildStyles();
  const [formValues, setFormValues] = useState(null);
  const [initialValues, setInitialValues] = useState({
    externalType: 'native',
    webType: 'native',
  });

  const maxHeight
    = height < 968 ? `calc(${height}px - 276px)` : 'calc(100% + 138px)';

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          nameEn: Yup.string().required('Quicklink Name is required'),
          nameEs: Yup.string(),
          iconUrl: Yup.string().required('Icon is required'),

          // Quicklink Validation
          linkType: Yup.string().required('The Quicklink Type is required'),
          // categories:
          categories: Yup.array().when('linkType', {
            is: 'category',
            then: (schema) => schema.min(1).required('Category is required'),
          }),
          // in_app
          inAppDest: Yup.string()
            .nullable()
            .when('linkType', {
              is: 'in_app',
              then: (schema) => schema.required('In-App Destination is required.'),
            }),
          // list
          listDest: Yup.string()
            .nullable()
            .when('linkType', {
              is: 'list',
              then: (schema) => schema.nullable().required('List is required.'),
            }),
          // External App
          ...getExternalAppFormValidationSchema,
          // Website
          ...getWebsiteValidationSchema,
          // Custom
          custom: Yup.string()
            .nullable()
            .when('linkType', {
              is: 'custom',
              then: (schema) => schema.required('This open field is required'),
            }),
        },
        languages,
        { nameEn: LANGUAGE_CODES.ENGLISH, nameEs: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  useEffect(() => {
    if (!initialValues?.nameEn && selectedQuickLink) {
      setInitialValues({
        nameEn: parseByLang(selectedQuickLink.name) || '',
        ...!!parseByLang(selectedQuickLink.name, LANGUAGE_CODES.SPANISH) && {
          nameEs:
            parseByLang(selectedQuickLink.name, LANGUAGE_CODES.SPANISH) || '',
        },
        iconUrl: selectedQuickLink.iconUrl || '',
        linkType: selectedQuickLink.type || '',
        categories:
          selectedQuickLink.type === 'category'
            ? selectedQuickLink.categories ?? []
            : [],
        inAppDest:
          selectedQuickLink.type === 'in_app'
            ? selectedQuickLink.data?.id
            : null,
        listDest:
          selectedQuickLink.type === 'list'
            ? selectedQuickLink.data?.id ?? null
            : null,
        ...selectedQuickLink.type === 'external_app' && {
          externalURL: selectedQuickLink.data.url,
          externalAndroidURL: selectedQuickLink.data.androidUrl,
          externaliOSURL: selectedQuickLink.data.iosUrl,
          externalType: selectedQuickLink.data.client,
          fallbackiOSURL: selectedQuickLink.data.iosFallbackUrl,
          fallbackAndroidURL: selectedQuickLink.data.androidFallbackUrl,
          hasPlatformSpecificFallbacks: !!(
            selectedQuickLink.data.androidFallbackUrl
            || selectedQuickLink.data.iosFallbackUrl
          ),
        },
        ...selectedQuickLink.type === 'website' && {
          webUrlEn: selectedQuickLink.data.url?.en,
          ...hasSpanish(languages) && {
            webUrlEs: selectedQuickLink.data.url?.es || null,
          },
          webType: selectedQuickLink.data.client,
        },
        ...selectedQuickLink.type === 'custom' && {
          custom: selectedQuickLink.data.url,
        },
        validation: selectedQuickLink.validation,
      });
    }
  }, [initialValues, languages, selectedQuickLink]);

  const steps = useMemo(
    () => [
      {
        title: 'Define Quicklink',
        content: (
          <Box
            sx={{
              ...modalSx.quicklinkModalContent,
              maxHeight: `${maxHeight}px`,
            }}
          >
            <Grid item xs>
              <Typography sx={sx.displayHeading} variant="subtitle1">
                General
              </Typography>
            </Grid>
            <Grid item xs sx={sx.formFields}>
              {renderFields({
                sx,
                fields: QUICKLINK_NAME_FIELDS,
                languages,
                formValues: formValues ?? initialValues,
              })}
            </Grid>
            <Grid item xs>
              <Typography sx={sx.displayHeading} variant="subtitle1">
                Display
              </Typography>
            </Grid>
            <Grid item sx={{ paddingBottom: '24px' }}>
              <Field name="iconUrl">
                {({ input }) => (
                  <ImageUploader
                    title={
                      <span>
                        <Asterisk /> Supporting Icon
                      </span>
                    }
                    image={
                      input.value
                        ? {
                          url: input.value,
                        }
                        : null
                    }
                    fileInfo={`Supported files includes jpeg and png. Image dimensions must be ${iconWidthAndHeight}px by ${iconWidthAndHeight}px.`}
                    onImageUpdated={({ file, url }) => {
                      onIconUploaded({ file, iconUrl: url });
                      input.onChange(url);
                    }}
                    allowDelete={false}
                    showImage={true}
                    minWidth={iconWidthAndHeight}
                    minHeight={iconWidthAndHeight}
                    maxWidth={iconWidthAndHeight}
                    maxHeight={iconWidthAndHeight}
                    previewWidth={64}
                    previewHeight={64}
                  />
                )}
              </Field>
            </Grid>
          </Box>
        ),
        requiredFields: ['nameEn', 'iconUrl'],
      },
      {
        title: 'Select Quicklink Type',
        content: (
          <Box
            sx={{ ...modalSx.quicklinkModalContent, maxHeight: `${maxHeight}` }}
          >
            <LinkDestination
              formValues={formValues ?? initialValues}
              hasCustomLink={!!(formValues ?? initialValues)?.custom}
              supportedLinkTypes={supportedLinkTypes}
            />
          </Box>
        ),
      },
    ],
    [
      sx,
      maxHeight,
      modalSx,
      iconWidthAndHeight,
      formValues,
      initialValues,
      languages,
      onIconUploaded,
      supportedLinkTypes,
    ],
  );

  return (
    <GenericStepperModal
      open={true}
      formParams={{
        onSubmit,
        validate,
        initialValues,
      }}
      onChange={(values) => setFormValues(values)}
      onClose={async () => {
        onClose();
      }}
      canGoBack={() => true}
      canProceed={(step, formVals) => {
        if (formVals?.errors && Object.keys(formVals.errors).length === 0) {
          if (step.index === 0) {
            return !formVals.errors.hasOwnProperty('linkType');
          }
        }

        return true;
      }}
      steps={steps}
    />
  );
};

QuicklinkModal.propTypes = {
  sx: PropTypes.object,
  iconWidthAndHeight: PropTypes.number.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  networkId: PropTypes.string.isRequired,
  onIconUploaded: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedQuickLink: PropTypes.object,
  supportedLinkTypes: PropTypes.arrayOf(PropTypes.string),
};

QuicklinkModal.defaultProps = {
  sx: {},
  selectedQuickLink: null,
  supportedLinkTypes: null,
};
