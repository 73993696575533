import { ArrowBack, Clear, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Link, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    gridTemplateRows: '60px [content-start] auto',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: theme.spacing(2),
    alignItems: 'start',
    padding: `0 0 ${theme.spacing(1)}`,
    height: '100%',
    minWidth: '300px',
  },
  pageName: {},
  pageTitle: {
    alignSelf: 'center',
    gridColumnEnd: 'span 8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageTitleWithSearch: {
    minWidth: 0,
    maxWidth: '25%',
  },
  headerArea: {
    marginBottom: theme.spacing(3),
  },
  headerButtons: {},
  backArrow: {
    marginRight: '6px',
    marginTop: theme.spacing(1),
    fontSize: '20px',
    color: theme.palette.grey[400],
  },
  back: {
    color: 'inherit',
    lineHeight: '20px',
    marginTop: theme.spacing(1),
  },
  content: {
    gridColumn: '1 / -1',
    gridColumnEnd: 'span 8',
    gridRowStart: 'content-start',
    gridRowEnd: 'span 1',
    alignSelf: 'stretch',
  },
  handleBack: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1px',
  },
  searchWrap: {
    margin: '0 16px 0 16px',
    flex: 3,
    backgroundColor: theme.palette.white,

    '& input': {
      height: '27px',
      padding: '10px 6px',
    },
  },
});

function FlamingoPage({
                        pageName,
                        headerButtons,
                        handleBack,
                        handleBackSx,
                        handleBackTitle,
                        children,
                        sx,
                        contentSx,
                        pageTitleSx,
                        headerSx,
                        searchValue,
                        onSearchChange,
                        ...rest
                      }) {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [searchVal, setSearchVal] = useState(searchValue);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSearchChange(searchVal);
    }
  };

  const clearSearch = () => {
    if (searchVal.length > 0) {
      setSearchVal('');
      onSearchChange('');
    }
  };

  return (
    <Box sx={{ ...styles.root, ...sx }} {...rest}>
      <Box sx={styles.headerArea}>
        <Box sx={{ ...styles.pageTitle, ...pageTitleSx }}>
          {!onSearchChange && (
            <Box sx={styles.pageName}>
              <Typography
                variant="h1"
                display="block"
                data-test={`FlamingoPage-title-${pageName}`}
              >
                {pageName || ''}
              </Typography>
            </Box>
          )}

          {onSearchChange && (
            <>
              <Box sx={styles.pageTitleWithSearch}>
                <Tooltip title={pageName || ''} placement="top-start">
                  <Typography
                    variant="h1"
                    noWrap
                    data-test={`FlamingoPage-title-${pageName}`}
                  >
                    {pageName || ''}
                  </Typography>
                </Tooltip>
              </Box>
              <TextField
                margin="normal"
                variant="outlined"
                onKeyDown={handleKeyDown}
                placeholder="Search"
                onChange={(e) => setSearchVal(e.target.value)}
                value={searchVal}
                sx={styles.searchWrap}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        fontSize: '24px',
                        color: ColorPalette.grey[500],
                        padding: 0,
                      }}
                    >
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchVal.length > 0 && (
                    <IconButton
                      onClick={clearSearch}
                      sx={{
                        fontSize: '20px',
                        color: ColorPalette.grey[500],
                        padding: 0,
                      }}
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
              />
            </>
          )}
          <Box sx={{ ...styles.headerButtons, ...headerSx }}>
            {headerButtons || ''}
          </Box>
        </Box>
        {handleBack && (
          <Box sx={{ ...styles.handleBack, ...handleBackSx }}>
            <ArrowBack sx={styles.backArrow} onClick={handleBack} />
            <Link
              onClick={handleBack}
              variant="subtitle2"
              data-test="backTo"
              sx={styles.back}
            >
              {handleBackTitle || 'Back'}
            </Link>
          </Box>
        )}
      </Box>
      <Box sx={{ ...styles.content, ...contentSx }}>{children || ''}</Box>
    </Box>
  );
}

FlamingoPage.propTypes = {
  children: PropTypes.any,
  sx: PropTypes.object,
  contentSx: PropTypes.object,
  pageTitleSx: PropTypes.object,
  handleBackSx: PropTypes.object,
  headerSx: PropTypes.object,
  pageName: PropTypes.any,
  headerButtons: PropTypes.node,
  handleBack: PropTypes.func,
  handleBackTitle: PropTypes.string,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
};

FlamingoPage.defaultProps = {
  sx: {},
  contentSx: {},
  handleBackSx: {},
  pageTitleSx: {},
  headerSx: {},
  searchValue: '',
};

export default FlamingoPage;
