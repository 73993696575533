import { Delete, DragIndicator } from '@mui/icons-material';
import { Box, CardMedia, IconButton, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import YouTubePlayButton from 'assets/youtube-play.png';
import clsx from 'clsx';
import { ItemTypes } from 'helpers/dnd-util';
import { getYoutTubeVideoLinkName } from 'helpers/media-util';
import MediaEmptyPlaceholder from 'pages/locations/containers/sections/mediaEmptyPlaceholder';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    padding: '11px',
    position: 'relative',
    verticalAlign: 'top',
    minHeight: '240px',
    width: '231px',
  },
  emptyPlaceholder: {
    padding: 0,
  },
  dragging: {
    opacity: 0.35,
  },
  hovered: {
    background: theme.palette.blue[50],
    border: `2px solid ${theme.palette.blue[400]}`,
    borderRadius: '8px',
  },
  placeholderHovered: {
    border: `3px dotted ${theme.palette.blue[400]}`,
    borderRadius: '8px',
    height: '200px',
    textAlign: 'center',
  },
  placeholderHoveredBox: {
    alignItems: 'center',
    display: 'flex',
    height: '178px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  dropTargetRight: {
    borderRight: `2px solid ${theme.palette.blue[400]}`,
  },
  dropTargetLeft: {
    borderLeft: `2px solid ${theme.palette.blue[400]}`,
  },
  image: {
    borderRadius: '8px',
    backgroundSize: 'contain',
    width: '209px',
    height: '133px',
    border: `0.5px solid ${theme.palette.grey[400]}`,
  },
  placeholderImage: {
    border: `1.5px dashed ${theme.palette.grey[400]}`,
  },
  index: {
    color: theme.palette.grey[600],
    paddingTop: '9px',
    textAlign: 'center',
  },
  hoveredText: {
    color: theme.palette.grey[500],
    display: '-webkit-box',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    paddingTop: '9px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,

    '& p': {
      fontWeight: 'bold',
    },
  },
  descriptionContainer: {
    textAlign: 'center',

    '& > :first-child': {
      marginRight: theme.spacing(0.5),
    },
  },
  description: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  multipleDescriptions: {
    display: 'inline-block',
  },
  roundedText: {
    borderRadius: '18px',
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '6px 12px 6px 12px',
  },
  placeholderText: {
    marginRight: '4px',
  },
  hoveredRoundedText: {
    color: theme.palette.blue[400],
    border: `1px solid ${theme.palette.blue[400]}`,
  },
  playOverlay: {
    position: 'absolute',
    top: '57px',
    left: '85px',
  },
  playButton: {
    height: '40px',
    width: '58px',
  },
  actionBar: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '2px',
    boxShadow: `0 2px 4px 0 ${theme.palette.grey[350]}`,
    cursor: 'pointer',
    height: '48px',
    left: '53.5px',
    padding: '6px 12px',
    position: 'absolute',
    top: theme.spacing(1),
    width: '124px',
  },
  actionIcon: {
    height: '24px',
    margin: '6px 4px',
    width: '24px',
  },
}));

const MediaCard = ({
                     index,
                     indexLabel,
                     media,
                     isDropTarget,
                     isPlaceholder,
                     dropIndex,
                     designation,
                     onDrop,
                     onHover,
                     onMediaDeleted,
                     supportsKiosk,
                   }) => {
  const classes = useStyles();

  const name
    = media?.type === 'image'
      ? media?.image?.name
      || media?.file?.name
      || media?.file?.file?.name
      || indexLabel
     : (
      <Link href={media?.video?.url} target="_blank">
        {getYoutTubeVideoLinkName(media?.video?.url)}
      </Link>
    );
  // noinspection JSUnresolvedVariable
  const url
    = media?.image?.transformations?.[0].url
    || media?.image?.url
    || media?.video?.thumbnail?.url;

  const ref = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dropItemCanBeDefault, setDropItemCanBeDefault] = useState(true);
  const [dropTargetClass, setDropTargetClass] = useState('');

  const handleDeleteMediaClicked = useCallback(() => {
    onMediaDeleted(media);
  }, [onMediaDeleted, media]);

  const [, drop] = useDrop({
    accept: ItemTypes.MEDIA_IMAGE,
    drop: (item) => {
      if (onDrop) {
        onDrop(item, index);
      }
    },
    canDrop: (item) => {
      setDropItemCanBeDefault(item.canBeDefault);
      if (isPlaceholder || index <= 0) {
        if (!item.canBeDefault) {
          onDrop();
        }
        return item.canBeDefault;
      }

      return true;
    },
    hover(item) {
      if (onHover) {
        onHover(item, index);
      }
    },
  });

  const [, drag] = useDrag({
    type: ItemTypes.MEDIA_IMAGE,
    item: {
      canBeDefault: media?.type === 'image',
      media,
      index,
    },
    canDrag: () => true,
    collect: (monitor) => {
      setIsDragging(monitor.isDragging());
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  if (isPlaceholder) {
    drop(ref);
  } else {
    drag(drop(ref));
  }

  const isPlaceholderHovered = isPlaceholder && isDropTarget;

  useEffect(() => {
    if (
      isPlaceholder
      || !isDropTarget
      || dropIndex === index
      || (index <= 0 && !dropItemCanBeDefault)
    ) {
      setDropTargetClass('');
    } else if (dropIndex < index) {
      setDropTargetClass(classes.dropTargetRight);
    } else {
      setDropTargetClass(classes.dropTargetLeft);
    }
  }, [
    dropItemCanBeDefault,
    isDragging,
    isPlaceholder,
    isDropTarget,
    dropIndex,
    index,
    classes.dropTargetRight,
    classes.dropTargetLeft,
  ]);

  const handleMouseDown = (flag) => {
    if (flag) {
      setIsMouseDown(true);
      setTimeout(() => {
        if (ref.current) {
          setIsMouseDown(false);
        }
      }, 200);
    } else {
      setIsMouseDown(false);
    }
  };

  const actionBar = useMemo(() => {
    if (!isDragging && isHovered && !isMouseDown && !isPlaceholder) {
      return (
        <Box className={classes.actionBar}>
          <IconButton
            className={classes.actionIcon}
            onMouseDown={() => handleMouseDown(true)}
            onMouseUp={() => handleMouseDown(false)}
            onMouseLeave={() => handleMouseDown(false)}
            aria-label="Move media"
            size="large"
          >
            <DragIndicator />
          </IconButton>
          <IconButton
            className={classes.actionIcon}
            onClick={handleDeleteMediaClicked}
            onMouseDown={handleDeleteMediaClicked}
            aria-label="Delete media"
            size="large"
          >
            <Delete className={classes.actionIcon} />
          </IconButton>
        </Box>
      );
    }
  }, [
    isDragging,
    isHovered,
    isMouseDown,
    isPlaceholder,
    classes.actionBar,
    classes.actionIcon,
    handleDeleteMediaClicked,
  ]);

  const renderDescription = useCallback(
    (label, isMultiple, icon) => (
      <Box
        className={clsx(
          classes.description,
          isMultiple && classes.multipleDescriptions,
        )}
      >
        <Box
          className={clsx(
            classes.roundedText,
            isHovered && classes.hoveredRoundedText,
          )}
        >
          {label}
          {!!icon && icon}
        </Box>
      </Box>
    ),
    [
      classes.description,
      classes.multipleDescriptions,
      classes.roundedText,
      classes.hoveredRoundedText,
      isHovered,
    ],
  );

  const renderCardMedia = () => {
    if (media || !isPlaceholder) {
      return (
        <CardMedia
          className={clsx(
            classes.image,
            isPlaceholder && classes.placeholderImage,
          )}
          image={url}
        />
      );
    }

    return <MediaEmptyPlaceholder />;
  };

  return (
    <Box
      className={clsx(
        classes.root,
        isDragging
          ? classes.dragging
          : isHovered && !isPlaceholder
            ? classes.hovered
            : '',
        isPlaceholderHovered && classes.placeholderHovered,
        isPlaceholder && !media && classes.emptyPlaceholder,
        dropTargetClass,
      )}
      ref={ref}
      onMouseDown={() => handleMouseDown(true, 1)}
      onMouseUp={() => handleMouseDown(false, 1)}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => {
        handleMouseDown(false);
        setIsHovered(false);
      }}
    >
      {actionBar}
      {media?.type === 'video' && !media?.video?.id && (
        <Box
          className={classes.playOverlay}
          onDragStart={() => setIsHovered(false)}
        >
          <img
            src={YouTubePlayButton}
            className={classes.playButton}
            alt="YouTube play button"
          />
        </Box>
      )}
      {isPlaceholderHovered && (
        <Box className={classes.placeholderHoveredBox}>
          <Typography variant="h1" color="primary">
            Assign Default
            <br />
            Image
          </Typography>
        </Box>
      )}
      {!isPlaceholderHovered && (
        <Box onDragStart={() => setIsHovered(false)}>
          {renderCardMedia()}
          {isPlaceholder && !!media && (
            <Box className={classes.descriptionContainer}>
              {renderDescription('Placeholder Image', true, null)}
              {designation === 'poi'
                && supportsKiosk
                && renderDescription('Kiosk', true, null)}
            </Box>
          )}
          {!isPlaceholder && (
            <Box
              className={clsx(
                isHovered && classes.hoveredText,
                !isHovered && classes.index,
              )}
            >
              <Typography variant="body1">
                {!isDragging && isHovered ? name ?? indexLabel : indexLabel}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

MediaCard.propTypes = {
  indexLabel: PropTypes.number,
  index: PropTypes.number.isRequired,
  media: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    video: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  isDropTarget: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  dropIndex: PropTypes.number,
  onDrop: PropTypes.func,
  onHover: PropTypes.func,
  onMediaDeleted: PropTypes.func,
  designation: PropTypes.oneOf([
    'site',
    'building',
    'floor',
    'poi',
    'landmark',
  ]),
  supportsKiosk: PropTypes.bool,
};

MediaCard.defaultProps = {
  indexLabel: 0,
  isDropTarget: false,
  isPlaceholder: false,
  dropIndex: null,
  media: null,
  onDrop: () => {},
  onHover: () => {},
  onMediaDeleted: () => {},
  supportsKiosk: false,
};

export default MediaCard;
