import { FlamingoContext } from 'contexts/flamingo';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { useContext } from 'react';

const useActiveNetworkLanguages = () => {
  const {
    activeNetwork: { configuration, id },
  } = useContext(FlamingoContext);

  if (id) {
    if (isGlobalTemplate(id)) {
      return Object.keys(LANGUAGE_CODES).map((v) => LANGUAGE_CODES[v]);
    }
  }

  return configuration?.features?.languages;
};

export default useActiveNetworkLanguages;
