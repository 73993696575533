import { Grid } from '@mui/material';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from 'helpers/form-util';
import { useUpdateNetworkConfiguration } from 'hooks/dataHooks';
import { makeValidate, TextField } from 'mui-rff';
import { createVersions } from 'pages/network/configuration/containers/version';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const generalSchema = Yup.object().shape({
  version: Yup.string().required('Version is required'),
  message: Yup.string().required('Messages is required'),
});

const validate = makeValidate(generalSchema);

const EditVersionModal = ({ version, versions, networkId, handleClose }) => {
  const [isEdit] = useState(version.key !== undefined);
  const [updateVersions] = useUpdateNetworkConfiguration();

  const handleSave = async (values) => {
    let result;
    if (isEdit) {
      const versionsClone = [...versions];
      const index = versionsClone.findIndex((v) => v.key === values.key);
      versionsClone[index] = values;
      const inputData = createVersions(versionsClone);
      result = await updateVersions({ variables: { input: inputData } });
    } else {
      const versionsClone = [...versions];
      versionsClone.push(values);
      const inputData = createVersions(versionsClone);
      result = await updateVersions({ variables: { input: inputData } });
    }
    if (result.data) handleClose();
  };
  return (
    <GenericFormModal
      title={isEdit ? 'Edit Version' : 'Add Version'}
      open={true}
      formParams={{
        onSubmit: handleSave,
        validate,
        initialValues: {
          platform: version?.platform || 'Android',
          state: version?.state || 'Not Supported',
          key: version?.key,
          version: version?.version,
          message: version?.message,
        },
      }}
      body={
        <Grid container direction="column" spacing={3}>
          <Grid item sx={{ marginTop: '8px' }}>
            <Field
              name="platform"
              component={({ input }) => (
                <GozioSelect
                  input={input}
                  label="Platform"
                  options={[
                    { id: 'Android', label: 'Android' },
                    { id: 'IOS', label: 'IOS' },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Field
              name="state"
              component={({ input }) => (
                <GozioSelect
                  input={input}
                  label="State"
                  options={[
                    { id: 'Not Supported', label: 'Not Supported' },
                    { id: 'End of Life', label: 'End of Life' },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label={getFormLabel('Version', true)}
              name="version"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label={getFormLabel('Message', true)}
              name="message"
              variant="outlined"
              fullWidth
              minRows={2}
              maxRows={5}
              multiline
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
      }
      confirmText={isEdit ? 'Save' : 'Add Version'}
      handleClose={handleClose}
    />
  );
};

export default EditVersionModal;
