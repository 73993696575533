const LABEL_SEPARATOR = '.';
const LABEL_ROOT = '';

export const ParseResultType = {
	Error: 'ERROR',
	Invalid: 'INVALID',
	Ip: 'IP',
	Reserved: 'RESERVED',
	NotListed: 'NOT_LISTED',
	Listed: 'LISTED',
};

export const RESERVED_TOP_LEVEL_DOMAINS = [
	'localhost',
	'local',
	'example',
	'invalid',
	'test',
];

export const ICANN_TOP_LEVEL_DOMAINS = ['com', 'net', 'co', 'org'];

const lookUpTlds = (labels) => {
	const labelsToCheck = labels.slice();
	const tlds = [];

	while (labelsToCheck.length !== 0) {
		const label = labelsToCheck.pop().toString();
		const labelLowerCase = label.toLowerCase();
		if (ICANN_TOP_LEVEL_DOMAINS.includes(labelLowerCase)) tlds.unshift(label);
	}
	return tlds;
};

const sanitize = (input) => {
	// Extra check for non-TypeScript users
	if (typeof input !== 'string') {
		return {
			type: ParseResultType.Error,
			errors: [
				`The given input ${String(input)} does not look like a hostname.`,
			],
		};
	}

	const inputTrimmed = input.trim();

	const labels = inputTrimmed.split(LABEL_SEPARATOR);
	const lastLabel = labels[labels.length - 1];

	// If the last label is the special root label, ignore it
	if (lastLabel === LABEL_ROOT) {
		labels.pop();
	}

	return {
		domain: inputTrimmed,
		labels,
	};
};

const splitLabelsIntoDomains = (labels, index) => ({
		subDomains: labels.slice(0, Math.max(0, index)),
		domain: getAtIndex(labels, index),
		topLevelDomains: labels.slice(index + 1),
	});
const getAtIndex = (array, index) => index >= 0 && index < array.length ? array[index] : undefined;

export const parseDomain = (hostname) => {
	const sanitizationResult = sanitize(hostname);

	if (sanitizationResult.type === ParseResultType.Error) {
		return {
			type: ParseResultType.Invalid,
			hostname,
			errors: sanitizationResult.errors,
		};
	}
	const { labels, domain } = sanitizationResult;

	if (
		hostname === ''
		|| RESERVED_TOP_LEVEL_DOMAINS.includes(labels[labels.length - 1])
	) {
		return {
			type: ParseResultType.Reserved,
			hostname: domain,
			labels,
		};
	}

	const icannTlds = lookUpTlds(labels);

	if (icannTlds.length === 0) {
		return {
			type: ParseResultType.NotListed,
			hostname: domain,
			labels,
		};
	}

	const indexOfPublicSuffixDomain = labels.length - icannTlds.length - 1;

	return {
		type: ParseResultType.Listed,
		hostname: domain,
		labels,
		...splitLabelsIntoDomains(labels, indexOfPublicSuffixDomain),
	};
};
