import { Add, Clear } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from 'components/textField/textField';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  row: {
    width: '100%',

    '&:not(:first-of-type)': {
      marginTop: '24px',
    },

    '> div > div > *': {
      width: '100%',
    },
  },
  clear: {
    color: theme.palette.grey[500],
  },
  delete: {
    alignContent: 'center',
    color: theme.palette.grey[500],
    paddingLeft: '8px',
  },
  addButton: {
    marginTop: '12px',
  },
});

const CustomTextList = ({
                          data,
                          textLabel,
                          addLabel,
                          onAdd,
                          addIcon,
                          onChange,
                          onDelete,
                          sx,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Grid container sx={sx}>
      {data && data.map((value, idx) => (
        <Grid key={`cust-list-${idx}`} container sx={styles.row}>
          <Grid item xs={11}>
            <TextField
              label={textLabel}
              value={value}
              onChange={(v) => onChange(v, idx)}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="Clear text"
                      edge="end"
                      onClick={() => onChange('', idx)}
                      sx={styles.clear}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sx={styles.delete}>
            <IconButton
              aria-label="Remove row"
              edge="end"
              onClick={() => onDelete(idx)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={data?.length > 0 ? styles.addButton : {}}>
        <Button
          color="primary"
          variant="contained"
          onClick={onAdd}
          startIcon={addIcon}
        >
          {addLabel}
        </Button>
      </Grid>
    </Grid>
  );
};

CustomTextList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  textLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  addLabel: PropTypes.string.isRequired,
  addIcon: PropTypes.node,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

CustomTextList.defaultProps = {
  addIcon: <Add />,
  sx: {},
};

export default React.memo(CustomTextList);
