export const HOUR_STATUSES = {
	OPEN: 'open',
	CLOSED: 'closed',
};

export const HOURS_OF_OPERATIONS = [
	{
		label: 'Customize Weekly Hours',
		id: 'custom',
	},
	{
		label: 'Always Open',
		id: 'open',
	},
	{
		label: 'Daylight Hours',
		id: 'daylight',
	},
	{
		label: 'Closed',
		id: 'closed',
	},
	{
		label: 'None',
		id: 'none',
	},
];

export const EXCEPTION_HOURS_OF_OPERATIONS = [
	{
		label: 'Customize Exception',
		id: 'custom',
	},
	{
		label: 'Always Open (24/7)',
		id: 'open',
	},
	{
		label: 'Daylight Hours',
		id: 'daylight',
	},
	{
		label: 'Closed',
		id: 'closed',
	},
	{
		label: 'None',
		id: 'none',
	},
];

export const EXCEPTION_TYPE_TO_LABEL_MAP = {
	closed: 'Closed',
	custom: 'Custom Hours',
	daylight: 'Daylight Hours',
	open: 'Always Open (24/7)',
	none: 'None',
};

export const DEFAULT_START_AND_END_TIMES = {
	startTime: '00:00',
	endTime: '00:00',
};

export const DEFAULT_CUSTOM_HOURS = [
	{
		day: 'sunday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'monday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'tuesday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'wednesday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'thursday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'friday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
	{
		day: 'saturday',
		status: HOUR_STATUSES.OPEN,
		hours: [{ startTime: '00:00', endTime: '00:00' }],
	},
];

export const ADDRESS_FIELDS = ['street', 'streetNumber', 'building', 'floor', 'suite', 'city', 'state', 'zip'];

export const US_STATE_CODES = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY',
];

const timeToDate = (time) => {
	const date = new Date();
	date.setHours(time.substring(0, 2));
	date.setMinutes(time.substring(3, 5));
	date.setSeconds(0);
	return date;
};

export const validateStartAndEndTime = (startTime, endTime) => {
	if (!startTime || !endTime) {
		return false;
	}
	if (
		!startTime?.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)
		|| !endTime?.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)
	) {
		return false;
	}

	const startDate = timeToDate(startTime);
	const endDate = timeToDate(endTime);
	return startDate < endDate;
};

export const filterExceptionHours = (list) => list?.map(({ __typename, id, custom, ...rest }) => ({
		custom:
			custom?.map(({ __typename, hours, ...customRest }) => ({
				hours: hours?.map(({ __typename, ...hoursRest }) => hoursRest) || [],
				...customRest,
			})) || [],
		...rest,
	})) ?? [];

export const HOURS_VALIDATION_OBJECT = {
	// hoursType: Yup.string().oneOf(HOURS_OF_OPERATIONS.map(({ id }) => id)),
	// customHours: Yup.array().when('hoursType', {
	// 	is: (hoursType) => hoursType === 'custom',
	// 	then: (schema) => schema
	// 		.of(
	// 			Yup.object()
	// 				.shape({
	// 					day: Yup.string()
	// 						.oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
	// 						.required('Day is required'),
	// 					status: Yup.string()
	// 						.oneOf([HOUR_STATUSES.OPEN, HOUR_STATUSES.CLOSED])
	// 						.required('Status is required'),
	// 					hours: Yup.array()
	// 						.of(
	// 							Yup.object().shape({
	// 								startTime: Yup.string().matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/),
	// 								endTime: Yup.string().matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/),
	// 							})
	// 						)
	// 						.required('Hours is required.'),
	// 				})
	// 				.required('Custom hours is required.')
	// 		)
	// 		.required('Custom hours is required.')
	// 		.test({
	// 			message: 'Invalid custom hours.',
	// 			test: (list) => {
	// 				if (list.length !== 7) {
	// 					return false;
	// 				}
	// 				const error = list.find((h) => {
	// 					if (h.status === 'open') {
	// 						if (h.hours.length < 1) {
	// 							return true;
	// 						}
	//
	// 						const hour = h.hours[0];
	// 						if (!validateStartAndEndTime(hour.startTime, hour.endTime)) {
	// 							return true;
	// 						}
	// 					}
	//
	// 					return false;
	// 				});
	//
	// 				return !error;
	// 			},
	// 		}),
	// })
};
