import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from 'helpers/config-util';
import { parseDomain, ParseResultType } from 'helpers/domainParser';
import { resetAllTableSettings } from 'helpers/table-util';
import LogRocket from 'logrocket';

export const SSO_LOGOUT = new Map(getConfig().SSO_LOGOUT || []);

/**
 * Handles user logout. For an SSO user, the login state is managed by the network's SSO service. Logging out of Portal will only mean that the user is returned to the Logged Out page on Portal. This page is defined in the `SSO_LOGOUT` configuration and is mapped by the 4LD in the domain.
 */
const useLogout = () => {
  const { logout } = useAuth0();

  const parseResult = parseDomain(window.location.hostname);

  let redirectUrl = null;
  switch (parseResult.type) {
    case ParseResultType.Listed: {
      const { subDomains } = parseResult;

      const result = subDomains.filter((sub) => SSO_LOGOUT.has(sub));
      if (result.length) {
        redirectUrl = SSO_LOGOUT.get(result[0]);
        LogRocket.info('[parseDomain] redirectUrl: ', redirectUrl);
      }
      break;
    }
    case ParseResultType.Reserved:
    case ParseResultType.NotListed: {
      const { hostname } = parseResult;
      LogRocket.info(
        `[parseDomain]: ${hostname} is a reserved or unknown domain`,
      );
      break;
    }
    default:
      const { hostname } = parseResult;
      LogRocket.info(
        `[parseDomain]: ${hostname} is an ip address or invalid domain`,
      );
      break;
  }

  const returnTo = redirectUrl || window.location.origin;

  return async () => {
    localStorage.clear();
    resetAllTableSettings();
    await logout({ logoutParams: { returnTo } });
  };
};

export default useLogout;
