import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GenericModal from 'components/genericModal/genericModal';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormSpy } from 'react-final-form';

const buildStyles = ({ theme }) => ({
  modalContent: {
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  sectionName: {
    color: theme.palette.grey[600],
  },
  fieldName: {
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(2),
  },
});

const createErrorList = (errors, formFieldMap) => {
  const errorObj = {};
  for (const err in errors) {
    if (formFieldMap.has(err)) {
      formFieldMap.get(err)?.section in errorObj
        ? errorObj[formFieldMap.get(err)?.section]?.push(
          formFieldMap.get(err)?.label,
        )
        : errorObj[formFieldMap.get(err)?.section] = [
          formFieldMap.get(err)?.label,
        ];
    } else {
      // this handles new things not added to list above by accident so we don't have blank dialog
      'Miscellaneous' in errorObj
        ? errorObj.Miscellaneous.push(err)
        : errorObj.Miscellaneous = [err];
    }
  }
  return errorObj;
};

const FormErrorsModal = ({
                           disableCancel,
                           formFieldMap,
                           open,
                           onCancel,
                           onClose,
                         }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const errors = useRef();

  return (
    <GenericModal
      title="Input Errors"
      open={open}
      handleClose={onClose}
      maxWidth="sm"
      fullWidth
      cancelText={disableCancel ? null : 'Continue'}
      confirmText="Correct Errors"
      handleConfirm={onClose}
      overrideCancel={disableCancel ? null : onCancel}
      disableCancel={disableCancel}
      body={
        <Grid
          container
          direction="column"
          spacing={2}
          sx={styles.modalBody}
        >
          <Grid item>
            <Typography variant="body1" sx={styles.modalContent}>
              The fields below have improper inputs that need to be corrected.
            </Typography>
          </Grid>
          <FormSpy subscription={{ errors: true }}>
            {(props) => {
              const errorList = [];
              errors.current = props.errors;
              const errorObj = createErrorList(props.errors, formFieldMap);
              for (const section in errorObj) {
                errorList.push(
                  <Grid item key={section} sx={{ paddingBottom: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={styles.sectionName}
                      gutterBottom
                    >
                      {section}
                    </Typography>
                    {errorObj[section]?.map((field) => (
                      <Typography
                        key={field}
                        variant="subtitle1"
                        sx={styles.fieldName}
                        gutterBottom
                      >
                        {field}
                      </Typography>
                    ))}
                  </Grid>
                );
              }
              return errorList;
            }}
          </FormSpy>
        </Grid>
      }
    />
  );
};

FormErrorsModal.propTypes = {
  disableCancel: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  formFieldMap: PropTypes.object.isRequired,
};

FormErrorsModal.defaultProps = {
  disableCancel: true,
  onCancel: null,
};

export default FormErrorsModal;
