import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Autorenew, GetApp } from '@mui/icons-material';

const useStyles = makeStyles(({ spacing, palette }) => ({
  downloadIcon: {
    color: palette.blue[400],
    height: spacing(2.5),
    width: spacing(2.5),
  },
  downloadText: {
    fontSize: spacing(2),
  },
  downloadWrapper: {
    cursor: 'pointer',
  },
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const DownloadButton = ({ downloadButtonText, downloadInProgress, downloadInProgressText, handleDownload, showIcon }) => {
  const classes = useStyles();

  return (
    <>
      {downloadInProgress && (
        <Grid container>
          <Grid item>
            <Autorenew className={classes.rotateIcon} />
          </Grid>
          <Grid item sx={{ marginLeft: '8px' }}>
            <Typography variant="body1">
              {downloadInProgressText || 'Processing Download Request...'}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!downloadInProgress && (
        <Grid container>
          <Grid item sx={{ zIndex: 10 }}>
            <Grid
              container
              className={classes.downloadWrapper}
              onClick={handleDownload}
            >
              {showIcon && (<Grid item>
                <GetApp className={classes.downloadIcon} />
              </Grid>)}
              <Grid item>
                <Link className={classes.downloadText}>
                  {downloadButtonText || 'Download'}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

DownloadButton.propTypes = {
  dowloadButtonText: PropTypes.string,
  downloadInProgressText: PropTypes.string,
  downloadInProgress: PropTypes.bool,
  handleDownload: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
};

DownloadButton.defaultProps = {
  downloadInProgress: false,
  showIcon: true,
};

export default React.memo(DownloadButton);
