import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import PageHelpers from 'helpers/pageHelpers';
import { useNetworkPlace } from 'hooks/dataHooks';
import BuildingsPanel from 'pages/locations/containers/detail/buildingsPanel';
import LandmarksPanel from 'pages/locations/containers/detail/landmarksPanel';
import POIPanel from 'pages/locations/containers/detail/poiPanel';
import { buildValidator, initialParser, submitParser } from 'pages/locations/containers/detail/siteFormHelper';
import DetailContainer from 'pages/locations/containers/detailContainer';
import ActionLinks from 'pages/locations/containers/sections/actionLinks';
import Address from 'pages/locations/containers/sections/address';
import Details from 'pages/locations/containers/sections/details';
import Hours from 'pages/locations/containers/sections/hours';
import MappingInputs from 'pages/locations/containers/sections/mappingInputs';
import MapVisibility from 'pages/locations/containers/sections/mapVisibility';
import Media from 'pages/locations/containers/sections/media';
import Searchability from 'pages/locations/containers/sections/searchability';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const sections = [
  {
    text: 'Mapping Inputs',
    hash: 'mapping',
    conditional: ({ hasPermissions, data }) => hasPermissions && data?.isMapped,
    component: MappingInputs,
  },
  {
    text: 'Site Details',
    hash: 'site-details',
    component: Details,
  },
  {
    text: 'Address',
    hash: 'address',
    component: Address,
  },
  {
    text: 'Media',
    hash: 'media',
    component: Media,
  },
  {
    text: 'Map Visibility',
    hash: 'mapVisibility',
    component: MapVisibility,
  },
  {
    text: 'Searchability',
    hash: 'searchability',
    component: Searchability,
  },
  {
    text: 'Quicklinks',
    hash: 'links',
    component: ActionLinks,
  },
  {
    text: 'Hours',
    hash: 'hours',
    component: Hours,
  },
];

const SiteDetailPage = () => {
  const { networkId, siteId } = useParams();
  const { data: place, error } = useNetworkPlace({
    id: siteId,
    skipFunc: (site) => site.id !== undefined,
  });

  const tabItems = [
    { key: 'site', title: 'Site Information' },
    {
      key: 'buildings',
      title: 'Buildings',
      mapFunc: ({ panelClass, isUberAdmin, data, networkId }) => ({
        sx: panelClass,
        networkId,
        isUberAdmin,
        buildings: data?.buildings?.edges || [],
        site: data,
      }),
      Component: BuildingsPanel,
    },
    {
      key: 'pois',
      title: 'POIS',
      enableForm: true,
      mapFunc: ({ panelClass, isUberAdmin, data, networkId }) => ({
        sx: panelClass,
        networkId,
        isUberAdmin,
        site: data,
        pois: data?.pois?.edges || [],
        buildings:
          (data?.buildings?.edges || []).filter(
            (desc) => desc.node.isMapped === false,
          ) || [],
      }),
      Component: POIPanel,
    },
    {
      key: 'landmarks',
      title: 'Landmarks',
      mapFunc: ({ panelClass, isUberAdmin, data, networkId }) => ({
        sx: panelClass,
        networkId,
        landmarks: data?.landmarks?.edges || [],
      }),
      Component: LandmarksPanel,
    },
  ];

  if (place.isMapped === false) {
    tabItems.pop();
  }

  if (error) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  if (isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  return (
    <PageHelpers showWorkspaceSwitcher={false}>
      <DetailContainer
        networkId={networkId}
        networkPlaceId={siteId}
        sections={sections}
        initialParser={initialParser}
        submitParser={submitParser}
        validator={buildValidator}
        backText="Back to Locations"
        data={place}
        backUrl={`/network/${networkId}/locations`}
        showNetworkLogo={true}
        tabItems={tabItems}
        pageTitle={place?.network?.name}
        pageSubtitle={getLabelByLang(place?.name)}
      />
    </PageHelpers>
  );
};

export default React.memo(SiteDetailPage);
