import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AutoComplete from 'components/autocomplete/autoComplete';
import CategorySelection from 'components/categories/categorySelection';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import GenericFormModal from 'components/genericModal/genericFormModal';
import Loading from 'components/loading/loading';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from 'helpers/form-util';
import { getLabelByLang } from 'helpers/lang-util';
import { useCategories } from 'hooks/dataHooks';
import { useListAvailableLocations } from 'hooks/dataHooks/useLists';
import { useWorkspace } from 'hooks/useWorkspace';
import { makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const buildStyles = ({ theme }) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '40px',
    marginBottom: theme.spacing(4),
  },
  searchType: {
    marginTop: theme.spacing(4),
  },
});

const addLocationsSchema = Yup.object().shape({
  sectionId: Yup.string().required().nullable(),
  locationId: Yup.string().when('searchType', {
    is: 'singleLocation',
    then: (schema) => schema.required().nullable(),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  categoryIds: Yup.array().when('searchType', {
    is: 'multipleLocationsByCategories',
    then: (schema) => schema
        .of(
          Yup.object({
            name: Yup.string().notRequired(),
            id: Yup.string().required(),
            checked: Yup.boolean().notRequired(),
          }),
        )
        .required()
        .nullable(),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  searchType: Yup.string(),
});

const validate = makeValidate(addLocationsSchema);

const AddListLocationModal = ({ handleClose, handleSaved, list }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { loading: listLoading, list: listWithAvailableLocations }
    = useListAvailableLocations(list.id);
  const { categories: categoriesData, loading: categoriesQueryLoading }
    = useCategories();

  const { workspace } = useWorkspace();

  // Filter out locations that have already been assigned to sections
  const unavailableLocationIds = useMemo(
    () => list.sections
        .map(({ locations }) => locations)
        .filter((locations) => locations.length > 0)
        .flat()
        .map(({ id }) => id),
    [list],
  );

  const mappedSingleLocations = useMemo(() => {
    const mapLocations = (list) => list?.availableLocations
        .filter(({ id }) => !unavailableLocationIds?.includes(id))
        .filter(
          ({ designation, workspaces }) => workspaces[0]?.name === workspace
            && ['site', 'building', 'poi'].includes(designation),
        )
        .map((location) => {
          const { id, name, geoLocation, designation } = location;
          const address = {
            street: geoLocation?.address?.street,
            city: geoLocation?.address?.city,
            state: geoLocation?.address?.state,
            zip: geoLocation?.address?.zip,
          };
          const addressValues = Object.values(address).filter((v) => v);
          const parents = [];
          let x = location;
          while (x.parent) {
            parents.push(getLabelByLang(x.parent.name));
            x = x.parent;
          }
          const mappedLocation = {
            id,
            type: designation,
            label: getLabelByLang(name),
            label2:
              designation !== 'poi' && addressValues?.length && parents?.length
                ? `${parents.join(', ')}, ${addressValues.join(', ')}`
                : addressValues?.length
                  ? addressValues.join(', ')
                  : parents.join(', '),
          };
          return mappedLocation;
        });

    return mapLocations(listWithAvailableLocations);
  }, [listWithAvailableLocations, unavailableLocationIds, workspace]);

  const handleSubmit = (fields) => {
    handleSaved({
      ...fields,
      unavailableLocationIds,
      categoryIds: fields.categoryIds
        ? fields.categoryIds.map(({ id }) => id)
        : null,
    });
  };

  return (
    <GenericFormModal
      title="Add a Location"
      fullWidth
      maxWidth={'sm'}
      formParams={{
        onSubmit: handleSubmit,
        validate,
        initialValues: {
          locationId: null,
          categoryIds: null,
          sectionId: null,
          searchType: 'singleLocation',
        },
      }}
      body={({ form }) => (
        <Grid container direction="column">
          {!(categoriesQueryLoading || listLoading) && (
            <>
              <Grid container direction="column">
                <FormSubSection name={getFormLabel('General')}>
                  <Grid item xs={12}>
                    <Field name="sectionId">
                      {({ input, meta }) => (
                        <GozioSelect
                          label={getFormLabel('Select Section', true)}
                          options={list?.sections?.map(({ id, name }) => ({
                            id,
                            label: getLabelByLang(name),
                          }))}
                          input={input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sx={styles.searchType}>
                    <Field name="searchType">
                      {({ input, meta }) => (
                        <GozioSelect
                          label={getFormLabel('Search Type', true)}
                          options={[
                            {
                              id: 'singleLocation',
                              label: 'Single Location',
                            },
                            {
                              id: 'multipleLocationsByCategories',
                              label: 'Location(s) by Categories',
                            },
                          ]}
                          input={{
                            ...input,
                            onChange: (value) => {
                              form.batch(() => {
                                form.change('locationId', null);
                                form.change('searchType', value);
                              });
                            },
                          }}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </Grid>
                </FormSubSection>
              </Grid>
              <Grid item xs={12}>
                <Condition when="searchType" is="singleLocation">
                  <FormSubSection
                    name={getFormLabel('Single Location Search', true)}
                    sx={{ paddingBottom: 0 }}
                  >
                    <Field name="locationId">
                      {({ input }) => (
                        <AutoComplete
                          label={'Select or Search for a Location'}
                          options={mappedSingleLocations}
                          input={input}
                          required
                        />
                      )}
                    </Field>
                  </FormSubSection>
                </Condition>
                <Condition when="searchType" is="multipleLocationsByCategories">
                  <FormSubSection
                    name={getFormLabel('Selected Categories', true)}
                    sx={{
                      paddingBottom: 0,
                      position: 'relative',
                    }}
                  >
                    <Field name="categoryIds">
                      {({ input }) => (
                        <CategorySelection
                          input={input}
                          categoriesData={categoriesData}
                          callToAction="No Categories Have Been Selected"
                        />
                      )}
                    </Field>
                  </FormSubSection>
                </Condition>
              </Grid>
            </>
          )}
          {(categoriesQueryLoading || listLoading) && (
            <>
              <Loading />
            </>
          )}
        </Grid>
      )}
      confirmText="ADD LOCATION(S)"
      handleClose={handleClose}
    />
  );
};

AddListLocationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSaved: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  sectionData: PropTypes.object,
};

AddListLocationModal.defaultProps = {
  sectionData: null,
};

export default AddListLocationModal;
