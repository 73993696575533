import { getConfig } from 'helpers/config-util';
import intersection from 'lodash/intersection';

export const USER_ROLES = {
  UBER_ADMIN: 'uber-admin',
  NETWORK_ADMIN: 'network-admin',
  SDK_ADMIN: 'sdk-admin',
};
Object.freeze(USER_ROLES);

export const checkVisibility = (params) => {
  const {
    permissions = [],
    type = null,
    section,
    allowed = [],
    except = [],
  } = params;
  const tempPerm = [...permissions];
  if (tempPerm.includes('*')) return true;
  if (!tempPerm.includes('Dashboard')) tempPerm.push('Dashboard');
  if (intersection(tempPerm, createPermissions(type, section, except)).length) return false;
  //Temporary until desciion on 403
  if (intersection(tempPerm, createPermissions(type, section, allowed)).length) return true;

  return false;
};

const createPermissions = (type, section, actions) => {
  if (actions.length === 0) return [];
  const actionsArray = type
    ? actions.map((x) => `${type}${section}:${x}`)
    : actions.map((x) => `${section}:${x}`);
  actionsArray.push(type ? `${type}${section}` : section);
  return actionsArray;
};

export const featureFlagCheck = (flag) => {
  const featureFlags = getConfig().featureFlag;
  if (featureFlags.includes(flag)) return true;
  return false;
};

export const rolePublicName = (role) => {
  switch (role) {
    case USER_ROLES.UBER_ADMIN:
      return 'Gozio Admin';
    case USER_ROLES.NETWORK_ADMIN:
      return 'Admin';
    case USER_ROLES.SDK_ADMIN:
      return 'SDK Admin';
    default:
      return role;
  }
};

export default {
  checkVisibility,
};
