import { useMutation, useSuspenseQuery } from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import useHandleError from 'hooks/useHandleError';
import {
  GET_NETWORK_DATA_VERSIONS,
  CREATE_NETWORK_CHANNEL,
  FIND_NETWORK_CHANNEL,
  UPDATE_NETWORK_CHANNEL,
  FIND_NETWORK_CONTENT_PROGRESS,
} from 'graphql/queries';

export const useNetworkChannels = (networkId) => {
  const { handleError } = useHandleError('NetworkContentProgress');
  return useSuspenseQuery(FIND_NETWORK_CHANNEL, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
  });
};

export const useGetNetworkChannelsAndContentProgress = (networkId) => {
  const { handleError } = useHandleError('NetworkContentProgress');

  return useSuspenseQuery(FIND_NETWORK_CONTENT_PROGRESS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { workspaces: [LIVE_WORKSPACE, DRAFT_WORKSPACE] },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
  });
};

export const useNetworkDataVersions = (networkId) => {
  const { handleError } = useHandleError('Network');
  const { data } = useSuspenseQuery(GET_NETWORK_DATA_VERSIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    variables: { networkId },
  });

  return {
    data: data?.getNetwork,
  };
};

export const useCreateNetworkChannel = () => {
  const { handleError } = useHandleError('NetworkChannel');

  return useMutation(CREATE_NETWORK_CHANNEL, {
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [
      {
        query: FIND_NETWORK_CHANNEL,
      },
    ],
  });
};

export const useUpdateNetworkChannel = () => {
  const { handleError } = useHandleError('NetworkChannel');

  return useMutation(UPDATE_NETWORK_CHANNEL, {
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [
      {
        query: FIND_NETWORK_CHANNEL,
      },
    ],
  });
};
