const sections = {
  ANALYTICS: 'Analytics',
  BANNER_MESSAGE: 'BannerMessage',
  BASIC_CONFIG: 'Configuration',
  CATEGORY: 'Category',
  CATEGORY_GROUP: 'PrimaryCategory',
  CONFIGURATION: 'Configuration',
  CURATED_LIST: 'CuratedList',
  DASHBOARD: 'Dashboard',
  EXPLORE: 'ExplorePanel',
  FEATURES: 'ConfigurationFeatures',
  LOCATIONS: 'NetworkPlace',
  MOBILE_DASHBOARD: 'MobileDashboard',
  MYCHART: 'MyChart',
  NAVIGATION: 'Navigation',
  PERSONAS: 'Persona',
  PUBLISHING: 'Publish',
  PUSH_NOTIFICATIONS: 'Notification',
  TYPEAHEAD: 'Typeahead',
  USER: 'User',
  PROMOTION: 'Promotion',
  KIOSK: 'Kiosk',
  KIOSK_TEMPLATE: 'KioskTemplate',
  VISUAL_CONTROLS: 'VisualControls',
  NONE: '',
};

Object.freeze(sections);

export default sections;
