import { useMutation, useSuspenseQuery } from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';
import {
  CREATE_USER,
  FIND_USER,
  GET_NETWORK_USERS,
  UPDATE_USER,
} from 'graphql/queries';

export const convertRemoteUsersData = (data, isGlobal) => {
  if (isGlobal) {
    return {
      users: data?.findUser?.filter((user) => user?.roles?.[0]?.internal) || [],
      roles: [],
    };
  }

  return {
    users: data?.getNetwork?.users || [],
    roles: data?.system?.roles || [],
  };
};

export const useUsers = (networkId) => {
  const { handleError } = useHandleError('Users');

  const isGlobal = isGlobalTemplate(networkId);

  const { data: internalData, refetch: internalRefetch } = useSuspenseQuery(
    FIND_USER,
    {
      fetchPolicy: 'no-cache',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
      context: { headers: { network: networkId } },
      skip: !isGlobal,
    },
  );

  const { data: networkData, refetch: networkRefetch } = useSuspenseQuery(
    GET_NETWORK_USERS,
    {
      variables: {
        networkId,
      },
      fetchPolicy: 'no-cache',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
      context: { headers: { network: networkId } },
      skip: isGlobal,
    },
  );

  return {
    data: convertRemoteUsersData(
      isGlobal ? internalData : networkData,
      isGlobal,
    ),
    refetch: isGlobal ? internalRefetch : networkRefetch,
  };
};

export const useCreateUser = ({ networkId, onCompleted, onError }) => {
  const { handleError } = useHandleError('Users');

  return useMutation(CREATE_USER, {
    errorPolicy: 'all',
    onCompleted,
    onError: onError
      ? onError
      : ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    context: { headers: { network: networkId } },
    refetchQueries: [
      {
        query: networkId === 'Global Template' ? FIND_USER : GET_NETWORK_USERS,
        variables: { networkId },
      },
    ],
  });
};

export const useUpdateUser = (networkId) => {
  const { handleError } = useHandleError('Users');

  return useMutation(UPDATE_USER, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    context: { headers: { network: networkId } },
    refetchQueries: [
      {
        query: networkId === 'Global Template' ? FIND_USER : GET_NETWORK_USERS,
        variables: { networkId },
      },
    ],
  });
};
