import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Help from 'components/help/help';
import HelpDrawer from 'components/help/helpDrawer';
import CustomerMenu from 'components/navigation/customerMenu';
import NetworkSelector from 'components/navigation/networkSelector';
import SectionDrawer from 'components/navigation/sectionDrawer';
import { FlamingoContext } from 'contexts/flamingo';
import { getConfig } from 'helpers/config-util';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: { position: 'relative' },
}));

const FlamingoNavigation = ({ isAuthenticated, networkId }) => {
  const classes = useStyles();
  const {
    activeNetwork,
    layout: { openDrawer, openNetwork, setOpenNetwork },
    networks,
  } = useContext(FlamingoContext);

  const [showNeedHelpDrawer, setShowNeedHelpDrawer] = useState(false);

  const handleClose = () => {
    setOpenNetwork(false);
  };

  if (isAuthenticated) {
    return (
      <Box className={classes.root}>
        <CustomerMenu customerName={activeNetwork?.humanName || ''} />
        {openDrawer && (
          <SectionDrawer
            customerName={activeNetwork?.humanName || ''}
            networkId={networkId}
            showMenu={isAuthenticated}
          />
        )}
        <NetworkSelector
          open={openNetwork}
          customerName={activeNetwork?.humanName || ''}
          handleClose={handleClose}
          loading={networks?.length === 0}
          networks={networks}
        />
        {!showNeedHelpDrawer && (
          <Help onClick={() => setShowNeedHelpDrawer(true)} />
        )}
        {showNeedHelpDrawer && (
          <HelpDrawer
            onClick={() => {
              const url = getConfig().HELP_CENTER_URL;
              if (url) {
                window.open(url, '_blank');
              } else {
                console.warn('HELP_CENTER_URL is not set!');
              }
            }}
            onClose={() => setShowNeedHelpDrawer(false)}
          />
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <SectionDrawer
        customerName={activeNetwork?.humanName || ''}
        networkId={networkId}
        showMenu={false}
      />
    </Box>
  );
};

FlamingoNavigation.propTypes = {
  isAuthenticated: PropTypes.bool,
  networkId: PropTypes.string,
};

FlamingoNavigation.defaultProps = {
  isAuthenticated: true,
  networkId: null,
};

export default FlamingoNavigation;
