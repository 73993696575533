import { Clear } from '@mui/icons-material';
import { Chip as MuiChip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme: { palette } }) => ({
  // filter
  rootFilter: {},
  defaultFilter: {
    border: `1px solid ${palette.grey[400]}`,
    color: palette.grey[500],
    fontWeight: 400,
    '& svg': {
      color: palette.grey[400],
    },
    '&:hover': {
      backgroundColor: palette.white,
      borderColor: palette.blue[400],
      color: palette.blue[400],
      fontWeight: 700,
      '& svg': {
        color: palette.blue[400],
      },
    },
  },
  selectedFilter: {
    border: `2px solid ${palette.blue[400]}`,
    color: palette.blue[400],
    backgroundColor: palette.white,
    fontWeight: 700,
    '& svg': {
      color: palette.blue[400],
    },
  },
  activeFilter: {
    border: `1px solid ${palette.blue[400]}`,
    color: palette.blue[400],
    fontWeight: 400,
    '& svg': {
      color: palette.blue[400],
    },
  },
  disabledFilter: {
    border: `2px solid ${palette.grey[300]}`,
    color: palette.grey[300],
    fontWeight: 'bold',
    '& svg': {
      color: palette.grey[300],
    },
  },

  // primary
  rootPrimary: {},
  defaultPrimary: {
    border: `1px solid ${palette.blue[400]}`,
    backgroundColor: palette.blue[50],
    fontWeight: 400,
    color: palette.blue[400],
    '& svg': {
      color: palette.blue[400],
    },
    '&:hover': {
      backgroundColor: palette.blue[50],
      boxShadow: `0 2px 4px 0px ${palette.grey[350]}`,
      color: palette.blue[400],
      fontWeight: 700,
      '& svg': {
        color: palette.blue[400],
      },
    },
  },
  selectedPrimary: {
    backgroundColor: palette.blue[50],
    border: `2.5px solid ${palette.blue[400]}`,
    color: palette.blue[400],
    fontWeight: 700,
    '& svg': {
      color: palette.blue[400],
    },
    '&:hover': {
      backgroundColor: palette.blue[50],
      color: palette.blue[400],
    },
  },
  activePrimary: {
    backgroundColor: palette.blue[400],
    color: palette.white,
    fontWeight: 700,
    '& svg': {
      color: palette.white,
    },

    '&:hover': {
      boxShadow: `0 2px 4px 0px ${colorWithAlpha(palette.grey[350], 0.4)}`,
    },
  },

  inactiveSelectedPrimary: {
    background: palette.white,
    borderRadius: '4px',
    border: `2.5px solid ${palette.grey[400]}`,
    color: palette.grey[500],
    fontWeight: 'bold',
    padding: '6.55px 10.5px',

    '& svg': {
      color: palette.grey[400],
    },
  },

  inactivePrimary: {
    background: palette.white,
    borderRadius: '4px',
    border: `1px solid ${palette.grey[400]}`,
    color: palette.grey[500],
    fontWeight: 'normal',

    '& svg': {
      color: palette.grey[400],
    },

    '&:hover': {
      boxShadow: `0 2px 4px 0px ${palette.grey[350]}`,
      fontWeight: 'bold',
    },
  },

  // secondary
  rootSecondary: {
    borderRadius: '18px',
  },
  defaultSecondary: {
    border: `1px solid ${palette.blue[400]}`,
    color: palette.blue[400],

    '& svg': {
      color: palette.blue[400],
    },

    '&:hover': {
      border: `1px solid ${palette.blue[400]}`,
      color: palette.blue[400],
      fontWeight: 700,
      '& svg': {
        color: palette.blue[400],
      },
    },
  },
  inactiveSecondary: {
    background: palette.white,
    border: `1px solid ${palette.grey[400]}`,
    color: palette.grey[500],

    '&:hover': {
      fontWeight: 700,
    },

    '& svg': {
      color: palette.grey[400],
    },
  },
  inactiveSelectedSecondary: {
    background: palette.white,
    border: `2.5px solid ${palette.grey[400]}`,
    color: palette.grey[500],
    fontWeight: 700,
    '&:hover': {
      border: `1px solid ${palette.grey[400]}`,
      paddingRight: '13px',
      paddingLeft: '13px',
    },
    '& svg': {
      color: palette.grey[400],
    },
  },
  selectedSecondary: {
    background: palette.white,
    borderRadius: '18px',
    border: `2.5px solid ${palette.blue[400]}`,
    color: palette.blue[400],
    fontWeight: 700,

    '& svg': {
      color: palette.blue[400],
    },
  },
});

const Chip = ({
                disabled,
                icon,
                label,
                onClick,
                onIconClick,
                clickableIcon,
                state,
                sx,
                variant,
                selectable,
              }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [selected, setSelected] = useState(false);

  const handleClick = (params) => {
    if (!selected) {
      onClick(params);
    }

    setSelected(!selected);
  };

  const rest = {};
  if (onClick && selectable) {
    rest.clickable = true;
    rest.onClick = handleClick;
  } else {
    rest.onClick = onClick;
  }

  if (onIconClick) {
    rest.onDelete = onIconClick;
    rest.deleteIcon = clickableIcon ?? <Clear />;
  }

  let type = 'default';
  if (disabled) {
    type = 'disabled';
  } else if (selected) {
    if (variant !== 'filter' && state === 'inactive') {
      type = 'inactiveSelected';
    } else {
      type = 'selected';
    }
  } else if (state === 'active') {
    type = 'active';
  } else if (state === 'inactive') {
    type = 'inactive';
  }

  const ucVariant = capitalize(variant);

  return (
    <MuiChip
      sx={{
        ...styles[`root${ucVariant}`],
        ...styles[`${type}${ucVariant}`],
        ...sx,
      }}
      color="default"
      disabled={disabled}
      icon={icon}
      label={<span data-label={label}>{label}</span>}
      size="medium"
      {...rest}
    />
  );
};

Chip.propTypes = {
  clickableIcon: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  selectable: PropTypes.bool,
  state: PropTypes.oneOf(['active', 'default', 'inactive']),
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['filter', 'primary', 'secondary']),
};

Chip.defaultProps = {
  disabled: false,
  icon: null,
  onClick: null,
  onIconClick: null,
  clickableIcon: null,
  selectable: true,
  state: 'default',
  sx: {},
  variant: 'filter',
};

export default Chip;
