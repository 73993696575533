import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CategorySelections from 'components/categories/catSelections';
import CategoriesTable from 'components/categories/catsTable';
import CategoryTable from 'components/categories/catTable';
import GroupsTable from 'components/categories/groupsTable';
import GenericModal from 'components/genericModal/genericModal';
import GlobalFilter from 'components/tables/globalFilter';
import { colorWithAlpha } from 'helpers/color-util';
import { tabA11yProps } from 'helpers/page-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '859px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& .MuiDialogContent-root': {
        width: '100%',
        padding: 0,
      },
    },

    '& .MuiDialogContent-root': {
      marginBottom: 0,
    },
  },
  filterWrap: {
    padding: '12px 32px',
    backgroundColor: theme.palette.grey[100],
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '590px',
    overflowY: 'auto',
    paddingTop: '20px',
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`searchability-tabpanel-${index}`}
      aria-labelledby={`searchability-tab-${index}`}
      sx={{ flex: 1, overflow: 'scroll' }}
      {...other}
    >
      {children}
    </Box>
  );
};

const tabLabelOverride = (labelName = '', labelNumber = 0, active = false) => (
  <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
    {labelName}
    <Typography
      sx={{
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: `${active ? 700 : 400}px`,
        color: 'inherit',
        letterSpacing: 0,
        marginLeft: '4px',
      }}
    >
      {`(${labelNumber})`}
    </Typography>
  </Box>
);

const SearchabilityModal = ({
                              showModal,
                              setShowModal,
                              handleSave,
                              defaultSelectedCategories,
                              categoriesData,
                              confirmText,
                              disableCancel,
                              disableClear,
                            }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [filter, setFilter] = useState('');
  const [groupCount, setGroupCount] = useState(0);
  const [catCount, setCatCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(
    defaultSelectedCategories,
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRowSelectUpdates = (updates) => {
    let updatedCategories = [...selectedCategories];
    updates.forEach((update) => {
      if (update.checked) {
        if (!updatedCategories.find((cat) => cat.id === update.id)) updatedCategories.push(update);
      } else {
        updatedCategories = updatedCategories.filter(
          (cat) => cat.id !== update.id,
        );
      }
    });
    setSelectedCategories(updatedCategories);
  };

  const handleClear = () => {
    setSelectedCategories([]);
  };

  const clearButton = disableClear ? null : (
    <Button
      onClick={handleClear}
      color="primary"
      variant="outlined"
      data-test="clear-categories-btn"
      disabled={selectedCategories.length === 0}
      sx={{ minWidth: '120px' }}
    >
      Clear
    </Button>
  );

  return (
    showModal && (
      <GenericModal
        title={'Search Categories'}
        sx={styles.root}
        formContentSx={styles.formContent}
        scrollable
        body={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Box sx={styles.filterWrap}>
              <GlobalFilter
                filter={filter}
                setFilter={setFilter}
                placeholder={'Search'}
              />
            </Box>
            {currentCategory && (
              <CategoryTable
                category={currentCategory}
                filter={filter}
                setCurrentCategory={setCurrentCategory}
                selectedCategories={selectedCategories}
                handleRowSelectUpdates={handleRowSelectUpdates}
              />
            )}
            {!currentCategory && (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
                      ColorPalette.grey[350],
                      0.3,
                    )}`,
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="searchability tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ padding: '0 32px' }}
                  >
                    <Tab
                      label={
                        groupCount > 0
                          ? tabLabelOverride(
                            filter.length > 0
                              ? 'Category Group Results'
                              : 'Category Groups',
                            groupCount,
                            tabValue === 0,
                          )
                          : 'No Category Group Results'
                      }
                      {...tabA11yProps('searchability', 0)}
                    />
                    <Tab
                      label={
                        catCount > 0
                          ? tabLabelOverride(
                            filter.length > 0
                              ? 'Category Results'
                              : 'Categories',
                            catCount,
                            tabValue === 1,
                          )
                          : 'No Category Results'
                      }
                      {...tabA11yProps('searchability', 1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  {categoriesData.length > 0 && (
                    <GroupsTable
                      visible={tabValue === 0}
                      categories={categoriesData}
                      filter={filter}
                      handleCountUpdate={setGroupCount}
                      setCurrentCategory={setCurrentCategory}
                    />
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <CategoriesTable
                    visible={tabValue === 1}
                    categories={categoriesData}
                    filter={filter}
                    handleCountUpdate={setCatCount}
                    selectedCategories={selectedCategories}
                    handleRowSelectUpdates={handleRowSelectUpdates}
                  />
                </TabPanel>
              </Box>
            )}
            {selectedCategories.length > 0 && (
              <CategorySelections
                selectedCategories={selectedCategories}
                updateCategories={setSelectedCategories}
              />
            )}
          </Box>
        }
        open={true}
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={() => {
          handleSave(selectedCategories);
        }}
        greyOutConfirm={selectedCategories.length === 0}
        confirmText={confirmText}
        disableCancel={disableCancel}
        additionalLeftButton={clearButton}
      />
    )
  );
};

SearchabilityModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  defaultSelectedCategories: PropTypes.array,
  confirmText: PropTypes.string,
  disableCancel: PropTypes.bool,
  disableClear: PropTypes.bool,
};

SearchabilityModal.defaultProps = {
  showModal: true,
  defaultSelectedCategories: [],
  confirmText: 'Apply Category(s)',
  disableCancel: true,
  disableClear: false,
};

export default React.memo(SearchabilityModal);
