import { AddPhotoAlternate, Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MapImage from 'assets/care-near-me-small.png';
import { getLanguageLabel } from 'components/dashboard/cardLayouts/cardhelper';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: '250px',
    overflow: 'hidden',
    padding: '12px 12px 0 12px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  titleSection: {
    height: '56px',
  },
  title: {
    display: '-webkit-box',
    lineHeight: 1.1,
    marginTop: '10px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  subtitleSection: {
    minHeight: '40px',
  },
  subtitle: {
    display: '-webkit-box',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  imageSection: {
    width: '100%',
    height: '120px',
    marginTop: '-12px',
  },
  image: {
    display: 'inline-block',
    height: '120px',
    width: '149px',

    '& img': {
      borderRadius: '8px',
      height: '100%',
      width: '100%',
    },
  },
  placeholderImage: {
    backgroundColor: theme.palette.grey[350],
    borderRadius: '8px',
    display: 'inline-block',
    height: '120px',
    width: '149px',

    '& svg': {
      color: theme.palette.white,
      height: '80px',
      width: '80px',
      margin: '20px auto',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
});

export const A2cMapAndImageCard = ({
                                     backgroundColor,
                                     clickHandler,
                                     disabled,
                                     iconUrl,
                                     incompleteForPackaging,
                                     selected,
                                     subtitle,
                                     subtitle2,
                                     textColor,
                                     title,
                                   }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box
        sx={{
          ...styles.incompleteWarning,
          left: `${width - 50}px`,
          top: '6px',
        }}
      >
        <Info />
      </Box>
    </Box>
  );

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={12} sx={styles.titleSection}>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.title, color: textColor }}
        >
          {title}
        </Typography>
      </Grid>
      <Box sx={styles.imageSection}>
        <Box
          sx={{
            ...styles.image,
            marginLeft: incompleteForPackaging ? '-6px' : '-3px',
            marginRight: '5px',
          }}
        >
          <img src={MapImage} alt="Care Near Me" />
        </Box>
        {iconUrl && (
          <Box
            sx={{
              ...styles.image,
              marginLeft: '5px',
              marginRight: incompleteForPackaging ? '-6px' : '-3px',
            }}
          >
            <img alt="supporting" src={iconUrl} />
          </Box>
        )}
        {!iconUrl && (
          <Box
            sx={{
              ...styles.placeholderImage,
              marginLeft: '5px',
              marginRight: incompleteForPackaging ? '-6px' : '-3px',
            }}
          >
            <AddPhotoAlternate />
          </Box>
        )}
      </Box>
      <Grid item xs={6} sx={styles.subtitleSection}>
        <Typography
          variant="body2"
          sx={{ ...styles.subtitle, color: textColor }}
        >
          {getLanguageLabel(subtitle) ?? ''}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={styles.subtitleSection}>
        <Typography
          variant="body2"
          sx={{ ...styles.subtitle, color: textColor, marginLeft: '4px' }}
        >
          {getLanguageLabel(subtitle2) ?? ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

A2cMapAndImageCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  iconUrl: PropTypes.string,
  incompleteForPackaging: PropTypes.bool,
  selected: PropTypes.bool,
  subtitle: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  subtitle2: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

A2cMapAndImageCard.defaultProps = {
  disabled: false,
  iconUrl: null,
  incompleteForPackaging: false,
  selected: false,
  subtitle: [],
  subtitle2: [],
};

export default A2cMapAndImageCard;
