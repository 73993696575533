import { Checkbox, FormControlLabel } from '@mui/material';
import Colors from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = () => ({
  root: {
    '& .MuiFormControlLabel-label': {
      letterSpacing: '0.16px',
      lineHeight: '1.5rem',
      color: Colors.grey[600],
    },
    '& .MuiCheckbox-root': {
      paddingRight: '4px',

      '&.Mui-checked': {
        color: Colors.blue[400],
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      '&.Mui-checked': {
        color: Colors.grey[300],
      },
      '&:not(.Mui-checked)': {
        color: Colors.grey[300],
      },
      '&.Mui-disabled': {
        color: Colors.grey[400],
      },
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: Colors.grey[400],
    },
  },
  checkedDisabledLabel: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: Colors.grey[400],
    },
  },
});

const LabeledCheckbox = ({ checked, color, label, onChange, disabled, sx }) => {
  const styles = buildStyles();

  return (
    <FormControlLabel
      sx={{
        ...styles.root,
        ...checked && disabled && styles.checkedDisabledLabel,
        ...sx,
      }}
      control={<Checkbox checked={checked} onChange={onChange} color={color} />}
      label={label}
      disabled={disabled}
    />
  );
};

LabeledCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

LabeledCheckbox.defaultProps = {
  color: 'default',
  disabled: false,
  sx: {},
};

export default LabeledCheckbox;
