import { Sync, SyncProblem } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProgressCard from 'components/progressCard/progressCard';
import { capitalize } from 'helpers/lang-util';
import { useWorkspace } from 'hooks/useWorkspace';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    '& > p': {
      marginTop: theme.spacing(1),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  channelsContainer: {
    marginTop: theme.spacing(3),
  },
  publishedVersionText: {
    fontSize: '12px',
  },
  channel: {
    marginTop: '24px',
  },
  inSyncIcon: {
    color: theme.palette.success.light,
  },
});

const getPublishedVersion = (publishChannel) => {
  if (!publishChannel || publishChannel?.published?.length === 0) {
    return 'There is nothing published to this channel.';
  }

  return `Current Publish # ${publishChannel.published[0].publishVersion}`;
};

const ChannelStatus = ({ networkChannels, isUberAdmin }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { workspace } = useWorkspace();

  const channels = useMemo(
    () => networkChannels?.map(({ label, isInSync, publishChannel, id }, index) => (
        <Grid key={id} sx={{ ...index > 1 && styles.channel }} item xs={6}>
          <Box>
            <ProgressCard
              large
              actionable={false}
              title={label}
              description={`Is ${
                isInSync ? 'In' : 'Out of'
              } Sync With the Current Network's Data`}
              icon={
                isInSync ? <Sync sx={styles.inSyncIcon} /> : <SyncProblem />
              }
              children={
                <Typography variant="body1" sx={styles.publishedVersionText}>
                  {getPublishedVersion(publishChannel)}
                </Typography>
              }
              tooltip={`${label} ID: ${id}`}
              showTooltip={isUberAdmin}
              status="inactive"
            />
          </Box>
        </Grid>
      )),
    [styles, isUberAdmin, networkChannels],
  );

  return (
    <Paper sx={styles.root}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1" sx={styles.subtitle}>
            Channel Status in the {capitalize(workspace)} Workspace
          </Typography>
          <Typography variant="body1">
            Below is a status overview of the {capitalize(workspace)} Workspace
            channels.
          </Typography>
        </Grid>
        <Grid sx={styles.channelsContainer} container>
          {channels}
        </Grid>
      </Grid>
    </Paper>
  );
};

ChannelStatus.propTypes = {
  networkChannels: PropTypes.array,
};

ChannelStatus.defaultProps = {
  networkChannels: [],
};

export default React.memo(ChannelStatus);
