import { LANGUAGE_CODES, SUPPORTED_LANGUAGE_CODES } from 'helpers/lang-util';
import _ from 'lodash';
import { SubmissionError } from 'redux-form';

export const toi18nArrayEntries = (property) => _.keys(property).map((key) => {
    const value = property[key];
    return {
      lang: key,
      label: value,
    };
  });

export const fromi18nArrayEntries = (property) => {
  const result = {};
  if (_.isString(property)) {
    result[LANGUAGE_CODES.ENGLISH] = property;
  } else {
    _.each(property, (entry) => {
      result[entry.lang] = entry.label;
    });
  }
  return result;
};

export const fromi18nArrayEntriesErr = (errProperty, remoteProperty) => {
  const result = {};
  if (_.isString(errProperty)) {
    result[LANGUAGE_CODES.ENGLISH] = errProperty;
  } else {
    _.each(errProperty, (entry, index) => {
      const remote = remoteProperty[index];
      const errMsg = _.get(entry, 'label');
      if (errMsg) result[remote.lang] = entry.label;
    });
  }
  return result;
};

export const filterLangs = (entries) => entries.filter((entry) => {
    const found = SUPPORTED_LANGUAGE_CODES.find((l) => l === entry.lang);
    if (found) {
      return true;
    }
    return false;
  });

export const toError = (result, pathTransform = null) => {
  const errors = result.errors || result.graphQLErrors;
  if (!errors) {
    if (result.status) {
      return result;
    }

    return Object.assign(result, { status: 'error' });
  }
  const newObj = {};
  errors.forEach((error) => {
    const fieldErrors = error.fields;
    if (fieldErrors && fieldErrors.length > 0) {
      fieldErrors.forEach((err) => {
        const value = err.message;
        _.set(newObj, err.path, value);
      });
    }
  });
  const transformedObj = pathTransform(newObj);
  const errorObj = {
    ...result,
    submissionErrors: new SubmissionError(transformedObj),
  };
  return errorObj;
};

export const hashCode = (s) => {
  let h;
  for (let h = 0, i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};
