import { gql } from '@apollo/client';

export const FIND_WORKSPACE = gql`
  query findWorkspace {
    findWorkspace {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
