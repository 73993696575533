import { PLACEHOLDER_TITLE_MAP } from 'components/dashboard/cardLayouts/cardhelper';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { isValidJSON } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import * as Yup from 'yup';

export const buildOpenPlatformSchema = (languages) => Yup.object().shape({
    cardGroupType: Yup.string(),
    cardName: Yup.string()
      .max(40, 'Title must be 40 characters or less')
      .required('Title is required'),
    moduleId: Yup.string().nullable().matches(/^[A-Za-z0-9\-_]+$/, {
      message: 'Use alphanumeric characters only.',
    }),
    moduleData: Yup.string().nullable(),
    visibilityRule: Yup.string()
      .nullable()
      .test('isJSON', 'Invalid JSON input', isValidJSON),
  });

export const buildOpenPlatformInitialValues = ({
                                                 cardGroupId,
                                                 cardGroupType,
                                                 cardName,
                                                 module,
                                                 visibilityRule,
                                               }) => ({
  cardGroupId,
  cardGroupType,
  cardName: cardName ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.ENGLISH].label,
  moduleId: module?.moduleId ?? null,
  moduleData: module?.data ?? null,
  visibilityRule: visibilityRule ?? null,
});

export const mapOpenPlatformEditCardToPreview = ({ selectedCard, values }) => ({
  cardGroupId: selectedCard.cardGroupId,
  cardGroupType: selectedCard.cardGroupType,
  cardId: selectedCard.cardId,
  validation: selectedCard.validation,
  card1: {
    cardName: values.cardName ?? null,
  },
});

export const submitOpenPlatformCard = async ({
                                               cardId,
                                               updateCard,
                                               values,
                                             }) => {
  await updateCard({
    variables: {
      id: cardId,
      input: {
        cardName: values.cardName,
        module: {
          moduleId: values.moduleId ?? null,
          data: values.moduleData ?? null,
        },
        visibilityRule: values.visibilityRule ?? null,
      },
    },
  });
};
