import { Box, Grid, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import ImageUploader from 'components/imageUploader/imageUploader';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import MainPreview from 'pages/kiosks/containers/template/MainPreview';
import React from 'react';
import { Field } from 'react-final-form';

const TemplateMainView = ({ form }) => {
  const {
    mainCtaText,
    mainSearchPlaceholderText,
    mainNetworkLogo,
    ctaImage,
    mainBackgroundColor,
  } = form.getState().values;
  return (
    <Box sx={{ paddingBottom: '24px' }}>
      <MainPreview
        color={mainBackgroundColor}
        networkUrl={mainNetworkLogo?.url}
        ctaImageUrl={ctaImage?.url}
        cta={mainCtaText}
        searchPlaceholder={mainSearchPlaceholderText}
      />
      <Typography
        variant="subtitle1"
        sx={{ marginTop: '40px', marginBottom: '16px' }}
      >
        Mobile Application CTA Preferences
      </Typography>
      <Grid spacing={3} container sx={{ marginBottom: '12px' }}>
        <Grid item xs={6}>
          <Field name="mainCtaText">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Mobile Application CTA Text', true)}
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
      {/* so we will get dirty flag on change */}
      <Field name="ctaImage">{() => null}</Field>
      <ImageUploader
        title={getFormLabel('CTA Application Graphic', false)}
        image={ctaImage}
        onImageUpdated={(file) => {
          form.change('ctaImage', {
            url: file.url,
            file: file.file,
            name: file.name,
          });
        }}
        onImageDeleted={() => {
          form.change('ctaImage', null);
        }}
        showImage={true}
        fileInfo="Supported files includes jpeg and png."
        minHeight={null}
        minWidth={null}
        previewWidth={125}
        previewHeight={118}
        maxFileSize={Infinity}
      />
      <Typography
        variant="subtitle1"
        sx={{ marginTop: '48px', marginBottom: '16px' }}
      >
        Search Preferences
      </Typography>
      <Grid spacing={3} container>
        <Grid item xs={6}>
          <Field name="mainSearchPlaceholderText">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Search CTA Text', true)}
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Typography
        variant="subtitle1"
        sx={{ marginTop: '28px', marginBottom: '16px' }}
      >
        Footer Preferences
      </Typography>
      {/* so we will get dirty flag on change */}
      <Grid container sx={{ height: '132px' }}>
        <Grid item xs={12}>
          <Field name="mainNetworkLogo">{() => null}</Field>
          <ImageUploader
            title={getFormLabel('Network Footer Logo', true)}
            image={mainNetworkLogo}
            onImageUpdated={(file) => {
              form.change('mainNetworkLogo', {
                url: file.url,
                file: file.file,
                name: file.name,
              });
            }}
            onImageDeleted={() => {
              form.change('mainNetworkLogo', null);
            }}
            showImage={true}
            fileInfo="Supported files includes jpeg and png."
            previewWidth={226}
            previewHeight={50}
            minWidth={null}
            minHeight={null}
            maxFileSize={Infinity}
          />
        </Grid>
      </Grid>
      <Grid spacing={3} container sx={{ marginTop: '12px' }}>
        <Grid item xs={4}>
          <Field name="mainBackgroundColor">
            {({ input, meta }) => (
              <ColorOptions
                initialColor={input.value}
                getColor={input.onChange}
                label={'Footer Color'}
                helperText={meta.error}
                required={true}
                formControl={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateMainView;
