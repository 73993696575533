import { TextField } from '@mui/material';
import React from 'react';

const materialTextField = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => {
	const hasError = !!(touched && error);
	const helperText = hasError ? error : '';

	return (
		<TextField
			id={`${label} outlined-with-placeholder`}
			label={label}
			margin="normal"
			variant="outlined"
			helperText={helperText}
			error={hasError}
			{...input}
			{...custom}
		/>
	);
};

export default materialTextField;
