import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SCOPES } from 'components/authorization/authorize';
import FormWatcher from 'components/forms/formWatcher';
import { FlamingoContext } from 'contexts/flamingo';
import createDecorator from 'final-form-submit-listener';
import { formatLang, getFormLabel, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import { useUpdateCategory, useUpdateCategoryGroup } from 'hooks/dataHooks/useCategories';
import { isEmpty } from 'lodash';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '28px',
    position: 'relative',
  },
  title: {
    marginBottom: '20px',
  },
  footer: {
    height: '70px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: '16px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: theme.palette.grey[50],
    borderTop: `2px solid ${theme.palette.grey[200]}`,
  },
  saveBtn: {
    width: '131px',
  },
}));

const CategoryGeneralTab = ({
                              category,
                              hasSpanish,
                              isGlobalTemplate,
                              languages,
                            }) => {
  const classes = useStyles();
  const { authorize } = useContext(FlamingoContext);
  const [updateCategory] = useUpdateCategory();
  const [updateCategoryGroup] = useUpdateCategoryGroup();

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          name: Yup.string().required('Name is required'),
          nameES: Yup.string().required('Name (Spanish) is required'),
        },
        languages,
        { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  const isGroup = !category?.parent?.id;

  const categoryEditPermission
    = isGlobalTemplate && authorize(SCOPES.CATEGORIES.EDIT);

  const submitListener = useMemo(
    () => createDecorator({
        beforeSubmit: (formApi) => {
          const formState = formApi.getState();
          if (!isEmpty(formState.errors)) {
            console.warn('beforeSubmit: errors', formState.errors);
            return false;
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = async (values) => {
    const variables = {
      id: category.id,
      input: {
        name: formatLang('name', values),
      },
    };
    if (isGroup) {
      await updateCategoryGroup({ variables });
    } else {
      await updateCategory({ variables });
    }
  };

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        {isGroup ? 'Category Group' : 'Category'} Details
      </Typography>
      <Form
        subscription={{ submitting: true, pristine: true, validating: true }}
        decorators={[submitListener]}
        initialValues={{
          id: category?.id,
          name: parseByLang(category?.name),
          nameES: parseByLang(category?.name, LANGUAGE_CODES.SPANISH),
          langES: true,
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <FormWatcher
              formRenderProps={{ form }}
              errorFormFieldMap={
                new Map([
                  ['name', { label: 'Name', section: 'General' }],
                  ['nameES', { label: 'Name (Spanish)', section: 'General' }],
                ])
              }
            />
            <Grid container spacing={3}>
              <Grid item xs={6} sx={{ paddingBottom: '18px' }}>
                <FinalTextField
                  label={getFormLabel(
                    isGroup ? 'Category Group Name' : 'Category Name',
                    true,
                  )}
                  name="name"
                  variant="outlined"
                  disabled={!categoryEditPermission}
                />
              </Grid>
              {(hasSpanish || isGroup) && (
                <Grid item xs={6}>
                  <FinalTextField
                    label={getFormLabel(
                      isGroup
                        ? 'Category Group Name (Spanish)'
                        : 'Category Name (Spanish)',
                      true,
                    )}
                    name="nameES"
                    variant="outlined"
                    disabled={!categoryEditPermission}
                  />
                </Grid>
              )}
              {!isGroup && (
                <Grid item xs={6}>
                  <FinalTextField
                    label={
                      <span style={{ color: ColorPalette.grey[500] }}>
                        * Category ID
                      </span>
                    }
                    name="id"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              )}
            </Grid>
            {isGlobalTemplate && (
              <Box className={classes.footer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveBtn}
                  onClick={handleSubmit}
                  disabled={form.getState().submitting}
                  startIcon={
                    form.getState().submitting ? (
                      <CircularProgress
                        size={16}
                        thickness={4}
                        className={classes.progress}
                      />
                    ) : null
                  }
                >
                  Save
                </Button>
              </Box>
            )}
          </form>
        )}
      </Form>
    </Box>
  );
};

CategoryGeneralTab.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CategoryGeneralTab;
