import { useSuspenseQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FlamingoContext } from 'contexts/flamingo';
import { useSearchParams } from 'hooks/useSearchParams';
import {
  DEFAULT_WORKSPACE,
  WORKSPACE_KEY,
  WORKSPACE_STORAGE_KEY,
} from 'helpers/workspace-util';
import {
  FIND_WORKSPACE,
} from 'graphql/queries';

export const useWorkspace = () => {
  const flamingo = useContext(FlamingoContext);
  const { getSearchParam, setSearchParam } = useSearchParams();
  const [lastWorkspace, setLastWorkspace] = useState(null);

  const workspaceParam = getSearchParam(WORKSPACE_KEY);

  const setWorkspaceData = useCallback(
    (workspace) => {
      const storedWorkspace = localStorage.getItem(WORKSPACE_STORAGE_KEY);
      if (!storedWorkspace || storedWorkspace !== workspace) {
        localStorage.setItem(WORKSPACE_STORAGE_KEY, workspace);
      }
      if (!workspaceParam || workspaceParam !== workspace) {
        setSearchParam(WORKSPACE_KEY, workspace, { routerPush: true });
      }
    },
    [setSearchParam, workspaceParam],
  );

  const initializeWorkspace = useCallback(() => {
    const newWorkspaceParam
      = localStorage.getItem(WORKSPACE_STORAGE_KEY) || DEFAULT_WORKSPACE;
    setWorkspaceData(newWorkspaceParam);
  }, [setWorkspaceData]);

  const setWorkspace = (updatedWorkspace) => {
    if (updatedWorkspace === workspace) return;
    setLastWorkspace(workspace);
    setWorkspaceData(updatedWorkspace);
    flamingo.updateAuthorizerContext(updatedWorkspace);
  };

  useEffect(() => {
    if (!workspaceParam) {
      initializeWorkspace();
    }
  }, [initializeWorkspace, workspaceParam]);

  const workspace
    = workspaceParam
    || localStorage.getItem(WORKSPACE_STORAGE_KEY)
    || flamingo.workspace
    || DEFAULT_WORKSPACE;
  if (localStorage.getItem(WORKSPACE_STORAGE_KEY) !== workspace) localStorage.setItem(WORKSPACE_STORAGE_KEY, workspace);

  return {
    lastWorkspace,
    workspace,
    setWorkspace,
    initializeWorkspace,
  };
};

export const useWorkspaceData = () => {
  const { handleError } = useHandleError('Workspace');
  const { data } = useSuspenseQuery(FIND_WORKSPACE, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  const workspaces = {};
  data?.findWorkspace?.edges?.forEach(({ node: { id, name } }) => {
    workspaces[name] = id;
  });

  return { workspaces };
};
