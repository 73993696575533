import { SvgIcon } from '@mui/material';
import React from 'react';

function BuildingIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M19.5 1.5h-15v21h15v-21zm-9 18h-3v-3h3v3zm0-6h-3v-3h3v3zm0-6h-3v-3h3v3zm6 12h-3v-3h3v3zm0-6h-3v-3h3v3zm0-6h-3v-3h3v3z" />
      </g>
    </SvgIcon>
  );
}

export default BuildingIcon;
