import Condition from 'components/forms/condition';
import AdditionalTags from 'components/networkPlace/tags/additionalTags';
import React from 'react';
import { Field } from 'react-final-form';

const TagsMain = (props) => (
  <>
    <Field name="tags.en" component={AdditionalTags} label="Additional Tags" />
    <Condition when="langES" is={true}>
      <Field
        name="tags.es"
        component={AdditionalTags}
        label="Additional Tags (Spanish)"
        sx={{ marginTop: '24px' }}
      />
    </Condition>
  </>
);

TagsMain.propTypes = {};

TagsMain.defaultProps = {};

export default TagsMain;
