import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from 'components/autocomplete/autoComplete';
import Condition from 'components/forms/condition';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import { useSites } from 'hooks/dataHooks';
import { makeValidate, Radios } from 'mui-rff';
import { getAvailableSites } from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const addExploreSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'Name must be 30 characters or less')
    .required('Explore Panel Name is required'),
  site: Yup.string().required('Site is required'),
  copyPanel: Yup.string().when('isCopy', {
    is: 'yes',
    then: (schema) => schema.required('Copy Existing Explore Panel is required'),
  }),
});

const validate = makeValidate(addExploreSchema);

const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const AddExplorePanelModal = ({
                                handleClose,
                                handleConfirm,
                                panels,
                                networkName,
                                networkLogo,
                              }) => {
  const classes = useStyles();
  const { networkId } = useParams();
  const { sites } = useSites({ networkId });

  const siteOptions = useMemo(
    () => getAvailableSites(panels, sites),
    [sites, panels],
  );

  const onSubmit = async (values) => {
    const foundPanel = panels.find((p) => p.id === values.copyPanel);
    let copiedPanel = {};
    if (foundPanel) {
      copiedPanel = {
        color: foundPanel.color,
        template: foundPanel.template,
        actions: foundPanel.actions?.map((action, i) => ({
          ...action,
          ...action.categories && {
            categories: action.categories?.map((category) => category.id),
          },
          ...action.list?.id && {
            list: { id: action.list.id },
          },
        })),
      };
    }
    handleConfirm(values, copiedPanel);
  };

  const renderContent = useMemo(
    () => (
      <Grid sx={{ padding: '120px 88px' }} container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.heading}>
            Add New Explore Panel
          </Typography>
          <Typography variant="subtitle1" className={classes.describe}>
            Tell Us a Bit About the New Explore Panel
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: '22px', marginBottom: '24px', textAlign: 'left' }}
        >
          <Radios
            label={getFormLabel(
              'Is this a copy of an existing Explore Panel?',
              true,
            )}
            name="isCopy"
            color="primary"
            size="small"
            radioGroupProps={{ row: true }}
            required={false}
            data={[
              { label: 'No', value: 'no' },
              {
                label: 'Yes',
                value: 'yes',
                disabled: !panels || panels.length === 0,
              },
            ]}
          />
        </Grid>
        <Condition when="isCopy" is={'yes'}>
          <Grid item xs={12} sx={{ marginBottom: '34px' }}>
            <Field name="copyPanel">
              {({ input, meta }) => (
                <Autocomplete
                  input={input}
                  meta={meta}
                  options={panels
                    .map((panel) => ({
                      id: panel.id,
                      label: panel.name?.en,
                    }))
                    .sort(nameSort)}
                  label={'Copy Existing Explore Panel'}
                  required={true}
                  fullWidth
                  disableClearable={true}
                />
              )}
            </Field>
          </Grid>
        </Condition>
        <Grid item xs={12} sx={{ marginBottom: '34px' }}>
          <Field name="name">
            {({ input, meta }) => (
              <TextField
                label={getFormLabel('Explore Panel Name', true)}
                charLimit={30}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                {...input}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="site">
            {({ input, meta }) => (
              <Autocomplete
                input={input}
                meta={meta}
                options={siteOptions}
                label={'Site'}
                required={true}
                fullWidth
                disableClearable={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    ),
    [classes, panels, siteOptions],
  );

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          copyPanel: '',
          name: '',
          site: '',
          isCopy: 'no',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      content={() => renderContent}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddExplorePanelModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  networkName: PropTypes.string,
  networkLogo: PropTypes.string,
  panels: PropTypes.array.isRequired,
  siteOptions: PropTypes.array,
};

export default AddExplorePanelModal;
