import { gql } from '@apollo/client';

export const CREATE_NETWORK_CHANNEL = gql`
  mutation createNetworkChannel($input: NetworkChannelInputCreate!) {
    createNetworkChannel(input: $input) {
      createdAt
      createdBy {
        id
        profile {
          firstName
          lastName
        }
        state
      }
      enabled
      id
      internal
      isDefault
      isInSync
      label
      packagerDataSpec {
        clientType
        createdAt
        id
        updatedAt
        version
      }
      purpose
      workspaces {
        id
        name
      }
    }
  }
`;

export const FIND_NETWORK_CHANNEL = gql`
  query($workspaces: AutoGraphMixed) {
    findNetworkChannel(where: { workspaces: { name: $workspaces } }) {
      edges {
        node {
          id
          archivedBy {
            profile {
              firstName
              lastName
            }
          }
          archivedReason
          enabled
          label
          internal
          isDefault
          isInSync
          purpose
          updatedAt
          updatedBy {
            profile {
              firstName
              lastName
            }
          }
          network {
            id
          }
          workspaces {
            name
          }
          packagerDataSpec {
            id
            clientType
            updatedAt
            version
          }
          publishChannel {
            published {
              publishVersion
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_CHANNEL = gql`
  mutation updateNetworkChannel(
    $networkChannelId: ID!
    $input: NetworkChannelInputUpdate
  ) {
    updateNetworkChannel(id: $networkChannelId, input: $input) {
      isDefault
    }
  }
`;
