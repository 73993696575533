import { Box, Button, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RemoveIcon from 'assets/trash-copy.svg';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GenericModal from 'components/genericModal/genericModal';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { convertDateToString } from 'helpers/date-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { getFullNameFromProfile } from 'helpers/string-util';
import { DRAFT_WORKSPACE } from 'helpers/workspace-util';
import {
  useCreateNetworkPromotion,
  useDeletePromotionItem,
  usePromotionHistory,
  usePromotionItems,
} from 'hooks/dataHooks/usePromotion';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import PromoteModal from 'pages/promotion/containers/promoteModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  content: {
    height: 'calc(100vh - 188px)',
    backgroundColor: theme.palette.white,
    borderRadius: '20px',
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    margin: '0 2px 0 2px',
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  tableWrap: {
    height: '100%',
    width: '100%',
  },
  removeIcon: {
    '& img': {
      cursor: 'pointer',
    },
  },
});

const PROMOTION_ITEM_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    alwaysShown: true,
    minWidth: 150,
    width: 150,
    sticky: 'left',
  },
  {
    Header: 'Data Type',
    accessor: 'designation',
    minWidth: 88,
    width: 88,
  },
  {
    Header: 'All Children Included',
    accessor: 'allChildrenIncluded',
    minWidth: 99,
    width: 99,
  },
  {
    Header: 'User',
    accessor: 'user',
    minWidth: 99,
    width: 99,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    minWidth: 80,
    width: 80,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
  },
];

const PROMOTION_HISTORY_COLUMNS = [
  {
    Header: 'Promo #',
    accessor: 'sequenceNumber',
    minWidth: 88,
    sticky: 'left',
  },
  {
    Header: 'Description',
    accessor: 'description',
    minWidth: 280,
  },
  {
    Header: 'User',
    accessor: 'user',
    minWidth: 108,
  },
  {
    Header: 'Date',
    accessor: 'date',
    minWidth: 114,
    Cell: ({ cell }) => convertDateToString(cell.value),
  },
];

const getDesignation = (item) => {
  const type
    = item.designation === 'poi' ? 'POI' : capitalize(item.designation);
  return `Location, ${type} (${item.isMapped ? 'Mapped' : 'Unmapped'})`;
};

const buildDescription = (item) => {
  if (item.errors) {
    const errDesc = item.errors.map((err) => err.description);
    return `Error${errDesc.length === 1 ? '' : 's'}: ${errDesc.join(', ')}`;
  }
  return item.description;
};

const processPromotionHistory = (items) => items.map((item) => {
    const desc = buildDescription(item);
    return {
      id: item?.id,
      sequenceNumber: item?.sequenceNumber,
      description: (
        <Tooltip title={desc.length > 150 ? desc : ''}>
          <Box>{desc}</Box>
        </Tooltip>
      ),
      user: getFullNameFromProfile(item?.createdBy?.profile),
      date: item.createdAt,
    };
  });

export const Promotion = ({ networkId }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const [tabValue, setTabValue] = useState(0);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [showPromoteWarningModal, setShowPromoteWarningModal] = useState(false);

  const {
    data: promotionItems,
    loading: promotionItemLoading,
    refetch: refetchPromotionItems,
  } = usePromotionItems(networkId);
  const {
    data: promotionHistory,
    loading: promotionHistoryLoading,
    refetch: refetchPromotionHistory,
  } = usePromotionHistory(networkId);
  const [deletePromotionItem] = useDeletePromotionItem();
  const [createNetworkPromotion] = useCreateNetworkPromotion(networkId);

  const memoizedPromotionHistoryData = useMemo(
    () => processPromotionHistory(promotionHistory),
    [promotionHistory],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePromoteQueue = async (description) => {
    await createNetworkPromotion({
      variables: {
        input: {
          description,
        },
      },
    });
    refetchPromotionHistory();
    setShowPromoteModal(false);
  };

  const handleDemotion = useCallback(
    async (id) => {
      await deletePromotionItem({
        variables: {
          id,
        },
      });
    },
    [deletePromotionItem],
  );

  const processPromotionItems = useCallback(
    () => promotionItems.map((item) => ({
        id: item?.id,
        name: getLabelByLang(item?.item?.name),
        designation: getDesignation(item?.item),
        allChildrenIncluded: item?.includeAllChildren ? 'Yes' : 'No',
        user: getFullNameFromProfile(item?.createdBy?.profile),
        actions: (
          <Box className="hoverUnhide" sx={styles.removeIcon}>
            <img
              src={RemoveIcon}
              alt="Remove"
              width={24}
              onClick={() => handleDemotion(item?.id)}
            />
          </Box>
        ),
      })),
    [styles.removeIcon, handleDemotion, promotionItems],
  );

  const memoizedPromotionItemData = useMemo(
    () => processPromotionItems(),
    [processPromotionItems],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (e, newValue) => {
    if (tabValue === 0) {
      refetchPromotionItems();
    } else {
      refetchPromotionHistory();
    }
    setTabValue(newValue);
  };

  if (useCheckRole() !== USER_ROLES.UBER_ADMIN) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  return (
    <FlamingoPage
      pageName="Promotion"
      headerButtons={
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowPromoteWarningModal(true)}
          disabled={promotionItems.length === 0}
        >
          Promote Queue
        </Button>
      }
    >
      <Box sx={styles.content}>
        <Grid item xs={12} sx={styles.tabContainer}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="QUEUE" {...tabA11yProps('promotion', 0)} />
            <Tab label="HISTORY" {...tabA11yProps('promotion', 1)} />
          </Tabs>
        </Grid>
        <TabPanel
          sx={{
            ...styles.tabPanel,
            ...tabValue === 0 && { height: 'calc(100% - 69px)' },
          }}
          value={tabValue}
          index={0}
        >
          <Box sx={styles.tableWrap}>
            <GozioTable
              name="PromotionTable"
              sx={{
                height: '100%',
                paddingTop: 0,
              }}
              columns={PROMOTION_ITEM_COLUMNS}
              data={memoizedPromotionItemData}
              sortBy={[{ id: 'name', desc: false }]}
              loading={promotionItemLoading}
              countTitle="Data Types Queue for Promotion"
              emptyContent={
                <Box sx={{ height: `${window.innerHeight - 200}px` }}>
                  <EmptyPanel
                    large
                    title={
                      <>
                        There is No Data in
                        <br />
                        the Queue to Promote
                      </>
                    }
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel
          sx={{
            ...styles.tabPanel,
            ...tabValue === 1 && { height: 'calc(100% - 69px)' },
          }}
          value={tabValue}
          index={1}
        >
          <Box sx={styles.tableWrap}>
            <GozioTable
              name="HistoryTable"
              sx={{
                height: '100%',
                paddingTop: 0,
              }}
              columns={PROMOTION_HISTORY_COLUMNS}
              data={memoizedPromotionHistoryData}
              sortBy={[{ id: 'date', desc: true }]}
              loading={promotionHistoryLoading}
              countTitle="Promotion History"
              emptyContent={
                <Box sx={{ height: `${window.innerHeight - 200}px` }}>
                  <EmptyPanel large title="Nothing has been Promoted" />
                </Box>
              }
            />
          </Box>
        </TabPanel>
      </Box>
      {showPromoteWarningModal && (
        <GenericModal
          title="Promote Locations"
          body="Upon Promotion, please re-run the Map Sync process for Radio and Map files before publishing content to the Live Workspace. NOTE: Once locations are promoted from draft to live, this action cannot be undone. Are you sure you want to promote?"
          cancelText="Cancel"
          confirmText="Continue Promoting Locations"
          handleConfirm={() => {
            setShowPromoteWarningModal(false);
            setShowPromoteModal(true);
          }}
          handleClose={() => {
            setShowPromoteModal(false);
            setShowPromoteWarningModal(false);
          }}
        />
      )}
      {showPromoteModal && (
        <PromoteModal
          handleClose={() => setShowPromoteModal(false)}
          handleConfirm={(description) => handlePromoteQueue(description)}
        />
      )}
    </FlamingoPage>
  );
};

const PromotionPage = (props) => {
  const { networkId } = useParams();

  return !isGlobalTemplate(networkId) ? (
    <LoggedinLayout supportedWorkspace={DRAFT_WORKSPACE}>
      <Promotion networkId={networkId} {...props} />
    </LoggedinLayout>
  ) : (
    <Navigate to="/" />
  );
};

export default PromotionPage;
