import { gql } from '@apollo/client';

export const CREATE_NETWORK_CURATED_LIST = gql`
  mutation createNetworkCuratedList($input: NetworkCuratedListInputCreate!) {
    createNetworkCuratedList(input: $input) {
      id
    }
  }
`;

export const CREATE_NETWORK_CURATED_LIST_SECTION = gql`
  mutation createNetworkCuratedListSection($input: NetworkCuratedListSectionInputCreate!) {
    createNetworkCuratedListSection(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_NETWORK_CURATED_LIST_SECTION_LOCATION = gql`
  mutation createNetworkCuratedListSectionLocation(
    $input: NetworkCuratedListSectionLocationInputCreate!
    $meta: NetworkCuratedListSectionLocationInputMeta
  ) {
    createNetworkCuratedListSectionLocation(input: $input, meta: $meta) {
      id
      name
      type
      order
      children {
        id
        name
        type
        order
      }
    }
  }
`;

export const DELETE_NETWORK_CURATED_LIST = gql`
  mutation deleteNetworkCuratedList($id: ID!) {
    deleteNetworkCuratedList(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_NETWORK_CURATED_LIST_SECTION = gql`
  mutation deleteNetworkCuratedListSection($id: ID!) {
    deleteNetworkCuratedListSection(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_NETWORK_CURATED_LIST_SECTION_LOCATION = gql`
  mutation deleteNetworkCuratedListSectionLocation(
    $id: ID!
    $meta: NetworkCuratedListSectionLocationInputMeta
  ) {
    deleteNetworkCuratedListSectionLocation(id: $id, meta: $meta) {
      id
      name
      type
      order
    }
  }
`;

export const FIND_NETWORK_CURATED_LIST = gql`
  query findNetworkCuratedList {
    findNetworkCuratedList {
      edges {
        node {
          id
          name
          assignedLocationCount
        }
      }
    }
  }
`;

export const FIND_NETWORK_CURATED_LIST_WITH_LOCATIONS = gql`
  query findNetworkCuratedList {
    findNetworkCuratedList(
      where: {
        sections: {
          locations: {
            designation: "*"
          }
        }
      }
    ) {
      edges {
        node {
          id
          name
          assignedLocationCount
        }
      }
    }
  }
`;

export const GET_NETWORK_CURATED_LIST = gql`
  query getNetworkCuratedList($id: ID!) {
    getNetworkCuratedList(id: $id) {
      id
      name
      assignedLocationCount
      sections {
        id
        name
        locations {
          id
          name
          designation
          workspaces {
            id
            name
          }
          parent {
            id
            name
            designation
            parent {
              id
              name
              designation
              parent {
                id
                name
                designation
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NETWORK_CURATED_LIST_AVAILABLE_LOCATIONS = gql`
  query getListAvailableLocations($id: ID!) {
    getNetworkCuratedList(id: $id) {
      id
      name
      availableLocations {
        id
        name
        designation
        geoLocation {
          address {
            street
            city
            state
            zip
          }
        }
        ancestors {
          id
          name
          designation
        }
        workspaces {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_CURATED_LIST = gql`
  mutation updateNetworkCuratedList($id: ID!, $input: NetworkCuratedListInputUpdate!) {
    updateNetworkCuratedList(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_NETWORK_CURATED_LIST_SECTION = gql`
  mutation updateNetworkCuratedListSection($id: ID!, $input: NetworkCuratedListSectionInputUpdate) {
    updateNetworkCuratedListSection(id: $id, input: $input) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;

export const UPDATE_NETWORK_CURATED_LIST_SECTION_LOCATION_ORDER = gql`
  mutation updateNetworkCuratedListSectionLocationOrder($id: ID!, $order: Int!) {
    updateNetworkCuratedListSectionLocationOrder(id: $id, order: $order) {
      id
      order
    }
  }
`;

export const UPDATE_NETWORK_CURATED_LIST_SECTIONS = gql`
  mutation updateNetworkCuratedListSections(
    $currentSectionId: ID!,
    $currentSectionData: NetworkCuratedListSectionInputUpdate,
    $targetSectionId: ID!,
    $targetSectionData: NetworkCuratedListSectionInputUpdate
  ) {
    currentSectionData: updateNetworkCuratedListSection(id: $currentSectionId, input: $currentSectionData) {
      id
      name
      locations {
        id
        name
      }
    }
    targetSectionData: updateNetworkCuratedListSection(id: $targetSectionId, input: $targetSectionData) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;
