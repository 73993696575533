import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { tickFormatterThousandsToK } from './chartHelpers';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',

    '& .recharts-surface': {
      marginLeft: '-1px',
      overflow: 'visible',
    },

    '& .recharts-wrapper': {
      margin: '0 auto',
    },

    '& .recharts-text': {
      fill: ColorPalette.grey[600],
      fontSize: '12px',
    },
  },
  axis: {
    fill: `${theme.palette.grey[400]} !important`,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: '12px',
  },
}));

const GozioAreaChart = ({
                          data,
                          dataKey,
                          xAxisLabel,
                          xAxisKey,
                          yAxisLabel,
                          yAxisKey,
                          width,
                          height,
                          maxXAxisLabels,
                          showLines,
                          showTooltip,
                          customTooltip,
                          linearData = [],
                        }) => {
  const classes = useStyles();
  const xAxisInterval = maxXAxisLabels
    ? Math.floor(data?.length / maxXAxisLabels)
    : undefined;

  if (!linearData.length) {
    linearData.push({
      dataKey,
      color: ColorPalette.blue[400],
    });
  }

  return (
    <Box
      className={classes.root}
      sx={{
        '& .recharts-surface': {
          width,
        },
      }}
    >
      <ResponsiveContainer width={width} height={height}>
        <AreaChart height={height} data={data}>
          {showTooltip
            && (customTooltip ? (
              <Tooltip content={customTooltip} cursor={false} />
            ) : (
              <Tooltip cursor={false} />
            ))}
          {showLines && (
            <CartesianGrid stroke={ColorPalette.grey[300]} strokeWidth={2} />
          )}
          <defs>
            {linearData.length
              && linearData.map(({ dataKey, color }) => (
                <linearGradient
                  id={`colorGradient-${dataKey}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                  key={`linearGradient-${dataKey}`}
                >
                  <stop offset="5%" stopColor={color} stopOpacity={1} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
          </defs>
          {linearData.length
            && linearData.map(({ dataKey, color }) => (
              <Area
                dataKey={dataKey}
                activeDot={{
                  stroke: colorWithAlpha(ColorPalette.blue[400], 0.35),
                  strokeWidth: 4,
                  r: 6,
                  strokeDasharray: '',
                }}
                fill={`url(#colorGradient-${dataKey})`}
                key={`area-${dataKey}`}
                stroke={color}
                strokeWidth={2}
                type="monotone"
              />
            ))}
          {xAxisKey && (
            <XAxis
              dataKey={xAxisKey}
              dy={7}
              interval={xAxisInterval}
              stroke="none"
            >
              {xAxisLabel && (
                <Label value={xAxisLabel} dy={15} className={classes.axis} />
              )}
            </XAxis>
          )}
          {yAxisKey && (
            <YAxis
              dataKey={yAxisKey}
              stroke={ColorPalette.grey[300]}
              strokeWidth={2}
              tickFormatter={tickFormatterThousandsToK}
            >
              {yAxisLabel && (
                <Label
                  angle={-90}
                  className={classes.axis}
                  position="insideLeft"
                  style={{ textAnchor: 'middle' }}
                  value={yAxisLabel}
                />
              )}
            </YAxis>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

GozioAreaChart.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string,
  xAxisKey: PropTypes.string,
  yAxisLabel: PropTypes.string,
  yAxisKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  maxXAxisLabels: PropTypes.number,
  showLines: PropTypes.bool,
  showTooltip: PropTypes.bool,
  customTooltip: PropTypes.node,
};

GozioAreaChart.defaultProps = {
  data: null,
  xAxisLabel: null,
  xAxisKey: null,
  yAxisLabel: null,
  yAxisKey: null,
  width: 270,
  height: 170,
  showLines: false,
  showTooltip: false,
  customTooltip: null,
};

export default GozioAreaChart;
