import React from 'react';
import { Field } from 'react-final-form';

const Condition = ({ when, is, not, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (is !== undefined && value === is) || (not !== undefined && value !== not) ? children : null
		}
	</Field>
);

export default Condition;
