import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const RenderedSection = ({
                           data,
                           section,
                           form,
                           isUberAdmin,
                           networkId,
                           readOnly,
                           compProps,
                         }) => {
  const Comp = section.component;

  return (
    <Grid
      id={section.hash}
      item
      sx={{
        paddingTop: '40px',
        paddingBottom: '24px',
      }}
    >
      <Box>
        <fieldset
          disabled={readOnly}
          style={{ border: 0, margin: 0, padding: 0 }}
        >
          <Comp
            data={data}
            values={form.getState()?.values}
            form={form}
            isUberAdmin={isUberAdmin}
            networkId={networkId}
            {...compProps}
          />
        </fieldset>
      </Box>
    </Grid>
  );
};

RenderedSection.propTypes = {
  data: PropTypes.object,
  section: PropTypes.object,
  form: PropTypes.object,
  isUberAdmin: PropTypes.bool,
  networkId: PropTypes.string,
  readOnly: PropTypes.bool,
  compProps: PropTypes.object,
};

RenderedSection.defaultProps = {
  compProps: {},
};

export default React.memo(RenderedSection);
