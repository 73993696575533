import { FlamingoContext } from 'contexts/flamingo';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';

function useToast() {
  const { flash } = useContext(FlamingoContext);
  const snackbar = useSnackbar();
  const closeToastNotificationById = useCallback(
    (id) => snackbar?.closeSnackbar(id),
    [snackbar],
  );
  const toastNotificationCustomHook = useCallback(
    ({ content, options }) => flash.sendCustomNotification({ content, options }),
    [flash],
  );
  const toastNotificationErrorHook = useCallback(
    (message) => flash.sendErrorNotification(message),
    [flash],
  );
  const toastNotificationSuccessHook = useCallback(
    (message) => flash.sendSuccessNotification(message),
    [flash],
  );

  return {
    closeToastNotificationById,
    toastNotificationCustomHook,
    toastNotificationSuccessHook,
    toastNotificationErrorHook,
  };
}

export default useToast;
