import { Add, Delete } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { getTableCountTitle } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useLists } from 'hooks/dataHooks/index';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import { useWorkspace } from 'hooks/useWorkspace';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddListModal from 'pages/lists/containers/AddListModal';
import DeleteListModal from 'pages/lists/containers/DeleteListModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = () => ({
  content: { height: 'calc(100vh - 186px)', position: 'relative' },
  addIcon: {
    fontSize: '48px',
  },
});

const ListsPageContent = () => {
  const styles = buildStyles();

  const languages = useActiveNetworkLanguages();

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedListForDeletion, setSelectedListForDeletion] = useState(null);
  const { activeNetwork, handleNavigate } = useContext(FlamingoContext);
  const { workspace } = useWorkspace();

  const { networkId } = useParams();
  const { lists, refetch } = useLists();

  const subtitle = useMemo(
    () => workspace === LIVE_WORKSPACE
        ? 'Start creating lists.'
        : 'Start creating lists in the Live Workspace.',
    [workspace],
  );

  const columns = useMemo(() => {
    const isLiveWorkspace = workspace === LIVE_WORKSPACE;
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        alwaysHidden: true,
      },
      {
        Header: 'List Name',
        accessor: 'name',
        onClick: (data) => {
          handleNavigate(`/network/${networkId}/lists/${data.id}`);
        },
        minWidth: 241,
        width: 241,
      },
      {
        Header: 'Number of Locations',
        accessor: 'locationCount',
        disableSortBy: true,
        minWidth: 460,
        width: 460,
      },
    ];
    if (isLiveWorkspace) {
      columns.push({
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        minWidth: 66,
        width: 66,
        disableSortBy: true,
        justifyRight: true,
      });
    }
    return columns;
  }, [handleNavigate, networkId, workspace]);

  const data = useMemo(
    () => lists?.map((list) => ({
        id: list.id,
        name: getLabelByLang(list.name),
        locationCount: list.assignedLocationCount || 0,
        actions: (
          <Box className="hoverUnhide" onClick={() => setSelectedListForDeletion(list)}>
            <Tooltip title="Delete">
              <Delete onClick={() => setSelectedListForDeletion(list)} />
            </Tooltip>
          </Box>
        ),
      })),
    [lists],
  );

  if (isGlobalTemplate(activeNetwork?.id)) {
    return <Navigate to="/" />;
  }

  return (
    <FlamingoPage
      pageName="Lists"
      headerButtons={
        workspace === LIVE_WORKSPACE
        && lists?.length > 0 && (
          <AddCircleButton
            onClick={() => setShowAddModal(true)}
            tooltipTitle="Add a List"
            data-test="addListButton"
          />
        )
      }
    >
      <Box sx={styles.content}>
        {activeNetwork?.id && (
          <GozioTable
            name="ListsTable"
            columns={columns}
            data={data || []}
            sx={{
              borderRadius: '20px',
              boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
                ColorPalette.grey[600],
                0.4,
              )}`,
            }}
            sortBy={[{ id: 'name', desc: false }]}
            countTitle={getTableCountTitle(lists?.length, 'List')}
            emptyContent={
              <Box sx={{ height: `${window.innerHeight - 200}px` }}>
                <EmptyPanel
                  title="You Have No Lists"
                  subtitle={subtitle}
                  buttonLabel="Add a List"
                  ButtonIcon={Add}
                  buttonShown={workspace === LIVE_WORKSPACE}
                  buttonClicked={() => setShowAddModal(true)}
                />
              </Box>
            }
            hidePagination={true}
          />
        )}
      </Box>

      {showAddModal && (
        <AddListModal
          handleClose={() => setShowAddModal(false)}
          languages={languages}
        />
      )}

      {selectedListForDeletion && (
        <DeleteListModal
          handleClose={() => setSelectedListForDeletion(null)}
          list={selectedListForDeletion}
        />
      )}
      {<WorkspaceSwitcher onWorkspaceSwitched={refetch} />}
    </FlamingoPage>
  );
};

const ListsPage = () => (
  <LoggedinLayout enableSuspense={true} showWorkspaceSwitcher={false}>
    <ListsPageContent />
  </LoggedinLayout>
);

export default ListsPage;
