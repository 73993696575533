import { AddToPhotos, Delete } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import SpeedDialBackdrop from 'components/speedDial/speedDialBackdrop';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { hasChildren } from 'helpers/location-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { wrapWithTooltip } from 'helpers/page-util';
import {
  filtersChanged,
  getTableCountTitle,
  LOCATIONS_TABLE_NAME,
  persistTableSearch,
  retrieveTablePageSize,
  retrieveTableSearch,
} from 'helpers/table-util';
import { DRAFT_WORKSPACE, isNetworkPlaceInDraft } from 'helpers/workspace-util';

import { useNetworkPlaces } from 'hooks/dataHooks/useNetworkPlace';
import { useCreateNetworkPromotionItem } from 'hooks/dataHooks/usePromotion';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { useWorkspace } from 'hooks/useWorkspace';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddBuildingModal from 'pages/locations/containers/sections/addBuildingModal';
import AddSiteModal from 'pages/locations/containers/sections/addSiteModal';
import AddUnmappedPoiModal from 'pages/locations/containers/sections/addUnmappedPoiModal';
import DeleteLocationModal from 'pages/locations/containers/sections/deleteLocationModal';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useDynamicRefs from 'use-dynamic-refs';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: theme.spacing(6),
  },
  content: { width: '100%', height: 'calc(100vh - 193px)' },
  toggleGroup: {
    textAlign: 'right',
  },
  toggle: {
    color: colorWithAlpha(theme.palette.black, 0.25),
    marginBottom: 0,
    padding: '4px !important',

    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  selectedToggle: {
    background: `${theme.palette.blue[400]} !important`,
    color: `${theme.palette.white} !important`,
  },
  actions: {
    position: 'relative',
  },

  promoteMenu: {
    background: theme.palette.white,
    boxShadow: `0 4px 8px 0 ${colorWithAlpha(theme.palette.black, 0.25)}`,
    position: 'absolute',
    top: '32px',
    left: '-236px',
    zIndex: 200,
  },
  promoteMenuItem: {
    height: '38px',
    marginBottom: 0,

    '&:hover': {
      color: theme.palette.grey[600],
      paddingRight: '9px',
    },
  },
  actionsCell: {
    '&:hover': {
      '& > div': {
        overflow: 'visible !important',
      },
    },
  },
}));

const getLocationsColumns = (networkId, navigate, classes) => [
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    cellStyle: {
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
    },
    onClick: (data) => {
      navigate(
        `/network/${networkId}/locations/${data.designation.toLowerCase()}/${
          data.id
        }`,
      );
    },
    alwaysShown: true,
    sticky: 'left',
  },
  {
    Header: 'Order',
    accessor: 'order',
    defaultSort: 'asc',
    hidden: true,
    minWidth: 88,
    width: 88,
  },
  {
    Header: 'Type',
    accessor: 'designation',
    minWidth: 88,
    width: 88,
    filters: {
      type: 'select',
      options: [
        { value: 'site', label: 'Site' },
        { value: 'building', label: 'Building' },
        { value: 'floor', label: 'Floor' },
        { value: 'poi', label: 'POI' },
        { value: 'landmark', label: 'Landmark' },
      ],
    },
  },
  {
    Header: 'Mapping',
    accessor: 'isMapped',
    minWidth: 99,
    width: 99,
    filters: {
      type: 'select',
      options: [
        { value: true, label: 'Mapped' },
        { value: false, label: 'Unmapped' },
      ],
    },
  },
  {
    Header: 'Categories',
    accessor: 'categories',
    minWidth: 150,
    width: 150,
    disableSortBy: true,
    filters: {
      type: 'categories',
    },
  },
  {
    Header: 'Progress',
    accessor: 'validation',
    minWidth: 100,
    width: 100,
    filters: {
      type: 'select',
      options: [
        { value: 'complete', label: 'Complete' },
        { value: 'incomplete', label: 'Incomplete' },
      ],
    },
  },
  {
    Header: 'Workspace',
    accessor: 'workspace',
    minWidth: 120,
    width: 120,
    filters: {
      type: 'select',
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'live', label: 'Live' },
      ],
    },
  },
  {
    Header: 'Street Address',
    accessor: 'street',
    minWidth: 154,
    width: 154,
  },
  {
    Header: 'City',
    accessor: 'city',
    minWidth: 105,
    width: 105,
  },
  {
    Header: 'State',
    accessor: 'state',
    minWidth: 81,
    width: 81,
    hidden: true,
  },
  {
    Header: 'Zip',
    accessor: 'zip',
    minWidth: 81,
    width: 81,
    hidden: true,
  },
  {
    Header: 'BLDGs',
    accessor: 'buildingCount',
    minWidth: 83,
    width: 83,
    onClick: (data) => {
      if (data.designation.toLowerCase() === 'site') {
        navigate(
          `/network/${networkId}/locations/site/${data.id}?tab=buildings`,
        );
      }
    },
    isClickable: (data) => data.designation.toLowerCase() === 'site',
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'POIs',
    accessor: 'poiCount',
    minWidth: 81,
    width: 81,
    onClick: (data) => {
      if (data.designation.toLowerCase() === 'site') {
        navigate(`/network/${networkId}/locations/site/${data.id}?tab=pois`);
      }
    },
    isClickable: (data) => data.designation.toLowerCase() === 'site',
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'LDMKs',
    accessor: 'ldmkCount',
    minWidth: 85,
    width: 85,
    onClick: (data) => {
      if (data.designation.toLowerCase() === 'site') {
        navigate(
          `/network/${networkId}/locations/site/${data.id}?tab=landmarks`,
        );
      }
    },
    isClickable: (data) => data.designation.toLowerCase() === 'site',
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Dept ID',
    accessor: 'deptId',
    minWidth: 150,
    width: 150,
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Mapping ID',
    accessor: 'mapId',
    minWidth: 150,
    width: 150,
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Gozio ID',
    accessor: 'externalId',
    minWidth: 150,
    width: 150,
    hidden: true,
  },
  {
    Header: 'Parent Site',
    accessor: 'parentSite',
    minWidth: 160,
    width: 160,
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Parent Building',
    accessor: 'parentBuilding',
    minWidth: 160,
    width: 160,
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Parent Floor',
    accessor: 'parentFloor',
    minWidth: 160,
    width: 160,
    hidden: true,
    disableSortBy: true,
  },
  {
    Header: 'Default Image Used',
    accessor: 'defaultImage',
    minWidth: 180,
    width: 180,
    hidden: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
    sticky: 'right',
    cellClass: classes.actionsCell,
  },
];

const sortOverride = {
  street: 'geoLocation.address.street',
  city: 'geoLocation.address.city',
  state: 'geoLocation.address.state',
  zip: 'geoLocation.address.zip',
  workspace: 'workspaces.name',
  validation: 'validation.status',
};

const parseCategories = (netPlace) => {
  const list = netPlace?.categories?.map((cat) => getLabelByLang(cat.name)) || [
    '-',
  ];
  return list.join(', ');
};

const parseSortBy = ({ id, desc }) => {
  const sortField = sortOverride[id] || id;
  const order = desc === true ? 'desc' : 'asc';
  const sortObj = {};
  let container = sortObj;
  sortField.split('.').forEach((k, i, values) => {
    container = container[k] = i === values.length - 1 ? order : {};
  });
  if (['parentSite', 'parentBuilding', 'parentFloor'].includes(sortField)) {
    sortObj[sortField] = { name: order };
  }
  return sortObj;
};

const buildWhereClause = (filters) => {
  const retVal = {};
  filters.forEach((f) => {
    if (f.values && f.values.length > 0) {
      if (f.name === 'designation') {
        if (f.values.length === 1) {
          retVal.designation = f.values[0].value;
        } else {
          retVal.designation = `{${f.values.map((v) => v.value).join(',')}}`;
        }
      } else if (f.name === 'isMapped') {
        if (f.values.length === 1) {
          retVal.isMapped = f.values[0].value === true;
        }
      } else if (f.name === 'validation') {
        if (f.values.length === 1) {
          retVal.validation = { status: f.values[0].value };
        }
      } else if (f.name === 'workspace') {
        if (f.values.length === 1) {
          retVal.workspaces = { name: f.values[0].value };
        } else {
          retVal.workspaces = {
            name: `{${f.values.map((v) => v.value).join(',')}}`,
          };
        }
      } else if (f.name === 'categories') {
        if (f.values.length === 1) {
          retVal.categories = { id: f.values[0].value };
        } else {
          retVal.categories = {
            id: f.values.map((v) => v.value),
          };
        }
      }
    }
  });
  return retVal;
};

const getLocationCount = (count) => count || '';

export const LocationsPageContent = ({ networkId }) => {
  const classes = useStyles();
  const { handleNavigate } = useContext(FlamingoContext);
  const [showAddSite, setShowAddSite] = useState(false);
  const [showAddBuilding, setShowAddBuilding] = useState(false);
  const [showAddUnmappedPoi, setShowAddUnmappedPoi] = useState(false);
  const [showDeleteLocation, setShowDeleteLocation] = useState(false);
  const [searchParam, setSearchParam] = useState(
    retrieveTableSearch(LOCATIONS_TABLE_NAME),
  );
  const [remoteDataParams, setRemoteDataParams] = useState({
    sortBy: { id: 'name', desc: false },
    pageSize: retrieveTablePageSize(LOCATIONS_TABLE_NAME),
    pageIndex: 0,
    filters: [],
    gotoPage: null,
  });
  // const { authorize } = useContext(FlamingoContext);
  // const locationsPermissionsMap = authorize(
  //   [
  //     'NetworkPlace:read',
  //     'NetworkPlace:delete',
  //     'NetworkPlace:create',
  //     'NetworkPromotion:create',
  //   ],
  //   {
  //     map: true,
  //   },
  // );

  // SCM-3364: Do not use JSON logic permissions for now
  // https://goziohealth.atlassian.net/browse/SCM-3364
  const isUberAdmin = useCheckGozioAdmin();
  const locationsPermissionsMap = {};
  locationsPermissionsMap['NetworkPlace:read'] = true;
  locationsPermissionsMap['NetworkPlace:delete'] = isUberAdmin;
  locationsPermissionsMap['NetworkPlace:create'] = isUberAdmin;
  locationsPermissionsMap['NetworkPromotion:create'] = isUberAdmin;

  const getLocationsQuery = useCallback(
    () => ({
      variables: {
        search: searchParam,
        sortBy: remoteDataParams.sortBy?.id
          ? parseSortBy(remoteDataParams.sortBy)
          : {},
        limit: remoteDataParams.pageSize,
        // the skip count needs to be reset if there is a search param, since the results will begin on page 0
        skip: remoteDataParams.pageIndex * remoteDataParams.pageSize,
        where: buildWhereClause(remoteDataParams.filters),
      },
    }),
    [searchParam, remoteDataParams],
  );

  const { workspace } = useWorkspace();

  const count = useRef(0);

  const { activeNetwork } = useContext(FlamingoContext);
  const networkName = activeNetwork?.humanName || 'Global Template';
  const networkLogo = activeNetwork?.logo?.url;

  const { data, loading, refetch: refetchLocations, refetching } = useNetworkPlaces(getLocationsQuery());

  const [createNetworkPromotionItem] = useCreateNetworkPromotionItem(networkId);

  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    if (networkId !== 'Global Template') {
      if (
        workspace === 'live'
        && (remoteDataParams.sortBy?.id === 'workspace'
          || remoteDataParams.filters.find((f) => f.name === 'workspace'))
      ) {
        const newRDP = { ...remoteDataParams };
        if (newRDP.sortBy?.id === 'workspace') {
          newRDP.sortBy.id = 'name';
        }
        newRDP.filters = remoteDataParams.filters.filter(
          (f) => f.name !== 'workspace',
        );
        setRemoteDataParams(newRDP);
      } else {
        refetchLocations({ ...getLocationsQuery()?.variables });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteDataParams, searchParam, networkId, workspace]);

  // setup data for the calls down the line
  count.current = data?.count || 0;

  const handlePromoteLocation = async (
    locationId,
    includeAllChildren = false,
  ) => {
    await createNetworkPromotionItem({
      variables: {
        input: {
          item: locationId,
          includeAllChildren,
          type: 'NetworkPlace',
        },
      },
    });
    await refetchLocations();
    if (getRef(locationId)?.current?.style?.visibility) {
      getRef(locationId).current.style.visibility = 'hidden';
    }
  };

  const processLocations = (locations) => locations.map((loc, idx) => ({
      externalId: loc?.externalId,
      id: loc?.id,
      mapId: wrapWithTooltip(
        ['poi', 'landmark'].includes(loc?.designation)
          ? loc?.indoorLocation?.mapKey
          : loc?.indoorLocation?.mapId,
      ),
      deptId: (loc?.departmentIds ?? []).join(','),
      name: wrapWithTooltip(getLabelByLang(loc?.name)),
      order: loc?.order,
      designation:
        loc?.designation === 'poi' ? 'POI' : capitalize(loc?.designation),
      isMapped: loc?.isMapped ? 'Mapped' : 'Unmapped',
      street: wrapWithTooltip(loc?.geoLocation?.address?.street),
      city: loc?.geoLocation?.address?.city,
      state: loc?.geoLocation?.address?.state,
      zip: loc?.geoLocation?.address?.zip,
      categories: wrapWithTooltip(parseCategories(loc)),
      validation:
        loc?.validation?.status === 'complete' ? 'Complete' : 'Incomplete',
      workspace: capitalize(
        loc?.workspaces?.map((w) => w.name).join(', ') || 'live',
      ),
      buildingCount: getLocationCount(loc?.buildings?.count),
      poiCount: getLocationCount(loc?.pois?.count),
      ldmkCount: getLocationCount(loc?.landmarks?.count),
      parentSite: getLabelByLang(loc?.parentSite?.name),
      parentBuilding: getLabelByLang(loc?.parentBuilding?.name),
      parentFloor: getLabelByLang(loc?.parentFloor?.name),
      defaultImage: loc?.defaultImage ? 'Yes' : 'No',
      actions: (
        <Box className={classes.actions}>
          <Box className="hoverUnhide">
            {locationsPermissionsMap['NetworkPromotion:create']
              && isNetworkPlaceInDraft(loc)
              && !loc.isPromotionItem && (
                <Tooltip title="Promote" placement="top">
                  <AddToPhotos
                    sx={{ marginRight: '8px' }}
                    onClick={(e) => {
                      if (hasChildren(loc)) {
                        getRef(loc.id).current.style.visibility = 'visible';
                      } else {
                        handlePromoteLocation(loc.id, false);
                      }
                    }}
                  />
                </Tooltip>
              )}
            {(!loc?.isMapped
                || (loc?.isMapped && loc?.designation === 'floor')
                || ['poi', 'landmark'].includes(loc?.designation))
              && locationsPermissionsMap['NetworkPlace:delete'] && (
                <button
                  data-test="LocationsTable-button-delete"
                  onClick={() => setShowDeleteLocation(loc)}
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    marginL: 0,
                    padding: 0,
                  }}
                >
                  <Tooltip title="Delete">
                    <Delete />
                  </Tooltip>
                </button>
              )}
            {locationsPermissionsMap['NetworkPromotion:create']
              && hasChildren(loc) && (
                <Box
                  ref={setRef(loc.id)}
                  className={clsx(classes.promoteMenu)}
                  sx={{ visibility: 'hidden' }}
                >
                  <ul
                    className="MuiList-root MuiList-padding"
                    role="menu"
                    tabIndex="-1"
                  >
                    <MenuItem
                      key="promote-location-1"
                      className={classes.promoteMenuItem}
                      onClick={() => handlePromoteLocation(loc.id, true)}
                    >
                      Promote Location &amp; Children
                    </MenuItem>
                    <MenuItem
                      key="promote-location-2"
                      className={classes.promoteMenuItem}
                      onClick={() => handlePromoteLocation(loc.id, false)}
                    >
                      Promote Location Only
                    </MenuItem>
                  </ul>
                </Box>
              )}
          </Box>
        </Box>
      ),
    }));

  const handleSiteAdded = (data) => {
    setShowAddSite(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/site/${data.id}`);
    }
  };

  const handleBuildingAdded = (data) => {
    setShowAddBuilding(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/building/${data.id}`);
    }
  };

  const handleUnmappedPoiAdded = (data) => {
    setShowAddUnmappedPoi(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/locations/poi/${data.id}`);
    }
  };

  const memoizedData = useMemo(() => processLocations(data?.edges?.map((i) => i.node) || []), [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedLocationsColumns = useMemo(
    () => getLocationsColumns(networkId, handleNavigate, classes).filter(
        (col) => col.accessor !== 'workspace' || workspace === DRAFT_WORKSPACE,
      ),
    [networkId, handleNavigate, workspace, classes],
  );

  const getRemoteData = useCallback(
    (sortBy, pageIndex = 0, pageSize = 10, filters = [], gotoPage) => {
      const filtersHaveChanged = filtersChanged(
        remoteDataParams.filters,
        filters,
      );
      if (
        remoteDataParams.sortBy?.id !== sortBy?.id
        || remoteDataParams.sortBy?.desc !== sortBy?.desc
        || remoteDataParams.pageIndex !== pageIndex
        || remoteDataParams.pageSize !== pageSize
        || filtersHaveChanged
      ) {
        const newPageIndex = filtersHaveChanged ? 0 : pageIndex;
        gotoPage(newPageIndex);
        setRemoteDataParams({
          sortBy,
          pageIndex: newPageIndex,
          pageSize,
          filters,
          gotoPage,
        });
      }
    },
    [remoteDataParams],
  );

  return (
    <FlamingoPage
      pageName="Locations"
      searchValue={searchParam}
      onSearchChange={(keywords) => {
        const newRDP = { ...remoteDataParams, pageIndex: 0 };
        newRDP.sortBy
          = keywords.length === 0 ? { id: 'name', desc: false } : {};
        setRemoteDataParams(newRDP);
        setSearchParam(keywords);
        persistTableSearch(LOCATIONS_TABLE_NAME, keywords);
        if (remoteDataParams.gotoPage) {
          remoteDataParams.gotoPage(0);
        }
      }}
      headerButtons={
        locationsPermissionsMap['NetworkPlace:create']
        && !loading && (
          <Box className={classes.buttonContainer}>
            <SpeedDialBackdrop
              direction="down"
              actions={[
                { name: 'Site', onClick: () => setShowAddSite(true) },
                { name: 'Building', onClick: () => setShowAddBuilding(true) },
                { name: 'POI', onClick: () => setShowAddUnmappedPoi(true) },
              ]}
            />
          </Box>
        )
      }
    >
      <Box className={classes.content}>
        <GozioTable
          name={LOCATIONS_TABLE_NAME}
          columns={memoizedLocationsColumns}
          data={memoizedData}
          sortBy={[
            { ...remoteDataParams.sortBy, clearSort: searchParam.length > 0 },
          ]}
          sx={{
            borderRadius: '20px',
            boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
              ColorPalette.grey[600],
              0.4,
            )}`,
          }}
          configureColumns={true}
          currentPage={remoteDataParams.pageIndex}
          rowsPerPage={remoteDataParams.pageSize}
          remoteDataFn={getRemoteData}
          loading={loading || refetching}
          dataCount={count.current}
          countTitle={getTableCountTitle(
            count.current,
            searchParam || remoteDataParams.filters?.length > 0
              ? 'Result'
              : 'Location',
          )}
          disableSortRemove={true}
          onRowLeave={(row) => {
            if (getRef(row.values.id)?.current?.style?.visibility) {
              getRef(row.values.id).current.style.visibility = 'hidden';
            }
          }}
          emptyContent={<EmptyTable />}
        />
      </Box>
      {showAddSite && (
        <AddSiteModal
          handleClose={() => setShowAddSite(false)}
          handleConfirm={handleSiteAdded}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}
      {showAddBuilding && (
        <AddBuildingModal
          handleClose={() => setShowAddBuilding(false)}
          handleConfirm={handleBuildingAdded}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}

      {showAddUnmappedPoi && (
        <AddUnmappedPoiModal
          handleClose={() => setShowAddUnmappedPoi(false)}
          handleConfirm={handleUnmappedPoiAdded}
          networkName={networkName}
          networkLogo={networkLogo}
        />
      )}

      {showDeleteLocation && (
        <DeleteLocationModal
          onDeleteSuccess={() => refetchLocations({ ...getLocationsQuery()?.variables })}
          handleClose={(id) => {
            // TODO: How do we want to handle locations
            // in the table while they are _being_ deleted?
            // Do we remove them? Do we change the status?
            // What happens if we remove then, then the delete fails?
            setShowDeleteLocation(null);
          }}
          locationData={showDeleteLocation}
        />
      )}
    </FlamingoPage>
  );
};

const LocationsPage = () => {
  const { networkId, locationType } = useParams();

  return !isGlobalTemplate(networkId) ? (
    <LoggedinLayout enableSuspense={true}>
      <LocationsPageContent networkId={networkId} locationType={locationType} />
    </LoggedinLayout>
  ) : (
    <Navigate to="/" />
  );
};

export default React.memo(LocationsPage);
