import { useSearchParams as useReactRouterDOMSearchParams } from 'react-router-dom';

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useReactRouterDOMSearchParams();

  const deleteSearchParam = (key) => setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.delete(key);
      return searchParams;
    });

  const setSearchParam = (key, value) => {
    setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.set(key, value);
      return searchParams;
    });
  };

  const getSearchParam = (key) => searchParams.get(key);

  return {
    searchParams,
    deleteSearchParam,
    getSearchParam,
    setSearchParam,
  };
};
