import { Grid, Typography } from '@mui/material';
import ImageUploader from 'components/imageUploader/imageUploader';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import CtaPreview from 'pages/kiosks/containers/template/CtaPreview';
import React from 'react';
import { Field } from 'react-final-form';

const TemplateAppCta = ({ form }) => {
  const values = form.getState().values;
  return (
    <Grid container sx={{ paddingBottom: '24px' }}>
      <Grid item xs={12}>
        <CtaPreview url={values.mainSupportImage?.url} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: '40px', marginBottom: '16px' }}
        >
          App CTA Preferences
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* so we will get dirty flag on change */}
        <Field name="mainSupportImage">{() => null}</Field>
        <ImageUploader
          title={getFormLabel('Supporting Image', false)}
          image={values.mainSupportImage}
          onImageUpdated={(file) => {
            form.change('mainSupportImage', {
              url: file.url,
              file: file.file,
              name: file.name,
            });
          }}
          onImageDeleted={() => {
            form.change('mainSupportImage', null);
          }}
          showImage={true}
          fileInfo="Supported files includes jpeg and png. Image dimensions must be 1920px by 1080px."
          minHeight={1080}
          maxHeight={1080}
          minWidth={1920}
          maxWidth={1920}
          previewHeight={118}
          maxFileSize={Infinity}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Field name="ctaActionUrl">
          {({ input, meta }) => (
            <TextField
              label={getFormLabel('App CTA URL', true)}
              helperText={meta.touched ? meta.error : ''}
              error={meta.touched && !!meta.error}
              {...input}
              fullWidth
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default TemplateAppCta;
