import { formatLang, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { allowedCharacterRegex } from 'helpers/location-util';
import { softDeleteImage, softDeleteMedia } from 'helpers/media-util';
import {
  updateMediaImageToService,
  updateMediaVideoToService,
  uploadImage,
  uploadImageToService,
  uploadMediaImageToService,
  uploadMediaVideoToService,
} from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  DEFAULT_CUSTOM_HOURS,
  filterExceptionHours,
  HOURS_VALIDATION_OBJECT,
} from 'pages/locations/containers/locationsFormHelper';
import * as Yup from 'yup';

export const DEFAULT_RADIUS_IN_METERS = 250;

export const buildValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          // MAPPING INPUTS
          mapID: Yup.number()
            .typeError('Must be a numeric value')
            .min(1, 'Value must be greater than 1'),
          mapBoundary: Yup.array().test(
            'len',
            'Must have 4 values',
            (val) => !val || val.length === 0 || val.length === 4,
          ),
          'mapBoundary[0]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[0]
                || !isNaN(this?.parent?.mapBoundary?.[0])
              );
            })
            .test(
              'inclusive',
              'West value must be a numeric value between -180 and 180 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[0]
                  || (this?.parent?.mapBoundary?.[0] >= -180
                    && this?.parent?.mapBoundary?.[0] <= 180)
                );
              },
            ), // West
          'mapBoundary[1]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[1]
                || !isNaN(this?.parent?.mapBoundary?.[1])
              );
            })
            .test(
              'inclusive',
              'South value must be a numeric value between -90 and 90 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[1]
                  || (this?.parent?.mapBoundary?.[1] >= -90
                    && this?.parent?.mapBoundary?.[1] <= 90)
                );
              },
            ), // South
          'mapBoundary[2]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[2]
                || !isNaN(this?.parent?.mapBoundary?.[2])
              );
            })
            .test(
              'inclusive',
              'East value must be a numeric value between -180 and 180 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[2]
                  || (this?.parent?.mapBoundary?.[2] >= -180
                    && this?.parent?.mapBoundary?.[2] <= 180)
                );
              },
            ), // East
          'mapBoundary[3]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[3]
                || !isNaN(this?.parent?.mapBoundary?.[3])
              );
            })
            .test(
              'inclusive',
              'North value must be a numeric value between -90 and 90 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[3]
                  || (this?.parent?.mapBoundary?.[3] >= -90
                    && this?.parent?.mapBoundary?.[3] <= 90)
                );
              },
            ), // North
          strictParking: Yup.boolean(),
          alternateTransportation: Yup.array().of(Yup.string()),
          mapRotation: Yup.number()
            .typeError('Must be a numeric value')
            .min(0, 'Must be 0 or greater, but less than 360')
            .lessThan(360, 'Must be 0 or greater, but less than 360'),
          latitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          longitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          xAxis: Yup.number().typeError('Must be a numeric value'),
          yAxis: Yup.number().typeError('Must be a numeric value'),

          // SITE DETAILS
          name: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .required('Site name is required'),
          shortName: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
          shortNameES: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),

          // SITE ADDRESS
          street: Yup.string().nullable(),
          streetNumber: Yup.string().nullable(),
          building: Yup.string().nullable(),
          floor: Yup.string().nullable(),
          suite: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string()
            .nullable()
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code'),
          siteGeofenceLatitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          siteGeofenceLongitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          siteGeofenceRadius: Yup.number()
            .integer('Must be an integer')
            .typeError('Must be an integer')
            .min(0, 'Must be 0 or greater'),
          displayLatitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          displayLongitude: Yup.number()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          destinationLatitude: Yup.number()
            .typeError('Must be a numeric value')
            .nullable()
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          destinationLongitude: Yup.number()
            .nullable()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          departmentIds: Yup.array().of(Yup.string()),

          // SITE PROVIDER MATCHING
          providerMatchingRule: Yup.string().when('providerMatchingEnabled', {
            is: true,
            then: (schema) => schema
                .typeError('Not a valid JSON string')
                .required('JSON input is required'),
            otherwise: (schema) => schema.nullable(),
          }),

          // SITE MEDIA
          youTubeUrl: Yup.string().matches(
            /^$|(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/,
            'We only support YouTube videos at this time.',
          ),

          // SITE MAP VISIBILITY
          visibility: Yup.string().oneOf(['always', 'onsite', 'never']),
          priority: Yup.number(),

          // SITE SEARCHABILITY
          searchability: Yup.string().oneOf(['always', 'onsite', 'never']),

          // HOURS
          ...HOURS_VALIDATION_OBJECT,
        },
        languages,
        {
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

export const initialParser = (SiteData, languages) => {
  const tempVal = {
    excludeCategoryTags: SiteData?.excludeCategoryTags || [],
    designation: SiteData?.designation,
    saveBack: null,
    isMapped: SiteData?.isMapped || false,
    langES: hasSpanish(languages) || false,

    // MAPPING INPUTS
    ...!!SiteData?.indoorLocation?.mapId && {
      mapID: SiteData?.indoorLocation?.mapId,
    },
    CampusNav: SiteData?.isCampusNavEnabled || false,
    parkingType: SiteData?.navigation?.parkingType || 'none',
    strictParking:
      SiteData?.navigation?.enableStrictParkingArrival === undefined
      || SiteData?.navigation?.enableStrictParkingArrival === null
        ? true
        : SiteData?.navigation.enableStrictParkingArrival,
    alternateTransportation: SiteData?.navigation?.enableAlternateNavigation
      ? ['driving']
      : [],
    mapBoundary: [
      SiteData?.osmMapInfo?.boundWest || 0,
      SiteData?.osmMapInfo?.boundSouth || 0,
      SiteData?.osmMapInfo?.boundEast || 0,
      SiteData?.osmMapInfo?.boundNorth || 0,
    ],
    mapRotation: SiteData?.geoIndoorTranslation?.rotation || 0,
    latitude: SiteData?.geoIndoorTranslation?.geoPoint?.lat || 0,
    longitude: SiteData?.geoIndoorTranslation?.geoPoint?.lng || 0,
    xAxis: SiteData?.geoIndoorTranslation?.indoorPoint?.x || 0,
    yAxis: SiteData?.geoIndoorTranslation?.indoorPoint?.y || 0,

    // SITE DETAILS
    externalId: SiteData?.externalId,
    ...!!parseByLang(SiteData?.name) && {
      name: parseByLang(SiteData?.name),
    },
    ...!!parseByLang(SiteData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(SiteData?.name, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(SiteData?.shortName) && {
      shortName: parseByLang(SiteData?.shortName),
    },
    ...!!parseByLang(SiteData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(SiteData?.shortName, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(SiteData?.description) && {
      description: parseByLang(SiteData?.description),
    },
    ...!!parseByLang(SiteData?.description, LANGUAGE_CODES.SPANISH) && {
      descriptionES: parseByLang(SiteData?.description, LANGUAGE_CODES.SPANISH),
    },

    // SITE ADDRESS
    ...!!SiteData?.geoLocation?.address?.street && {
      street: SiteData.geoLocation.address.street,
    },
    ...!!SiteData?.geoLocation?.address?.building && {
      building: SiteData.geoLocation.address.building,
    },
    ...!!SiteData?.geoLocation?.address?.floor && {
      floor: SiteData.geoLocation.address.floor,
    },
    ...!!SiteData?.geoLocation?.address?.suite && {
      suite: SiteData.geoLocation.address.suite,
    },
    ...!!SiteData?.geoLocation?.address?.city && {
      city: SiteData.geoLocation.address.city,
    },
    ...!!SiteData?.geoLocation?.address?.state && {
      state: SiteData.geoLocation.address.state,
    },
    ...!!SiteData?.geoLocation?.address?.zip && {
      zip: SiteData.geoLocation.address.zip,
    },
    autoPopulateCoords:
    SiteData?.metadata?.ui?.autoPopulateCoordinatesFromAddress,

    ...!!SiteData?.navigation?.defaultPlace && {
      defaultDestination: SiteData?.navigation?.defaultPlace?.id,
    },
    ...Array.isArray(SiteData?.geoLocation?.perimeter?.points)
      && SiteData?.isMapped
      && SiteData.geoLocation.perimeter.points.length > 0 && {
        siteGeofenceLatitude:
          SiteData.geoLocation.perimeter.points[0].lat || undefined,
      },
    ...Array.isArray(SiteData?.geoLocation?.perimeter?.points)
      && SiteData?.isMapped
      && SiteData.geoLocation.perimeter.points.length > 0 && {
        siteGeofenceLongitude:
          SiteData.geoLocation.perimeter.points[0].lng || undefined,
      },
    ...!isNaN(SiteData?.geoLocation?.perimeter?.radius) && SiteData?.isMapped
      ? {
        siteGeofenceRadius: Number(SiteData.geoLocation.perimeter.radius),
      }
      : { siteGeofenceRadius: DEFAULT_RADIUS_IN_METERS },
    ...!!SiteData?.geoLocation?.markerPoint?.lat && {
      displayLatitude: SiteData.geoLocation.markerPoint.lat,
    },
    ...!!SiteData?.geoLocation?.markerPoint?.lng && {
      displayLongitude: SiteData.geoLocation.markerPoint.lng,
    },
    ...!!SiteData?.geoLocation?.navigationPoint?.lat && {
      destinationLatitude: SiteData.geoLocation.navigationPoint.lat,
    },
    ...!!SiteData?.geoLocation?.navigationPoint?.lng && {
      destinationLongitude: SiteData.geoLocation.navigationPoint.lng,
    },
    ...!!SiteData?.departmentIds && {
      departmentIds: SiteData.departmentIds ?? [],
    },

    providerMatchingEnabled: SiteData?.matching?.enabled || false,
    providerMatchingRule:
      JSON.stringify(SiteData?.matching?.rule, null, 2) || '',

    // HOURS
    hoursType: SiteData?.contact?.hours?.type ?? 'none',
    customHours: SiteData?.contact?.hours?.custom ?? [...DEFAULT_CUSTOM_HOURS],
    exceptionHours: filterExceptionHours(SiteData?.contact?.exceptionHours),

    // SITE MEDIA
    ...!!SiteData?.media && {
      media: SiteData.media ?? [],
    },
    ...!!SiteData?.defaultImage && {
      defaultImage: SiteData.defaultImage,
    },

    // MAP VISIBILITY
    visibility: SiteData?.visibility ?? 'always',
    priority: SiteData?.priority ?? 50,

    // SEARCHABILITY
    searchability: SiteData?.searchability ?? 'always',

    // Categories
    ...!!SiteData?.categories && {
      categories:
        SiteData.categories?.map((cat) => ({
          id: cat.id,
          name: parseByLang(cat.name),
        })) || [],
    },

    // Tags
    ...parseTags(SiteData?.tags?.flatMap((item) => item.name)),

    // no ids, so we need to track idx as id
    actionLinks:
      SiteData?.actionLinks?.map((val, idx) => ({ idx, ...val })) || [],
  };
  return tempVal;
};

const parseTags = (tagArray) => {
  const tagsEn = [];
  const tagsEs = [];
  if (tagArray) {
    tagArray.forEach((tag) => {
      if (tag.lang === LANGUAGE_CODES.ENGLISH) {
        tagsEn.push(tag.label);
      } else if (tag.lang === LANGUAGE_CODES.SPANISH) {
        tagsEs.push(tag.label);
      }
    });
  }
  return { tags: { en: tagsEn, es: tagsEs } };
};

export const uploadMediaImagesAndVideos = async (dispatch, mediaFiles) => {
  if (mediaFiles && mediaFiles.length) {
    return await Promise.all(
      mediaFiles.map(({ file, video }) => file
          ? dispatch(uploadMediaImageToService(file.file ?? file))
          : dispatch(uploadMediaVideoToService(video.url)),
      ),
    );
  }

  return [];
};

export const updateMedia = async (dispatch, mediaFiles) => {
  if (mediaFiles && mediaFiles.length) {
    return await Promise.all(
      mediaFiles.map(({ id, type, file, image, video }) => file
          ? dispatch(updateMediaImageToService(id, type, file, image.id))
          : dispatch(updateMediaVideoToService(id, type, video.url)),
      ),
    );
  }

  return [];
};

export const deleteMedia = async (mediaFiles) => {
  if (mediaFiles && mediaFiles.length) {
    return await Promise.all(mediaFiles.map(({ id }) => softDeleteMedia(id)));
  }

  return [];
};

const buildActionLink = async (link) => {
  let imageId;
  if (link.type === 'custom') {
    imageId = link.icon?.image?.id;
    if (link.icon?.file) {
      const uploadedImage = await uploadImage(link.icon.file);
      imageId = uploadedImage?.id;
    }
  }
  return {
    ...link,
    __typename: undefined,
    icon: imageId,
    idx: undefined,
  };
};

const prepareActionLinks = async (actionLinks) => {
  if (!actionLinks) return [];

  return Promise.all(actionLinks.map((link) => buildActionLink(link)));
};

export const submitParser = async (dispatch, values = {}) => {
  const geolocationValues = { address: {} };
  [
    'building',
    'floor',
    'suite',
    'street',
    'streetNumber',
    'city',
    'state',
    'zip',
  ].forEach((fieldName) => {
    geolocationValues.address[fieldName] = values.hasOwnProperty(fieldName)
      ? values[fieldName]
      : null;
  });

  const restValues = {};
  if (values.media) {
    const mediaToBeUploaded = [];
    const mediaToBeUpdated = [];
    const mediaToBeDeleted = [];
    values.media.forEach((m) => {
      if (m.id) {
        if (m.toBeUpdated) {
          mediaToBeUpdated.push(m);
        } else if (m.toBeDeleted) {
          mediaToBeDeleted.push(m);
        }
      } else if (m.file) {
        mediaToBeUploaded.push(m); // image
      } else if (m.type === 'video') {
        mediaToBeUploaded.push(m); // video
      }
    });
    const uploadedImagesAndVideos = mediaToBeUploaded.length
      ? await uploadMediaImagesAndVideos(dispatch, mediaToBeUploaded)
      : [];

    if (mediaToBeUpdated.length) {
      await updateMedia(dispatch, mediaToBeUpdated);
    }

    if (mediaToBeDeleted.length) {
      await deleteMedia(mediaToBeDeleted);
    }

    const updatedList = [];
    let index = 0;
    values.media.forEach((m) => {
      if (m.id) {
        if (!m.toBeDeleted) {
          updatedList.push(m.id);
        }
      } else {
        updatedList.push(uploadedImagesAndVideos[index++].id);
      }
    });
    restValues.media = updatedList;
  }

  let defaultImageId = null;
  if (values.defaultImage) {
    if (values.defaultImage.image?.id) {
      defaultImageId = values.defaultImage.image.id;
    } else if (values.defaultImage.id) {
      defaultImageId = values.defaultImage.id;
    } else if (values.defaultImage.file) {
      const uploadedImage = await dispatch(
        uploadImageToService(values.defaultImage.file),
      );
      defaultImageId = uploadedImage?.id;
    }
  }

  if (values.deletedDefaultImageId) {
    await softDeleteImage(values.deletedDEfaultImageId);
  }

  restValues.categories = values.categories?.map((cat) => cat.id) || [];
  restValues.excludeCategoryTags = values.excludeCategoryTags || [];

  if (values.tags.en.length > 0) {
    restValues.tags = values.tags.en.map((tag) => ({
      name: [{ lang: LANGUAGE_CODES.ENGLISH, label: tag }],
    }));
  } else {
    restValues.tags = [];
  }

  if (values.langES) {
    restValues.tags = [
      ...restValues.tags,
      ...values.tags.es.map((tag) => ({
        name: [{ lang: LANGUAGE_CODES.SPANISH, label: tag }],
      })),
    ];
  }

  restValues.actionLinks = await prepareActionLinks(values.actionLinks);

  const submitVal = {
    // MAPPING INPUTS
    ...values.isMapped && {
      indoorLocation: { mapId: Number(values?.mapID) || null },
    },
    osmMapInfo: {
      ...!!values.mapBoundary?.[0] && {
        boundWest: Number(values?.mapBoundary[0]),
      },
      ...!!values.mapBoundary?.[1] && {
        boundSouth: Number(values?.mapBoundary[1]),
      },
      ...!!values.mapBoundary?.[2] && {
        boundEast: Number(values?.mapBoundary[2]),
      },
      ...!!values.mapBoundary?.[3] && {
        boundNorth: Number(values?.mapBoundary[3]),
      },
    },
    navigation: {
      ...!!values.parkingType && {
        parkingType: values?.parkingType,
      },
      ...{
        enableStrictParkingArrival:
          values?.parkingType === 'spot' ? !!values?.strictParking : false,
      },
      enableAlternateNavigation:
        values?.alternateTransportation?.[0] === 'driving',

      ...!!values.defaultDestination && {
        defaultPlace: values.defaultDestination,
      },
    },
    geoIndoorTranslation: {
      ...!!values.mapRotation && { rotation: Number(values.mapRotation) },
      geoPoint: {
        ...values.latitude && { lat: Number(values.latitude) },
        ...values.latitude && { lng: Number(values.longitude) },
      },
      indoorPoint: {
        ...!!values.xAxis && { x: Number(values.xAxis) },
        ...!!values.yAxis && { y: Number(values.yAxis) },
      },
    },
    isCampusNavEnabled: !!values?.CampusNav,

    // SITE DETAILS
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
    description: formatLang('description', values),

    // SITE ADDRESS
    metadata: {
      ui: {
        autoPopulateCoordinatesFromAddress: values.autoPopulateCoords,
      },
    },
    geoLocation: {
      ...{
        markerPoint: {
          lat: values.displayLatitude ? Number(values.displayLatitude) : null,
          lng: values.displayLongitude ? Number(values.displayLongitude) : null,
        },
      },
      ...!values.isMapped && {
        navigationPoint: {
          lat: values.destinationLatitude
            ? Number(values.destinationLatitude)
            : null,
          lng: values.destinationLongitude
            ? Number(values.destinationLongitude)
            : null,
        },
      },

      ...values.isMapped && {
        perimeter: {
          ...{
            radius: !isNaN(values.siteGeofenceRadius)
              ? Number(values.siteGeofenceRadius)
              : null,
          },
          ...{
            points: [
              {
                lat: values.siteGeofenceLatitude
                  ? Number(values.siteGeofenceLatitude)
                  : null,
                lng: values.siteGeofenceLongitude
                  ? Number(values.siteGeofenceLongitude)
                  : null,
              },
            ],
          },
        },
      },
      ...geolocationValues,
    },

    ...!!values.departmentIds && {
      departmentIds: values.departmentIds,
    },

    matching: {
      enabled: values.providerMatchingEnabled || false,
      rule:
        values.providerMatchingRule && values.providerMatchingRule !== ''
          ? JSON.parse(values.providerMatchingRule)
          : {},
    },

    ...!!values.hoursType && {
      contact: {
        hours: {
          type: values.hoursType,
          custom:
            values.customHours && values.customHours.length
              ? values.customHours.map(
                ({ __typename, day, status, hours }) => ({
                  day,
                  status,
                  hours: hours.map(({ __typename, ...rest }) => rest),
                }),
              )
              : [],
        },
        ...!!values.exceptionHours && {
          exceptionHours: values.exceptionHours,
        },
      },
    },
    defaultImage: defaultImageId,
    searchability: values.searchability,
    visibility: values.visibility,
    priority: values.priority,
    ...restValues,
  };
  return submitVal;
};
