import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';

export const buildModalStyles = ({ theme }) => ({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: colorWithAlpha(ColorPalette.grey[300], 0.66),
      backdropFilter: 'blur(6px)',
    },
  },
  actions: {
    background: theme.palette.grey[100],
    margin: 0,
    padding: theme.spacing(2),
    borderRadius: '0 0 8px 8px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  closeBtn: {
    padding: theme.spacing(2),
    position: 'absolute',
    right: 0,
    top: 0,
    '& svg': {
      fontSize: '22px',
    },
  },
  dialogContent: {
    flex: 1,
    overflowX: 'hidden',
    paddingTop: '20px',

    '&.MuiDialogContent-root:first-of-type': {
      paddingTop: 0,
    },
  },
  dialogContentCentered: {
    flex: 1,
    overflowX: 'hidden',
    marginBottom: theme.spacing(15),
    textAlign: 'center',
  },
  formContent: {
    '& form': {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '24px',
    },
  },
  nonScrollableContent: {
    overflow: 'hidden',
  },
  paperScrollPaper: {
    minHeight: '266px',
    maxHeight: 'calc(100% - 80px)',
  },
  titleCentered: {
    padding: 0,
    '& h2': {
      color: theme.palette.grey[500],
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '39px',
    },
    '& svg': {
      color: colorWithAlpha(theme.palette.black, 0.54),
    },
    marginTop: `${theme.spacing(15)} !important`,
    textAlign: 'center',
  },
  titleStyle: {
    padding: '32px 32px 0',

    '& div': {
      color: theme.palette.grey[500],
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '39px',
    },
    '& svg': {
      color: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
});
