import { useMutation, useSuspenseQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import {
  CREATE_NETWORK_BANNER_MESSAGE,
  GET_NETWORK_BANNER_MESSAGES,
  //GET_NETWORK_BANNER_MESSAGE_HISTORY,
  UPDATE_NETWORK_BANNER_MESSAGE,
} from 'graphql/queries';

export const useNetworkBanners = (networkId) => {
  const { handleError } = useHandleError('BannerMessage');
  return useSuspenseQuery(GET_NETWORK_BANNER_MESSAGES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateNetworkBanner = () => {
  const { handleError } = useHandleError('BannerMessage');
  const { toastNotificationSuccessHook } = useToast();
  return useMutation(CREATE_NETWORK_BANNER_MESSAGE, {
    onCompleted: (data) => {
      if (data.createNetworkBannerMessage.status === 'active') {
        toastNotificationSuccessHook('Your banner was published');
      } else {
        toastNotificationSuccessHook('Your banner was created');
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Banner creation failed'),
  });
};

export const useUpdateNetworkBanner = () => {
  const { handleError } = useHandleError('BannerMessage');
  const { toastNotificationSuccessHook } = useToast();
  return useMutation(UPDATE_NETWORK_BANNER_MESSAGE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.updateNetworkBannerMessage.status === 'active') {
        toastNotificationSuccessHook('Your banner was published');
      } else if (data?.updateNetworkBannerMessage.status === 'published') {
        toastNotificationSuccessHook('Your banner was recalled');
      } else {
        toastNotificationSuccessHook('Your banner was updated');
      }
    },
    onError: (props) => handleError(
        props?.graphQLErrors,
        props?.networkError,
        'Banner update failed',
      ),
  });
};

export default useNetworkBanners;
