import { gql } from '@apollo/client';
import { fetchApollo } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import { fromi18nArrayEntriesErr, toError } from 'helpers/transformer-util';
import { produce } from 'immer';

export const TYPES = typeUtil([
  'RESET_PERSONA_FLASH',
  'GET_PERSONAS',
  'CREATE_PERSONA',
  'UPDATE_PERSONA',
  'DELETE_PERSONA',
]);

const initialState = { loading: true, personas: [] };

const fromPersonaRemoteErr = (errObject, remoteObject) => {
  const result = {};
  if (errObject.name) {
    result.name = fromi18nArrayEntriesErr(
      (errObject || {}).name,
      (remoteObject || {}).name,
    );
  }

  if (Array.isArray(errObject.tags)) {
    result.tags = errObject.tags.map((tag, index) => {
      const remotetag = remoteObject.tags[index];
      return {
        ...tag,
        name: fromi18nArrayEntriesErr(tag.name, remotetag.name),
      };
    });
  } else {
    result.tags = errObject.tags;
  }
  return result;
};

export const getPersonas = (params) => (dispatch) => fetchApollo({
    doc: gql`
      query getNetwork($networkId: ID!) {
        getNetwork(id: $networkId) {
          id
          name
          personas {
            id
            name
            icon {
              url
              image {
                id
              }
            }
            isDefault
            publishable
          }
        }
      }
    `,
    variables: { networkId: params.networkId },
    method: 'query',
    queryName: 'getNetwork',
    type: TYPES.GET_PERSONAS,
    params,
    dispatch,
  });

export const createNetworkPersona = (params) => (dispatch) => {
  const { name, icon, publishable } = params;

  const input = {
    icon,
    name,
    publishable,
  };

  return fetchApollo({
    doc: gql`
      mutation createNetworkPersona($input: NetworkPersonaInputCreate!) {
        createNetworkPersona(input: $input) {
          id
          name
          icon {
            url
            image {
              id
            }
          }
          isDefault
          publishable
        }
      }
    `,
    variables: { input },
    method: 'mutate',
    queryName: 'createNetworkPersona',
    type: TYPES.CREATE_PERSONA,
    params,
    dispatch,
  });
};

export const updateNetworkPersona = (params) => (dispatch) => {
  const { personaId, input } = params;

  return fetchApollo({
    doc: gql`
			mutation updateNetworkPersona($input: NetworkPersonaInputUpdate!) {
				updateNetworkPersona(
					id: "${personaId}"
					input: $input
				) {
					id
					name
					icon {
						url
						image {
							id
						}
					}
					isDefault
					publishable
				}
			}
		`,
    variables: { input },
    method: 'mutate',
    queryName: 'updateNetworkPersona',
    type: TYPES.UPDATE_PERSONA,
    params,
    dispatch,
  });
};

export const deleteNetworkPersona = (params) => (dispatch) => {
  const { personaId: id } = params;

  return fetchApollo({
    doc: gql`
      mutation deleteNetworkPersona($id: ID!) {
        deleteNetworkPersona(id: $id) {
          id
          name
          icon {
            url
            image {
              id
            }
          }
          isDefault
          publishable
        }
      }
    `,
    variables: { id },
    method: 'mutate',
    queryName: 'deleteNetworkPersona',
    type: TYPES.DELETE_PERSONA,
    params,
    dispatch,
  });
};

export const resetPersnoaFlash = () => (dispatch) => {
  dispatch({
    type: TYPES.RESET_PERSONA_FLASH,
  });
  return Promise.resolve();
};

export default (state = initialState, action) => {
  let updatedPersonaList;
  switch (action.type) {
    case TYPES.RESET_PERSONA_FLASH:
      return {
        ...state,
        flash: null,
      };

    case TYPES.GET_PERSONAS_SUCCESS:
      return {
        ...state,
        loading: false,
        personas: action.result.personas,
      };

    case TYPES.CREATE_PERSONA_SUCCESS:
      updatedPersonaList = produce(state.personas, (draft) => {
        draft.push(action.result);
      });

      return {
        ...state,
        loading: false,
        personas: updatedPersonaList,
      };

    case TYPES.UPDATE_PERSONA_SUCCESS:
      updatedPersonaList = produce(state.personas, (draft) => {
        draft[
          draft.findIndex(
            (persona) => persona && persona.id === action.result.id,
          )
        ] = action.result;
      });
      return {
        ...state,
        loading: false,
        personas: updatedPersonaList,
      };

    case TYPES.DELETE_PERSONA_SUCCESS:
      updatedPersonaList = produce(state.personas, (draft) => {
        delete draft[
          draft.findIndex(
            (persona) => persona && persona.id === action.result.id,
          )
        ];
      });
      return {
        ...state,
        loading: false,
        personas: updatedPersonaList,
      };

    case TYPES.GET_PERSONAS_ERROR:
    case TYPES.CREATE_PERSONA_ERROR:
    case TYPES.UPDATE_PERSONA_ERROR:
    case TYPES.DELETE_PERSONA_ERROR:
      return {
        ...state,
        loading: false,
        flash: {
          error: true,
          status: 'error',
          ...toError(action.result, (errObject) => fromPersonaRemoteErr(errObject, action.remoteObject),
          ),
        },
      };

    default:
      return state;
  }
};
