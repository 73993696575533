import { gql } from '@apollo/client';

export const FIND_NETWORK_DEEP_LINK_CONFIGURATION = gql`
  query findNetworkDeepLinkConfiguration(
    $where: NetworkDeepLinkConfigurationInputWhere
  ) {
    findNetworkDeepLinkConfiguration(where: $where) {
      edges {
        node {
          alternateShortUrlDomain
          analytics {
            channel
            feature
          }
          android {
            packageName
            playStoreUrl
            sha256CertFingerprints
            uriScheme
          }
          branding {
            networkName
          }
          customUrlDomain
          desktopDeepview
          id
          ios {
            appStoreId
            appStoreUrl
            bundleIds
            teamId
            uriScheme
          }
          mobileAppId
          network {
            mobileApps {
              id
              name
              type
            }
          }
          openGraphMeta {
            contentDescription
            imageUrl
            title
          }
          shortUrlDomain
          webUrl
        }
      }
    }
  }
`;

export const CREATE_NETWORK_DEEP_LINK_CONFIGURATION = gql`
  mutation createNetworkDeepLinkConfiguration(
    $input: NetworkDeepLinkConfigurationInputCreate!
  ) {
    createNetworkDeepLinkConfiguration(input: $input) {
      alternateShortUrlDomain
      android {
        packageName
        playStoreUrl
        sha256CertFingerprints
        uriScheme
      }
      branding {
        networkName
      }
      customUrlDomain
      desktopDeepview
      id
      ios {
        appStoreId
        appStoreUrl
        bundleIds
        teamId
        uriScheme
      }
      mobileAppId
      network {
        mobileApps {
          id
          name
          type
        }
      }
      openGraphMeta {
        contentDescription
        imageUrl
        title
      }
      shortUrlDomain
      webUrl
    }
  }
`;

export const UPDATE_NETWORK_DEEP_LINK_CONFIGURATION = gql`
  mutation updateNetworkDeepLinkConfiguration(
    $id: ID!
    $input: NetworkDeepLinkConfigurationInputUpdate
  ) {
    updateNetworkDeepLinkConfiguration(id: $id, input: $input) {
      alternateShortUrlDomain
      android {
        packageName
        playStoreUrl
        sha256CertFingerprints
        uriScheme
      }
      branding {
        networkName
      }
      customUrlDomain
      desktopDeepview
      id
      ios {
        appStoreId
        appStoreUrl
        bundleIds
        teamId
        uriScheme
      }
      mobileAppId
      network {
        mobileApps {
          id
          name
          type
        }
      }
      openGraphMeta {
        contentDescription
        imageUrl
        title
      }
      shortUrlDomain
      webUrl
    }
  }
`;

export const FIND_NETWORK_DEEP_LINK = gql`
  query findNetworkDeepLink($where: NetworkDeepLinkInputWhere) {
    findNetworkDeepLink(where: $where) {
      edges {
        node {
          analytics {
            campaign
            channel
            feature
            tags
          }
          id
          isDesktopDeepview
          isVisible
          linkData
          linkName
          openGraphMeta {
            contentDescription
            imageUrl
            title
          }
          linkAlias
          referringLink
          updatedAt
          updatedBy {
            profile {
              firstName
              lastName
            }
          }
          webUrl
        }
      }
    }
  }
`;

export const CREATE_NETWORK_DEEP_LINK = gql`
  mutation createNetworkDeepLink($input: NetworkDeepLinkInputCreate!) {
    createNetworkDeepLink(input: $input) {
      analytics {
        channel
        campaign
        feature
        tags
      }
      createdAt
      id
      isDesktopDeepview
      linkAlias
      linkData
      linkName
      openGraphMeta {
        contentDescription
        imageUrl
        title
      }
      updatedAt
      updatedBy {
        profile {
          firstName
          lastName
        }
        id
      }
      webUrl
    }
  }
`;

export const UPDATE_NETWORK_DEEP_LINK = gql`
  mutation updateNetworkDeepLink($id: ID!, $input: NetworkDeepLinkInputUpdate) {
    updateNetworkDeepLink(id: $id, input: $input) {
      analytics {
        channel
        campaign
        feature
        tags
      }
      createdAt
      id
      isDesktopDeepview
      linkAlias
      linkData
      linkName
      openGraphMeta {
        contentDescription
        imageUrl
        title
      }
      redirect {
        android
        desktop
        ios
      }
      updatedAt
      updatedBy {
        profile {
          firstName
          lastName
        }
        id
      }
      webUrl
    }
  }
`;
