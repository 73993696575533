import WebAsset from '@mui/icons-material/WebAsset';
import { Backdrop, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Authorize, SCOPES } from 'components/authorization/authorize';
import CustomerMenu from 'components/navigation/customerMenu';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { resetAllTableSettings } from 'helpers/table-util';
import { useLogout } from 'hooks/dataHooks';
import { useSearchParams } from 'hooks/useSearchParams';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    zIndex: theme.zIndex.drawer,
  },
  globalItem: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  list: {
    height: 'inherit',
    overflowY: 'auto',
    paddingBottom: '300px',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      fontWeight: 700,
    },
    color: theme.palette.text.primary,
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
    lineHeight: '1.2rem',
    paddingBottom: '9px',
    paddingTop: '9px',
  },
  networkHeading: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  root: {
    backgroundColor: theme.palette.white,
    height: '100vh',
    padding: 0,
    width: '250px',
  },
  spacer: {
    alignItems: 'center',
    boxShadow: `4px 4px 8px 0px ${colorWithAlpha(
      theme.palette.grey[350],
      0.24,
    )}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    height: '70px',
    justifyContent: 'center',
    padding: '0 15px',
  },
}));
const NetworkSelector = ({ customerName, handleClose, networks, open }) => {
  const classes = useStyles();
  const { searchParams } = useSearchParams();
  const { activeNetwork, globalTemplate, handleNavigate }
    = useContext(FlamingoContext);
  const logout = useLogout();

  const handleSelectNetwork = (newNetworkId) => {
    resetAllTableSettings();
    if (isGlobalTemplate(newNetworkId)) {
      activeNetwork.setActiveNetwork(globalTemplate);
    } else {
      const selectedNetwork = networks.find((n) => n.id === newNetworkId);
      if (selectedNetwork) {
        activeNetwork.setActiveNetwork(selectedNetwork);
      } else {
        logout();
      }
    }
    handleNavigate(`/network/${newNetworkId}/welcome`, {
      state: { search: searchParams.toString() },
    });
  };

  return (
    <Backdrop
      classes={{
        root: classes.backdrop,
      }}
      open={open}
      onClick={handleClose}
    >
      <Box className={classes.root}>
        <Box className={classes.spacer}>
          <CustomerMenu customerName={customerName} />
        </Box>
        <List className={classes.list} data-test="network-selector-list">
          <Authorize scope={SCOPES.GLOBAL_TEMPLATE.ACCESS}>
            <ListItem
              button
              data-test="global-network-select"
              className={clsx(classes.listItem, classes.globalItem)}
              onClick={() => handleSelectNetwork('Global Template')}
            >
              <ListItemIcon>
                <WebAsset className={classes.globalLogo} />
              </ListItemIcon>
              <ListItemText disableTypography primary="Global Template" />
            </ListItem>
          </Authorize>
          <ListItem sx={{ paddingBottom: 0 }}>
            <ListItemText disableTypography className={classes.networkHeading}>
              CUSTOMERS
            </ListItemText>
          </ListItem>
          {!!networks
            && networks.map((network, idx) => (
              <ListItem
                button
                className={classes.listItem}
                onClick={() => handleSelectNetwork(network.id)}
                key={`network-${network.id ?? idx}`}
                data-test={`network-selector-${network.humanName}`}
              >
                <ListItemText disableTypography primary={network.humanName} />
              </ListItem>
            ))}
        </List>
      </Box>
    </Backdrop>
  );
};

NetworkSelector.propTypes = {
  networks: PropTypes.array,
  customerName: PropTypes.string.isRequired,
};

NetworkSelector.defaultProps = {
  networks: [],
};

export default NetworkSelector;
