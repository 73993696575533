import sections from 'components/authorization/sections';

export const permissions = {
  READ: 'read',
  EDIT: 'update',
  CREATE: 'create',
  DELETE: 'delete',
};

export const section = sections;
