import { ContactSupport, Launch } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GozioDrawer from 'components/drawer/drawer';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    color: theme.palette.white,
    fontFamily: 'Poppins',

    '& $content:hover': {
      background: '#f2f2f2',
    },
  },
  closeButton: {
    color: theme.palette.white,
    zIndex: 1000,
  },
  header: {
    background: 'rgb(33, 150, 243)',
    color: theme.palette.white,
    marginTop: '-68px',
    minHeight: '80px',
    padding: '10px 30px 20px 30px',
    position: 'relative',

    '& h3': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    '& h4': {
      color: colorWithAlpha(theme.palette.white, 0.6),
      fontSize: '14px',
    },
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',

    '& svg': {
      width: '50px',
      height: '50px',
    },
  },
  text: {
    display: 'inline-block',
    marginLeft: '65px',
    verticalAlign: 'middle',
  },
  content: {
    borderBottom: '1px solid rgb(221, 226, 229)',
    color: 'rgb(35, 35, 35)',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: '20px 30px 20px 30px',
    position: 'relative',
    width: '100%',
  },
  contentText: {
    display: 'inline-block',
    width: '80%',
    verticalAlign: 'middle',
  },
  contentTitle: {
    fontSize: '16px',
  },
  contentDescription: {
    fontSize: '12px',
    marginTop: '5px',
    opacity: 0.6,
  },
  contentIcon: {
    display: 'inline-block',
    position: 'absolute',
    right: '50px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '10%',
    verticalAlign: 'middle',

    '& svg': {
      color: '#ACB5BD',
      width: '24px',
      height: '24px',
    },
  },
});

const HelpDrawer = ({ onClick, onClose }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <GozioDrawer
      anchor="left"
      closeButtonSx={styles.closeButton}
      disableCancel={true}
      disableConfirm={true}
      open={true}
      onClose={onClose}
      sx={styles.root}
      width={450}
    >
      <Box sx={styles.header}>
        <Box sx={styles.icon}>
          <ContactSupport />
        </Box>
        <Box sx={styles.text}>
          <h3>Resource Center</h3>
          <h4>Help from the Gozio Health team</h4>
        </Box>
      </Box>
      <Box sx={styles.content} onClick={onClick}>
        <Box sx={styles.contentText}>
          <Box sx={styles.contentTitle}>Need Portal Help</Box>
          <Box sx={styles.contentDescription}>
            Find anything you need about the Gozio Portal
          </Box>
        </Box>
        <Box sx={styles.contentIcon}>
          <Launch />
        </Box>
      </Box>
    </GozioDrawer>
  );
};

HelpDrawer.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

HelpDrawer.defaultProps = {};

export default HelpDrawer;
