import { Info, Warning } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[100],
    borderRadius: '8px',
    display: 'flex',
    padding: '8px 14px 8px 14px',
    width: 'fit-content',
  },
  rootWithBoxShadow: {
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
    padding: '5px 14px 5px 14px',
  },
  rootWithFullWidth: {
    width: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  icon: {
    alignItems: 'flex-start',
  },
  info: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: '10px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      display: '-webkit-box',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      WebkitBoxOrient: 'vertical',
    },
  },
  progressContainer: {
    display: 'block',
    marginLeft: '35px',
    marginTop: '3px',
    flexGrow: 1,
  },
  warning: {
    fill: `${theme.palette.orange[400]} !important`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: '10px',
  },
});

const GozioInfoPanel = ({
                          boxShadow,
                          fullWidth,
                          maxLines,
                          progress = null,
                          title,
                          type,
                          sx,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box
      sx={{
        ...styles.root,
        ...boxShadow && styles.rootWithBoxShadow,
        ...fullWidth && styles.rootWithFullWidth,
        ...sx,
      }}
    >
      <Box sx={styles.content}>
        <Box sx={styles.icon}>
          {type === 'warning' ? (
            <Warning sx={styles.warning} />
          ) : (
            <Info sx={styles.info} color="primary" />
          )}
        </Box>
        <Box sx={styles.text}>
          <Typography
            variant="body2"
            sx={{ ...maxLines && { WebkitLineClamp: 2 } }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      {progress !== null && (
        <Box sx={styles.progressContainer}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {`${Math.floor(progress)}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

GozioInfoPanel.propTypes = {
  boxShadow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxLines: PropTypes.number,
  progress: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['info', 'warning']),
};

GozioInfoPanel.defaultProps = {
  boxShadow: false,
  fullWidth: true,
  maxLines: 2,
  sx: {},
  type: 'info',
};

export default GozioInfoPanel;
