import { SecureUrlRegexp } from 'helpers/string-util';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

export const templateSchema = Yup.object().shape({
  screensaverCta: Yup.string().nullable(),
  screensaverBackgroundColor: Yup.string()
    .required()
    .matches(/^#[a-fA-F0-9]{6}$/, 'Must be a valid hex color'),
  mainCtaText: Yup.string().nullable().max(40, 'Must be 40 characters or less'),
  mainSearchPlaceholderText: Yup.string()
    .nullable()
    .max(40, 'Must be 40 characters or less'),
  mainBackgroundColor: Yup.string()
    .required()
    .matches(/^#[a-fA-F0-9]{6}$/, 'Must be a valid hex color'),
  ctaActionUrl: Yup.string().nullable().matches(SecureUrlRegexp, 'Invalid URL'),
});

export const initialParser = (data) => {
  const parsedVal = {
    id: data?.id,
    screensaverBackgroundColor: data?.screensaver?.backgroundColor || '#F3F3F3',
    screensaverLogo: data?.screensaver?.networkLogo || null,
    screensaverCta: data?.screensaver?.ctaText || '',
    ctaImage: data?.screen?.ctaImage || null,
    ctaActionUrl: data?.screen?.actionUrl || null,
    mainCtaText: data?.screen?.ctaText,
    mainSearchPlaceholderText: data?.screen?.searchPlaceholderText,
    mainNetworkLogo: data?.screen?.networkLogo || null,
    mainSupportImage: data?.screen?.supportImage || null,
    mainBackgroundColor: data?.screen?.backgroundColor || '#FFFFFF',
  };
  return parsedVal;
};

const getImageId = async (image) => {
  if (image?.file) {
    const uploadedImage = await uploadImage(image.file);
    return uploadedImage?.id;
  }
  return image?.id;
};

export const submitParser = async (values = {}) => ({
  screensaver: {
    ctaText: values.screensaverCta,
    backgroundColor: values.screensaverBackgroundColor,
    networkLogo: await getImageId(values.screensaverLogo) || null,
  },
  screen: {
    ctaText: values.mainCtaText,
    ctaImage: await getImageId(values.ctaImage) || null,
    actionUrl: values.ctaActionUrl || null,
    networkLogo: await getImageId(values.mainNetworkLogo) || null,
    supportImage: await getImageId(values.mainSupportImage) || null,
    backgroundColor: values.mainBackgroundColor,
    searchPlaceholderText: values.mainSearchPlaceholderText,
  },
});

export const validate = makeValidate(templateSchema);
