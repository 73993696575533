import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AreaChart from 'components/analyticsDashboardCard/charts/areaChart';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import Loading from 'components/loading/loading';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import { DATE_FORMAT, retrieveAnalyticsFilter } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { formatDesignation } from 'helpers/location-util';
import { tabA11yProps, wrapWithTooltip } from 'helpers/page-util';
import { USER_ROLES } from 'helpers/permissions-util';
import {
  NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME,
  retrieveTableFilters,
  retrieveTablePageSize,
  retrieveTableSearch,
  TABLE_MAX_PAGE_SIZE,
} from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useAnalytics } from 'hooks/dataHooks/useAnalytics';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AnalyticsFilters, { ANALYTICS_FILTER_MAP } from './containers/analyticsFilters';
import AnalyticsDownloadButton from './containers/downloadButton';

const buildStyles = ({ theme }) => ({
  handleBack: {
    marginTop: '-16px !important',
  },
  loading: {
    height: '100%',
  },
  content: {
    borderRadius: '20px',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    height: '80vh',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabBody: {
    width: '100%',
    padding: '24px 24px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRange: {
    marginTop: '2px',
  },
  count: {
    fontSize: '34px',
    marginTop: '10px',
  },
  chartContainer: {
    marginTop: '10.5px',
  },
  customTooltip: {
    background: theme.palette.grey[600],
    borderRadius: '4px',
    padding: '12px 12px',
    maxWidth: '250px',

    '& p': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: 0,
    },
  },
  tooltipContainer: {
    '& span': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'bold',
      margin: '8px 0 0 0',
    },
  },
  tooltipHeading: {
    color: theme.palette.white,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '8px 0 0 0',
    width: '64px',
  },
  dot: {
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '6px',
    width: '16px',
    height: '16px',

    '&:not(:first-child)': {
      marginLeft: '6px',
    },
  },
  xAxisLabel: {
    color: theme.palette.grey[400],
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    marginLeft: '71px',
    marginTop: '1px',
    textAlign: 'center',
  },
});

export const NavigationEventsPage = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const { filter, rangeStart, rangeEnd } = retrieveAnalyticsFilter();
  const [currentTab, setCurrentTab] = useState(0);
  const [dateRangeKey, setDateRangeKey] = useState(null);
  const [dateRangeText, setDateRangeText] = useState(null);
  const [parentSites, setParentSites] = useState([]);
  const [parentBuildings, setParentBuildings] = useState([]);
  const [parentFloors, setParentFloors] = useState([]);

  const backToOverview = useCallback(() => {
    handleNavigate(`/network/${networkId}/analytics`);
  }, [handleNavigate, networkId]);

  const { data, fetch } = useAnalytics({
    networkId,
    endpoint: 'navigationevents',
  });

  const userRole = useCheckRole();
  const hasPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(userRole);

  const [tableParams, setTableParams] = useState({
    sortBy: { id: 'totalSessions', desc: true },
    pageSize:
      retrieveTablePageSize(
        NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME,
        TABLE_MAX_PAGE_SIZE,
      ) || TABLE_MAX_PAGE_SIZE,
    pageIndex: 0,
  });
  const [searchParam, setSearchParam] = useState(
    retrieveTableSearch(NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME),
  );
  const [filters, setFilters] = useState(
    retrieveTableFilters(NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME),
  );

  const filteredDestinations = useMemo(() => {
    if (data?.bySites) {
      const sites = data.bySites.map(({ id, name, label }) => ({
        id,
        name,
        label,
      }));
      const buildings = data.bySites.map(({ buildings }) => buildings).flat();
      const floors = buildings.map(({ floors }) => floors).flat();
      setParentSites(sites);
      setParentBuildings(buildings);
      setParentFloors(floors);
    }
    if (!filters.length && !searchParam) return (
        data?.byLocation.map((d) => ({
          ...d,
          name: wrapWithTooltip(d.name),
          type: formatDesignation(d.type),
        })) || []
      );
    if (data?.byLocation) {
      let destinations = [...data.byLocation];
      const filtersArr = [...filters.filter((f) => f.values.length)];
      while (filtersArr.length) {
        const filter = filtersArr.shift();
        if (filter.name === 'parentLocations') {
          const parentsArr = [...filter.values];
          while (parentsArr.length) {
            const parentFilter = parentsArr.shift();
            switch (parentFilter.type) {
              case 'parentSite':
                destinations = destinations.filter(
                  (d) => d.parent.site.id === parentFilter.value,
                );
                break;
              case 'parentBuilding':
                destinations = destinations.filter(
                  (d) => d.parent.building.id === parentFilter.value,
                );
                break;
              case 'parentFloor':
                destinations = destinations.filter(
                  (d) => d.parent.floor.id === parentFilter.value,
                );
                break;
              default:
                break;
            }
          }
        } else {
          const values = filter.values.map(({ value }) => value);
          destinations = destinations.filter((d) => values.includes(d[filter.name]),
          );
        }
      }

      if (searchParam) {
        destinations = destinations.filter((d) => d.name.toLowerCase().includes(searchParam.toLowerCase()),
        );
      }

      return destinations.map((d) => ({
        ...d,
        name: wrapWithTooltip(d.name),
        type: formatDesignation(d.type),
      }));
    }
  }, [filters, data, searchParam]);

  const CustomTooltip = React.memo(({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload ?? {};
      return (
        <Box sx={styles.customTooltip}>
          <p>Navigation Events</p>
          <Box sx={styles.tooltipContainer}>
            <Box
              sx={{
                ...styles.dot,
                backgroundColor: ColorPalette.chart.blue,
                marginBottom: '-3px',
              }}
            />
            <Box sx={styles.tooltipHeading}>Mixed</Box>
            <span>{data?.mixedSessions?.toLocaleString()}</span>
          </Box>
          <Box sx={styles.tooltipContainer}>
            <Box
              sx={{
                ...styles.dot,
                backgroundColor: ColorPalette.chart.green,
                marginBottom: '-3px',
              }}
            />
            <Box sx={styles.tooltipHeading}>Outdoor</Box>
            <span>{data?.outdoorsSessions?.toLocaleString()}</span>
          </Box>
          <Box sx={styles.tooltipContainer}>
            <Box
              sx={{
                ...styles.dot,
                backgroundColor: ColorPalette.chart.yellow,
                marginBottom: '-3px',
              }}
            />
            <Box sx={styles.tooltipHeading}>Indoor</Box>
            <span>{data?.indoorsSessions?.toLocaleString()}</span>
          </Box>
          <Box sx={{ ...styles.tooltipContainer, paddingLeft: '22px' }}>
            <Box sx={styles.tooltipHeading}>Date</Box>
            <span>{data.fullDate}</span>
          </Box>
        </Box>
      );
    }

    return null;
  });

  const chartData = useMemo(
    () => data?.byDay?.map(
        ({
           day,
           totalSessions,
           outdoorsSessions,
           indoorsSessions,
           mixedSessions,
         }) => ({
          totalSessions,
          outdoorsSessions,
          indoorsSessions,
          mixedSessions,
          day,
          date: dayjs(day).format('MMM D'),
          fullDate: dayjs(day).format('MMM D, YYYY'),
        }),
      ),
    [data],
  );

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 130,
      sticky: 'left',
    },
    {
      Header: 'Destination Type',
      accessor: 'destinationType',
      width: 110,
      filters: {
        type: 'select',
        options: [
          { value: 'Location', label: 'Location' },
          { value: 'Practice Location', label: 'Practice Location' },
          { value: 'Parking', label: 'Parking' },
        ],
      },
      disableGlobalFilter: true,
    },
    {
      Header: 'Location Type',
      accessor: 'type',
      width: 110,
      filters: {
        type: 'select',
        options: [
          { value: 'site', label: 'Site' },
          { value: 'building', label: 'Building' },
          { value: 'floor', label: 'Floor' },
          { value: 'poi', label: 'POI' },
        ],
      },
      disableGlobalFilter: true,
    },
    {
      Header: 'Mapping',
      accessor: 'mappingStatus',
      width: 110,
      filters: {
        type: 'select',
        options: [
          { value: 'Mapped', label: 'Mapped' },
          { value: 'Unmapped', label: 'Unmapped' },
          { value: 'Neither', label: 'Neither' },
        ],
      },
      disableGlobalFilter: true,
    },
    {
      Header: <span>Parent&nbsp;Site</span>,
      accessor: 'parent.site.name',
      minWidth: 125,
      disableGlobalFilter: true,
      filterAccessor: 'parentLocations',
      filterTitle: 'Parent',
      filters: {
        type: 'parentLocations',
        parentSites,
        parentBuildings,
        parentFloors,
      },
    },
    {
      Header: <span>Parent&nbsp;Building</span>,
      accessor: 'parent.building.name',
      minWidth: 125,
      disableGlobalFilter: true,
    },
    {
      Header: <span>Parent&nbsp;Floor</span>,
      accessor: 'parent.floor.name',
      minWidth: 125,
      disableGlobalFilter: true,
    },
    {
      Header: 'Sessions',
      accessor: 'totalSessions',
      minWidth: 125,
      disableGlobalFilter: true,
    },
    {
      Header: 'LOCATION ID',
      accessor: 'id',
      hidden: true,
      disableGlobalFilter: true,
    },
  ];

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName={
          <Box>
            Navigation Events
            <Box
              sx={{
                display: 'inline-block',
                marginLeft: '8px',
                verticalAlign: 'middle',
              }}
            >
              <InfoTooltip
                title={
                  <Box>
                    Navigation Events are categorized in three types: Indoor,
                    Outdoor, or Mixed. “Indoor” utilizes Gozio's
                    indoor-wayfinding only, “Outdoor” utilizes third-party
                    navigation only, and “Mixed” uses a combination of Gozio
                    indoor-wayfinding and third-party navigation. This report
                    shows the number of sessions that fall within each
                    Navigation Event type.
                  </Box>
                }
              />
            </Box>
          </Box>
        }
        handleBack={() => backToOverview()}
        handleBackSx={styles.handleBack}
        handleBackTitle="Back to Analytics Overview"
        headerButtons={
          <AnalyticsFilters
            value={filter}
            start={rangeStart}
            end={rangeEnd}
            onChange={async ({ start, end, value, dateRangeText }) => {
              if (dateRangeKey !== value || value === 'custom') {
                const diffDays = dayjs(end).diff(dayjs(start), 'day');
                setDateRangeKey(dateRangeText ?? value);
                if (value === 'custom') {
                  setDateRangeText(
                    `${dayjs(start).format(DATE_FORMAT)} - ${dayjs(end).format(
                      DATE_FORMAT,
                    )}`,
                  );
                } else {
                  setDateRangeText(null);
                }
                await fetch({
                  start,
                  end,
                  points: diffDays < 14 ? diffDays : 14,
                });
              }
            }}
          />
        }
      >
        <Box sx={styles.content}>
          <Grid sx={styles.takeoverTabs}>
            <Tabs
              value={currentTab}
              onChange={(e, idx) => setCurrentTab(idx)}
              aria-label="simple tabs"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Positioning" {...tabA11yProps('analytics', 0)} />
              <Tab label="By Location" {...tabA11yProps('analytics', 1)} />
            </Tabs>
          </Grid>
          <TabPanel
            value={currentTab}
            index={0}
            sx={{
              ...styles.tabPanel,
              ...currentTab === 0 && { height: 'calc(100% - 69px)' },
            }}
          >
            <Box sx={styles.tabBody}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">Navigation Events</Typography>
                <AnalyticsDownloadButton
                  endpoint="navigationevents"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!chartData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {chartData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  <Grid item>
                    <Typography variant="h3" sx={styles.count}>
                      {data.totalSessions?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Typography variant="body2" sx={styles.dateRange}>
                          {dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: '40px', marginBottom: '10px' }}>
                    <Typography variant="h3" sx={styles.count}>
                      {data.totalMixedSessions?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Box
                          sx={{
                            ...styles.dot,
                            backgroundColor: ColorPalette.chart.blue,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <Typography variant="body2">Mixed</Typography>
                          </Grid>
                          <Grid item>
                            <InfoTooltip
                              title={
                                <Box>
                                  Mixed Navigation Events use a combination of
                                  Gozio indoor-wayfinding and third-party
                                  navigation.
                                </Box>
                              }
                              sx={{
                                marginTop: '-2px',
                                marginLeft: '4px',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: '40px', marginBottom: '10px' }}>
                    <Typography variant="h3" sx={styles.count}>
                      {data.totalOutdoorsSessions?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Box
                          sx={{
                            ...styles.dot,
                            backgroundColor: ColorPalette.chart.green,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <Typography variant="body2">Outdoor</Typography>
                          </Grid>
                          <Grid item>
                            <InfoTooltip
                              title={
                                <Box>
                                  Outdoor Navigation Events utilize third-party
                                  navigation only.
                                </Box>
                              }
                              sx={{
                                marginTop: '-2px',
                                marginLeft: '4px',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: '40px', marginBottom: '10px' }}>
                    <Typography variant="h3" sx={styles.count}>
                      {data.totalIndoorsSessions?.toLocaleString()}
                    </Typography>
                    <Grid container sx={{ marginTop: '8px' }}>
                      <Grid item>
                        <Box
                          sx={{
                            ...styles.dot,
                            backgroundColor: ColorPalette.chart.yellow,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <Typography variant="body2">Indoor</Typography>
                          </Grid>
                          <Grid item>
                            <InfoTooltip
                              title={
                                <Box>
                                  Indoor Navigation Events utilize Gozio's
                                  indoor way-finding only.
                                </Box>
                              }
                              sx={{
                                marginTop: '-2px',
                                marginLeft: '4px',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AreaChart
                      data={chartData}
                      dataKey="totalSessions"
                      xAxisKey="date"
                      yAxisLabel="Number of Sessions"
                      yAxisKey="totalSessions"
                      width={width - 370}
                      height={370}
                      maxXAxisLabels={14}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip />}
                      linearData={[
                        {
                          dataKey: 'mixedSessions',
                          color: ColorPalette.chart.blue,
                        },
                        {
                          dataKey: 'outdoorsSessions',
                          color: ColorPalette.chart.green,
                        },
                        {
                          dataKey: 'indoorsSessions',
                          color: ColorPalette.chart.yellow,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Date
                  </Grid>
                </Grid>
              )}
            </Box>
          </TabPanel>
          <TabPanel
            value={currentTab}
            index={1}
            sx={{
              ...styles.tabPanel,
              ...currentTab === 1 && { height: 'calc(100% - 69px)' },
            }}
          >
            <Box sx={styles.tabBody}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                  zIndex: 10,
                }}
              >
                <AnalyticsDownloadButton
                  endpoint="navigationevents"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {filteredDestinations && (
                <GozioTable
                  name={NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME}
                  columns={columns}
                  loading={!data}
                  countTitleText={
                    searchParam || filters?.length > 0
                      ? 'Result'
                      : 'Navigation Destination'
                  }
                  data={filteredDestinations}
                  currentPage={tableParams.pageIndex}
                  rowsPerPage={tableParams.pageSize}
                  sortBy={[
                    {
                      ...tableParams.sortBy,
                      clearSort: searchParam.length > 0,
                    },
                  ]}
                  onSearchChanged={(searchValue) => {
                    setSearchParam(searchValue);
                  }}
                  onFiltersChanged={(updatedFilters, globalFilter) => {
                    setTableParams({
                      ...tableParams,
                      pageIndex: 0,
                    });
                    setFilters(updatedFilters);
                    setSearchParam(globalFilter);
                  }}
                  emptyContent={
                    searchParam || filters?.length > 0 ? (
                      <EmptyTable />
                    ) : (
                      <EmptyPanel title="There are No POIs for This Site" />
                    )
                  }
                  filterable={true}
                  customFilter={true}
                  sx={{
                    marginTop: '-54px',
                    marginLeft: '-24px',
                  }}
                />
              )}
            </Box>
          </TabPanel>
        </Box>
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(NavigationEventsPage);
