export const PUBLISHING_STATE_MAP = {
	ENABLED: 'enabled',
	ADMIN: 'gozioAdmin',
	DISABLED: 'disabled',
};

export const PACKAGE_STATE_MAP = {
	errored: 'Failed',
	packaged: 'Published',
	packaging: 'Publishing',
	reverted: 'Reverted',
};

export const convertPackageEvent = (event) => {
	if (PACKAGE_STATE_MAP.hasOwnProperty(event)) {
		return PACKAGE_STATE_MAP[event];
	}

	return 'Unknown';
};

export const convertNetworkChannels = (networkChannels = []) => networkChannels
		.map((networkChannel) => networkChannel?.label || null)
		.filter((l) => l)
		.join(', ');
