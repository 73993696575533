import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getFormLabel } from 'helpers/form-util';
import htmlToDraft from 'html-to-draftjs';
import MUIRichTextEditor from 'mui-rte';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
    position: 'relative',
  },
  label: {
    lineHeight: 1.25,
    color: theme.palette.grey[500],
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.38px',
    background: theme.palette.white,
    left: '9px',
    padding: '4px 4px 4px 7px',
    position: 'absolute',
    top: '-11px',
  },
  focusedLabel: {
    color: theme.palette.grey[600],
  },
}));

const parseFromHTML = (value) => {
  if (!value) return value;
  if (value[0] !== '<') value = `<div>${value}</div>`; // If the initial value isn't wrapped in a div, wrap it
  const blocksFromHTML = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return JSON.stringify(convertToRaw(contentState));
};

const parseToHtml = (value) => {
  const rawContentState = JSON.parse(value);
  return draftToHtml(rawContentState);
};

const RichTextField = ({ name, label, required, isFormField }) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);
  const richTextRef = useRef(null);
  const showLabel = (value) => {
    if (isFocus) {
      return true;
    }

    if (typeof value === 'string') {
      try {
        const json = JSON.parse(value);
        return (
          json.blocks
          && (json.blocks.length > 1
            || (json.blocks.length === 1 && json.blocks[0].text.length !== 0)) // fixes when we backspace all data from element
        );
      } catch (err) {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isFocus) {
      richTextRef.current.focus();
    }
  }, [isFocus]);

  const MUIRichTextAdapter = ({
    input: { onChange, value },
    label,
    ...rest
  }) => (
    <Box className={classes.root}>
      {showLabel(value) && (
        <Box className={clsx(classes.label, isFocus && classes.focusedLabel)}>
          {getFormLabel(label, required)}
        </Box>
      )}
      <MUIRichTextEditor
        id="mui"
        ref={richTextRef}
        label={getFormLabel(label, required)}
        defaultValue={value}
        onSave={(data) => {
          onChange && onChange(data);
        }}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          // this check here is for any popovers that open on control push like the 'link' one. Blur is fired when we open
          // a popover. We don't want to redraw or save until we come out of the field itself, not because of popover
          const popoverCount = (
            document.getElementsByClassName('MuiPopover-root') || []
          ).length;
          if (popoverCount === 0) {
            setIsFocus(false);
            richTextRef.current?.save();
          }
        }}
        controls={[
          'undo',
          'redo',
          'bold',
          'italic',
          'underline',
          'numberList',
          'bulletList',
          'link',
          'clear',
        ]}
        {...rest}
      />
    </Box>
  );

  if (isFormField) {
    return (
      <Field
        name={name}
        label={label}
        component={MUIRichTextAdapter}
        format={parseFromHTML}
        parse={parseToHtml}
      />
    );
  }

  return <MUIRichTextAdapter label={label} input={{ onChange: () => {} }} />;
};

RichTextField.propTypes = {
  isFormField: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

RichTextField.defaultProps = {
  isFormField: true,
  required: false,
};

export default React.memo(RichTextField);
