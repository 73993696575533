import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClickHandIcon from 'assets/click-hand.png';
import ColorPalette from 'pages/gozio_colors';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  previewPane: {
    height: '503px',
    width: '876px',
    padding: '12px',
    backgroundColor: theme.palette.grey[50],
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
  },
  previewInner: {
    width: '100%',
    height: '100%',
    border: '3px solid #FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    color: theme.palette.white,
  },
  logoPreview: {
    width: '191px',
    height: '191px',
    marginTop: '16px',
  },
  imagePlaceholderWrapper: {
    width: '191px',
    height: '191px',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
    '& svg': {
      color: theme.palette.grey[600],
    },
  },
  clickHand: {
    marginTop: '40px',
    height: '59px',
    width: '41px',
  },
  cta: {
    marginTop: '77px',
    textAlign: 'center',
    width: '240px',
    height: '96px',
    overflow: 'hidden',
    '& p': {
      padding: 0,
      margin: 0,
    },
  },
  ctaText: {
    fontSize: '1.125rem',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  poweredBy: {
    position: 'absolute',
    bottom: '29px',
    right: '36px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyright: {
    fontSize: '5.5px',
    lineHeight: '10px',
    fontWeight: 700,
  },
});

const ScreensaverPreview = ({ color, url, cta }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ImagePreview = ({ url }) => {
    if (url) {
      return (
        <Box component="img" src={url} sx={styles.logoPreview} alt="Screensaver Logo" />
      );
    }
    return (
      <Box sx={styles.imagePlaceholderWrapper}>
        <AddPhotoAlternateIcon />
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: '16px', width: '876px', padding: '0 16px' }}
      >
        Kiosk Preview
      </Typography>
      <Box sx={styles.previewPane}>
        <Box
          sx={{
            ...styles.previewInner,
            backgroundColor: color || ColorPalette.grey[100],
          }}
        >
          <ImagePreview url={url} />
          <Box
            component="img"
            src={ClickHandIcon}
            sx={styles.clickHand}
            alt="Click Hand"
          />
          <Box sx={styles.cta}>
            <Typography
              variant="body1"
              sx={styles.ctaText}
              dangerouslySetInnerHTML={{
                __html: cta || '',
              }}
            />
          </Box>
          <Box sx={styles.poweredBy}>
            <Typography variant="caption">powered by GOZIO</Typography>
            <Typography variant="caption" sx={styles.copyright}>
              &reg;
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ScreensaverPreview.propTypes = {};

ScreensaverPreview.defaultProps = {};

export default React.memo(ScreensaverPreview);
