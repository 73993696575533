import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CategorySelection from 'components/categories/categorySelection';
import FormSubSection from 'components/forms/formSubSection';
import TagsMain from 'components/networkPlace/tags/tagsMain';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import { capitalize } from 'helpers/lang-util';
import { useCategories } from 'hooks/dataHooks';
import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
  priorityText: {
    color: theme.palette.grey[600],
    marginTop: '30px',
    marginBottom: '8px',
  },
}));

const Searchability = ({ form, values }) => {
  const classes = useStyles();
  const { categories: categoriesData } = useCategories();

  useField('searchability');
  useField('excludeCategoryTags');

  const handleSearchabilityChange = (v) => {
    form.mutators.setValue('searchability', v);
  };

  const locationDesignation = useMemo(
    () => values?.designation === 'poi' ? 'POI' : capitalize(values.designation),
    [values],
  );

  const searchabilityOptions = useMemo(() => {
    const options = [
      { label: 'Always', value: 'always' },
      { label: 'Onsite', value: 'onsite' },
      { label: 'Never', value: 'never' },
    ];
    if (!values?.isMapped) {
      options.splice(1, 1);
    }
    return options;
  }, [values]);

  const handleSaveTags = (excludedTags, includedTags) => {
    let excludeCategoryTags = [].concat(values.excludeCategoryTags);

    for (const tag of includedTags) {
      if (typeof tag !== 'string') continue;
      excludeCategoryTags = excludeCategoryTags.filter((t) => t !== tag);
    }
    for (const tag of excludedTags) {
      if (typeof tag !== 'string') continue;
      if (!excludeCategoryTags.includes(tag)) excludeCategoryTags.push(tag);
    }
    form.mutators.setValue('excludeCategoryTags', excludeCategoryTags);
  };

  return (
    <>
      <FormSubSection name="Searchability">
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.text}>
            Do you want this {locationDesignation} to be searchable?
          </Typography>
          <GozioRadioGroup
            onChange={handleSearchabilityChange}
            options={searchabilityOptions}
            groupName="searchability"
            value={values?.searchability ?? 'always'}
          />
        </Grid>
      </FormSubSection>
      <FormSubSection
        name="Categories &amp; Associated Tags"
        sx={{
          position: 'relative',

          '& .MuiTypography-subtitle1': { marginBottom: 0 },
        }}
      >
        <Field
          name="categories"
          component={CategorySelection}
          sourceName={values?.designation}
          categoriesData={categoriesData}
          locationCategories={values.categories || []}
          excludeCategoryTags={values.excludeCategoryTags || []}
          handleSaveTags={handleSaveTags}
          detailed
        />
      </FormSubSection>
      <FormSubSection name="Add Additional Tags">
        <TagsMain />
      </FormSubSection>
    </>
  );
};

export default React.memo(Searchability);
