import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import InfoPanel from 'components/infoPanel/infoPanel';
import GozioTable from 'components/tables/gozioTable';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import CategoryTagTableEditModal from './categoryTagTableEditModal';

const COLUMNS = [
  {
    Header: 'Category Name',
    accessor: 'name',
    minWidth: 200,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    disableSortBy: true,
    minWidth: 500,
    cellStyle: {
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    alwaysShown: true,
    disableSortBy: true,
    justifyRight: true,
    sticky: 'right',
  },
];

const CategoryTagTable = ({
                            data,
                            deleteCategory,
                            excludeCategoryTags,
                            handleSaveTags,
                            locationCategories,
                          }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categoryChildren = useMemo(
    () => data.reduce((a, b) => a.concat(b.children), []),
    [data],
  );
  const filteredCategoryChildren = categoryChildren
    .filter((c) => locationCategories.filter((l) => l.id === c.id).length > 0)
    .map((c) => ({
      id: c.id,
      name: getLabelByLang(c.name),
      tags:
        c.tags?.map((t) => ({
          id: t.id,
          name: capitalize(getLabelByLang(t.name)),
          checked: !excludeCategoryTags.includes(t.id),
        })) || [],
    }));

  const allTags = useMemo(
    () => filteredCategoryChildren.reduce((a, b) => a.concat(b.tags || []), []),
    [filteredCategoryChildren],
  );

  const tags = useMemo(
    () => allTags
        .filter((t) => !excludeCategoryTags.includes(t.id))
        .map((t) => t.name.toLowerCase()),
    [allTags, excludeCategoryTags],
  );

  const duplicates = useMemo(
    () => tags.reduce((a, b) => {
        if (tags.indexOf(b) < tags.lastIndexOf(b)) return a.concat(b);
        return a;
      }, []),
    [tags],
  );

  const createTagActions = (category) => (
    <Box className="hoverUnhide">
      <Tooltip title="Edit">
        <Edit
          onClick={() => {
            setShowModal(true);
            setSelectedCategory(category);
          }}
          sx={{
            marginRight: '8px',
          }}
        />
      </Tooltip>
      <Tooltip title="Delete">
        <Delete onClick={() => deleteCategory(category.id)} />
      </Tooltip>
    </Box>
  );

  const categoryTags = filteredCategoryChildren.map((c) => {
    const tagString = c.tags
      .filter((t) => t.checked)
      .map((t) => capitalize(t.name))
      .join(', ');
    return {
      name: c.name,
      tags: tagString.length ? (
        <Box>
          <Tooltip title={tagString}>
            <span>{tagString}</span>
          </Tooltip>
        </Box>
      )
        : '',
      actions: createTagActions(c),
    };
  });

  const tagTable = useMemo(
    () => (
      <>
        {duplicates.length > 0 ? (
          <InfoPanel
            title={'Duplicate Tag names are applied to this location.'}
            sx={{ marginTop: '8px', width: '370px' }}
          />
        ) : null}
        <GozioTable
          columns={COLUMNS}
          data={categoryTags}
          hidePagination={true}
          sx={{
            marginTop: '32px',
            maxHeight: '500px',
            padding: 0,
          }}
        />
        {showModal && (
          <CategoryTagTableEditModal
            confirmText="Apply"
            excludeCategoryTags={excludeCategoryTags}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCategory={selectedCategory}
            handleClose={() => {
              setShowModal(false);
              setSelectedCategory(null);
            }}
            handleSave={handleSaveTags}
          />
        )}
      </>
    ),
    [
      categoryTags,
      duplicates,
      excludeCategoryTags,
      handleSaveTags,
      selectedCategory,
      showModal,
    ],
  );

  return tagTable;
};

CategoryTagTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  deleteCategory: PropTypes.func,
  excludeCategoryTags: PropTypes.arrayOf(PropTypes.string),
  handleSaveTags: PropTypes.func,
  locationCategories: PropTypes.arrayOf(PropTypes.object),
};

CategoryTagTable.defaultProps = {};

export default React.memo(CategoryTagTable);
