import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_KIOSK = gql`
  ${fragments.KIOSK_ATTRIBUTES}
  mutation createKiosk($input: KioskInputCreate!) {
    createKiosk(input: $input) {
      ...kioskAttributes
    }
  }
`;

export const DELETE_KIOSK = gql`
  mutation deleteKiosk($id: ID!) {
    deleteKiosk(id: $id) {
      id
    }
  }
`;

export const FIND_KIOSK = gql`
  ${fragments.KIOSK_ATTRIBUTES}
  query {
    findKiosk {
      count
      edges {
        node {
          ...kioskAttributes
        }
      }
    }
  }
`;

export const FIND_KIOSK_COUNT = gql`
  query findKiosk($where: KioskInputWhere) {
    findKiosk(where: $where) {
      count
    }
  }
`;

export const FIND_KIOSK_TEMPLATE = gql`
  ${fragments.KIOSK_TEMPLATE_ATTRIBUTES}
  query {
    findKioskTemplate {
      edges {
        node {
          ...kioskTemplateAttributes
        }
      }
    }
  }
`;

export const GET_KIOSK = gql`
  ${fragments.KIOSK_ATTRIBUTES}
  query getKiosk($id: ID!) {
    getKiosk(id: $id) {
      ...kioskAttributes
    }
  }
`;

export const UPDATE_KIOSK = gql`
  ${fragments.KIOSK_ATTRIBUTES}
  mutation updateKiosk($id: ID!, $input: KioskInputUpdate) {
    updateKiosk(id: $id, input: $input) {
      ...kioskAttributes
    }
  }
`;

export const UPDATE_KIOSK_DIRECTORY = gql`
  mutation updateKiosk($id: ID!, $input: KioskInputUpdate) {
    updateKiosk(id: $id, input: $input) {
      id
      directory {
        progress
        tiles {
          assignedId
          name
          type
          progress
          order
          icon {
            id
            url (protocol: secure)
          }
          backgroundColor
          ... on KioskDirectoryTileFreeForm {
            title
            description
          }
          ... on KioskDirectoryTilePOIGroup {
            poiItems {
              poi {
                id
              }
              order
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_KIOSK_TEMPLATE = gql`
  ${fragments.KIOSK_TEMPLATE_ATTRIBUTES}
  mutation updateKioskTemplate($id: ID!, $input: KioskTemplateInputUpdate) {
    updateKioskTemplate(id: $id, input: $input) {
      ...kioskTemplateAttributes
    }
  }
`;
