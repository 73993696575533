import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import LinkDestination from 'components/forms/linkDestination';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import LabeledCheckbox from 'components/labeledCheckbox/labeledCheckbox';
import TextField from 'components/textField/textField';
import { getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { useUpdateExplorePanelAction, useUpdateSystemExplorePanelAction } from 'hooks/dataHooks/useExplorePanel';
import useToast from 'hooks/useToast';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  buildExplorePanelQuicklinkSchema,
  buildQuicklinkFromValues,
} from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      height: '100%',
      overflowX: 'hidden',
    },
  },
  content: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  fieldArea: {
    marginTop: theme.spacing(3.25),
  },
  formFields: {
    marginTop: theme.spacing(3.25),
  },
  field: {
    marginBottom: theme.spacing(4),
  },
  fieldSection: {
    marginBottom: theme.spacing(4),
  },
}));

export const I18N_FIELDS = [
  {
    key: 'name',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'nameEn',
    label: 'Quicklink Name',
    required: true,
    charLimit: 18,
  },
  {
    key: 'name',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'nameEs',
    label: 'Quicklink Name (Spanish)',
    required: false,
    charLimit: 18,
  },
];

const buildInitialValues = (data, languages) => {
  const values = {
    filterLinkDestBySite: data.filterLinkDestBySite,
    langES: hasSpanish(languages),
    iconUrl: data?.icon?.url || null,
    linkType: data?.type,
    nameEn: getLabelByLang(data?.name),
    nameEs: getLabelByLang(data?.name, LANGUAGE_CODES.SPANISH),
    type: data?.type,
  };
  switch (data.type) {
    case 'category':
      values.categories = data.categories || [];
      values.listDest = '';
      values.custom = '';
      break;
    case 'list':
      values.categories = [];
      values.listDest = data.list?.id || '';
      values.custom = '';
      break;
    case 'custom':
      values.categories = [];
      values.listDest = '';
      values.custom = data.custom || '';
      break;
    default:
      break;
  }

  return values;
};

const EditExplorePanelPanel = ({
                                 data,
                                 languages,
                                 onEditCardCancelled,
                                 onEditCardChanged,
                                 onEditCardSaved,
                                 panelId,
                                 panelType,
                                 title,
                               }) => {
  const classes = useStyles();
  const validate = makeValidate(buildExplorePanelQuicklinkSchema(languages));

  const [initialValues, setInitialValues] = useState({});
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { networkId } = useParams();
  const { toastNotificationErrorHook, toastNotificationSuccessHook }
    = useToast();
  const [updateExplorePanelAction] = useUpdateExplorePanelAction(
    networkId,
    title,
  );
  const [updateSystemExplorePanelAction] = useUpdateSystemExplorePanelAction();

  const isGlobal = isGlobalTemplate(networkId);

  const supportedLinkTypes = ['category', ...isGlobal ? [] : ['list', 'custom']];

  useEffect(() => {
    if (data && Object.keys(initialValues).length === 0) {
      setInitialValues(buildInitialValues(data, languages));
    }
  }, [data, initialValues, languages]);

  const onSubmit = async (values = {}) => {
    setIsSubmitting(true);
    const updatedData = buildQuicklinkFromValues(data, values, true);

    if (isGlobal) {
      delete updatedData.custom;
    }

    if (uploadedIcon?.file) {
      const iconImage = await uploadImage(
        uploadedIcon.file,
        toastNotificationErrorHook,
      );
      updatedData.icon = iconImage?.id;
    } else {
      updatedData.icon = data?.icon?.image?.id;
    }

    const updateFunction = isGlobal
      ? updateSystemExplorePanelAction
      : updateExplorePanelAction;
    const result = await updateFunction({
      variables: {
        panelId: panelId,
        actionId: data.id,
        input: updatedData,
      },
    });

    if (result.data) {
      toastNotificationSuccessHook(
        <>
          Your progress on the Explore Panel Quicklink{' '}
          <strong style={{ marginLeft: '4px', marginRight: '4px' }}>
            {values.nameEn}
          </strong>{' '}
          has been saved.
        </>,
      );
      setUploadedIcon(null);
      onEditCardSaved(
        values,
        result.data.replaceNetworkExplorePanelAction
        || result.data.replaceSystemExplorePanelAction,
      );
      setInitialValues({});
    }
    setIsSubmitting(false);
  };

  const buildUpdatedQuicklinkData = (form, iconUrl = null) => {
    const icon = iconUrl
      ? { url: iconUrl }
      : uploadedIcon
        ? { url: uploadedIcon.iconUrl }
        : data.icon;
    return {
      ...buildQuicklinkFromValues(data, form.getState().values),
      icon,
    };
  };

  const canShowFilter = (values) => panelType === 'site' && values.linkType === 'category';

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({
          handleSubmit,
          form,
          values,
          submitting,
          pristine,
          invalid,
          errors,
        }) => (
        <form
          className={classes.root}
          onSubmit={handleSubmit}
          onChange={() => onEditCardChanged(buildUpdatedQuicklinkData(form))}
        >
          <FormWatcher
            formRenderProps={{ form }}
            errorFormFieldMap={
              new Map([
                [
                  'nameEn',
                  {
                    label: 'Quicklink Name',
                    section: 'General',
                  },
                ],
                [
                  'nameEs',
                  {
                    label: 'Quicklink Name (Spanish)',
                    section: 'General',
                  },
                ],
              ])
            }
            isClosing={isClosing}
            isSubmitting={isSubmitting}
            onClose={() => {
              setIsClosing(false);
              setIsSubmitting(false);
            }}
            onClosed={() => {
              setIsClosing(false);
              setIsSubmitting(false);
              onEditCardCancelled();
            }}
            onCorrect={() => setIsClosing(false)}
            onContinue={onEditCardCancelled}
          />

          <GozioDrawer
            open={true}
            disableCancel={true}
            greyOutConfirm={pristine || submitting || invalid}
            onClose={() => setIsClosing(true)}
            onConfirm={() => form.submit()}
            title={title}
            top={70}
            width={568}
          >
            <Box className={classes.content}>
              <Box className={classes.fieldArea}>
                <Grid item xs>
                  <Typography variant="subtitle1">General</Typography>
                </Grid>
                <Grid item xs className={classes.formFields}>
                  {I18N_FIELDS.map(
                    ({ langKey, lang, label, charLimit, required }) => {
                      if (!languages.includes(lang)) {
                        return null;
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          className={classes.field}
                          key={`field_${langKey}`}
                        >
                          <Field name={langKey}>
                            {({ input, meta }) => (
                              <TextField
                                label={
                                  required ? (
                                    <span>
                                      <Asterisk /> {label}
                                    </span>
                                  )
                                    : label

                                }
                                charLimit={charLimit}
                                helperText={meta.touched ? meta.error : ''}
                                error={meta.touched && !!meta.error}
                                {...input}
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>
                      );
                    },
                  ).filter((l) => l)}
                </Grid>
              </Box>
              <Box className={classes.fieldSection}>
                <Grid item xs>
                  <Typography variant="subtitle1">Display</Typography>
                </Grid>
                <Grid item xs sx={{ marginTop: '16px' }}>
                  <Field name="iconUrl">
                    {({ input: { onChange, value } }) => (
                      <ImageUpLoader
                        title={
                          <span>
                            <Asterisk /> Supporting Icon
                          </span>
                        }
                        image={value ? { url: value } : null}
                        fileInfo="Supported files includes jpeg and png. File size should be no larger than 50KB. Image dimensions must be at least 120px by 120px."
                        onImageUpdated={({ file, url }) => {
                          setUploadedIcon({ file, iconUrl: url });
                          onChange(url);
                          onEditCardChanged(
                            buildUpdatedQuicklinkData(form, url),
                          );
                        }}
                        maxFileSize={50000}
                        minHeight={120}
                        minWidth={120}
                        activeDragPadding={12}
                        allowDelete={false}
                        showImage={true}
                        previewWidth={64}
                        previewHeight={64}
                      />
                    )}
                  </Field>
                </Grid>
              </Box>
              <Box className={classes.fieldSection}>
                <LinkDestination
                  form={form}
                  hasCustomLink={!!initialValues.custom}
                  initialValues={initialValues}
                  supportedLinkTypes={supportedLinkTypes}
                />
              </Box>
              {canShowFilter(values) && (
                <Box className={classes.fieldSection}>
                  <Grid item xs>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          Link Preferences
                        </Typography>
                      </Grid>
                      <Grid item>
                        <InfoTooltip
                          sx={{ marginTop: '2px' }}
                          title={
                            'Selecting “Filter Quicklink by Site” ensures that all locations referenced will be sorted by travel time, rather than by distance, and will filter locations that are accessible while on site.'
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sx={{ marginTop: '16px' }}>
                    <Field name="filterLinkDestBySite">
                      {({ input: { onChange, value } }) => (
                        <LabeledCheckbox
                          checked={value ?? false}
                          label="Filter Quicklink by Site"
                          onChange={() => onChange(!value)}
                        />
                      )}
                    </Field>
                  </Grid>
                </Box>
              )}
            </Box>
          </GozioDrawer>
        </form>
      )}
    </Form>
  );
};

EditExplorePanelPanel.propTypes = {
  panelId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEditCardCancelled: PropTypes.func.isRequired,
  onEditCardChanged: PropTypes.func,
  onEditCardSaved: PropTypes.func.isRequired,
  panelType: PropTypes.string,
  title: PropTypes.string.isRequired,
};

EditExplorePanelPanel.defaultProps = {
  onEditCardChanged: () => {},
  panelType: null,
};

export default EditExplorePanelPanel;
