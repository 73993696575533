// noinspection JSValidateTypes

import { Info } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    minHeight: '134px',
    maxHeight: '159px',
    overflow: 'hidden',
    padding: '16px 16px 12px 16px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  text: {
    display: '-webkit-box',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  subtext: {
    display: '-webkit-box',
    fontSize: '14px',
    fontWeight: 'normal',
    marginTop: '8px',
    marginBottom: '24px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  buttonText: {
    display: '-webkit-box',
    marginTop: 'auto',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    width: 'fit-content',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    '& img, svg': {
      width: '48px',
      height: '48px',
    },
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-11px',
  },
});

export const MyFavoritesEmptyStateCard = ({
                                            backgroundColor,
                                            buttonBackgroundColor,
                                            buttonText,
                                            buttonTextColor,
                                            clickHandler,
                                            disabled,
                                            iconUrl,
                                            incompleteForPackaging,
                                            selected,
                                            subtitle,
                                            textColor,
                                            title,
                                          }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const incompleteWarningContent = useMemo(
    () => (
      <Box
        sx={{
          position: 'fixed',
          width,
        }}
      >
        <Box sx={{ ...styles.incompleteWarning, left: `${width - 54}px` }}>
          <Info />
        </Box>
      </Box>
    ),
    [styles, width],
  );

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && incompleteWarningContent}
      <Grid item xs={8} sx={styles.textContainer}>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.text, color: textColor }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ ...styles.subtext, color: textColor }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            ...styles.buttonText,
            backgroundColor: buttonBackgroundColor,
            color: buttonTextColor,
          }}
        >
          {buttonText}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={styles.iconContainer}>
        <Box sx={{ ...styles.icon, background: backgroundColor }}>
          {iconUrl && <img src={iconUrl} alt="Empty State icon" />}
          {!iconUrl && <AddPhotoAlternateIcon />}
        </Box>
      </Grid>
    </Grid>
  );
};

MyFavoritesEmptyStateCard.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  iconUrl: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  selected: PropTypes.bool,
};

MyFavoritesEmptyStateCard.defaultProps = {
  buttonText: '',
  disabled: false,
  incompleteForPackaging: false,
  selected: false,
  title: 'Card Title',
};

export default MyFavoritesEmptyStateCard;
