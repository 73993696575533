import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_CATEGORY = gql`
  ${fragments.CATEGORY_ATTRIBUTES}
  mutation createCategory($input: CategoryInputCreate!) {
    createCategory(input: $input) {
      ...categoryAttributes
    }
  }
`;

export const GET_CATEGORY = gql`
  ${fragments.CATEGORY_ATTRIBUTES}
  query getCategory($id: ID!) {
    getCategory(id: $id) {
      ...categoryAttributes
    }
  }
`;

export const GET_CATEGORY_GROUP = gql`
  ${fragments.CATEGORY_GROUP_ATTRIBUTES}
  query getCategory($id: ID!) {
    getCategory(id: $id) {
      ...categoryGroupAttributes
    }
  }
`;

export const FIND_PRIMARY_CATEGORIES = gql`
  query findPrimaryCategories {
    findPrimaryCategories {
      id
      name
      tags {
        id
        name
      }
      children {
        edges {
          node {
            parent {
              id
              name
            }
            id
            name
            tags {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FIND_NETWORK_PLACE_BY_CATEGORY = gql`
  query findNetworkPlaceByCategory($ids: AutoGraphMixed!) {
    findCategory(where: {
      id: $ids
    }) {
      edges {
        node {
          id
          name
          places {
            edges {
              node {
                id
                designation
                name
                workspaces {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REPLACE_CATEGORY = gql`
  ${fragments.CATEGORY_ATTRIBUTES}
  mutation replaceCategory($id: ID!, $input: CategoryInputIdentity!) {
    replaceCategory(id: $id, input: $input) {
      ...categoryAttributes
    }
  }
`;

export const REPLACE_CATEGORY_GROUP = gql`
  ${fragments.CATEGORY_GROUP_ATTRIBUTES}
  mutation replaceCategory($id: ID!, $input: CategoryInputIdentity!) {
    replaceCategory(id: $id, input: $input) {
      ...categoryGroupAttributes
    }
  }
`;

export const SET_CATEGORY_TAGS = gql`
  mutation setCategoryTags($id: ID!, $tags: [TagInputIdentity]!) {
    setCategoryTags(id: $id, tags: $tags) {
      id
      name
      tags {
        id
        name
        weight
      }
    }
  }
`;
