import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';

const TypeHeadStyles = (theme) => ({
  stepContent: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '540px',
  },
  stepper: {
    backgroundColor: 'initial',
    float: 'left',
    padding: 0,
    width: '100px',
  },
  title: {
    marginBottom: '50px',
  },
  content: {
    height: 'calc(100vh - 216px)',
    backgroundColor: ColorPalette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(ColorPalette.grey[600], 0.4)}`,
    borderRadius: '20px',
  },
  drawer: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '240px',
  },
  drawerPaper: {
    width: '240px',
  },
  dropDown: {
    marginBottom: '20px',
  },
  emptyContainer: {
    height: 'calc(100vh - 264px)',
    width: '100%',
    padding: '0 24px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default TypeHeadStyles;
