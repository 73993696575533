import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from 'components/dashboard/cardComponent/card';
import {
  dndHover,
  isCardIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  card: {
    marginBottom: '4px',
    transform: 'translate3d(0, 0, 0)',
    maxHeight: '288px',
  },
  card3Container: {
    marginTop: theme.spacing(1),
  },
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const ThreeCardLayout = ({
                           id,
                           index,
                           card1,
                           card2,
                           card3,
                           size,
                           preview,
                           hovered,
                           moveCard,
                           onCardClick,
                           onDrag,
                           onHover,
                           canDragAndDrop,
                         }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
        card2,
        card3,
        size,
      },
      cardType: ThreeCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? '3-card layout' : ''}>
      <Grid
        container
        direction="row"
        spacing={1}
        ref={ref}
        sx={{
          ...styles.card,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs={6}>
          <Card
            size="extraLarge"
            title={card1.title}
            subtitle={card1.subtitle}
            overline={card1.overline}
            iconUrl={card1.iconUrl}
            incompleteForPackaging={isCardIncompleteForPackaging(card1)}
            backgroundImageUrl={card1.backgroundImageUrl}
            backgroundColor={card1.backgroundColor}
            textColor={card1.textColor}
            clickHandler={() => onCardClick({
                cardGroupId: id,
                key: 'card1',
                size: 'extraLarge',
                ...card1,
              })
            }
            standalone={false}
            disabled={card1.disabled}
            selected={card1.selected}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Grid item xs>
            <Card
              size="medium"
              title={card2.title}
              subtitle={card2.subtitle}
              overline={card2.overline}
              iconUrl={card2.iconUrl}
              incompleteForPackaging={isCardIncompleteForPackaging(card2)}
              backgroundImageUrl={card2.backgroundImageUrl}
              backgroundColor={card2.backgroundColor}
              textColor={card2.textColor}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card2',
                  size: 'medium',
                  ...card2,
                })
              }
              standalone={false}
              disabled={card2.disabled}
              selected={card2.selected}
            />
          </Grid>
          <Grid item xs sx={styles.card3Container}>
            <Card
              size="small"
              title={card3.title}
              iconUrl={card3.iconUrl}
              incompleteForPackaging={isCardIncompleteForPackaging(card3)}
              backgroundImageUrl={card3.backgroundImageUrl}
              backgroundColor={card3.backgroundColor}
              textColor={card3.textColor}
              clickHandler={() => onCardClick({
                  cardGroupId: id,
                  key: 'card3',
                  size: 'small',
                  ...card3,
                })
              }
              standalone={false}
              disabled={card3.disabled}
              selected={card3.selected}
            />
          </Grid>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

ThreeCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card2: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  card3: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    backgroundImageUrl: PropTypes.string,
    clickHandler: PropTypes.func,
    iconUrl: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    overline: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
  size: PropTypes.string,
};

ThreeCardLayout.defaultProps = {
  preview: false,
  card1: {
    incompleteForPackaging: false,
    title: 'Card title 1',
    clickHandler: () => {},
  },
  card2: {
    incompleteForPackaging: false,
    title: 'Card title 2',
    clickHandler: () => {},
  },
  card3: {
    incompleteForPackaging: false,
    title: 'Card title 3',
    clickHandler: () => {},
  },
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default ThreeCardLayout;
