import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Asterisk from 'components/asterisk/asterisk';
import { Authorize, SCOPES } from 'components/authorization/authorize';
import Condition from 'components/forms/condition';
import GenericStepperModal from 'components/genericModal/genericStepperModal';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import TextField from 'components/textField/textField';
import { colorWithAlpha } from 'helpers/color-util';
import { makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const DESCRIPTION_MAX_LIMIT = 500;

const publishingSchema = Yup.object().shape({
  channels: Yup.array().of(Yup.string()).min(1),
  message: Yup.string()
    .min(1)
    .max(
      DESCRIPTION_MAX_LIMIT,
      `Description must be ${DESCRIPTION_MAX_LIMIT} characters or less.`,
    )
    .required('Description is required'),
  requireImmediateDownload: Yup.boolean(),
  purgePreviousDataPackage: Yup.boolean(),
});

const validate = makeValidate(publishingSchema);

const buildStyles = ({ theme }) => ({
  modal: {
    maxHeight: '472px',

    '& .MuiDialogContent-root': {
      height: '100%',
    },
  },
  modalContent: {
    marginTop: '-32px',
    textAlign: 'left',
  },
  subtitle: {
    marginBottom: '32px',
    textAlign: 'center',
  },
  charCount: {
    color: theme.palette.grey[400],
  },
  charCountError: {
    color: theme.palette.error.main,
  },

  checkboxRow: {
    height: '28px',
    marginBottom: '16px',
  },
  checkboxContainer: {
    marginBottom: 0,
    '& .MuiIconButton-label': {
      color: colorWithAlpha(theme.palette.black, 0.6),
    },
    '& .MuiFormControlLabel-label': {
      color: colorWithAlpha(theme.palette.black, 0.6),
    },
  },
  disabledCheckboxContainer: {
    '& .MuiIconButton-label': {
      color: colorWithAlpha(theme.palette.black, 0.38),
    },
    '& .MuiFormControlLabel-label': {
      color: colorWithAlpha(theme.palette.black, 0.38),
    },
  },
  checkbox: {
    color: colorWithAlpha(theme.palette.black, 0.54),
  },
  textArea: {
    width: '100%',
  },
  publishTextArea: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      marginTop: '16px',
      border: `1px solid ${colorWithAlpha(theme.palette.black, 0.32)}`,
      borderRadius: '2px',
    },
    '& textarea': {
      color: colorWithAlpha(theme.palette.black, 0.6),
    },
  },
  titleWithTooltip: {
    display: 'inline-block',
    marginTop: '24px',
    marginRight: '10px',
  },
  tooltip: {
    verticalAlign: 'middle',
  },
});

export const PublishModal = ({
                               handleCancel,
                               handleCompleted,
                               networkChannels,
                             }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [initialFormValues, setInitialFormValues] = useState({
    channels: [],
    message: '',
    requireImmediateDownload: false,
    purgePreviousDataPackage: false,
  });
  const publishPackage = async (values) => {
    const data = {
      channels: values.channels,
      message: values.message,
      level: 'publish',
      purge: values.requireImmediateDownload
        ? values.purgePreviousDataPackage
        : false,
      urgent: values.requireImmediateDownload,
    };
    handleCompleted(data);
  };

  const steps = useMemo(
    () => [
      {
        title: 'Define Publishing Description',
        requiredFields: ['message'],
        content: (
          <Box sx={styles.modalContent}>
            <Grid item xs>
              <Typography sx={styles.subtitle} variant="subtitle1">
                Tell Us a Bit About the Data That's Being Published.
              </Typography>
            </Grid>
            <Grid item xs>
              <Field name="message">
                {({ input: { value, onChange }, meta }) => (
                  <TextField
                    name="message"
                    fullWidth={true}
                    multiline
                    label={
                      <>
                        <Asterisk /> Description
                      </>
                    }
                    charLimit={DESCRIPTION_MAX_LIMIT}
                    minRows={6}
                    maxRows={6}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    onChange={(val) => {
                      if (val.length <= DESCRIPTION_MAX_LIMIT) {
                        onChange(val);
                      }
                    }}
                    placeholder=""
                    value={value}
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>
            <Authorize
              scope={[
                SCOPES.NETWORK_DASHBOARD.CAN_REQUIRE_IMMEDIATE_DOWNLOAD,
                SCOPES.NETWORK_DASHBOARD.CAN_PURGE_PREVIOUS_DATA_PACKAGES,
              ]}
            >
              <Grid item xs>
                <Typography variant="body1" sx={styles.titleWithTooltip}>
                  Define Application Update Preferences
                </Typography>
                <InfoTooltip
                  title="Utilizing Application Update Preferences ensures data is fetched immediately upon publishing rather than waiting for the regular update cycle. Users must download the new data before reaccessing the app when both options are enabled. Please note that selecting only the first option won't prevent users from using the app with existing data."
                  sx={styles.tooltip}
                />
              </Grid>
              <Grid item xs sx={{ marginTop: '3px' }}>
                <Field name="requireImmediateDownload" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          sx={{ padding: '4px 4px 4px 8px' }}
                          {...input}
                        />
                      }
                      label="Require Immediate Download For Devices"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs sx={{ marginTop: '4px', marginBottom: '24px' }}>
                <Condition when="requireImmediateDownload" is={true}>
                  <Field name="purgePreviousDataPackage" type="checkbox">
                    {({ input, meta }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            sx={{ padding: '4px 4px 4px 8px' }}
                            {...input}
                          />
                        }
                        label="Purge Previous Data Package On Devices"
                      />
                    )}
                  </Field>
                </Condition>
                <Condition when="requireImmediateDownload" is={false}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        sx={{ padding: '4px 4px 4px 8px' }}
                        disabled={true}
                      />
                    }
                    label="Purge Previous Data Package On Devices"
                  />
                </Condition>
              </Grid>
            </Authorize>
          </Box>
        ),
      },
      {
        title: 'Select Publishing Channels',
        requiredFields: ['channels'],
        content: (
          <Box sx={{ marginTop: '-30px' }}>
            <Typography variant="subtitle1">
              What Channels Would You Like to Publish To?
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '8px' }}>
              Make sure that everything is approved and ready to be consumed
              before publishing to a channel.
            </Typography>
            <Typography
              sx={{ marginTop: '40px', textAlign: 'left', marginLeft: '4px' }}
            >
              <Asterisk /> Select Channel(s)
            </Typography>
            <Grid container>
              <Field name="channels">
                {({ input, meta }) => networkChannels.map((channel) => (
                    <Grid
                      item
                      xs={4}
                      key={channel.id}
                      sx={{ textAlign: 'left' }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            sx={{ padding: '4px 4px 4px 8px' }}
                            checked={input.value.includes(channel.id)}
                            onChange={() => {
                              const i = input.value.indexOf(channel.id);
                              if (i === -1) {
                                input.onChange(input.value.concat(channel.id));
                              } else {
                                const newValues = input.value.slice();
                                newValues.splice(i, 1);
                                input.onChange(newValues);
                              }
                            }}
                          />
                        }
                        label={channel.label}
                      />
                    </Grid>
                  ))
                }
              </Field>
            </Grid>
          </Box>
        ),
      },
    ],
    [styles, networkChannels],
  );

  return (
    <GenericStepperModal
      open={true}
      sx={styles.modal}
      formParams={{
        keepDirtyOnReinitialize: true,
        onSubmit: publishPackage,
        onChange: (form) => {
          setInitialFormValues(form.getState().values);
        },
        validate,
        initialValues: initialFormValues,
      }}
      onClose={handleCancel}
      confirmText="Publish"
      steps={steps}
    />
  );
};

PublishModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleCompleted: PropTypes.func.isRequired,
  networkChannels: PropTypes.array,
};

PublishModal.defaultProps = {
  dataPackage: null,
};

export default React.memo(PublishModal);
