import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    flexShrink: 0,
    overflow: 'hidden',
    '& .MuiDrawer-paper': {
      height: 'auto',
    },
  },
  closeButton: {
    color: theme.palette.grey[600],
    fontSize: '22px',
  },
  titleContainer: {
    height: '68px',
    padding: '16px',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginTop: '8px',
    marginLeft: '8px',
    fontSize: '22px',
    fontWeight: 500,
  },
  content: {
    marginTop: '68px',
    position: 'relative',
  },
  actionBar: {
    background: theme.palette.white,
    bottom: 0,
    boxShadow: `0 -2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: '70px',
    padding: '14px',
    position: 'fixed',
    transitionDelay: 225,
  },
});

const GozioDrawer = ({
                       anchor,
                       closeButtonSx,
                       cancelText,
                       confirmText,
                       thirdText,
                       children,
                       disableCancel,
                       disableConfirm,
                       disableThird,
                       greyOutCancel,
                       greyOutConfirm,
                       greyOutThird,
                       onClose,
                       onConfirm,
                       onThird,
                       open,
                       sx,
                       title,
                       contentHeight,
                       top,
                       width,
                       variant,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const topPx = typeof top === 'string' ? top : `${top}px`;

  const hasAction = !disableCancel || !disableConfirm;

  return (
    <Drawer
      anchor={anchor}
      open={open}
      sx={{
        ...styles.root,

        top,
        width,

        '& .MuiDrawer-paper': {
          top,
          width,
        },

        ...sx,
      }}
      transitionDuration={{
        enter: 225,
        exit: 225,
      }}
      variant={variant}
    >
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title} variant="h1">
          {title || ''}
        </Typography>
        <Close
          sx={{ ...styles.closeButton, ...closeButtonSx }}
          onClick={disableCancel ? onClose : onConfirm}
          data-test="close-drawer"
        />
      </Box>
      <Box
        sx={{
          ...styles.content,
          height:
            contentHeight ?? topPx !== '0px'
              ? `calc(100vh - 136px - ${topPx})`
              : '100vh',
        }}
      >
        {children}
      </Box>
      {hasAction && (
        <Box sx={{ ...styles.actionBar, width }}>
          <Box>
            {!disableCancel && (
              <Button
                color="primary"
                data-test="cancel-button"
                disabled={greyOutCancel}
                onClick={onClose}
                variant="outlined"
                sx={{ marginRight: '12px', minWidth: '125px' }}
              >
                {cancelText}
              </Button>
            )}
            {!disableThird && (
              <Button
                color="primary"
                data-test="third-button"
                disabled={greyOutThird}
                onClick={onThird}
                variant="outlined"
                sx={{ minWidth: '125px' }}
              >
                {thirdText}
              </Button>
            )}
          </Box>
          {!disableConfirm && (
            <Button
              color="primary"
              data-test="confirm-button"
              disabled={greyOutConfirm}
              onClick={onConfirm}
              variant="contained"
              sx={{ minWidth: '125px' }}
            >
              {confirmText}
            </Button>
          )}
        </Box>
      )}
    </Drawer>
  );
};

GozioDrawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right']),
  closeButtonSx: PropTypes.object,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  thirdText: PropTypes.string,
  disableCancel: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  disableThird: PropTypes.bool,
  greyOutCancel: PropTypes.bool,
  greyOutConfirm: PropTypes.bool,
  greyOutThird: PropTypes.bool,
  onConfirm: PropTypes.func,
  onThird: PropTypes.func,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  sx: PropTypes.object,
  title: PropTypes.string,
  contentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
};

GozioDrawer.defaultProps = {
  anchor: 'right',
  closeButtonSx: {},
  cancelText: 'Cancel',
  confirmText: 'Save',
  disableCancel: false,
  disableConfirm: false,
  disableThird: true,
  greyOutCancel: false,
  greyOutConfirm: false,
  onConfirm: () => {},
  open: false,
  sx: {},
  top: 0,
  width: 500,
  variant: 'persistent',
};

export default GozioDrawer;
