import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import GenericModal from 'components/genericModal/genericModal';
import GozioTable from 'components/tables/gozioTable';
import { getTableCountTitle } from 'helpers/table-util';
import { useNetworkConfiguration, useUpdateNetworkConfiguration } from 'hooks/dataHooks';
import EditVersionModal from 'pages/network/configuration/containers/editVersionModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const buildStyles = () => ({
  root: { height: '100%', paddingTop: '8px' },
  addButton: {
    marginLeft: '16px',
    marginRight: '4px',
    paddingTop: '12px!important',
    width: '36px',
    height: '36px',
  },
});

const COLUMNS = [
  { Header: 'Key', accessor: 'key', alwaysHidden: true },
  {
    Header: 'Platform',
    accessor: 'platform',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'State',
    accessor: 'state',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Version',
    accessor: 'version',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Message',
    accessor: 'message',
    minWidth: 200,
    width: 200,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    minWidth: 80,
    width: 80,
    disableSortBy: true,
    justifyRight: true,
  },
];

export const createVersions = (versionData) => {
  const tempData = {
    iosControl: {
      notSupported: [],
      endOfLife: [],
    },
    androidControl: {
      notSupported: [],
      endOfLife: [],
    },
  };
  versionData.forEach((element) => {
    if (element.platform === 'Android') {
      if (element.state === 'Not Supported') {
        tempData.androidControl.notSupported.push({
          version: element.version,
          message: element.message,
        });
      } else {
        tempData.androidControl.endOfLife.push({
          version: element.version,
          message: element.message,
        });
      }
    } else if (element.state === 'Not Supported') {
      tempData.iosControl.notSupported.push({
        version: element.version,
        message: element.message,
      });
    } else {
      tempData.iosControl.endOfLife.push({
        version: element.version,
        message: element.message,
      });
    }
  });
  return tempData;
};

const Version = ({ networkId }) => {
  const styles = buildStyles();
  const [versions, setVersions] = useState([]);
  const [editedVersion, setEditedVersion] = useState(null);
  const [deletedVersion, setDeletedVersion] = useState(null);
  const { loading, data: configuration } = useNetworkConfiguration(networkId);
  const [updateVersions] = useUpdateNetworkConfiguration();

  useEffect(() => {
    if (configuration) setVersions(versionTransform(configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  const versionTransform = (configuration) => {
    const versionData = [];
    let key = 0;
    if (configuration.androidControl) {
      configuration.androidControl.endOfLife.forEach((element) => {
        const data = {
          version: element.version,
          message: element.message,
          platform: 'Android',
          state: 'End of Life',
          key: key,
        };
        data.actions = getActions(data);
        versionData.push(data);
        key++;
      });
      configuration.androidControl.notSupported.forEach((element) => {
        const data = {
          version: element.version,
          message: element.message,
          platform: 'Android',
          state: 'Not Supported',
          key: key,
        };
        data.actions = getActions(data);
        versionData.push(data);
        key++;
      });
    }
    if (configuration.iosControl) {
      configuration.iosControl.endOfLife.forEach((element) => {
        const data = {
          version: element.version,
          message: element.message,
          platform: 'IOS',
          state: 'End of Life',
          key: key,
        };
        data.actions = getActions(data);
        versionData.push(data);
        key++;
      });
      configuration.iosControl.notSupported.forEach((element) => {
        const data = {
          version: element.version,
          message: element.message,
          platform: 'IOS',
          state: 'Not Supported',
          key: key,
        };
        data.actions = getActions(data);
        versionData.push(data);
        key++;
      });
    }
    return versionData;
  };

  const showEdit = (version) => {
    setEditedVersion(version);
  };

  const doDelete = async () => {
    const versionsClone = [...versions];
    const index = versionsClone.findIndex((v) => v.key === deletedVersion.key);
    versionsClone.splice(index, 1);
    const inputData = createVersions(versionsClone);
    updateVersions({ variables: { input: inputData } });
    setDeletedVersion(null);
  };

  const showDelete = (version) => {
    setDeletedVersion(version);
  };

  const showAdd = () => {
    setEditedVersion({});
  };

  const getActions = (key) => (
    <Box className="hoverUnhide">
      <Tooltip title="Edit">
        <Edit onClick={() => showEdit(key)} sx={{ marginRight: '8px' }} />
      </Tooltip>
      <Tooltip title="Delete">
        <Delete onClick={() => showDelete(key)} />
      </Tooltip>
    </Box>
  );

  const count = versions?.length || 0;

  return (
    <Box sx={styles.root}>
      <GozioTable
        name="VersionsTable"
        columns={COLUMNS}
        data={versions}
        loading={loading}
        sortBy={[{ id: 'platform', desc: false }]}
        countTitle={getTableCountTitle(count, 'Version')}
        headerActions={
          <AddCircleButton
            onClick={() => showAdd()}
            aria-label="add-version"
            sx={styles.addButton}
            tooltipTitle="Add a Version"
          />
        }
        filterable={true}
      />
      {editedVersion && (
        <EditVersionModal
          version={editedVersion}
          versions={versions}
          handleClose={() => setEditedVersion(null)}
          networkId={networkId}
        />
      )}
      {deletedVersion !== null && (
        <GenericModal
          title="Delete Version"
          body={`Are you sure version ${deletedVersion.version} is no longer in use and you want to proceed with deleting it?`}
          open={true}
          handleClose={() => setDeletedVersion(null)}
          handleConfirm={doDelete}
          confirmText="Delete Version"
          customTags={{
            confirm: 'VersionDeleteModalConfirmButton',
            cancel: 'VersionDeleteModalCancelButton',
          }}
        />
      )}
    </Box>
  );
};

Version.propTypes = {
  networkId: PropTypes.string.isRequired,
};

export default Version;
