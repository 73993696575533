import { AddCircle, ArrowRightAlt, Clear, FileCopy } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormSubSectionTitle from 'components/forms/formSubSectionTitle';
import InfoPanel from 'components/infoPanel/infoPanel';
import GozioSwitch from 'components/switch/formSwitch';
import GozioTable from 'components/tables/gozioTable';
import TimePicker from 'components/timePicker/timePicker';
import dayjs from 'dayjs';
import { colorWithAlpha } from 'helpers/color-util';
import { STANDARD_DATE_FORMAT } from 'helpers/date-util';
import { capitalize } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import { DEFAULT_CUSTOM_HOURS, HOUR_STATUSES, DEFAULT_START_AND_END_TIMES } from 'pages/locations/containers/locationsFormHelper';
import CopyHoursPopover from 'pages/locations/containers/sections/copyHoursPopover';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const COLUMNS = [
  {
    Header: 'Day',
    accessor: 'day',
    cellStyle: {
      alignItems: 'start',
    },
    disableSortBy: true,
    minWidth: 120,
    width: 120,
  },
  {
    Header: <span>Hours&nbsp;of&nbsp;Operation</span>,
    accessor: 'hoursOfOperation',
    cellStyle: {
      alignItems: 'start',
    },
    disableSortBy: true,
    minWidth: 648,
    width: 648,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    cellStyle: {
      alignItems: 'start',
    },
    minWidth: 90,
    width: 90,
    disableSortBy: true,
    justifyRight: true,
  },
];

const buildStyles = ({ theme }) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  day: {
    marginTop: '24px',
  },
  status: {
    cursor: 'pointer',
    marginTop: '16px',
    minHeight: '58px',
    width: '120px',
    maxWidth: '120px',
  },
  hoursOfOperation: {
    cursor: 'pointer',
    position: 'relative',
  },
  timesRow: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '82px',
    position: 'relative',
  },
  timePicker: {
    display: 'flex',
  },
  rightArrowIcon: {
    marginLeft: '4px',
    marginRight: '6px',
    width: '24px',
    height: '24px',

    '& svg': {
      fill: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
  deleteIcon: {
    marginLeft: '16px',
    marginRight: '4px',
    width: '24px',
    height: '24px',
    visibility: 'hidden',

    '& svg': {
      fill: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
  actions: {
    marginTop: '24px',
  },
  unhide: {
    display: 'block !important',
  },
  actionIcon: {
    cursor: 'pointer',
    fill: theme.palette.grey[500],
    width: '24px',
    height: '24px',

    '&:not(:first-of-type)': {
      marginLeft: '18px',
    },
  },
});

const CustomHours = ({
                       canClearHours,
                       canCopyHours,
                       columns,
                       data,
                       description,
                       form,
                       onChange,
                       showOpenSwitch,
                       sx,
                       title,
                     }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [showClearHours, setShowClearHours] = useState(canClearHours);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCopyModalDay, setSelectedCopyModalDay] = useState(null);

  useEffect(() => {
    if (data?.length > 0) {
      setShowClearHours(
        data.find(({ hours }) => hours.find((hour) => hour?.startTime || hour?.endTime),
        ),
      );
    }
  }, [data]);

  const handleStatusChange = useCallback(
    (day, status) => {
      const dayIndex = data.findIndex((item) => item.day === day);
      const item = data[dayIndex];
      const hours = item.hours
        .map((h) => h.startTime && h.endTime ? h : null)
        .filter((h) => h);

      const updatedData = JSON.parse(JSON.stringify(data));
      updatedData.splice(dayIndex, 1, {
        day: item.day,
        status,
        hours,
      });
      onChange(updatedData);
    },
    [data, onChange],
  );

  const extractTimeString = (dayJsDate) => {
    const date = dayJsDate?.toDate();
    if (isNaN(date)) {
      return '';
    }
    const timeStr = date?.toTimeString();
    return timeStr?.substring(0, 8) || '';
  };

  const handleTimeChange = useCallback(
    (hourIndex, day, status, type, value) => {
      const dayIndex = data.findIndex((item) => item.day === day);
      const { hours: oldHours, ...rest } = data[dayIndex];
      const updatedHours
        = oldHours.length > 0
          ? JSON.parse(JSON.stringify(oldHours))
          : [DEFAULT_START_AND_END_TIMES];
      const time = extractTimeString(value);
      if (updatedHours[hourIndex]) {
        if (type === HOUR_STATUSES.OPEN) {
          updatedHours[hourIndex].startTime = time || '';
        } else {
          updatedHours[hourIndex].endTime = time || '';
        }
      }
      const updatedData = Array.from(data);
      updatedData.splice(dayIndex, 1, { hours: updatedHours, ...rest });
      onChange(updatedData);
    },
    [data, onChange],
  );

  const handleAddHour = useCallback(
    (day) => {
      const dayIndex = data.findIndex((item) => item.day === day);
      const updatedData = JSON.parse(JSON.stringify(data));
      updatedData[dayIndex].hours.push(DEFAULT_START_AND_END_TIMES);
      onChange(updatedData);
    },
    [data, onChange],
  );

  const handleDeleteHour = useCallback(
    (day, hourIndex) => {
      const dayIndex = data.findIndex((item) => item.day === day);
      const updatedData = JSON.parse(JSON.stringify(data));
      updatedData[dayIndex].hours.splice(hourIndex, 1);
      onChange(updatedData);
    },
    [data, onChange],
  );

  const renderTimes = useCallback(
    (day, status, hours) => {
      const hasExistingHours = hours?.length > 0;
      const list = hasExistingHours ? hours : [DEFAULT_START_AND_END_TIMES];

      if (list.length > 0) {
        if (status === HOUR_STATUSES.OPEN) {
          return list.map((hour, hourIndex) => (
            <Box key={`h_${hourIndex}`} sx={styles.timesRow}>
              <Box sx={styles.timePicker}>
                <TimePicker
                  label="Open"
                  onChange={(v) => handleTimeChange(
                      hourIndex,
                      day,
                      status,
                      HOUR_STATUSES.OPEN,
                      v,
                    )
                  }
                  required={true}
                  value={hour?.startTime ?? ''}
                />
              </Box>
              <Box sx={styles.rightArrowIcon}>
                <ArrowRightAlt />
              </Box>
              <Box sx={styles.timePicker}>
                <TimePicker
                  label="Closed"
                  onChange={(v) => handleTimeChange(
                      hourIndex,
                      day,
                      status,
                      HOUR_STATUSES.CLOSED,
                      v,
                    )
                  }
                  required={true}
                  value={hour?.endTime ?? ''}
                />
              </Box>
              <Box sx={styles.deleteIcon}>
                <Clear onClick={() => handleDeleteHour(day, hourIndex)} />
              </Box>
            </Box>
          ));
        }
      }
    },
    [styles, handleDeleteHour, handleTimeChange],
  );

  const shouldDisableCopy = (status, hours) => {
    if (status === HOUR_STATUSES.CLOSED) {
      return false;
    }

    if (hours.length === 0) {
      return true;
    }

    return !!hours.find((h) => !h.startTime || !h.endTime);
  };

  const handleCopyClick = (e, day) => {
    setAnchorEl(e.currentTarget);
    setSelectedCopyModalDay(day);
  };

  const closeCopyPopover = () => {
    setSelectedCopyModalDay(null);
    setAnchorEl(null);
  };

  const handleCopyHours = useCallback(
    (day, targetDays) => {
      const dayIndex = data.findIndex((item) => item.day === day);
      const { status, hours } = data[dayIndex];
      const updatedData = Array.from(data).map((d) => {
        if (targetDays.includes(d.day)) {
          return {
            day: d.day,
            status,
            hours,
          };
        }

        return d;
      });
      onChange(updatedData);
      closeCopyPopover();
    },
    [data, onChange],
  );

  const handleResetHours = () => {
    closeCopyPopover();
    onChange(
      data.map(({ hours, ...rest }) => ({
        hours: hours.map(() => DEFAULT_START_AND_END_TIMES),
        ...rest,
      })),
    );
  };

  const getDisabledDaysLookup = useCallback(() => {
    if (!form) {
      return {};
    }
    const { startDate, endDate, exceptionHours } = form.getState().values;
    const disabledDaysLookup = {};
    DEFAULT_CUSTOM_HOURS.forEach(({ day }) => {
      disabledDaysLookup[day] = true;
    });

    const start = dayjs(startDate, STANDARD_DATE_FORMAT, true);
    const end = dayjs(endDate, STANDARD_DATE_FORMAT, true);
    if (start.isValid() && end.isValid()) {
      const startDay = start.format('dddd').toLowerCase();
      const numOfDays = Math.min(end.diff(start, 'days') + 1, 7);
      if (numOfDays > 0) {
        const map = {};
        exceptionHours?.forEach((exception) => {
          map[exception.day] = exception;
        });
        const indices = [];
        const startIndex = DEFAULT_CUSTOM_HOURS.findIndex(
          (item) => item.day === startDay,
        );
        for (
          let i = startIndex, max = Math.min(startIndex + numOfDays, 7);
          i < max;
          i++
        ) {
          disabledDaysLookup[DEFAULT_CUSTOM_HOURS[i].day] = false;
          indices.push(i);
        }
        if (indices.length < numOfDays) {
          for (let i = 0, max = numOfDays - indices.length; i < max; i++) {
            disabledDaysLookup[DEFAULT_CUSTOM_HOURS[i].day] = false;
          }
        }
      }
    }

    return disabledDaysLookup;
  }, [form]);

  const memoizedData = useMemo(
    () => data?.map(({ day, status, hours }, index) => ({
        day: (
          <Typography sx={styles.day} variant="body1">
            {capitalize(day)}
          </Typography>
        ),
        hoursOfOperation: (
          <Grid container>
            {showOpenSwitch && (
              <Grid sx={styles.status} item xs={4}>
                <GozioSwitch
                  input={{
                    value: status === HOUR_STATUSES.OPEN,
                    onChange: () => handleStatusChange(
                        day,
                        status === HOUR_STATUSES.OPEN
                          ? HOUR_STATUSES.CLOSED
                          : HOUR_STATUSES.OPEN,
                      ),
                  }}
                  name="status"
                  labelOn={'Open'}
                  labelOff={'Closed'}
                />
              </Grid>
            )}
            <Grid
              sx={styles.hoursOfOperation}
              item
              xs={showOpenSwitch ? 8 : 12}
            >
              {renderTimes(day, status, hours, index)}
            </Grid>
          </Grid>
        ),
        actions: (
          <Box
            className="hoverUnhide"
            sx={{
              ...styles.actions,
              ...selectedCopyModalDay === day && styles.unhide,
            }}
          >
            <IconButton
              sx={styles.actionIcon}
              disabled={status === HOUR_STATUSES.CLOSED}
              onClick={() => handleAddHour(day)}
              size="large"
            >
              <AddCircle />
            </IconButton>
            {canCopyHours && (
              <>
                <IconButton
                  aria-describedby={`copy_${day}`}
                  sx={styles.actionIcon}
                  disabled={shouldDisableCopy(status, hours)}
                  onClick={(e) => handleCopyClick(e, day)}
                  size="large"
                >
                  <FileCopy />
                </IconButton>
                <CopyHoursPopover
                  anchor={anchorEl}
                  id={`copy_${day}`}
                  day={day}
                  disabledDaysLookup={getDisabledDaysLookup()}
                  onChange={handleCopyHours}
                  onClose={closeCopyPopover}
                  open={selectedCopyModalDay === day}
                />
              </>
            )}
          </Box>
        ),
      })),
    [
      anchorEl,
      styles,
      canCopyHours,
      data,
      getDisabledDaysLookup,
      handleAddHour,
      handleCopyHours,
      handleStatusChange,
      renderTimes,
      selectedCopyModalDay,
      showOpenSwitch,
    ],
  );

  return (
    <Grid sx={{ ...styles.root, ...sx }} item xs={12}>
      <FormSubSectionTitle
        title={title}
        variant="subtitle1"
        clearText={showClearHours ? 'Clear Hours' : null}
        onClear={showClearHours ? handleResetHours : null}
      />
      {description && <InfoPanel title={description} />}
      <GozioTable
        name="HoursTable"
        sx={{
          borderRadius: 0,
          marginTop: '16px',
          overflowY: 'hidden',
          padding: 0,

          '& tbody': {
            '& tr': {
              height: 'unset',

              '& :hover': {
                '& :last-child div': {
                  visibility: 'visible',
                },
              },
            },
          },

          '& .MuiTableCell-body': {
            height: 'unset',
            padding: '0 12px 0 12px',
          },

          '& .MuiTableRow-root': {
            '&:hover': {
              background: `linear-gradient(to bottom right, ${ColorPalette.gradient.blue.start}, ${ColorPalette.gradient.blue.end})`,

              '& span.MuiFormControlLabel-label': {
                fontWeight: 'bold',
                color: ColorPalette.grey[600],
              },

              '& $deleteIcon': {
                visibility: 'visible',
              },
            },
          },
        }}
        columns={columns}
        data={memoizedData}
      />
      {!!selectedCopyModalDay && (
        <CopyHoursPopover
          day={selectedCopyModalDay}
          onChange={handleCopyHours}
          onClose={() => {
            setAnchorEl(null);
            setSelectedCopyModalDay(null);
          }}
        />
      )}
    </Grid>
  );
};

CustomHours.propTypes = {
  canClearHours: PropTypes.bool,
  canCopyHours: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      day: PropTypes.oneOf([
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]).isRequired,
      status: PropTypes.oneOf([HOUR_STATUSES.OPEN, HOUR_STATUSES.CLOSED])
        .isRequired,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          startTime: PropTypes.string,
          endTime: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
  description: PropTypes.string,
  form: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showOpenSwitch: PropTypes.bool,
  sx: PropTypes.object,
  title: PropTypes.string.isRequired,
};

CustomHours.defaultProps = {
  canClearHours: true,
  canCopyHours: true,
  columns: COLUMNS,
  description: null,
  form: null,
  showOpenSwitch: true,
  sx: {},
};

export default CustomHours;
