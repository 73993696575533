import { Clear } from '@mui/icons-material';
import Chip from 'components/chip/chip';
import { getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React from 'react';

const getCategoryName = (name) => {
  if (!name) {
    return '';
  }

  if (typeof name === 'string') {
    return name;
  }

  return getLabelByLang(name);
};

const CatChip = ({ category, deleteCategory, sx }) => (
    <Chip
      label={getCategoryName(category.name)}
      clickableIcon={<Clear />}
      onIconClick={() => deleteCategory(category.id)}
      state={'default'}
      variant={'primary'}
      selectable={false}
      sx={{
        margin: '4px',
        ...sx,
      }}
    />
  );

CatChip.propTypes = {
  category: PropTypes.object.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

CatChip.defaultProps = {
  sx: {},
};

export default CatChip;
