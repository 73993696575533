export const typeUtil = (types) => {
	const result = {};
	types.every((type) => {
		result[type] = type;
		result[`${type}_SUCCESS`] = `${type}_SUCCESS`;
		result[`${type}_ERROR`] = `${type}_ERROR`;
		result[`${type}_CUSTOM`] = `${type}_CUSTOM`;
		return true;
	});
	return result;
};
