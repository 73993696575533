import { Box } from '@mui/material';
import Loading from 'components/loading/loading';
import EmptyTable from 'components/tables/emptyTable';
import GozioTable from 'components/tables/gozioTable';
import { useNetworkMobileAppTokens } from 'hooks/dataHooks/useNetworkConfiguration';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = () => ({
  root: { height: '100%', paddingTop: '8px' },
});

const COLUMNS = [
  { Header: 'Key', accessor: 'key', alwaysHidden: true },
  {
    Header: 'Type',
    accessor: 'type',
    minWidth: 35,
    width: 35,
  },
  {
    Header: 'Token',
    accessor: 'token',
    minWidth: 85,
    width: 85,
  },
];

const MobileAppTokens = ({ networkId }) => {
  const styles = buildStyles();
  const { loading, data } = useNetworkMobileAppTokens(networkId);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={styles.root}>
      <GozioTable
        name="MobileAppTokensTable"
        columns={COLUMNS}
        data={data ?? []}
        loading={loading}
        countTitle="Mobile App Tokens"
        sortBy={[{ id: 'type', desc: false }]}
        emptyContent={<EmptyTable />}
      />
    </Box>
  );
};

MobileAppTokens.propTypes = {
  networkId: PropTypes.string.isRequired,
};

export default MobileAppTokens;
