import { Box, IconButton, Menu, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { rolePublicName } from 'helpers/permissions-util';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'none none none solid',
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  userSpace: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'initial',
  },
  truncate: {
    width: 130,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  arrowButton: {
    padding: 0,
  },
}));

const UserMenu = ({ name, role, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        data-test="user-menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        className={classes.root}
        onClick={handleClick}
      >
        <Box className={classes.userSpace}>
          <Typography variant="subtitle2" className={classes.truncate}>
            {name}
          </Typography>
          {role && (
            <Typography variant="caption">{rolePublicName(role)}</Typography>
          )}
        </Box>
        <IconButton
          aria-label="open drawer"
          onClick={handleClick}
          className={classes.arrowButton}
          size="large"
          sx={{ color: 'inherit' }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};

UserMenu.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
};

UserMenu.defaultProps = {
  role: null,
};

export default UserMenu;
