import { Delete } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import EmptySection from 'components/emptySection/emptySection';
import Condition from 'components/forms/condition';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { getFormLabel } from 'helpers/form-util';
import { buildLabelByLang, buildLanguageSchema, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { useUpdateList } from 'hooks/dataHooks/useLists';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import { makeValidate, TextField } from 'mui-rff';
import DeleteListSectionModal from 'pages/lists/containers/DeleteListSectionModal';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';

const GeneralTabPanel = ({
                           sx,
                           list,
                           onFormChanged,
                           onListSectionDeleted,
                           onListSectionClicked,
                           tabValue,
                         }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSectionForDeletion, setSelectedSectionForDeletion]
    = useState(null);

  const languages = useActiveNetworkLanguages();

  const [updateList] = useUpdateList();

  const validate = languages
    ? makeValidate(
      Yup.object().shape(
        buildLanguageSchema(
          {
            langES: Yup.boolean(),
            name: Yup.string().required('Name is required'),
            nameES: Yup.string(),
          },
          languages,
          { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
        ),
      ),
    )
    : null;

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await updateList({
      variables: {
        id: list.id,
        input: {
          name: buildLabelByLang(data, 'name', 'nameES'),
        },
      },
    });
    setIsSubmitting(false);
  };

  const sectionCount = useMemo(() => list?.sections?.length ?? 0, [list]);

  const hasSpanishLang = hasSpanish(languages);
  const columns = useMemo(() => {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        alwaysHidden: true,
      },
      {
        Header: 'Section Name',
        accessor: 'name',
        minWidth: hasSpanishLang ? 273 : 503,
        width: hasSpanishLang ? 273 : 503,
        onClick: (data) => onListSectionClicked(data),
      },
      {
        Header: <span>Number&nbsp;of&nbsp;Locations</span>,
        accessor: 'locationCount',
        disableSortBy: true,
        minWidth: 152,
        width: 152,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableGlobalFilter: true,
        minWidth: 66,
        width: 66,
        disableSortBy: true,
        justifyRight: true,
      },
    ];
    if (hasSpanishLang) {
      columns.splice(2, 0, {
        Header: 'Section Name (Spanish)',
        accessor: 'nameES',
        disableSortBy: true,
        minWidth: 310,
        width: 310,
      });
    }
    return columns;
  }, [hasSpanishLang, onListSectionClicked]);

  const data = useMemo(
    () => list?.sections?.map((section) => ({
        id: section?.id,
        name: getLabelByLang(section?.name),
        nameES: getLabelByLang(section?.name, LANGUAGE_CODES.SPANISH),
        locationCount: section?.locations?.length || 0,
        actions: (
          <Box className="hoverUnhide">
            <Tooltip title="Delete">
              <Delete onClick={() => setSelectedSectionForDeletion(section)} />
            </Tooltip>
          </Box>
        ),
      })),
    [list],
  );

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <TabPanel
      value={tabValue}
      index={1}
      sx={{
        ...sx.tabPanel,
        ...tabValue === 1 && { height: 'calc(100% - 69px)' },
      }}
    >
      <Box sx={{ ...sx.tabBody, ...sx.generalTabContent }}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            langES: hasSpanishLang,
            name: getLabelByLang(list.name),
            ...hasSpanishLang && {
              nameES: getLabelByLang(list.name, LANGUAGE_CODES.SPANISH),
            },
          }}
          render={({
                     handleSubmit,
                     form,
                     submitting,
                     pristine,
                     invalid,
                     dirty,
                     errors,
                   }) => (
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <FormWatcher
                formRenderProps={{ form }}
                errorFormFieldMap={
                  new Map([
                    [
                      'name',
                      {
                        label: 'Link Name',
                        section: 'General',
                      },
                    ],
                    [
                      'nameES',
                      {
                        label: 'Link Name (Spanish)',
                        section: 'General',
                      },
                    ],
                  ])
                }
                isClosing={isClosing}
                isSubmitting={isSubmitting}
                onFormChanged={() => onFormChanged({ dirty, errors })}
                onClose={() => {
                  setIsClosing(false);
                  setIsSubmitting(false);
                }}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">List Details</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ marginTop: '21px', paddingRight: '12px' }}
                >
                  <TextField
                    label={getFormLabel('List Name', true)}
                    name="name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Condition when="langES" is={true}>
                  <Grid
                    item
                    xs={6}
                    sx={{ marginTop: '21px', paddingLeft: '12px' }}
                  >
                    <TextField
                      label={getFormLabel('List Name (Spanish)', false)}
                      name="nameES"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Condition>
                <Grid item xs={12} sx={{ marginTop: '40px' }}>
                  <Typography variant="subtitle1">
                    {sectionCount ?? 0} Section{sectionCount !== 1 ? 's' : ''}{' '}
                    in This List
                  </Typography>
                </Grid>
                <Grid sx={sx.sections} item xs={12}>
                  {sectionCount === 0 && (
                    <EmptySection title="There Are No Sections In This List" />
                  )}
                  {sectionCount > 0 && (
                    <GozioTable
                      sx={{
                        padding: '0 0 12px 0',

                        '& > div': {
                          overflowY: 'hidden',
                        },
                      }}
                      name="ListTable"
                      columns={columns}
                      data={data}
                      hidePagination={true}
                      sortBy={[{ id: 'name', desc: false }]}
                    />
                  )}
                </Grid>
              </Grid>
              <Box sx={sx.footer}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={sx.saveBtn}
                  onClick={async () => {
                    await form.submit();
                  }}
                  disabled={submitting || pristine || invalid}
                  startIcon={
                    submitting ? (
                      <CircularProgress
                        size={16}
                        thickness={4}
                        sx={sx.progress}
                      />
                    ) : null
                  }
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
      {selectedSectionForDeletion && (
        <DeleteListSectionModal
          handleClose={() => setSelectedSectionForDeletion(null)}
          handleDeleted={() => {
            setSelectedSectionForDeletion(null);
            onListSectionDeleted();
          }}
          listId={list.id}
          section={selectedSectionForDeletion}
        />
      )}
    </TabPanel>
  );
};

GeneralTabPanel.propTypes = {
  sx: PropTypes.object.isRequired,
  list: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
  onFormChanged: PropTypes.func.isRequired,
  onListSectionDeleted: PropTypes.func.isRequired,
  onListSectionClicked: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

GeneralTabPanel.defaultProps = {
  tabValue: 0,
};

export default GeneralTabPanel;
