import { CircularProgress, Grid, Typography } from '@mui/material';

import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({
                   backgroundColor,
                   className,
                   content,
                   fullPage,
                   progressSize,
                   textVariant,
                   thickness,
                   ...rest
                 }) => (
  <Grid
    container
    direction="row"
    className={className}
    sx={{
      backgroundColor,
      height: fullPage ? '100vh' : '100%',
    }}
    {...rest}
  >
    <Grid
      container
      item
      xs
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item>
        <CircularProgress size={progressSize} thickness={thickness} />
      </Grid>
      {content.length > 0 && (
        <Grid item>
          <Typography variant={textVariant}>{content}</Typography>
        </Grid>
      )}
    </Grid>
  </Grid>
);

Loading.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  fullPage: PropTypes.bool,
  progressSize: PropTypes.number,
  textVariant: PropTypes.string,
  thickness: PropTypes.number,
};

Loading.defaultProps = {
  backgroundColor: ColorPalette.grey[50],
  className: '',
  content: 'Loading...',
  fullPage: false,
  progressSize: 130,
  textVariant: 'h1',
  thickness: 2,
};

export default Loading;
