import { LocalHospitalOutlined, WebAsset } from '@mui/icons-material';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    borderRadius: '50%',
    display: 'flex',
    marginRight: '4px',
    justifyContent: 'center',
  },
  logo: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '90%',
    width: '90%',
  },
  blueColor: { color: theme.palette.primary.main },
}));

const NetworkLogo = ({ networkName, logoUrl, size, lightMode }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      sx={{ height: `${size}px`, width: `${size}px` }}
    >
      {networkName === 'Global Template' ? (
        <WebAsset
          className={clsx(classes.logo, lightMode && classes.blueColor)}
        />
      ) : logoUrl ? (
        <Box
          className={classes.logo}
          sx={{
            backgroundImage: `url(${logoUrl})`,
          }}
          alt="selected network icon"
        />
      ) : (
        <LocalHospitalOutlined
          className={clsx(classes.logo, lightMode && classes.blueColor)}
        />
      )}
    </Box>
  );
};

NetworkLogo.propTypes = {
  networkName: PropTypes.string,
  lightMode: PropTypes.bool,
  logoUrl: PropTypes.string,
  size: PropTypes.number,
};

NetworkLogo.defaultProps = {
  networkName: '',
  lightMode: true,
  logoUrl: null,
  size: 18,
};

export default NetworkLogo;
