import { Grid, Typography } from '@mui/material';
import Condition from 'components/forms/condition';
import GenericFormModal from 'components/genericModal/genericFormModal';
import Loading from 'components/loading/loading';
import { getFormLabel } from 'helpers/form-util';
import { buildLabelByLang, buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateListSection, useUpdateListSection } from 'hooks/dataHooks/useLists';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const buildStyles = () => ({
  heading: {
    fontSize: '28px',
    lineHeight: '40px',
  },
});

const AddOrEditListSectionModal = ({
                                     handleClose,
                                     handleSaved,
                                     listId,
                                     sectionData,
                                   }) => {
  const styles = buildStyles();

  const [createListSection] = useCreateListSection(listId);
  const [updateListSection] = useUpdateListSection(listId);

  const languages = useActiveNetworkLanguages();

  const validate = languages
    ? makeValidate(
      Yup.object().shape(
        buildLanguageSchema(
          {
            langES: Yup.boolean(),
            name: Yup.string().required('Name is required'),
            nameES: Yup.string(),
          },
          languages,
          { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
        ),
      ),
    )
    : null;

  const isAdd = !sectionData;

  const handleListAdded = async (data) => {
    if (isAdd) {
      await createListSection({
        variables: {
          input: {
            curatedList: listId,
            name: buildLabelByLang(data, 'name', 'nameES'),
            locations: [],
          },
        },
      });
    } else {
      await updateListSection({
        variables: {
          id: sectionData.id,
          input: {
            curatedList: listId,
            name: buildLabelByLang(data, 'name', 'nameES'),
            locations: sectionData.locations,
          },
        },
      });
    }
    handleSaved();
  };

  const hasSpanishLang = hasSpanish(languages);

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <GenericFormModal
      title={null}
      open={true}
      formParams={{
        onSubmit: handleListAdded,
        validate,
        initialValues: {
          langES: hasSpanishLang,
          name: sectionData?.name,
          ...hasSpanishLang && {
            nameES: sectionData?.nameES,
          },
        },
      }}
      body={
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.heading}>
              {isAdd ? 'Add a List Section' : 'Edit List Section'}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <TextField
              label={getFormLabel('Section Name', true)}
              name="name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Condition when="langES" is={true}>
            <Grid item xs={12} sx={{ marginTop: '34px' }}>
              <TextField
                label={getFormLabel('Section Name (Spanish)', false)}
                name="nameES"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Condition>
        </Grid>
      }
      confirmText={isAdd ? 'ADD SECTION' : 'SAVE'}
      handleClose={handleClose}
    />
  );
};

AddOrEditListSectionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSaved: PropTypes.func.isRequired,
  listId: PropTypes.string.isRequired,
  sectionData: PropTypes.object,
};

AddOrEditListSectionModal.defaultProps = {
  sectionData: null,
};

export default AddOrEditListSectionModal;
