import { FlamingoContext } from 'contexts/flamingo';
import { useContext, useMemo } from 'react';
import { useCustomQuery } from './useCustomQuery';
import {
  FIND_NETWORK,
} from 'graphql/queries';

const useNetworkList = (options = { skip: true }) => {
  let { skip } = options;
  if (window.location.pathname.split('/')[4] === 'networks') skip = false;
  const { networks, setNetworks } = useContext(FlamingoContext);
  return useCustomQuery(FIND_NETWORK, {
    initialState: [],
    returnFn: useMemo(() => ({ data, error, loading }) => ({ networkList: data, error, loading }), []),
    skipFn: useMemo(() => (data) => (data.length > 0 || networks.length > 0) && skip, [networks.length, skip]),
    useQueryOptions: {
      onCompleted: (results, { data, setData }) => {
        if (
          (networks.length === 0 && data.length === 0)
          || results?.findNetwork?.edges?.length > networks.length
        ) {
          const nodeList = results.findNetwork.edges.map((n) => n.node);
          const list = nodeList
            .filter((n) => n.isActive)
            .sort((a, b) => a.humanName > b.humanName ? 1 : b.humanName > a.humanName ? -1 : 0,
            );
          setData(list);
          setNetworks(list);
        }
      },
    },
  });
};

export default useNetworkList;
