import { gql } from '@apollo/client';
import { fetchApollo } from 'helpers/fetch-util';
import { typeUtil } from 'helpers/module-util';
import {
  fromi18nArrayEntries,
  fromi18nArrayEntriesErr,
  toError,
} from 'helpers/transformer-util';

export const TYPES = typeUtil(['READ_LIST']);

const initialState = {
  page: {
    docs: [],
    status: null,
  },
  referencedBy: {
    isLoading: false,
    references: [],
  },
  selectedList: null,
  flash: null,
  location: null,
  deleteLocation: null,
  availableLocations: [],
};

const fromListRemote = (list) => ({
  ...list,
  site: list.site
    ? { ...list.site, name: fromi18nArrayEntries(list.site.name) }
    : null,
  name: fromi18nArrayEntries(list.name),
  sections: (list.sections || []).map((section) => ({
    ...section,
    name: fromi18nArrayEntries(section.name),
    locations: (section.locations || []).map((location) => ({
      ...location,
      name: fromi18nArrayEntries(location.name),
      children: (location.children?.edges || []).map((child) => ({
        ...child.node,
        name: fromi18nArrayEntries(child.name),
      })),
    })),
  })),
});

const fromListRemoteErr = (errObject, remoteObject) => {
  const result = {};
  if (errObject.name) {
    result.name = fromi18nArrayEntriesErr(errObject.name, remoteObject.name);
  }

  if (Array.isArray(errObject.tags)) {
    result.tags = errObject.tags.map((tag, index) => {
      const remotetag = remoteObject.tags[index];
      return {
        ...tag,
        name: fromi18nArrayEntriesErr(tag.name, remotetag.name),
      };
    });
  } else {
    result.tags = errObject.tags;
  }
  return result;
};

export const transform = (obj, prop, originalResult) => {
  if (prop === 'type') {
    return originalResult.replace(/"/g, '');
  }
  return originalResult;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.READ_LIST:
      return {
        ...state,
        page: {
          ...state.page,
          status: 'loading',
        },
      };
    case TYPES.READ_LIST_SUCCESS:
      return {
        ...state,
        selectedList: null,
        page: {
          ...state.page,
          docs: action.result?.edges.map((list) => fromListRemote(list.node)),
          status: 'responded',
        },
      };
    case TYPES.READ_LIST_ERROR:
      return {
        ...state,
        selectedListLoading: false,
        flash: {
          error: true,
          status: 'error',
          ...toError(action.result, (errObject) => fromListRemoteErr(errObject, action.remoteObject),
          ),
        },
      };
    default:
      return state;
  }
};

export const getLists = (params) => (dispatch) => fetchApollo({
    doc: gql`
      query findNetworkCuratedList {
        findNetworkCuratedList(
          where: { sections: { locations: { designation: "*" } } }
        ) {
          edges {
            node {
              ... on NetworkCuratedList {
                id
                name
                network {
                  id
                  name
                }
                sections {
                  id
                  name
                  locations {
                    id
                    name
                    children {
                      edges {
                        node {
                          id
                          name
                          order
                          children {
                            edges {
                              node {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    method: 'query',
    queryName: 'findNetworkCuratedList',
    type: TYPES.READ_LIST,
    params,
    dispatch,
  });
