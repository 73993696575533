import textField from 'components/_deprecated/reduxTextField';
import { UriRegexp, UrlRegexp } from 'helpers/string-util';
import { memoize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

export const requiredValidator = (value) => (value && value.toString().length) > 0 ? undefined : 'Required';

const maxLengthValidator = memoize(
  (max) => (value) => value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined,
);

const phoneValidator = (value) => {
  if (
    !/^(((\([2-9]\d{2}\) ?)|([2-9]\d{2}-))\d{3}-\d{4})|([2-9]\d{9})/.test(value)
  ) return 'Must be a valid ten digit phone number';

  return undefined;
};

const urlValidator = (value) => {
  if (!UrlRegexp.test(value)) return 'Must be a valid URL';

  return undefined;
};

const uriValidator = (value) => {
  if (!UriRegexp.test(value)) return 'Must be a valid URI';

  return undefined;
};

const noSpecialCharValidator = (value) => {
  if (!/^[\w-_ ]+$/.test(value)) {
    return 'Must only be alpha numeric characters';
  }

  return undefined;
};

const ReduxField = React.memo(
  ({
    name,
    label,
    component,
    classes,
    required,
    charLimit,
    phone,
    uri,
    url,
    alphaNum,
    customValidators,
    ...custom
  }) => {
    const validators = customValidators ? [...customValidators] : [];
    if (required) validators.push(requiredValidator);
    if (charLimit) validators.push(maxLengthValidator(charLimit));
    if (phone) validators.push(phoneValidator);
    if (uri) validators.push(uriValidator);
    if (url) validators.push(urlValidator);
    if (alphaNum) validators.push(noSpecialCharValidator);

    return (
      <div className={classes}>
        <Field
          name={name}
          label={label}
          component={component}
          validate={validators}
          charLimit={charLimit}
          type="text"
          {...custom}
        />
      </div>
    );
  },
);

ReduxField.defaultProps = {
  required: false,
  component: textField,
  customValidators: [],
};
ReduxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  component: PropTypes.func,
  custom: PropTypes.object,
  customValidators: PropTypes.array,
};
export default ReduxField;
