import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { LargeToggleButton, LargeToggleButtonGroup } from 'components/toggleButtonGroup/toggleButtonGroup';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import validate from 'pages/typeaheads/containers/validate';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

const MatchByFieldComp = (props) => {
  const { customTags, input } = props;

  const handleChange = (e, val) => {
    input.onChange(val);
  };

  return (
    <LargeToggleButtonGroup
      value={input.value}
      exclusive
      onChange={handleChange}
    >
      <LargeToggleButton
        value="tag"
        style={{ height: '85px' }}
        {...customTags.tags}
      >
        <LabelOutlined />
        <Box>Tags</Box>
      </LargeToggleButton>
      <LargeToggleButton
        value="numeric"
        style={{ height: '85px' }}
        {...customTags.number}
      >
        <FormatListNumbered />
        <Box>Numbers</Box>
      </LargeToggleButton>
    </LargeToggleButtonGroup>
  );
};

class StepperSearch extends React.Component {
  render() {
    const { classes, valid } = this.props;
    const customTags = {
      tags: {
        'data-test': 'typeaheadStep2TagsButton',
      },
      number: {
        'data-test': 'typeaheadStep2NumberButton',
      },
      next: {
        'data-test': 'typeaheadStep2NextButton',
      },
      back: {
        'data-test': 'typeaheadStep2BackButton',
      },
      cancel: {
        'data-test': 'typeaheadStep2CancelButton',
      },
    };

    return (
      <Box>
        <Typography className={classes.title}>
          What are users searching?
        </Typography>
        <Typography className={classes.contentText}>
          Tags are generally the most helpful to surface a specific typeahead
          rule. However if the user's search includes a number, like a patient
          room or address, "Numbers" is a better option.
        </Typography>
        <Field
          name="matchBy"
          component={MatchByFieldComp}
          customTags={customTags}
        />
        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={this.props.handleCancel}
            {...customTags.cancel}
          >
            CANCEL
          </Button>
          <Box sx={{ float: 'right', display: 'inline-block' }}>
            <Button
              variant="outlined"
              onClick={this.props.handleBack}
              {...customTags.back}
            >
              BACK
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!valid}
              color="primary"
              {...customTags.next}
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect(
    ({ form }) => ({
      typeaheadForm: form.typeaheadForm,
    }),
    (dispatch) => ({
      dispatch,
    }),
  ),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: false,
    validate,
    enableReinitialize: false,
  }),
  withStyles(StepStyles),
)(StepperSearch);
