import { Box, Grid, LinearProgress, Link, Typography } from '@mui/material';
import AreaChart from 'components/analyticsDashboardCard/charts/areaChart';
import PieChart from 'components/analyticsDashboardCard/charts/pieChart';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import Loading from 'components/loading/loading';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export const ANALYTICS_DASHBOARD_CHART_TYPES = Object.freeze({
  AREA: 'area',
  PIE: 'pie',
  TABLE: 'table',
});

const buildStyles = () => ({
  root: {
    background: ColorPalette.white,
    borderRadius: '20px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 20px 31px 20px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  count: {
    marginTop: '10px',
  },
  tooltip: {
    maxWidth: '250px',
  },
  link: {
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '20px',
    textAlign: 'center',
    width: '100%',
  },
  pieLink: {
    marginTop: '5px',
  },
});

const getDateRange = (numOfDays) => {
  switch (numOfDays) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    case 30:
      return 'Last Month';
    default:
      return `${numOfDays} Days`;
  }
};

const AnalyticsTableRow = ({ children }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    wrap="nowrap"
    sx={{
      borderBottom: `1px solid ${ColorPalette.grey[300]}`,
      paddingBottom: '16px',
      paddingTop: '16px',
    }}
  >
    {children}
  </Grid>
);
const AnalyticsTableCell = ({ children }) => (
  <Grid
    item
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '50%',
    }}
  >
    {children}
  </Grid>
);

const AnalyticsDashboardCard = ({
                                  chartHeading,
                                  chartText,
                                  chartType,
                                  colors,
                                  count,
                                  data,
                                  dataKey,
                                  dataLabel,
                                  dateRangeLabel,
                                  itemLabel,
                                  linkText,
                                  nameKey,
                                  numOfDays,
                                  title,
                                  tooltip,
                                  height,
                                  width,
                                  onClick,
                                  renderLegend,
                                }) => {
  const styles = buildStyles();
  const hasCount = !!count;

  const chartRender = useMemo(() => {
    if (chartType === ANALYTICS_DASHBOARD_CHART_TYPES.AREA) {
      return (
        <>
          <AreaChart data={data} dataKey={dataKey} width={360} height={178} />
          {chartHeading && (
            <Typography
              variant="subtitle1"
              sx={{ color: ColorPalette.grey[600], marginTop: '16px' }}
            >
              {chartHeading}
            </Typography>
          )}
          {chartText && <Typography variant="body2">{chartText}</Typography>}
        </>
      );
    }

    if (chartType === ANALYTICS_DASHBOARD_CHART_TYPES.PIE) {
      if (data?.length) {
        return (
          <PieChart
            sx={{
              '& div:nthChild(2)': {
                // legend
                marginTop: hasCount ? 0 : '21px',
              },
            }}
            colors={colors}
            data={data}
            dataKey={dataKey}
            dataLabel={dataLabel}
            nameKey={nameKey}
            width={300}
            height={218}
            renderLegend={renderLegend}
          />
        );
      }
    }

    if (chartType === ANALYTICS_DASHBOARD_CHART_TYPES.TABLE) {
      if (!data) return null;
      const dataMap = data.reduce(
        (a, b) => ({
          ...a,
          [b[dataLabel]]: b[dataKey],
          total: a.total + b[dataKey],
        }),
        {
          total: 0,
        },
      );

      return (
        <>
          <AnalyticsTableRow>
            <AnalyticsTableCell>
              <Typography
                sx={{
                  color: ColorPalette.text.primary,
                  fontSize: '12px',
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  textTransform: 'uppercase',
                }}
              >
                {itemLabel || 'Item Name'}
              </Typography>
            </AnalyticsTableCell>
            <AnalyticsTableCell>
              <Typography
                sx={{
                  color: ColorPalette.text.primary,
                  fontSize: '12px',
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  textTransform: 'uppercase',
                }}
              >
                Event Clicks
              </Typography>
            </AnalyticsTableCell>
          </AnalyticsTableRow>
          {data
            ? data.map((item, i) => (
              <AnalyticsTableRow key={`analytics-table-row-${i}`}>
                <AnalyticsTableCell>{item[dataLabel]}</AnalyticsTableCell>
                <AnalyticsTableCell>
                  <Box alignItems="center" display="flex">
                    <Box mr={1} width="100%">
                      <LinearProgress
                        sx={{
                          backgroundColor: ColorPalette.grey[100],
                          color: ColorPalette.blue[400],
                          borderRadius: '5px',
                          height: '12px',
                        }}
                        value={Number(
                          (dataMap[item[dataLabel]] / dataMap.total) * 100,
                        ).toFixed(0)}
                        variant="determinate"
                      />
                    </Box>
                    <Box minWidth={75}>
                      <Typography>
                        {item[dataKey].toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </AnalyticsTableCell>
              </AnalyticsTableRow>
            ))
            : null}
        </>
      );
    }

    return null;
  }, [
    chartHeading,
    chartText,
    chartType,
    colors,
    data,
    dataKey,
    dataLabel,
    hasCount,
    itemLabel,
    nameKey,
    renderLegend,
  ]);

  return (
    <Box
      sx={{
        ...styles.root,
        height: `${height || 455}px`,
        width: width ? `${width}px` : '100%',
      }}
    >
      <Box sx={styles.titleSection}>
        <Typography variant="subtitle1">{title}</Typography>
        <InfoTooltip title={tooltip || title} />
      </Box>
      {!data && <Loading backgroundColor={ColorPalette.white} />}
      {data && (
        <>
          {count && (
            <Typography variant="h3" className="hero" sx={styles.count}>
              {count?.toLocaleString() ?? 'N/A'}
            </Typography>
          )}
          <Typography variant="body2" sx={{ marginTop: hasCount ? '8px' : 0 }}>
            {dateRangeLabel || getDateRange(numOfDays)}
          </Typography>
          <Box sx={!count ? { marginTop: '29px' } : {}}>{chartRender}</Box>
          {onClick && (
            <Box
              sx={{
                ...styles.link,
                ...chartType === ANALYTICS_DASHBOARD_CHART_TYPES.PIE
                  && styles.pieLink,
              }}
            >
              <Link onClick={onClick}>{linkText}</Link>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

AnalyticsDashboardCard.propTypes = {
  chartType: PropTypes.oneOf(Object.values(ANALYTICS_DASHBOARD_CHART_TYPES)),
  chartHeading: PropTypes.string,
  chartText: PropTypes.string,
  colors: PropTypes.array,
  count: PropTypes.number,
  data: PropTypes.array,
  dataKey: PropTypes.string,
  dateRangeLabel: PropTypes.string,
  itemLabel: PropTypes.string,
  linkText: PropTypes.string,
  nameKey: PropTypes.string,
  numOfDays: PropTypes.number,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  renderLegend: PropTypes.func,
};

AnalyticsDashboardCard.defaultProps = {
  chartHeading: null,
  chartText: null,
  chartType: ANALYTICS_DASHBOARD_CHART_TYPES.AREA,
  colors: null,
  count: null,
  data: [],
  dataKey: null,
  dateRangeLabel: '',
  itemLabel: 'Item Name',
  linkText: 'View Details',
  nameKey: null,
  numOfDays: null,
  tooltip: '',
  onClick: null,
  renderLegend: null,
};

export default React.memo(AnalyticsDashboardCard);
