import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AreaChart from 'components/analyticsDashboardCard/charts/areaChart';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import Loading from 'components/loading/loading';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import { DATE_FORMAT, retrieveAnalyticsFilter } from 'helpers/analytics-util';
import { colorWithAlpha } from 'helpers/color-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { MORE_MENU_USAGE_TABLE_NAME } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useAnalytics } from 'hooks/dataHooks/useAnalytics';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AnalyticsFilters, { ANALYTICS_FILTER_MAP } from './containers/analyticsFilters';
import AnalyticsDownloadButton from './containers/downloadButton';

const buildStyles = ({ theme }) => ({
  handleBack: {
    marginTop: '-16px !important',
  },
  loading: {
    height: '100%',
  },
  chartContainer: {
    background: theme.palette.white,
    borderRadius: '20px',
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    height: '546px',
    padding: '20px 20px 31px 20px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRange: {
    marginTop: '4px',
  },
  count: {
    fontSize: '34px',
    marginTop: '10px',
  },
  tableContent: {
    height: 'calc(100vh - 766px)',
    minHeight: '400px',
    marginTop: '12px',
    marginBottom: '56px',
    position: 'relative',
  },
  customTooltip: {
    background: theme.palette.grey[600],
    borderRadius: '4px',
    padding: '12px 12px',
    maxWidth: '250px',

    '& p': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: 0,
    },
  },
  tooltipContainer: {
    '& span': {
      color: theme.palette.white,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'bold',
      margin: '8px 0 0 0',
    },
  },
  tooltipHeading: {
    color: theme.palette.white,
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '8px 0 0 0',
    width: '48px',
  },
  emptyContainer: {
    height: '100%',
  },
  xAxisLabel: {
    color: theme.palette.grey[400],
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    marginLeft: '71px',
    marginTop: '1px',
    textAlign: 'center',
  },
});

export const MoreMenuUsagePage = () => {
  const COLUMNS = useMemo(
    () => [
      {
        Header: 'More Menu Item',
        accessor: 'name',
        minWidth: 80,
        width: 80,
      },
      {
        Header: 'Event Clicks',
        id: 'clicks',
        accessor: (a) => a.clicks.toLocaleString(),
        minWidth: 100,
        width: 100,
        defaultSort: 'asc',
        sortType: (a, b) => Number(a.original.clicks) > Number(b.original.clicks) ? -1 : 1,
      },
    ],
    [],
  );

  const { width } = useWindowSize();
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const { handleNavigate } = useContext(FlamingoContext);
  const { filter, rangeStart, rangeEnd } = retrieveAnalyticsFilter();
  const [dateRangeKey, setDateRangeKey] = useState(null);
  const [dateRangeText, setDateRangeText] = useState(null);

  const backToOverview = useCallback(() => {
    handleNavigate(`/network/${networkId}/analytics`);
  }, [handleNavigate, networkId]);

  const { data: usageData, fetch: fetchUsageData } = useAnalytics({
    networkId,
    endpoint: 'usage',
    detailed: true,
    start: rangeStart,
    end: rangeEnd,
  });

  const userRole = useCheckRole();
  const hasPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(userRole);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload ?? {};
      return (
        <Box sx={styles.customTooltip}>
          <p>Number of Clicks</p>
          <Box sx={styles.tooltipContainer}>
            <Box sx={styles.tooltipHeading}>Clicks</Box>
            <span>{data.clicks.toLocaleString()}</span>
          </Box>
          <Box sx={styles.tooltipContainer}>
            <Box sx={styles.tooltipHeading}>Date</Box>
            <span>{data.fullDate}</span>
          </Box>
        </Box>
      );
    }

    return null;
  };

  const chartData = useMemo(
    () => usageData?.moreMenu?.byDay?.map(({ clicks, day }) => ({
        clicks,
        day: dayjs(day).format('MMM D'),
        fullDate: dayjs(day).format('MMM D, YYYY'),
      })),
    [usageData],
  );

  const tableData = useMemo(
    () => usageData?.moreMenu?.items.sort((a, b) => a.clicks > b.clicks ? 1 : -1,
      ) || null,
    [usageData],
  );

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName={
          <Box>
            More Menu Usage
            <Box
              sx={{
                display: 'inline-block',
                marginLeft: '8px',
                verticalAlign: 'middle',
              }}
            >
              <InfoTooltip
                title={
                  <Box>
                    More Menu Usage tracks user interactions with the "More"
                    quick access button's Menu Items within your mobile
                    application, revealing which items are clicked most over
                    time.
                  </Box>
                }
              />
            </Box>
          </Box>
        }
        handleBack={() => backToOverview()}
        handleBackSx={styles.handleBack}
        handleBackTitle="Back to Analytics Overview"
        headerButtons={
          <AnalyticsFilters
            value={filter}
            start={rangeStart}
            end={rangeEnd}
            onChange={async ({ start, end, value }) => {
              if (dateRangeKey !== value || value === 'custom') {
                setDateRangeKey(dateRangeText ?? value);
                if (value === 'custom') {
                  setDateRangeText(
                    `${dayjs(start).format(DATE_FORMAT)} - ${dayjs(end).format(
                      DATE_FORMAT,
                    )}`,
                  );
                } else {
                  setDateRangeText(null);
                }
                await fetchUsageData({
                  start,
                  end,
                  detailed: true,
                });
              }
            }}
          />
        }
      >
        <Grid
          alignContent="flex-start"
          alignItems="flex-start"
          container
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            <Box sx={{ ...styles.chartContainer, width: `${width - 299}px` }}>
              <Box sx={styles.titleSection}>
                <Typography variant="subtitle1">
                  More Menu Usage Over Time
                </Typography>
                <AnalyticsDownloadButton
                  dataType="MoreItemSelected"
                  endpoint="usage"
                  fileNameDetail="more_menu_usage"
                  networkId={networkId}
                  range={{
                    start: rangeStart,
                    end: rangeEnd,
                  }}
                />
              </Box>
              {!chartData && (
                <Grid container sx={styles.loading}>
                  <Grid item xs={12}>
                    <Loading backgroundColor={ColorPalette.white} />
                  </Grid>
                </Grid>
              )}
              {chartData && (
                <Grid container sx={{ marginTop: '6px' }}>
                  <Grid item>
                    <Typography variant="h3" sx={styles.count}>
                      {usageData?.totalMoreMenuClickEvents.toLocaleString()
                        ?? 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={styles.dateRange}>
                      {dateRangeText ?? ANALYTICS_FILTER_MAP[dateRangeKey]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AreaChart
                      data={chartData}
                      dataKey="clicks"
                      xAxisKey="day"
                      yAxisLabel="Number of Clicks"
                      yAxisKey="clicks"
                      width={width - 370}
                      height={370}
                      maxXAxisLabels={14}
                      showLines={true}
                      showTooltip={true}
                      customTooltip={<CustomTooltip />}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.xAxisLabel}>
                    Date
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sx={styles.tableContent}>
            <GozioTable
              name={MORE_MENU_USAGE_TABLE_NAME}
              countTitle="More Menu Usage"
              loading={!tableData}
              columns={COLUMNS}
              data={tableData || []}
              hidePagination={true}
              sortBy={[{ id: 'clicks', desc: false }]}
              emptyContent={
                <Box sx={styles.emptyContainer}>
                  <EmptyPanel
                    large
                    title="There is no More Menu usage data available."
                    showImage={false}
                  />
                </Box>
              }
              sx={{
                borderRadius: '20px',
                boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
                  ColorPalette.grey[600],
                  0.4,
                )}`,
              }}
            />
          </Grid>
        </Grid>
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(MoreMenuUsagePage);
