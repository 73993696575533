import { Grid, Typography } from '@mui/material';
import GenericFormModal from 'components/genericModal/genericFormModal';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const buildStyles = () => ({
	root: {
		'& .MuiDialog-paper': {
			maxWidth: '682px', // per SCM-2590
		},
	},
});

const AddStreetNumberModal = ({
	handleClose,
	handleConfirm,
}) => {
	const styles = buildStyles();

	return (
		<GenericFormModal
			title={null}
			sx={styles.root}
			confirmText="Correct Error"
			handleClose={handleClose}
			handleConfirm={handleConfirm}
			disableCancel
			spaceBetweenDialogActions={false}
			formParams={{
				onSubmit: handleConfirm,
				initialValues: {
					streetNumber: null,
				},
			}}
			body={({ form }) => (
				<Grid container direction="column">
					<Grid item xs={12}>
						<Typography variant="h1">
							Street Number Error
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '4px' }}>
						<Typography variant="h5">
							Google is having a hard time finding the street number from the address you've selected. Please re-enter the desired street address number and we will correct the issue.
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '32px' }}>
						<Field name="streetNumber">
							{({ input, meta }) => (
								<TextField
									label={getFormLabel('Street Address Number', true)}
									charLimit={40}
									helperText={meta.touched ? meta.error : ''}
									error={meta.touched && !!meta.error}
									{...input}
									fullWidth
								/>
							)}
						</Field>
					</Grid>
				</Grid>
			)}
		/>
	);
};

AddStreetNumberModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	handleConfirm: PropTypes.func.isRequired,
};

export default AddStreetNumberModal;
