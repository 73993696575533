import { Add, CheckCircle, Delete } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import { SCOPES } from 'components/authorization/authorize';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GenericModal from 'components/genericModal/genericModal';
import InfoPanel from 'components/infoPanel/infoPanel';
import ProgressCard from 'components/progressCard/progressCard';
import GozioTable from 'components/tables/gozioTable';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { getTableCountTitle } from 'helpers/table-util';
import { filterLangs, toi18nArrayEntries } from 'helpers/transformer-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useCreateExplorePanel, useDeleteExplorePanel, useExplorePanels } from 'hooks/dataHooks/useExplorePanel';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkspace } from 'hooks/useWorkspace';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AddPanelModal from 'pages/mobileSettings/explorePanel/containers/AddExplorePanelModal';
import {
  buildTableColumns,
  DEFAULT_EXPLORE_PANEL_BACKGROUND_COLOR,
  EXPLORE_PANEL_MODAL_CUSTOM_TAGS,
} from 'pages/mobileSettings/explorePanel/explorePanelHelper';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { memo, useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    marginBottom: spacing(2),
  },
  actions: {
    justifyContent: 'end',
    position: 'relative',
  },
  cardData: {
    color: palette.text.primary,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  infoPanel: {
    marginBottom: spacing(3),
    '& > div': {
      width: '100%',
    },
  },
  tableHeader: {
    margin: `${spacing(3)} ${spacing(2)}`,
    marginTop: 0,
  },
  emptyPanel: {
    height: 'calc(100% - 110px)',
    width: '100%',
    padding: '0 24px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successIcon: {
    color: palette.success.light,
  },
}));

const ExplorePanelPageContent = () => {
  const classes = useStyles();
  const { networkId } = useParams();
  useWindowSize();
  const userRole = useCheckRole();
  const { workspace } = useWorkspace();
  const { activeNetwork, authorize, handleNavigate }
    = useContext(FlamingoContext);

  const {
    data: explorePanels,
    count,
    refetch: refetchExplorePanels,
  } = useExplorePanels(networkId);
  const [createExplorePanel] = useCreateExplorePanel(networkId);
  const [deleteExplorePanel] = useDeleteExplorePanel(networkId);

  const [selectedPanel, setSelectedPanel] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const sitesAvailable = useMemo(
    () => typeof count.explorePanels !== 'undefined'
      && typeof count.sites !== 'undefined'
        ? count.explorePanels < count.sites
        : false,
    [count],
  );

  const allTableData = useMemo(
    () => explorePanels?.map((panel) => {
        const panelName = getLabelByLang(panel.name);
        const siteName = getLabelByLang(panel.site?.name);
        return {
          id: panel.id,
          name: panelName,
          site: siteName,
          status: capitalize(panel?.validation?.status),
          type: panel.type,
          workspace: `${capitalize(panel.workspace)} Workspace`,
          actions: (
            <Box className={classes.actions}>
              <Box className="hoverUnhide">
                {!(
                  panel.workspace === LIVE_WORKSPACE
                  && workspace === DRAFT_WORKSPACE
                ) && (
                  <Tooltip title="Delete">
                    <Delete
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedPanel(panel);
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
          ),
        };
      }),
    [classes, explorePanels, workspace],
  );

  const tableData = useMemo(
    () => allTableData?.length
        ? allTableData.filter((panel) => panel.type !== 'network')
        : [],
    [allTableData],
  );

  const networkDefaultExplorePanel = useMemo(
    () => allTableData.find((panel) => panel.type === 'network'),
    [allTableData],
  );

  const handleAdd = async ({ panel, values }) => {
    await createExplorePanel({
      variables: {
        input: {
          name: [
            {
              lang: LANGUAGE_CODES.ENGLISH,
              label: values.name,
            },
          ],
          type: 'site',
          site: values.site,
          color: panel.color || DEFAULT_EXPLORE_PANEL_BACKGROUND_COLOR,
          template: panel.template || 'four_actions',
          actions: panel.actions?.map((a) => ({
            name: Array.isArray(a.name)
              ? a.name
              : filterLangs(toi18nArrayEntries(a.name)),
            order: a.order,
            type: a.type || 'category',
            icon: a?.icon?.image?.id ?? null,
            list: a.list ? a.list.id : null,
            categories: a.categories ?? null,
            filterLinkDestBySite: a.filterLinkDestBySite || false,
            custom: a.custom || null,
          })),
        },
      },
    });

    await refetchExplorePanels();
    setShowAddModal(false);
  };

  const handleDeletePanel = async () => {
    await deleteExplorePanel({
      variables: {
        id: selectedPanel.id,
      },
    });

    await refetchExplorePanels();
    setShowDeleteModal(false);
    setSelectedPanel(null);
  };

  if (userRole !== USER_ROLES.UBER_ADMIN && workspace === DRAFT_WORKSPACE) {
    return <Navigate to="/" />;
  }

  return (
    <FlamingoPage
      pageName="Explore Panels"
      headerButtons={
        <AddCircleButton
          disabled={!sitesAvailable || !authorize(SCOPES.EXPLORE_PANEL.CREATE)}
          onClick={() => setShowAddModal(true)}
          tooltipTitle="Add an Explore Panel"
        />
      }
    >
      <Box className={classes.content}>
        <GozioTable
          sx={{
            borderRadius: '20px',
            boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
              ColorPalette.grey[600],
              0.4,
            )}`,
            height: 'calc(100vh - 186px)',
            paddingBottom: '8px',
          }}
          columns={buildTableColumns({
            explorePanels,
            navigate: handleNavigate,
            networkId,
            workspace,
          })}
          data={tableData}
          countTitle={getTableCountTitle(tableData?.length, 'Explore Panel')}
          hidePagination={false}
          emptyContent={
            <Box className={classes.emptyPanel}>
              <EmptyPanel
                large
                title="You Have No Site Explore Panels"
                subtitle="Start creating Site Explore Panels"
                buttonShown={true}
                buttonLabel="Add A Site Explore Panel"
                ButtonIcon={Add}
                buttonClicked={() => setShowAddModal(true)}
              />
            </Box>
          }
          headerContent={
            <Box className={classes.tableHeader}>
              {!sitesAvailable && explorePanels.length > 0 && (
                <Box className={classes.infoPanel}>
                  <InfoPanel
                    title={
                      'You are unable to create more Explore Panels at this time. All Sites have been assigned to an Explore Panel. To add more Explore Panels, add a new Site.'
                    }
                  />
                </Box>
              )}
              {workspace === LIVE_WORKSPACE ? (
                <ProgressCard
                  large
                  title={networkDefaultExplorePanel?.name}
                  description={`The Network Default Explore Panel is ${networkDefaultExplorePanel?.status}`}
                  icon={
                    networkDefaultExplorePanel?.status === 'Complete' && (
                      <CheckCircle
                        className={classes.successIcon}
                        sx={{ color: 'inherit' }}
                      />
                    )
                  }
                  onClick={(data) => {
                    const networkPanel = explorePanels.filter(
                      (panel) => panel.type === 'network',
                    )[0];
                    handleNavigate(
                      `/network/${networkId}/explore/${networkPanel.id}`,
                    );
                  }}
                />
              ) : (
                <ProgressCard
                  large
                  actionable={false}
                  title={networkDefaultExplorePanel?.name}
                  description={`Is ${networkDefaultExplorePanel?.status}`}
                  icon={
                    networkDefaultExplorePanel?.status === 'Complete' && (
                      <CheckCircle
                        className={classes.successIcon}
                        sx={{ color: 'inherit' }}
                      />
                    )
                  }
                  status="inactive"
                  className={classes.progressCard}
                >
                  <Typography className={classes.cardData}>
                    Editable in the Live Workspace
                  </Typography>
                </ProgressCard>
              )}
            </Box>
          }
        />
        {showAddModal && (
          <AddPanelModal
            handleClose={() => setShowAddModal(false)}
            handleConfirm={(values, panel) => handleAdd({ values, panel })}
            panels={explorePanels || []}
            networkName={activeNetwork?.humanName || 'Global Template'}
            networkLogo={activeNetwork?.logo?.url}
          />
        )}
        <GenericModal
          title="Delete Explore Panel"
          body={`If this Explore Panel is deleted, ${getLabelByLang(
            selectedPanel?.site?.name,
          )} will use the Network Default Explore Panel. Are you sure you want to delete the Explore Panel ${getLabelByLang(
            selectedPanel?.name,
          )}?`}
          confirmText="Delete Explore Panel"
          open={showDeleteModal}
          handleClose={() => {
            setShowDeleteModal(false);
            setSelectedPanel(null);
          }}
          handleConfirm={handleDeletePanel}
          customTags={EXPLORE_PANEL_MODAL_CUSTOM_TAGS}
        />
      </Box>
    </FlamingoPage>
  );
};

const ExplorePanelPage = () => (
  <LoggedinLayout enableSuspense={true}>
    <ExplorePanelPageContent />
  </LoggedinLayout>
);

export default memo(ExplorePanelPage);
