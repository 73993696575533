import { useMutation, useSuspenseQuery } from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import { fromi18nArrayEntries } from 'helpers/transformer-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useWorkspace } from 'hooks/useWorkspace';
import {
  CREATE_NETWORK_EXPLORE_PLANEL,
  DELETE_NETWORK_EXPLORE_PANEL,
  FIND_NETWORK_EXPLORE_PANEL,
  FIND_SYSTEM_EXPLORE_PANEL,
  GET_NETWORK_EXPLORE_PANEL,
  REPLACE_NETWORK_EXPLORE_PANEL_ACTION,
  REPLACE_SYSTEM_EXPLORE_PANEL_ACTION,
  UPDATE_NETWORK_EXPLORE_PANEL,
  UPDATE_SYSTEM_EXPLORE_PANEL,
} from 'graphql/queries';

const fromPanelRemote = (panel) => ({
  ...panel,
  name: fromi18nArrayEntries(panel.name),
  site: panel.site
    ? {
      ...panel.site,
      name: fromi18nArrayEntries(panel.site.name),
    }
    : null,
  siteId: panel.site ? panel.site.id : null,
  workspace: panel.workspaces[0].name,
});

export const useCreateExplorePanel = () => {
  const { handleError } = useHandleError('ExplorePanel');
  const { toastNotificationSuccessHook } = useToast();

  return useMutation(CREATE_NETWORK_EXPLORE_PLANEL, {
    onCompleted: (data) => {
      toastNotificationSuccessHook('The Explore Panel has been created.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, null, null, {
        statusHandlers: {
          409: ({ extensions }) => [
            true,
            'An explore panel with this name already exists. Try a different name.',
          ],
        },
      }),
    refetchQueries: [{ query: FIND_NETWORK_EXPLORE_PANEL }],
  });
};

export const useDeleteExplorePanel = () => {
  const { handleError } = useHandleError('ExplorePanel');
  const { toastNotificationSuccessHook } = useToast();

  return useMutation(DELETE_NETWORK_EXPLORE_PANEL, {
    onCompleted: (data) => {
      toastNotificationSuccessHook('The Explore Panel has been deleted.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK_EXPLORE_PANEL }],
  });
};

export const useSystemExplorePanel = (networkId) => {
  const { handleError } = useHandleError('ExplorePanel');
  const { data, error, refetch } = useSuspenseQuery(FIND_SYSTEM_EXPLORE_PANEL, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: !isGlobalTemplate(networkId),
  });

  return {
    data: data?.findSystemExplorePanel?.edges[0]?.node,
    error,
    refetch,
  };
};

export const useUpdateSystemExplorePanel = (networkId) => {
  const { handleError } = useHandleError('ExplorePanel');
  const { toastNotificationSuccessHook } = useToast();

  return useMutation(UPDATE_SYSTEM_EXPLORE_PANEL, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'Your progress on the System Explore Panel has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_SYSTEM_EXPLORE_PANEL }],
    skip: !isGlobalTemplate(networkId),
  });
};

export const useUpdateSystemExplorePanelAction = () => {
  const { handleError } = useHandleError('ExplorePanel');

  return useMutation(REPLACE_SYSTEM_EXPLORE_PANEL_ACTION, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_SYSTEM_EXPLORE_PANEL }],
  });
};

export const useExplorePanel = (
  networkId,
  panelId,
) => {
  const { handleError } = useHandleError('ExplorePanel');

  const { data, error, refetch } = useSuspenseQuery(GET_NETWORK_EXPLORE_PANEL, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
    variables: {
      id: panelId,
    },
  });

  return {
    data: data?.getNetworkExplorePanel,
    error,
    refetch,
  };
};

export const useExplorePanels = (networkId) => {
  const { handleError } = useHandleError('ExplorePanel');
  const { workspace } = useWorkspace();

  const { data, error, refetch } = useSuspenseQuery(FIND_NETWORK_EXPLORE_PANEL, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
    variables: {
      workspaces: [workspace],
    },
  });

  return {
    data: data?.findNetworkExplorePanel?.edges?.map((n) => fromPanelRemote(n.node),
    ),
    count: {
      explorePanels: data?.findNetworkExplorePanelCount?.count,
      sites: data?.findNetworkPlaceCount?.count,
    },
    error,
    refetch,
  };
};

export const useUpdateExplorePanel = (networkId, explorePanelName) => {
  const { handleError } = useHandleError('ExplorePanel');
  const { toastNotificationSuccessHook } = useToast();

  return useMutation(UPDATE_NETWORK_EXPLORE_PANEL, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        `Your progress on the Explore Panel ${explorePanelName} has been saved.`,
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK_EXPLORE_PANEL }],
  });
};

export const useUpdateExplorePanelAction = () => {
  const { handleError } = useHandleError('ExplorePanel');

  return useMutation(REPLACE_NETWORK_EXPLORE_PANEL_ACTION, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK_EXPLORE_PANEL }],
  });
};
