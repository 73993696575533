export const generateRandomString = (length) => {
  let result = '';
  const characters
    = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charLen = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charLen));
    counter += 1;
  }
  return result;
};

/**
 * Merges a list of maps in an array of object based on the field name.
 *
 * @param list {Object[]} the list of Objects
 * @param fieldName {String} the field name
 * @param functionProps {Object} the list of arguments if the field value is a function
 */
export const mergeMaps = (list, fieldName, functionProps = {}) => {
  let mergedMap = {};
  list.forEach((item) => {
    if (item.hasOwnProperty(fieldName)) {
      if (typeof item[fieldName] === 'function') {
        mergedMap = { ...mergedMap, ...item[fieldName](functionProps) };
      } else {
        mergedMap = { ...mergedMap, ...item[fieldName] };
      }
    }
  });
  return mergedMap;
};
