import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

function SplitButton({
  className,
  popperClassName,
  options,
  handleButtonClick,
  handleChangeState,
  transformLabel,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    handleButtonClick(options[selectedIndex].action);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    handleChangeState(options[index].action);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid item xs className={className}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          disabled={options[selectedIndex].disabled}
          onClick={handleClick}
          {...rest}
        >
          {transformLabel
            ? transformLabel(options[selectedIndex].name)
            : options[selectedIndex].name}
        </Button>
        <Button
          color="primary"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select other options"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {anchorRef.current && (
        <Popper
          className={popperClassName}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        disabled={option.disabled}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Grid>
  );
}

SplitButton.propTypes = {
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  options: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func,
  handleChangeState: PropTypes.func,
  transformLabel: PropTypes.func,
};

SplitButton.defaultProps = {
  className: '',
  popperClassName: '',
  handleButtonClick: () => {},
  handleChangeState: () => {},
  transformLabel: null,
};

export default SplitButton;
