import { AnalyticsPage } from 'pages/analytics/analyticsPage';
import { DashboardCardUsagePage } from 'pages/analytics/dashboardCardUsagePage';
import { MobileInstallationsPage } from 'pages/analytics/mobileInstallationsPage';
import { MoreMenuUsagePage } from 'pages/analytics/moreMenuUsagePage';
import { NavigationEventsPage } from 'pages/analytics/navigationEventsPage';
import { SessionsPage } from 'pages/analytics/sessionsPage';
import { TotalUsersPage } from 'pages/analytics/totalUsersPage';
import CategoriesPage from 'pages/categories/CategoriesPage';
import CategoryGroupPage from 'pages/categories/CategoryGroupPage';
import CategoryPage from 'pages/categories/CategoryPage';
import NetworksPage from 'pages/configuration/NetworksPage';
import SystemEnvironmentsPage from 'pages/configuration/SystemEnvironmentsPage';
import DeepLinksConfigurationPage from 'pages/deepLinks/DeepLinksConfigurationPage';
import DeepLinksLinkManagerPage from 'pages/deepLinks/DeepLinksLinkManagerPage';
import HomePage from 'pages/home/HomePage';
import KioskDetailPage from 'pages/kiosks/KioskDetailPage';
import KiosksPage from 'pages/kiosks/KiosksPage';
import KioskTemplatePage from 'pages/kiosks/KioskTemplatePage';
import ListDetailPage from 'pages/lists/ListDetailPage';
import ListsPage from 'pages/lists/ListsPage';
import BuildingDetailPage from 'pages/locations/BuildingDetailPage';
import FloorDetailPage from 'pages/locations/FloorDetailPage';
import LandmarkDetailPage from 'pages/locations/LandmarkDetailPage';
import LocationsPage from 'pages/locations/LocationsPage';
import PoiDetailPage from 'pages/locations/PoiDetailPage';
import SiteDetailPage from 'pages/locations/SiteDetailPage';
import LoggedOutPage from 'pages/LoggedOutPage';
import Login from 'pages/login';
import BannerMessagePage from 'pages/messaging/BannerMessagePage';
import PushNotificationPage from 'pages/messaging/PushNotificationsPage';
import BottomNavigationPage from 'pages/mobileSettings/bottomNavigation/BottomNavigationPage';
import MobileDashboardPage from 'pages/mobileSettings/dashboard/MobileDashboardPage';
import ExplorePanelDetailPage from 'pages/mobileSettings/explorePanel/ExplorePanelDetailPage';
import ExplorePanelPage from 'pages/mobileSettings/explorePanel/ExplorePanelPage';
import ConfigurationPage from 'pages/network/configuration/ConfigurationPage';
import FeaturesPage from 'pages/network/features/FeaturesPage';
import PersonasPage from 'pages/network/personas/PersonasPage';
import VisualControlsPage from 'pages/network/visualControls/VisualControlsPage';
import PromotionPage from 'pages/promotion/PromotionPage';
import ChannelManagementPage from 'pages/publishing/ChannelManagementPage';
import PublishingHistoryPage from 'pages/publishing/PublishingHistoryPage';
import Page404 from 'pages/shared/page404/page404';
import UnauthorizedPage from 'pages/shared/UnauthorizedPage';
import TypeaheadPage from 'pages/typeaheads/TypeaheadPage';
import TypeaheadWizardPage from 'pages/typeaheads/TypeaheadWizardPage';
import UsersPage from 'pages/users/UsersPage';
import WelcomePage from 'pages/welcome/WelcomePage';

const routesConfig = [
  {
    path: '/network/:networkId/configuration/environments',
    component: SystemEnvironmentsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/configuration/networks',
    component: NetworksPage,
    auth: true,
  },
  {
    path: '/network/:networkId/dashboard',
    component: WelcomePage,
    auth: true,
  },
  /* LOCATION ROUTES */
  {
    path: '/network/:networkId/locations/',
    component: LocationsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/locations/site/:siteId',
    component: SiteDetailPage,
    auth: true,
  },
  {
    path: '/network/:networkId/locations/building/:buildingId',
    component: BuildingDetailPage,
    auth: true,
  },
  {
    path: '/network/:networkId/locations/floor/:floorId',
    component: FloorDetailPage,
    auth: true,
  },
  {
    path: '/network/:networkId/locations/poi/:poiId',
    component: PoiDetailPage,
    auth: true,
  },
  {
    path: '/network/:networkId/locations/landmark/:landmarkId',
    component: LandmarkDetailPage,
    auth: true,
  },
  /* NETWORK ROUTES */
  {
    path: '/network/:networkId/config',
    component: ConfigurationPage,
    auth: true,
  },
  {
    path: '/network/:networkId/features',
    component: FeaturesPage,
    auth: true,
  },
  {
    path: '/network/:networkId/visual-controls',
    component: VisualControlsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/personas',
    component: PersonasPage,
    auth: true,
  },
  {
    path: '/network/:networkId/kiosks',
    component: KiosksPage,
    auth: true,
  },
  {
    path: '/network/:networkId/kiosks/template',
    component: KioskTemplatePage,
    auth: true,
  },
  {
    path: '/network/:networkId/kiosks/:kioskId',
    component: KioskDetailPage,
    auth: true,
  },
  /* DEEP LINKS ROUTES */
  /* Link Manager */
  {
    path: '/network/:networkId/deepLinks/linkManager',
    component: DeepLinksLinkManagerPage,
    auth: true,
  },
  /* Configuration */
  {
    path: '/network/:networkId/deepLinks/configuration',
    component: DeepLinksConfigurationPage,
    auth: true,
  },
  /* CONTENT ROUTES */
  /* Categories */
  {
    path: '/network/:networkId/categories',
    component: CategoriesPage,
    auth: true,
  },
  {
    path: '/network/:networkId/categories/group/:categoryId',
    component: CategoryGroupPage,
    auth: true,
  },
  {
    path: '/network/:networkId/categories/:categoryId',
    component: CategoryPage,
    auth: true,
  },
  /* List */
  {
    path: '/network/:networkId/lists',
    component: ListsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/lists/:listId',
    component: ListDetailPage,
    auth: true,
  },
  /* Typeahead */
  {
    path: '/network/:networkId/typeahead',
    component: TypeaheadPage,
    auth: true,
  },
  {
    path: '/network/:networkId/typeahead/:typeaheadId/edit',
    component: TypeaheadWizardPage,
    auth: true,
  },
  {
    path: '/network/:networkId/typeahead-wizard',
    component: TypeaheadWizardPage,
    auth: true,
  },
  /* MESSAGING CENTER ROUTES */
  {
    path: '/network/:networkId/pushNotification',
    component: PushNotificationPage,
    auth: true,
  },
  {
    path: '/network/:networkId/bannerMessage',
    component: BannerMessagePage,
    auth: true,
  },
  /* MOBILE SETTINGS ROUTES */
  {
    path: '/network/:networkId/mobileDashboard',
    component: MobileDashboardPage,
    auth: true,
  },
  {
    path: '/network/:networkId/explore',
    component: ExplorePanelPage,
    auth: true,
  },
  {
    path: '/network/:networkId/explore/:panelId',
    component: ExplorePanelDetailPage,
    auth: true,
  },
  {
    path: '/network/:networkId/navigation',
    component: BottomNavigationPage,
    auth: true,
  },

  /* USER ROUTES */
  {
    path: '/network/:networkId/users',
    component: UsersPage,
    auth: true,
  },

  /* PROMOTION ROUTE */
  {
    path: '/network/:networkId/promotion',
    component: PromotionPage,
    auth: true,
  },

  /* PUBLISHING ROUTES */
  {
    path: '/network/:networkId/publishingHistory',
    component: PublishingHistoryPage,
    auth: true,
  },
  {
    path: '/network/:networkId/channelManagement',
    component: ChannelManagementPage,
    auth: true,
  },

  /* ANALYTICS ROUTES */
  {
    path: '/network/:networkId/analytics',
    component: AnalyticsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/mobile-installations',
    component: MobileInstallationsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/sessions',
    component: SessionsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/navigation-events',
    component: NavigationEventsPage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/total-users',
    component: TotalUsersPage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/dashboard-card-usage',
    component: DashboardCardUsagePage,
    auth: true,
  },
  {
    path: '/network/:networkId/analytics/more-menu-usage',
    component: MoreMenuUsagePage,
    auth: true,
  },

  /* HOME PAGE */
  {
    path: '/network/:networkId/welcome',
    component: HomePage,
    auth: true,
  },
  {
    path: '/network/:networkId',
    component: HomePage,
    auth: true,
  },
  {
    path: '/',
    component: Login,
    auth: true,
  },

  /* GLOBAL ROUTES */
  {
    path: '/login',
    component: Login,
    auth: false,
  },
  {
    path: '/logout',
    component: LoggedOutPage,
    auth: false,
  },
  {
    path: '/unauthorized',
    component: UnauthorizedPage,
    auth: false,
  },
  {
    path: '*',
    component: Page404,
    auth: false,
  },
];

export default routesConfig;
