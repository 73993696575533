import { useLazyQuery, useMutation } from '@apollo/client';
import { FlamingoContext } from 'contexts/flamingo';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useContext, useEffect } from 'react';
import {
  GET_NETWORK_NAVIGATION,
  REMOVE_NETWORK_NAVIGATION_ACTION,
  UPDATE_NETWORK_NAVIGATION,
  UPDATE_NETWORK_NAVIGATION_ITEM,
  UPDATE_NETWORK_NAVIGATION_TAB,
  GET_SYSTEM_NAVIGATION,
  REMOVE_SYSTEM_NAVIGATION_ACTION,
  UPDATE_SYSTEM_NAVIGATION,
  UPDATE_SYSTEM_NAVIGATION_ITEM,
  UPDATE_SYSTEM_NAVIGATION_TAB,
} from 'graphql/queries';

export const useNavigation = (networkId) => {
  const { handleError } = useHandleError('Navigation');
  const { activeNetwork } = useContext(FlamingoContext);

  const isSystem = isGlobalTemplate(networkId);
  const gql = isSystem ? GET_SYSTEM_NAVIGATION : GET_NETWORK_NAVIGATION;

  const [getNavigation, { data, error, loading }] = useLazyQuery(gql, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  useEffect(() => {
    getNavigation({
      variables: { id: networkId },
    });
    // eslint-disable-next-line
  }, [activeNetwork]);

  return {
    data: isSystem ? data?.system?.navigation : data?.getNetwork?.navigation,
    configColors: isSystem ? null : data?.getNetwork?.theme?.colors || [],
    error,
    getNavigation,
    loading,
  };
};

export const useUpdateNavigation = (networkId) => {
  const { handleError } = useHandleError('Navigation');
  const { toastNotificationSuccessHook } = useToast();

  const isSystem = isGlobalTemplate(networkId);
  const gql = isSystem ? UPDATE_SYSTEM_NAVIGATION : UPDATE_NETWORK_NAVIGATION;

  return useMutation(gql, {
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'Your progress on the Bottom Navigation has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNavigationTab = (networkId) => {
  const { handleError } = useHandleError('Navigation');

  const isSystem = isGlobalTemplate(networkId);
  const gql = isSystem ? UPDATE_SYSTEM_NAVIGATION_TAB : UPDATE_NETWORK_NAVIGATION_TAB;

  return useMutation(gql, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateNavigationItem = (networkId) => {
  const { handleError } = useHandleError('Navigation');

  const isSystem = isGlobalTemplate(networkId);
  const gql = isSystem ? UPDATE_SYSTEM_NAVIGATION_ITEM : UPDATE_NETWORK_NAVIGATION_ITEM;

  return useMutation(gql, {
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useDeleteNavigationItem = (networkId) => {
  const { handleError } = useHandleError('Navigation');
  const { toastNotificationSuccessHook } = useToast();

  const isSystem = isGlobalTemplate(networkId);
  const gql = isSystem ? REMOVE_SYSTEM_NAVIGATION_ACTION : REMOVE_NETWORK_NAVIGATION_ACTION;

  return useMutation(gql, {
    onCompleted: (data) => {
      toastNotificationSuccessHook('The More Menu Link has been deleted.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
