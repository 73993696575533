import { Box, Grid } from '@mui/material';
import Autocomplete from 'components/autocomplete/autoComplete';
import SearchabilityModal from 'components/categories/searchabilityModal';
import GenericModal from 'components/genericModal/genericModal';
import LabeledCheckbox from 'components/labeledCheckbox/labeledCheckbox';
import { getLabelByLang } from 'helpers/lang-util';
import { useCategories } from 'hooks/dataHooks';
import React, { useEffect, useState } from 'react';

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '320px',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiDialogContent-root': {
        minWidth: 'inherit',
      },
    },
  },
  parentLocationModal: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '734px',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiDialogContent-root': {
        minWidth: 'inherit',
      },
    },
  },
});

const FilterSelection = ({
                           filter,
                           initialFilters,
                           clearFilter,
                           updateFilter,
                         }) => {
  const styles = buildStyles();
  const initValues
    = initialFilters.find(
      (f) => f.name === filter.filterAccessor || f.name === filter.accessor,
    )?.values || [];
  const [selectedFilters, setSelectedFilters] = useState(initValues);
  const { categories: categoriesData } = useCategories();
  const [parentSite, setParentSite] = useState('');
  const [parentBuilding, setParentBuilding] = useState('');
  const [parentFloor, setParentFloor] = useState('');

  useEffect(() => {
    if (filter.filterAccessor === 'parentLocations') {
      const initialFilter = initialFilters?.find(
        (f) => f.name === 'parentLocations',
      );
      if (initialFilter?.values?.length > 0) {
        initialFilter.values.forEach(({ type, value }) => {
          if (type === 'parentSite') {
            setParentSite(value);
          } else if (type === 'parentBuilding') {
            setParentBuilding(value);
          } else if (type === 'parentFloor') {
            setParentFloor(value);
          }
        });
      }
    }
  }, [initialFilters, filter]);

  const updateSelect = (item) => (e, val) => {
    if (val) {
      const newFilters = [...selectedFilters];
      newFilters.push(item);
      setSelectedFilters(newFilters);
    } else {
      setSelectedFilters(selectedFilters.filter((f) => f.value !== item.value));
    }
  };

  const filtersChanged = (initial, current) => {
    if (initial.length !== current.length) return true;
    let changed = false;
    current.forEach((c) => {
      if (!initial.find((i) => i.value === c.value)) changed = true;
    });
    return changed;
  };

  const categoriesSuccess = (values) => {
    const catFilters
      = values?.map((v) => ({ value: v.id, label: v.name })) || [];
    updateFilter(filter.filterAccessor ?? filter.accessor, catFilters);
  };

  if (filter.filters.type === 'select') {
    return (
      <GenericModal
        title={`Filter ${filter.Header}`}
        sx={styles.root}
        body={filter.filters.options.map((val) => (
          <Box
            key={`filter-select-${val.value}`}
            sx={{ marginTop: '6px', marginBottom: '6px' }}
          >
            <LabeledCheckbox
              label={val.label}
              onChange={updateSelect(val)}
              checked={!!selectedFilters.find((v) => v.value === val.value)}
            />
          </Box>
        ))}
        open={true}
        cancelText={'Clear'}
        handleClose={clearFilter}
        overrideCancel={() => setSelectedFilters([])}
        handleConfirm={() => {
          updateFilter(
            filter.filterAccessor ?? filter.accessor,
            selectedFilters,
          );
        }}
        greyOutCancel={selectedFilters.length === 0}
        greyOutConfirm={!filtersChanged(initValues, selectedFilters)}
        confirmText="Show Results"
      />
    );
  }

  if (filter.filters.type === 'categories') {
    const selectedCats
      = initValues?.map((v) => ({ id: v.value, name: v.label, checked: true }))
      || [];
    return (
      <SearchabilityModal
        setShowModal={clearFilter}
        handleSave={categoriesSuccess}
        defaultSelectedCategories={selectedCats}
        categoriesData={categoriesData}
        confirmText={'Show Results'}
      />
    );
  }

  if (filter.filters.type === 'parentLocations') {
    return (
      <GenericModal
        title="Filter by Parent Location"
        sx={styles.parentLocationModal}
        body={
          <Grid container>
            {!filter.filters.parentSites && (
              <Grid item xs={12} sx={{ marginTop: '8px' }}>
                <Autocomplete
                  input={{
                    value: parentBuilding,
                    onChange: (buildingId) => {
                      setParentBuilding(buildingId);
                      setParentFloor(null);
                    },
                  }}
                  options={filter.filters.parentBuildings}
                  label="Parent Building"
                  fullWidth
                  disableClearable={true}
                />
              </Grid>
            )}
            {filter.filters.parentSites && (
              <>
                <Grid item xs={12} sx={{ marginTop: '8px' }}>
                  <Autocomplete
                    input={{
                      value: parentSite,
                      onChange: (siteId) => {
                        setParentSite(siteId);
                        setParentBuilding(null);
                        setParentFloor(null);
                      },
                    }}
                    options={filter.filters.parentSites}
                    label="Parent Site"
                    fullWidth
                    disableClearable={true}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '34px' }}>
                  <Autocomplete
                    input={{
                      value: parentBuilding,
                      onChange: (buildingId) => {
                        const building = filter.filters.parentBuildings.find(
                          (b) => b.id === buildingId
                        );
                        if (!parentSite && building.siteId) {
                          setParentSite(building.siteId);
                        }
                        setParentBuilding(buildingId);
                      },
                    }}
                    options={
                      parentSite
                        ? filter.filters.parentBuildings.filter(
                            (f) => f.siteId === parentSite
                          )
                        : filter.filters.parentBuildings
                    }
                    label="Parent Building"
                    fullWidth
                    disableClearable={true}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={{ marginTop: '34px' }}>
              <Autocomplete
                input={{
                  value: parentFloor,
                  onChange: (floorId) => {
                    const floor = filter.filters.parentFloors.find(
                      (f) => f.id === floorId
                    );
                    if (!parentBuilding && floor.buildingId) {
                      setParentBuilding(floor.buildingId);
                    }
                    if (
                      filter.filters.parentSites
                      && !parentSite
                      && floor.siteId
                    ) {
                      setParentSite(floor.siteId);
                    }
                    setParentFloor(floorId);
                  },
                }}
                options={
                  parentBuilding
                    ? filter.filters.parentFloors.filter(
                        (f) => f.buildingId === parentBuilding
                      )
                    : parentSite
                    ? filter.filters.parentFloors.filter(
                        (f) => f.siteId === parentSite
                      )
                    : filter.filters.parentFloors
                }
                label="Parent Floor"
                fullWidth
                disableClearable={true}
              />
            </Grid>
          </Grid>
        }
        open={true}
        cancelText={'Clear'}
        handleClose={clearFilter}
        overrideCancel={() => {
          setParentSite(null);
          setParentBuilding(null);
          setParentFloor(null);
        }}
        handleConfirm={() => {
          const selectedParentFilters = [];
          if (parentSite) {
            selectedParentFilters.push({
              type: 'parentSite',
              label: getLabelByLang(
                filter.filters.parentSites.find((b) => b.id === parentSite)
                  ?.label
              ),
              value: parentSite,
            });
          }
          if (parentBuilding) {
            selectedParentFilters.push({
              type: 'parentBuilding',
              label: getLabelByLang(
                filter.filters.parentBuildings.find(
                  (b) => b.id === parentBuilding
                )?.label
              ),
              value: parentBuilding,
            });
          }
          if (parentFloor) {
            selectedParentFilters.push({
              type: 'parentFloor',
              label: getLabelByLang(
                filter.filters.parentFloors.find((f) => f.id === parentFloor)
                  ?.label
              ),
              value: parentFloor,
            });
          }
          updateFilter(
            filter.filterAccessor ?? filter.accessor,
            selectedParentFilters
          );
        }}
        greyOutCancel={!parentSite && !parentBuilding && !parentFloor}
        greyOutConfirm={!parentSite && !parentBuilding && !parentFloor}
        confirmText="Show Results"
      />
    );
  }

  return null;
};

FilterSelection.propTypes = {};

FilterSelection.defaultProps = {};

export default FilterSelection;
