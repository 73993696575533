import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, MenuItem, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FlamingoNavigation from 'components/navigation/flamingoNavigation';
import UserMenu from 'components/navigation/userMenu';
import { FlamingoContext } from 'contexts/flamingo';
import { useLogout, useSSOConnection } from 'hooks/dataHooks';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  main: {
    gridArea: 'main',
  },
  network: {
    alignSelf: 'center',
    gridArea: 'network',
    justifySelf: 'center',
  },
  root: {
    display: 'grid',
    gridTemplateAreas: '"network main user"',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: 'auto',
    height: '70px',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  user: {
    alignSelf: 'center',
    gridArea: 'user',
    justifySelf: 'flex-end',
  },
  login: {
    marginRight: '24px',
  },
}));

const FlamingoAppBar = ({ isAuthenticated, networkId }) => {
  const classes = useStyles();
  const ssoConnection = useSSOConnection();
  const logout = useLogout();
  const { loginWithRedirect } = useAuth0();
  const { user } = useContext(FlamingoContext);

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        ...ssoConnection && { connection: ssoConnection },
        prompt: 'login',
      },
    });
  };

  return (
    <Paper
      component="header"
      variant="outlined"
      square
      elevation={0}
      className={classes.root}
    >
      <Box className={classes.network}>
        <FlamingoNavigation
          isAuthenticated={isAuthenticated}
          networkId={networkId}
        />
      </Box>
      <Box className={classes.main}></Box>
      <Box className={classes.user}>
        {isAuthenticated && (
          <UserMenu
            name={`${user.firstName} ${user.lastName}`}
            role={user?.roles && user.roles[0]}
          >
            <MenuItem onClick={logout} data-test="logout">
              Logout
            </MenuItem>
          </UserMenu>
        )}
        {!isAuthenticated && (
          <Button
            className={classes.login}
            color="primary"
            data-test="login-button"
            onClick={handleLogin}
            variant="contained"
          >
            Login
          </Button>
        )}
      </Box>
    </Paper>
  );
};

FlamingoAppBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  networkId: PropTypes.string,
};

FlamingoAppBar.defaultProps = {
  isAuthenticated: true,
  networkId: null,
};

export default FlamingoAppBar;
