import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { buildModalStyles } from 'components/genericModal/modalStyles';
import Loading from 'components/loading/loading';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

const GenericFormModal = ({
                            actionSx,
                            body,
                            cancelText,
                            sx,
                            confirmText,
                            confirmType,
                            confirmProgress,
                            contentSx,
                            customTags: { cancel, confirm },
                            disableActions,
                            disableCancel,
                            disableClose,
                            disableConfirm,
                            disableConfirmCheck,
                            greyOutCancel,
                            greyOutConfirm,
                            handleClose,
                            handleConfirm,
                            open,
                            title,
                            formParams,
                            bodyProgress,
                            scrollable,
                            spaceBetweenDialogActions,
                            ...rest
                          }) => {
  const theme = useTheme();
  const styles = buildModalStyles({ theme });

  const renderedDialogContent = (formVals) => {
    if (!formVals) {
      return null;
    }

    if (bodyProgress) {
      return (
        <Box>
          <Loading
            content=""
            backgroundColor={ColorPalette.white}
            thickness={1.5}
          />
        </Box>
      );
    }

    if (typeof body === 'string') {
      return (
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      );
    }

    if (typeof body === 'function') {
      return body(formVals);
    }

    return body;
  };

  const renderForm = (formVals) => (
    <Box sx={styles.formContent}>
      <form onSubmit={formVals.handleSubmit}>
        <DialogContent
          sx={{
            ...styles.dialogContent,
            ...!scrollable && {
              '& .MuiDialogContent-root': styles.nonScrollableContent,
            },
            ...contentSx,
          }}
          data-test="GenericFormModal-content"
        >
          {renderedDialogContent(formVals)}
        </DialogContent>
        {!disableActions && (
          <DialogActions
            sx={{
              ...styles.actions,
              justifyContent: spaceBetweenDialogActions
                ? 'space-between'
                : 'flex-end',
              ...actionSx,
            }}
          >
            {!disableCancel && (
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                disabled={greyOutCancel}
                data-test={cancel}
                sx={{ minWidth: '120px' }}
              >
                {cancelText || 'Cancel'}
              </Button>
            )}
            <Box>
              {!disableConfirm && (
                <Box sx={{ display: 'inline', position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: '120px' }}
                    type={confirmType}
                    onClick={(e) => handleConfirm?.(e, formVals)
                      || formVals.handleSubmit(e, formVals)
                    }
                    disabled={
                      greyOutConfirm
                      || confirmProgress
                      || (disableConfirmCheck
                        ? disableConfirmCheck(formVals)
                        : formVals.submitting
                        || formVals.pristine
                        || formVals.invalid)
                    }
                    data-test={
                      confirm || `genericFormModal-${confirmText || 'Confirm'}`
                    }
                  >
                    {confirmText || 'Confirm'}
                  </Button>
                  {(confirmProgress || formVals.submitting) && (
                    <CircularProgress size={24} sx={styles.buttonProgress} />
                  )}
                </Box>
              )}
            </Box>
          </DialogActions>
        )}
      </form>
    </Box>
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ...styles.root,
        '& .MuiDialog-paperScrollPaper': styles.paperScrollPaper,
        ...sx,
      }}
      onClose={handleClose}
      {...rest}
    >
      {(title || !disableClose) && (
        <DialogTitle
          id="alert-dialog-title"
          data-test="genericFormModalTitle"
          sx={styles.titleStyle}
        >
          {!disableClose && (
            <IconButton sx={styles.closeBtn} onClick={handleClose} size="large">
              <Close />
            </IconButton>
          )}
          {title}
        </DialogTitle>
      )}
      <Form {...formParams} render={(formVals) => renderForm(formVals)} />
    </Dialog>
  );
};

GenericFormModal.propTypes = {
  actionSx: PropTypes.object,
  body: PropTypes.any,
  bodyProgress: PropTypes.bool,
  cancelText: PropTypes.string,
  sx: PropTypes.object,
  confirmText: PropTypes.any,
  confirmType: PropTypes.string,
  confirmProgress: PropTypes.bool,
  contentSx: PropTypes.object,
  customTags: PropTypes.object,
  disableActions: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableClose: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  disableConfirmCheck: PropTypes.func,
  formParams: PropTypes.object,
  greyOutCancel: PropTypes.bool,
  greyOutConfirm: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  open: PropTypes.bool,
  scrollable: PropTypes.bool,
  title: PropTypes.any,
  spaceBetweenDialogActions: PropTypes.bool,
};

GenericFormModal.defaultProps = {
  actionSx: {},
  bodyProgress: false,
  sx: {},
  confirmProgress: false,
  confirmType: 'button',
  contentSx: {},
  customTags: {
    cancel: null,
    confirm: null,
  },
  disableActions: false,
  disableClose: false,
  disableConfirmCheck: null,
  formParams: {},
  open: true,
  scrollable: false,
  spaceBetweenDialogActions: true,
  title: 'Generic modal title',
};

export default GenericFormModal;
