import { gql, useQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';

/**
 * This is the GraphQL hook for the Bulk Data Processor API.
 *
 * Doc: https://goziohealth.atlassian.net/wiki/spaces/EN/pages/2397372419/Bulk+Data+Processor+API
 */

export const BULK_DATA_SERVICE_KEYS = Object.freeze({
  LanguageExport: 'location_export',
});

export const BULK_REQUEST_OPERATIONS = Object.freeze({
  Export: 'export',
  Import: 'import',
});

export const BULK_DATA_REQUEST_ERRORS = Object.freeze({
  INVALID_DATA_TYPE: 'invalidDataType', // The import file is in an invalid format (expected xlsx but received csv, etc)
  INVALID_DATA_SET: 'invalidDataSet', // The import file has an invalid data set (tab) that could not be processed
  SYSTEM_ERROR: 'systemError', // Ununexpected error has occurred
  INVALID_DATA_ITEM: 'invalidDataItem', // A particular data item is in an invalid state (for publishing)
  UNKNOWN_DATA_ITEM: 'unknownDataItem', // A particular data item could not be found (404)
  IGNORED_DATA_ITEM: 'ignoredDataItem', // A particular data item was ignored/skipped
});

export const BULK_DATA_REQUEST_STATUSES = Object.freeze({
  COMPLETE: 'complete',
  FAILED: 'failed',
  IN_PROGRESS: 'inProgress',
});

export const FIND_BULK_DATA_SERVICE = gql`
  query findBulkDataService($where: BulkDataServiceInputWhere) {
    findBulkDataService(where: $where) {
      edges {
        node {
          id
          key
          name
          operations
        }
      }
    }
  }
`;

export const BULK_DATA_REQUEST = gql`
  query findBulkDataRequest(
    $where: BulkDataRequestInputWhere
    $sortBy: BulkDataRequestInputSort
    $limit: Int
  ) {
    findBulkDataRequest(where: $where, sortBy: $sortBy, limit: $limit) {
      edges {
        node {
          id
          createdAt
          createdBy {
            id
            profile {
              firstName
              lastName
            }
          }
          errors {
            edges {
              node {
                id
                type
                message
                data {
                  cell
                  field
                  model
                }
              }
            }
          }
          network {
            id
          }
          operation
          progress
          status
          updatedAt
        }
      }
    }
  }
`;

export const useBulkDataService = ({ key }) => {
  const { handleError } = useHandleError('Bulk Request');

  const { data, loading } = useQuery(FIND_BULK_DATA_SERVICE, {
    fetchPolicy: 'cache-first',
    variables: { where: { key } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    data: data?.findBulkDataService?.edges?.length
      ? data.findBulkDataService.edges[0].node
      : null,
    loading,
  };
};

export const useBulkRequest = ({
                                 limit = 1,
                                 operation,
                                 sortBy = { createdAt: 'desc' },
                               }) => {
  const { handleError } = useHandleError('Bulk Request');

  const { data, loading, refetch } = useQuery(BULK_DATA_REQUEST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        service: {
          key: BULK_DATA_SERVICE_KEYS.LanguageExport,
        },
        operation,
      },
      sortBy,
      limit,
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    data: data?.findBulkDataRequest?.edges
      ? data.findBulkDataRequest.edges.map((n) => n.node)
      : [],
    loading,
    refetch,
  };
};
