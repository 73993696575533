import { Typography } from '@mui/material';
import GenericModal from 'components/genericModal/genericModal';
import { FlamingoContext } from 'contexts/flamingo';
import { getLabelByLang } from 'helpers/lang-util';
import { useDeleteList } from 'hooks/dataHooks/useLists';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const FEATURE_TITLES_MAP = {
  banners: 'Banner Message',
  kiosk: null,
  myChart: null,
  personas: null,
  pushNotifications: null,
};

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '660px',
      '& .MuiDialogContent-root': {
        width: '100%',
      },
    },
  },
  text: {
    marginTop: '32px',
  },
});

const DeleteListModal = ({ handleClose, list }) => {
  const styles = buildStyles();
  const { toastNotificationSuccessHook } = useToast();

  const { activeNetwork } = useContext(FlamingoContext);
  const features = activeNetwork?.configuration?.features ?? 'off';

  const [deleteList] = useDeleteList();

  const featureTitles = useMemo(() => {
    const titles = ['Dashboard', 'Explore Panel', 'More Menu', 'Typeahead'];
    Object.keys(features).forEach((key) => {
      if (features[key] === 'on' && FEATURE_TITLES_MAP.hasOwnProperty(key)) {
        if (FEATURE_TITLES_MAP[key]) {
          titles.push(FEATURE_TITLES_MAP[key]);
        }
      }
    });
    titles.sort();
    return titles;
  }, [features]);

  const handleDeleteList = async () => {
    await deleteList({ variables: { id: list?.id } });
    toastNotificationSuccessHook(
      `List “${getLabelByLang(list?.name)}” has been deleted.`,
    );
    handleClose();
  };

  return (
    <GenericModal
      sx={styles.root}
      title="Delete List"
      body={[
        <Typography key="ft_desc" variant="subtitle1">
          There may be dependencies that exist for this list. Before deleting,
          it’s recommended you check the following areas where this list could
          be referenced:
        </Typography>,
        ...featureTitles.map((title, idx) => (
          <Typography
            sx={idx === 0 ? styles.text : {}}
            key={`ft_${idx}`}
            variant="subtitle1"
          >
            {title}
          </Typography>
        )),
        <Typography key="ft_sure" sx={styles.text} variant="subtitle1">
          Are you sure you want to delete "{getLabelByLang(list?.name)}"?
        </Typography>,
      ]}
      cancelText="Cancel"
      confirmText="YES, CONTINUE DELETING THIS LIST"
      handleConfirm={handleDeleteList}
      handleClose={handleClose}
    />
  );
};

DeleteListModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  list: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
};

export default DeleteListModal;
