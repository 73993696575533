import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    flexShrink: 0,
    float: 'left',
    left: 0,
    position: 'absolute',
    userSelect: 'none',
    msUserSelect: 'none',
  },
  icon: {
    color: theme.palette.grey[600],
    marginRight: '4px',
    padding: 0,
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  pageInfo: {
    color: theme.palette.grey[600],
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '4px',
  },
}));

const getPageInfo = ({ count, page, rowsPerPage }) => {
  const row = page * rowsPerPage;
  const currentMax = row + rowsPerPage;
  return `${row + 1}-${currentMax < count ? currentMax : count} of ${count}`;
};

const GozioTablePagination = ({ count, page, rowsPerPage, onPageChange }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box className={classes.root}>
      <IconButton
        className={classes.icon}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        className={classes.icon}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <Box className={classes.pageInfo}>
        {getPageInfo({ count, page, rowsPerPage })}
      </Box>
    </Box>
  );
};

GozioTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default GozioTablePagination;
