import Add from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { produce } from 'immer';
import * as _ from 'lodash';
import AddTagModal from 'pages/typeaheads/containers/add-tag-modal';
import StepStyles from 'pages/typeaheads/containers/step-styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

const renderErrorMessage = (errorObj) => errorObj.reduce((accu, currVal) => {
    const enError = _.get(currVal, 'name.en', '');
    const esError = _.get(currVal, 'name.es', '');
    const weightError = _.get(currVal, 'weight', '');
    return `${accu} ${enError} ${esError} ${weightError}`;
  }, '');

class StepperTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdd: '',
    };
    this.handleModal = this.handleModal.bind(this);
    this.showAddTagModal = this.showAddTagModal.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteAdditionalTags
      = this.handleDeleteAdditionalTags.bind(this);
    this.constructValidators = this.constructValidators.bind(this);
  }

  handleDelete = (tag, index) => () => {
    const { typeaheadForm, change } = this.props;
    const values = (typeaheadForm && typeaheadForm.values) || {};
    const excludeTags = [...values.excludeCategoryTags];
    excludeTags.push(tag.id);
    change('excludeCategoryTags', excludeTags);
  };

  handleDeleteAdditionalTags = (tag, index) => () => {
    const { typeaheadForm, change } = this.props;
    const values = (typeaheadForm && typeaheadForm.values) || {};
    const includeTags = produce(values.includeTags, (draft) => {
      draft.splice(index, 1);
    });
    change('includeTags', includeTags);
  };

  showAddTagModal() {
    this.setState({
      showAdd: true,
    });
  }

  handleModal() {
    this.setState({
      showAdd: false,
    });
  }

  handleAddTag(vals) {
    const { typeaheadForm, change } = this.props;
    return new Promise((resolve, reject) => {
      const values = (typeaheadForm && typeaheadForm.values) || {};
      const includeTags = produce(values.includeTags, (draft) => {
        draft.push(vals);
      });
      change('includeTags', includeTags);
      this.handleModal();
      resolve();
    });
  }

  constructValidators() {
    const { typeaheadForm } = this.props;
    const values = (typeaheadForm && typeaheadForm.values) || {};
    const required = (value) => value ? undefined : 'Required';

    const noRepeatEn = (value) => {
      for (let i = 0; i < values.category.tags.length; i += 1) {
        if (value === values.category.tags[i].name.en) {
          return 'Tag already exists';
        }
      }

      for (let i = 0; i < values.includeTags.length; i += 1) {
        if (value === values.includeTags[i].name.en) {
          return 'Tag already exists';
        }
      }
      return undefined;
    };

    const noRepeatEs = (value) => {
      for (let i = 0; i < values.category.tags.length; i += 1) {
        if (value === values.category.tags[i].name.es) {
          return 'Tag already exists';
        }
      }

      for (let i = 0; i < values.includeTags.length; i += 1) {
        if (value === values.includeTags[i].name.es) {
          return 'Tag already exists';
        }
      }
      return undefined;
    };

    return {
      name: {
        en: [required, noRepeatEn],
        es: [required, noRepeatEs],
      },
      weight: [required],
    };
  }

  tagsListChips = (tags, values, typeaheadForm, classes) => tags
      .concat(_.get(values.category, 'parent.tags', []))
      .filter(
        (tag) => !typeaheadForm.values.excludeCategoryTags.includes(tag.id),
      )
      .filter((tag) => !!tag.name.en)
      .map((tag, index) => (
        <Chip
          key={index + tag.name.en}
          label={tag.name.en}
          onDelete={this.handleDelete(tag, index)}
          className={classes.chipRoot}
          classes={{
            deleteIcon: classes.chipIcon,
            label: classes.chipLabel,
          }}
        />
      ));

  additionalTagChips = (includeTags, classes) => includeTags
      .filter((tag) => !!tag.name.en)
      .map((tag, index) => (
        <Chip
          key={tag.name.en}
          label={tag.name.en}
          onDelete={this.handleDeleteAdditionalTags(tag, index)}
          className={classes.chipRoot}
          classes={{
            deleteIcon: classes.chipIcon,
            label: classes.chipLabel,
          }}
        />
      ));

  render() {
    const {
      classes,
      valid,
      typeaheadForm,
      handleCancel,
      handleBack,
      languages,
    } = this.props;
    const { showAdd } = this.state;
    const values = (typeaheadForm && typeaheadForm.values) || {};
    const includeTags = _.get(typeaheadForm, 'submitErrors.includeTags', null);

    const customTags = {
      add: {
        'data-test': 'typeaheadStep5AddTagButton',
      },
      save: {
        'data-test': 'typeaheadStep5SaveButton',
      },
      back: {
        'data-test': 'typeaheadStep5BackButton',
      },
      cancel: {
        'data-test': 'typeaheadStep5CancelButton',
      },
    };
    let combinedTagChips = [];
    if (values.category !== null && values.category.tags) {
      combinedTagChips = this.tagsListChips(
        values.category.tags,
        values,
        typeaheadForm,
        classes,
      ).concat(this.additionalTagChips(values.includeTags, classes));
    } else if (values.includeTags) {
      combinedTagChips = this.additionalTagChips(values.includeTags, classes);
    }

    const errorMessage = includeTags ? renderErrorMessage(includeTags) : null;
    return (
      <Box>
        {showAdd && (
          <AddTagModal
            open={showAdd}
            type={'typeahead'}
            handleAddTag={(vals) => this.handleAddTag(vals)}
            handleClose={(event) => this.handleModal()}
            languages={languages}
            validators={values.category !== null && this.constructValidators()}
          />
        )}
        <Typography className={classes.title}>
          Finally, what tags will be matched?
        </Typography>
        <Typography
          className={classes.contentText}
          sx={{ marginBottom: '19px' }}
        >
          Tags help surface a specific typeahead rule to users. Add tags to help
          display this rule in the list users see.
        </Typography>
        <Typography className={classes.contentText}>
          We got things started by adding tags from the category you previously
          selected. You can remove any tags you don&apos;t want to include.
        </Typography>
        <Box className={classes.chipGroup}>{combinedTagChips}</Box>
        {errorMessage ? (
          <p className={classes.errorMessage}>
            {includeTags && <span>{errorMessage}</span>}
          </p>
        ) : null}
        <Button
          onClick={this.showAddTagModal}
          variant="contained"
          color="primary"
          {...customTags.add}
        >
          <Add />
          ADD TAG
        </Button>
        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            {...customTags.cancel}
          >
            CANCEL
          </Button>
          <Box sx={{ float: 'right', display: 'inline-block' }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              {...customTags.back}
            >
              BACK
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!valid}
              color="primary"
              {...customTags.save}
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

StepperTag.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ typeaheadWizard, form }) => ({
    typeaheadWizard,
    typeaheadForm: form.typeaheadForm,
  })),
  reduxForm({
    form: 'typeaheadForm',
    destroyOnUnmount: false,
    enableReinitialize: false,
  }),
  withStyles(StepStyles),
)(StepperTag);
