import { useMutation } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import {
  FIND_KIOSK_TEMPLATE,
  UPDATE_KIOSK_TEMPLATE,
} from 'graphql/queries';

import { useSuspenseQueryWrapper } from 'hooks/dataHooks/useQueryWrapper';

export const useUpdateKioskTemplate = (
  gql = UPDATE_KIOSK_TEMPLATE,
  showNotification = true,
) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Kiosk Template');

  return useMutation(gql, {
    onCompleted: (data) => {
      if (showNotification) {
        toastNotificationSuccessHook(
          'Your progress on the Kiosk Template has been saved.',
        );
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useKioskTemplate = () => useSuspenseQueryWrapper(FIND_KIOSK_TEMPLATE, {
  dataFn: (data) => data?.findKioskTemplate?.edges
    ? data.findKioskTemplate.edges[0]?.node ?? {}
    : null,
  skipFn: (data) => data && Object.keys(data).length > 0,
});
