import { createTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';

export const GozioThemeObject = {
  themeName: 'Gozio Theme',
  breakpoints: {
    values: {
      lg: 1200,
      md: 960,
      sm: 768,
      xl: 1600,
      xs: 576,
    },
  },
  palette: ColorPalette,

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          lineHeight: '22px',
          fontWeight: 500,
          minWidth: '82px',
          padding: '11px 15px',
          borderRadius: '8px',
          height: '42px',
        },
        sizeSmall: {
          fontFamily: 'Poppins',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 500,
          borderRadius: '4px',
          padding: '7px 17px',
          height: '32px',
        },
        sizeLarge: {
          fontFamily: 'Poppins',
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 700,
          padding: '15px 18px',
          height: '52px',
        },
        contained: {},
        containedPrimary: {
          fontWeight: 'bold',
        },
        outlined: {
          borderWidth: '1.5px',
          '&:disabled': {
            border: `2px solid ${ColorPalette.grey[300]}`,
            color: ColorPalette.grey[300],
            backgroundColor: 'transparent',
          },
        },
        outlinedPrimary: {
          border: `1.5px solid ${ColorPalette.blue[400]}`,
          '&:hover,&:active': {
            color: ColorPalette.blue[500],
            border: `1.5px solid${ColorPalette.blue[400]}`,
            backgroundColor: 'transparent',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: ColorPalette.grey[400],
          padding: '8px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: ${ColorPalette.white},
        }
      `,
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          margin: 0,
          minHeight: '195px',
          overflow: 'visible',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '16px',
          marginTop: 0,
          padding: '16px 8px 0 8',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          padding: '32px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
          marginBottom: '32px',
          minWidth: '452px',
          padding: '0 32px 0 32px',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 849,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: ColorPalette.grey[400],
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: '0.38px',
          '&.Mui-focused': {
            color: ColorPalette.grey[500],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: ColorPalette.grey[600],
          fontWeight: 400,
          letterSpacing: '0.1px',
          lineHeight: '18px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '1.125rem !important',
          fontSize: '12px',
          fontWeight: 400,
          letterSpacing: '0.38px',
          marginTop: '3px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          letterSpacing: '0.16px',
          lineHeight: 1.5,
        },
        input: {
          '&.Mui-disabled': {
            color: ColorPalette.grey[400],
          },
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&[type=number]': {
            WebkitAppearance: 'textfield',
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: ColorPalette.red[600],
          fontSize: '15px',
          fontWeight: 500,
        },
        outlined: {
          padding: '0 4px',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(9px, -6px) scale(0.75)',
            backgroundColor: ColorPalette.white,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: 500,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            color: ColorPalette.grey[600],
            backgroundColor: 'initial',
            fontWeight: 700,
          },
        },
        divider: {
          borderColor: ColorPalette.grey[600],
          padding: '13px 8px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 500,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '8px',
          minWidth: '8px',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: 'initial',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: ColorPalette.grey[300],
            },
          },
        },
        input: {
          color: ColorPalette.grey[600],

          '&.Mui-disabled': {
            color: ColorPalette.grey[400],
          },
        },
        notchedOutline: {
          borderColor: ColorPalette.grey[400],
          borderRadius: '4px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: `0 2px 4px 0 ${colorWithAlpha(
            ColorPalette.grey[350],
            0.4,
          )}`,
        },
        rounded: {
          borderRadius: '20px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingRight: '4px',
          paddingLeft: '12px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root:not(.MuiMenu-paper)': {
            borderRadius: '8px',

            '& .MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding': {
              paddingLeft: 0,
            },

            '& .MuiToolbar-root': {
              background: ColorPalette.white,

              '& .MuiPickersTimePickerToolbar-hourMinuteLabel': {
                '& .MuiButton-label': {
                  marginTop: '18px',
                },
              },

              '& h2': {
                color: ColorPalette.grey[600],
                fontFamily: 'Roboto',
                fontSize: '48px',
                fontWeight: 'normal',
              },

              '& .MuiPickersTimePickerToolbar-ampmSelection': {
                marginTop: '16px',

                '& button': {
                  height: '39px',
                  padding: '0 !important',
                },

                '& h6': {
                  color: ColorPalette.grey[400],
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 'normal',
                },

                '& .MuiPickersToolbarText-toolbarBtnSelected': {
                  background: ColorPalette.blue[400],
                  borderRadius: '8px',
                  boxShadow: `0 0 4px 0 ${colorWithAlpha(
                    ColorPalette.black,
                    0.5,
                  )}`,
                  color: ColorPalette.white,
                  fontWeight: 600,
                  padding: '12px 8px',
                },
              },
            },
          },
          // for select dropdown
          '& .MuiPaper-root.MuiMenu-paper': {
            borderRadius: 0,
            marginTop: '8px',
          },
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: ColorPalette.blue[400],
        },
      },
    },
    MuiPickersClockPointer: {
      styleOverrides: {
        pointer: {
          backgroundColor: ColorPalette.blue[400],
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        clockNumber: {
          color: ColorPalette.grey[500],
          fontFamily: 'Roboto',
          fontWeight: 600,
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        container: {
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          height: '100%',
          margin: 0,
        },
        editor: {
          flex: 1,
          overflow: 'auto',
          padding: '0 16px',
        },
        placeHolder: {
          paddingLeft: '16px',
          position: 'absolute',
          top: 0,
          width: 'inherit',
        },
        root: {
          border: `1px solid ${ColorPalette.grey[400]}`,
          borderRadius: '2px',
          height: '252px',
          paddingTop: '8px',
          '& div#mui-container': {
            padding: '1px', // so when we make focus below we can stop from full shift
          },
          '&:hover': {
            border: `1px solid ${ColorPalette.grey[500]}`,
          },
          '&:focus-within': {
            borderColor: ColorPalette.blue[400],
            borderWidth: '2px',
            '& div#mui-container': {
              padding: 0, // no shift in contents because of border width
            },
          },
        },
        toolbar: {
          borderTop: `1px solid ${ColorPalette.grey[400]}`,
          height: '40px',
          padding: '2px',
          '& button': {
            padding: '8px',
            height: '36px',
            width: '36px',
            '& svg': {
              fontSize: '24px',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: ColorPalette.grey[500],
          fontWeight: 400,
        },
        select: {
          '&:focus': {
            backgroundColor: ColorPalette.white,
          },
        },
        selectMenu: {
          width: '100%',
          lineHeight: '19px',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        // per comp
        lineVertical: {
          minHeight: '32px',
        },
        // to match bottom padding
        vertical: {
          flex: 'none',
          marginTop: '8px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '24px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px !important',
          fontWeight: 500,
          letterSpacing: '1.02px',
          lineHeight: '20px !important',
          padding: 0,
          minWidth: '0 !important',
          marginRight: '46px',

          '&.Mui-selected': {
            fontWeight: 700,
          },

          '&:last-child': {
            marginRight: 0,
          },
        },
        textColorPrimary: {
          color: ColorPalette.grey[400],
          '&.Mui-disabled': {
            fontWeight: '400 !important',
            color: ColorPalette.grey[300],
          },
        },
        wrapper: {
          padding: '24px 0',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          minHeight: '68px',
        },
        scroller: {
          minHeight: '68px',
        },
        flexContainer: {
          minHeight: '68px',
        },
        indicator: {
          height: '2px',
        },
      },
    },
    // TODO: Decide between React Table useFlexLayout with divs or MUI components
    MuiTable: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '0.6875rem',
          lineHeight: '16px',
          padding: '0 8px 0 16px',
          letterSpacing: '0.8px',
          textTransform: 'uppercase',
          height: '42px',
        },
        body: {
          fontSize: '14px',
          lineHeight: '18px',
          wordBreak: 'initial',
          letterSpacing: '0.1px',
          padding: '0 8px 0 16px',
          fontWeight: 400,
          height: '72px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.inactive': {
            opacity: '50%',
            '&:hover': {
              opacity: '100%',
            },
          },
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fontSize: '16px',
          marginLeft: '3px',
          color: ColorPalette.text.primary,
        },
        iconDirectionAsc: {
          transform: 'rotate(0)',
        },
        iconDirectionDesc: {
          transform: 'rotate(180deg)',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '18px',
          color: ColorPalette.grey[600],
          wordBreak: 'initial',
          letterSpacing: '0.1px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: ColorPalette.grey[300],
            border: `1px solid ${ColorPalette.blue[300]}`,
          },
          border: `1px solid ${ColorPalette.grey[300]} !important`,
          letterSpacing: '0.4px',
          lineHeight: '16px',
          marginBottom: '16px',
          padding: '8px !important',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          flexWrap: 'wrap',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          maxWidth: '250px',
          padding: '8px',
        },
        tooltip: {
          backgroundColor: ColorPalette.grey[600],
          fontSize: '12px',
          fontWeight: 400,
          letterSpacing: '0.4px',
          lineHeight: '17px',
          padding: '4px 10px',
          maxWidth: 600,
        },
        arrow: {
          color: ColorPalette.grey[600],
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `2px solid ${ColorPalette.grey[300]}`,
          borderRadius: 0,
          backgroundColor: ColorPalette.grey[100],
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          minHeight: '64px',
          paddingLeft: '16px',
        },
        expandIcon: {
          marginLeft: '-12px',
        },
        content: {
          alignItems: 'center',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          marginTop: '-20px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: `linear-gradient(${ColorPalette.gradient.table.start}, ${ColorPalette.gradient.table.end})`,
            backgroundColor: ColorPalette.white,
            fontWeight: 'bold',
          },
          '&.Mui-selected': {
            background: ColorPalette.white,
          },
          '&.Mui-selected:hover': {
            background: `linear-gradient(${ColorPalette.gradient.table.start}, ${ColorPalette.gradient.table.end})`,
            backgroundColor: ColorPalette.white,
            color: ColorPalette.grey[600],
            fontWeight: 'bold',
          },
          color: ColorPalette.grey[500],
          fontSize: '16px',
          letterSpacing: '0.29px',
          lineHeight: '43px',
          marginBottom: '5px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
        paper: {
          borderRadius: 0,
          marginTop: '8px',
        },
        listbox: {
          padding: 0,
        },
        option: {
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '9.5px 16px !important',
          color: ColorPalette.grey[500],
          '&.Mui-focused': {
            background: `linear-gradient(${ColorPalette.gradient.table.start}, ${ColorPalette.gradient.table.end})`,
            backgroundColor: ColorPalette.white,
            color: `${ColorPalette.grey[600]} !important`,
            '& > div > div:first-of-type': {
              fontWeight: 'bold',
            },
          },
          '&[aria-selected="true"]': {
            backgroundColor: ColorPalette.white,
          },
        },
        popper: {
          '&[x-placement="top"]': {
            top: '-8px !important',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: colorWithAlpha(ColorPalette.black, 0.54),
          '$checked$colorPrimary + &': {
            opacity: 0.38,
            backgroundColor: ColorPalette.blue[300],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: ColorPalette.grey[600],
          '&.Mui-disabled': {
            color: ColorPalette.grey[400],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          height: '36px',
          padding: '9px 14px 9px 14px',
          backgroundColor: ColorPalette.white,
          transition: 'none',

          '& span::after': {
            content: 'attr(data-label)',
            display: 'block',
            fontWeight: 'bold',
            height: 0,
            color: 'transparent',
            overflow: 'hidden',
            visibility: 'hidden',
          },
          '&:focus': {
            backgroundColor: ColorPalette.white,
          },
          '&:hover': {
            boxShadow: `0 2px 4px 0px ${colorWithAlpha(
              ColorPalette.grey[350],
              0.4,
            )}`,
          },
        },
        clickable: {
          '&:focus': {
            backgroundColor: ColorPalette.white,
          },
          '&:hover': {
            backgroundColor: ColorPalette.white,
          },
        },
        deletable: {
          backgroundColor: ColorPalette.white,
          '&:focus': {
            backgroundColor: ColorPalette.white,
          },
        },
        icon: {
          fontSize: '19.5px',
          marginLeft: '-2px',
          marginRight: '5px',
        },
        label: {
          paddingLeft: 0,
          paddingRight: 0,
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.1px',
        },
        deleteIcon: {
          color: 'unset !important',
          fontSize: '18px',
          width: '18px',
          marginLeft: '1px',
          marginRight: '-4px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.capitalize': {
            textTransform: 'capitalize',
          },
          '&.required::before': {
            content: '\'*\'',
            fontSize: '14px',
            lineHeight: 1.4,
            fontFamily: ['Roboto', 'Helvetica', 'sans-serif'],
            verticalAlign: 'top',
            color: ColorPalette.red[500],
            paddingRight: '2px',
          },
          '&.backLink': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            cursor: 'pointer',
            color: ColorPalette.grey[500],

            '& .backIcon': {
              fontSize: '1.25rem',
              marginRight: '8px',
            },
            '&:hover': {
              color: ColorPalette.blue[400],
              textDecoration: 'underline',
            },
          },
        },
        h1: {
          '&.hero': {
            color: ColorPalette.grey[400],
            fontWeight: 700,
          },
        },
        h3: {
          '&.hero': {
            color: ColorPalette.grey[600],
            fontSize: '34px',
          },
        },
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontFamily: 'Poppins',
      fontSize: '28px',
      lineHeight: '2.5rem',
      fontWeight: 500,
      color: ColorPalette.text.primary,
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '24px',
      lineHeight: '1.875rem',
      fontWeight: 700,
      color: ColorPalette.grey[600],
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '22px',
      lineHeight: '1.875rem',
      fontWeight: 500,
      color: ColorPalette.grey[600],
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 700,
      letterSpacing: '1px',
      color: ColorPalette.text.primary,
    },
    h5: {
      fontFamily: 'Roboto',
      fontSize: '16px',
    },
    bigHeroText: {
      fontFamily: 'Poppins',
      fontSize: '34px',
      lineHeight: '1.875rem',
      fontWeight: 500,
      color: ColorPalette.grey[600],
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
      color: ColorPalette.text.primary,
    },
    subtitle2: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 500,
      color: ColorPalette.text.primary,
    },
    button: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
      color: ColorPalette.text.primary,
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '18px',
      color: ColorPalette.text.primary,
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  props: {},
};

const GozioTheme = createTheme(GozioThemeObject);

export default GozioTheme;
