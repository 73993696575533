import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AuthorizedApolloProvider from 'authorizedApolloProvider';
import Loading from 'components/loading/loading';
import { ErrorIcon, InfoIcon, Success, WarningIcon } from 'components/snackbarIcons';
import { FlamingoContext } from 'contexts/flamingo';
import { useWorkspace } from 'hooks/useWorkspace';
import LogRocket from 'logrocket';
import { SnackbarProvider } from 'notistack';
import AccountUpdate from 'pages/accountUpdate';
import UnauthorizedPage from 'pages/shared/UnauthorizedPage';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  snackBar: {
    marginBottom: '24px',
    pointerEvents: 'all',
  },
  snackBarContent: {
    '& .notistack-MuiContent': {
      backgroundColor: `${theme.palette.white} !important`,
      color: `${theme.palette.grey[500]} !important`,
      fontSize: '16px',
      letterSpacing: '0.16px',
      position: 'relative',

      '& #notistack-snackbar': {
        marginRight: '48px',
      },

      '& .MuiIconButton-root': {
        position: 'absolute',
        right: '2px',
        top: '2px',
      },

      '& .MuiIconButton-label': {
        '& svg': {
          color: theme.palette.grey[500],
          height: '22px',
          width: '22px',
        },
      },
    },
  },
}));

const App = () => {
  const classes = useStyles();
  const {
    setWorkspace,
    user: userContext,
    workspace: workspaceContext,
  } = useContext(FlamingoContext);
  const { workspace } = useWorkspace();
  const { isLoading, isAuthenticated, error, user } = useAuth0();
  const [userForAccountUpdate, setUserForAccountUpdate] = useState(null);

  useEffect(() => {
    if ((!workspaceContext && workspace) || workspaceContext !== workspace) {
      setWorkspace(workspace);
    }
  }, [setWorkspace, workspace, workspaceContext]);

  const appContent = useMemo(
    () => (
      <AuthorizedApolloProvider>
        <SnackbarProvider
          iconVariant={{
            success: Success,
            error: ErrorIcon,
            warning: WarningIcon,
            info: InfoIcon,
          }}
          classes={{
            root: classes.snackBar,
            containerRoot: classes.snackBarContent,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className={classes.root}>
            {userForAccountUpdate ? (
              <AccountUpdate user={userForAccountUpdate} />
            ) : (
              <Outlet />
            )}
          </Box>
        </SnackbarProvider>
      </AuthorizedApolloProvider>
    ),
    [classes, userForAccountUpdate],
  );

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const tempAuth0 = {
          ...isAuthenticated && { authenticated: isAuthenticated },
          ...!!user['https://auth.goziohealth.com/user'] && {
            user: {
              ...user['https://auth.goziohealth.com/user'],
              jsonLogicPermissions:
                user['https://auth.goziohealth.com/jsonLogicPermissions'],
            },
          },
        };

        if (!userForAccountUpdate && tempAuth0?.user?.resetPassword) {
          setUserForAccountUpdate(tempAuth0.user);
        }
        userContext.setUser(tempAuth0);

        // register user with log rocket
        if (!!process.env.REACT_APP_LOG_ROCKET && !!tempAuth0) {
          LogRocket.identify(tempAuth0?.user.id, {
            name: `${tempAuth0.user.firstName} ${tempAuth0.user.lastName}`,
            email: tempAuth0?.user.email,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <Loading fullPage />;
  }

  if (error) {
    console.error('[Auth0] Error:', error);
    return <UnauthorizedPage />;
  }

  return appContent;
};

export default App;
