import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DashboardGraphic from 'assets/dashboard_graphic.svg';
import { colorWithAlpha } from 'helpers/color-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundImage: `url(${DashboardGraphic})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '85%',
    width: '100%',
  },
  greeting: {
    color: colorWithAlpha(theme.palette.black, 0.6),
    fontSize: '60px',
    fontWeight: 300,
    letterSpacing: '-0.54px',
    marginTop: '24px',
  },
});

const WelcomePageContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={styles.root}>
      <Typography variant="h1" sx={styles.greeting}>
        Hey there!
      </Typography>
    </Box>
  );
};

export const WelcomePage = () => (
  <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
    <WelcomePageContent />
  </LoggedinLayout>
);

export default WelcomePage;
