import { Add, Edit } from '@mui/icons-material';
import { Box, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GozioTable from 'components/tables/gozioTable';
import TabPanel from 'components/tabPanel/tabPanel';
import { colorWithAlpha } from 'helpers/color-util';
import { convertStringToMMDDYYYY } from 'helpers/date-util';
import { tabA11yProps } from 'helpers/page-util';
import { getFullNameFromProfile } from 'helpers/string-util';
import { getTableCountTitle } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import {
  useFindNetworkDeepLink,
  useFindNetworkDeepLinkConfiguration,
} from 'hooks/dataHooks/useNetworkNetworkDeepLink';
import AddOrEditDeepLinkModal from 'pages/deepLinks/deepLink/AddOrEditDeepLinkModal';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 193px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  speedDialContainer: {
    marginLeft: theme.spacing(5),
  },
  tabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 69px)',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
  },
});

const getColumns = ({ data, setSelectedDeepLinkForEdit }) => [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Link Title',
    accessor: 'linkName', // accessor is the "key" in the data
    width: 100,
  },
  {
    Header: 'Short URL',
    accessor: 'referringLink',
    onClick: (data) => {
      if (data?.referringLink) {
        window.open(data.webUrl, '_blank').focus();
      }
    },
    width: 100,
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 100,
    disableSortBy: true,
  },
  {
    Header: 'Last Updated',
    accessor: 'updatedAt',
    minWidth: 80,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    minWidth: 55,
    width: 55,
    disableSortBy: true,
    justifyRight: true,
    Cell: ({ row }) => (
      <Box className="hoverUnhide">
        <Tooltip title="Edit Deep Link">
          <Edit
            onClick={() => {
              setSelectedDeepLinkForEdit(
                data.find((d) => d.id === row.original.id),
              );
            }}
          />
        </Tooltip>
      </Box>
    ),
  },
];

const DeepLinksLinkManagerPageContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { networkId } = useParams();
  const [showDeepLinkModal, setShowDeepLinkModal] = useState(false);
  const [selectedDeepLinkForEdit, setSelectedDeepLinkForEdit] = useState(null);
  const [data, setData] = useState();

  const { data: configData } = useFindNetworkDeepLinkConfiguration(networkId);
  const { data: deepLinkData, refetchData } = useFindNetworkDeepLink(networkId);

  useEffect(() => {
    if (deepLinkData && !data) setData(deepLinkData);
  }, [deepLinkData, data]);

  const count = data?.length;

  const closeDeepLinkModal = () => {
    setSelectedDeepLinkForEdit(null);
    setShowDeepLinkModal(false);
  };

  const memoizedData = useMemo(
    () => data?.map((n) => ({
        id: n.id,
        linkName: n.linkName,
        referringLink: n.referringLink,
        updatedBy: getFullNameFromProfile(n.updatedBy?.profile),
        updatedAt: convertStringToMMDDYYYY(n.updatedAt),
      })),
    [data],
  );

  return (
    <FlamingoPage
      pageName="Link Manager"
      headerButtons={
        configData?.id && (
          <AddCircleButton
            tooltipTitle="Add Deep Link"
            onClick={() => setShowDeepLinkModal(true)}
          />
        )
      }
    >
      <Box sx={styles.content}>
        <Grid sx={styles.tabs}>
          <Tabs
            value={0}
            aria-label="deep links tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Deep Links" {...tabA11yProps('deeplinks', 0)} />
          </Tabs>
        </Grid>
        <TabPanel value={0} index={0} sx={styles.tabPanel}>
          <Box sx={styles.tabBody}>
            <GozioTable
              name="DeepLinksTable"
              columns={getColumns({ data, setSelectedDeepLinkForEdit })}
              data={memoizedData}
              sortBy={[{ id: 'linkName', desc: false }]}
              sx={{ paddingTop: '8px' }}
              countTitle={
                configData?.id && count > 0
                  ? getTableCountTitle(count, 'Deep Link')
                  : ''
              }
              rowsPerPage={10}
              emptyContent={
                configData?.id ? (
                  <Box sx={{ height: `${window.innerHeight - 200}px` }}>
                    <EmptyPanel
                      title="You Have No Links"
                      buttonShown={true}
                      buttonLabel="Add a Link"
                      ButtonIcon={Add}
                      buttonClicked={() => setShowDeepLinkModal(true)}
                    />
                  </Box>
                ) : (
                  <EmptyPanel title="The Deep Links configuration has not been set up for this network." />
                )
              }
            />
          </Box>
        </TabPanel>
      </Box>
      {(showDeepLinkModal || selectedDeepLinkForEdit) && (
        <AddOrEditDeepLinkModal
          config={configData}
          data={selectedDeepLinkForEdit}
          handleClose={closeDeepLinkModal}
          networkId={networkId}
          onSave={async () => {
            setData(await refetchData());
            closeDeepLinkModal();
          }}
        />
      )}
    </FlamingoPage>
  );
};

const DeepLinksLinkManagerPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <DeepLinksLinkManagerPageContent />
  </LoggedinLayout>
);

export default DeepLinksLinkManagerPage;
