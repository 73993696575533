import { Box } from '@mui/material';
import GenericFormModal from 'components/genericModal/genericFormModal';
import LinkTypeAppointment from 'components/networkPlace/actionLinks/linkTypeAppointment';
import LinkTypeCustom from 'components/networkPlace/actionLinks/linkTypeCustom';
import LinkTypeInApp from 'components/networkPlace/actionLinks/linkTypeInApp';
import LinkTypePhone from 'components/networkPlace/actionLinks/linkTypePhone';
import LinkTypeWebsite from 'components/networkPlace/actionLinks/linkTypeWebsite';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from 'helpers/form-util';
import useWindowSize from 'hooks/useWindowSize';
import { makeValidate } from 'mui-rff';
import { submitParser } from 'pages/locations/containers/detail/addLinkHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const menuItems = [
  { id: 'phone', label: 'Phone Number', component: LinkTypePhone },
  { id: 'website', label: 'Website', component: LinkTypeWebsite },
  { id: 'appointment', label: 'Appointment', component: LinkTypeAppointment },
  { id: 'in_app', label: 'In-App Link', component: LinkTypeInApp },
  {
    id: 'custom',
    label: 'Custom Gozio Action Type',
    adminOnly: true,
    component: LinkTypeCustom,
  },
];

const buildStyles = () => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minWidth: '506px',
      maxWidth: '1024px',
    },
  },
  content: {
    marginBottom: 0,
    padding: '6px 32px !important',
    overflowY: 'auto',
  },
});

const AddNewActionLinkModal = ({ closeModal, handleSave, isUberAdmin }) => {
  const size = useWindowSize();
  const styles = buildStyles();

  const onSubmit = (values) => {
    const parsedVal = submitParser(values);
    handleSave(parsedVal);
  };

  const onClose = () => {
    closeModal();
  };

  return (
    <GenericFormModal
      sx={styles.root}
      contentSx={{
        ...styles.content,
        height: size.height < 850 ? `${size.height - 280}px` : 'none',
      }}
      title="Add Quicklink"
      formParams={{
        initialValues: {
          linkType: null,
          isUberAdmin,
        },
        onSubmit,
        validate: makeValidate(
          Yup.object().shape({
            linkType: Yup.string().required('Type is required'),
          }),
        ),
      }}
      body={({ values }) => (
        <Box
          sx={{
            paddingBottom: '32px',
          }}
        >
          <Field name="linkType">
            {({ input }) => (
              <GozioSelect
                input={{
                  value: input.value,
                  onChange: input.onChange,
                }}
                label={getFormLabel('Quicklink Type', true)}
                options={menuItems}
                isUberAdmin={values.isUberAdmin}
              />
            )}
          </Field>
        </Box>
      )}
      confirmText="Add Quicklink"
      handleClose={onClose}
    />
  );
};

AddNewActionLinkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isUberAdmin: PropTypes.bool,
};

export default React.memo(AddNewActionLinkModal);
