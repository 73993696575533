import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import { isGlobalTemplate } from 'helpers/network-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useSuspenseQueryWrapper } from 'hooks/dataHooks/useQueryWrapper';
import {
  CREATE_NETWORK,
  DELETE_NETWORK_THEME_COLOR,
  FIND_NETWORK,
  FIND_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL,
  FIND_NETWORK_CONFIGURATION_PUBLISHING_CONTROL,
  FIND_NETWORK_MOBILE_APP_TOKENS,
  GET_NETWORK_THEME_COLORS,
  GET_NETWORK_CONFIG,
  GET_NETWORK_CONFIGURATION_VERSIONS,
  UPDATE_NETWORK,
  UPDATE_NETWORK_CONFIG,
  UPDATE_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL,
  UPDATE_NETWORK_CONFIGURATION_PUBLISHING_CONTROL,
  UPDATE_NETWORK_ENABLEMENT,
  UPDATE_NETWORK_VERSION_CONTROL,
} from 'graphql/queries';

export const useUpdateNetwork = () => {
  const { handleError } = useHandleError('NetworkConfiguration');
  return useMutation(UPDATE_NETWORK, {
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK }],
    awaitRefetchQueries: true,
  });
};

export const useCreateNetwork = () => {
  const { handleError } = useHandleError('NetworkConfiguration');
  return useMutation(CREATE_NETWORK, {
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK }],
    awaitRefetchQueries: true,
  });
};

export const useUpdateNetworkEnablement = (networkId) => {
  const { handleError } = useHandleError('NetworkConfiguration');
  return useMutation(UPDATE_NETWORK_ENABLEMENT, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [{ query: FIND_NETWORK }],
    awaitRefetchQueries: true,
  });
};

export const useNetworkConfig = (networkId) => {
  const { handleError } = useHandleError('NetworkConfiguration');

  const { data, error } = useSuspenseQuery(GET_NETWORK_CONFIG, {
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    error,
    data: data?.getNetwork || null,
  };
};

export const useUpdateNetworkConfig = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkConfiguration');

  return useMutation(UPDATE_NETWORK_CONFIG, {
    errorPolicy: 'all',
    onCompleted: () => {
      toastNotificationSuccessHook('Network Configuration updated');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(
      graphQLErrors,
      networkError,
      'Network Configuration update failed',
    ),
    refetchQueries: [{ query: GET_NETWORK_CONFIG, variables: { networkId } }],
  });
};

export const useFindNetworkConfigurationGozioLinkControl = () => useSuspenseQueryWrapper(
  FIND_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL,
  {
    skipFn: (data) => data?.findNetworkConfiguration !== undefined,
  }
);

export const useUpdateNetworkConfigurationGozioLinkControl = (options = {}) => {
  const {
    onCompleted,
    onCompletedMessage,
    onErrorCustomCallback,
    onErrorMessage,
  } = options;
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkConfiguration');

  return useMutation(UPDATE_NETWORK_CONFIGURATION_GOZIO_LINK_CONTROL, {
    onCompleted:
      onCompleted
      || ((data) => {
        toastNotificationSuccessHook(
          onCompletedMessage
          || 'The deep link settings for this network have been updated.',
        );
      }),
    onError: ({ graphQLErrors, networkError }) => {
      if (onErrorCustomCallback) onErrorCustomCallback({ graphQLErrors, networkError });
      handleError(graphQLErrors, networkError, null, onErrorMessage);
    },
  });
};

export const useNetworkPublishingControl = (networkId) => {
  const { handleError } = useHandleError('Publishing');
  const { data, error, refetch } = useSuspenseQuery(FIND_NETWORK_CONFIGURATION_PUBLISHING_CONTROL, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    skip: isGlobalTemplate(networkId),
  });

  return {
    data: {
      networkId: data?.findNetworkConfiguration?.edges[0]?.node?.network?.id,
      live: data?.findNetworkConfiguration?.edges[0]?.node?.publishingControl
        ?.liveWorkspace,
      draft:
      data?.findNetworkConfiguration?.edges[0]?.node?.publishingControl
        ?.draftWorkspace,
    },
    error,
    refetch,
  };
};

export const useUpdateNetworkPublishingControl = (options = {}) => {
  const {
    onCompleted,
    onCompletedMessage,
    onErrorCustomCallback,
    onErrorMessage,
  } = options;
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkConfiguration');

  return useMutation(UPDATE_NETWORK_CONFIGURATION_PUBLISHING_CONTROL, {
    onCompleted:
      onCompleted
      || ((data) => {
        toastNotificationSuccessHook(
          onCompletedMessage
          || 'The publishing settings for this network have been updated.',
        );
      }),
    onError: ({ graphQLErrors, networkError }) => {
      if (onErrorCustomCallback) onErrorCustomCallback({ graphQLErrors, networkError });
      handleError(graphQLErrors, networkError, null, onErrorMessage);
    },
  });
};

export const useUpdateNetworkConfiguration = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkConfiguration');

  return useMutation(UPDATE_NETWORK_VERSION_CONTROL, {
    errorPolicy: 'all',
    update: (cache, { data: { setNetworkVersionControl } }) => {
      try {
        cache.writeQuery({
          query: GET_NETWORK_CONFIGURATION_VERSIONS,
          variables: { networkId: networkId },
          data: { getNetwork: setNetworkVersionControl },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onCompleted: () => {
      toastNotificationSuccessHook('Versions Updated');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Versions update failed'),
  });
};

export const useNetworkMobileAppTokens = (networkId) => {
  const { handleError } = useHandleError('NetworkConfiguration');

  const { data, error, loading, refetch } = useQuery(FIND_NETWORK_MOBILE_APP_TOKENS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    loading,
    error,
    data: data?.findNetworkMobileApp?.edges?.map((n) => n.node) || null,
    refetch,
  };
};

export const useNetworkColors = (networkId) => useQuery(GET_NETWORK_THEME_COLORS, {
    variables: { networkId },
  });

export const useDeleteNetworkColors = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('NetworkConfiguration');
  return useMutation(DELETE_NETWORK_THEME_COLOR, {
    update: (cache, { data: { deleteNetworkThemeColor } }) => {
      try {
        cache.writeQuery({
          query: GET_NETWORK_THEME_COLORS,
          variables: { networkId },
          data: { getNetwork: deleteNetworkThemeColor },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onCompleted: () => {
      toastNotificationSuccessHook('Color deleted');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Color delete failed'),
  });
};

export const useNetworkConfiguration = (networkId) => {
  const { handleError } = useHandleError('NetworkConfiguration');

  const { data, error, loading } = useQuery(GET_NETWORK_CONFIGURATION_VERSIONS, {
    errorPolicy: 'all',
    variables: { networkId: networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  return {
    loading,
    error,
    data: data?.getNetwork?.configuration || null,
  };
};
