import { CircularProgress, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GenericModal from 'components/genericModal/genericModal';
import GozioSelect from 'components/selector/selector';
import { rolePublicName, USER_ROLES } from 'helpers/permissions-util';
import { useUpdateUser } from 'hooks/dataHooks/useUsers';
import useToast from 'hooks/useToast';
import _ from 'lodash';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  role: Yup.string().required(),
  email: Yup.string()
    .email('Email Address must be a valid email.')
    .required('Email Address is required.'),
});
const validate = makeValidate(schema);

const buildStyles = ({ theme }) => ({
  root: {
    '& .MuiPaper-root': {
      width: '792px',
      maxWidth: 'none',

      '& .MuiDialogContent-root': {
        width: '100%',
      },
    },
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(3),
    marginTop: `-${theme.spacing(2)}`,
    textAlign: 'left',
  },
  loading: {
    color: theme.palette.white,
  },
  secondRow: {
    paddingTop: `${theme.spacing(2)} !important`,
  },
});

const getUserName = (user) => {
  if (!user?.profile?.firstName) {
    return 'this user';
  }

  return `${user?.profile?.firstName} ${user?.profile?.lastName || ''}`;
};

const EditUserModal = ({
                         initialValues,
                         title,
                         confirmButtonText,
                         onUserUpdated,
                         onCancelled,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { user, state } = initialValues;
  const [initialVal, setInitialVal] = useState({});
  const [updating, setUpdating] = useState(false);
  const { networkId } = useParams();
  const [updateUser] = useUpdateUser(networkId);
  const { toastNotificationSuccessHook } = useToast();

  const isRestore = state === 'restore';

  const onSubmit = async (values) => {
    setUpdating(true);

    const variables = {
      id: values?.id,
      input: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        role: values?.role,
      },
    };

    if (isRestore) {
      variables.meta = { action: 'restore' };
    }

    const response = await updateUser({
      variables,
    });
    if (response?.data) {
      setUpdating(false);
      const userName = `${
        response.data?.updateUser?.profile?.firstName || ''
      } ${response.data?.updateUser?.profile?.lastName || ''}`;
      if (isRestore) {
        toastNotificationSuccessHook(`${userName} has been restored.`);
      } else {
        toastNotificationSuccessHook(`${userName} has been updated.`);
      }
      onUserUpdated();
    } else {
      setUpdating(false);
    }
  };

  useEffect(() => {
    const tempVal = {
      id: user?.id,
      firstName: user?.profile?.firstName,
      lastName: user?.profile?.lastName,
      email: user?.email?.address,
      role: `${_.get(user, 'roles[0].name', '')}`,
    };
    setInitialVal(tempVal);
  }, [user]);

  return (
    <Form onSubmit={onSubmit} initialValues={initialVal} validate={validate}>
      {({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <GenericModal
            sx={styles.root}
            title={title}
            open={true}
            body={
              <Grid container spacing={2} sx={styles.content}>
                {isRestore && (
                  <Grid item xs={12} sx={styles.description}>
                    <Typography variant="body1">
                      You are restoring {getUserName(user)} from an archived
                      state. Before sending off new credentials, would you like
                      to update the information on file?
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    required={true}
                  />
                </Grid>
                <Grid item xs={6} sx={styles.secondRow}>
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    disabled={true}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6} sx={styles.secondRow}>
                  <Field
                    name="role"
                    component={({ input }) => (
                      <GozioSelect
                        input={input}
                        label="Role"
                        disabled={true}
                        options={[
                          {
                            id: USER_ROLES.SDK_ADMIN,
                            label: rolePublicName(USER_ROLES.SDK_ADMIN),
                          },
                          {
                            id: USER_ROLES.NETWORK_ADMIN,
                            label: rolePublicName(USER_ROLES.NETWORK_ADMIN),
                          },
                          {
                            id: USER_ROLES.UBER_ADMIN,
                            label: rolePublicName(USER_ROLES.UBER_ADMIN),
                          },
                        ]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            }
            confirmText={
              submitting || updating ? (
                <CircularProgress size={14} sx={styles.loading} />
              )
                : confirmButtonText

            }
            confirmProgress={submitting || updating}
            greyOutCancel={submitting || updating}
            greyOutConfirm={invalid || submitting || updating}
            handleConfirm={handleSubmit}
            handleClose={onCancelled}
          />
        </form>
      )}
    </Form>
  );
};

EditUserModal.propTypes = {
  initialValues: PropTypes.shape({
    user: PropTypes.object.isRequired,
    state: PropTypes.oneOf(['edit', 'restore']).isRequired,
  }),
  onUserUpdated: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
};

EditUserModal.defaultProps = {
  title: 'Edit User',
  confirmButtonText: 'Confirm',
};

export default EditUserModal;
