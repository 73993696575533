import { useMutation } from '@apollo/client';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SCOPES } from 'components/authorization/authorize';
import Condition from 'components/forms/condition';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import NumberField from 'components/numberField/numberField';
import GozioSelect from 'components/selector/selector';
import TextField from 'components/textField/textField';
import { FlamingoContext } from 'contexts/flamingo';
import { CREATE_NETWORK_NOTIFICATION } from 'graphql/queries';
import { getFormLabel } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { makeValidate, Radios, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';

const PUSH_NOTIFICATION_ERROR_FORM_FIELD_MAP = new Map([
  ['radius', { label: 'Radius', section: 'Audience' }],
  ['lat', { label: 'Latitude', section: 'Audience' }],
  ['long', { label: 'Longitude', section: 'Audience' }],
  ['titleEN', { label: 'Title', section: 'Content' }],
  ['messageEN', { label: 'Message', section: 'Content' }],
  ['titleES', { label: 'Title', section: 'Content (Spanish)' }],
  ['messageES', { label: 'Message', section: 'Content (Spanish)' }],
]);

const buildStyles = ({ theme }) => ({
  root: {
    paddingBottom: '70px',
  },
  footer: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: '16px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: theme.palette.grey[50],
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    zIndex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formRoot: {},
  geoForm: {
    marginLeft: theme.spacing(4),
  },
  discardBtn: {
    marginLeft: '24px',
  },
  saveBtn: {
    marginRight: '8px',
  },
  sectionHeading: {
    marginTop: '24px',
  },
  sectionField: {
    marginTop: '16px',
  },
});

const Send = ({ networkId, data }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('PushNotification');

  const [appID, setAppID] = useState('');
  const [esEnabled, setesEnabled] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { authorize } = useContext(FlamingoContext);

  const languages = useActiveNetworkLanguages();

  const initialValues = {
    langES: esEnabled,
    titleEN: null,
    titleES: null,
    messageEN: null,
    messageES: null,
    audience: 'all',
    appID: appID,
    geoType: 'inside',
    radius: 0,
  };

  const appIdField = useMemo(() => (
    <Field
      name="appID"
      component={({ input }) => (
        <GozioSelect
          input={input}
          label="App ID/Version"
          options={
            data?.getNetwork?.mobileApps
              ? data.getNetwork.mobileApps.map((appID) => ({
                id: appID.id,
                label: appID.name,
              }))
              : [{ id: '', label: 'None' }]
          }
        />
      )}
    />
  ), [data]);

  const validate = languages
    ? makeValidate(
      Yup.object().shape(
        buildLanguageSchema(
          {
            titleEN: Yup.string()
              .nullable()
              .max(40, 'Title must be 40 characters or less')
              .required('Title is required'),
            messageEN: Yup.string()
              .nullable()
              .max(200, 'Message must be 200 characters or less')
              .required('Message is required'),
            linkEN: Yup.string().matches(/^\w+:(\/?\/?)[^\s]+/i, {
              message:
                'URL must include http or https, e.g. https://www.goziohealth.com',
              excludeEmptyString: true,
            }),
            titleES: Yup.string()
              .nullable()
              .when('langES', {
                is: true,
                then: (schema) => schema
                    .max(40, 'Title must be 40 characters or less')
                    .required('Title is required'),
              }),
            messageES: Yup.string()
              .nullable()
              .when('langES', {
                is: true,
                then: (schema) => schema
                    .max(200, 'Message must be 200 characters or less')
                    .required('Message is required'),
              }),
            linkES: Yup.string().matches(/^\w+:(\/?\/?)[^\s]+/i, {
              message:
                'URL must include http or https, e.g. https://www.goziohealth.com',
              excludeEmptyString: true,
            }),
            radius: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema.required().positive(),
            }),
            lat: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema
                  .min(-90, 'Latitude values must be between -90 to 90')
                  .max(90, 'Latitude values must be between -90 to 90')
                  .required('Latitude is required'),
            }),
            long: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema
                  .min(-180, 'Longitude values must be between -180 to 180')
                  .max(180, 'Longitude values must be between -180 to 180')
                  .required('Longitude is required'),
            }),
          },
          languages,
          {
            titleEN: LANGUAGE_CODES.ENGLISH,
            messageEN: LANGUAGE_CODES.ENGLISH,
            linkEN: LANGUAGE_CODES.ENGLISH,
            titleES: LANGUAGE_CODES.SPANISH,
            messageES: LANGUAGE_CODES.SPANISH,
            linkES: LANGUAGE_CODES.SPANISH,
          },
        ),
      ),
    )
    : null;

  const [sendNotification] = useMutation(CREATE_NETWORK_NOTIFICATION, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onCompleted: (data) => {
      toastNotificationSuccessHook('Notification Sent');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Message failed to send'),
  });

  useEffect(() => {
    if (languages) {
      setesEnabled(includes(languages, LANGUAGE_CODES.SPANISH));
    }
  }, [languages]);

  useEffect(() => {
    if (data?.getNetwork?.mobileApps) {
      const customerReleaseApp = find(data.getNetwork.mobileApps, [
        'type',
        'customerRelease',
      ]);
      setAppID(customerReleaseApp ? customerReleaseApp.id : '');
    }
  }, [data]);

  const onSubmit = async (values) => {
    const newMessage = {
      mobileAppId: values.appID || appID,
      title: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.titleEN }],
      body: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.messageEN }],
      actionUrl: [],
    };
    if (values.linkEN) newMessage.actionUrl.push({
        lang: LANGUAGE_CODES.ENGLISH,
        label: values.linkEN,
      });
    if (esEnabled) {
      newMessage.title.push({
        lang: LANGUAGE_CODES.SPANISH,
        label: values.titleES,
      });
      newMessage.body.push({
        lang: LANGUAGE_CODES.SPANISH,
        label: values.messageES,
      });
      if (values.linkES) newMessage.actionUrl.push({
          lang: LANGUAGE_CODES.SPANISH,
          label: values.linkES,
        });
    }
    if (values.audience === 'geo') {
      newMessage.geofence = {
        type: values.geoType,
        latitude: parseFloat(values.lat),
        longitude: parseFloat(values.long),
        radius: Math.floor(parseFloat(values.radius) * 1609),
      };
    }
    // console.log('sendNotification:', newMessage);
    await sendNotification({ variables: { input: newMessage } });
  };

  const onFormReset = (form) => {
    form.resetFieldState('titleEN');
    form.resetFieldState('messageEN');
    form.resetFieldState('linkEN');
    if (esEnabled) {
      form.resetFieldState('titleES');
      form.resetFieldState('messageES');
      form.resetFieldState('linkES');
    }
    form.reset();
  };

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <Grid container direction="column" spacing={2} sx={styles.root}>
      <Grid item>
        <Typography variant="subtitle1">New Push Notification</Typography>
      </Grid>
      <Grid item sx={{ paddingBottom: 0 }}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, invalid, values }) => (
            <form
              onSubmit={async (event) => {
                await handleSubmit(event);
                onFormReset(form);
              }}
            >
              {!pristine && (
                <FormWatcher
                  allowContinueOnError
                  formRenderProps={{ form }}
                  errorFormFieldMap={PUSH_NOTIFICATION_ERROR_FORM_FIELD_MAP}
                  isClosing={isClosing}
                  isSubmitting={isSubmitting}
                  onClose={() => {
                    setIsClosing(false);
                    setIsSubmitting(false);
                  }}
                  unsavedConfirmText="Send Message"
                  continueAndDeleteText="Continue & Delete Work"
                />
              )}
              <Grid item xs={12}>
                {authorize(SCOPES.PUSH_NOTIFICATIONS.HAS_APP_ID) && (
                  <Grid item xs={6}>
                    {appIdField}
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginBottom: '12px', marginTop: '24px' }}
                  >
                    Audience
                  </Typography>
                  <Radios
                    name="audience"
                    color="primary"
                    data={[
                      { label: 'All users', value: 'all' },
                      { label: 'Users in a geographic area', value: 'geo' },
                    ]}
                  />
                </Grid>
                <Condition when="audience" is="geo">
                  <Grid
                    container
                    direction="column"
                    sx={styles.geoForm}
                    spacing={1}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Field
                          name="geoType"
                          component={({ input }) => (
                            <GozioSelect
                              input={input}
                              label="Geo Type"
                              options={[
                                { id: 'inside', label: 'include' },
                                { id: 'outside', label: 'exclude' },
                              ]}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          all users within
                        </Typography>
                      </Grid>
                      <Grid item>
                        <NumberField
                          label={getFormLabel('Radius', true)}
                          name="radius"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">miles of</Typography>
                      </Grid>
                      <Grid item>
                        <NumberField
                          label={getFormLabel('Latitude', true)}
                          name="lat"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <NumberField
                          label={getFormLabel('Longitude', true)}
                          name="long"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Link
                        href="https://www.gps-coordinates.net/"
                        target="_blank"
                      >
                        Find your latitude and longitude coordinates
                      </Link>
                    </Grid>
                  </Grid>
                </Condition>

                <Grid item xs={6}>
                  <Grid item xs={12} sx={styles.sectionHeading}>
                    <Typography variant="subtitle1">Content (English)</Typography>
                  </Grid>
                  <Grid item sx={styles.sectionField}>
                    <Field name="titleEN">
                      {({ input, meta }) => (
                        <TextField
                          label={getFormLabel('Title', true)}
                          charLimit={40}
                          helperText={meta.touched ? meta.error : ''}
                          error={meta.touched && !!meta.error}
                          {...input}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item sx={styles.sectionField}>
                    <Field name="messageEN">
                      {({ input, meta }) => (
                        <TextField
                          label={getFormLabel('Message', true)}
                          charLimit={200}
                          helperText={meta.touched ? meta.error : ''}
                          error={meta.touched && !!meta.error}
                          {...input}
                          fullWidth
                          multiline
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item sx={styles.sectionField}>
                    <FinalTextField
                      label="Web link (optional)"
                      name="linkEN"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Condition when="langES" is={true}>
                  <Grid item xs={6}>
                    <Grid item sx={styles.sectionHeading}>
                      <Typography variant="subtitle1">
                        Content (Spanish)
                      </Typography>
                    </Grid>
                    <Grid item sx={styles.sectionField}>
                      <Field name="titleES">
                        {({ input, meta }) => (
                          <TextField
                            label={getFormLabel('Title', true, true)}
                            charLimit={40}
                            helperText={meta.touched ? meta.error : ''}
                            error={meta.touched && !!meta.error}
                            {...input}
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sx={styles.sectionField}>
                      <Field name="messageES">
                        {({ input, meta }) => (
                          <TextField
                            label={getFormLabel('Message', true, true)}
                            charLimit={200}
                            helperText={meta.touched ? meta.error : ''}
                            error={meta.touched && !!meta.error}
                            {...input}
                            fullWidth
                            multiline
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item sx={styles.sectionField}>
                      <FinalTextField
                        label="Web link (optional)"
                        name="linkES"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Condition>
                <Box sx={styles.footer}>
                  <Button
                    sx={styles.discardBtn}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      onFormReset(form);
                    }}
                    color="primary"
                  >
                    Delete
                  </Button>
                  <Button
                    sx={styles.saveBtn}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || pristine || invalid}
                  >
                    Send Message
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

Send.propTypes = {
  networkId: PropTypes.string,
  data: PropTypes.object,
};

Send.defaultProps = {
  networkId: '',
};

export default Send;
