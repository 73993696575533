import { Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit } from '@mui/icons-material';
import GozioTable from 'components/tables/gozioTable';
import { capitalize } from 'helpers/lang-util';
import { useGetEnvironment } from 'hooks/dataHooks/useEnvironment';
import EditEnvModal from 'pages/network/configuration/containers/editEnvModal';
import React, { useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100% - 12px)',
    overflow: 'scroll',
  },
}));

const COLUMNS = [
  {
    Header: 'ENV',
    accessor: 'env',
    alwaysHidden: true,
  },
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    minWidth: 60,
    width: 60,
    disableSortBy: true,
    justifyRight: true,
  },
];

const ConfigTable = ({ env, doEdit }) => {
  const memoizedData = useMemo(
    () => Object.keys(env)
        .filter((key) => key !== 'env' && key !== '__typename')
        .map((key) => ({
          env: env.env,
          key,
          value: env[key],
          actions: (
            <Box className="hoverUnhide">
              <Tooltip title="Edit">
                <Edit
                  onClick={() => doEdit({ env: env.env, key, value: env[key] })}
                />
              </Tooltip>
            </Box>
          ),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [env],
  );

  return (
    <Box sx={{ marginTop: '12px' }}>
      <GozioTable
        key={env?.env}
        name={`ConfigTable-${env?.env}`}
        columns={COLUMNS}
        data={memoizedData}
        sortBy={[{ id: 'key', desc: false }]}
        countTitle={env?.env === 'qa' ? 'QA' : capitalize(env?.env)}
      />
    </Box>
  );
};

export const Environment = (props) => {
  const classes = useStyles();
  const [editedEnv, setEditedEnv] = useState(null);

  const { data, refetch } = useGetEnvironment(props.networkId);

  const doEdit = (env) => setEditedEnv(env);

  const handleClose = async () => {
    await refetch();
    setEditedEnv(null);
  };

  return (
    <Box className={classes.root}>
      {data.map((env) => (
        <ConfigTable
          key={`config-table-${env.env}`}
          env={env}
          doEdit={doEdit}
        />
      ))}
      {editedEnv && (
        <EditEnvModal env={editedEnv} data={data} handleClose={handleClose} />
      )}
    </Box>
  );
};

export default Environment;
