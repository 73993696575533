import {
  useLazyQuery,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import { getLabelByLang } from 'helpers/lang-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useMemo } from 'react';
import { useCustomQuery } from 'hooks/dataHooks/useCustomQuery';
import {
  CREATE_CATEGORY,
  FIND_NETWORK_PLACE_BY_CATEGORY,
  FIND_PRIMARY_CATEGORIES,
  GET_CATEGORY,
  GET_CATEGORY_GROUP,
  REPLACE_CATEGORY,
  REPLACE_CATEGORY_GROUP,
  SET_CATEGORY_TAGS,
} from 'graphql/queries';

export const useCategories = () => useCustomQuery(FIND_PRIMARY_CATEGORIES, {
  dataFn: useMemo(() => (data) => {
    if (data?.findPrimaryCategories) {
      return data.findPrimaryCategories.map((cat) => ({
        ...cat,
        children: cat.children?.edges?.map((child) => child.node) || [],
      }));
    }
  }, []),
  initialState: [],
  returnFn: useMemo(() => ({ data, error, loading }) => ({ categories: data, error, loading }), []),
  skipFn: (data) => data?.length > 0,
});

export const useCategoryGroups = (networkId) => {
  const { data, error } = useSuspenseQuery(FIND_PRIMARY_CATEGORIES, {
    context: { headers: { network: networkId } },
    errorPolicy: 'all',
  });

  const getCategories = (groups) => groups.reduce((cats, group) => cats.concat(group?.children || []), []);

  const groups
    = data?.findPrimaryCategories?.map((cat) => ({
      ...cat,
      children: cat.children?.edges?.map(({ node }) => node) || [],
    })) || [];

  return {
    error,
    groups: groups,
    categories: getCategories(groups),
  };
};

export const useGetCategory = ({ id }) => useCustomQuery(GET_CATEGORY, {
  dataFn: useMemo(() => (data) => data?.getCategory, []),
  initialState: [],
  useQueryOptions: {
    variables: { id },
  },
});

export const useGetCategoryGroup = ({ id }) => useCustomQuery(GET_CATEGORY_GROUP, {
  dataFn: useMemo(() => (data) => {
    if (data?.getCategory) {
      return {
        ...data.getCategory,
        children:
          data.getCategory.children?.edges?.map(({ node }) => node)
          || [],
      };
    }
  }, []),
  initialState: [],
  useQueryOptions: {
    variables: { id },
  },
});

export const useUpdateCategory = (gql = REPLACE_CATEGORY) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Categories');

  return useMutation(gql, {
    onCompleted: (data) => {
      const name = getLabelByLang(data?.replaceCategory?.name);
      toastNotificationSuccessHook(
        `All changes within ${name || 'category'} have been saved.`,
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateCategoryGroup = () => useUpdateCategory(REPLACE_CATEGORY_GROUP);

export const useUpdateCategoryTags = () => {
  const { handleError } = useHandleError('Categories');

  return useMutation(SET_CATEGORY_TAGS, {
    refetchQueries: [{ query: FIND_PRIMARY_CATEGORIES, variables: {} }],
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateCategory = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Categories');

  return useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: FIND_PRIMARY_CATEGORIES, variables: {} }],
    onCompleted: (data) => {
      toastNotificationSuccessHook('The Category has been created.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useFindNetworkPlacesByCategories = (ids) => {
  const { handleError } = useHandleError('Network Place');
  return useLazyQuery(FIND_NETWORK_PLACE_BY_CATEGORY, {
    variables: {
      ids,
    },
    onError: handleError,
  });
};

export default useCategories;
