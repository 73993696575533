import { Grid } from '@mui/material';
import GenericFormModal from 'components/genericModal/genericFormModal';
import { getFormLabel } from 'helpers/form-util';
import { useSetEnvironment } from 'hooks/dataHooks/useEnvironment';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import * as Yup from 'yup';

const generalSchema = Yup.object().shape({
  key: Yup.string().required('Key is required'),
  value: Yup.string().required('Value is required'),
});

const validate = makeValidate(generalSchema);

const EditEnvModal = ({ env, data, handleClose }) => {
  const [setEnv] = useSetEnvironment();

  const handleSave = async (values) => {
    const foundEnv = data.find(({ env }) => env === values.env);
    const modifiedEnvObj = { ...foundEnv };
    modifiedEnvObj[values.key] = values.value;
    delete modifiedEnvObj.__typename;
    const result = await setEnv({
      variables: {
        envInput: modifiedEnvObj,
      },
    });

    if (result.data) handleClose();
  };

  return (
    <GenericFormModal
      title="Edit Environment"
      open={true}
      formParams={{
        onSubmit: handleSave,
        validate,
        initialValues: {
          key: env?.key,
          value: env?.value,
          env: env?.env,
        },
      }}
      body={
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              label={getFormLabel('Key', true)}
              name="key"
              variant="outlined"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label={getFormLabel('Value', true)}
              name="value"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      }
      confirmText="Save"
      handleClose={handleClose}
    />
  );
};

export default EditEnvModal;
