import { formatLang, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { allowedCharacterRegex } from 'helpers/location-util';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

export const buildValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          // MAPPING INPUTS
          mapID: Yup.number()
            .typeError('Must be a numeric value')
            .min(1, 'Value must be greater than 1'),
          mapBoundary: Yup.array().test(
            'len',
            'Must have 4 values',
            (val) => !val || val.length === 0 || val.length === 4,
          ),
          'mapBoundary[0]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[0]
                || !isNaN(this?.parent?.mapBoundary?.[0])
              );
            })
            .test(
              'inclusive',
              'West value must be a numeric value between -180 and 180 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[0]
                  || (this?.parent?.mapBoundary?.[0] >= -180
                    && this?.parent?.mapBoundary?.[0] <= 180)
                );
              },
            ), // West
          'mapBoundary[1]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[1]
                || !isNaN(this?.parent?.mapBoundary?.[1])
              );
            })
            .test(
              'inclusive',
              'South value must be a numeric value between -90 and 90 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[1]
                  || (this?.parent?.mapBoundary?.[1] >= -90
                    && this?.parent?.mapBoundary?.[1] <= 90)
                );
              },
            ), // South
          'mapBoundary[2]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[2]
                || !isNaN(this?.parent?.mapBoundary?.[2])
              );
            })
            .test(
              'inclusive',
              'East value must be a numeric value between -180 and 180 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[2]
                  || (this?.parent?.mapBoundary?.[2] >= -180
                    && this?.parent?.mapBoundary?.[2] <= 180)
                );
              },
            ), // East
          'mapBoundary[3]': Yup.number()
            .nullable()
            .test('numeric', 'Must be a numeric value', function() {
              return (
                !this?.parent?.mapBoundary?.[3]
                || !isNaN(this?.parent?.mapBoundary?.[3])
              );
            })
            .test(
              'inclusive',
              'North value must be a numeric value between -90 and 90 (inclusive)',
              function() {
                return (
                  !this?.parent?.mapBoundary?.[3]
                  || (this?.parent?.mapBoundary?.[3] >= -90
                    && this?.parent?.mapBoundary?.[3] <= 90)
                );
              },
            ), // North

          // Floor DETAILS
          name: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .required('Site name is required'),
          shortName: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
          shortNameES: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
        },
        languages,
        {
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

export const initialParser = (FloorData, languages) => {
  const tempVal = {
    designation: FloorData?.designation,
    saveClose: false,
    saveBack: null,
    isMapped: FloorData?.isMapped || false,
    langES: hasSpanish(languages) || false,

    // MAPPING INPUTS
    ...!!FloorData?.indoorLocation?.mapId && {
      mapID: FloorData?.indoorLocation?.mapId,
    },
    mapBoundary: [
      FloorData?.osmMapInfo?.boundWest || 0,
      FloorData?.osmMapInfo?.boundSouth || 0,
      FloorData?.osmMapInfo?.boundEast || 0,
      FloorData?.osmMapInfo?.boundNorth || 0,
    ],

    // Floor DETAILS
    externalId: FloorData?.externalId,
    ...!!parseByLang(FloorData?.name) && {
      name: parseByLang(FloorData?.name),
    },
    ...!!parseByLang(FloorData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(FloorData?.name, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(FloorData?.shortName) && {
      shortName: parseByLang(FloorData?.shortName),
    },
    ...!!parseByLang(FloorData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(FloorData?.shortName, LANGUAGE_CODES.SPANISH),
    },

    // Parent Info
    parentSite: FloorData?.parentSite
      ? `${parseByLang(FloorData.parentSite.name)} (${
        FloorData.parentSite.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,
    parentBuilding: FloorData?.parentBuilding
      ? `${parseByLang(FloorData.parentBuilding.name)} (${
        FloorData.parentBuilding.isMapped ? 'Mapped' : 'Unmapped'
      })`
      : null,
  };
  return tempVal;
};

export const submitParser = async (dispatch, values = {}) => {
  const submitVal = {
    // MAPPING INPUTS
    ...values.isMapped && {
      indoorLocation: { mapId: Number(values?.mapID) || null },
    },
    osmMapInfo: {
      ...!!values.mapBoundary[0] && {
        boundWest: Number(values?.mapBoundary[0]),
      },
      ...!!values.mapBoundary[1] && {
        boundSouth: Number(values?.mapBoundary[1]),
      },
      ...!!values.mapBoundary[2] && {
        boundEast: Number(values?.mapBoundary[2]),
      },
      ...!!values.mapBoundary[3] && {
        boundNorth: Number(values?.mapBoundary[3]),
      },
    },
    // Floor DETAILS
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
  };
  return submitVal;
};
