import { Add, AddCircle, AddPhotoAlternate, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmptySection from 'components/emptySection/emptySection';
import GozioTable from 'components/tables/gozioTable';
import { capitalize, LANGUAGE_CODES } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import React, { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '16px 16px 24px 24px',
    overflow: 'hidden',
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
  },
  imageDiv: {
    height: '38px',
    width: '38px',
    borderRadius: '90px',
    backgroundColor: theme.palette.grey[350],
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tileImage: {
    height: '20px',
    width: '20px',
  },
}));

const TileActions = ({ val, canEdit, canDelete, editTile, deleteTile }) => (
  <Box className="hoverUnhide">
    {canEdit && editTile && (
      <Tooltip title="Edit">
        <Edit
          onClick={() => {
            editTile(val);
          }}
          sx={{ fontSize: '24px' }}
        />
      </Tooltip>
    )}
    {canDelete && deleteTile && (
      <Tooltip title="Delete">
        <Delete
          onClick={() => {
            deleteTile(val);
          }}
          sx={{ fontSize: '24px', marginLeft: '8px' }}
        />
      </Tooltip>
    )}
  </Box>
);

const TileList = React.memo(
  ({ tiles, classes, onReorderTiles, onEditTile, onDeleteTile }) => {
    const COLUMNS = useMemo(
      () => [
        {
          Header: 'Directory Tile Name',
          accessor: 'name',
          minWidth: 210,
          width: 210,
          disableSortBy: true,
        },
        {
          Header: 'progress',
          accessor: 'progress',
          minWidth: 88,
          width: 88,
          disableSortBy: true,
        },
        {
          Header: 'Actions',
          accessor: 'actions',
          minWidth: 75,
          width: 75,
          disableSortBy: true,
          justifyRight: true,
        },
      ],
      [],
    );

    const generateName = (tile) => (
      <Box className={classes.nameRow}>
        <Box className={classes.imageDiv}>
          {tile?.icon?.url ? (
            <img
              src={tile.icon.url}
              className={classes.tileImage}
              alt={`tile-${tile.name}`}
            />
          ) : (
            <AddPhotoAlternate
              sx={{ color: ColorPalette.white, fontSize: '24px' }}
            />
          )}
        </Box>
        <span>{tile.name}</span>
      </Box>
    );

    const processData = (tiles = [], lang = LANGUAGE_CODES.ENGLISH) => (tiles || []).map((t, idx) => ({
        idx: idx,
        name: generateName(t),
        progress: capitalize(t.progress),
        actions: (
          <TileActions
            val={t}
            canEdit={true}
            canDelete={true}
            editTile={onEditTile}
            deleteTile={onDeleteTile}
          />
        ),
        order: t.order,
      }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = useMemo(() => processData(tiles), [tiles]);

    return (
      <Box
        sx={{
          marginLeft: '-8px',
          marginRight: '-32px',
          paddingRight: '8px',
          height: 'calc(100% - 40px)',
        }}
      >
        <GozioTable
          columns={COLUMNS}
          data={data}
          tableSx={{
            marginLeft: '-8px',
            marginRight: '-32px',
            paddingRight: '8px',
            height: 'calc(100% - 40px)',
          }}
          hidePagination={true}
          draggable={true}
          onDrop={onReorderTiles}
        />
      </Box>
    );
  },
);

const KioskTileListing = ({
                            kiosk,
                            onEditTile,
                            onDeleteTile,
                            onReorderTiles,
                          }) => {
  const classes = useStyles();
  const tileLength = kiosk?.directory?.tiles?.length || 0;

  return (
    <Box className={classes.root}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '14px',
        }}
      >
        <Typography variant="subtitle1" className="required">
          {tileLength}/12 Directory Tiles
        </Typography>
        {tileLength > 0 && (
          <IconButton
            onClick={() => onEditTile({})}
            aria-label="add-tile"
            sx={{ padding: 0 }}
            disabled={tileLength > 11}
            size="large"
          >
            <AddCircle
              sx={{
                color:
                  tileLength > 11
                    ? ColorPalette.grey[300]
                    : ColorPalette.primary.main,
                fontSize: '40px',
              }}
            />
          </IconButton>
        )}
      </Box>
      {tileLength === 0 ? (
        <EmptySection
          title="There Are No Directory Tiles Set Up"
          buttonLabel="Add A Directory Tile"
          hasPermission
          ButtonIcon={Add}
          buttonClicked={() => {
            onEditTile({});
          }}
        />
      ) : (
        <TileList
          tiles={kiosk.directory.tiles}
          onReorderTiles={onReorderTiles}
          onEditTile={onEditTile}
          onDeleteTile={onDeleteTile}
          classes={classes}
        />
      )}
    </Box>
  );
};

KioskTileListing.propTypes = {};

KioskTileListing.defaultProps = {};

export default React.memo(KioskTileListing);
