import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import { A2C_DISPLAY_TYPES, CARD_GROUP_TYPES } from 'components/dashboard/cardLayouts/cardhelper';
import EmptySection from 'components/emptySection/emptySection';
import GozioListItem from 'components/listItem/gozioListItem';
import TabPanel from 'components/tabPanel/tabPanel';
import { buildLabelByLang, getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import ColorPalette from 'pages/gozio_colors';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import { QuicklinkModal } from 'pages/mobileSettings/dashboard/containers/quicklinkModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, useField } from 'react-final-form';

export const AccessToCareMapDetailsTabPanel = ({
                                                 sx,
                                                 cardGroupType,
                                                 formValues,
                                                 form,
                                                 index,
                                                 languages = [],
                                                 linkBackgroundColor,
                                                 networkId,
                                                 onChange: onFormChange,
                                                 tabValue,
                                               }) => {
  useField('qlQuickLinks');

  const quickLinksLength = formValues?.qlQuickLinks?.length || 0;

  const quickLinksAddPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(useCheckRole());

  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const [uploadedIconData, setUploadedIconData] = useState(null);
  const [selectedQuickLink, setSelectedQuickLink] = useState(null);

  const handleShowQuickLinkModal = (link = null) => {
    setUploadedIconData(null);
    setSelectedQuickLink(link);
    setShowQuickLinkModal(true);
  };

  const handleEditQuickLinkClicked = (id) => {
    handleShowQuickLinkModal({
      ...formValues.qlQuickLinks.find((link) => link.id === id),
    });
  };

  const handleDeleteQuickLinkClicked = (id) => {
    let updatedQuickLinksData = [...formValues.qlQuickLinks];
    updatedQuickLinksData = updatedQuickLinksData
      .filter((link) => link.id !== id)
      .map(({ index, ...rest }, idx) => ({
        index: idx,
        ...rest,
      }));
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
  };

  const handleQuicklinkAddedOrEdited = (data) => {
    const updatedQuickLinksData = formValues?.qlQuickLinks
      ? [...formValues.qlQuickLinks]
      : [];
    const updatedData = {
      categories: data.categories,
      listDest: data.listDest,
      name: buildLabelByLang(data, 'nameEn', 'nameEs'),
      iconFile: uploadedIconData?.file,
      iconUrl: uploadedIconData?.iconUrl || data.iconUrl,
      type: data.linkType,
      data: {
        id:
          data.linkType === 'category'
            ? data.categories?.map((c) => c.id)
            : data.listDest,
      },
      validation: data.validation,
    };
    if (selectedQuickLink) {
      updatedData.id = selectedQuickLink.id;
      if (selectedQuickLink.iconFile) {
        updatedData.iconFile = selectedQuickLink.iconFile;
      }
      const index = updatedQuickLinksData.findIndex(
        (link) => link.id === selectedQuickLink.id,
      );
      updatedData.index = index;
      updatedQuickLinksData[index] = updatedData;
    } else {
      updatedData.index = updatedQuickLinksData.length;
      updatedQuickLinksData.push(updatedData);
    }
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
    setShowQuickLinkModal(false);
  };

  const handleUpdateOptions = (
    updatedListOptions,
    sourceIndex,
    targetIndex,
  ) => {
    const updatedQuicklinks = [...formValues.qlQuickLinks];
    updatedQuicklinks[sourceIndex].index = targetIndex;
    updatedQuicklinks[targetIndex].index = sourceIndex;
    [updatedQuicklinks[sourceIndex], updatedQuicklinks[targetIndex]] = [
      updatedQuicklinks[targetIndex],
      updatedQuicklinks[sourceIndex],
    ];
    form.change('qlQuickLinks', updatedQuicklinks);
    onFormChange();
  };

  const buildListOptions = () => {
    let options = [];
    if (quickLinksLength > 0) {
      const actions = [
        {
          Icon: Edit,
          hover: 'Edit',
          onClick: (id) => handleEditQuickLinkClicked(id),
        },
        {
          Icon: Delete,
          hover: 'Delete',
          onClick: (id) => handleDeleteQuickLinkClicked(id),
        },
      ];
      options = formValues.qlQuickLinks.map((link, index) => ({
        id: link.id,
        order: index,
        iconUrl: link.iconUrl,
        label: getLabelByLang(link.name),
        labelSx: sx.quickLinkLabel,
        actions,
        validation: link.validation,
      }));
    }
    return options;
  };

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      {cardGroupType === CARD_GROUP_TYPES.FINDING_CARE && (
        <>
          <Box sx={{ marginBottom: '24px' }}>
            <Grid item xs>
              <Typography variant="subtitle1">Color Options</Typography>
            </Grid>
          </Box>
          <Box sx={sx.fieldSection}>
            <Grid item xs>
              <Field name="linkBackgroundColor">
                {({ input: { onChange } }) => (
                  <ColorOptions
                    initialColor={linkBackgroundColor}
                    formControl={true}
                    getColor={(color) => {
                      onChange(color);
                    }}
                    label="Link Background Color"
                    required={true}
                    inputProps={{
                      'data-test': 'editCardLinkBackgroundColor',
                    }}
                  />
                )}
              </Field>
            </Grid>
          </Box>
        </>
      )}
      {cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME
        && formValues.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE && (
          <>
            <Box sx={sx.fieldArea}>
              <Grid item xs>
                <Typography variant="subtitle1">Map General</Typography>
              </Grid>
            </Box>
            <Grid item xs sx={sx.formFields}>
              {renderFields({
                sx,
                fields: [
                  {
                    key: 'subtitle',
                    lang: LANGUAGE_CODES.ENGLISH,
                    langKey: 'subtitleEn',
                    label: 'Subtitle',
                    required: true,
                    charLimit: 30,
                  },
                  {
                    key: 'subtitle',
                    lang: LANGUAGE_CODES.SPANISH,
                    langKey: 'subtitleEs',
                    label: 'Subtitle (Spanish)',
                    charLimit: 30,
                  },
                ],
                languages,
                size: null,
                values: formValues,
              })}
            </Grid>
          </>
        )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '14px',
          paddingTop: '5px',
        }}
      >
        <Typography variant="subtitle1" className="required">
          {quickLinksLength} Quicklinks
        </Typography>
        {quickLinksLength > 0 && (
          <IconButton
            onClick={() => handleShowQuickLinkModal()}
            aria-label="add-tile"
            sx={{ padding: 0 }}
            size="large"
          >
            <AddCircle
              sx={{
                color: ColorPalette.primary.main,
                fontSize: '40px',
              }}
            />
          </IconButton>
        )}
      </Box>
      {quickLinksLength === 0 ? (
        <EmptySection
          title="There Are No Quicklinks Set Up"
          buttonLabel="Add a Quicklink"
          hasPermission={quickLinksAddPermission}
          ButtonIcon={Add}
          buttonClicked={() => handleShowQuickLinkModal()}
        />
      ) : (
        <GozioListItem
          listHeaderName="QuickLink Name"
          progressHeaderName="Progress"
          enableReorder={true}
          options={buildListOptions()}
          updateOptions={handleUpdateOptions}
        />
      )}
      {showQuickLinkModal && (
        <QuicklinkModal
          sx={sx}
          onSubmit={handleQuicklinkAddedOrEdited}
          languages={languages}
          networkId={networkId}
          onClose={() => setShowQuickLinkModal(false)}
          onIconUploaded={setUploadedIconData}
          iconWidthAndHeight={
            cardGroupType === CARD_GROUP_TYPES.FINDING_CARE ? 140 : 56
          }
          selectedQuickLink={selectedQuickLink}
          supportedLinkTypes={['category', 'list']}
        />
      )}
    </TabPanel>
  );
};

AccessToCareMapDetailsTabPanel.propTypes = {
  sx: PropTypes.object,
  cardGroupType: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkBackgroundColor: PropTypes.string,
  index: PropTypes.number,
  networkId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

AccessToCareMapDetailsTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  linkBackgroundColor: ColorPalette.grey[500],
  tabValue: 0,
};
