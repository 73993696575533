import { Box, FormHelperText, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Asterisk from 'components/asterisk/asterisk';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  colorOval: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'inline-block',
    marginRight: '16px',
  },
  colorInputRoot: {
    margin: 0,
    flex: 1,
  },
  colorOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ColorOptions = ({
                        getColor,
                        initialColor,
                        label,
                        defaultColor,
                        alpha = 'none',
                        required,
                        customStyle = {},
                        helperText,
                        formControl = false,
                        ...custom
                      }) => {
  const classes = useStyles();
  const regString
    = alpha === 'none'
      ? '^[a-fA-F0-9]{6}$'
      : alpha === 'optional'
        ? '^[a-fA-F0-9]{6}$|^[a-fA-F0-9]{8}$'
        : '^[a-fA-F0-9]{8}$';
  const reg = RegExp(regString);
  if (alpha === 'required') defaultColor = `${defaultColor}FF`;
  const [color, setColor] = useState(
    (initialColor || defaultColor).replace('#', ''),
  );
  const [previewColor, setPreviewColor] = useState(color);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const colorString = (initialColor || defaultColor).replace('#', '');
    setColor(colorString);
    setPreviewColor(colorString);
    setValid(reg.test(colorString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColor, defaultColor]);

  const handleChange = (e) => {
    const val = e.target.value.replace('#', '');
    setColor(val);

    if (!val || val.length === 0) {
      setValid(true);
      setPreviewColor(defaultColor);
      getColor(defaultColor);
    } else if (reg.test(val)) {
      setValid(true);
      setPreviewColor(val);
      getColor(`#${val}`);
    } else {
      setValid(false);
      if (formControl) {
        getColor(`#${val}`);
      }
    }
  };

  return (
    <Box sx={customStyle}>
      <Box className={classes.colorOptionsContainer}>
        <Box
          className={classes.colorOval}
          sx={{ backgroundColor: `#${previewColor}` }}
        />
        <TextField
          id="outlined-bare"
          margin="normal"
          variant="outlined"
          label={
            required ? (
              <span>
                <Asterisk /> {label}
              </span>
            )
              : label

          }
          onChange={handleChange}
          value={color}
          classes={{ root: classes.colorInputRoot }}
          error={!valid}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography={true}
                sx={{ color: ColorPalette.grey[600] }}
              >
                #
              </InputAdornment>
            ),
          }}
          {...custom}
        />
      </Box>
      {!valid ? (
        <Box>
          <FormHelperText error={!valid} sx={{ marginLeft: '42px' }}>
            {helperText || alpha === 'required'
              ? 'Must be a valid hex color with alpha transparency'
              : 'Must be a valid hex color'}
          </FormHelperText>
        </Box>
      ) : null}
    </Box>
  );
};

ColorOptions.propTypes = {
  defaultColor: PropTypes.string,
  getColor: PropTypes.func,
  initialColor: PropTypes.string,
  label: PropTypes.string,
  alpha: PropTypes.string,
  required: PropTypes.bool,
};

ColorOptions.defaultProps = {
  defaultColor: ColorPalette.white,
  getColor: () => {},
  label: 'Default fill',
  alpha: 'none',
  required: false,
};

export default ColorOptions;
