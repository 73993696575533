import { Box, Grid, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * This component renders a link that will go to Google Maps. It works with React Final Form.
 */

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 'fit-content',
  },
  link: {
    color: theme.palette.blue[400],
    fontSize: '16px',
    paddingTop: theme.spacing(2),

    '& a': {
      fontWeight: 'bold',
    },
  },
}));

const GoogleMapsLink = ({
                          defaultZoom,
                          latitudeFieldName,
                          longitudeFieldName,
                          title,
                          zoomFieldName,
                        }) => {
  const classes = useStyles();

  return (
    <Field name={latitudeFieldName} subscription={{ value: true }}>
      {({ input: { value: latitude } }) => (
        <Field name={longitudeFieldName} subscription={{ value: true }}>
          {({ input: { value: longitude } }) => (
            <Grid item xs={3} className={classes.content}>
              {latitude && longitude && (
                <Box className={classes.link}>
                  <Field name={zoomFieldName} subscription={{ value: true }}>
                    {({ input: { value: zoom } }) => (
                      <Link
                        href={`https://www.google.com/maps/@?api=1&map_action=map&center=${latitude},${longitude}${
                          zoomFieldName
                            ? `&zoom=${
                              typeof zoom === 'undefined' ? defaultZoom : zoom
                            }`
                            : ''
                        }`}
                        target="_blank"
                      >
                        {title}
                      </Link>
                    )}
                  </Field>
                </Box>
              )}
            </Grid>
          )}
        </Field>
      )}
    </Field>
  );
};

GoogleMapsLink.propTypes = {
  defaultZoom: PropTypes.number,
  latitudeFieldName: PropTypes.string.isRequired,
  longitudeFieldName: PropTypes.string.isRequired,
  title: PropTypes.string,
  zoomFieldName: PropTypes.string,
};

GoogleMapsLink.defaultProps = {
  defaultZoom: 9,
  title: 'Show Location in Maps',
  zoomFieldName: null,
};

export default GoogleMapsLink;
