import { ArrowForward, Info } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DEFAULT_BACKGROUND_COLOR } from 'components/dashboard/cardLayouts/cardhelper';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    height: '196px',
    overflow: 'hidden',
    padding: '30px 16px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  text: {
    display: '-webkit-box',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  seeMoreText: {
    marginTop: '1px',
    textAlign: 'right',
  },
  iconContainer: {
    marginTop: theme.spacing(3),
  },
  placeholderIcon: {
    borderRadius: '50%',
    margin: '0 auto',
    padding: '18px',
    width: '60px',
    height: '60px',

    '& img, svg': {
      width: '24px',
      height: '24px',
    },
  },
  icon: {
    background: theme.palette.grey[500],
    borderRadius: '50%',
    margin: '0 auto',
    width: '60px',
    height: '60px',

    '& img': {
      width: '60px',
      height: '60px',
    },
  },
  iconText: {
    fontSize: '12px',
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: '-24px',
  },
});

const renderIcon = ({ iconUrl, iconFile }, styles, linkBackgroundColor) => {
  if (iconFile) {
    return (
      <Box sx={{ ...styles.placeholderIcon, background: linkBackgroundColor }}>
        <img src={iconUrl} alt="Quicklink Icon" />
      </Box>
    );
  }

  if (iconUrl) {
    return (
      <Box sx={styles.icon}>
        <img src={iconUrl} alt="Quicklink Icon" />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.placeholderIcon, background: linkBackgroundColor }}>
      <AddPhotoAlternateIcon />
    </Box>
  );
};

export const FindingCareCard = ({
                                  backgroundColor,
                                  clickHandler,
                                  disabled,
                                  incompleteForPackaging,
                                  quickLinksData,
                                  selected,
                                  textColor,
                                  title,
                                }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  const linkBackgroundColor
    = quickLinksData?.length > 0
      ? quickLinksData[0].iconTint
      : DEFAULT_BACKGROUND_COLOR;

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const renderIncompleteWarning = () => (
    <Box
      sx={{
        position: 'fixed',
        width,
      }}
    >
      <Box sx={{ ...styles.incompleteWarning, left: `${width - 54}px` }}>
        <Info />
      </Box>
    </Box>
  );

  /**
   * @type {React.ReactNode}
   */
  const quicklinks = useMemo(() => {
    if (quickLinksData?.length === 0) {
      return (
        <Grid item xs={12}>
          <Box
            sx={{ ...styles.placeholderIcon, background: linkBackgroundColor }}
          >
            <AddPhotoAlternateIcon />
          </Box>
        </Grid>
      );
    }

    const max = Math.min(quickLinksData?.length, 4);
    const links = [];
    for (let i = 0; i < max; i++) {
      const { name } = quickLinksData[i];
      links.push(
        <Grid key={`qli_${i}`} item xs={3} sx={styles.iconContainer}>
          {renderIcon(quickLinksData[i], styles, linkBackgroundColor)}
          <Typography
            variant="body1"
            sx={{ ...styles.iconText, color: textColor }}
          >
            {getLabelByLang(name)}
          </Typography>
        </Grid>,
      );
    }
    return links;
  }, [styles, quickLinksData, linkBackgroundColor, textColor]);

  return (
    <Grid
      ref={ref}
      container
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...incompleteForPackaging && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        backgroundColor,
      }}
    >
      {incompleteForPackaging && renderIncompleteWarning()}
      <Grid item xs={8}>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.text, color: textColor }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          sx={{ ...styles.seeMoreText, color: textColor }}
        >
          <ArrowForward />
        </Typography>
      </Grid>
      {quicklinks}
    </Grid>
  );
};

FindingCareCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  quickLinksData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      iconUrl: PropTypes.string,
      iconTint: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.object,
    }),
  ),
  selected: PropTypes.bool,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FindingCareCard.defaultProps = {
  disabled: false,
  incompleteForPackaging: false,
  quickLinksData: [],
  selected: false,
};

export default FindingCareCard;
