import { CheckCircle, Warning } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Link, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CardCarousel from 'components/cardCarousel/cardCarousel';
import DownloadButton from 'components/downloadButton/downloadButton';
import GenericModal from 'components/genericModal/genericModal';
import InfoPanel from 'components/infoPanel/infoPanel';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import ProgressCard from 'components/progressCard/progressCard';
import ViewDetailsModal from 'components/viewDetailsModal/viewDetailsModal';
import { FlamingoContext } from 'contexts/flamingo';
import { convertDateToString } from 'helpers/date-util';
import { httpRequest } from 'helpers/http-util';
import { capitalize } from 'helpers/lang-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import {
  BULK_DATA_REQUEST_ERRORS,
  BULK_DATA_REQUEST_STATUSES,
  BULK_DATA_SERVICE_KEYS,
  BULK_REQUEST_OPERATIONS,
  useBulkDataService,
  useBulkRequest,
} from 'hooks/dataHooks/useBulkRequest';
import useLogout from 'hooks/useLogout';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import useToast from 'hooks/useToast';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const REFETCH_PERIOD_IN_MS = 10000;

const SPECIFIC_ERROR_TYPES = [
  BULK_DATA_REQUEST_ERRORS.INVALID_DATA_ITEM,
  BULK_DATA_REQUEST_ERRORS.UNKNOWN_DATA_ITEM,
  BULK_DATA_REQUEST_ERRORS.IGNORED_DATA_ITEM,
  BULK_DATA_REQUEST_ERRORS.SYSTEM_ERROR,
];

const ERROR_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Excel Cell',
    accessor: 'cell',
    maxWidth: 110,
  },
  {
    Header: 'Data Type',
    accessor: 'type',
    maxWidth: 166,
  },
  {
    Header: 'Field Name',
    accessor: 'field',
    maxWidth: 208,
  },
  {
    Header: 'Description',
    accessor: 'description',
    maxWidth: 442,
  },
];

const buildStyles = ({ theme: { spacing, palette } }) => ({
  root: {
    marginBottom: spacing(3),
    padding: spacing(3),
    paddingBottom: spacing(1),
    boxShadow: '0 2px 4px 0 rgba(38, 43, 46, 0.4)',
    width: '100%',
    '& > p': {
      marginTop: spacing(1),
    },
  },
  avatar: {
    cursor: 'pointer',
    height: spacing(3),
    width: spacing(3),
  },
  arrow: {
    height: spacing(4.5),
    width: spacing(5),
    backgroundColor: '#e7f0ff',
    transform: 'rotate(45deg)',
    borderRadius: `0 0 ${spacing(1.5)} 0`,
    marginLeft: spacing(1),
    marginTop: spacing(-2.35),
    position: 'relative',
    zIndex: 0,
  },
  downloadIcon: {
    color: palette.blue[400],
    height: spacing(2.5),
    width: spacing(2.5),
  },
  downloadWrapper: {
    cursor: 'pointer',
  },
  circleIconSuccess: {
    color: palette.success.light,
  },
  progressCard: {
    position: 'relative',
    width: '100%',
    marginTop: spacing(2),
    maxWidth: '100% !important',
    backgroundColor: '#e7f0ff',
    borderRadius: spacing(1),
    padding: spacing(1.5),
    zIndex: 1,
    '& > div:first-of-type': {
      backgroundColor: palette.white,
    },
  },
  progressCardInactive: {
    position: 'relative',
    width: '100%',
    marginBottom: spacing(2),
    borderRadius: spacing(1),
    maxWidth: '100% !important',
    padding: spacing(1.5),
    zIndex: 1,
    '& > div:first-of-type': {
      backgroundColor: palette.white,
    },
  },
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  languageSupportContainer: {
    borderTop: `1.5px solid ${palette.grey[300]}`,
    marginTop: '24px',
    paddingTop: '24px',
    paddingBottom: '16px',
  },
  languageSupport: {
    maxWidth: '67%',
    paddingRight: '16px',

    '& h6': {
      display: 'inline-block',
      marginRight: '12px',
      color: palette.grey[600],
    },
  },
  tooltip: {
    maxWidth: '250px',
    verticalAlign: 'middle',
  },
  languageSupportButtons: {
    alignSelf: 'flex-end',
    flexGrow: 1,
    textAlign: 'right',
  },
  fileUpload: {
    '& input': {
      display: 'none',
    },
  },
  iconWrapper: {
    color: palette.orange[400],
    height: '36px',
    marginRight: '10px',
    verticalAlign: 'baseline',
    width: '36px',
  },
});

const bulkRequest = async ({
                             networkId,
                             file,
                             logout,
                             operation,
                             serviceId,
                             toastNotificationErrorHook,
                           }) => {
  const formData = new FormData();
  formData.append('operation', operation);
  formData.append('dataType', 'xlsx');
  formData.append('service', serviceId);
  if (operation === BULK_REQUEST_OPERATIONS.Import) {
    formData.append('file', file);
  } else {
    formData.append('sync', true);
  }

  try {
    const rawResponse = await httpRequest({
      url: `${process.env.REACT_APP_API_URL}/bulk/request`,
      method: 'POST',
      headers: {
        ...operation === BULK_REQUEST_OPERATIONS.Export && {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        network: networkId,
      },
      body: formData,
      logout,
    });
    if (operation === BULK_REQUEST_OPERATIONS.Import) {
      const response = await rawResponse.json();
      if (response.statusCode === 423) {
        toastNotificationErrorHook(
          response.message ?? 'An unexpected error has occurred.',
        );
      }
      return response;
    }

    return rawResponse;
  } catch (err) {
    console.error(err);
    toastNotificationErrorHook(err.message);
  }
};

const NetworkDataProgress = ({
                               disabled,
                               progressCards,
                               progressData,
                               isDataComplete,
                               workspace,
                             }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { handleNavigate } = useContext(FlamingoContext);

  const { networkId } = useParams();
  const logout = useLogout();
  const currentNetworkId = window.location.pathname.split('/')[2];
  const isUberAdmin = useCheckGozioAdmin();
  const { toastNotificationErrorHook } = useToast();
  const languages = useActiveNetworkLanguages();
  const hasLanguageSupport = languages?.length > 1;

  const {
    globalStates: {
      isLanguageSupportExportingMap: {
        [networkId]: isLanguageSupportExporting,
      },
      isLanguageSupportImportingMap: {
        [networkId]: isLanguageSupportImporting,
      },
      setIsLanguageSupportExporting,
      setIsLanguageSupportImporting,
    },
  } = useContext(FlamingoContext);

  const [refetchTimer, setRefetchTimer] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportErrorModal, setShowImportErrorModal] = useState(false);
  const [disableImport, setDisableImport] = useState(false);

  const { data: bulkDataServiceData, loading: bulkDataServiceLoading }
    = useBulkDataService({ key: BULK_DATA_SERVICE_KEYS.LanguageExport });
  const {
    data: bulkRequestData,
    loading: bulkRequestLoading,
    refetch: refetchBulkRequest,
  } = useBulkRequest({
    operation: BULK_REQUEST_OPERATIONS.Import,
  });

  const isLanguageSupportProcessing = useMemo(
    () => (isLanguageSupportExporting
        || isLanguageSupportImporting
        || (bulkRequestData?.length > 0
          && bulkRequestData[0].network?.id === networkId
          && bulkRequestData[0].status
          === BULK_DATA_REQUEST_STATUSES.IN_PROGRESS))
      ?? false,
    [
      isLanguageSupportExporting,
      isLanguageSupportImporting,
      bulkRequestData,
      networkId,
    ],
  );

  useEffect(
    () => () => {
      clearInterval(refetchTimer);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (
      bulkRequestData?.length > 0
      && bulkRequestData[0].network?.id === networkId
    ) {
      if (
        bulkRequestData[0].status === BULK_DATA_REQUEST_STATUSES.IN_PROGRESS
      ) {
        if (disableImport) {
          setDisableImport(false);
        }
        if (!isLanguageSupportImporting) {
          setIsLanguageSupportImporting(currentNetworkId, true);
        }
        if (!refetchTimer && !isLanguageSupportExporting) {
          const intervalId = setInterval(async () => {
            if (!isLanguageSupportImporting) {
              setIsLanguageSupportImporting(currentNetworkId, true);
            }
            await refetchBulkRequest();
          }, REFETCH_PERIOD_IN_MS);
          setRefetchTimer(intervalId);
        }
      } else {
        if (isLanguageSupportImporting) {
          setIsLanguageSupportImporting(currentNetworkId, false);
        }
        if (refetchTimer) {
          clearInterval(refetchTimer);
          setRefetchTimer(null);
        }
      }
    }
  }, [
    bulkRequestData,
    currentNetworkId,
    disableImport,
    isLanguageSupportExporting,
    isLanguageSupportImporting,
    networkId,
    refetchTimer,
    refetchBulkRequest,
    setIsLanguageSupportImporting,
  ]);

  const lastImportResults = useMemo(() => {
    if (bulkRequestData?.length > 0) {
      const lastImportData = bulkRequestData[0];
      if (
        !isLanguageSupportProcessing
        && lastImportData.status !== BULK_DATA_REQUEST_STATUSES.IN_PROGRESS
      ) {
        const date = convertDateToString(lastImportData.updatedAt);
        const name = `${lastImportData.createdBy?.profile?.firstName} ${lastImportData.createdBy?.profile?.lastName}`;
        if (
          lastImportData.errors?.edges?.length > 0
          || lastImportData.status === BULK_DATA_REQUEST_STATUSES.FAILED
        ) {
          return (
            <InfoPanel
              title={
                <>
                  Import Failed.{' '}
                  <Link
                    onClick={() => setShowImportErrorModal(true)}
                    sx={{ fontWeight: 'bold' }}
                  >
                    View Details
                  </Link>
                  <br />
                  Import attempt failed on {date} by {name}.
                </>
              }
              type="warning"
              fullWidth={true}
            />
          );
        }

        return (
          <Typography variant="caption" sx={{ color: ColorPalette.grey[600] }}>
            Import Successful. Import occurred on {date} by {name}.
          </Typography>
        );
      }
    }

    return null;
  }, [bulkRequestData, isLanguageSupportProcessing]);

  const lastImportSpecificErrors = useMemo(() => {
    if (bulkRequestData?.length > 0) {
      const lastImportData = bulkRequestData[0];
      if (lastImportData.errors?.edges?.length > 0) {
        return lastImportData.errors.edges
          .filter((n) => SPECIFIC_ERROR_TYPES.includes(n.node.type))
          .map((n) => ({
            id: n.node.id ?? '',
            cell: n.node.data?.cell ?? '',
            type: n.node.data?.model ?? '',
            field: n.node.data?.field ?? '',
            description: n.node.message ?? '',
          }));
      }
    }
  }, [bulkRequestData]);

  const lastImportErrorDescription = useMemo(() => {
    if (bulkRequestData?.length > 0) {
      const lastImportData = bulkRequestData[0];
      if (
        lastImportData.errors?.edges?.length > 0
        || lastImportData.status === BULK_DATA_REQUEST_STATUSES.FAILED
      ) {
        if (lastImportSpecificErrors?.length > 0) {
          return (
            <>
              Import Failed. When attempting to import the Language Support
              Excel sheet, {lastImportSpecificErrors.length} issues found with
              the data supplied.
              <br />
              Review/update issues and re-import.
            </>
          );
        }

        const hasInvalidDataSet = !!lastImportData.errors.edges.find(
          (n) => n.node.type === BULK_DATA_REQUEST_ERRORS.INVALID_DATA_SET,
        );
        if (hasInvalidDataSet) {
          return (
            <>
              The system will not accept a restructured document. Please
              reexport and download the Language Support Excel Sheet. Then
              validate that the structure is intact. If the problem persists{' '}
              <Link href="mailto:support@goziohealth.com">
                email Gozio Support
              </Link>{' '}
              for assistance.
            </>
          );
        }

        const hasInvalidDataType = !!lastImportData.errors.edges.find(
          (n) => n.node.type === BULK_DATA_REQUEST_ERRORS.INVALID_DATA_TYPE,
        );
        if (hasInvalidDataType) {
          return (
            <>
              The file type provided is not supported. Please make sure the file
              type is “.xlsx” before importing.
            </>
          );
        }

        return (
          <>
            An error occurred when receiving imported data. Try again. If the
            problem persists{' '}
            <Link href="mailto:support@goziohealth.com">
              email Gozio Support
            </Link>{' '}
            for assistance.
          </>
        );
      }

      return null;
    }
  }, [bulkRequestData, lastImportSpecificErrors]);

  const handleDownloadLanguageSupportFile = async () => {
    setIsLanguageSupportExporting(currentNetworkId, true);
    await bulkRequest({
      networkId,
      operation: BULK_REQUEST_OPERATIONS.Export,
      serviceId: bulkDataServiceData?.id,
      toastNotificationErrorHook,
      logout,
    });
    setIsLanguageSupportExporting(currentNetworkId, false);
  };

  const handleUploadLanguageSupportFile = async (e) => {
    if (e.target.files) {
      setDisableImport(true);
      setShowImportModal(false);
      const uploadedFile = e.target.files[0];
      e.target.value = '';
      await bulkRequest({
        networkId,
        operation: BULK_REQUEST_OPERATIONS.Import,
        serviceId: bulkDataServiceData?.id,
        file: uploadedFile,
        toastNotificationErrorHook,
        logout,
      });
      await refetchBulkRequest();
    }
  };

  const tableTitle = useMemo(() => {
    const count = lastImportSpecificErrors?.length;
    if (count > 0) {
      return `${count} Issue${count > 1 ? 's' : ''} Found`;
    }

    return null;
  }, [lastImportSpecificErrors]);

  return (
    <Paper sx={styles.root}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginBottom: '8px',
                    }}
                  >
                    Network's Data Progress
                  </Typography>
                  {!isDataComplete[workspace] && (
                    <Typography variant="body1">
                      The following areas need to be addressed before publishing
                      can occur.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!(hasLanguageSupport && isUberAdmin) && (
              <Grid item>
                <DownloadButton
                  downloadInProgress={isLanguageSupportExporting}
                  downloadButtonText="Download Network Content"
                  downloadInProgressText="Processing Export Request..."
                  handleDownload={handleDownloadLanguageSupportFile}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container wrap="nowrap">
            <Grid
              item
              sx={{
                flexGrow: 1,
              }}
            >
              <ProgressCard
                status="active"
                actionable={false}
                title={`${capitalize(workspace)} Workspace`}
                description={`Is ${
                  isDataComplete[workspace] ? 'Complete' : 'Incomplete'
                }`}
                icon={
                  isDataComplete[workspace] ? (
                    <CheckCircle sx={styles.circleIconSuccess} />
                  ) : (
                    <Warning />
                  )
                }
                sx={styles.progressCard}
              >
                <Typography variant="caption">
                  Last updated on{' '}
                  {`${convertDateToString(
                    progressData[workspace]?.Workspace?.updatedAt,
                  )}`}{' '}
                  by{' '}
                  {`${progressData[workspace]?.Workspace?.updatedBy?.profile?.firstName} ${progressData[workspace]?.Workspace?.updatedBy?.profile?.lastName}`}
                </Typography>
              </ProgressCard>
              {!isDataComplete[workspace] && <Box sx={styles.arrow} />}
            </Grid>
            {workspace === DRAFT_WORKSPACE && (
              <Grid
                item
                sx={{
                  flexGrow: 1,
                }}
              >
                <ProgressCard
                  status="inactive"
                  actionable={false}
                  title={'Live Workspace'}
                  description={`Is ${
                    isDataComplete[LIVE_WORKSPACE] ? 'Complete' : 'Incomplete'
                  }`}
                  icon={
                    isDataComplete[LIVE_WORKSPACE] ? (
                      <CheckCircle sx={styles.circleIconSuccess} />
                    ) : (
                      <Warning />
                    )
                  }
                  sx={styles.progressCardInactive}
                >
                  <Typography variant="caption">
                    Last updated on{' '}
                    {`${convertDateToString(
                      progressData.live?.Workspace?.updatedAt,
                    )}`}{' '}
                    by{' '}
                    {`${progressData.live?.Workspace?.updatedBy?.profile?.firstName} ${progressData.live?.Workspace?.updatedBy?.profile?.lastName}`}
                  </Typography>
                </ProgressCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isDataComplete[workspace] && (
        <Grid item>
          <Grid
            container
            sx={{
              marginBottom: '16px',
            }}
          >
            <CardCarousel
              cards={progressCards}
              onCardClicked={(card) => {
                handleNavigate(card.url);
              }}
            />
          </Grid>
        </Grid>
      )}
      {isUberAdmin && hasLanguageSupport && (
        <Grid
          container
          sx={styles.languageSupportContainer}
          alignItems={isLanguageSupportProcessing ? 'flex-end' : 'flex-start'}
        >
          <Grid item xs={8} sx={styles.languageSupport}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{ color: ColorPalette.grey[600] }}
              >
                Language Support
              </Typography>
              <InfoTooltip
                title="Language support helps you manage all areas within Portal that have Spanish alternatives. Here you will be able to export your network's data, make adjustments to Spanish specific fields, and import changes. Import is supported for locations only."
                sx={styles.tooltip}
              />
            </Grid>
            {isLanguageSupportProcessing && (
              <Grid item xs={12} sx={{ marginTop: '8px' }}>
                <InfoPanel
                  title="Portal is processing a large amount of data. Functions are disabled while the system is processing. Please wait…"
                  fullWidth={true}
                />
              </Grid>
            )}
            {!isLanguageSupportProcessing && lastImportResults && (
              <Grid item xs={12} sx={{ marginTop: '4px' }}>
                {lastImportResults}
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} sx={styles.languageSupportButtons}>
            <Button
              variant="outlined"
              color="primary"
              disabled={
                disabled
                || bulkDataServiceLoading
                || bulkRequestLoading
                || isLanguageSupportExporting
              }
              onClick={handleDownloadLanguageSupportFile}
            >
              EXPORT
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={
                disabled
                || disableImport
                || bulkDataServiceLoading
                || bulkRequestLoading
                || isLanguageSupportProcessing
              }
              onClick={() => setShowImportModal(true)}
              startIcon={
                disableImport ? (
                  <CircularProgress size={16} thickness={4} />
                ) : null
              }
              sx={{ marginLeft: '12px' }}
            >
              {disableImport ? 'IMPORTING...' : 'IMPORT LOCATION DATA'}
            </Button>
          </Grid>
        </Grid>
      )}
      {showImportModal && (
        <GenericModal
          title="Importing Language Support"
          body={
            <Box sx={styles.fileUpload}>
              <input
                id="file-upload"
                type="file"
                accept="application/vnd.ms-excel,.xlsx"
                onChange={handleUploadLanguageSupportFile}
              />
              <Typography variant="subtitle1">
                Importing updates made through the Language Support Excel sheet
                can override preexisting data in Portal. Before importing, we
                advise that you validate that any desired language updates are
                not being overridden.
              </Typography>
            </Box>
          }
          confirmText={<label htmlFor="file-upload">IMPORT FILE</label>}
          handleConfirm={handleUploadLanguageSupportFile}
          handleClose={() => setShowImportModal(false)}
        />
      )}
      {showImportErrorModal && (
        <ViewDetailsModal
          columns={lastImportSpecificErrors?.length > 0 ? ERROR_COLUMNS : null}
          data={lastImportSpecificErrors}
          date={convertDateToString(bulkRequestData[0].updatedAt)}
          descriptionTitle="Description"
          description={lastImportErrorDescription}
          handleClose={() => setShowImportErrorModal(false)}
          messageTitle="Failed Import Details"
          tableTitle={tableTitle}
          tableMessage={
            lastImportSpecificErrors?.length > 0 ? (
              <>
                If there are issues you’d like assistance with{' '}
                <Link href="mailto:support@goziohealth.com">
                  email Gozio Support
                </Link>{' '}
                for help.
              </>
            ) : null
          }
          rowsPerPage={10}
          userName={`${bulkRequestData[0].createdBy?.profile?.firstName} ${bulkRequestData[0].createdBy?.profile?.lastName}`}
          title="Failed Import Details"
        />
      )}
    </Paper>
  );
};

NetworkDataProgress.propTypes = {
  disabled: PropTypes.bool,
  progressCards: PropTypes.array.isRequired,
  progressData: PropTypes.object.isRequired,
  isDataComplete: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
};

NetworkDataProgress.defaultProps = {
  disabled: false,
};

export default React.memo(NetworkDataProgress);
