import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DetailFormFooter from 'components/forms/detailFormFooter';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import createDecorator from 'final-form-submit-listener';
import { isGlobalTemplate } from 'helpers/network-util';
import PageHelpers from 'helpers/pageHelpers';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useKioskTemplate, useUpdateKioskTemplate } from 'hooks/dataHooks/useKioskTemplate';
import { isEmpty } from 'lodash';
import formFieldMap from 'pages/kiosks/containers/template/errorModalHelper';
import { initialParser, submitParser, validate } from 'pages/kiosks/containers/template/formHelper';
import TemplateAppCta from 'pages/kiosks/containers/template/TemplateAppCta';
import TemplateHeader from 'pages/kiosks/containers/template/TemplateHeader';
import TemplateMainView from 'pages/kiosks/containers/template/TemplateMainView';
import TemplateScreensaver from 'pages/kiosks/containers/template/TemplateScreensaver';
import React, { Suspense, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.grey[100],
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  tabsContainer: {
    height: '100vh',
    width: '100%',
    padding: '138px 0 69px',
  },
  tabPanel: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    width: '100%',
  },
  tabBody: {
    height: '100%',
    width: '100%',
    maxWidth: '1104px',
    padding: '32px 24px',
  },
});

const KioskTemplate = React.memo(({ networkId }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [currentTab, setCurrentTab] = useState(0);
  const [nextTab, setNextTab] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data } = useKioskTemplate();
  const [updateKioskTemplate] = useUpdateKioskTemplate();

  const handleTabChange = (tabValue, form) => {
    const { dirty, errors } = form.getState();
    if (dirty || Object.keys(errors).length) {
      setIsClosing(true);
      setNextTab(tabValue);
    } else {
      setIsClosing(false);
      setCurrentTab(tabValue);
    }
  };

  /** Form Functions */
  const submitListener = useMemo(
    () => createDecorator({
        beforeSubmit: (formApi) => {
          setIsSubmitting(true);
          const formState = formApi.getState();
          if (!isEmpty(formState.errors)) {
            console.warn('beforeSubmit: errors', formState.errors);
            return false;
          }
        },
        afterSubmitSucceeded: (formApi) => setIsSubmitting(false),
        afterSubmitFailed: (formApi) => setIsSubmitting(false),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initialValues = useMemo(() => initialParser(data), [data]);

  async function onSubmit(values) {
    const parsedVal = await submitParser(values);
    await updateKioskTemplate({
      variables: {
        id: values.id,
        input: parsedVal,
      },
    });
  }

  return (
    <Form
      subscription={{ submitting: true, pristine: true, validating: true }}
      decorators={[submitListener]}
      initialValues={initialValues}
      onSubmit={(data) => onSubmit(data)}
      validate={validate}
    >
      {({ form, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormWatcher
            formRenderProps={{ form }}
            errorFormFieldMap={formFieldMap}
            isClosing={isClosing}
            isSubmitting={isSubmitting}
            onClose={() => {
              setIsClosing(false);
              setIsSubmitting(false);
            }}
            onContinue={(path) => {
              setIsClosing(false);
              setIsSubmitting(false);
              if (!path && nextTab > -1) setCurrentTab(nextTab);
              return true;
            }}
            onSave={async () => {
              setIsClosing(false);
              setIsSubmitting(false);
              if (nextTab > -1) setCurrentTab(nextTab);
            }}
          />
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: 'none' }}
          ></input>
          <Box sx={styles.root}>
            <TemplateHeader
              form={form}
              currentTab={currentTab}
              handleCurrentTabChange={handleTabChange}
              networkId={networkId}
              onBack={() => setIsClosing(true)}
            />
            <Box sx={styles.tabsContainer}>
              <TabPanel
                value={currentTab}
                index={0}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 0 && { height: '100%' },
                }}
              >
                <Box sx={styles.tabBody}>
                  <TemplateScreensaver form={form} />
                </Box>
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={1}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 1 && { height: '100%' },
                }}
              >
                <Box sx={styles.tabBody}>
                  <TemplateMainView form={form} />
                </Box>
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={2}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 2 && { height: '100%' },
                }}
              >
                <Box sx={styles.tabBody}>
                  <TemplateAppCta form={form} />
                </Box>
              </TabPanel>
              <DetailFormFooter
                form={form}
                submitting={submitting}
                onSubmit={async () => {
                  await form.submit();
                }}
              />
            </Box>
          </Box>
        </form>
      )}
    </Form>
  );
});

const KioskTemplatePage = () => {
  const { networkId } = useParams();

  return !isGlobalTemplate(networkId) ? (
    <PageHelpers
      showWorkspaceSwitcher={false}
      supportedWorkspace={LIVE_WORKSPACE}
    >
      <Suspense fallback={<Loading />}>
        <KioskTemplate networkId={networkId} />
        <WorkspaceSwitcher leftOffset={0} />
      </Suspense>
    </PageHelpers>
  ) : (
    <Navigate to="/" />
  );
};

export default KioskTemplatePage;
