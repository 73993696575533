import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkPlace } from 'hooks/dataHooks/useSites';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

const addFloorSchema = Yup.object().shape({
  floorName: Yup.string().required('Floor name is required'),
});

const validate = makeValidate(addFloorSchema);

const AddFloorModal = ({
  handleClose,
  handleConfirm,
  building,
  floorCount,
  networkName,
  networkLogo,
}) => {
  const classes = useStyles();
  const [createFloor] = useCreateNetworkPlace('floor');

  const onSubmit = async (values) => {
    const response = await createFloor({
      variables: {
        input: {
          name: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.floorName }],
          inheritAddress: true,
          isMapped: true,
          designation: 'floor',
          order: floorCount + 1,
          contact: {
            hours: {
              type: 'none',
            },
          },
        },
        meta: {
          parent: building.id,
        },
      },
    });
    handleConfirm(response?.data?.createNetworkPlace);
  };

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          siteName: getLabelByLang(building?.parentSite?.name),
          buildingName: getLabelByLang(building?.name),
          floorName: '',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={`${parseByLang(building?.name)} - ${
        building.isMapped ? 'Mapped' : 'Unmapped'
      } Building`}
      content={({ form }) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading}>
              Add New Floor
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Floor
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <FinalTextField
              label={getFormLabel('Parent Site Selection', true)}
              name="siteName"
              variant="outlined"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <FinalTextField
              label={getFormLabel('Parent Building Selection', true)}
              name="buildingName"
              variant="outlined"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '34px' }}>
            <FinalTextField
              label={getFormLabel('Floor Name', true)}
              name="floorName"
              variant="outlined"
              autoComplete="off"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddFloorModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  building: PropTypes.object.isRequired,
};

export default React.memo(AddFloorModal);
