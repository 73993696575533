import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    border: 'none',
    boxSizing: 'content-box',
    cursor: 'pointer',
    background: 'rgb(13, 106, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.25) -4px 0px 4px',
    borderRadius: '0px 5px 5px 0px',
    fontFamily: 'Poppins',
    left: 0,
    opacity: 1,
    padding: '15px 10px',
    position: 'fixed',
    right: 'auto',
    top: '90%',
    transform: 'translateY(calc(-90% - 0.5px))',
    transition: 'opacity 0.3s linear',
    whiteSpace: 'nowrap',
    writingMode: 'vertical-lr',
    zIndex: 1100,
  },
  content: {
    transform: 'rotate(180deg)',
    writingMode: 'vertical-rl',
  },
  text: {
    color: theme.palette.white,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '27px',
    writingMode: 'vertical-rl',
  },
});

const Help = ({ onClick }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <Box sx={styles.root} onClick={onClick}>
      <Box sx={styles.content}>
        <Box sx={styles.text}>Need help?</Box>
      </Box>
    </Box>
  );
};

Help.propTypes = {
  onClick: PropTypes.func.isRequired,
};

Help.defaultProps = {};

export default Help;
