import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import GenericModal from 'components/genericModal/genericModal';
import GozioTable from 'components/tables/gozioTable';
import { getTableCountTitle } from 'helpers/table-util';
import { useDeleteNetworkColors, useNetworkColors } from 'hooks/dataHooks/useNetworkConfiguration';
import ColorPalette from 'pages/gozio_colors';
import EditColorModal from 'pages/network/configuration/containers/editColorModal';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

const buildStyles = () => ({
  root: { height: '100%', paddingTop: '8px' },
  addButton: {
    marginLeft: '16px',
    marginRight: '4px',
    paddingTop: '12px!important',
    width: '36px',
    height: '36px',
  },
});

const COLUMNS = [
  {
    Header: 'Key',
    accessor: 'key',
    width: 300,
    minWidth: 300,
  },
  {
    Header: 'Color',
    accessor: 'value',
    disableGlobalFilter: true,
    width: 300,
    minWidth: 300,
    Cell: ({ cell }) => (
      <Box sx={{ display: 'flex', textTransform: 'uppercase' }}>
        <Box
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '12px',
            border: `1px solid ${ColorPalette.grey[300]}`,
            display: 'inline-block',
            marginRight: '16px',
            backgroundColor: `${cell.value}`,
          }}
        />
        {cell.value}
      </Box>
    ),
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableGlobalFilter: true,
    minWidth: 80,
    width: 80,
    disableSortBy: true,
    justifyRight: true,
  },
];

const Colors = ({ networkId }) => {
  const styles = buildStyles();
  const [editedColor, setEditedColor] = useState(null);
  const [deletedColor, setDeletedColor] = useState(null);

  const { loading, data } = useNetworkColors(networkId);
  const [deleteColor] = useDeleteNetworkColors(networkId);
  const showEdit = (color) => {
    setEditedColor(color);
  };

  const doDelete = async () => {
    await deleteColor({
      variables: {
        networkId: networkId,
        color: { key: deletedColor.key, value: deletedColor.value },
      },
    });
    setDeletedColor(null);
  };
  const showDelete = (color) => {
    setDeletedColor(color);
  };

  const showAdd = () => {
    setEditedColor({});
  };

  const memoizedData = useMemo(() => {
    const processData = (d) => (d?.getNetwork?.theme?.colors || []).map((color) => ({
        key: color.key,
        value: color.value,
        actions: (
          <Box className="hoverUnhide">
            <Tooltip title="Edit">
              <Edit
                onClick={() => showEdit(color)}
                sx={{ marginRight: '8px' }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete onClick={() => showDelete(color)} />
            </Tooltip>
          </Box>
        ),
      }));
    if (!loading) return processData(data);
  }, [data, loading]);

  const count = memoizedData?.length || 0;

  return (
    <Box sx={styles.root}>
      <GozioTable
        name="ColorsTable"
        columns={COLUMNS}
        data={memoizedData}
        loading={loading}
        sortBy={[{ id: 'key', desc: false }]}
        countTitle={getTableCountTitle(count, 'Application Color')}
        headerActions={
          <AddCircleButton
            onClick={() => showAdd()}
            sx={styles.addButton}
            tooltipTitle="Add an Application Color"
          />
        }
        filterable={true}
        rowsPerPage={50}
      />
      {editedColor && (
        <EditColorModal
          color={editedColor}
          handleClose={() => setEditedColor(null)}
          networkId={networkId}
        />
      )}
      {deletedColor && (
        <GenericModal
          title="Delete Color"
          body={`Are you sure ${deletedColor.key} is no longer in use and you want to proceed with deleting it?`}
          open={true}
          handleClose={() => setDeletedColor(null)}
          handleConfirm={doDelete}
          confirmText="Delete Color"
          customTags={{
            confirm: 'ColorDeleteModalConfirmButton',
            cancel: 'ColorDeleteModalCancelButton',
          }}
        />
      )}
    </Box>
  );
};

Colors.propTypes = {
  networkId: PropTypes.string.isRequired,
};

export default React.memo(Colors);
