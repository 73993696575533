import { Tooltip } from '@mui/material';
import React from 'react';

export const MINIMUM_SCREEN_WIDTH = 1280;
export const MINIMUM_SCREEN_HEIGHT = 800;

export const tabA11yProps = (key, index) => ({
  id: `${key}-tab-${index}`,
  'aria-controls': `${key}-tabpanel-${index}`,
  disableRipple: true,
});

export const wrapWithTooltip = (label) => label && (
    <Tooltip title={label} placement="top">
      <span>{label}</span>
    </Tooltip>
  );
