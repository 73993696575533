import { Cancel, CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GozioLogo from 'assets/gozio-logo.svg';
import GenericFormModal from 'components/genericModal/genericFormModal';
import GenericModal from 'components/genericModal/genericModal';
import { getFormLabel } from 'helpers/form-util';
import { useUpdateUser } from 'hooks/dataHooks/useUsers';
import useWindowSize from 'hooks/useWindowSize';
import _ from 'lodash';
import { makeValidate, TextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import StaticLayout from 'pages/layouts/staticLayout';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.blue[500],
    backgroundSize: 'cover',
    display: 'flex',
    flexGrow: 1,
    width: '452px',
    overflow: 'auto',
  },
  modal: {
    '& .MuiDialog-container': {
      background: theme.palette.blue[500],
    },
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '452px',
    },
    '& .MuiDialogContent-root': {
      marginBottom: 0,
      minWidth: 'unset',
    },
    '& .MuiTextField-root': {
      width: '388px',
    },
    '& .MuiDialogActions-root': {
      background: theme.palette.white,
      justifyContent: 'center !important',
      marginTop: '16px',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  header: {
    background: theme.palette.white,
    opacity: 1,
    paddingLeft: '0 !important',
    position: 'fixed',
    textAlign: 'center',
    width: '388px',
    zIndex: 10,
  },
  logo: {
    width: '147px',
    height: '87px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  welcomeTitle: {
    marginBottom: theme.spacing(0.5),
  },
  description: {
    fontSize: '16px',
  },
  scrollableContent: {
    marginTop: '224px',
  },
  field: {
    marginTop: theme.spacing(3),
  },
  passwordChecklist: {
    color: theme.palette.grey[500],
    fontSize: '16px',
    fontWeight: 'normal',
    listStyleType: 'none',
    marginTop: theme.spacing(4),
    paddingLeft: 0,

    '& li': {
      lineHeight: '24px',
      paddingTop: 0,

      '& div': {
        display: 'inline-block',
        width: '90%',
      },
    },

    '& svg': {
      marginRight: theme.spacing(1),
      verticalAlign: 'top',
    },
  },
  checkIcon: {
    color: theme.palette.green[400],
  },
  cancelIcon: {
    color: theme.palette.red[600],
  },
  confirmPassword: {
    marginBottom: theme.spacing(2),
  },
  successIcon: {
    color: theme.palette.success.main,
    width: '96px',
    height: '96px',

    '& path': {
      color: `${theme.palette.success.light} !important`,
    },
  },
  continueButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
});

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*\W).{8,128})$/, {
      message: 'Your new password does not meet the required criteria.',
      excludeEmptyString: true,
    }),
  passwordConfirmation: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('password'), null], 'Your password does not match.'),
});

const validate = makeValidate(schema);

const PASSWORD_CHECKLIST = [
  {
    regex: /^.{8,128}$/,
    label: 'At least 8 characters long',
  },
  {
    regex: /(?=.*[A-Z])/,
    label: 'Contains at least 1 uppercase letter',
  },
  {
    regex: /(?=.*[a-z])/,
    label: 'Contains at least 1 lowercase letter',
  },
  {
    regex: /(?=.*\d)/,
    label: 'Contains at least 1 number',
  },
  {
    regex: /(?=.*[!@#$%^&*])/,
    label: 'Contains at least 1 of the following special characters: !@#$%^&*',
  },
];

const AccountUpdate = ({ user }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { height } = useWindowSize();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const size = useWindowSize();

  const networkId = _.get(user, 'networks[0]');

  const [updateUser] = useUpdateUser(networkId);

  const onSubmit = async ({
                      firstName,
                      lastName,
                      password,
                      passwordConfirmation,
                    }) => {
    const response = await updateUser({
      variables: {
        id: user.id,
        input: {
          firstName,
          lastName,
          password,
          confirmPassword: passwordConfirmation,
        },
        meta: { action: 'signup' },
      },
    });
    if (!response?.errors?.message) setSignUpSuccess(true);
  };

  const redirectToHome = () => {
    window.location.reload(false);
  };

  const renderPasswordCheckList = ({ password }) => (
    <List sx={styles.passwordChecklist}>
      {PASSWORD_CHECKLIST.map(({ regex, label }, i) => {
        const passed = password ? regex.test(password) : false;
        return (
          <ListItem key={`item_${i}`}>
            {passed ? (
              <CheckCircle sx={styles.checkIcon} />
            ) : (
              <Cancel sx={styles.cancelIcon} />
            )}
            <Box>{label}</Box>
          </ListItem>
        );
      })}
    </List>
  );

  if (!networkId) {
    window.location.reload();
    return;
  }

  return (
    <StaticLayout>
      <Box sx={styles.root}>
        {!signUpSuccess && (
          <GenericFormModal
            formParams={{
              initialValues: {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                password: '',
                passwordConfirmation: '',
              },
              onSubmit,
              validate,
            }}
            title={null}
            body={({ form }) => (
              <Grid
                container
                direction="column"
                spacing={2}
                sx={{ marginLeft: '0 !important' }}
              >
                <Grid item xs={12} sx={styles.header}>
                  <img style={styles.logo} src={GozioLogo} alt="Gozio Health" />
                  <Typography variant="h1" sx={styles.welcomeTitle}>
                    Welcome to Gozio Portal!
                  </Typography>
                  <Typography variant="body1" sx={styles.description}>
                    Get started by updating your account!
                    <br />
                    information below.
                  </Typography>
                </Grid>
                <Box sx={styles.scrollableContent}>
                  <Grid item sx={styles.field}>
                    <TextField
                      label={getFormLabel('First Name', true)}
                      name="firstName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sx={styles.field}>
                    <TextField
                      label={getFormLabel('Last Name', true)}
                      name="lastName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sx={styles.field}>
                    <TextField
                      label={getFormLabel('New Password', true)}
                      name="password"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              sx={{ cursor: 'pointer' }}
                              aria-label="Toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              size="large"
                            >
                              {showPassword ? (
                                <Visibility
                                  sx={{ color: ColorPalette.grey[500] }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: ColorPalette.grey[500] }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        minLength: 8,
                        maxLength: 128,
                      }}
                    />
                    {renderPasswordCheckList(form.getState().values)}
                  </Grid>
                  <Grid item>
                    <TextField
                      label={getFormLabel('Confirm Password', true)}
                      name="passwordConfirmation"
                      sx={styles.confirmPassword}
                      variant="outlined"
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              sx={{ cursor: 'pointer' }}
                              aria-label="Toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)
                              }
                              size="large"
                            >
                              {showConfirmPassword ? (
                                <Visibility
                                  sx={{ color: ColorPalette.grey[500] }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: ColorPalette.grey[500] }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
            )}
            actionSx={styles.actionContainer}
            sx={{
              ...styles.modal,
              '& .MuiDialogContent-root > div': {
                height: `calc(${height}px - 184px)`,
                maxHeight: '848px',
              },
            }}
            confirmText="Create Account"
            confirmType="submit"
            disableCancel={true}
            disableClose={true}
            scrollable={size?.height <= 938}
          />
        )}
        {signUpSuccess && (
          <GenericModal
            sx={styles.modal}
            title={
              <Box sx={{ textAlign: 'center' }}>
                <CheckCircle sx={styles.successIcon} />
              </Box>
            }
            open={true}
            body={
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="h1">
                    Your account has been created
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={redirectToHome}
                    sx={styles.continueButton}
                    fullWidth={true}
                    data-test="signUp-continue-button"
                  >
                    Continue to Login
                  </Button>
                </Grid>
              </Grid>
            }
            disableActions={true}
          />
        )}
      </Box>
    </StaticLayout>
  );
};

AccountUpdate.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountUpdate;
