import { Box, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const GlobalFilter = ({ filter, setFilter, placeholder, numRecs }) => (
  <Box sx={{ flex: 1 }}>
    <TextField
      id="search-text"
      variant="outlined"
      placeholder={placeholder || `Search ${numRecs} records`}
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: ColorPalette.grey[400] }} />
          </InputAdornment>
        ),
        endAdornment: (filter || '').length > 0 && (
          <InputAdornment position="end">
            <CloseIcon
              fontSize="small"
              onClick={() => setFilter('')}
              style={{
                cursor: 'pointer',
                color: colorWithAlpha(ColorPalette.black, 0.54),
              }}
            />
          </InputAdornment>
        ),
      }}
      fullWidth
      margin="normal"
      sx={{ backgroundColor: ColorPalette.white, margin: 0 }}
    />
  </Box>
);

GlobalFilter.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  numRecs: PropTypes.number,
};

GlobalFilter.defaultProps = {
  filter: '',
  numRecs: 0,
};

export default GlobalFilter;
