import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DrawerStyles from 'components/navigation/drawer-styles';
import PropTypes from 'prop-types';
import React from 'react';

const SingleListItem = (props) => {
  const {
    classes,
    dataTestName,
    disabled,
    handleClick,
    isSelected,
    permission,
    sectionIcon,
    sectionName,
  } = props;

  if (disabled) {
    return <></>;
  }

  if (permission) {
    return (
      <ListItem
        button
        data-test={`${dataTestName ?? sectionName?.toLowerCase()}-link`}
        onClick={handleClick}
        selected={isSelected}
        classes={{
          root: classes.nestedListTitle,
          selected: classes.singleListTitleSelected,
        }}
      >
        {sectionIcon && (
          <ListItemIcon
            classes={{
              root: classes.nestedListIcon,
            }}
          >
            {sectionIcon}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          classes={{
            primary: classes.nestedListTitle,
          }}
          primary={sectionName}
        />
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      data-test={`${dataTestName ?? sectionName?.toLowerCase()}-link`}
      onClick={handleClick}
      selected={isSelected}
      classes={{
        root: classes.nestedListTitle,
        selected: classes.singleListTitleSelected,
      }}
    >
      {sectionIcon && (
        <ListItemIcon
          classes={{
            root: classes.nestedListIcon,
          }}
        >
          {sectionIcon}
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        classes={{
          primary: classes.nestedListTitle,
        }}
        primary={sectionName}
      />
    </ListItem>
  );
};

SingleListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTestName: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  permission: PropTypes.string,
  sectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SingleListItem.defaultProps = {
  dataTestName: null,
  disabled: false,
};

export default withStyles(DrawerStyles)(SingleListItem);
