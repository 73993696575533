import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries/fragments';

export const CREATE_NETWORK_BANNER_MESSAGE = gql`
  ${fragments.NETWORK_BANNNER_MESSAGE_ATTRIBUTES}
  mutation createNetworkBannerMessage(
    $input: NetworkBannerMessageInputCreate!
    $meta: NetworkBannerMessageMetaInput
  ) {
    createNetworkBannerMessage(input: $input, meta: $meta) {
      ...networkBannerMessageAttributes
    }
  }
`;

export const GET_NETWORK_BANNER_MESSAGES = gql`
  ${fragments.NETWORK_BANNNER_MESSAGE_ATTRIBUTES}
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      bannerMessages {
        ...networkBannerMessageAttributes
      }
    }
  }

`;

export const GET_NETWORK_BANNER_MESSAGE_HISTORY = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      bannerMessages {
        id
        title
        status
        publishedDate
        unpublishedDate
      }
    }
  }
`;

export const UPDATE_NETWORK_BANNER_MESSAGE = gql`
  ${fragments.NETWORK_BANNNER_MESSAGE_ATTRIBUTES}
  mutation updateNetworkBannerMessage(
    $id: ID!
    $input: NetworkBannerMessageInputUpdate!
    $meta: NetworkBannerMessageMetaInput
  ) {
    updateNetworkBannerMessage(id: $id, input: $input, meta: $meta) {
      ...networkBannerMessageAttributes
    }
  }
`;
