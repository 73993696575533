import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Asterisk from 'components/asterisk/asterisk';
import GenericFormModal from 'components/genericModal/genericFormModal';
import TextField from 'components/textField/textField';
import { httpRequest } from 'helpers/http-util';
import useLogout from 'hooks/useLogout';
import useToast from 'hooks/useToast';
import { useWorkspace } from 'hooks/useWorkspace';
import { makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const buildStyles = ({ theme }) => ({
  modal: {
    margin: 'auto',
    width: '768px',
  },
  content: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
});

const DESCRIPTION_MAX_LIMIT = 500;

const revertSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
});

const validate = makeValidate(revertSchema);

export const RevertModal = ({ handleCancel, handleCompleted }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const [disableConfirm, setDisableConfirm] = useState(false);

  const { networkId } = useParams();
  const { workspace } = useWorkspace();
  const { toastNotificationErrorHook, toastNotificationSuccessHook }
    = useToast();
  const logout = useLogout();

  const onSubmit = async (data) => {
    setDisableConfirm(true);
    try {
      const response = await httpRequest({
        url: `${process.env.REACT_APP_PUBLISHER_API_URL}/reverter`,
        body: {
          message: data.description,
        },
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          network: networkId,
          workspace,
        },
        logout,
      });
      if (response.status === 200) {
        const responseData = await response.json();
        toastNotificationSuccessHook(
          `Publishing event “#${responseData?.externalId}” has successfully been reverted. See Publishing History for details.`,
        );
      }
    } catch (err) {
      toastNotificationErrorHook(
        <span>
          Revert has failed.{' '}
          <a href="mailto:support@goziohealth.com">Email Gozio Support</a> for
          help.
        </span>,
      );
      console.error(err);
    }
    handleCompleted();
  };

  return (
    <GenericFormModal
      sx={styles.modal}
      title={null}
      formParams={{
        onSubmit,
        validate,
        initialValues: {
          description: '',
        },
      }}
      body={
        <Grid container direction="column" sx={styles.content}>
          <Grid item>
            <Typography variant="h1" sx={styles.title}>
              Revert To Previous Publish
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={styles.text}>
              By reverting to the previous published content, you are choosing
              to undo the present published content. Note that there is no
              undoing this action or reverting back further. Are you sure you
              want to revert to the previous publish?
            </Typography>
          </Grid>
          <Grid item>
            <Field name="description">
              {({ input: { value, onChange }, meta }) => (
                <TextField
                  name="Description"
                  fullWidth={true}
                  label={
                    <>
                      <Asterisk /> Description
                    </>
                  }
                  multiline
                  charLimit={DESCRIPTION_MAX_LIMIT}
                  minRows={6}
                  maxRows={6}
                  helperText={meta.touched ? meta.error : ''}
                  error={meta.touched && !!meta.error}
                  onChange={(val) => {
                    if (val.length <= DESCRIPTION_MAX_LIMIT) {
                      onChange(val);
                    }
                  }}
                  value={value}
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
        </Grid>
      }
      open={true}
      confirmText="REVERT"
      greyOutConfirm={disableConfirm}
      handleClose={() => handleCancel()}
    />
  );
};

RevertModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleCompleted: PropTypes.func.isRequired,
};

RevertModal.defaultProps = {};

export default RevertModal;
