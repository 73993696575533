import { DragIndicator, Settings } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loading from 'components/loading/loading';
import ColumnConfigModal from 'components/tables/columnConfigModal';
import EmptyTable from 'components/tables/emptyTable';
import GlobalFilter from 'components/tables/globalFilter';
import GozioTablePagination from 'components/tables/gozioTablePagination';
import { buildTableStyles } from 'components/tables/gozioTableStyles';
import TableFilters from 'components/tables/tableFilters';
import { processCellProps, processHeaderProps } from 'components/tables/tableHelpers';
import {
  persistTableFilters,
  persistTablePageSize,
  retrieveTableFilters,
  retrieveTablePageSize,
} from 'helpers/table-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useFlexLayout, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

const horizScrollMinWidth = '936px';
const getStickyStyle = (sticky, styles, isCell) => {
  if (!sticky) {
    return {};
  }

  if (sticky === 'left') {
    return {
      ...isCell ? styles.stickyCell : styles.sticky,
      ...styles.stickyLeft,
    };
  }

  return {
    ...isCell ? styles.stickyCell : styles.sticky,
    ...styles.stickyRight,
  };
};

const colHidden = (configuredColumns, id) => configuredColumns.find((c) => id === c.id)?.hidden;

const Cell = React.memo(
  ({
     cell,
     colIdx,
     rowIdx,
     dragRef,
     styles,
     rows,
     configuredColumns,
     showDrag,
     isSelected,
     selectable,
   }) => {
    const headerCol = cell.column;
    return (
      <TableCell
        ref={dragRef}
        className={
          headerCol.onClick
          && (typeof headerCol.isClickable === 'function'
            ? headerCol.isClickable(rows[rowIdx].values)
            : true)
            ? 'highlightOnHover'
            : ''
        }
        sx={{
          ...getStickyStyle(headerCol.sticky, styles, true),
          ...colHidden(configuredColumns, headerCol.id) && styles.hidden,
        }}
        {...processCellProps(
          cell.getCellProps(),
          headerCol,
          rows[rowIdx].values,
        )}
      >
        <Box
          sx={{
            ...styles.cell,
            ...headerCol.justifyRight && styles.justifyRight,
            ...headerCol.cellClass ?? {},
            ...headerCol.cellStyle,
          }}
        >
          {showDrag && (
            <Box
              sx={{ width: '20px', height: '20px', marginLeft: '-6px' }}
              className="dragIndicator"
            >
              <Box sx={styles.cellContent}>
                <DragIndicator
                  sx={{ fontSize: '20px', color: ColorPalette.grey[600] }}
                />
              </Box>
            </Box>
          )}
          {selectable && colIdx === 1 && (
            <Checkbox
              color="primary"
              sx={{ marginRight: '8px', ...styles.checkbox }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => cell.row?.toggleRowSelected()}
              checked={isSelected}
            />
          )}
          <Box
            sx={{
              ...styles.cellContent,
              ...headerCol.alwaysShown && styles.alwaysShown,
            }}
          >
            {headerCol.render
              ? headerCol.render(cell.render('Cell'))
              : cell.render('Cell')}
          </Box>
        </Box>
      </TableCell>
    );
  },
);

const Row = React.memo(
  ({
     row,
     rowIdx,
     styles,
     rows,
     prepareRow,
     configuredColumns,
     hideShowCols,
     onRowHovered,
     onRowLeave,
     isSelected,
     selectable,
   }) => {
    prepareRow(row);
    const { style, ...restProps } = row.getRowProps();
    return (
      <TableRow
        {...restProps}
        onMouseOver={() => onRowHovered && onRowHovered(row)}
        onMouseLeave={() => onRowLeave && onRowLeave(row)}
        sx={{
          ...style,
          ...hideShowCols ? { minWidth: horizScrollMinWidth } : null,
        }}
      >
        {row.cells.map((cell, colIdx) => (
          <Cell
            key={`td_${colIdx}`}
            row={row}
            cell={cell}
            colIdx={colIdx}
            rowIdx={rowIdx}
            styles={styles}
            rows={rows}
            configuredColumns={configuredColumns}
            isSelected={isSelected}
            selectable={selectable}
          />
        ))}
      </TableRow>
    );
  },
);

const DraggableRow = React.memo(
  ({
     row,
     rowIdx,
     styles,
     rows,
     prepareRow,
     onDrag,
     onDrop,
     rowId,
     configuredColumns,
     hideShowCols,
   }) => {
    const dropRef = useRef(null);
    const dragRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'row',
      drop: (item) => {
        if (onDrop) {
          onDrop(item, row, rowIdx);
        }
      },
      hover(item, monitor) {
        if (!onDrag || !dropRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = rowIdx;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < 24) {
          return;
        }

        // Dragging upwards
        const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
        if (dragIndex > hoverIndex && hoverClientY > hoverHeight - 24) {
          return;
        }

        // Time to actually perform the action
        onDrag(rows[dragIndex], row);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'row',
      item: { rowIdx },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    preview(drop(dropRef));
    drag(dragRef);

    prepareRow(row);
    const isInactive = row?.original?._meta?.inactive;

    const { style, ...restProps } = row.getRowProps();

    return (
      <TableRow
        ref={dropRef}
        {...restProps}
        sx={{
          ...style,
          ...isDragging && styles.dragging,
          ...isInactive && styles.inactive,
          ...styles.draggableRow,
          ...hideShowCols ? { minWidth: horizScrollMinWidth } : null,
        }}
      >
        {row.cells.map((cell, colIdx) => (
          <Cell
            key={`td_${rowId}_${colIdx}`}
            dragRef={colIdx === 0 ? dragRef : null}
            cell={cell}
            rowIdx={rowIdx}
            colIdx={colIdx}
            styles={styles}
            rows={rows}
            configuredColumns={configuredColumns}
            showDrag={colIdx === 0}
            style={hideShowCols ? { minWidth: horizScrollMinWidth } : null}
          />
        ))}
      </TableRow>
    );
  },
);

const getColumnsConfig = (configureColumns, name, columns) => {
  const savedConfig
    = configureColumns && name
      ? JSON.parse(localStorage.getItem(`${name}-table-cols`)) || []
      : [];
  const findConfig = (id) => savedConfig.find((c) => c.id === id);
  return columns.map((c) => ({
    id: c.accessor,
    label: c.Header,
    hidden:
      c.alwaysHidden === true
      || (findConfig(c.accessor)?.hidden === undefined
        ? c.hidden === true
        : findConfig(c.accessor)?.hidden === true),
    alwaysShown: c.alwaysShown === true,
    alwaysHidden: c.alwaysHidden === true,
    cellStyle: c.cellStyle || {},
  }));
};

const GozioTableWithPagination = ({
                                    sx,
                                    tableSx,
                                    data,
                                    dataCount,
                                    columns,
                                    countTitle,
                                    countTitleText,
                                    configureColumns,
                                    pageSizeOptions,
                                    currentPage,
                                    rowsPerPage,
                                    selectable,
                                    sortBy: initSort,
                                    filterable,
                                    customFilter,
                                    handleRowSelectUpdates,
                                    hidePagination,
                                    hideHeaderDivide,
                                    draggable,
                                    emptyTitle,
                                    onDrag,
                                    onDrop,
                                    onFiltersChanged,
                                    onRowHovered,
                                    onRowLeave,
                                    onRowClick,
                                    onSearchChanged,
                                    name,
                                    remoteDataFn,
                                    loading,
                                    disableSortRemove,
                                    headerActions,
                                    headerContent,
                                    emptyContent,
                                    fullWidth,
                                  }) => {
  const theme = useTheme();
  const styles = buildTableStyles({ theme });
  const containerRef = useRef(null);
  const tableRef = useRef(null);
  const [showColModal, setShowColModal] = useState(false);
  const [filters, setFilters] = useState(retrieveTableFilters(name));
  const [configuredColumns, setConfiguredColumns] = useState(
    getColumnsConfig(configureColumns, name, columns),
  );
  const [customGlobalFilter, setCustomGlobalFilter] = useState('');

  const [toggleSortOnce, setToggleSortOnce] = useState(false);
  const columnModalSuccess = (cols) => {
    localStorage.setItem(
      `${name}-table-cols`,
      JSON.stringify(cols.filter((c) => c.alwaysHidden !== true)),
    );
    setConfiguredColumns(cols);
    setShowColModal(false);
  };

  useEffect(() => {
    if (configureColumns) {
      setConfiguredColumns(getColumnsConfig(configureColumns, name, columns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  const tableSettings = {
    columns,
    data,
    initialState: {
      sortBy: initSort,
      pageIndex: remoteDataFn ? currentPage : currentPageIndex,
      pageSize: rowsPerPage ?? retrieveTablePageSize(name),
    },
    autoResetPage: true,
    autoResetSortBy: false,
    disableSortRemove,
    disableMultiSort: true,
  };

  if (remoteDataFn && !loading) {
    tableSettings.manualSortBy = true;
    tableSettings.manualPagination = true;
    tableSettings.pageCount = Math.ceil(dataCount / rowsPerPage);
    tableSettings.initialState.pageIndex = currentPage;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter = '', sortBy },
    toggleAllRowsSelected,
    isAllRowsSelected,
    selectedFlatRows,
  } = useTable(
    tableSettings,
    filterable && useGlobalFilter,
    useSortBy,
    !hidePagination && usePagination,
    useFlexLayout,
    selectable && useRowSelect,
  );

  // If the input data changes (maybe due to custom search and filters), reset the page number.
  useEffect(() => {
    setCurrentPageIndex(0);
    if (gotoPage && !remoteDataFn) {
      gotoPage(0);
    }
  }, [data, gotoPage, remoteDataFn]);

  useEffect(() => {
    if (initSort[0]?.clearSort && !toggleSortOnce) {
      headerGroups.forEach((row) => {
        row.headers.forEach((column) => {
          column.clearSortBy && column.clearSortBy();
        });
      });
      setToggleSortOnce(true);
    }
  }, [headerGroups, initSort, toggleSortOnce]);

  useEffect(() => {
    if (!initSort[0]?.clearSort && initSort[0]?.id && toggleSortOnce) {
      headerGroups.forEach((row) => {
        row.headers.forEach((column) => {
          if (column.id === initSort[0].id) {
            setToggleSortOnce(false);
            column.toggleSortBy();
          }
        });
      });
    }
  }, [initSort, toggleSortOnce, headerGroups]);

  useEffect(() => {
    handleRowSelectUpdates(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  useEffect(() => {
    if (remoteDataFn) {
      remoteDataFn(sortBy[0], pageIndex, pageSize, filters, gotoPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pageIndex, pageSize, filters]);

  const updateFilters = useCallback(
    (newFilters) => {
      if (!newFilters) {
        return;
      }
      if (name) {
        persistTableFilters(name, newFilters);
      }
      setFilters(newFilters);
      if (onFiltersChanged) onFiltersChanged(newFilters, globalFilter);
    },
    [name, onFiltersChanged, globalFilter],
  );

  const handleChangePage = (_, newPage) => {
    if (!remoteDataFn) setCurrentPageIndex(newPage);
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (!remoteDataFn) {
      setCurrentPageIndex(0);
    }
    const updatedPageSize = Number(event.target.value);
    if (name) {
      persistTablePageSize(name, updatedPageSize);
    }
    setPageSize(updatedPageSize);
  };

  const HeaderCell = ({ column, row, headerColIdx }) => {
    const { headerSx, ...restProps } = draggable
      ? processHeaderProps(column.getHeaderProps(), column)
      : processHeaderProps(
        column.getHeaderProps(column.getSortByToggleProps()),
        column,
      );
    return (
      <TableCell
        sx={{
          ...styles.header,
          ...getStickyStyle(column.sticky, styles),
          ...colHidden(configuredColumns, column.id) && styles.hidden,
          ...headerSx,
        }}
        {...restProps}
      >
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {selectable && headerColIdx === 1 && (
            <Checkbox
              color="primary"
              sx={{ marginRight: '8px', ...styles.checkbox }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => toggleAllRowsSelected()}
              checked={isAllRowsSelected}
            />
          )}
          <TableSortLabel
            active={column.isSorted}
            direction={column.isSortedDesc ? 'desc' : 'asc'}
            hideSortIcon={!column.canSort}
            sx={{
              ...styles.headerLabel,
              ...headerColIdx < row.headers.length - 1
                && !hideHeaderDivide
                && styles.headerLabelBorder,
              ...(!column.canSort || draggable) && styles.noSort,
              ...column.justifyRight && styles.justifyRight,
              ...draggable
                && !column.disableSortBy
                && headerColIdx === 1
                && styles.dragPadding,
            }}
          >
            {column.render('Header')}
          </TableSortLabel>
        </Box>
      </TableCell>
    );
  };

  const HeaderRow = ({ row, headerRowIdx }) => {
    const { style, ...restProps } = row.getHeaderGroupProps();
    return (
      <TableRow
        {...restProps}
        sx={{
          ...style,
          ...configureColumns
            ? { minWidth: horizScrollMinWidth }
            : { minWidth: 'unset' },
        }}
      >
        {row.headers.map((column, headerColIdx) => (
          <HeaderCell
            key={`th_${headerRowIdx}_${headerColIdx}`}
            row={row}
            column={column}
            headerColIdx={headerColIdx}
          />
        ))}
      </TableRow>
    );
  };

  const renderTable = () => {
    const { style, ...restProps } = getTableProps();
    return (
      <Table
        stickyHeader
        ref={tableRef}
        {...restProps}
        sx={{
          ...style,
          ...configureColumns ? { minWidth: horizScrollMinWidth } : null,
        }}
      >
        <TableHead sx={styles.stickyHeader}>
          {headerGroups.map((row, headerRowIdx) => (
            <HeaderRow
              key={`th_${headerRowIdx}`}
              row={row}
              headerRowIdx={headerRowIdx}
            />
          ))}
        </TableHead>
        {!loading && rows.length > 0 && (
          <TableBody {...getTableBodyProps()}>
            {(hidePagination ? rows : page).map((row, rowIdx) => draggable ? (
                <DraggableRow
                  key={`tr_${row.id || rowIdx}`}
                  row={row}
                  rowId={row.original.id}
                  rowIdx={rowIdx}
                  styles={styles}
                  rows={hidePagination ? rows : page}
                  prepareRow={prepareRow}
                  onDrag={onDrag}
                  onDrop={onDrop}
                  hideShowCols={configureColumns}
                  configuredColumns={configuredColumns}
                />
              ) : (
                <Row
                  key={`tr_${row.id || rowIdx}`}
                  row={row}
                  rowIdx={rowIdx}
                  styles={styles}
                  rows={hidePagination ? rows : page}
                  prepareRow={prepareRow}
                  hideShowCols={configureColumns}
                  configuredColumns={configuredColumns}
                  onRowHovered={onRowHovered}
                  onRowLeave={onRowLeave}
                  onRowClick={onRowClick}
                  isSelected={row.isSelected}
                  selectable={selectable}
                />
              ),
            )}
          </TableBody>
        )}
      </Table>
    );
  };

  const title = useMemo(() => {
    if (countTitle) {
      return countTitle;
    }

    if (countTitleText) {
      return `${rows.length} ${countTitleText}${rows.length !== 1 ? 's' : ''}`;
    }

    return null;
  }, [countTitle, countTitleText, rows]);

  return (
    <Box sx={{ ...styles.root, ...fullWidth && styles.fullWidth, ...sx }}>
      {loading ? (
        <Box sx={styles.tableLoading}>
          <Loading backgroundColor={ColorPalette.white} />
        </Box>
      ) : (
        <>
          {(title || configureColumns) && (
            <Box sx={styles.toolsRow}>
              <Box sx={styles.toolsPane}>
                <Typography variant="subtitle1" sx={styles.countTitle}>
                  {title || ' '}
                </Typography>
              </Box>

              <TableFilters
                updateFilters={updateFilters}
                columns={columns}
                selectedFilters={filters}
              />

              {configureColumns && (
                <IconButton
                  aria-label="Configure Columns"
                  onClick={() => {
                    setShowColModal(true);
                  }}
                  size="large"
                >
                  <Settings
                    sx={{ color: ColorPalette.grey[500], fontSize: '25px' }}
                  />
                </IconButton>
              )}
            </Box>
          )}
          {headerContent && <Box>{headerContent}</Box>}
          {data.length || customGlobalFilter ? (
            <>
              {(filterable || headerActions) && (
                <Box sx={{ ...styles.toolsRow, paddingTop: 0 }}>
                  <Box sx={{ flex: 1 }}>
                    {filterable && (
                      <GlobalFilter
                        filter={globalFilter || customGlobalFilter}
                        setFilter={(filterValue) => {
                          if (onSearchChanged) onSearchChanged(filterValue);
                          if (!customFilter) setGlobalFilter(filterValue);
                          else setCustomGlobalFilter(filterValue);
                        }}
                        numRecs={rows.length}
                        placeholder={'Search'}
                      />
                    )}
                  </Box>
                  {headerActions}
                </Box>
              )}
              <TableContainer
                ref={containerRef}
                sx={{
                  ...styles.table,
                  '&.MuiTableRow-root td': {},
                  '&.hoverUnhide': {
                    display: 'none !important',
                  },
                  ...tableSx,
                }}
              >
                {rows.length === 0 ? (
                  <EmptyTable title={emptyTitle} />
                )
                  : renderTable()
                }
              </TableContainer>

              {!hidePagination && (dataCount || rows.length) > pageSize && (
                <TableFooter sx={styles.footer} component="div">
                  <TableRow component="div">
                    <TablePagination
                      component="div"
                      sx={{
                        '& .MuiTablePagination-root':
                        styles.paginationContainer,
                        '& .MuiTablePagination-selectIcon':
                        styles.paginationSelectIcon,
                        '& .MuiTablePagination-selectRoot':
                        styles.paginationSelect,
                        '& .MuiTablePagination-toolbar':
                        styles.paginationToolbar,
                      }}
                      count={dataCount || data.length}
                      page={pageIndex}
                      rowsPerPageOptions={pageSizeOptions}
                      rowsPerPage={pageSize}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={GozioTablePagination}
                      labelDisplayedRows={() => ''}
                      labelRowsPerPage="Rows per page:"
                    />
                  </TableRow>
                </TableFooter>
              )}
              {configureColumns && (
                <ColumnConfigModal
                  showModal={showColModal}
                  setShowModal={setShowColModal}
                  handleSave={columnModalSuccess}
                  columns={configuredColumns}
                />
              )}
            </>
          )
            : !loading && emptyContent && emptyContent
          }
        </>
      )}
    </Box>
  );
};

GozioTableWithPagination.propTypes = {
  sx: PropTypes.object,
  tableSx: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      accessor: PropTypes.string.isRequired,
      cellStyle: PropTypes.object,
      disableClick: PropTypes.bool,
      hidden: PropTypes.bool,
      id: PropTypes.string,
      alwaysShown: PropTypes.bool,
      onClick: PropTypes.func,
      justifyRight: PropTypes.bool,
      render: PropTypes.func, // custom column rendering
      disableSortBy: PropTypes.bool,
      sticky: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
    }),
  ),
  countTitle: PropTypes.string,
  countTitleText: PropTypes.string,
  customFilter: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  disableSortRemove: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  selectable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortBy: PropTypes.array,
  remoteDataFn: PropTypes.func,
  handleRowSelectUpdates: PropTypes.func,
  hidePagination: PropTypes.bool,
  hideHeaderDivide: PropTypes.bool,
  configureColumns: PropTypes.bool,
  draggable: PropTypes.bool,
  emptyTitle: PropTypes.string,
  onDrag: PropTypes.func,
  onDrop: PropTypes.func,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  dataCount: PropTypes.number,
  onFiltersChanged: PropTypes.func,
  onRowHovered: PropTypes.func,
  onRowLeave: PropTypes.func,
  onRowClick: PropTypes.func,
  onSearchChanged: PropTypes.func,
  headerActions: PropTypes.node,
  headerContent: PropTypes.node,
  emptyContent: PropTypes.node,
};

GozioTableWithPagination.defaultProps = {
  sx: {},
  tableSx: {},
  columns: [],
  data: [],
  disableSortRemove: true,
  currentPage: 0,
  pageSizeOptions: [10, 20, 50, 100, 200],
  selectable: false,
  filterable: false,
  sortBy: [],
  handleRowSelectUpdates: (ids) => {},
  hidePagination: false,
  hideHeaderDivide: false,
  configureColumns: false,
  draggable: false,
  emptyTitle: null,
  onDrag: () => {},
  onDrop: () => {},
  onRowHovered: null,
  onRowLeave: null,
  onRowClick: null,
  loading: false,
  headerActions: null,
};

export default React.memo(GozioTableWithPagination);
