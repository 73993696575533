import { useMemo } from 'react';
import { useCustomQuery } from 'hooks/dataHooks/useCustomQuery';
import { FIND_SYSTEM_APP_DESTINATIONS } from 'graphql/queries';

const useInApp = () => useCustomQuery(FIND_SYSTEM_APP_DESTINATIONS, {
  dataFn: useMemo(() => (data) => data?.system?.appDestinations, []),
  initialState: [],
  skipFn: useMemo(() => (data) => data?.length > 0, []),
});

export {
  useInApp as default,
  FIND_SYSTEM_APP_DESTINATIONS,
};
