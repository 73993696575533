import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import DeprecatedModalStyles from 'components/_deprecated/deprecated-modal-styles';
import React, { PureComponent } from 'react';

class ToggleWeight extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChangeWeight = this.handleChangeWeight.bind(this);
  }

  handleChangeWeight(e, val) {
    const {
      input: { onChange },
    } = this.props;

    onChange(val);
  }

  render() {
    const { classes } = this.props;
    const {
      meta: { touched, error, warning },
      input,
    } = this.props;

    return (
      <Box>
        <Tooltip
          title="Weight helps the search engine determine sort order and relevance"
          placement="right"
          classes={{ tooltip: classes.toolTipWidth }}
        >
          <Box
            className={classes.buttonGroupTitle}
            sx={{ display: 'inline-block' }}
          >
            Weight
          </Box>
        </Tooltip>
        <ToggleButtonGroup
          value={input.value}
          exclusive
          onChange={this.handleChangeWeight}
          className={classes.toggleGroup}
        >
          <ToggleButton
            value="low"
            className={classes.toggleButton}
            classes={{ selected: classes.selected }}
            data-test="weight-low"
          >
            Low
          </ToggleButton>
          <ToggleButton
            value="medium"
            className={classes.toggleButton}
            classes={{ selected: classes.selected }}
            data-test="weight-medium"
          >
            Medium
          </ToggleButton>
          <ToggleButton
            value="high"
            className={`${classes.toggleButton} ${classes.lastToggleButton}`}
            classes={{ selected: classes.selected }}
            data-test="weight-high"
          >
            High
          </ToggleButton>
        </ToggleButtonGroup>
        <p className={classes.weightToggleError}>
          {touched
            && ((error && <span>{error}</span>)
              || (warning && <span>{warning}</span>))}
        </p>
      </Box>
    );
  }
}

export default withStyles(DeprecatedModalStyles)(ToggleWeight);
