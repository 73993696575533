import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import GenericFormModal from 'components/genericModal/genericFormModal';
import { getFormLabel } from 'helpers/form-util';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { makeValidate, TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import {
  CREATE_NETWORK_THEME_COLOR,
  GET_NETWORK_THEME_COLORS,
  SET_NETWORK_THEME_COLOR,
} from 'graphql/queries';

const generalSchema = Yup.object().shape({
  key: Yup.string().required('Key is required'),
  value: Yup.string().matches(/^#[a-fA-F0-9]{6}$|^#[a-fA-F0-9]{8}$/i, {
    message: 'Color must be valid hex color',
  }),
});

const validate = makeValidate(generalSchema);

const EditColorModal = ({ color, networkId, handleClose }) => {
  const [isEdit] = useState(!!color.key);
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Colors');

  const [addColor] = useMutation(CREATE_NETWORK_THEME_COLOR, {
    update: (cache, { data: { createNetworkThemeColors } }) => {
      try {
        cache.writeQuery({
          query: GET_NETWORK_THEME_COLORS,
          variables: { networkId },
          data: { getNetwork: createNetworkThemeColors },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onCompleted: () => {
      toastNotificationSuccessHook('Color added');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Color add failed'),
  });

  const [updateColor] = useMutation(SET_NETWORK_THEME_COLOR, {
    update: (cache, { data: { setNetworkThemeColor }, loading: updating }) => {
      try {
        cache.writeQuery({
          query: GET_NETWORK_THEME_COLORS,
          variables: { networkId },
          data: { getNetwork: setNetworkThemeColor },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onCompleted: () => {
      toastNotificationSuccessHook('Color updated');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Color update failed'),
  });

  const handleSave = async (values) => {
    let result;
    if (isEdit) {
      result = await updateColor({
        variables: {
          networkId,
          color: { key: values.key, value: values.value },
        },
      });
    } else {
      result = await addColor({
        variables: {
          networkId,
          colors: [{ key: values.key, value: values.value }],
        },
      });
    }
    if (result.data) handleClose();
  };
  return (
    <GenericFormModal
      title={isEdit ? 'Edit Color' : 'Add Color'}
      open={true}
      formParams={{
        onSubmit: handleSave,
        validate,
        initialValues: {
          key: color?.key,
          value: color?.value || '#FFFFFFFF',
        },
      }}
      body={
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              label={getFormLabel('Key', true)}
              name="key"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Field name="value">
              {({ input, meta }) => (
                <ColorOptions
                  label={'Color'}
                  required={true}
                  initialColor={input.value}
                  getColor={input.onChange}
                  defaultColor={'#FFFFFFFF'}
                  alpha="optional"
                  formControl={true}
                  helperText={meta.error}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      }
      confirmText={isEdit ? 'Save' : 'Add Color'}
      handleClose={handleClose}
    />
  );
};

export default EditColorModal;
