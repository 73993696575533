import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import App from 'app';
import { Auth0ProviderWithNavigate } from 'auth0ProviderWithNavigate';
import { ErrorFallback } from 'components/errorFallback/errorFallback';
import { FlamingoProvider } from 'contexts/flamingo';
import { DndProvider } from 'helpers/dnd-util';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import GozioTheme from 'pages/gozio_theme';
import React from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import store from 'store';
import routesConfig from 'router/config';
import { RouteGuard } from 'router/routeGuard';

const AppWrapper = () => (
  <Auth0ProviderWithNavigate>
    <FlamingoProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={GozioTheme}>
            <EmotionThemeProvider theme={GozioTheme}>
              <CssBaseline />
              <DndProvider>
                <ErrorBoundary
                  fallbackRender={ErrorFallback}
                  onError={onError}
                >
                  <App />
                </ErrorBoundary>
              </DndProvider>
            </EmotionThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </FlamingoProvider>
  </Auth0ProviderWithNavigate>
);

const router = createBrowserRouter([
  {
    element: <AppWrapper />,
    children: routesConfig.map(({ path, component, auth }, index) => (
      { path, element: <RouteGuard component={component} auth={auth} /> }
    )),
  },
]);


if (process.env.REACT_APP_LOG_ROCKET) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
  setupLogRocketReact(LogRocket);
}

const container = document.getElementById('root');
const root = createRoot(container);

const onError = (error, info) => {
  console.error('Unexpected error: ', error);
  console.error('Unexpected error info: ', info);
  if (process.env.REACT_APP_LOG_ROCKET) {
    LogRocket.captureException(error, {
      extra: {
        info: info,
      },
    });
  }
};

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
