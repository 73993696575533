import { gql, useQuery } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Autocomplete from 'components/autocomplete/autoComplete';
import GozioListItem from 'components/listItem/gozioListItem';
import Loading from 'components/loading/loading';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import useHandleError from 'hooks/useHandleError';
import update from 'immutability-helper';
import _ from 'lodash';
import { Radios } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router-dom';

const FIND_RESERVED_CATEGORIES_QUERY = gql`
  query {
    system {
      configuration {
        reservedCategories {
          id
          name
        }
      }
    }
  }
`;

const FIND_AVAILABLE_LOCATIONS_BY_NETWORK_ID_QUERY = gql`
  query findNetworkDashboardsByNetworkId($networkId: ID!) {
    findNetworkDashboardsByNetworkId(id: $networkId) {
      id
      availableSites {
        id
        name
        geoLocation {
          address {
            street
            city
            state
            zip
          }
        }
        categories {
          id
        }
        descendants {
          edges {
            node {
              id
              name
              designation
              externalId
              geoLocation {
                address {
                  street
                  city
                  state
                  zip
                }
              }
              categories {
                id
              }
              parentSite {
                name
              }
              parentBuilding {
                name
              }
              parentFloor {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const EMERGENCY_DEPARTMENT = 'Emergency department';
const URGENT_CARE = 'Urgent Care';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioContainer: {
    marginTop: theme.spacing(1),
  },
  radios: {
    fontSize: '16px',
    color: theme.palette.grey[600],
  },
  noLocations: {
    marginTop: theme.spacing(1),
    fontSize: '12px',
  },
  deleteIcon: {
    color: colorWithAlpha(theme.palette.black, 0.54),
    cursor: 'pointer',
    height: '24px',
    width: '24px',
  },
  customSelect: {
    marginTop: theme.spacing(2),
  },
  loadingWrap: {
    height: 'calc(100vh - 206px)',
  },
}));

const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const hasCategoryId = (categories, categoryId) => {
  if (categories && categories.length) {
    return !!categories.find((o) => o?.id === categoryId);
  }

  return false;
};

const mapLocations = (
  sites = [],
  emergencyDepartmentCategoryId,
  urgentCareCategoryId,
) => {
  const ucList = [];
  const edList = [];
  const customList = [];
  sites.forEach((site) => {
    if (!site) {
      return;
    }
    const siteData = {
      id: site.id,
      name: site.name,
      type: 'site',
      details: getThirdDataLine(site),
    };
    customList.push(siteData);

    if (site.categories) {
      if (hasCategoryId(site.categories, urgentCareCategoryId)) {
        ucList.push(siteData);
      } else if (
        hasCategoryId(site.categories, emergencyDepartmentCategoryId)
      ) {
        edList.push(siteData);
      }
    }

    if (site.descendants?.edges) {
      site.descendants.edges.forEach((child) => {
        let locationData;
        if (child?.node?.designation === 'building') {
          locationData = {
            id: child?.node?.id,
            siteId: site.id,
            buildingExternalId: child?.node?.externalId,
            name: child?.node?.name,
            details: getSecondDataLine(child),
            details2: getThirdDataLine(child),
            type: 'building',
          };
          customList.push(locationData);
        } else if (child?.node?.designation === 'poi') {
          locationData = {
            id: child?.node?.id,
            name: child?.node?.name,
            details: getSecondDataLine(child),
            details2: getThirdDataLine(child),
            type: 'place',
          };
          customList.push(locationData);
        }

        if (locationData && child?.node?.categories) {
          if (
            hasCategoryId(
              child?.node?.categories,
              emergencyDepartmentCategoryId,
            )
          ) {
            edList.push(locationData);
          } else if (
            hasCategoryId(child?.node?.categories, urgentCareCategoryId)
          ) {
            ucList.push(locationData);
          }
        }
      });
    }
  });

  return {
    ucList,
    erList: edList,
    customList,
  };
};

const getSecondDataLine = (item) => {
  if (item.node) item = item.node;
  if (
    !item?.parentSite?.name
    || (!item?.parentBuilding?.name && !item?.parentFloor?.name)
  ) return null;
  const labels = [];
  if (item?.parentSite?.name) labels.push(`${getLabelByLang(item.parentSite.name)}`);
  if (item?.parentBuilding?.name) labels.push(`${getLabelByLang(item.parentBuilding.name)}`);
  if (item?.parentFloor?.name) labels.push(`${getLabelByLang(item.parentFloor.name)}`);
  return labels.join(', ');
};

const getThirdDataLine = (item) => {
  if (item.node) item = item.node;
  if (
    !item?.geoLocation?.address?.street
    && !item?.geoLocation?.address?.state
    && !item?.geoLocation?.address?.city
    && !item?.geoLocation?.address?.zip
  ) return null;

  const labels = [];
  if (item?.geoLocation?.address?.street) labels.push(item.geoLocation.address.street);
  if (item?.geoLocation?.address?.city) labels.push(item.geoLocation.address.city);
  if (item?.geoLocation?.address?.state) labels.push(item.geoLocation.address.state);
  let retVal = labels.join(', ');
  if (item?.geoLocation?.address?.zip) retVal = `${retVal} ${item.geoLocation.address.zip}`;
  return retVal;
};

const LinkedLocations = ({
                           form,
                           locations = {},
                           customLocations,
                           onCustomLocationsAdded,
                         }) => {
  const { networkId } = useParams();

  const [emergencyDepartmentCategoryId, setEmergencyDepartmentCategoryId]
    = useState(null);
  const [urgentCareCategoryId, setUrgentCareCategoryId] = useState(null);
  const [urgentCareFacilities, setUrgentCareFacilities] = useState([]);
  const [emergencyDepartments, setEmergencyDepartments] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allCustomLocations, setAllCustomLocations] = useState([]);
  const { handleError } = useHandleError('LinkedLocations');

  const { loading: reservedCategoriesLoading, data: reservedCategoriesData }
    = useQuery(FIND_RESERVED_CATEGORIES_QUERY, {
      context: { headers: { network: networkId } },
      errorPolicy: 'all',
      onError: ({ graphQLErrors, networkError }) => handleError(
          graphQLErrors,
          networkError,
          'Reserved categories load failure',
        ),
    });

  const {
    loading: locationsLoading,
    data: locationsData,
    refetch: refetchLocationsData,
  } = useQuery(FIND_AVAILABLE_LOCATIONS_BY_NETWORK_ID_QUERY, {
    context: { headers: { network: networkId } },
    variables: { networkId },
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(
        graphQLErrors,
        networkError,
        'LinkedLocations loading failure',
      ),
  });

  useEffect(() => {
    if (!locationsLoading) {
      refetchLocationsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reservedCategoriesData?.system?.configuration?.reservedCategories) {
      reservedCategoriesData.system.configuration.reservedCategories.forEach(
        ({ id, name }) => {
          name.forEach(({ lang, label }) => {
            if (lang === LANGUAGE_CODES.ENGLISH) {
              if (EMERGENCY_DEPARTMENT === label) {
                setEmergencyDepartmentCategoryId(id);
              } else if (URGENT_CARE === label) {
                setUrgentCareCategoryId(id);
              }
            }
          });
        },
      );
    }
  }, [
    reservedCategoriesData,
    setEmergencyDepartmentCategoryId,
    setUrgentCareCategoryId,
  ]);

  useEffect(() => {
    if (
      emergencyDepartmentCategoryId
      && urgentCareCategoryId
      && locationsData?.findNetworkDashboardsByNetworkId
    ) {
      const availableSites = _.get(
        locationsData.findNetworkDashboardsByNetworkId,
        '0.availableSites',
        [],
      );

      const { ucList, erList, customList } = mapLocations(
        availableSites,
        emergencyDepartmentCategoryId,
        urgentCareCategoryId,
      );
      setUrgentCareFacilities(ucList);
      setEmergencyDepartments(erList);
      setAllLocations(customList);

      const deleteList = locations
        ? locations.allUrgentCare
          ? ucList
          : locations.allEmergencyDepartments
            ? erList
            : customLocations
        : customLocations ?? [];
      setAllCustomLocations(
        customList
          .filter((loc) => {
            if (deleteList.find((delLoc) => delLoc.id === loc.id)) {
              return null;
            }

            return loc;
          })
          .filter((l) => l),
      );
    }
  }, [
    customLocations,
    emergencyDepartmentCategoryId,
    locations,
    locationsData,
    setAllCustomLocations,
    urgentCareCategoryId,
  ]);

  const classes = useStyles();

  const deleteLocation = (type, location) => {
    let list;
    switch (type) {
      case 'allUrgentCare':
        form.mutators.setCategoryType('custom');
        list = urgentCareFacilities;
        break;
      case 'allEmergencyDepartments':
        form.mutators.setCategoryType('custom');
        list = emergencyDepartments;
        break;
      default:
        list = customLocations;
        setAllCustomLocations(
          update(allCustomLocations, {
            $push: [location],
          }),
        );
        break;
    }

    onCustomLocationsAdded(list.filter((loc) => loc.id !== location.id));
  };

  const getListOptions = (type) => {
    const list
      = type === 'allUrgentCare'
        ? urgentCareFacilities
        : type === 'allEmergencyDepartments'
          ? emergencyDepartments
          : customLocations;
    return list.map((location) => ({
      id: location?.id,
      label: getLabelByLang(location?.name),
      secondaryText: [location.details, location.details2],
      actions: [
        {
          Icon: Delete,
          hover: 'Delete',
          onClick: (id) => deleteLocation(type, location),
        },
      ],
    }));
  };

  if (reservedCategoriesLoading || locationsLoading) return (
      <Box className={classes.loadingWrap}>
        <Loading backgroundColor={ColorPalette.white} />
      </Box>
    );

  const handleCustomLocationSelect = (selectedLocation) => {
    const location = allLocations.find((loc) => loc.id === selectedLocation);
    onCustomLocationsAdded(
      update(customLocations, {
        $push: [location],
      }),
    );
    setAllCustomLocations(
      allCustomLocations.filter((loc) => loc.id !== location.id),
    );
    form.change('customLocation', null);
  };

  return (
    <Box className={classes.root}>
      <Grid item xs>
        <Typography variant="h2">Quicklink Type</Typography>
      </Grid>
      <Grid item xs className={classes.radioContainer}>
        <Radios
          name="categoryType"
          className={classes.radios}
          color="primary"
          data={[
            {
              label: 'All urgent care facilities',
              value: 'allUrgentCare',
            },
            {
              label: 'All emergency departments',
              value: 'allEmergencyDepartments',
            },
            {
              label: 'Custom locations list',
              value: 'custom',
            },
          ]}
          radioGroupProps={{
            onChange: (e) => {
              if (e.target.value !== 'custom') {
                setAllCustomLocations(Array.from(allLocations));
                onCustomLocationsAdded([]);
              }
            },
          }}
        />
      </Grid>
      <Field name="categoryType" subscription={{ value: true }}>
        {({ input: { value: selectedCategory } }) => {
          const listOptions = getListOptions(selectedCategory).sort(nameSort);
          const locOpts = allCustomLocations.map((loc) => ({
            id: loc.id,
            label: getLabelByLang(loc.name),
            label2: loc.details || loc.details2,
            type: loc.type,
          }));
          return (
            <Grid item xs className={classes.customSelect}>
              {selectedCategory === 'custom' && (
                <Field name="customLocation">
                  {({ input }) => (
                    <Autocomplete
                      label="Add a location"
                      options={locOpts}
                      input={{
                        value: input.value,
                        onChange: handleCustomLocationSelect,
                      }}
                    />
                  )}
                </Field>
              )}
              <Box sx={{ marginTop: '24px' }}>
                <GozioListItem
                  title="Locations added"
                  enableReorder={false}
                  options={listOptions}
                  emptyMessage={
                    selectedCategory === 'custom'
                      ? 'No locations have been added'
                      : 'No locations available'
                  }
                />
              </Box>
            </Grid>
          );
        }}
      </Field>
    </Box>
  );
};

LinkedLocations.propTypes = {
  form: PropTypes.object.isRequired,
  locations: PropTypes.shape({
    allUrgentCare: PropTypes.bool,
    allEmergencyDepartments: PropTypes.bool,
    custom: PropTypes.object,
  }),
  customLocations: PropTypes.array,
  onCustomLocationsAdded: PropTypes.func.isRequired,
};

LinkedLocations.defaultProps = {
  locations: {},
  customLocations: [],
};

export default LinkedLocations;
