import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormSubSection from 'components/forms/formSubSection';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import GozioSlider from 'components/slider/slider';
import { capitalize } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  visibilityText: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
  priorityText: {
    color: theme.palette.grey[600],
    marginTop: '30px',
    marginBottom: '8px',
  },
}));

const MapVisibility = ({ form, values }) => {
  const classes = useStyles();

  useField('visibility');
  useField('priority');

  const handleVisibilityChange = (v) => {
    form.mutators.setValue('visibility', v);
    if (v === 'never') {
      form.mutators.setValue('priority', 50);
    }
  };

  const handlePriorityChange = (p) => {
    form.mutators.setValue('priority', p === 1 ? 1 : (p - 1) * 25);
  };

  const convertPriorityValue = (p = 50) => {
    switch (p) {
      case 1:
        return 1;
      case 25:
        return 2;
      case 50:
        return 3;
      case 75:
        return 4;
      case 100:
        return 5;
      default:
        return 3;
    }
  };

  const locationDesignation = useMemo(
    () => values?.designation === 'poi' ? 'POI' : capitalize(values.designation),
    [values],
  );

  const visibilityOptions = useMemo(() => {
    const options = [
      { label: 'Always', value: 'always' },
      { label: 'Onsite', value: 'onsite' },
      { label: 'Never', value: 'never' },
    ];
    if (!values.isMapped) {
      options.splice(1, 1);
    }
    return options;
  }, [values]);

  return (
    <FormSubSection name="Map Visibility">
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.visibilityText}>
          Do you want this {locationDesignation} to show up on the Gozio Site
          map?
        </Typography>
        <GozioRadioGroup
          onChange={handleVisibilityChange}
          options={visibilityOptions}
          groupName="visibility"
          value={values.visibility ?? 'always'}
        />
      </Grid>
      {values?.designation === 'poi' && values.visibility !== 'never' && (
        <Grid item xs={9}>
          <Typography variant="body1" className={classes.priorityText}>
            How Important is It to See This {locationDesignation} on the Map?
          </Typography>
          <GozioSlider
            input={{
              value: convertPriorityValue(values.priority),
              onChange: handlePriorityChange,
            }}
            marks={[
              { value: 1, label: 'Trivial' },
              { value: 2, label: 'Low' },
              { value: 3, label: 'Neutral' },
              { value: 4, label: 'Medium' },
              { value: 5, label: 'High' },
            ]}
          />
        </Grid>
      )}
    </FormSubSection>
  );
};

MapVisibility.propTypes = {
  values: PropTypes.object,
  form: PropTypes.object.isRequired,
};

MapVisibility.defaultProps = {
  values: {},
};

export default MapVisibility;
