import { formatLang, parseByLang } from 'helpers/form-util';
import { buildLanguageSchema, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { allowedCharacterRegex } from 'helpers/location-util';
import { softDeleteImage } from 'helpers/media-util';
import { uploadImage, uploadImageToService } from 'modules/media';
import { makeValidate } from 'mui-rff';
import {
  deleteMedia,
  updateMedia,
  uploadMediaImagesAndVideos,
} from 'pages/locations/containers/detail/siteFormHelper';
import {
  DEFAULT_CUSTOM_HOURS,
  filterExceptionHours,
  HOURS_VALIDATION_OBJECT,
} from 'pages/locations/containers/locationsFormHelper';
import * as Yup from 'yup';

export const buildValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          // POI DETAILS
          name: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .required('Site name is required'),
          shortName: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),
          shortNameES: Yup.string()
            .matches(allowedCharacterRegex, `Only UTF-8 characters are allowed`)
            .max(40, 'Short Name must be 40 characters or less'),

          // POI ADDRESS
          street: Yup.string().nullable(),
          streetNumber: Yup.string().nullable(),
          building: Yup.string().nullable(),
          floor: Yup.string().nullable(),
          suite: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string()
            .nullable()
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code'),
          destinationLatitude: Yup.number()
            .nullable()
            .typeError('Must be a numeric value')
            .min(-90, 'Must be a numeric value between -90 and 90 (inclusive)')
            .max(90, 'Must be a numeric value between -90 and 90 (inclusive)'),
          destinationLongitude: Yup.number()
            .nullable()
            .typeError('Must be a numeric value')
            .min(
              -180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            )
            .max(
              180,
              'Must be a numeric value between -180 and 180 (inclusive)',
            ),
          departmentIds: Yup.array().of(Yup.string()),

          // POI PROVIDER MATCHING
          providerMatchingEnabled: Yup.bool(),
          providerMatchingRule: Yup.string().when('providerMatchingEnabled', {
            is: true,
            then: (schema) => schema
                .typeError('Not a valid JSON string')
                .required('JSON input is required'),
            otherwise: (schema) => schema.nullable(),
          }),

          // // POI MEDIA
          youTubeUrl: Yup.string().matches(
            /^$|(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/,
            'We only support YouTube videos at this time.',
          ),

          // POI MAP VISIBILITY
          visibility: Yup.string().oneOf(['always', 'onsite', 'never']),
          priority: Yup.number(),

          // POI SEARCHABILITY
          searchability: Yup.string().oneOf(['always', 'onsite', 'never']),

          // Extra Info
          extraMessage: Yup.string()
            .nullable()
            .max(300, 'Message must be 300 characters or less'),
          extraMessageES: Yup.string()
            .nullable()
            .max(300, 'Message must be 300 characters or less'),

          // HOURS
          ...HOURS_VALIDATION_OBJECT,
        },
        languages,
        {
          shortName: LANGUAGE_CODES.ENGLISH,
          shortNameES: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

const getParentAddress = (data, inheritAddress) => {
  let parent;
  if (data.parentBuilding && data.parentBuilding.inheritAddress === false) {
    parent = JSON.parse(JSON.stringify(data.parentBuilding));
  } else {
    parent = JSON.parse(JSON.stringify(data.parentSite));
  }
  if (inheritAddress) {
    if (parent?.geoLocation?.address) {
      if (!parent.geoLocation.address.building) {
        parent.geoLocation.address.building = getLabelByLang(parent.name);
      }
      if (!parent.geoLocation.address.floor) {
        parent.geoLocation.address.floor = getLabelByLang(
          data.parentFloor?.name,
        );
      }
    }
  }
  return parent;
};

const parseAddress = (data) => {
  const inheritAddress
    = data?.inheritAddress === false ? 'override' : 'inherit';
  const autoPopulateCoords
    = data?.metadata?.ui?.autoPopulateCoordinatesFromAddress;

  const parent = getParentAddress(data, data?.inheritAddress);
  const parentGeoLocation = parent?.geoLocation;
  const defaultAddress = data?.geoLocation?.address;

  const retVal = { inheritAddress, autoPopulateCoords };
  if (defaultAddress?.street) {
    retVal.street = defaultAddress.street;
  }
  if (defaultAddress?.building) {
    retVal.building = defaultAddress.building;
  }
  if (defaultAddress?.floor) {
    retVal.floor = defaultAddress.floor;
  }
  if (defaultAddress?.suite) {
    retVal.suite = defaultAddress.suite;
  }
  if (defaultAddress?.city) {
    retVal.city = defaultAddress.city;
  }
  if (defaultAddress?.state) {
    retVal.state = defaultAddress.state;
  }
  if (defaultAddress?.zip) {
    retVal.zip = defaultAddress.zip;
  }

  if (data?.designation === 'poi' && data?.isMapped) {
    if (data?.inheritAddress) {
      retVal.building = parent?.geoLocation?.address?.building;
      retVal.floor = parent?.geoLocation?.address?.floor;
    }
  }

  if (parent?.designation === 'site' && parent?.isMapped) {
    retVal.parentLat = parentGeoLocation?.markerPoint?.lat;
    retVal.parentLong = parentGeoLocation?.markerPoint?.lng;
  } else {
    retVal.parentLat = parentGeoLocation?.navigationPoint?.lat;
    retVal.parentLong = parentGeoLocation?.navigationPoint?.lng;
  }
  retVal.parentAddress = parentGeoLocation?.address;

  retVal.displayLatitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLat
      : data?.geoLocation?.markerPoint?.lat;
  retVal.displayLongitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLong
      : data?.geoLocation?.markerPoint?.lng;
  retVal.destinationLatitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLat
      : data?.geoLocation?.navigationPoint?.lat;
  retVal.destinationLongitude
    = inheritAddress === 'inherit' && autoPopulateCoords
      ? retVal.parentLong
      : data?.geoLocation?.navigationPoint?.lng;

  return retVal;
};

export const initialParser = (POIData, languages) => {
  const tempVal = {
    designation: POIData?.designation,
    saveBack: null,
    isMapped: POIData?.isMapped || false,
    langES: hasSpanish(languages) || false,
    // MAPPING INPUTS
    ...!!POIData?.indoorLocation?.mapKey && {
      mapID: POIData?.indoorLocation?.mapKey,
    },
    xCoord: POIData?.indoorLocation?.indoorPoint?.x || 0,
    yCoord: POIData?.indoorLocation?.indoorPoint?.y || 0,

    isParkingArea: POIData?.isParkingArea,

    // POI DETAILS
    externalId: POIData?.externalId,
    ...!!parseByLang(POIData?.name) && {
      name: parseByLang(POIData?.name),
    },
    ...!!parseByLang(POIData?.name, LANGUAGE_CODES.SPANISH) && {
      nameES: parseByLang(POIData?.name, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(POIData?.shortName) && {
      shortName: parseByLang(POIData?.shortName),
    },
    ...!!parseByLang(POIData?.shortName, LANGUAGE_CODES.SPANISH) && {
      shortNameES: parseByLang(POIData?.shortName, LANGUAGE_CODES.SPANISH),
    },
    ...!!parseByLang(POIData?.description) && {
      description: parseByLang(POIData?.description),
    },
    ...!!parseByLang(POIData?.description, LANGUAGE_CODES.SPANISH) && {
      descriptionES: parseByLang(POIData?.description, LANGUAGE_CODES.SPANISH),
    },

    // Parent Info
    parentSite: POIData?.parentSite
      ? parseByLang(POIData.parentSite.name)
      : null,
    parentBuilding: POIData?.parentBuilding
      ? parseByLang(POIData.parentBuilding.name)
      : null,
    parentFloor: POIData?.parentFloor
      ? parseByLang(POIData.parentFloor.name)
      : null,

    //  POI ADDRESS
    ...parseAddress(POIData),

    defaultDestination: '',
    ...!!POIData?.departmentIds && {
      departmentIds: POIData.departmentIds ?? [],
    },

    providerMatchingEnabled: POIData?.matching?.enabled || false,
    providerMatchingRule:
      JSON.stringify(POIData?.matching?.rule, null, 2) || '',

    // HOURS
    hoursType: POIData?.contact?.hours?.type ?? 'none',
    customHours: POIData?.contact?.hours?.custom ?? [...DEFAULT_CUSTOM_HOURS],
    exceptionHours: filterExceptionHours(POIData?.contact?.exceptionHours),

    // POI MEDIA
    ...!!POIData?.media && {
      media: POIData.media ?? [],
    },
    ...!!POIData?.defaultImage && {
      defaultImage: POIData.defaultImage,
    },
    ...!!POIData?.navigation?.arrivalImage && {
      arrivalImage: POIData.navigation.arrivalImage,
    },
    deletedArrivalImageId: null,

    // MAP VISIBILITY
    visibility: POIData?.visibility ?? 'always',
    priority: POIData?.priority ?? 50,

    // SEARCHABILITY
    searchability: POIData?.searchability ?? 'always',

    // Categories
    ...!!POIData?.categories && {
      categories:
        POIData.categories?.map((cat) => ({
          id: cat.id,
          name: parseByLang(cat.name),
        })) || [],
    },
    excludeCategoryTags: POIData?.excludeCategoryTags || [],

    // Tags
    ...parseTags(POIData?.tags?.flatMap((item) => item.name)),

    // no ids, so we need to track idx as id
    actionLinks:
      POIData?.actionLinks?.map((val, idx) => ({ idx, ...val })) || [],

    //extra
    ...POIData.isMapped && {
      extraDirective: POIData?.navigation?.reroute?.directive || 'none',
      extraDisclaimer: parseByLang(POIData?.navigation?.disclaimer),
      extraDisclaimerES: parseByLang(
        POIData?.navigation?.disclaimer,
        LANGUAGE_CODES.SPANISH,
      ),
      extraMessage: parseByLang(POIData?.navigation?.reroute?.message),
      extraMessageES: parseByLang(
        POIData?.navigation?.reroute?.message,
        LANGUAGE_CODES.SPANISH,
      ),
      extraImage: POIData?.navigation?.reroute?.image || {},
      extraDestination: POIData?.navigation?.reroute?.destination?.id,
    },
  };
  return tempVal;
};

const parseTags = (tagArray) => {
  const tagsEn = [];
  const tagsEs = [];
  if (tagArray) {
    tagArray.forEach((tag) => {
      if (tag.lang === LANGUAGE_CODES.ENGLISH) {
        tagsEn.push(tag.label);
      } else if (tag.lang === LANGUAGE_CODES.SPANISH) {
        tagsEs.push(tag.label);
      }
    });
  }
  return { tags: { en: tagsEn, es: tagsEs } };
};

const persistExtraImage = async (image) => {
  // save image as below
  let imageId = image?.id;
  if (image?.file) {
    const uploadedImage = await uploadImage(image.file);
    imageId = uploadedImage?.id;
  }
  return imageId;
};

const buildActionLink = async (link) => {
  let imageId;
  if (link.type === 'custom') {
    imageId = link.icon?.image?.id;
    if (link.icon?.file) {
      const uploadedImage = await uploadImage(link.icon.file);
      imageId = uploadedImage?.id;
    }
  }
  return {
    ...link,
    __typename: undefined,
    icon: imageId,
    idx: undefined,
  };
};

const prepareActionLinks = async (actionLinks) => {
  if (!actionLinks) return [];

  return Promise.all(actionLinks.map((link) => buildActionLink(link)));
};

export const submitParser = async (dispatch, values = {}) => {
  const geolocationValues = { address: {} };
  [
    'building',
    'floor',
    'suite',
    'street',
    'streetNumber',
    'city',
    'state',
    'zip',
  ].forEach((fieldName) => {
    geolocationValues.address[fieldName] = values.hasOwnProperty(fieldName)
      ? values[fieldName]
      : null;
  });

  const restValues = {};
  if (values.media) {
    const mediaToBeUploaded = [];
    const mediaToBeUpdated = [];
    const mediaToBeDeleted = [];
    values.media.forEach((m) => {
      if (m.id) {
        if (m.toBeUpdated) {
          mediaToBeUpdated.push(m);
        } else if (m.toBeDeleted) {
          mediaToBeDeleted.push(m);
        }
      } else if (m.file) {
        mediaToBeUploaded.push(m); // image
      } else if (m.type === 'video') {
        mediaToBeUploaded.push(m); // video
      }
    });
    const uploadedImagesAndVideos = mediaToBeUploaded.length
      ? await uploadMediaImagesAndVideos(dispatch, mediaToBeUploaded)
      : [];

    if (mediaToBeUpdated.length) {
      await updateMedia(dispatch, mediaToBeUpdated);
    }

    if (mediaToBeDeleted.length) {
      await deleteMedia(mediaToBeDeleted);
    }

    const updatedList = [];
    let index = 0;
    values.media.forEach((m) => {
      if (m.id) {
        if (!m.toBeDeleted) {
          updatedList.push(m.id);
        }
      } else {
        updatedList.push(uploadedImagesAndVideos[index++].id);
      }
    });
    restValues.media = updatedList;
  }

  let defaultImageId = null;
  if (values.defaultImage) {
    if (values.defaultImage.image?.id) {
      defaultImageId = values.defaultImage.image.id;
    } else if (values.defaultImage.id) {
      defaultImageId = values.defaultImage.id;
    } else if (values.defaultImage.file) {
      const uploadedImage = await dispatch(
        uploadImageToService(values.defaultImage.file),
      );
      defaultImageId = uploadedImage?.id;
    }
  }

  if (values.deletedArrivalImageId) {
    await softDeleteImage(values.deletedArrivalImageId);
  }

  if (values.deletedDefaultImageId) {
    await softDeleteImage(values.deletedDefaultImageId);
  }

  let arrivalImageId = null;
  if (values.arrivalImage) {
    if (values.arrivalImage.id) {
      arrivalImageId = values.arrivalImage.id;
    } else if (values.arrivalImage.file) {
      const uploadedImage = await dispatch(
        uploadImageToService(values.arrivalImage.file),
      );
      arrivalImageId = uploadedImage?.id;
    }
  }

  restValues.categories = values.categories?.map((cat) => cat.id) || [];
  restValues.excludeCategoryTags = values.excludeCategoryTags || [];

  if (values.tags.en.length > 0) {
    restValues.tags = values.tags.en.map((tag) => ({
      name: [{ lang: LANGUAGE_CODES.ENGLISH, label: tag }],
    }));
  } else {
    restValues.tags = [];
  }

  if (values.langES) {
    restValues.tags = [
      ...restValues.tags,
      ...values.tags.es.map((tag) => ({
        name: [{ lang: LANGUAGE_CODES.SPANISH, label: tag }],
      })),
    ];
  }

  restValues.actionLinks = await prepareActionLinks(values.actionLinks);

  // extra
  if (values.isMapped) {
    restValues.navigation = {
      reroute: {
        directive: values.extraDirective,
        message: formatLang('extraMessage', values),
        image: values.extraImage
          ? await persistExtraImage(values.extraImage)
          : null,
        destination: values.extraDestination,
      },
      disclaimer: formatLang('extraDisclaimer', values),
    };
  } else {
    restValues.navigation = {};
  }
  // Arrival Image
  restValues.navigation.arrivalImage = arrivalImageId;

  const submitVal = {
    // MAPPING INPUTS
    isParkingArea: values.isParkingArea,

    // POI DETAILS
    name: formatLang('name', values),
    shortName: formatLang('shortName', values),
    description: formatLang('description', values),

    // POI ADDRESS
    inheritAddress: values.inheritAddress !== 'override',
    metadata: {
      ui: {
        autoPopulateCoordinatesFromAddress: values.autoPopulateCoords,
      },
    },

    geoLocation: {
      ...{
        markerPoint: {
          lat: values.displayLatitude ? Number(values.displayLatitude) : null,
          lng: values.displayLongitude ? Number(values.displayLongitude) : null,
        },
      },
      // Disabled per SCM-2899 / MP-1332 since not yet supported in mobile app
      // ...!values.isMapped && {
      // 	navigationPoint: {
      // 		lat: values.destinationLatitude ? Number(values.destinationLatitude) : null,
      // 		lng: values.destinationLongitude ? Number(values.destinationLongitude) : null,
      // 	},
      // },
      ...geolocationValues,
    },
    ...!!values.departmentIds && {
      departmentIds: values.departmentIds,
    },
    ...!!values.hoursType && {
      contact: {
        hours: {
          type: values.hoursType,
          custom:
            values.customHours && values.customHours.length
              ? values.customHours.map(
                ({ __typename, day, status, hours }) => ({
                  day,
                  status,
                  hours: hours.map(({ __typename, ...rest }) => rest),
                }),
              )
              : [],
        },
        ...!!values.exceptionHours && {
          exceptionHours: values.exceptionHours,
        },
      },
    },
    matching: {
      enabled: values.providerMatchingEnabled || false,
      rule:
        values.providerMatchingRule && values.providerMatchingRule !== ''
          ? JSON.parse(values.providerMatchingRule)
          : {},
    },
    defaultImage: defaultImageId,
    searchability: values.searchability,
    visibility: values.visibility,
    priority: values.priority,
    ...restValues,
  };
  return submitVal;
};
