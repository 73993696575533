import { WORKSPACE_STORAGE_KEY } from 'helpers/workspace-util';
import LogRocket from 'logrocket';

export const httpRequest = async ({
                                    url,
                                    method = 'GET',
                                    headers = {},
                                    body,
                                    options = {},
                                    logout,
                                  }) => {
  const { triggerDownload, fileName } = options;
  if (
    body
    && typeof body === 'object'
    && headers['Content-Type'] === 'application/json'
  ) {
    body = JSON.stringify(body);
  }
  try {
    const request = new Request(url, {
      method,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        workspace: window.localStorage.getItem(WORKSPACE_STORAGE_KEY),
      },
      body,
    });
    const response = await fetch(request);
    if (response?.status === 401) {
      if (typeof logout === 'function') {
        logout();
      }
      return response;
    }
    const responseHeaders
      = [...response.headers].reduce(
        (a, b) => ({
          ...a,
          [b[0]]: b[1],
        }),
        {},
      ) || {};
    if (
      responseHeaders['content-disposition']?.split(';')[0] === 'attachment'
      || triggerDownload
    ) {
      const data = await response.arrayBuffer();
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download
        = fileName
        || responseHeaders['content-disposition']?.split(';')[1]?.split('=')[1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return response;
  } catch (err) {
    LogRocket.captureException(err);
    throw err;
  }
};
