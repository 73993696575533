import { getExternalAppFormValidationSchema, getWebsiteValidationSchema } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import { PhoneRegexp } from 'helpers/string-util';
import { makeValidate } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import * as Yup from 'yup';

export const BANNER_ERROR_FORM_FIELD_MAP = new Map([
  ['backgroundColor', { label: 'Background Color', section: 'Color options' }],
  ['uploadedIconUrl', { label: 'Background Image', section: 'Color options' }],

  ['titleEN', { label: 'Title', section: 'Text options' }],
  ['titleES', { label: 'Title', section: 'Text options' }],
  ['subtitleES', { label: 'Subtitle', section: 'Text options' }],
  ['buttonES', { label: 'Background Color', section: 'Text options' }],

  ['linkType', { label: 'Quicklink Type', section: 'Quicklink' }],
  ['categories', { label: 'Categories', section: 'Selected Categories' }],
  ['inAppDest', { label: 'In-App Destination', section: 'In-App Details' }],
  ['listDest', { label: 'List', section: 'List Details' }],

  ['externaliOSURL', { label: 'iOS URL', section: 'External App Details' }],
  [
    'externalAndroidURL',
    { label: 'Android URL', section: 'External App Details' },
  ],
  [
    'fallbackiOSURL',
    { label: 'Fallback iOS URL', section: 'External App Details' },
  ],
  [
    'fallbackAndroidURL',
    { label: 'Fallback Android URL', section: 'External App Details' },
  ],
  ['externalURL', { label: 'Fallback URL', section: 'External App Details' }],
  [
    'externalType',
    { label: 'External App Preference', section: 'External App Preferences' },
  ],
  ['webUrlEn', { label: 'Website URL', section: 'Website Details' }],
  ['webUrlEs', { label: 'Website URL (Spanish)', section: 'Website Details' }],
  ['webType', { label: 'Website Preference', section: 'Website Preferences' }],
  ['phone', { label: 'Phone Number', section: 'Phone' }],
  [
    'custom',
    { label: 'Open Field', section: 'Custom Gozio Action Type Details' },
  ],
]);

export const EMPTY_BANNER = {
  backgroundType: 'color',
  backgroundColor: `${ColorPalette.grey[100]}FF`,
  theme: 'light',
  titleEN: '',
  titleES: '',
  subtitleEN: '',
  subtitleES: '',
  buttonEN: '',
  buttonES: '',
  linkType: '',
};

export const getBannerValidator = (languages) => makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          langES: Yup.boolean(),
          theme: Yup.string().matches(/(light|dark)/),
          backgroundColor: Yup.string().when('backgroundType', {
            is: 'color',
            then: (schema) => schema.matches(/^#[a-f0-9]{8}$/i, {
                message:
                  'Background Color must be valid hex color with alpha transparency',
              }),
          }),
          titleEN: Yup.string(),
          titleES: Yup.string(),
          subtitleEN: Yup.string(),
          subtitleES: Yup.string(),
          buttonEN: Yup.string(),
          buttonES: Yup.string(),
          uploadedIconUrl: Yup.mixed().nullable(),
          // Link Destination Validation
          linkType: Yup.string(),
          // Category
          categories: Yup.array().when('linkType', {
            is: 'category',
            then: (schema) => schema.min(1).required('Selection is required'),
          }),
          // in_app
          inAppDest: Yup.string().when('linkType', {
            is: 'in_app',
            then: (schema) => schema.required('Selection is required'),
          }),
          // List
          listDest: Yup.string().when('linkType', {
            is: 'list',
            then: (schema) => schema.required('Selection is required'),
          }),
          // External App
          ...getExternalAppFormValidationSchema,
          //Website
          ...getWebsiteValidationSchema,
          // Phone
          phone: Yup.string().when(['linkType'], {
            is: 'phone',
            then: (schema) => schema
                .required('Phone Number is required')
                .matches(PhoneRegexp, 'Invalid phone number'),
          }),
          // Custom
          custom: Yup.string().when('linkType', {
            is: 'custom',
            then: (schema) => schema.required('Custom value is required'),
          }),
        },
        languages,
        {
          titleEN: LANGUAGE_CODES.ENGLISH,
          titleES: LANGUAGE_CODES.SPANISH,
          subtitleEN: LANGUAGE_CODES.ENGLISH,
          subtitleES: LANGUAGE_CODES.SPANISH,
          buttonEN: LANGUAGE_CODES.ENGLISH,
          buttonES: LANGUAGE_CODES.SPANISH,
          webUrlEn: LANGUAGE_CODES.ENGLISH,
          webUrlEs: LANGUAGE_CODES.SPANISH,
        },
      ),
    ),
  );

export const getBannerDisabledStateForDeletion = (values) => {
  if (!values) {
    return true;
  }

  for (const field of Object.keys(EMPTY_BANNER)) {
    if (EMPTY_BANNER[field] !== values[field]) {
      return false;
    }
  }

  return true;
};

const REQUIRED_BANNER_FIELDS = {
  title: { i18n: true, required: false },
  subtitle: { i18n: true, required: false },
  button: { i18n: true, required: false },
  linkType: { i18n: false, required: true },
};

export const getBannerDisabledStateForPublishing = (values) => {
  if (!values || Object.keys(values).length === 0) {
    return true;
  }

  const isInvalid = Object.keys(REQUIRED_BANNER_FIELDS).find((field) => {
    const { i18n, required } = REQUIRED_BANNER_FIELDS[field];
    if (i18n) {
      if (values[`${field}EN`]) {
        if (values.langES && !values[`${field}ES`]) {
          return true;
        }
      } else if (required) {
        return true;
      }
      return false;
    }

    return required && !values[field];
  });
  if (isInvalid) {
    return true;
  }

  if (values.backgroundType === 'color' && !values.backgroundColor) {
    return true;
  }

  if (values.backgroundType === 'image' && !values.uploadedIconUrl) {
    return true;
  }

  return false;
};
