import { Delete } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '209px',
    padding: '12px',
    position: 'relative',
    marginBottom: '20px',

    '&:hover': {
      background: theme.palette.blue[50],
      border: `2px solid ${theme.palette.blue[400]}`,
      padding: '10px',
      borderRadius: '8px',
      '& p': {
        fontWeight: 'bold',
      },

      '& $actionBar': {
        visibility: 'visible',
      },
    },
  },
  actionBar: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '2px',
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[350], 0.4)}`,
    cursor: 'pointer',
    height: '48px',
    left: '76px',
    padding: '6px 12px',
    position: 'absolute',
    top: '8px',
    visibility: 'hidden',
    width: '52px',
  },
  actionIcon: {
    height: '24px',
    margin: '6px 0',
    width: '24px',
  },
}));

const SupportingImage = ({ image, onImageDeleted }) => {
  const classes = useStyles();
  const imageUrl = image.url;
  const imageName = image?.name || image?.file?.name;

  const handleDelete = () => {
    onImageDeleted(image);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          marginBottom: '16px',
          fontWeight: 400,
          color: ColorPalette.grey[600],
        }}
      >
        Supporting Image
      </Typography>
      {imageUrl && (
        <Box
          className={classes.imageContainer}
          onClick={(e) => e.preventDefault()}
        >
          <Box className={classes.actionBar}>
            <IconButton
              className={classes.actionIcon}
              onClick={handleDelete}
              onMouseDown={handleDelete}
              aria-label="Delete Image"
              size="large">
              <Delete className={classes.actionIcon} />
            </IconButton>
          </Box>
          <img
            src={imageUrl}
            style={{ width: '100%', borderRadius: '8px' }}
            alt={imageName}
          />
          <Typography
            variant="body2"
            sx={{
              marginTop: '11px',
              marginBottom: '12px',
              fontWeight: 400,
              color: ColorPalette.grey[600],
            }}
          >
            {imageName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

SupportingImage.propTypes = {
  onImageDeleted: PropTypes.func,
};

SupportingImage.defaultProps = {
  onImageDeleted: () => {},
};

export default React.memo(SupportingImage);
