import dayjs from 'dayjs';
import { httpRequest } from 'helpers/http-util';
const ANALYTICS_FILTER_NAME = 'AnalyticsFilter';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const ANALYTICS_FILTER_RANGE_START = 'AnalyticsFilter-rangeStart';
export const ANALYTICS_FILTER_RANGE_END = 'AnalyticsFilter-rangeEnd';
export const DEFAULT_ANALYTICS_FILTER = 'last_30_days';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const today = dayjs().format(ISO_DATE_FORMAT);

export const DEFAULT_RANGE = {
  start: dayjs(today).subtract(30, 'days').format(ISO_DATE_FORMAT),
  end: dayjs(today).subtract(1, 'day').format(ISO_DATE_FORMAT),
};

export const FILE_NAMES = {
  activeusers: 'total_users',
  sessions: 'sessions',
  newusers: 'mobile_installations',
  navigationevents: 'navigation_events',
};

export const downloadAnalyticsFile = async ({
  dataType,
  endDate,
  endpoint,
  fileNameDetail,
  networkId,
  networkName,
  onDownloadEnd,
  onDownloadStart,
  onLogout,
  startDate,
}) => {
  if (onDownloadStart) onDownloadStart();
  await httpRequest({
    options: {
      triggerDownload: true,
      fileName: `${networkName}_${fileNameDetail || FILE_NAMES[endpoint]}_${
        new Date().toISOString().split('T')[0]
      }.xlsx`,
    },
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    url: `${
      process.env.REACT_APP_ANALYTICS_URL
    }/${endpoint}?networkId=${networkId}${startDate ? `&start=${startDate}` : ''}${
      endDate ? `&end=${endDate}${dataType ? `&dataType=${dataType}` : ''}` : ''
    }&download=true`,
    logout: onLogout,
  });
  if (onDownloadEnd) onDownloadEnd();
};

export const retrieveAnalyticsFilter = (
  defaultFilter = DEFAULT_ANALYTICS_FILTER,
  defaultRangeStart = DEFAULT_RANGE.start,
  defaultRangeEnd = DEFAULT_RANGE.end,
) => ({
  filter: sessionStorage.getItem(ANALYTICS_FILTER_NAME) || defaultFilter,
  rangeStart: sessionStorage.getItem(ANALYTICS_FILTER_RANGE_START) || defaultRangeStart,
  rangeEnd: sessionStorage.getItem(ANALYTICS_FILTER_RANGE_END) || defaultRangeEnd,
});

export const persistAnalyticsFilter = (
  filter,
  rangeStart = null,
  rangeEnd = null,
) => {
  sessionStorage.setItem(ANALYTICS_FILTER_NAME, filter);
  sessionStorage.setItem(ANALYTICS_FILTER_RANGE_START, rangeStart);
  sessionStorage.setItem(ANALYTICS_FILTER_RANGE_END, rangeEnd);
};
