import A2cCardLayout from 'components/dashboard/cardLayouts/a2cCardLayout';
import FindingCareCardLayout from 'components/dashboard/cardLayouts/findingCareCardLayout';
import FourCardLayout from 'components/dashboard/cardLayouts/fourCardLayout';
import MyChartCardLayout from 'components/dashboard/cardLayouts/myChartCardLayout';
import MyFavoritesCardLayout from 'components/dashboard/cardLayouts/myFavoritesCardLayout';
import OneCardLayout from 'components/dashboard/cardLayouts/oneCardLayout';
import OpenPlatformCardLayout from 'components/dashboard/cardLayouts/openPlatformCardLayout';
import QuicklinkListCardLayout from 'components/dashboard/cardLayouts/quicklinkListCardLayout';
import ThreeCardLayout from 'components/dashboard/cardLayouts/threeCardLayout';
import TwoCardLayout from 'components/dashboard/cardLayouts/twoCardLayout';
import {
  MY_FAVORITES_BUTTON_TEXT,
  MY_FAVORITES_DISPLAY_TYPES,
  MY_FAVORITES_VISIBILITY_RULES,
} from 'components/dashboard/myFavoritesHelper';
import { ItemTypes } from 'helpers/dnd-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import _ from 'lodash';
import ColorPalette from 'pages/gozio_colors';

// Spec: https://goziohealth.atlassian.net/wiki/spaces/EN/pages/626851863/Dashboard+Cards
export const DEFAULT_BACKGROUND_COLOR = ColorPalette.grey[500];
export const DEFAULT_TEXT_COLOR = ColorPalette.white;

export const CARD_GROUP_TYPES = Object.freeze({
  CARE_NEAR_ME: 'careNearMe',
  EXPANDABLE: 'expandable',
  FINDING_CARE: 'findingCare',
  FOUR: 'four',
  MYCHART: 'myChart',
  MY_FAVORITES: 'myFavorites',
  ONE: 'one',
  OPEN_PLATFORM: 'openPlatform',
  QUICKLINK_LIST: 'linkListUnlimited',
  TEMPORARY: 'temporary',
  THREE: 'three',
  TWO: 'two',
});

export const A2C_DISPLAY_TYPES = Object.freeze({
  MAP: 'map',
  IMAGE: 'image',
  MAP_AND_IMAGE: 'mapAndImage',
});

export const AVAILABLE_CARD_GROUPS = [
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Quicklink List card',
    cardType: QuicklinkListCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        cardType: 'quickLinks',
        linkBackgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card Title' }],
      },
    },
    feature: CARD_GROUP_TYPES.QUICKLINK_LIST,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Single card',
    cardType: OneCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Finding Care card',
    cardType: FindingCareCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        cardType: 'quickLinks',
        linkBackgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card Title' }],
      },
    },
    feature: CARD_GROUP_TYPES.FINDING_CARE,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Care Near Me card',
    cardType: A2cCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        buttonText: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Button Text' }],
        cardType: 'quickLinks',
        displayType: A2C_DISPLAY_TYPES.MAP,
        subtitle: [],
        subtitle2: [],
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card Title' }],
      },
    },
    feature: CARD_GROUP_TYPES.CARE_NEAR_ME,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Favorites card',
    cardType: MyFavoritesCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        cardType: 'empty',
        displayType: MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card Title' }],
        subtitle: [
          { lang: LANGUAGE_CODES.ENGLISH, label: 'Favorites card subtext.' },
        ],
        buttonText: [
          {
            lang: LANGUAGE_CODES.ENGLISH,
            label: 'Button Text',
          },
        ],
        visibilityRule: MY_FAVORITES_VISIBILITY_RULES.EMPTY_LOCATIONS,
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        cardType: 'active',
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card Title' }],
        visibilityRule: MY_FAVORITES_VISIBILITY_RULES.ACTIVE_LOCATIONS,
      },
      isActiveState: false,
    },
    feature: CARD_GROUP_TYPES.MY_FAVORITES,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Expandable card',
    cardType: OneCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
        isExpandable: true,
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
      card3: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
      card4: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Open Platform',
    cardType: OpenPlatformCardLayout,
    cardProps: {
      card1: {
        cardName: 'Card Title',
      },
    },
    feature: CARD_GROUP_TYPES.OPEN_PLATFORM,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Expandable card with wait times',
    cardType: OneCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        isExpandable: true,
        waitTimes: {
          included: true,
          label: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'WAIT TIME' }],
          showWhenCollapsed: true,
        },
        iconText: 'XX miles',
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
      card3: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
      card4: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        iconText: 'XX miles',
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'Temporary card',
    cardType: OneCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
        dismissable: true,
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: 'MyChart cards',
    cardType: MyChartCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        cardType: 'login',
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' }],
      },
      card2: {
        cardType: 'quickLinks',
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'QuickLinks Card' }],
      },
    },
    feature: CARD_GROUP_TYPES.MYCHART,
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: '2-card layout',
    cardType: TwoCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 1' }],
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 2' }],
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: '3-card layout',
    cardType: ThreeCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 1' }],
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 2' }],
      },
      card3: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 3' }],
      },
    },
  },
  {
    type: ItemTypes.DASHBOARD_CARD,
    label: '4-card layout',
    cardType: FourCardLayout,
    cardProps: {
      size: 'short',
      card1: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 1' }],
      },
      card2: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 2' }],
      },
      card3: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 3' }],
      },
      card4: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        textColor: DEFAULT_TEXT_COLOR,
        title: [{ lang: LANGUAGE_CODES.ENGLISH, label: 'Card title 4' }],
      },
    },
  },
];

export const dndHover = (item, monitor, ref, index, moveCard) => {
  if (!ref.current) {
    return;
  }

  const dragIndex = item.index;
  const hoverIndex = index;
  if (dragIndex === hoverIndex) {
    return;
  }

  const hoverBoundingRect = ref.current.getBoundingClientRect();
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return;
  }

  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return;
  }

  moveCard(dragIndex, hoverIndex, item);
  item.index = hoverIndex;
};

export const getLanguageLabel = (
  multiLangList,
  lang = LANGUAGE_CODES.ENGLISH
) => {
  if (typeof multiLangList === 'string') {
    return lang === LANGUAGE_CODES.ENGLISH ? multiLangList : null;
  }

  if (multiLangList) {
    const langObj = multiLangList.find((t) => t.lang === lang);
    if (langObj) {
      return langObj.label;
    }
  }

  return null;
};

export const isCardIncompleteForPackaging = (card) => card.validation?.status === 'incomplete';

export const extractPropertiesForRendering = (
  cardProps,
  selectedCard,
  editPanelTabValue,
  cardGroupType
) => {
  const props = _.cloneDeep(cardProps);
  if (props.card1.measurements?.height) {
    props.size = props.card1.measurements.height;
  }

  if (props.card1.waitTimes?.label) {
    props.card1.waitTimeLabel = getLanguageLabel(props.card1.waitTimes.label);
  }

  if (
    selectedCard?.cardGroupType === CARD_GROUP_TYPES.MYCHART
    || selectedCard?.cardGroupType === CARD_GROUP_TYPES.QUICKLINK_LIST
  ) {
    props.showQuickLinks = editPanelTabValue === 1;
  } else if (selectedCard?.cardGroupType === CARD_GROUP_TYPES.MY_FAVORITES) {
    props.isActiveState = editPanelTabValue === 1;
  }

  ['card1', 'card2', 'card3', 'card4'].forEach((cardKey) => {
    if (props[cardKey]) {
      ['title', 'overline', 'buttonText'].forEach((fieldName) => {
        if (props[cardKey][fieldName]) {
          props[cardKey][fieldName] = getLanguageLabel(
            props[cardKey][fieldName]
          );
        }
      });
      if (cardGroupType !== CARD_GROUP_TYPES.CARE_NEAR_ME) {
        if (props[cardKey].subtitle) {
          props[cardKey].subtitle = getLanguageLabel(props[cardKey].subtitle);
        }
      }
      props[cardKey].incompleteForPackaging = isCardIncompleteForPackaging(
        props[cardKey]
      );
      if (selectedCard) {
        if (props[cardKey].id === selectedCard.cardId) {
          props[cardKey].selected = true;
        } else {
          props[cardKey].disabled = true;
        }
      }
    }
  });
  return props;
};

export const convertCardGroup = (inputCardGroup) => {
  const cardGroup = _.cloneDeep(inputCardGroup);
  let cardType;
  let size = 'short';
  switch (cardGroup.type) {
    case CARD_GROUP_TYPES.QUICKLINK_LIST:
      cardType = QuicklinkListCardLayout;
      break;
    case CARD_GROUP_TYPES.ONE:
      cardType = OneCardLayout;
      break;
    case CARD_GROUP_TYPES.FINDING_CARE:
      cardType = FindingCareCardLayout;
      break;
    case CARD_GROUP_TYPES.CARE_NEAR_ME:
      cardType = A2cCardLayout;
      break;
    case CARD_GROUP_TYPES.MY_FAVORITES:
      cardType = MyFavoritesCardLayout;
      break;
    case CARD_GROUP_TYPES.OPEN_PLATFORM:
      cardType = OpenPlatformCardLayout;
      break;
    case CARD_GROUP_TYPES.TEMPORARY:
      cardType = OneCardLayout;
      cardGroup.cards[0] = Object.assign(cardGroup.cards[0], {
        dismissable: cardGroup.cards[0].dismissable,
        expires: cardGroup.cards[0].expires,
      });
      size = cardGroup.cards[0].measurements?.height || 'short';
      break;
    case CARD_GROUP_TYPES.EXPANDABLE:
      cardType = OneCardLayout;
      cardGroup.cards[0] = Object.assign(cardGroup.cards[0], {
        isExpandable: true,
      });
      break;
    case CARD_GROUP_TYPES.TWO:
      cardType = TwoCardLayout;
      break;
    case CARD_GROUP_TYPES.THREE:
      cardType = ThreeCardLayout;
      break;
    case CARD_GROUP_TYPES.FOUR:
      cardType = FourCardLayout;
      break;
    case CARD_GROUP_TYPES.MYCHART:
      cardType = MyChartCardLayout;
      break;
    default:
      break;
  }
  const cardProps = {};
  cardGroup.cards.forEach((card, i) => {
    const key = `card${i + 1}`;
    cardProps[key] = card;
  });

  return {
    id: cardGroup.id,
    type: cardGroup.type,
    cardType,
    cardProps: { size, ...cardProps },
  };
};

export const PLACEHOLDER_TITLE_MAP = {
  en: { lang: LANGUAGE_CODES.ENGLISH, label: 'Card title' },
  es: { lang: LANGUAGE_CODES.SPANISH, label: 'Titulo' },
};

export const PLACEHOLDER_BUTTON_TEXT_MAP = {
  en: { lang: LANGUAGE_CODES.ENGLISH, label: 'Button Text' },
  es: { lang: LANGUAGE_CODES.SPANISH, label: 'Texto del botón' },
};

export const PLACEHOLDER_CUSTOM_LOGIC_TITLE_MAP = {
  en: { lang: LANGUAGE_CODES.ENGLISH, label: 'Recommended' },
  es: { lang: LANGUAGE_CODES.SPANISH, label: 'Recomendado' },
};

export const PLACEHOLDER_WAIT_TIME_LABEL_MAP = {
  en: { lang: LANGUAGE_CODES.ENGLISH, label: 'WAIT TIME' },
  es: { lang: LANGUAGE_CODES.SPANISH, label: 'ESPERA' },
};

const mapCardProperties = (
  card = {},
  languages = [],
  isTemporary = false,
  cardGroupType = null
) => {
  const {
    measurements,
    // eslint-disable-next-line no-unused-vars
    title,
    // eslint-disable-next-line no-unused-vars
    waitTimeLabel,
    // eslint-disable-next-line no-unused-vars
    waitTimeInMinutes,
    // eslint-disable-next-line no-unused-vars
    isTemporary: ignore1,
    // eslint-disable-next-line no-unused-vars
    isExpandable: ignore2,
    // eslint-disable-next-line no-unused-vars
    incompleteForPackaging: ignore3,
    // eslint-disable-next-line no-unused-vars
    linkBackgroundColor,
    ...cardProps
  } = card;

  const i18nTitle = languages
    .map((lang) => PLACEHOLDER_TITLE_MAP[lang])
    .filter((t) => t);
  const i18nCustomLogicTitle = languages
    .map((lang) => PLACEHOLDER_CUSTOM_LOGIC_TITLE_MAP[lang])
    .filter((t) => t);
  const i18nButtonText = languages
    .map((lang) => PLACEHOLDER_BUTTON_TEXT_MAP[lang])
    .filter((t) => t);
  const result = {
    ...cardProps,
    title: i18nTitle,
    ...[CARD_GROUP_TYPES.CARE_NEAR_ME].includes(cardGroupType) && {
      sortingOptions: {
        title: i18nCustomLogicTitle,
        type: 'distance',
      },
    },
    ...cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME && {
      buttonText: i18nButtonText,
    },
  };

  if (card.waitTimes?.label) {
    result.waitTimes.label = languages
      .map((lang) => PLACEHOLDER_WAIT_TIME_LABEL_MAP[lang])
      .filter((l) => l);
  }

  if (measurements?.height) {
    result.size = measurements.height;
  }

  if (isTemporary) {
    result.dismissable = true;
  }

  return result;
};

export const generateNewCardGroupInput = (
  dashboardId,
  cardGroup,
  arrayPosition,
  languages = []
) => {
  let type;
  let cards;
  const card1 = _.get(cardGroup, 'cardProps.card1');
  const card2 = _.get(cardGroup, 'cardProps.card2');
  const card3 = _.get(cardGroup, 'cardProps.card3');
  const card4 = _.get(cardGroup, 'cardProps.card4');
  switch (cardGroup.cardType) {
    case QuicklinkListCardLayout:
      type = CARD_GROUP_TYPES.QUICKLINK_LIST;
      cards = [mapCardProperties(card1, languages)];
      break;
    case OneCardLayout:
      if (card1?.isExpandable) {
        type = CARD_GROUP_TYPES.EXPANDABLE;
        cards = [mapCardProperties(card1, languages, false)];
      } else if (card1?.dismissable || !!card1?.expires) {
        type = CARD_GROUP_TYPES.TEMPORARY;
        cards = [mapCardProperties(card1, languages, true)];
      } else {
        type = CARD_GROUP_TYPES.ONE;
        cards = [mapCardProperties(card1, languages)];
      }
      break;
    case FindingCareCardLayout:
      type = CARD_GROUP_TYPES.FINDING_CARE;
      cards = [mapCardProperties(card1, languages, false, type)];
      break;
    case A2cCardLayout:
      type = CARD_GROUP_TYPES.CARE_NEAR_ME;
      cards = [mapCardProperties(card1, languages, false, type)];
      break;
    case MyFavoritesCardLayout:
      type = CARD_GROUP_TYPES.MY_FAVORITES;
      cards = [
        mapCardProperties(card1, languages),
        mapCardProperties(card2, languages),
      ];
      cards[0].buttonText = [
        {
          lang: LANGUAGE_CODES.ENGLISH,
          label: MY_FAVORITES_BUTTON_TEXT.LOCATIONS,
        },
      ];
      cards[0].subtitle = [
        { lang: LANGUAGE_CODES.ENGLISH, label: cards[0].subtitle },
      ];
      break;
    case OpenPlatformCardLayout:
      type = CARD_GROUP_TYPES.OPEN_PLATFORM;
      // eslint-disable-next-line no-unused-vars
      const { incompleteForPackaging, ...rest } = card1;
      cards = [{ ...rest }];
      break;
    case TwoCardLayout:
      type = CARD_GROUP_TYPES.TWO;
      cards = [
        mapCardProperties(card1, languages),
        mapCardProperties(card2, languages),
      ];
      break;
    case ThreeCardLayout:
      type = CARD_GROUP_TYPES.THREE;
      cards = [
        mapCardProperties(card1, languages),
        mapCardProperties(card2, languages),
        mapCardProperties(card3, languages),
      ];
      break;
    case FourCardLayout:
      type = CARD_GROUP_TYPES.FOUR;
      cards = [
        mapCardProperties(card1, languages),
        mapCardProperties(card2, languages),
        mapCardProperties(card3, languages),
        mapCardProperties(card4, languages),
      ];
      break;
    case MyChartCardLayout:
      type = CARD_GROUP_TYPES.MYCHART;
      cards = [
        mapCardProperties(card1, languages),
        mapCardProperties(card2, languages),
      ];
      break;
    default:
      throw new Error(`Invalid card type: ${cardGroup.cardType}`);
  }

  return {
    dashboardId,
    type,
    arrayPosition,
    cards,
  };
};

export const buildI18NValues = (
  i18nFieldList = [],
  languages = [],
  values = {},
  langKeyPrefix = null
) => {
  const results = {};
  i18nFieldList.forEach(({ key, lang, langKey }) => {
    const customLangKey = langKeyPrefix
      ? `${langKeyPrefix}${langKey}`
      : langKey;
    if (!results.hasOwnProperty(key)) {
      results[key] = [];
    }
    if (languages.includes(lang)) {
      results[key].push({
        lang,
        label: values[customLangKey] || '',
      });
    }
  });
  return results;
};
