import { Flag, Image, Settings, VisibilityOff } from '@mui/icons-material';
import { Box, Grid, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colorWithAlpha } from 'helpers/color-util';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  flagIcon: {
    cursor: 'pointer',
    marginRight: '12px',
  },
  icons: {
    color: theme.palette.grey[500],
    marginLeft: '12px',
    marginRight: '12px',
    marginTop: '12px',
  },
  personaIcon: {
    color: theme.palette.grey[500],
    height: '64px',
    margin: '16px',
    width: '64px',
  },
  personaIconContainer: {
    background: 'transparent',
    borderRadius: '50%',
    boxShadow: `0 1px 2px 0px ${colorWithAlpha(theme.palette.blue[600], 0.25)}`,
    height: '96px',
    minWidth: '96px',
    width: '96px',
  },
  personaIconTypography: {
    height: theme.spacing(8),
  },
  root: {
    background: `linear-gradient(to bottom right, ${theme.palette.gradient.white.start}, ${theme.palette.gradient.white.end})`,
    borderColor: theme.palette.grey[300],
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: `0px 2px 4px 0px ${theme.palette.grey[350]}`,
    height: '304px',
    width: '266px',
  },
  selectedRoot: {
    background: theme.palette.blue[50],
    border: `2px solid ${theme.palette.blue[400]}`,
    borderRadius: '8px',
    height: '304px',
    width: '266px',
  },
  settings: {
    cursor: 'pointer',
    float: 'right',
    '&:hover': {
      color: theme.palette.grey[600],
    },
  },
  visibilityOff: {
    cursor: 'pointer',
    marginRight: '12px',
  },
  bottomLabel: {
    padding: theme.spacing(1),
  },
}));

const PersonaCard = ({
                       name,
                       iconUrl,
                       bottomLabel,
                       options,
                       handleEditPersona,
                       handleApplyToAllContent,
                       handlePublishingStatus,
                       handleDeletePersona,
                       deleteVisible,
                       editVisible,
                     }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, fn) => {
    handleClose();
    fn(e);
  };

  const rootClassname = options.isSelected
    ? classes.selectedRoot
    : classes.root;
  return (
    <Grid container className={rootClassname}>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        className={classes.icons}
      >
        <Grid item>
          {options.isDefault && (
            <Tooltip title="Default persona" placement="right">
              <Flag className={classes.flagIcon} />
            </Tooltip>
          )}
          {!options.showInPublishing && (
            <Tooltip title="Hidden from publishing" placement="right">
              <VisibilityOff className={classes.visibilityOff} />
            </Tooltip>
          )}
        </Grid>
        {editVisible && (
          <Grid item>
            <Settings
              className={classes.settings}
              sx={anchorEl ? { color: ColorPalette.grey[600] } : {}}
              onClick={handleClick}
              data-test="personaCardSettings"
            />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        sx={{ height: '96px' }}
      >
        {iconUrl && (
          <Box className={classes.personaIconContainer}>
            <img src={iconUrl} alt="Icon" className={classes.personaIcon} />
          </Box>
        )}
        {!iconUrl && (
          <Box className={classes.personaIconContainer}>
            <Image className={classes.personaIcon} />
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
          className={classes.personaIconTypography}
          data-test="personaCardName"
        >
          {name}
        </Typography>
      </Grid>

      {bottomLabel && (
        <Grid item xs={12} className={classes.bottomLabel}>
          {bottomLabel}
        </Grid>
      )}

      <Menu
        id="settingsMenu"
        anchorEl={anchorEl}
        keepMounted
        sx={{ top: '32px', left: '-130px' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-test="personaCardMenu"
      >
        <MenuItem
          onClick={(e) => handleMenuItemClick(e, handleEditPersona)}
          data-test="personaCardEditPersonaMenuItem"
        >
          Edit persona
        </MenuItem>
        <MenuItem
          onClick={(e) => handleMenuItemClick(e, handleApplyToAllContent)}
          data-test="personaCardApplyToAllContentMenuItem"
        >
          Apply to all content
        </MenuItem>
        {!options.isDefault && (
          <MenuItem
            onClick={(e) => handleMenuItemClick(e, handlePublishingStatus)}
            data-test="personaCardReadyForPublishingMenuItem"
          >
            {options.showInPublishing
              ? 'Hide from publishing'
              : 'Ready for publishing'}
          </MenuItem>
        )}
        {!options.isDefault && deleteVisible && (
          <MenuItem
            onClick={(e) => handleMenuItemClick(e, handleDeletePersona)}
            data-test="personaCardDeletePersonaMenuItem"
          >
            Delete persona
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};

PersonaCard.propTypes = {
  bottomLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deleteVisible: PropTypes.bool,
  editVisible: PropTypes.bool,
  handleApplyToAllContent: PropTypes.func.isRequired,
  handleDeletePersona: PropTypes.func.isRequired,
  handleEditPersona: PropTypes.func.isRequired,
  handlePublishingStatus: PropTypes.func.isRequired,
  iconUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.shape({
    isSelected: PropTypes.bool,
    isDefault: PropTypes.bool,
    showInPublishing: PropTypes.bool,
  }),
};

PersonaCard.defaultProps = {
  deleteVisible: true,
  editVisible: true,
  iconUrl: null,
  options: {
    isDefault: false,
    showInPublishing: false,
  },
};

export default PersonaCard;
