import { Box, Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import Condition from 'components/forms/condition';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import TabPanel from 'components/tabPanel/tabPanel';
import { getFormLabel } from 'helpers/form-util';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const MINIMUM_IMAGE_WIDTH = 1500;
const MINIMUM_IMAGE_HEIGHT = 1665;

export const AboutUsPageTabPanel = ({
                                      classes,
                                      index,
                                      isUberAdmin,
                                      onImageEnUploaded,
                                      onImageEsUploaded,
                                      tabValue,
                                    }) => (
  <TabPanel
    id="tabPanel-aboutUs"
    className={classes.tabPanel}
    value={tabValue}
    index={index}
  >
    <Box className={classes.fieldArea}>
      <Grid item xs>
        <Typography variant="subtitle1">Display</Typography>
      </Grid>
      <Grid item xs sx={{ marginTop: '16px' }}>
        <Field name="supportingImageUrlEn">
          {({ input: { onChange, value } }) => (
            <ImageUpLoader
              title={
                <span>
                  <Asterisk /> Supporting Image
                </span>
              }
              display="inline"
              previewWidth={120}
              image={value ? { url: value } : null}
              fileInfo={
                <span>
                  Supported files include jpeg and png. File size should be no
                  smaller than 1MB and no larger than 3MBs. Image dimensions
                  must be at least {MINIMUM_IMAGE_WIDTH}px by{' '}
                  {MINIMUM_IMAGE_HEIGHT}px.
                </span>
              }
              onImageUpdated={({ file, url }) => {
                onChange(url);
                onImageEnUploaded({ file, url });
              }}
              maxFileSize={3000000}
              minHeight={MINIMUM_IMAGE_HEIGHT}
              minWidth={MINIMUM_IMAGE_WIDTH}
              allowDelete={false}
              showImage={true}
            />
          )}
        </Field>
      </Grid>
      <Condition when="langES" is={true}>
        <Grid item xs sx={{ marginTop: '24px' }}>
          <Field name="supportingImageUrlEs">
            {({ input: { onChange, value } }) => (
              <ImageUpLoader
                title={
                  <span>
                    <Asterisk /> Supporting Image (Spanish)
                  </span>
                }
                display="inline"
                previewWidth={120}
                image={value ? { url: value } : null}
                fileInfo={
                  <span>
                    Supported files include jpeg and png. File size should be no
                    smaller than 1MB and no larger than 3MBs. Image dimensions
                    must be at least {MINIMUM_IMAGE_WIDTH}px by{' '}
                    {MINIMUM_IMAGE_HEIGHT}px.
                  </span>
                }
                onImageUpdated={({ file, url }) => {
                  onChange(url);
                  onImageEsUploaded({ file, url });
                }}
                maxFileSize={3000000}
                minHeight={MINIMUM_IMAGE_HEIGHT}
                minWidth={MINIMUM_IMAGE_WIDTH}
                allowDelete={false}
                showImage={true}
              />
            )}
          </Field>
        </Grid>
      </Condition>
    </Box>
    <Box className={classes.fieldSection} sx={{ marginTop: '40px' }}>
      <Grid item xs>
        <Typography variant="subtitle1">Legal Information</Typography>
      </Grid>
      <Grid item xs sx={{ marginTop: '38px' }}>
        <TextField
          label={getFormLabel('EULA URL', true)}
          name="eulaUrl"
          variant="outlined"
          type="url"
          disabled={!isUberAdmin}
        />
      </Grid>
      <Grid item xs sx={{ marginTop: '52px' }}>
        <TextField
          label={getFormLabel('Privacy Policy URL', true)}
          name="privacyPolicyUrl"
          variant="outlined"
          type="url"
          disabled={!isUberAdmin}
        />
      </Grid>
      <Grid item xs sx={{ marginTop: '52px' }}>
        <TextField
          label={getFormLabel('Legal Notes URL', true)}
          name="legalNotesUrl"
          variant="outlined"
          type="url"
          disabled={!isUberAdmin}
        />
      </Grid>
    </Box>
  </TabPanel>
);

AboutUsPageTabPanel.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  isUberAdmin: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onImageEnUploaded: PropTypes.func.isRequired,
  onImageEsUploaded: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

AboutUsPageTabPanel.defaultProps = {
  classes: {},
  index: 1,
  tabValue: 1,
};
