import { FlamingoContext } from 'contexts/flamingo';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

export const SCOPES = Object.freeze({
  BANNER_MESSAGE: {
    CREATE: 'NetworkBannerMessage:create',
    EDIT: 'NetworkBannerMessage:update',
    HAS_CUSTOM_LINK: 'NetworkBannerMessage.actionLink',
    VIEW: 'NetworkBannerMessage:read',
  },
  CATEGORIES: {
    CREATE: 'Category:create',
    EDIT: 'Category:update',
    VIEW: 'Category:read',
  },
  EXPLORE_PANEL: {
    CREATE: 'NetworkExplorePanel:create',
    DELETE: 'NetworkExplorePanel:delete',
  },
  GLOBAL_TEMPLATE: {
    ACCESS: 'GlobalTemplate',
  },
  MYCHART: {
    UPDATE: 'NetworkDashboardMyChart:update',
  },
  NETWORK_CHANNELS: {
    CREATE: 'NetworkChannel:create',
    UPDATE: 'NetworkChannel:update',
    VIEW: 'NetworkChannel:read',
  },
  NETWORK_DASHBOARD: {
    CAN_PUBLISH: 'DataPackage:create',
    CAN_PURGE_PREVIOUS_DATA_PACKAGES: 'DataPackage.purge:create',
    CAN_REQUIRE_IMMEDIATE_DOWNLOAD: 'DataPackage.urgent:create',
    VIEW_NETWORK_DATA_PROGRESS: 'NetworkContentProgress:read',
  },
  PUSH_NOTIFICATIONS: {
    CREATE: 'NetworkNotification:create',
    EDIT: 'NetworkNotification:update',
    HAS_APP_ID: 'NetworkNotification.mobileApp',
    VIEW: 'NetworkNotification:read',
  },
});

export const BEHAVIORS = Object.freeze({
  DISABLE: 'disable',
  HIDE: 'hide',
});

/**
 * Authorize
 *
 * Given a JSON Logic scope, render the children if the scope is allowed. If it is denied, render it based on the input behavior.
 *
 * @param scope {String} - A specific scope to authorize
 * @param data {Object} - Additional data to use when evaluating rules
 * @param behavior {String} - The behavior
 * @param unauthorizedProps {Object} - Optional props for the children if the scope is denied
 * @returns {Object} - the children, if allowed
 */
export const Authorize = ({
                            scope,
                            data,
                            behavior,
                            unauthorizedProps,
                            children,
                          }) => {
  const { authorize } = useContext(FlamingoContext);

  const authorizedResult = authorize(scope, {
    data,
    consolidate: Array.isArray(scope),
  });
  if (authorizedResult) {
    return children;
  }

  if (behavior === BEHAVIORS.HIDE || typeof children !== 'object') {
    return null;
  }

  return React.cloneElement(children, unauthorizedProps ?? { disabled: true });
};

Authorize.propTypes = {
  scope: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  data: PropTypes.object,
  behavior: PropTypes.oneOf(Object.values(BEHAVIORS)),
  unauthorizedProps: PropTypes.object,
};

Authorize.defaultProps = {
  behavior: BEHAVIORS.HIDE,
  data: undefined,
  unauthorizedProps: null,
};
