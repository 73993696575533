import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import A2cImageCard from 'components/dashboard/cardComponent/a2cImageCard';
import A2cMapAndImageCard from 'components/dashboard/cardComponent/a2cMapAndImageCard';
import A2cMapCard from 'components/dashboard/cardComponent/a2cMapCard';
import {
  A2C_DISPLAY_TYPES,
  dndHover,
  isCardIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  fade: {},
  dragging: {
    paddingBottom: '0 !important',
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

const A2cCardLayout = ({
                         id,
                         index,
                         card1,
                         configColors,
                         preview,
                         hovered,
                         moveCard,
                         onCardClick,
                         onDrag,
                         onHover,
                         canDragAndDrop,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
      },
      cardType: A2cCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const renderedCard = useMemo(() => {
    if (card1.displayType === A2C_DISPLAY_TYPES.IMAGE) {
      return (
        <A2cImageCard
          title={card1.title}
          backgroundColor={card1.backgroundColor}
          buttonText={card1.buttonText}
          buttonBackgroundColor={configColors.dashboard_card_button_bg}
          buttonTextColor={configColors.dashboard_card_button_text}
          iconUrl={card1.iconUrl}
          incompleteForPackaging={isCardIncompleteForPackaging(card1)}
          textColor={card1.textColor}
          disabled={card1.disabled}
          selected={card1.selected}
          subtitle={card1.subtitle2}
          clickHandler={() => onCardClick({ cardGroupId: id, key: 'card1', ...card1 })
          }
        />
      );
    }

    if (card1.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE) {
      return (
        <A2cMapAndImageCard
          title={card1.title}
          backgroundColor={card1.backgroundColor}
          iconUrl={card1.iconUrl2}
          incompleteForPackaging={isCardIncompleteForPackaging(card1)}
          textColor={card1.textColor}
          disabled={card1.disabled}
          selected={card1.selected}
          subtitle={card1.subtitle}
          subtitle2={card1.subtitle2}
          clickHandler={() => onCardClick({ cardGroupId: id, key: 'card1', ...card1 })
          }
        />
      );
    }

    return (
      <A2cMapCard
        title={card1.title}
        backgroundColor={card1.backgroundColor}
        incompleteForPackaging={isCardIncompleteForPackaging(card1)}
        textColor={card1.textColor}
        disabled={card1.disabled}
        selected={card1.selected}
        clickHandler={() => onCardClick({ cardGroupId: id, key: 'card1', ...card1 })
        }
      />
    );
  }, [card1, configColors, id, onCardClick]);

  return (
    <Tooltip title={preview && !hovered ? 'Care Near Me card' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...isDragging && styles.dragging,
          ...preview && styles.preview,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          {renderedCard}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

A2cCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  card1: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    clickHandler: PropTypes.func,
    displayType: PropTypes.string,
    incompleteForPackaging: PropTypes.bool,
    subtitle: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    subtitle2: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  configColors: PropTypes.shape({
    dashboard_card_button_bg: PropTypes.string,
    dashboard_card_button_text: PropTypes.string,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

A2cCardLayout.defaultProps = {
  card1: {
    buttonText: '',
    incompleteForPackaging: false,
    subtitle: [],
    subtitle2: [],
    title: 'Card Title',
    clickHandler: () => {},
  },
  configColors: {},
  preview: false,
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default A2cCardLayout;
