import { TextField, FormHelperText, Box } from '@mui/material';
import React from 'react';

const RenderTextField = ({
                           disabled,
                           charLimit,
                           showInitialError,
                           input: { value: formValue, ...formInput },
                           label,
                           meta: { touched, error },
                           ...rest
                         }) => {
  const hasError = !!((showInitialError || touched) && error);
  const helperText = hasError ? error : '';
  return (
    <>
      <TextField
        id={`${label} outlined-with-placeholder`}
        label={label}
        variant="outlined"
        margin="normal"
        fullWidth
        error={hasError}
        {...formInput}
        {...rest}
        sx={{ marginTop: '8px' }}
        value={formValue}
        disabled={disabled}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '-8px',
          justifyContent: 'space-between',
        }}
      >
        <FormHelperText error={hasError}>{helperText}</FormHelperText>
        {charLimit ? (
          <Box
            sx={{
              display: 'inline',
              fontSize: '12px',
              marginTop: '3px',
              lineHeight: '17px',
              letterSpacing: '0.4px',
              wordBreak: 'normal',
              color: hasError || formValue.length > charLimit ? 'red' : '',
            }}
          >
            {formValue.length}/{charLimit}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

RenderTextField.defaultProps = {
  input: {
    value: '',
  },
  meta: {
    touched: false,
  },
};

export default RenderTextField;
