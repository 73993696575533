import { useMutation } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import {
  CREATE_KIOSK,
  DELETE_KIOSK,
  FIND_KIOSK,
  GET_KIOSK,
  UPDATE_KIOSK,
} from 'graphql/queries';

import { useSuspenseQueryWrapper } from 'hooks/dataHooks/useQueryWrapper';

export const DEFAULT_KIOSK_BANNER_INTERVAL = 30;

export const useKiosks = () => useSuspenseQueryWrapper(FIND_KIOSK, {
  dataFn: (data) => ({
    count: data?.findKiosk?.count || 0,
    kiosks: data?.findKiosk?.edges?.map(({ node }) => node) || [],
  }),
  skipFn: (data) => data?.kiosks?.length > 0,
});

export const useKiosk = (kioskId) => useSuspenseQueryWrapper(GET_KIOSK, {
  variables: {
    id: kioskId,
  },
  dataFn: (data) => data?.getKiosk
    ? {
      ...data.getKiosk,
      directory: {
        progress: data.getKiosk.directory.progress,
        tiles: data.getKiosk.directory?.tiles
          ?.map((tile, id) => ({ ...tile, id }))
          .sort((a, b) => a.order - b.order),
      },
    }
    : {},
  skipFn: (data) => Object.keys(data).length > 0,
});

export const useCreateKiosk = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Kiosk');

  return useMutation(CREATE_KIOSK, {
    refetchQueries: [{ query: FIND_KIOSK, variables: {} }],
    onCompleted: (data) => {
      toastNotificationSuccessHook('The Kiosk has been created.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useDeleteKiosk = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Kiosk');

  return useMutation(DELETE_KIOSK, {
    refetchQueries: [{ query: FIND_KIOSK, variables: {} }],
    onCompleted: (data) => {
      toastNotificationSuccessHook('The Kiosk has been deleted.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateKiosk = (gql = UPDATE_KIOSK, message = null) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Kiosk');

  return useMutation(gql, {
    onCompleted: (data) => {
      if (message !== null) {
        if (message.length > 0) toastNotificationSuccessHook(message);
      } else {
        const name = data?.updateKiosk?.name;
        toastNotificationSuccessHook(
          `All changes within ${name || 'kiosk'} have been saved.`,
        );
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
