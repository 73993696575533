import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Notifier = ({ flash, clearFlash }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (flash?.message && !flash?.options) {
      enqueueSnackbar(flash.message || flash.errorMessage, {
        preventDuplicate: true,
        variant: flash.status,
        action,
        autoHideDuration:
          typeof flash?.autoHideDuration === 'undefined'
            ? 5000
            : flash.autoHideDuration,
        onExited: clearFlash,
      });
    } else if (flash?.content || flash?.options) {
      const snackbarOptions = {
        autoHideDuration: null,
        ...flash.options,
      };
      if (!flash.content && !flash.options?.content) {
        //snackbarOptions.variant = flash.status;
        snackbarOptions.action = action;
        snackbarOptions.onExited = clearFlash;
      } else {
        snackbarOptions.content = flash.content || flash.options?.content;
      }
      enqueueSnackbar(flash.options?.message || '', snackbarOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flash]);

  useEffect(
    () => () => {
      // force clear
      clearFlash();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const action = (key) => (
    <IconButton onClick={() => closeSnackbar(key)} size="large">
      <CloseIcon />
    </IconButton>
  );

  return null;
};

Notifier.propTypes = {
  flash: PropTypes.object,
  clearFlash: PropTypes.func,
};

Notifier.defaultProps = {};

export default React.memo(Notifier);
