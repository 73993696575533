import { ArrowBack, Close } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import NetworkLogo from 'components/networkLogo';
import { FlamingoContext } from 'contexts/flamingo';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    background: theme.palette.blue[400],
    padding: '24px',
  },
  headerNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerActions: {
    color: 'inherit',
    fontWeight: 500,
    flex: 1,
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    flex: 2,
  },
  subtitle: {
    marginTop: '3px',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  editMapping: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    marginTop: '3px',
  },
  mappedTitle: {
    color: theme.palette.blue[50],
  },
  closeButton: {
    color: theme.palette.white,
    padding: 0,
    '& svg': {
      fontSize: '22px',
    },
  },
});

const TakeoverHeader = ({
                          expanded,
                          backText,
                          backUrl,
                          closeUrl,
                          showTitle,
                          title,
                          subtitle,
                          networkName,
                          icon,
                          itemType,
                          onBack,
                          sx,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { handleNavigate } = useContext(FlamingoContext);

  const handleBackTo = (event) => {
    event.preventDefault();
    onBack(backUrl);
    handleNavigate(backUrl);
  };

  const handleClose = (event) => {
    event.preventDefault();
    const url = closeUrl ?? backUrl;
    onBack(url);
    handleNavigate(url);
  };

  return (
    <Box sx={{ ...styles.root, height: `${expanded ? 135 : 68}px`, ...sx }}>
      <Box sx={styles.headerNav}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: ColorPalette.white,
            flex: 1,
          }}
        >
          {backText && (
            <>
              <ArrowBack
                sx={{ fontSize: '20px', marginRight: '5px' }}
                onClick={handleBackTo}
              />
              <Link
                onClick={handleBackTo}
                variant="subtitle2"
                data-test="LocationDetailBackLink"
                sx={styles.headerActions}
              >
                {backText}
              </Link>
            </>
          )}
        </Box>
        <Box sx={styles.title}>
          {networkName && (
            <NetworkLogo networkName={networkName} logoUrl={icon?.url} />
          )}
          {showTitle && (
            <Typography
              variant="subtitle2"
              sx={{ color: ColorPalette.white }}
              data-test="TakeoverHeader-title"
            >
              {expanded
                ? title
                : `${subtitle}${itemType ? ` - ${itemType}` : ''}`}
            </Typography>
          )}
        </Box>
        <Box sx={{ flex: 1, textAlign: 'right' }}>
          <IconButton
            sx={styles.closeButton}
            onClick={handleClose}
            size="large"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      {expanded && (
        <>
          <Box sx={styles.subtitle}>
            <Typography
              variant="h1"
              sx={{
                color: ColorPalette.white,
                ...!showTitle && { marginTop: '-22px' },
              }}
              data-test="TakeoverHeader-subtitle"
            >
              {subtitle}
            </Typography>
          </Box>
          <Box sx={styles.editMapping} datatype="itemType">
            {typeof itemType === 'string' ? (
              <Typography
                variant="body2"
                sx={styles.mappedTitle}
                data-test="TakeoverHeader-item"
              >
                {itemType}
              </Typography>
            )
              : itemType
            }
          </Box>
        </>
      )}
    </Box>
  );
};

TakeoverHeader.propTypes = {
  expanded: PropTypes.bool,
  backText: PropTypes.string,
  backUrl: PropTypes.string,
  closeUrl: PropTypes.string,
  networkName: PropTypes.string,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  itemType: PropTypes.any,
  icon: PropTypes.object,
  onBack: PropTypes.func,
  sx: PropTypes.object,
};

TakeoverHeader.defaultProps = {
  expanded: true,
  showTitle: true,
  sx: {},
};

export default React.memo(TakeoverHeader);
