import { Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GozioListItem from 'components/listItem/gozioListItem';
import { colorWithAlpha } from 'helpers/color-util';
import { getLabelByLang } from 'helpers/lang-util';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    overflow: 'hidden',
    padding: theme.spacing(0, 2),
  },
  previewRoot: {
    paddingRight: '0 !important',
  },
  content: {
    paddingTop: '28px',
    position: 'relative',
    width: '100%',
  },
  menu: {
    position: 'absolute',
    right: '22px',
    top: '22px',
  },
  selected: {
    boxShadow: `0 8px 16px 0 ${colorWithAlpha(theme.palette.black, 0.4)}`,
  },
  disabled: {
    opacity: 0.5,
  },
  incompleteForPackaging: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
  incompleteWarning: {
    color: theme.palette.warning.main,
    position: 'absolute',
    top: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  username: {
    paddingBottom: '16px',
  },
  baseTextLeft: {
    paddingRight: theme.spacing(6),
    WebkitLineClamp: 1,
  },
  listItem: {
    background: 'unset',
    marginTop: '8px',
    overflowY: 'auto',
  },
  quickLinkLabel: {
    display: '-webkit-box',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
});

export const MyChartQuickLinksCard = ({
                                        backgroundColor,
                                        clickHandler,
                                        disabled,
                                        incompleteForPackaging,
                                        preview,
                                        quickLinksData,
                                        selected,
                                        textAlignment,
                                        textColor,
                                        title,
                                      }) => {
  const theme = useTheme();
  const styles = buildStyles({
    theme,
  });

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  const isLeftAlign = textAlignment === 'left';

  const renderIncompleteWarning = () => (
    <Box sx={{ ...styles.incompleteWarning, left: `${width - 28}px` }}>
      <Info />
    </Box>
  );

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const getQuickLinkLabel = (name) => {
    const label = getLabelByLang(name);
    if (label.length < 50) {
      return label;
    }

    return `${label.substr(0, 50)}...`;
  };

  const quickLinksContent = useMemo(
    () => (
      <GozioListItem
        sx={styles.listItem}
        enableReorder={false}
        options={quickLinksData.map((link, index) => ({
          id: link.id,
          order: index,
          iconUrl: link.iconUrl,
          label: getQuickLinkLabel(link.name),
          labelSx: styles.quickLinkLabel,
        }))}
        labelSx={{
          color: textColor,
        }}
        canHover={false}
        showTooltip={false}
      />
    ),
    [styles, quickLinksData, textColor],
  );

  return (
    <Grid
      ref={ref}
      container
      direction="column"
      alignItems={isLeftAlign ? 'flex-start' : 'center'}
      onClick={clickHandler}
      sx={{
        ...styles.root,
        ...!preview
          && incompleteForPackaging
          && styles.incompleteForPackaging,
        ...selected && styles.selected,
        ...disabled && styles.disabled,
        ...preview && styles.previewRoot,
        backgroundColor,
        color: textColor,
        textAlign: textAlignment,
      }}
    >
      {!preview && incompleteForPackaging && renderIncompleteWarning()}
      <Box sx={styles.content}>
        {!!title && (
          <Typography
            variant="h2"
            sx={{
              ...isLeftAlign && styles.baseTextLeft,
              ...styles.title,
              backgroundColor,
              color: textColor,
            }}
          >
            {title}
          </Typography>
        )}
        <Box sx={styles.menu}>
          <Typography
            variant="h2"
            sx={{ ...styles.title, backgroundColor, color: textColor }}
          >
            ...
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{ ...styles.username, backgroundColor, color: textColor }}
        >
          Username
        </Typography>
        {quickLinksData.length > 0 && quickLinksContent}
      </Box>
    </Grid>
  );
};

MyChartQuickLinksCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  incompleteForPackaging: PropTypes.bool,
  preview: PropTypes.bool,
  quickLinksData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      iconUrl: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  selected: PropTypes.bool,
  textAlignment: PropTypes.oneOf(['left', 'center']),
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

MyChartQuickLinksCard.defaultProps = {
  disabled: false,
  incompleteForPackaging: false,
  preview: false,
  quickLinksData: [],
  selected: false,
};

export default MyChartQuickLinksCard;
