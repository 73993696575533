import Loading from 'components/loading/loading';
import { getLabelByLang } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import PageHelpers from 'helpers/pageHelpers';
import { useNetworkPlace } from 'hooks/dataHooks';
import { buildValidator, initialParser, submitParser } from 'pages/locations/containers/detail/poiFormHelper';
import DetailContainer from 'pages/locations/containers/detailContainer';
import ActionLinks from 'pages/locations/containers/sections/actionLinks';
import Address from 'pages/locations/containers/sections/address';
import Details from 'pages/locations/containers/sections/details';
import Extra from 'pages/locations/containers/sections/extraInstructions/extraInstructions';
import Hours from 'pages/locations/containers/sections/hours';
import MappingInputs from 'pages/locations/containers/sections/mappingInputs';
import MapVisibility from 'pages/locations/containers/sections/mapVisibility';
import Media from 'pages/locations/containers/sections/media';
import Searchability from 'pages/locations/containers/sections/searchability';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const sections = [
  {
    text: 'Mapping Inputs',
    hash: 'mapping',
    conditional: ({ hasPermissions, data }) => hasPermissions && data?.isMapped,
    component: MappingInputs,
  },
  {
    text: 'POI Details',
    hash: 'poi-details',
    conditional: () => true,
    component: Details,
  },
  {
    text: 'Address',
    hash: 'address',
    conditional: () => true,
    component: Address,
  },
  {
    text: 'Media',
    hash: 'media',
    conditional: () => true,
    component: Media,
  },
  {
    text: 'Map Visibility',
    hash: 'mapVisibility',
    conditional: () => true,
    component: MapVisibility,
  },
  {
    text: 'Searchability',
    hash: 'searchability',
    conditional: () => true,
    component: Searchability,
  },
  {
    text: 'Quicklinks',
    hash: 'links',
    conditional: () => true,
    component: ActionLinks,
  },
  {
    text: 'Hours',
    hash: 'hours',
    conditional: () => true,
    component: Hours,
  },
  {
    text: 'Extra Instructions',
    hash: 'extra',
    conditional: ({ data }) => data.isMapped,
    component: Extra,
  },
];

const PoiDetailPage = () => {
  const { networkId, poiId } = useParams();
  const { data: place, error, loading } = useNetworkPlace({ id: poiId });

  const tabItems = [{ key: 'poi', title: 'POI Information' }];

  const buildBreadcrumb = () => {
    const { parentSite, parentBuilding, parentFloor } = place;
    const parents = [];
    if (parentSite) parents.push({
        name: getLabelByLang(parentSite.name) || 'Site Name Unavailable',
        link: `/network/${networkId}/locations/site/${parentSite.id}`,
      });
    if (parentBuilding) parents.push({
        name:
          getLabelByLang(parentBuilding.name) || 'Building Name Unavailable',
        link: `/network/${networkId}/locations/building/${parentBuilding.id}`,
      });
    if (parentFloor) parents.push({
        name: getLabelByLang(parentFloor.name) || 'Floor Name Unavailable',
        link: `/network/${networkId}/locations/floor/${parentFloor.id}`,
      });

    return { title: getLabelByLang(place?.name), parents };
  };

  if (error) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  if (isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <PageHelpers showWorkspaceSwitcher={false}>
      <DetailContainer
        networkId={networkId}
        networ
        networkPlaceId={poiId}
        sections={sections}
        initialParser={initialParser}
        submitParser={submitParser}
        validator={buildValidator}
        backText="Back to Site"
        data={place}
        backUrl={`/network/${networkId}/locations/site/${place?.parentSite?.id}?tab=pois`}
        closeUrl={`/network/${networkId}/locations`}
        tabItems={tabItems}
        pageTitle={`${place?.network?.name}, ${getLabelByLang(
          place?.parent?.name,
        )}`}
        pageSubtitle={getLabelByLang(place?.name)}
        breadcrumb={buildBreadcrumb()}
      />
    </PageHelpers>
  );
};

export default PoiDetailPage;
