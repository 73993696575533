import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Label, Tooltip, XAxis, YAxis } from 'recharts';
import { tickFormatterThousandsToK } from './chartHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',

    '& .recharts-wrapper': {
      margin: '0 auto',
    },

    '& .recharts-text': {
      fill: ColorPalette.grey[600],
      fontSize: '12px',
    },
  },
  axis: {
    fill: `${theme.palette.grey[400]} !important`,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: '12px',
  },
}));

const GozioBarChart = ({
                         data,
                         dataKey,
                         xAxisLabel,
                         xAxisKey,
                         yAxisLabel,
                         yAxisKey,
                         width,
                         height,
                         showLines,
                         showTooltip,
                         customTooltip,
                       }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      sx={{
        '& .recharts-surface': {
          width: width + 6,
        },
      }}
    >
      <BarChart width={width} height={height} data={data} barSize={32}>
        {xAxisKey && (
          <XAxis dataKey={xAxisKey} dy={7} stroke="none">
            {xAxisLabel && (
              <Label value={xAxisLabel} dy={15} className={classes.axis} />
            )}
          </XAxis>
        )}
        {yAxisKey && (
          <YAxis
            dataKey={yAxisKey}
            stroke={ColorPalette.grey[300]}
            strokeWidth={2}
            tickFormatter={tickFormatterThousandsToK}
          >
            {yAxisLabel && (
              <Label
                angle={-90}
                className={classes.axis}
                position="insideLeft"
                style={{ textAnchor: 'middle' }}
                value={yAxisLabel}
              />
            )}
          </YAxis>
        )}
        {showTooltip
          && (customTooltip ? (
            <Tooltip content={customTooltip} cursor={{ fill: 'transparent' }} />
          ) : (
            <Tooltip cursor={{ fill: 'transparent' }} />
          ))}
        {showLines && (
          <CartesianGrid stroke={ColorPalette.grey[300]} strokeWidth={2} />
        )}
        <Bar
          dataKey={dataKey}
          fill={ColorPalette.blue[400]}
          background={{ fill: '#d8d8d8' }}
        />
      </BarChart>
    </Box>
  );
};

GozioBarChart.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string,
  xAxisKey: PropTypes.string,
  yAxisLabel: PropTypes.string,
  yAxisKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showLines: PropTypes.bool,
  showTooltip: PropTypes.bool,
  customTooltip: PropTypes.node,
};

GozioBarChart.defaultProps = {
  data: null,
  xAxisLabel: null,
  xAxisKey: null,
  yAxisLabel: null,
  yAxisKey: null,
  width: 270,
  height: 170,
  showLines: false,
  showTooltip: false,
  customTooltip: null,
};

export default GozioBarChart;
