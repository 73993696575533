import ColorPalette from 'pages/gozio_colors';

export const buildTableStyles = ({ theme }) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px',
    borderRadius: '20px',

    '& div.hoverUnhide': {
      display: 'none',
    },

    '& tbody tr': {
      height: '72px',
    },

    '& tbody tr:hover': {
      background: `linear-gradient(${ColorPalette.gradient.table.start}, ${ColorPalette.gradient.table.end})`,

      '& td': {
        background: `linear-gradient(${ColorPalette.gradient.table.start}, ${ColorPalette.gradient.table.end}) !important`,
      },

      '& div, p': {
        fontWeight: 'bold',
        color: ColorPalette.grey[600],
      },

      '& td[class*="highlightOnHover"] div': {
        color: ColorPalette.blue[400],
        textDecoration: 'underline',
      },

      '& div.hoverUnhide': {
        display: 'block',

        '& svg:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
  fullWidth: {
    padding: 0,
  },
  toolsRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 8px 8px 16px',
    '& > .MuiButtonBase-root': {
      paddingTop: 0,
    },
  },
  toolsPane: {},
  countTitle: {
    marginRight: '16px',
  },
  hideShowIcon: {
    fontSize: '16px',
  },
  tableContainer: {
    boxShadow: 'none',
    height: '100%',
  },
  table: {
    flex: 1,
  },
  flexTable: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  sticky: {
    position: 'sticky !important',

    '&:hover': {
      zIndex: '100 !important',
    },
  },
  stickyCell: {
    position: 'sticky !important',
    background: theme.palette.white,
    zIndex: 900,

    '&:hover': {
      zIndex: '999 !important',
    },
  },
  stickyLeft: {
    left: 0,

    '&.MuiTableCell-head': {
      zIndex: '101 !important',
    },
  },
  stickyRight: {
    right: 0,
  },
  header: {
    backgroundColor: theme.palette.white,
    paddingRight: 0,

    '&:last-child': {
      paddingRight: '12px',
    },
  },
  headerLabel: {
    width: '100%',
  },

  headerLabelBorder: {
    borderRight: `2px solid ${theme.palette.grey[300]}`,
  },

  noSort: {
    cursor: 'default',
  },

  justifyRight: {
    justifyContent: 'flex-end',
  },

  dragPadding: {
    paddingLeft: '14px',
  },

  noRightPad: {
    paddingRight: 0,
    paddingLeft: '12px',
    marginRight: '-4px',
  },

  hiddenContent: {
    '& div': {
      visibility: 'hidden',
    },
  },

  cell: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100% - 18px)',
    marginTop: '9px',
  },

  cellContent: {
    overflow: 'hidden !important',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box !important',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },

  draggableRow: {
    '& .dragIndicator': {
      opacity: '0%',
    },
    '&:hover .dragIndicator': {
      opacity: '100%',
    },
  },

  alwaysShown: {
    overflow: 'visible',
  },

  checkbox: {
    color: theme.palette.grey[500],
    padding: 0,
    '& svg': {
      fontSize: '16px',
    },
  },

  hidden: { display: 'none' },

  footer: {
    padding: '30px 0 16px',
    '& div.MuiTableRow-root': {
      display: 'block',
    },
  },

  paginationContainer: {
    borderBottom: 0,
    minHeight: '24px',
  },
  paginationToolbar: {
    width: '100%',
    minHeight: 0,
    padding: 0,
  },
  paginationSelectIcon: {
    color: theme.palette.grey[600],
    top: 'calc(50% - 15px)',
    right: '-5px',
  },
  paginationSelect: {
    marginLeft: 0,
    marginRight: '8px',
    '& div.MuiSelect-root': {
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: 400,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  tableLoading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragging: {
    background: `${theme.palette.white} !important`,
    '& div': { fontWeight: '400 !important' },
  },
  inactive: {
    opacity: '50%',
    '&:hover': {
      opacity: '100%',
    },
  },
  emptyContent: {
    height: 'auto',
  },
});
