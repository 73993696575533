import { Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import FormSubSection from 'components/forms/formSubSection';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import { TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const MINIMUM_IMAGE_WIDTH = 120;
const MINIMUM_IMAGE_HEIGHT = 120;

export const DEEP_LINK_LINK_CONTENT_SCHEMA = {
  linkContentTitle: Yup.string()
    .max(45, 'Link Alias must be 45 characters or less'),
  linkContentDescription: Yup.string(),
};

export const buildLinkContentInitialValues = ({ config, data }) => ({
  linkContentTitle: '',
  linkContentDescription: '',
  linkContentImageUrl: '',
});

export const buildLinkContentUpdateInput = ({ values, uploadedImage }) => ({
  openGraphMeta: {
    contentDescription: values.linkContentDescription,
    imageUrl: uploadedImage?.secureUrl ?? values.linkContentImageUrl,
    title: values.linkContentTitle,
  },
});

const LinkContentSection = ({ onImageUploaded }) => (
  <FormSubSection name="Link Content">
    <Grid container direction="row" spacing={4.5}>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <Typography variant="body1">
          Below you can override the visual components associated with the deep
          link. These components will display in the link’s deep view.
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <Field name="linkContentTitle">
          {({ input, meta }) => (
            <TextField
              label={getFormLabel('App Name', true)}
              charLimit={45}
              helperText={meta.touched ? meta.error : ''}
              error={meta.touched && !!meta.error}
              {...input}
              fullWidth
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={6} sx={{ marginRight: '50%' }}>
        <FinalTextField
          label={getFormLabel('Social Media Description', true)}
          name="linkContentDescription"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Add Supporting Image</Typography>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: '-22px' }}>
        <Field name="linkContentImageUrl">
          {({ input: { onChange, value } }) => (
            <ImageUpLoader
              title={
                <span>
                  <Asterisk /> Supporting Image
                </span>
              }
              display="stacked"
              previewWidth={120}
              image={value ? { url: value } : null}
              fileInfo={
                <span>
                  Supported files include jpeg and png. File size should be no
                  larger than 50KB. Image dimensions must be at least{' '}
                  {MINIMUM_IMAGE_WIDTH}px by {MINIMUM_IMAGE_HEIGHT}px.
                </span>
              }
              onImageUpdated={({ file, url }) => {
                onChange(url);
                onImageUploaded({ file, url });
              }}
              maxFileSize={50000}
              minHeight={MINIMUM_IMAGE_HEIGHT}
              minWidth={MINIMUM_IMAGE_WIDTH}
              allowDelete={false}
              showImage={true}
            />
          )}
        </Field>
      </Grid>{' '}
    </Grid>
  </FormSubSection>
);

LinkContentSection.propTypes = {
  onImageUploaded: PropTypes.func.isRequired,
};

export default LinkContentSection;
