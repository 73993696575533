import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WelcomeImage from 'assets/welcome.svg';
import { FlamingoContext } from 'contexts/flamingo';
import { isGlobalTemplate } from 'helpers/network-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import useToast from 'hooks/useToast';
import { useWorkspace } from 'hooks/useWorkspace';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import LoggedOutLayout from 'pages/layouts/loggedOutLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    textAlign: 'center',
  },
}));

export const Welcome = ({ isAuthenticated }) => {
  const { networkId } = useParams();
  const { toastNotificationErrorHook } = useToast();
  const { activeNetwork, globalTemplate } = useContext(FlamingoContext);
  const { workspace, setWorkspace } = useWorkspace();

  useEffect(() => {
    if (
      isAuthenticated
      && isGlobalTemplate(networkId)
      && workspace !== LIVE_WORKSPACE
    ) {
      toastNotificationErrorHook(
        'You have been switched to the Live Workspace. You are unable to access the Draft Workspace in the Global Template.',
      );
      setWorkspace(LIVE_WORKSPACE);
    }
  }, [
    isAuthenticated,
    networkId,
    setWorkspace,
    toastNotificationErrorHook,
    workspace,
  ]);

  const pageName = useMemo(() => {
    if (isAuthenticated) {
      if (isGlobalTemplate(networkId)) {
        return `Welcome to the ${globalTemplate.humanName}`;
      }

      return `Welcome to the ${activeNetwork.humanName} Portal`;
    }

    return 'Please login to access the Portal';
  }, [activeNetwork, globalTemplate, isAuthenticated, networkId]);

  const classes = useStyles();

  return (
    <FlamingoPage pageName={pageName}>
      <Grid item xs={12} className={classes.imageContainer}>
        <img src={WelcomeImage} alt="Welcome" />
      </Grid>
    </FlamingoPage>
  );
};

Welcome.propTypes = {
  isAuthenticated: PropTypes.bool,
};

Welcome.defaultProps = {
  isAuthenticated: true,
};

export const WelcomePage = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return (
      <LoggedinLayout>
        <Welcome />
      </LoggedinLayout>
    );
  }

  return (
    <LoggedOutLayout>
      <Welcome isAuthenticated={false} />
    </LoggedOutLayout>
  );
};

WelcomePage.propTypes = {
  isAuthenticated: PropTypes.bool,
};

WelcomePage.defaultProps = {
  isAuthenticated: true,
};

export default WelcomePage;
