import { Clear } from '@mui/icons-material';
import { Box, Link, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Chip from 'components/chip/chip';
import FilterSelection from 'components/tables/filterSelection';
import React, { useState } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  clearFiltersWrap: {
    height: '20px',
    borderLeft: `2px solid ${palette.grey[300]}`,
    marginLeft: '8px',
    paddingLeft: '12px',
  },
  clearFields: {
    fontSize: '0.875rem',
    letterSpacing: 0,
  },
}));

const FilterChip = ({
                      filter,
                      classes,
                      selectedValues,
                      clearFilter,
                      setCurrentFilter,
                    }) => {
  const currentSelections = selectedValues.find(
    (val) => val.name === filter.filterAccessor || val.name === filter.accessor,
  );
  const tooltip = (currentSelections?.values?.map((v) => v.label) || []).join(
    ', ',
  );
  const clear = () => {
    clearFilter(filter.filterAccessor ?? filter.accessor);
  };

  const chipLabel = () => {
    let retVal = filter.filterTitle ?? filter.Header;
    if (currentSelections?.values && currentSelections.values.length > 0) {
      retVal = `${retVal} (${currentSelections.values.length})`;
    }
    return retVal;
  };

  return (
    <Tooltip title={tooltip} arrow>
      <Box>
        <Chip
          label={chipLabel()}
          selectable={false}
          onClick={() => setCurrentFilter(filter)}
          state={'default'}
          sx={{ marginRight: '8px' }}
          onIconClick={currentSelections ? clear : null}
          clickableIcon={currentSelections ? <Clear /> : null}
        />
      </Box>
    </Tooltip>
  );
};

const TableFilters = ({ columns, selectedFilters, updateFilters }) => {
  const classes = useStyles();
  const displayFilters = columns.filter((c) => c.filters);
  const [currentFilter, setCurrentFilter] = useState(null);

  const clearFilters = () => {
    updateFilters([]);
  };

  const clearFilter = (filterName) => {
    const newFilters = selectedFilters.filter((f) => f.name !== filterName);
    updateFilters(newFilters);
  };

  const updateFilter = (type, filters) => {
    setCurrentFilter(null);
    let found = false;
    const newFilters = selectedFilters.map((f) => {
      if (f.name === type) {
        found = true;
        return {
          name: type,
          values: [...filters],
        };
      }
      return {
        name: f.name,
        values: [...f.values],
      };
    });
    if (!found) {
      newFilters.push({
        name: type,
        values: [...filters],
      });
    }
    updateFilters(newFilters);
  };

  return (
    <Box className={classes.root}>
      {currentFilter && (
        <FilterSelection
          filter={currentFilter}
          initialFilters={selectedFilters}
          clearFilter={() => setCurrentFilter(null)}
          updateFilter={updateFilter}
        />
      )}
      {displayFilters.map((f) => (
        <FilterChip
          key={`filter-chip-${f.filterAccessor ?? f.accessor}`}
          filter={f}
          selectedValues={selectedFilters}
          classes={classes}
          clearFilter={clearFilter}
          setCurrentFilter={setCurrentFilter}
        />
      ))}
      {selectedFilters.length > 0 && (
        <Box className={classes.clearFiltersWrap}>
          <Link
            className={classes.clearFields}
            onClick={(e) => {
              e.preventDefault();
              clearFilters();
            }}
          >
            Clear Filters
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(TableFilters);
