import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import ColorPalette from 'pages/gozio_colors';
import React from 'react';

export const Success = (
  <CheckCircle
    sx={{
      fill: ColorPalette.green[500],
      fontSize: '32px',
      marginRight: '16px',
    }}
    key="circle"
  />
);
export const ErrorIcon = (
  <Error
    sx={{
      fill: ColorPalette.red[500],
      fontSize: '32px',
      marginRight: '16px',
    }}
    key="error"
  />
);
export const WarningIcon = (
  <Warning
    sx={{
      fill: ColorPalette.orange[500],
      fontSize: '32px',
      marginRight: '16px',
    }}
    key="error"
  />
);
export const InfoIcon = (
  <Info
    sx={{
      fill: ColorPalette.purple[500],
      fontSize: '32px',
      marginRight: '16px',
    }}
    key="error"
  />
);
