import { useSuspenseQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GozioTable from 'components/tables/gozioTable';
import { convertDateToString } from 'helpers/date-util';
import { getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import useHandleError from 'hooks/useHandleError';
import React, { useMemo } from 'react';
import { GET_NETWORK_NOTIFICATIONS } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: 0,
  },
}));

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Date Sent',
    accessor: 'createdAt',
    Cell: ({ cell }) => convertDateToString(cell.value),
    minWidth: 80,
    width: 80,
  },
  { Header: 'Message Title', accessor: 'title', minWidth: 240, width: 240 },
];

const History = (props) => {
  const classes = useStyles();
  const { handleError } = useHandleError('PushNotifications');

  const { data } = useSuspenseQuery(GET_NETWORK_NOTIFICATIONS, {
    context: { headers: { network: props.networkId } },
    variables: { networkId: props.networkId },
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  const processHistory = () => data?.getNetwork?.notifications?.map((not) => ({
      id: not.id,
      title: getLabelByLang(not.title),
      createdAt: not.createdAt,
      type: not.type,
    }));

  const memoizedData = useMemo(
    () => processHistory(),
    [data], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const count = memoizedData?.length || 0;

  return (
    <Grid item className={classes.root}>
      <GozioTable
        name="NotificationsTable"
        columns={COLUMNS}
        data={memoizedData}
        sortBy={[{ id: 'createdAt', desc: true }]}
        sx={{ paddingTop: '8px' }}
        countTitle={getTableCountTitle(count, 'Notification')}
      />
    </Grid>
  );
};

export default History;
