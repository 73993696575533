import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Condition from 'components/forms/condition';
import FormSubSection from 'components/forms/formSubSection';
import NumberField from 'components/numberField/numberField';
import GozioSwitch from 'components/switch/formSwitch';
import { getFormLabel } from 'helpers/form-util';
import { capitalize } from 'helpers/lang-util';
import { Checkboxes, Radios, TextField as FinalTextField } from 'mui-rff';
import React from 'react';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  muiFormHelperTextRoot: {
    textAlign: 'right',
    fontWeight: 300,
    color: theme.palette.grey[400],
    padding: 0,
  },
}));

function MappingInputs({ data }) {
  const classes = useStyles();
  const designation = data?.designation;
  return (
    <>
      <FormSubSection name="Mapping Identification">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FinalTextField
              label={getFormLabel(
                `${
                  designation === 'poi' ? 'POI' : capitalize(designation)
                } Map ID`,
                true,
              )}
              name="mapID"
              variant="outlined"
              disabled={['poi', 'landmark'].includes(designation)}
            />
          </Grid>
          {['poi', 'building', 'landmark'].includes(designation) && (
            <>
              <Grid item xs={3}>
                <NumberField
                  label={getFormLabel('X Coordinate', true)}
                  name="xCoord"
                  variant="outlined"
                  disabled={true}
                  InputProps={{ inputProps: { step: 'any' } }}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberField
                  label={getFormLabel('Y Coordinate', true)}
                  name="yCoord"
                  variant="outlined"
                  disabled={true}
                  InputProps={{ inputProps: { step: 'any' } }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </FormSubSection>
      {['site', 'floor'].includes(designation) && (
        <FormSubSection name="Map Boundary" id="up-map-boundary">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <FinalTextField
                label={getFormLabel('Map Boundary', true)}
                name="mapBoundary"
                variant="outlined"
                helperText="Input Order: West, South, East, North"
                fieldProps={{
                  format: (value) => (value && value.length !== 0 && value?.join(',')) || '',
                  parse: (value) => (value && value.length !== 0 && value?.split(',')) || [],
                }}
                FormHelperTextProps={{
                  classes: { root: classes.muiFormHelperTextRoot },
                }}
              />
            </Grid>
          </Grid>
          {designation === 'site' && (
            <Grid container direction="row" spacing={3}>
              <Grid item xs={6}>
                <Radios
                  label="Parking Type"
                  name="parkingType"
                  color="primary"
                  radioGroupProps={{ row: true }}
                  required={false}
                  size="small"
                  data={[
                    { label: 'None', value: 'none' },
                    { label: 'Level', value: 'level' },
                    { label: 'Spot', value: 'spot' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <Condition when="parkingType" is="spot">
                  <Field
                    name="strictParking"
                    component={GozioSwitch}
                    title={'Strict Parking Arrival'}
                    labelOn="Yes"
                    labelOff="No"
                  />
                </Condition>
              </Grid>
              <Grid item xs={6} sx={{ marginBottom: '-8px' }}>
                <Checkboxes
                  label="Alternate Transportation"
                  name="alternateTransportation"
                  color="primary"
                  data={[{ label: 'Driving', value: 'driving' }]}
                />
              </Grid>
            </Grid>
          )}
        </FormSubSection>
      )}
      {designation === 'site' && (
        <FormSubSection name="Outdoor Positioning">
          <Field name="CampusNav" subscription={{ value: true }}>
            {({ input: { value: campusNavValue } }) => (
              <>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs>
                    <NumberField
                      label={getFormLabel('Map Rotation', true)}
                      name="mapRotation"
                      variant="outlined"
                      InputProps={{ inputProps: { step: 'any' } }}
                      disabled={!campusNavValue}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="CampusNav"
                      component={GozioSwitch}
                      title={'Outdoor Positioning'}
                      labelOn="Enable"
                      labelOff="Disable"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={3}
                  sx={{ marginTop: '6px' }}
                >
                  <Grid item xs>
                    <NumberField
                      label={getFormLabel('Latitude', true)}
                      name="latitude"
                      variant="outlined"
                      InputProps={{ inputProps: { step: 'any' } }}
                      disabled={!campusNavValue}
                    />
                  </Grid>
                  <Grid item xs>
                    <NumberField
                      label={getFormLabel('Longitude', true)}
                      name="longitude"
                      variant="outlined"
                      InputProps={{ inputProps: { step: 'any' } }}
                      disabled={!campusNavValue}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={3}
                  sx={{ marginTop: '12px' }}
                >
                  <Grid item xs>
                    <NumberField
                      label={getFormLabel('X Axis', true)}
                      name="xAxis"
                      variant="outlined"
                      InputProps={{ inputProps: { step: 'any' } }}
                      disabled={!campusNavValue}
                    />
                  </Grid>
                  <Grid item xs>
                    <NumberField
                      label={getFormLabel('Y Axis', true)}
                      name="yAxis"
                      variant="outlined"
                      InputProps={{ inputProps: { step: 'any' } }}
                      disabled={!campusNavValue}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Field>
        </FormSubSection>
      )}
      {designation === 'poi' && (
        <FormSubSection name="Parking Availability">
          <Field
            name="isParkingArea"
            component={GozioSwitch}
            title={'Is This POI a Parking Facility?'}
            labelOn="Yes"
            labelOff="No"
          />
        </FormSubSection>
      )}
    </>
  );
}

export default MappingInputs;
