import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import AutoComplete from 'components/autocomplete/autoComplete';
import CustomTextList from 'components/customTextList/customTextList';
import Loading from 'components/loading/loading';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import { getFormLabel } from 'helpers/form-util';
import { SecureUrlRegexp } from 'helpers/string-util';
import { TextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

export const REQUIRED_REDIRECTS_SCHEMA = {
  webUrl: {
    label: 'Web URL',
    schema: Yup.string().required().matches(
      SecureUrlRegexp,
      'URL must be https, e.g. https://www.goziohealth.com',
    ).required('Web URL is required'),
  },
  mobileAppId: { label: 'App', schema: Yup.string().required('App is required') },
  androidUriScheme: { label: 'Android URI Scheme', schema: Yup.string().required('Android URI Scheme is required') },
  androidPlayStoreUrl: {
    label: 'Fallback URL',
    schema: Yup.string().matches(
      SecureUrlRegexp,
      'URL must be https, e.g. https://www.goziohealth.com',
    ).required('Fallback URL is required'),
  },
  androidPackageName: { label: 'App Package Name', schema: Yup.string().required('App Package Name is required') },
  androidSha256CertFingerprints: {
    label: 'SHA256 Cert Fingerprint',
    schema: Yup.array().of(Yup.string()).required('SHA256 Cert Fingerprint is required'),
  },
  iosUriScheme: { label: 'iOS URI Scheme', schema: Yup.string().required('iOS URI Scheme is required') },
  iosAppStoreUrl: {
    label: 'App Store URL',
    schema: Yup.string().matches(
      SecureUrlRegexp,
      'URL must be https, e.g. https://www.goziohealth.com',
    ).required('App Store URL is required'),
  },
  iosAppStoreId: { label: 'Apple App Store ID', schema: Yup.string().required('Apple App Store ID is required') },
  iosBundleIds: { label: 'Bundle ID', schema: Yup.array().of(Yup.string()).required('Bundle ID is required') },
  iosTeamId: { label: 'Team ID', schema: Yup.string().required('Team ID is required') },
  brandingNetworkName: { label: 'Network Name', schema: Yup.string().required('Network Name is required') },
};

export const REQUIRED_REDIRECTS_ERROR_MAP = Object.keys(
  REQUIRED_REDIRECTS_SCHEMA,
).map((key) => [
  key,
  {
    label: REQUIRED_REDIRECTS_SCHEMA[key].label,
    section: 'Required Redirects',
  },
]);

export const buildRequiredRedirectsInitialValues = (data) => ({
  webUrl: data?.webUrl,
  mobileAppId: data?.mobileAppId,
  desktopDeepview: data?.desktopDeepview,
  androidUriScheme: data?.android?.uriScheme,
  androidPlayStoreUrl: data?.android?.playStoreUrl,
  androidPackageName: data?.android?.packageName,
  androidSha256CertFingerprints: data?.android?.sha256CertFingerprints,
  iosUriScheme: data?.ios?.uriScheme,
  iosAppStoreUrl: data?.ios?.appStoreUrl,
  iosAppStoreId: data?.ios?.appStoreId,
  iosBundleIds: data?.ios?.bundleIds,
  iosTeamId: data?.ios?.teamId,
  brandingNetworkName: data?.branding?.networkName,
});

const RequiredRedirects = ({ mobileApps, disabled, loading, onSaved, sx }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Default URL</Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="webUrl"
            label={getFormLabel('Web URL', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Field name="mobileAppId">
            {({ input, meta }) => (
              <AutoComplete
                input={input}
                meta={meta}
                options={mobileApps.map(({ id, name: label, type }) => ({
                  id,
                  label,
                  type,
                }))}
                label="Select App"
                required={true}
                fullWidth
                disableClearable={true}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box component="hr" sx={sx.separator} />
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Typography variant="subtitle1">Link Location</Typography>
            <InfoTooltip
              sx={{ paddingLeft: '8px' }}
              title={
                <Box>
                  Determines your deep link's behavior. Choose “Gozio Redirect Page” to display a QR code (for browsers)
                  and prompt app download (on mobile). Without this, links open the specified web URL directly. Each
                  link's redirect page can be customized within Link Manager.
                </Box>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-8px' }}>
          <Field name="desktopDeepview">
            {({ input: { value, onChange } }) => (
              <Grid item sx={{ marginRight: '32px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      sx={{ padding: '4px 4px 4px 8px' }}
                      checked={value}
                      onChange={() => {
                        onChange(!value);
                      }}
                    />
                  }
                  label="Gozio Redirect Page"
                />
              </Grid>
            )}
          </Field>
        </Grid>
      </Grid>
      <Box component="hr" sx={sx.separator} />
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Android Redirects</Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="androidUriScheme"
            label={getFormLabel('Android URI Scheme', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="androidPlayStoreUrl"
            label={getFormLabel('Fallback URL', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="androidPackageName"
            label={getFormLabel('App Package Name', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <Field name="androidSha256CertFingerprints">
            {({ input: { value, onChange } }) => (
              <CustomTextList
                data={value}
                textLabel={getFormLabel('SHA256 Cert Fingerprint', true)}
                addLabel="Add Additional Fingerprint"
                onAdd={async () => onChange([...value, null])}
                onChange={(v, idx) => {
                  const updatedData = [...value];
                  updatedData[idx] = v;
                  onChange(updatedData);
                }}
                onDelete={(index) => {
                  const updatedData = [...value];
                  updatedData.splice(index, 1);
                  onChange(updatedData);
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box component="hr" sx={sx.separator} />
      <Grid container sx={sx.tabPanelContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">iOS Redirects</Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="iosUriScheme"
            label={getFormLabel('iOS URI Scheme', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="iosAppStoreUrl"
            label={getFormLabel('App Store URL', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="iosAppStoreId"
            label={getFormLabel('Apple App Store ID', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: ColorPalette.black }}>
            Bundle Identifiers
          </Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <Field name="iosBundleIds">
            {({ input: { value, onChange } }) => (
              <CustomTextList
                data={value}
                textLabel={getFormLabel('Bundle ID', true)}
                addLabel="Add Additional Bundle ID"
                onAdd={async () => onChange([...value, null])}
                onChange={(v, idx) => {
                  const updatedData = [...value];
                  updatedData[idx] = v;
                  onChange(updatedData);
                }}
                onDelete={(index) => {
                  const updatedData = [...value];
                  updatedData.splice(index, 1);
                  onChange(updatedData);
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: ColorPalette.black }}>
            Apple App Prefix
          </Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="iosTeamId"
            label={getFormLabel('Team ID', true)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: ColorPalette.black }}>
            Branding
          </Typography>
        </Grid>
        <Grid item xs={6} sx={sx.row}>
          <TextField
            name="brandingNetworkName"
            label={getFormLabel('Network Name', true)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box sx={sx.footer}>
        <Button
          color="primary"
          data-test="save-button"
          disabled={disabled}
          onClick={onSaved}
          sx={{ width: '120px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};
RequiredRedirects.propTypes = {
  mobileApps: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
};

RequiredRedirects.defaultProps = {
  mobileApps: [],
  disabled: false,
  loading: false,
};

export default RequiredRedirects;
