export const DASHBOARD_CARD_USAGE_TABLE_NAME = 'DashboardCardUsageTable';
export const LOCATIONS_TABLE_NAME = 'LocationsTable';
export const LOCATIONS_POI_TABLE_NAME = 'LocationsPoiTable';
export const PUBLISHING_HISTORY_TABLE_NAME = 'PublishingHistoryTable';
export const MOBILE_INSTALLATIONS_TABLE_NAME = 'MobileInstallationsTable';
export const MORE_MENU_USAGE_TABLE_NAME = 'MoreMenuUsageTable';
export const NAVIGATION_EVENTS_BY_LOCATION_TABLE_NAME
  = 'NavigationEventsByLocationTable';

export const DEFAULT_PAGE_SIZE = 100;
export const TABLE_MAX_PAGE_SIZE = 200;

export const retrieveTablePageSize = (
  tableName,
  defaultSize = DEFAULT_PAGE_SIZE,
) => Number(sessionStorage.getItem(`${tableName}-table-pageSize`)) || defaultSize;

export const persistTablePageSize = (tableName, pageSize) => {
  if (tableName) {
    sessionStorage.setItem(`${tableName}-table-pageSize`, pageSize);
  }
};

export const retrieveTableFilters = (tableName) => JSON.parse(sessionStorage.getItem(`${tableName}-table-filters`)) || [];

export const persistTableFilters = (tableName, filters) => {
  if (tableName) {
    if (filters) {
      sessionStorage.setItem(
        `${tableName}-table-filters`,
        JSON.stringify(filters),
      );
    }
  }
};

export const retrieveTableSearch = (tableName) => sessionStorage.getItem(`${tableName}-table-search`) || '';

export const persistTableSearch = (tableName, search) => {
  if (tableName) {
    sessionStorage.setItem(`${tableName}-table-search`, search);
  }
};

export const resetAllTableSettings = () => {
  sessionStorage.clear();
};

export const filtersChanged = (initial = [], current = []) => {
  if (initial.length !== current.length) return true;
  let changed = false;
  current.forEach((c) => {
    const initFilter = initial.find((i) => i.name === c.name);
    if (initFilter) {
      if (c.values.length !== initFilter.values.length) {
        changed = true;
      } else {
        c.values.forEach((cv) => {
          if (!initFilter.values.find((iv) => iv.value === cv.value)) changed = true;
        });
      }
    } else {
      changed = true;
    }
    if (!initial.find((i) => i.value === c.value)) changed = true;
  });
  return changed;
};

export const parseSortBy = (
  { id, desc },
  sortOverride = null,
  sortOverrideFunction = null,
) => {
  if (sortOverrideFunction) {
    const result = sortOverrideFunction({ id, desc });
    if (result) {
      return result;
    }
  }
  const sortField = sortOverride?.[id] || id;
  const order = desc === true ? 'desc' : 'asc';
  const sortObj = {};
  let container = sortObj;
  sortField.split('.').forEach((k, i, values) => {
    container = container[k] = i === values.length - 1 ? order : {};
  });
  return sortObj;
};

export const getTableCountTitle = (dataLength, title, pluralTitle = null) => {
  if (dataLength === 1) {
    return `${dataLength} ${title}`;
  }

  if (pluralTitle) {
    return `${dataLength} ${pluralTitle}`;
  }

  return `${dataLength} ${title}s`;
};
