import { FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GenericModal from 'components/genericModal/genericModal';
import Loading from 'components/loading/loading';
import Switch from 'components/switch/formSwitch';
import { FlamingoContext } from 'contexts/flamingo';
import { LANGUAGE_CODES, SUPPORTED_LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useUpdateNetworkFeatures } from 'hooks/dataHooks/useNetworkFeatures';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 'calc(100vh - 170px)',
  },
  formControlLabel: {
    marginLeft: 0,

    '& label.MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  progress: {
    marginLeft: '16px',
    width: 'fit-content',
  },
}));

const switches = [
  {
    feature: 'appointmentAvailability',
    label: 'Appointment Availability',
    showWarning: false,
  },
  { feature: 'banners', label: 'Banners', showWarning: false },
  { feature: 'languages', label: 'Language Support', showWarning: false },
  { feature: 'myChart', label: 'MyChart', showWarning: true },
  { feature: 'myFavorites', label: 'My Favorites', showWarning: true },
  { feature: 'openPlatform', label: 'Open Platform', showWarning: true },
  { feature: 'personas', label: 'Personas', showWarning: false },
  {
    feature: 'providerIntegration',
    label: 'Provider Integration',
    showWarning: false,
  },
  {
    feature: 'pushNotifications',
    label: 'Push Notifications',
    showWarning: false,
  },
  { feature: 'singleSignOn', label: 'SSO', showWarning: false },
  { feature: 'quicklinkList', label: 'Quicklink List', showWarning: true },
];

const FeaturesPage = () => {
  const { networkId } = useParams();

  const classes = useStyles();

  const { activeNetwork } = useContext(FlamingoContext);
  const [features, setFeatures] = useState(null);
  const [dirtyFeature, setDirtyFeature] = useState(null);
  const [warningProps, setWarningProps] = useState(null);

  const [updateFeatures] = useUpdateNetworkFeatures(networkId);

  useEffect(() => {
    if (activeNetwork?.configuration?.features) {
      setFeatures(activeNetwork.configuration?.features);
    }
  }, [activeNetwork]);

  const toggleFeature = async (feature) => {
    if (warningProps) {
      setWarningProps(null);
    }

    // eslint-disable-next-line no-unused-vars
    const { __typename, ...rest } = features;
    const updatedFeatures = { ...rest };

    if (feature === 'languages') {
      if (activeNetwork.configuration?.features?.languages.length === 1) {
        updatedFeatures.languages = SUPPORTED_LANGUAGE_CODES;
      } else {
        updatedFeatures.languages = [LANGUAGE_CODES.ENGLISH];
      }
    } else {
      updatedFeatures[feature]
        = updatedFeatures[feature] === 'on' ? 'off' : 'on';
    }
    setDirtyFeature(feature);
    await updateFeatures({
      variables: {
        networkConfigurationId: activeNetwork.configuration.id,
        input: { features: updatedFeatures },
      },
    });
    setFeatures(updatedFeatures);
    setDirtyFeature(null);
  };

  const handleChange
    = ({ feature, label, showWarning }) => async () => {
        // eslint-disable-next-line no-unused-vars
        const { __typename, ...rest } = features;
        const updatedFeatures = { ...rest };

        if (showWarning && updatedFeatures[feature] === 'on') {
          setWarningProps({ feature, label });
        } else {
          await toggleFeature(feature);
        }
      };

  const getFeatureValue = useCallback(
    (featureName) => {
      if (featureName === 'languages') {
        return activeNetwork.configuration?.features?.languages?.length > 1;
      }

      return features?.[featureName] === 'on';
    },
    [activeNetwork, features],
  );

  const FeatureSwitch = ({ feature, label, showWarning }) => (
    <Grid item container direction="row" justifyContent="flex-start">
      <FormGroup>
        <FormControlLabel
          className={classes.formControlLabel}
          disabled={!!dirtyFeature}
          control={
            dirtyFeature === feature ? (
              <Loading
                className={classes.progress}
                progressSize={34}
                content=""
              />
            ) : (
              <Switch
                input={{
                  value: getFeatureValue(feature),
                  onChange: handleChange({ feature, label, showWarning }),
                }}
                value={feature}
                data-test={`FeatureSwitch-${feature}`}
              />
            )
          }
          label={label}
          labelPlacement="start"
        />
      </FormGroup>
    </Grid>
  );

  FeatureSwitch.propTypes = {
    feature: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    showWarning: PropTypes.bool.isRequired,
  };

  if (isGlobalTemplate(networkId)) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage pageName="Available Features">
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.content}
        >
          {!features && <Loading />}
          {features && (
            <>
              <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                  Add-ons
                </Typography>
              </Grid>
              {switches.map(({ feature, label, showWarning }) => (
                <FeatureSwitch
                  key={feature}
                  feature={feature}
                  label={label}
                  showWarning={showWarning}
                />
              ))}
            </>
          )}
        </Grid>
        {warningProps && (
          <GenericModal
            title={warningProps.label}
            body="Turning off this feature will remove content from the Mobile Dashboard. This action can not be reversed. Are you sure you want to continue?"
            handleConfirm={async () => {
              await toggleFeature(warningProps.feature);
            }}
            handleClose={() => setWarningProps(null)}
          />
        )}
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default FeaturesPage;
