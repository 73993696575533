import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/loading/loading';
import React from 'react';

const onRedirecting = () => <Loading />;

export const AuthGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting,
  });

  return <Component />;
};
