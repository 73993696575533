import { useLazyQuery, useMutation } from '@apollo/client';
import { FlamingoContext } from 'contexts/flamingo';
import { DRAFT_WORKSPACE } from 'helpers/workspace-util';
import { useNetworkById } from 'hooks/dataHooks/useNetworkById';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import { useContext, useEffect, useState } from 'react';

import {
  CREATE_NETWORK_PROMOTION,
  CREATE_NETWORK_PROMOTION_ITEM,
  DELETE_NETWORK_PROMOTION_ITEM,
  FIND_NETWORK_PROMOTION_ITEMS,
  FIND_NETWORK_PROMOTIONS,
  FIND_NETWORK_PLACE,
} from 'graphql/queries';

export const useCreateNetworkPromotionItem = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Promotion');

  return useMutation(CREATE_NETWORK_PROMOTION_ITEM, {
    refetchQueries: [
      {
        query: FIND_NETWORK_PLACE,
        variables: {
          search: '',
          sortBy: { name: 'asc' },
          limit: 10,
          skip: 0,
          where: {
            workspaces: { name: DRAFT_WORKSPACE },
          },
        },
      },
    ],
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'The location has been added to the promotion queue.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const usePromotionItems = (networkId) => {
  const [shouldGetNetwork, setShouldGetNetwork] = useState(false);
  const { handleError } = useHandleError('Promotion');
  const { loading: networkLoading, network: networkData } = useNetworkById({
    networkId,
    shouldExecute: shouldGetNetwork,
  });
  const { activeNetwork } = useContext(FlamingoContext);

  const [getNetworkPromotionItems, { data, error, loading, refetch }]
    = useLazyQuery(FIND_NETWORK_PROMOTION_ITEMS, {
      context: {
        headers: { network: networkId, workspace: DRAFT_WORKSPACE },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    });

  useEffect(() => {
    if (activeNetwork.id === networkId) {
      getNetworkPromotionItems({});
    } else {
      setShouldGetNetwork(true);
    }
    // eslint-disable-next-line
  }, [activeNetwork, networkData, networkId]);

  return {
    getNetworkPromotionItems,
    loading: loading || networkLoading,
    error,
    data: data?.findNetworkPromotionItem?.edges
      ? data.findNetworkPromotionItem.edges.map((i) => i.node)
      : [],
    refetch,
  };
};

export const useDeletePromotionItem = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Promotion');

  return useMutation(DELETE_NETWORK_PROMOTION_ITEM, {
    context: {
      headers: { workspace: DRAFT_WORKSPACE },
    },
    refetchQueries: [
      {
        query: FIND_NETWORK_PROMOTION_ITEMS,
        variables: {},
        context: { headers: { workspace: DRAFT_WORKSPACE } },
      },
    ],
    onCompleted: (data) => {
      toastNotificationSuccessHook('The promotion item has been deleted.');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateNetworkPromotion = (networkId) => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Promotion');

  return useMutation(CREATE_NETWORK_PROMOTION, {
    context: { headers: { workspace: DRAFT_WORKSPACE } },
    refetchQueries: [
      {
        query: FIND_NETWORK_PROMOTION_ITEMS,
        variables: {},
        context: {
          headers: { network: networkId, workspace: DRAFT_WORKSPACE },
        },
      },
    ],
    onCompleted: (data) => {
      toastNotificationSuccessHook(
        'You have promoted data from the Draft Workspace to the Live Workspace.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const usePromotionHistory = (networkId) => {
  const [shouldGetNetwork, setShouldGetNetwork] = useState(false);
  const { handleError } = useHandleError('Promotion');

  const { loading: networkLoading, network: networkData } = useNetworkById({
    networkId,
    shouldExecute: shouldGetNetwork,
  });
  const { activeNetwork } = useContext(FlamingoContext);

  const [getNetworkPromotions, { data, error, loading, refetch }]
    = useLazyQuery(FIND_NETWORK_PROMOTIONS, {
      context: {
        headers: { network: networkId, lang: '*', workspace: DRAFT_WORKSPACE },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    });

  useEffect(() => {
    if (activeNetwork.id === networkId) {
      getNetworkPromotions({});
    } else {
      setShouldGetNetwork(true);
    }
    // eslint-disable-next-line
  }, [activeNetwork, networkData, networkId]);

  return {
    getNetworkPromotions,
    loading: loading || networkLoading,
    error,
    data: data?.findNetworkPromotion?.edges
      ? data.findNetworkPromotion.edges.map((i) => i.node)
      : [],
    refetch,
  };
};
