import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { getFormLabel } from 'helpers/form-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkPlace } from 'hooks/dataHooks/useSites';
import { makeValidate, Radios, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
}));

export const addSiteSchema = Yup.object().shape({
  siteName: Yup.string().required('Site name is required'),
  isMapped: Yup.string().required('Mapped status is required'),
});

export const validate = makeValidate(addSiteSchema);

const AddSiteModal = ({
  handleClose,
  handleConfirm,
  networkName,
  networkLogo,
}) => {
  const classes = useStyles();

  const [createSite] = useCreateNetworkPlace('site');

  const onSubmit = async (values) => {
    const response = await createSite({
      variables: {
        input: {
          name: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.siteName }],
          isMapped: values.isMapped === 'true',
          designation: 'site',
          contact: {
            hours: {
              type: 'none',
            },
          },
        },
      },
    });

    handleConfirm(response?.data?.createNetworkPlace);
  };

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues: {
          siteName: '',
          isMapped: '',
        },
        validate,
      }}
      networkName={networkName}
      logo={networkLogo}
      header={networkName}
      content={(formProps) => (
        <Grid sx={{ padding: '120px 88px' }} container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.heading} data-test="TakeOverModal-Add New Site">
              Add New Site
            </Typography>
            <Typography variant="subtitle1" className={classes.describe}>
              Tell Us a Bit About the New Site
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '33px' }}>
            <FinalTextField
              className={classes.textField}
              label={getFormLabel('Site Name', true)}
              name="siteName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '27px', textAlign: 'left' }}>
            <Radios
              label={getFormLabel('This Site is', true)}
              name="isMapped"
              color="primary"
              size="small"
              radioGroupProps={{ row: true }}
              required={false}
              data={[
                { label: 'Mapped', value: 'true' },
                { label: 'Unmapped', value: 'false' },
              ]}
            />
          </Grid>
        </Grid>
      )}
      confirmText="Save & Add"
      confirmType="submit"
      handleClose={handleClose}
    />
  );
};

AddSiteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  networkName: PropTypes.string,
};

AddSiteModal.defaultProps = {};

export default AddSiteModal;
