import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colorWithAlpha } from 'helpers/color-util';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const buildStyles = ({ theme }) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.blue[500],
  },
  cardGrid: {
    background: theme.palette.white,
    boxShadow: `0px 14px 24px 0px ${colorWithAlpha(theme.palette.black, 0.15)}`,
    borderRadius: '8px',
    width: '452px',
    height: '362px',
    padding: '16px 16px',
    textAlign: 'center',
  },
  titleText: {
    padding: '8px 72px !important',
  },
  bodyText: {
    padding: '8px 56px !important',
  },
});

/**
 * This page is shown when an SSO user logs out. The SSO login state is maintained by the network's SSO site, so logging out of Portal does not log out the user per se.
 */
function LoggedOutPage() {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const navigate = useNavigate();

  function handleClick() {
    navigate('/login');
  }

  return (
    <Grid
      container
      sx={styles.content}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={styles.cardGrid}
      >
        <Grid item sx={styles.titleText}>
          <Typography variant="h1" color="textSecondary">
            Logout
          </Typography>
        </Grid>
        <Grid item sx={styles.bodyText}>
          <Typography variant="body1">
            You have been logged out of the Gozio Portal. If you need to sign
            back in, please click below to be redirected.
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="center">
          <Button
            component="a"
            onClick={handleClick}
            size="large"
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoggedOutPage;
